import React, { useEffect, useState } from 'react';
import './styles.scss';
import NewWritingTheName from '../../../../class/new-writing-hypothesi/new-writing-the-name/new-writing-the-name';
import { useParams } from 'react-router-dom';
import type NewWritingTheNameType from '../../../../class/types/new-writing-hypothesi/new-writing-the-name';
import NewWritingTheNameContentTable from '../../../../components/new-writing-hypothesis/new-writing-the-name';
import LoadContent from '../../../../components/Load-content';
import EmptyComponent from '../../../../components/Empty-component';
import { FaUser } from 'react-icons/fa';

interface NewWritingTheNameTableProps {
  display: string
  roomInfo: any
}

export default function NewWritingTheNameTable({ display, roomInfo }: NewWritingTheNameTableProps) {
  const [writingTheName, setWritingTheName] = useState<NewWritingTheNameType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initIsLoading, setInitIsLoading] = useState<boolean>(false);

  const { id } = useParams();

  const initNewWritingTheNameTable = () => {
    if (!id || !roomInfo.twoMonths) {
      return;
    }

    const newWritingTheName = new NewWritingTheName();

    setInitIsLoading(true);
    newWritingTheName.initWritingTheName(id, roomInfo.twoMonths).then(response => {
      setInitIsLoading(false);
      if (response.length <= 0) {
        return;
      }
      setWritingTheName(response);
    }).catch(e => {
      //
    });
  }

  const getWritingTheNameCount = () => {
    return writingTheName?.reduce((count, value) => {
      if (value.writingTheName) {
        count += 1;
        return count;
      }

      return count;
    }, 0)
  }

  useEffect(() => {
    if (!id || !roomInfo.twoMonths) {
      return;
    }

    const newWritingTheNama = new NewWritingTheName();
    setIsLoading(true);
    newWritingTheNama.getAllWritingTheName(id, roomInfo.twoMonths).then(response => {
      setIsLoading(false);
      if (response.length <= 0) {
        setWritingTheName([]);
        return;
      }

      setWritingTheName(response);
    }).catch(e => {
      //
    })
  }, [display, roomInfo]);

  return (
    <div className='new-writing-the-name-table'>
      <div className='new-writing-the-name-table-header'>
        <h1>Aluno Escreve Nome - {roomInfo.twoMonthsName}</h1>
        <div className='writing-the-name-count'>
          <FaUser /> <span>{getWritingTheNameCount()}</span>
        </div>
      </div>
      <div className='new-writing-the-name-table-middle-buttons'>
        {writingTheName?.length > 0
          ? ''
          : (
            initIsLoading
              ? (
                <LoadContent
                  isLoading={initIsLoading}
                  style={{
                    container: {
                      width: 20,
                      height: 20,
                    }
                  }}
                />
              )
              : (
                <button className='btn-element' onClickCapture={initNewWritingTheNameTable}>Iniciar Registro</button>
              )
          )}
      </div>
      <div className='table-new-writing-the-name-content'>
        <div className='table-new-writing-the-name-content-header'>
          <ul>
            <li>Nº</li>
            <li>Nome do Aluno</li>
            <li className='writing-the-name-btn-content'>Escreve o Nome</li>
          </ul>
        </div>
        <div className='table-new-writing-the-name-content'>
          {writingTheName?.length === 0
            ? <EmptyComponent>Click em Iniciar Registro para registrar os alunos que escreve o próprio nome</EmptyComponent>
            : (
              isLoading
                ? (
                  <LoadContent
                    isLoading={isLoading}
                  />
                )
                : (
                  writingTheName?.map((writing, index) => {
                    return (
                      <NewWritingTheNameContentTable
                        index={index}
                        writingTheName={writing}
                        roomInfo={roomInfo}
                        setWritingTheName={setWritingTheName}
                        key={writing.studentId}
                      />
                    )
                  })
                )
            )}
        </div>
      </div>
    </div>
  );
}
