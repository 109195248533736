import React, { useState } from 'react';
import { type StudentExamsType } from '../../../class/types/student-exams/student-exams-protocol';
import './styles.scss';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import LoadContent from '../../Load-content';
import lscache from 'lscache';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';

interface StudentExamsTableContentProps {
  setStudentExams: React.Dispatch<React.SetStateAction<StudentExamsType[]>>
  setElementSelected: React.Dispatch<React.SetStateAction<StudentExamsType | undefined>>
  huddleDisplayEdit: () => void
  studentExams: StudentExamsType
  index: number
}

export default function StudentExamsTableContent({ setStudentExams, huddleDisplayEdit, setElementSelected, studentExams, index }: StudentExamsTableContentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatDate = () => {
    return new Date(studentExams.date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
    });
  }

  const huddleDelete = () => {
    const studentExamsApi = new StudentExamsApi();

    setIsLoading(true);
    studentExamsApi.delete(studentExams.id ?? '').then(response => {
      setStudentExams(response);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  }

  const huddleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    const user = lscache.get('user') as UserClientProtocol;

    if ((['path', 'svg'].includes((e.target as HTMLElement).tagName))) {
      return;
    }

    if (user.permission?.rule === 'estudante') {
      window.location.href = `/provas/estudantes/${studentExams.id ?? ''}`;
      return;
    }

    window.location.href = `/provas/${studentExams.id ?? ''}`;
  }

  return (
    <div className='student-exams-table-content' onClickCapture={huddleClick}>
      <ul className='student-exams-table-content-ul'>
        <li>{index + 1}</li>
        <li>
          {studentExams.title}
        </li>
        <li>
          {studentExams.matter.matterName}
        </li>
        <li>
          {studentExams.room.roomName}
        </li>
        <li>{formatDate()}</li>
        <li className='content-table-ul-li' >
          <FiEdit2 size='20' style={{ color: '#118951' }} onClick={() => {
            huddleDisplayEdit()
            setElementSelected(studentExams);
          }} className='icon-content' />
        </li>
        <li className='header-table-ul-li'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    width: 20,
                  },
                  container: {
                    width: 20,
                    height: 20,
                  }
                }}
              />
            )
            : <MdDelete onClickCapture={huddleDelete} size='20' style={{ color: '#ff0000' }} className='icon-content' />}        </li>
      </ul>
    </div>
  )
}
