/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import React, { useState } from 'react';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import LoadContent from '../Load-content';
import { MdDelete } from 'react-icons/md';
import lscache from 'lscache';
interface CommentsElementProps {
  api: any | {
    deleteDescription: (elementId: string, descriptionId: string, userId: string) => Promise<any>
  }
  elements: any
  setElements: React.Dispatch<React.SetStateAction<any>>
  setElement: React.Dispatch<React.SetStateAction<any>>
  comment: any
  commentId: string
  elementId: string
}

export default function CommentsElement({comment, commentId, elementId, api, setElements, elements, setElement}: CommentsElementProps) {
  const [descriptionDeleteLoading, setDescriptionDeleteLoading] = useState(false)

  const deleteDescription = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user.id || !elementId) {
      return;
    }

    setDescriptionDeleteLoading(true)
    api.deleteDescription(elementId, commentId, user.id).then((response: any) => {
      setDescriptionDeleteLoading(false);
      if (!response) {
        return;
      }

      elements.findIndex((value: any, index: number) => {
        if (response?.id === value?.id) {
          elements[index] = response;

          setElement(response);
          setElements(elements);
          return true;
        }

        return false;
      })
    }).catch((e: any) => {

    });
  }

  const IsTheUser = ({ children, userId }: { children: any, userId: string }) => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user) {
      return '';
    }

    if (user.id !== userId) {
      return '';
    }

    return children
  }
  return (
    <div className='description-content' key="key">
      {!comment
        ? ''
        : (
          <div className='description-title'>
            <div className='comment-title-content'>
              <span className='tag'>Comentário</span>
              {!comment.issue
                ? ''
                : (
                  <>
                    <h1>{comment?.issue?.userName}</h1>
                    <span className='date-content'>{comment?.date}</span>
                  </>
                )
              }
            </div>
            {descriptionDeleteLoading === true
              ? <LoadContent
                isLoading={descriptionDeleteLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />

              : (
                <IsTheUser userId={comment?.issue?.userId}>
                  <button className='delete-comment-button' onClickCapture={() => {
                    deleteDescription();
                  }}>
                    <MdDelete color='red' size={25} />
                  </button>
                </IsTheUser>
              )}
          </div>
        )}
      <p>
        {comment?.content}
      </p>
    </div>
  )
}
