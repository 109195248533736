import type { MatterResProtocol } from '../types/Matter/matterApi-protocol';
import type MatterFactoryProtocol from '../types/Matter/matterFactory-protocol';

class MatterFactory implements MatterFactoryProtocol {
	constructor(private readonly inputs: NodeListOf<HTMLInputElement>, private readonly teacherId: string, private readonly matterIdSelect: string) {}

	create(): MatterResProtocol {
		return {
			matterName: this.inputs[0].value,
			teacherInfo: {
				teacherId: this.teacherId,
				teacherName: this.inputs[1].value
			},
      bond: this.matterIdSelect,
		};
	}
}

export default MatterFactory;
