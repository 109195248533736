/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React, { useEffect, useRef } from 'react';
import './styles.scss';
import { type QuestionElementType } from '../../../class/types/student-exams/student-exams-protocol';

interface ExamTitleContentProps {
  option: QuestionElementType
}

export default function ExamTextContent({option}: ExamTitleContentProps): React.ReactElement {
  const textArea = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (!textArea || !textArea.current) {
      return;
    };

    textArea.current.style.height = `${textArea.current?.scrollHeight}px`
  });
  return (
    <div className='exam-text-content'>
      <textarea value={option.content} ref={textArea} disabled></textarea>
    </div>
  )
}
