import { type StudentType } from '../../types/Student/student-factory-protocol';
import type MathematicDiagnosticAssessmentCostumerProtocol from '../../types/mathematic-diagnostic-assessment';
import { type MathematicDiagnosticAssessmentObjType } from '../../types/mathematic-diagnostic-assessment';
import MathematicDiagnosticAssessmentApi from '../mathematic-diagnostic-assessment-api';
import MathematicDiagnosticAssessmentFactory from '../mathematics-diagnostic-assessment-factory';

export default class MathematicDiagnosticAssessmentCostumer implements MathematicDiagnosticAssessmentCostumerProtocol {
  constructor(private readonly roomId: string, private readonly twoMonths: string, private readonly inputs: NodeListOf<HTMLInputElement>, private readonly students: StudentType[]) {}

  async post(): Promise<MathematicDiagnosticAssessmentObjType> {
    const mathDiagnosticFactory = new MathematicDiagnosticAssessmentFactory()

    const mathDiagnosticAssessment: MathematicDiagnosticAssessmentObjType = mathDiagnosticFactory.createMathObject(this.inputs, this.students);

    let newMathObj = {};

    const mathDiagnosticApi = new MathematicDiagnosticAssessmentApi();

    await mathDiagnosticApi.post(this.roomId, this.twoMonths, mathDiagnosticAssessment).then(response => {
      newMathObj = response;
    }).catch(() => {
      //
    });

    return newMathObj;
  }
}
