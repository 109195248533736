/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { BiSolidBell } from 'react-icons/bi';
import BellRotate from './animations/bell-rotate';
import NotificationsBell from '../notifications';
import type NotificationType from '../../../class/types/notification';
import NotificationAPI from '../../../class/Notification/NotificationAPI';
import verifyNotificationCount from './service/returnNotificationCount';

export default function BellContainer() {
  const bellElement = useRef<HTMLDivElement | null>(null);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [displayNotificationBell, setDisplayNotificationBell] = useState<boolean>(false);
  const [clearNotification, setClearNotification] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [displayBell, setDisplayBell] = useState<boolean>(false);

  const huddleNotificationCount = (notifications: NotificationType[]) => {
    const count = verifyNotificationCount(notifications);

    if (count === 0) {
      setClearNotification(true);
      return 0;
    }

    setClearNotification(false);

    setNotificationCount(count);

    if (!bellElement.current) {
      return;
    }
    BellRotate(bellElement.current);
  }

  useEffect(() => {
    NotificationAPI.getAll().then(response => {
      setNotifications(response);
      huddleNotificationCount(response);
      setDisplayBell(true);
    });
    if (!bellElement.current) {
      return;
    }
    BellRotate(bellElement.current);
  }, []);

  useEffect(() => {
    huddleNotificationCount(notifications);
  }, [notifications]);

  return (
    <div className={`bell-notification-container display-${String(displayBell)}`}>
      <NotificationsBell
        notifications={notifications}
        displayNotification={displayNotificationBell}
        setDisplayNotification={setDisplayNotificationBell}
        setNotifications={setNotifications}
      />
      {clearNotification
        ? (
          <div className='bell-content' onClickCapture={() => {
            setDisplayNotificationBell(true);
          }}>
            <div>
              <BiSolidBell className='bell-icon-element' size='35' />
            </div>
          </div>
        )
        : (
          <div className='bell-content' onClickCapture={() => {
            setDisplayNotificationBell(true);
          }}>
            <div className='notification-count-element'>
              <h1>{notificationCount > 9 ? '9+' : notificationCount}</h1>
            </div>
            <div ref={bellElement}>
              <BiSolidBell className='bell-icon-element' size='35' />
            </div>
          </div>
        )}
    </div>
  )
}
