
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import ContentTableReadingFluency from '../../../components/reading-fluency-files/ContentTable-reading-fluency';
import { type ReadingFluencyType } from '../../../class/types/ReadingFluency/reading-fluencyApi-protocol';
import ReadingFluencySearch from '../service/reading-fluency-search';
import readingFluencyApi from '../../../class/reading-fluency/reading-fluencyApi';
import FormEditElement from '../FormEditElement-reading-fluency';
import FormAddElement from '../FormAddElement-reading-fluency';
import EmptyComponent from '../../../components/Empty-component';
import './style.scss';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [readingFluencyFiltered, setReadingFluencyFiltered] = useState<ReadingFluencyType[]>([]);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [readingFluency, setReadingFluency] = useState<ReadingFluencyType[]>([])
  const [element, setElement] = useState<ReadingFluencyType | undefined>();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement | undefined>) => {
    e.preventDefault();

    const inputs = (e.target as HTMLFormElement).querySelectorAll('input');

    const readingFluencySearch = new ReadingFluencySearch(inputs, readingFluency);

    const newReadingFluencySearch = readingFluencySearch.searchReadingFluency();

    setReadingFluencyFiltered(newReadingFluencySearch);
  }

  useEffect(() => {
    readingFluencyApi.get().then(response => {
      setReadingFluencyFiltered(response);
      setReadingFluency(response);
    });
  }, []);

  useEffect(() => {
    setReadingFluencyFiltered(readingFluency);
  }, [readingFluency])

  return (
    <div className='table-container-reading-fluency'>
      <h1 className='title-table'>Fluência Leitora</h1>
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setReadingFluency={setReadingFluency}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        element={element}
        setDisplayEditElement={setDisplayEditElement}
        setReadingFluency={setReadingFluency}
      />
      <div className='btn-create-new-teacher'>
        <button onClick={hundleCreateNewElement}>Criar gráfico de fluência leitora</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='sala' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='ano letivo' />
        </div>
        <div className='search-input-content input-content'>
          <input type='date' />
        </div>
        <h2>A</h2>
        <div className='search-input-content input-content'>
          <input type='date' />
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Bimestre</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li'>Ano Letivo</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            readingFluencyFiltered?.length === 0
              ? <EmptyComponent>click em (Adicionar professor) para adicionar um professor</EmptyComponent>
              : readingFluencyFiltered?.map((readingFluency, index) => (
                <ContentTableReadingFluency
                  index={index}
                  readingFluency={readingFluency}
                  setReadingFluency={setReadingFluency}
                  setDisplayFormEditElement={setDisplayEditElement}
                  setElement={setElement}
                  key={String(readingFluency.id)}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
