import { type ElementarySchoolMonthlyPlanType } from '../../../class/types/ElementarySchoolMonthlyPlan/ElementaryMonthlyPlanAPI';

export default function returnMaxNumber(element: ElementarySchoolMonthlyPlanType): number {
  const numbers = [
    element.methodology.ability.split(' - ').length,
    element.methodology.assessment.split(' - ').length,
    element.methodology.fieldOfAction.split(' - ').length,
    element.methodology.learningObjectives.split(' - ').length,
    element.methodology.methodologyStrategy.split(' - ').length,
    element.methodology.thematicUnit.split(' - ').length
  ]

  const maxNumber = Math.max(...numbers);

  return maxNumber;
};
