import type ActionFactoryProtocol from '../types/Action/Action-factory-protocol';
import type {ActionProtocol} from '../types/Action/Action-factory-protocol';

class ActionFactory implements ActionFactoryProtocol {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly id: string,
	) {}

	create(): ActionProtocol {
		return {
			strategyId: this.id,
			actionName: this.inputs[0].value,
			responsible: this.inputs[2].value,
			shipping: this.inputs[1].value,
			deadline: this.inputs[3].value,
			situation: 'não inicializado',
		};
	}

	createPut(): ActionProtocol {
		return {
			strategyId: this.id,
			actionName: this.verifyValue(0),
			responsible: this.verifyValue(2),
			shipping: this.verifyValue(1),
			deadline: this.verifyValue(3),
			situation: this.inputs[4].value,
		};
	}

	verifyValue(index: number): string {
		return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
	}
}

export default ActionFactory;
