/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, {useState, useEffect} from 'react';
import './style.scss';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';

interface ContentTableActionType {
  setStudentSelected: React.Dispatch<React.SetStateAction<StudentType | undefined>>
  setDisplayStudentMonetaryAidInfo: React.Dispatch<React.SetStateAction<boolean>>
  index: number
  student: any
}

export default function ContentTableStudentMonetaryAid({
  index,
  student,
  setStudentSelected,
  setDisplayStudentMonetaryAidInfo,
}: ContentTableActionType): React.ReactElement {
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);

  const formateDate = (_date: string | undefined) => {
    if (!_date) {
      return '';
    }
    return (new Date(_date)).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      dateStyle: 'short'
    })
  }

  const huddleClick = () => {
    setStudentSelected(student);
    setDisplayStudentMonetaryAidInfo(true);
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [student]);

  return (
		<div className={`content-table-element-student-monetary-aid display-animation-${displayAnimation}`} onClick={huddleClick}>
			<ul className='content-table-ul'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{student.studentName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{student.responsible}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{student.ra.content}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{student.ra.uf}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{student.district}
					</h2>
				</li>
				<li className='content-table-ul-li situation-element'>
					<h2>
						{student.situation}
						<span>
							{formateDate(student.situationModified)}
						</span>
					</h2>
				</li>
			</ul>
		</div>
  );
}
