/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import PsychologistApi from '../../class/Psychologist';
import LoadContent from '../../components/Load-content';
import EmptyComponent from '../../components/Empty-component';
import TableContentPsychologistMonitoring from '../../components/psychologist-files/table-content-monitoring';
import MedicalRecordByStudent from './medical-record-by-student';
import { type PsychologistAnamnese } from '../../class/types/Psychologist';

interface PsychologistStudentRegisterProps {
  display: string
}

export default function PsychologistMonitoring({ display }: PsychologistStudentRegisterProps): React.ReactElement {
  const [displayMedicalRecord, setDisplayMedicalRecord] = useState(false);
  const [studentPsychologist, setStudentPsychologist] = useState<PsychologistAnamnese[]>([]);
  const [studentSelected, setStudentSelected] = useState<PsychologistAnamnese | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const psychologist = new PsychologistApi();

    setIsLoading(true);
    psychologist.getAllStudent().then(response => {
      setStudentPsychologist(response);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  }, [display]);

  return (
    <section className='psychologist-monitoring'>
      <MedicalRecordByStudent display={displayMedicalRecord} setDisplay={setDisplayMedicalRecord} student={studentSelected} />
      <header className='psychologist-monitoring-header'>
        <div className='psychologist-monitoring-header-title'>
          <h1>Prontuário por Aluno</h1>
        </div>
      </header>
      <div className='section-student-list'>
        <div className='section-student-list-header'>
          <ul>
            <li>Nome do Estudante</li>
            <li>Nome do Mãe</li>
            <li>Nome do Pai</li>
            <li>Data de Aniversario</li>
          </ul>
        </div>
        {isLoading
          ? (
            <LoadContent isLoading={isLoading} style={{container: {marginTop: '4rem'}}} />
          )
          : <div className='section-student-list-table'>
            {studentPsychologist.length !== 0
              ? (
                studentPsychologist.map(student => {
                  return <TableContentPsychologistMonitoring setDisplayMedicalRecord={setDisplayMedicalRecord} student={student} setStudentSelected={setStudentSelected}/>;
                })
              )
              : <EmptyComponent>Nenhum aluno foi registrado</EmptyComponent>}
          </div>}
      </div>
    </section>
  )
}
