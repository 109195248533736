/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import Validator from '../Validator';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import StudentObserverFactory from './student-observer-factory';
import StudentObserverApi from './student-observer-api';
import { type StudentObserverType } from '../types/StudentObserver/student-observer-protocol';
import lscache from 'lscache';

export default class StudentObserverCustumer {
  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly textArea: HTMLTextAreaElement,
    private readonly student: any,
    private readonly roomId: string,
    private readonly matterId: string,
    private readonly twoMonths: string,
    private readonly studentObserverId = ''
  ) {}

  async post(): Promise<any> {
		const validation = new Validator(this.inputs);
		if (validation.validation(true, true)) {
			return {
				error: true
			};
		}

    const user = lscache.get('user') as UserClientProtocol;

    if (!user) {
      return [{
        error: true,
      }]
    }

		let newStudent: StudentObserverType[] = [];
		const studentObserverFactory = new StudentObserverFactory(this.inputs, this.textArea, this.student, user);
		const studentObserver = studentObserverFactory.create();

		await StudentObserverApi.post(studentObserver, this.roomId, this.matterId, this.twoMonths).then((response: StudentObserverType[]) => {
			newStudent = response;
		});

		return newStudent;
	}

  async put(): Promise<any> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: true
			};
		}

    const user = lscache.get('user') as UserClientProtocol;

    if (!user) {
      return [{
        error: true,
      }]
    }

		let newStudent: StudentObserverType[] = [];
		const studentObserverFactory = new StudentObserverFactory(this.inputs, this.textArea, this.student, user);

		const studentObserver = studentObserverFactory.createPut();

		await StudentObserverApi.put(studentObserver, this.roomId, this.matterId, this.twoMonths, this.studentObserverId).then((response: StudentObserverType[]) => {
			newStudent = response;
		});

		return newStudent;
	}
}
