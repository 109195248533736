import lscache from 'lscache';
import type { ActionResponseProtocol } from '../../../../class/types/Action/ActionAPI-protocol';
import filterById from './filterById';

export default function filterBySituation(situationFilter: string, id: string): ActionResponseProtocol[] {
  const newActions: ActionResponseProtocol[] = [];
  const actions = lscache.get('actions') as ActionResponseProtocol[];

  const actionFilter = filterById(actions, String(id));

  if (situationFilter.toLowerCase() === 'todos') {
    return actionFilter;
  }

  actionFilter.forEach(action => {
    if (action.situation?.toLowerCase() === situationFilter.toLowerCase()) {
      newActions.push(action);
    }
  });

  return newActions;
}
