/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import StudentExamsFormAdd from '../student-exams-form-add';
import { type RoomType } from '../../../class/types/Room/Rooms-protocol';
import RoomsApi from '../../../class/Room/RoomsApi';
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import { type StudentExamsType } from '../../../class/types/student-exams/student-exams-protocol';
import StudentExamsTableContent from '../../../components/student-exams-files/table-content';
import StudentExamsFormEdit from '../student-exams-form-edit';
import EmptyComponent from '../../../components/Empty-component';
import LoadContent from '../../../components/Load-content';
import lscache from 'lscache';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import RuleVerification from '../../../components/rule-verification';
import SearchElement from '../../../components/Search-element';

interface StudentExamsTableProps {
  display: string
}

export default function StudentExamsTable({ display }: StudentExamsTableProps): React.ReactElement {
  const [displayFormAddStudentExams, setDisplayFormAddStudentExams] = useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomType[]>([]);
  const [studentExams, setStudentExams] = useState<StudentExamsType[]>([]);
  const [displayFormEditStudentExams, setDisplayFormEditStudentExams] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [elementSelected, setElementSelected] = useState<StudentExamsType>();

  const [studentExamsFiltered, setStudentExamsFiltered] = useState<StudentExamsType[]>([]);

  const huddleDisplayFormAddStudentExams = () => {
    setDisplayFormAddStudentExams(!displayFormAddStudentExams);
  }

  const huddleDisplayFormEditStudentExams = () => {
    setDisplayFormEditStudentExams(!displayFormEditStudentExams);
  }

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol & { ra: string };

    RoomsApi.getAllRoomInfo().then(rooms => {
      setRooms(rooms);
    }).catch(e => [
      //
    ]);

    const studentExamsApi = new StudentExamsApi();

    setIsLoading(true);

    if (user.permission?.rule === 'estudante' && user.id && user.ra) {
      studentExamsApi.getAllStudentId(user.id, user.ra).then(response => {
        setIsLoading(false);
        setStudentExams(response);
      }).catch(e => {
        //
      })
      return
    }

    studentExamsApi.get().then(response => {
      setIsLoading(false);
      setStudentExams(response);
      setStudentExamsFiltered(response);
    }).catch(e => {
      //
    })
  }, [display]);

  useEffect(() => {
    setStudentExamsFiltered(studentExams);
  }, [studentExams]);

  return (
    <div className='student-exams-table'>
      <StudentExamsFormAdd
        display={displayFormAddStudentExams}
        huddleDisplay={huddleDisplayFormAddStudentExams}
        setStudentExams={setStudentExams}
        rooms={rooms}
      />
      <StudentExamsFormEdit
        elementSelected={elementSelected}
        display={displayFormEditStudentExams}
        huddleDisplay={huddleDisplayFormEditStudentExams}
        setStudentExams={setStudentExams}
        rooms={rooms}
      />
      <div className='student-exams-table-title'>
        <h1>Provas</h1>
      </div>
      <RuleVerification rule={['coordenador']}>
        <div className='btn-student-exams-table'>
          <button onClickCapture={huddleDisplayFormAddStudentExams}>Criar Prova</button>
        </div>
      </RuleVerification>
      <div className='student-exams-search-container'>
        <SearchElement
          elements={studentExams}
          elementKey='title'
          elementFiltered={studentExamsFiltered}
          setElementFiltered={setStudentExamsFiltered}
          placeholder='Procurar por titulo'
        />
        <SearchElement
          elements={studentExamsFiltered}
          elementKey='matter'
          otherKey='matterName'
          elementFiltered={studentExamsFiltered}
          setElementFiltered={setStudentExamsFiltered}
          placeholder='Procurar por materia'
        />
      </div>
      <div className='table-container'>
        <div className='table-content-header'>
          <ul className='header-table-ul'>
            <li>Nº</li>
            <li>Titulo</li>
            <li>Materia</li>
            <li>Sala</li>
            <li>Data</li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className='table-content'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      width: 50
                    },
                    container: {
                      marginTop: 20,
                    }
                  }
                }
              />
            )
            : (
              studentExamsFiltered.length > 0
                ? studentExamsFiltered.map((exams, index) => {
                  return <StudentExamsTableContent setStudentExams={setStudentExams} studentExams={exams} index={index} huddleDisplayEdit={huddleDisplayFormEditStudentExams} setElementSelected={setElementSelected} />
                })
                : (
                  <EmptyComponent>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        click em (criar prova) para criar prova
                      </>
                    </RuleVerification>
                  </EmptyComponent>
                )
            )}
        </div>
      </div>
    </div>
  )
}
