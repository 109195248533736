import React from 'react';
import type StudentMonetaryResType from '../../../class/types/student-monetary-aid';
import './styles.scss';
import DashBoardStudentMonetaryAid from '../Dashboard';

interface StudentMonetaryAidProps {
  studentMonetary: StudentMonetaryResType
}
export default function StudentMonetaryAidContentTable({ studentMonetary }: StudentMonetaryAidProps): React.ReactElement {
  const twoMonths = ['twoMonths-one', 'twoMonths-two', 'twoMonths-tree', 'twoMonths-for'];
  return (
    <div className='student-monetary-aid-content-table-container'>
      <div className='student-monetary-aid-content-table-header'>
        <h1>{studentMonetary.name}</h1>
      </div>
      <div className='student-monetary-aid-content-table-chart'>
        <div className='dashboard-content'>
          <h1>1º Bimestre</h1>
          <DashBoardStudentMonetaryAid
            studentMonetaryAid={studentMonetary}
            twoMonths={'twoMonths-one'}
          />
        </div>
        <div className='dashboard-content'>
          <h1>2º Bimestre</h1>
          <DashBoardStudentMonetaryAid
            studentMonetaryAid={studentMonetary}
            twoMonths={'twoMonths-two'}
          />
        </div>
        <div className='dashboard-content'>
        <h1>3º Bimestre</h1>
          <DashBoardStudentMonetaryAid
            studentMonetaryAid={studentMonetary}
            twoMonths={'twoMonths-tree'}
          />
        </div>
        <div className='dashboard-content'>
          <h1>4º Bimestre</h1>
          <DashBoardStudentMonetaryAid
            studentMonetaryAid={studentMonetary}
            twoMonths={'twoMonths-for'}
          />
        </div>
      </div>
    </div>
  );
}
