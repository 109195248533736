/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {useEffect, useRef, useState} from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import type DigitalHTTPIResProtocol from '../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import { type HTTPIType } from '../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import LoadContent from '../../../../components/Load-content';
import WorkshopDigitalHTTPICostumer from '../../../../class/workshopDigital-HTTPI/WorkshopDigitalHTTPI-custumer';

interface AddHTTPIProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  setDayOfHTTPI: React.Dispatch<React.SetStateAction<HTTPIType[]>>
  element: DigitalHTTPIResProtocol
  elementSelected: HTTPIType | undefined
}

export default function EditHTTPI({ display, element, elementSelected, setDisplay, setDayOfHTTPI }: AddHTTPIProps) {
  const [isLoading, setIsLoading] = useState(false);
  const activityRef = useRef<HTMLInputElement | null>(null);
  const dateRef = useRef<HTMLInputElement | null>(null);
  const afterTime = useRef<HTMLInputElement | null>(null);
  const beforeTime = useRef<HTMLInputElement | null>(null);

  const huddleClose = () => {
    setDisplay(!display);
  }

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!elementSelected) {
      return;
    }

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const dayOfHTTPICustumer = new WorkshopDigitalHTTPICostumer(inputs);

    setIsLoading(true);
    dayOfHTTPICustumer.putDayOfHTTPI(element.id ?? '', elementSelected?.id ?? '').then((response: any) => {
      if (response[0].error) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setDayOfHTTPI(response);
      setDisplay(false);
    });
  }

  useEffect(() => {
    if (!elementSelected) {
      return;
    }
    (activityRef.current ? activityRef.current : {value: ''}).value = elementSelected.activity;
    (dateRef.current ? dateRef.current : {value: ''}).value = elementSelected.date;
    (afterTime.current ? afterTime.current : {value: ''}).value = elementSelected.time.after;
    (beforeTime.current ? beforeTime.current : {value: ''}).value = elementSelected.time.before;
  }, [display]);

  return (
    <div className={`add-day-of-HTTPI display-${String(display)}`}>
      <form className='form-day-of-HTTPI' onSubmit={huddleSubmit}>
        <div className='header-form-add-element-content'>
          <h1>
            Editar Atividade
          </h1>
          <IoCloseOutline onClickCapture={huddleClose} size='25' />
        </div>
        <div className='main-form'>
          <div className='input-content'>
            <label htmlFor="">Atividade</label>
            <input type="" ref={activityRef} />
          </div>
          <div className='input-content'>
            <label htmlFor="">Atividade</label>
            <input type="date" className='date' ref={dateRef} />
          </div>
          <div className='input-content'>
            <label htmlFor="">Hora - Inicio</label>
            <input type="time" className='time' ref={afterTime} />
          </div>
          <div className='input-content'>
            <label htmlFor="">Hora - Fim</label>
            <input type="time" className='time' ref={beforeTime} />
          </div>
        </div>
        <div className='button-content'>
        {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                    marginRight: 50,
                  }
                }}
              />
            )
            : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  setDisplay(false);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Adicionar</button>
              </>
            )}
        </div>
      </form>
    </div>
  )
}
