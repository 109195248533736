/* eslint-disable @typescript-eslint/no-var-requires */
import JSPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { type FrequencyElement } from '../../../../class/types/Frequency/frequency-factory-protocol';

const img = require('../../img/header-img.png');

export default function PrintFrequencyInformation(frequency: FrequencyElement[], date: string, twoMonthsName: string, roomName: string) {
  const doc = new JSPDF({
    orientation: 'landscape',
  });
  doc.addImage(img, 'PNG', 50, 5, 180, 0);

  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951'
    },
    head: [
      ['Numero', 'Nome do Aluno', 'Frequência']
    ],
    body: (() => {
      const bodyArray: string[][] = [];

      frequency.forEach((freq, index) => {
        bodyArray.push([
          `${index + 1}`,
          freq.studentName,
          freq.present === 'true' ? 'C' : 'F'
        ]);
      });

      return bodyArray;
    })()
  });

  doc.save(`Frequência ${roomName} ${date.replaceAll('_', '-')} - ${twoMonthsName}.pdf`);
}
