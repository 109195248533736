import { type StudentType } from '../../../../class/types/Student/student-factory-protocol';

export default function searchStudentByName(name: string, students: StudentType[]): StudentType[] {
  if (!name) {
    return [];
  }

  const studentsFiltered = students.filter(value => {
    const formattedName = name.trim().replaceAll(' ', '').toLowerCase();
    const formateStudentName = value.studentName.trim().replaceAll(' ', '').toLowerCase().slice(0, formattedName.length);

    if (formateStudentName === formattedName) {
      return true
    }

    return false;
  });

  return studentsFiltered;
}
