/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import MunicipalEducationPlanMonitoringSheetCostumer from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-costumer';
import type MunicipalEducationPlanMonitoringSheetType from '../../../class/types/municipal-education-plan-monitoring-sheet';

interface FormEditElementProps {
  displayEditElement: boolean
  setDisplayEditElement: (_boolean: React.SetStateAction<boolean>) => void
  setMunicipalEducationPlanMonitoringSheet: React.Dispatch<React.SetStateAction<MunicipalEducationPlanMonitoringSheetType[]>>
  element: MunicipalEducationPlanMonitoringSheetType | undefined
}

export default function FormEditElement({ displayEditElement, element, setDisplayEditElement, setMunicipalEducationPlanMonitoringSheet }: FormEditElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const county = useRef<HTMLInputElement | null>(null);
  const codCounty = useRef<HTMLInputElement | null>(null);
  const microregion = useRef<HTMLInputElement | null>(null);
  const mesoregion = useRef<HTMLInputElement | null>(null);
  const uf = useRef<HTMLInputElement | null>(null);
  const municipalEducationPlan = useRef<HTMLInputElement | null>(null)
  const expectedEvaluationPeriods = useRef<HTMLInputElement | null>(null);
  const commitCoordinatorInputContent = useRef<HTMLInputElement | null>(null);
  const commitCoordinatorLaw = useRef<HTMLInputElement | null>(null);
  const yearOfFirstAssessment = useRef<HTMLInputElement | null>(null);
  const technicalTeam = useRef<HTMLInputElement | null>(null);
  const technicalTeamLaw = useRef<HTMLInputElement | null>(null);
  const tell = useRef<HTMLInputElement | null>(null);
  const email = useRef<HTMLInputElement | null>(null);
  const initOfTerm = useRef<HTMLInputElement | null>(null);
  const endOfTerm = useRef<HTMLInputElement | null>(null);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const municipalEducationPlanMonitoringSheetCostumer = new MunicipalEducationPlanMonitoringSheetCostumer(inputs);

    setIsLoading(true);
    municipalEducationPlanMonitoringSheetCostumer.put(element?.id ?? '').then(response => {
      if ((response[0] as {error: boolean}).error) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setDisplayEditElement(false);
      setDisplayElement(false);
      setMunicipalEducationPlanMonitoringSheet(response as MunicipalEducationPlanMonitoringSheetType[]);
    }).catch(e => {
      //
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayEditElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  const huddleDisplayElement = () => {
    (county.current as any).value = element?.county;
    (codCounty.current as any).value = element?.codCount;
    (microregion.current as any).value = element?.microRegion;
    (mesoregion.current as any).value = element?.mesoRegion;
    (uf.current as any).value = element?.uf;
    (municipalEducationPlan.current as any).value = element?.municipalEducationPlan;
    (expectedEvaluationPeriods.current as any).value = element?.expectedEvaluationPeriods;
    (yearOfFirstAssessment.current as any).value = element?.yearOfFirstAssessment;
    (technicalTeam.current as any).value = element?.technicalTeam.names;
    (commitCoordinatorInputContent.current as any).value = element?.coordinatingCommittee.names;
    (commitCoordinatorLaw.current as any).value = element?.coordinatingCommittee.law;
    (technicalTeamLaw.current as any).value = element?.technicalTeam.law;
    (tell.current as any).value = element?.referenceContacts.tell;
    (email.current as any).value = element?.referenceContacts.email;
    (initOfTerm.current as any).value = element?.period.init;
    (endOfTerm.current as any).value = element?.period.end;
  }

  useEffect(() => {
    setDisplayElement(displayEditElement);
    huddleDisplayElement();
  }, [displayEditElement]);

  return (
    <div className={`form-edit-container-municipal-education-plan-monitoring display-${displayElement}`}>
      <form method='POST' className='form-add-element' id='form-add-element' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Ficha de monitoramento do plano municipal de educação
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='form-edit-county-input-content'>Município:</label>
            <input
              type='text'
              id='form-edit-county-input-content'
              placeholder='nome do município'
              ref={county}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-codCounty-input-content'>Cod Município:</label>
            <input
                type='text'
                id='form-edit-codCounty-input-content'
                placeholder='código município'
                ref={codCounty}
              />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-microregion-input-content'>Microrregião:</label>
            <input
              type='text'
              id='form-edit-microregion-input-content'
              placeholder='microrregião'
              ref={microregion}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-mesoregion-input-content'>Mesorregião:</label>
            <input
              type='text'
              id='form-edit-mesoregion-input-content'
              placeholder='mesorregião'
              ref={mesoregion}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-uf-input-content'>UF:</label>
            <input
              type='text'
              id='form-edit-uf-input-content'
              placeholder='UF'
              ref={uf}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-municipal-education-plan-input-content'>Plano Municipal de Educação:</label>
            <input
              type='text'
              id='form-edit-municipal-education-plan-input-content'
              placeholder='plano municipal de educação'
              ref={municipalEducationPlan}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-expected-evaluation-periods-input-content'>Períodos de Avaliação previstos:</label>
            <input
              type='text'
              id='form-edit-expected-evaluation-periods-input-content'
              placeholder='períodos de avaliação'
              ref={expectedEvaluationPeriods}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-year-of-first-assessment-input-content'>Ano da primeira avaliação:</label>
            <input
              type='text'
              id='form-edit-year-of-first-assessment-input-content'
              placeholder='ano de primeira avaliação'
              ref={yearOfFirstAssessment}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-commit-coordinator-input-content'>Comissão Coordenadora:</label>
            <input
              type='text'
              id='form-edit-commit-coordinator-input-content'
              placeholder='comissão coordenadora'
              ref={commitCoordinatorInputContent}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-commit-coordinator-law-input-content'>Lei:</label>
            <input
              type='text'
              id='form-edit-commit-coordinator-law-input-content'
              placeholder='lei'
              ref={commitCoordinatorLaw}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-technical-team-input-content'>Equipe Técnica:</label>
            <input
              type='text'
              id='form-edit-technical-team-input-content'
              placeholder='equipe técnica'
              ref={technicalTeam}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-technical-team-law-input-content'>Lei:</label>
            <input
              type='text'
              id='form-edit-technical-team-law-input-content'
              placeholder='lei'
              ref={technicalTeamLaw}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-tell-input-content'>Telefone:</label>
            <input
              type='text'
              id='form-edit-tell-input-content'
              placeholder='telefone'
              ref={tell}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-email-input-content'>Email:</label>
            <input
              type='text'
              id='form-edit-email-input-content'
              placeholder='email'
              ref={email}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-init-term-input-content'>Inicio do Prazo:</label>
            <input
              type='text'
              id='form-edit-init-term-input-content'
              placeholder='inicio do prazo'
              ref={initOfTerm}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='form-edit-end-of-term-input-content'>Fim do Prazo:</label>
            <input
              type='text'
              id='form-edit-end-fo-term-input-content'
              placeholder='fim do prazo'
              ref={endOfTerm}
            />
          </div>
          <div className='buttons-content'>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={
                    {
                      button: {
                        fontSize: 25,
                      },
                      container: {
                        width: 25,
                      }
                    }
                  }
                />
              )
              : (
                <>
                  <button className='cancel-button' type='button' onClick={() => {
                    hundleClickClose(true);
                  }}>Cancelar</button>
                  <button className='save-button' type='submit'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
