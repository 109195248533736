
import type { FrequencyType } from '../types/Frequency/frequency-factory-protocol';
import type { StudentType } from '../types/Student/student-factory-protocol';
import frequencyApi from './frequency-api';
import FrequencyFactory from './frequency-factorty';

class FrequencyCustumer {
  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly roomId: string,
    private readonly matterId: string,
    private readonly twoMonths: string,
    private readonly students: StudentType[],
    private readonly frequencyId: string = ''
  ) {}

  async post(): Promise<FrequencyType[]> {
    const frequencyFactory = new FrequencyFactory(this.inputs, this.students, this.roomId);

    const frequency = frequencyFactory.create();

    let frequencies: FrequencyType[] = [];

    await frequencyApi.post(this.roomId, this.matterId, this.twoMonths, frequency).then(response => {
      frequencies = response;
    });

    return frequencies;
  }

  async put(): Promise<FrequencyType[]> {
    const frequencyFactory = new FrequencyFactory(this.inputs, this.students, this.roomId);

    const frequency = frequencyFactory.create();

    let frequencies: FrequencyType[] = [];

    await frequencyApi.put(this.roomId, this.matterId, this.twoMonths, this.frequencyId, frequency).then(response => {
      frequencies = response;
    });

    return frequencies;
  }
}

export default FrequencyCustumer;
