import React, { useEffect, useState } from 'react';
import './styles.scss';
import { type MonitoringSheetGoalType, type StrategyType } from '../../../class/types/municipal-education-plan-monitoring-sheet';
import MunicipalEducationPlanMonitoringSheetCostumer from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-costumer';
import { useParams } from 'react-router-dom';
import ContentTableMunicipalEducationPlanMonitoringSheetStrategies from '../../../components/municipal-education-plan-monitoring-sheet-files/ContentTable-municipal-education-plan-monitoring-sheet-strategies';
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import LoadContent from '../../../components/Load-content';

interface TableContentProps {
  display: string
  goalInfo: MonitoringSheetGoalType | undefined
}

export default function TableContent({ display, goalInfo }: TableContentProps) {
  const [strategies, setStrategies] = useState<StrategyType[]>([]);
  const { id } = useParams();

  const [createContentLoading, setCreateContentLoading] = useState(false);

  const contentLoadingStyle = {
    button: {
      fontSize: '25px',
    },
    container: {
      width: '70px',
    }
  }

  const createInitialStrategy = () => {
    if (!id || !goalInfo?.id) {
      return;
    }
    const municipalEducationPlanMonitoringSheetCostumer = new MunicipalEducationPlanMonitoringSheetCostumer();

    setCreateContentLoading(true);
    municipalEducationPlanMonitoringSheetCostumer.createInitialStrategy(id, goalInfo?.id).then(response => {
      setStrategies(response);
      setCreateContentLoading(false);
    }).catch(e => {
      //
    });
  }

  useEffect(() => {
    if (!id || !goalInfo?.id) {
      return;
    }

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    municipalEducationPlanMonitoringSheetApi.getAllStrategy(id, goalInfo.id).then(response => {
      setStrategies(response);
    }).catch(e => {
      //
    })
  }, [display]);

  return (
    <div className='municipal-education-plan-monitoring-sheet-strategies-table-content'>
      <header className='municipal-education-plan-monitoring-sheet-strategy-header'>
        <h1>Estrategias</h1>
        <div className='button-content'>
          {createContentLoading
            ? (
              <LoadContent
                isLoading={createContentLoading}
                style={contentLoadingStyle}
              />
            )
            : <button onClick={createInitialStrategy}>Criar nova estrategia</button>}
        </div>
      </header>
      <section className='municipal-education-plan-monitoring-sheet-strategy-table'>
        {strategies.map((strategy, index) => {
          return <ContentTableMunicipalEducationPlanMonitoringSheetStrategies setStrategies={setStrategies} strategy={strategy} goalInfo={goalInfo} key={index} />
        })}
      </section>
    </div>
  )
}
