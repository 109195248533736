/* eslint-disable @typescript-eslint/no-floating-promises */
import { type ReadingFluencyLevelResType } from '../types/ReadingFluencyLevel/reading-fluency-level-api-protocol';
import { type StudentType } from '../types/Student/student-factory-protocol';
import ReadingFluencyLevelApi from './reading-fluency-level-api';
import ReadingFluencyLevelFactory from './reading-fluency-level-factory';

export default class ReadingFluencyLevelCustumer {
  constructor(
    private readonly readingFluencyId: string,
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly students: StudentType[],
	) {}

  async post(): Promise<ReadingFluencyLevelResType[]> {
    let newReadingFluencyLevel: ReadingFluencyLevelResType[] = [];

    const readingFluencyLevel = new ReadingFluencyLevelFactory(this.inputs, this.students);

    const readingFluencyObj = readingFluencyLevel.create();

    await ReadingFluencyLevelApi.post(this.readingFluencyId, readingFluencyObj).then(response => {
      newReadingFluencyLevel = response;
    });

    return newReadingFluencyLevel;
  }
}
