import lscache from 'lscache';
import React from 'react';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';

interface RuleVerificationProps {
  rule?: Array<'coordenador' | 'professor' | 'nutricionista' | 'psicologo' | string>
  children: JSX.Element
}

export default function RuleVerification({ rule, children }: RuleVerificationProps): JSX.Element {
  const ruleClient = lscache.get('rule') as string;
  const user = lscache.get('user') as UserClientProtocol;

  if (ruleClient === 'console') {
    return children
  }

  if (!rule) {
    if (ruleClient) {
      return children;
    }
  }

  if (rule?.includes(user.permission?.rule ?? '')) {
    return children;
  }

  if (!rule?.includes(ruleClient)) {
    return <></>;
  }

  return children;
}
