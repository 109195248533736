/* eslint-disable @typescript-eslint/member-ordering */
import displayErro from './services/displayError';
import type ValidatorProtocol from './types/Validator-protocol';

class Validator implements ValidatorProtocol {
	constructor(private readonly inputs: NodeListOf<HTMLInputElement> | any[], private readonly displayError = true) {}

	init(): boolean {
		return this.validation();
	}

	validation(extend = false, validDate = false): boolean {
		let error = false;
		try {
			this.inputs.forEach(input => {
				if (this.isEmpty(input.value)) {
					error = true;
					if (!this.displayError) {
						return;
					}

					displayErro(input, 'campo esta vazio!');

					throw new Error('campo vazio');
				}

				if (!extend) {
          if (!validDate) {
            return;
          }

          this.inputs.forEach((input: HTMLInputElement) => {
            if (input.type !== 'date') {
              return;
            }
            if (input.value === '') {
              error = true;
              if (!this.displayError) {
                return;
              }
              displayErro(input, 'campo esta vazio!');
              throw new Error('campo vazio');
            }
          });

          return;
				}

				if (this.lengthVerify(input.value)) {
					error = true;
					if (!this.displayError) {
						return;
					}

					displayErro(input, 'o mínimo de caracteres é 8');

					throw new Error('campo vazio');
				}
			});
		} catch (e) {
			//
		}

		return error;
	}

	lengthVerify(value: string): boolean {
		return value.length < 8;
	}

	isEmpty(value: string): boolean {
		return value.length === 0;
	}

	static displayError(element: HTMLElement, text: string): void {
		displayErro(element, text);
	}
}

export default Validator;
