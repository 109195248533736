import React from 'react';
import './styles.scss';
import NewWritingHypothesisTable from '../table';

interface NewWritingHypothesisProps {
  display: string
  roomInfo: any
}

export function NewWritingHypothesis({display, roomInfo}: NewWritingHypothesisProps): React.ReactElement {
  return (
    <div className='new-writing-hypothesis-container'>
      <NewWritingHypothesisTable display={display} roomInfo={roomInfo} />
    </div>
  )
}
