/* eslint-disable no-negated-condition */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React from 'react';
import type { FrequencyType } from '../../../class/types/Frequency/frequency-factory-protocol';
import FrequencyInformationElement from '../../../components/frequency-files/frequency-information';
import './style.scss';
import PrintFrequencyInformation from './service/print-frequency-information';
import { useParams } from 'react-router-dom';

interface FrequencyInformationProps {
  cardInformation: Record<string, string>
  displayFrequency: boolean
  setDisplayFrequency: React.Dispatch<React.SetStateAction<boolean>>
  frequencySelected: FrequencyType | undefined
}

export default function FrequencyInformation({ cardInformation, frequencySelected, displayFrequency, setDisplayFrequency }: FrequencyInformationProps): React.ReactElement {
  const {roomName} = useParams();

  const hundleClick = () => {
    setDisplayFrequency(false);
  };

  const print = () => {
    if (!frequencySelected?.frequency || !roomName) {
      return;
    }

    PrintFrequencyInformation(frequencySelected?.frequency, frequencySelected.date, cardInformation.twoMonthsName, roomName);
  }

  return (
		<div className={`frequency-container-information display-${displayFrequency}`}>
			<div className='header-frequency-container'>
				<div className='header-frequency-element'>
					<h1>Frequência</h1>
					<h1>Materia: {cardInformation.matterName}</h1>
					<h1>{cardInformation.twoMonthsName}</h1>
					<h1>Data: {frequencySelected?.date ?? ''}</h1>
					<h1>Data de postagem: {frequencySelected?.datePost ?? ''}</h1>
				</div>
				<div className='button-content'>
          <button type='button' onClick={print}>Imprimir</button>
					<button type='button' onClick={hundleClick}>Voltar</button>
				</div>
			</div>
			<div className='header-table-element-frequency'>
				<ul className='header-table-ul'>
					<li className='header-table-ul-li'>Nº</li>
					<li className='header-table-ul-li'>Nome</li>
					<li className='header-table-ul-li present-element'>Presenças</li>
				</ul>
			</div>
			<div className='body-tabele-element-frequency'>
				{!frequencySelected?.frequency
				  ? ''
				  : frequencySelected.frequency.map((frequency, index) => <FrequencyInformationElement
					frequency={frequency}
					index={index}
				/>)}
			</div>
		</div>
  );
}
