/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import axios from 'axios';
import config from '../../config/config';
import { type StringArraySupportOption } from 'prettier';

class RecoverByEmail {
	private get href() {
		return config.apiUrl;
	}

	async recoverByEmail(email: string): Promise<any> {
		const success = {
			success: false,
			error: false
		};
		await axios.post(`${this.href}/recover/password/by/email`, { email }).then((response: any) => {
			const { data } = response;

			if (data.error) {
				success.success = false;
				success.error = response.error;
			}

			if (data.accepted.length >= 1) {
				success.success = true;
			}
		});

		return success;
	}

	async codeIsValid(email: string, code: string): Promise<string | undefined> {
		let newCode;
		await axios.post(`${this.href}/recover/password/codeIsValid`, { email, code }).then(response => {
			if (response.data.success) {
				newCode = response.data.newCode as StringArraySupportOption;
			}
		});

		return newCode;
	}

	async resetPassword(email: string, code: string, password: string): Promise<boolean> {
		let success = false;
		await axios.post(`${this.href}/recover/reset/password`, { email, code, password }).then(response => {
			success = response.data.success as boolean;
		});

		return success;
	}
}

export default new RecoverByEmail();
