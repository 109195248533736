/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { type ElementarySchoolDecentWorkType } from '../../class/types/Upload_ElementarySchoolDecenteWork';
import uploadKindergartenHTTPIApi from '../../class/UploadKindergartenSchoolHTTPI/upload-HTTPI-api';
import uploadElementarySchoolHTTPIApi from '../../class/UploadElementarySchoolHTTPI/upload-HTTPI-api';
import LoadContent from '../Load-content';
import UploadWorkshopHTTPIApi from '../../class/UploadWorkShopHTTPI/upload-HTTPI-api';

interface ReadCheckingProps {
  displayReadChecking: boolean
  elementId: string
  setElementaryDecentWork: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  setDisplayReadChecking: React.Dispatch<React.SetStateAction<boolean>>
  type: 'elementary-school' | 'kindergarten-school' | 'workshop'
}

export default function ReadCheckingHTTPI({ displayReadChecking, elementId, type, setElementaryDecentWork, setDisplayReadChecking }: ReadCheckingProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setCheckingHuddle = () => {
    setIsLoading(true);
    if (type === 'elementary-school') {
      uploadElementarySchoolHTTPIApi.setChecking(elementId).then(response => {
        setElementaryDecentWork(response);
        setDisplayReadChecking(false);
        setIsLoading(false);
      });
      return;
    }

    if (type === 'workshop') {
      UploadWorkshopHTTPIApi.setChecking(elementId).then(response => {
        setElementaryDecentWork(response);
        setDisplayReadChecking(false);
        setIsLoading(false);
      });
      return;
    }

    uploadKindergartenHTTPIApi.setChecking(elementId).then(response => {
      setElementaryDecentWork(response);
      setDisplayReadChecking(false);
      setIsLoading(false);
    });
  }

  return (
    <div className={`read-checking-element display-${String(displayReadChecking)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          confirmação de visualização
        </h1>
        <IoCloseOutline size='25' onClickCapture={() => {
          setDisplayReadChecking(false);
        }} />
      </div>
      <div className='main-content-element'>
        <h2>Para prosseguir, escolha entre Cancelar ou Confirmar a visualização do documento</h2>
      </div>
      <div className='button-confirm-content'>
        <button className='button-cancel button' onClickCapture={() => {
          setDisplayReadChecking(false);
        }}>cancelar</button>
        {isLoading
          ? (
            <LoadContent
              isLoading={isLoading}
              style={
                {
                  button: {
                    width: 30,
                    marginRight: 40,
                  },
                  container: {
                    justifyContent: 'flex-end'
                  }
                }
              }
            />
          )
          : (
            <button className='button-confirm button' onClickCapture={setCheckingHuddle} >confirmar</button>
          )}
      </div>
    </div>
  )
}
