import React from 'react';
import Table from '../Table';
import './style.scss';

interface StrategyProps {
  id: string
  display: string
}

export default function KindergartenSchoolMonthlyPlan({ id, display }: StrategyProps): React.ReactElement {
  return (
		<div className={'kindergarten-school-monthly-container'} id={id}>
			<Table display={display}/>
		</div>
  );
}
