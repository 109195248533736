import type { StrategyOfPlanProtocol } from '../../../../class/types/Strategy-of-plan/Strategy-of-plan-factory-protocol';
import type ActionInformation from '../type/action-information';

export default function returnInformation(actions: StrategyOfPlanProtocol[]): ActionInformation {
  const sizeAction = actions.length;
  let notInitialized = 0;
  let accomplished = 0;
  let inProgress = 0;
  let notFinished = 0;
  actions.forEach(action => {
    if (action.situation === 'não inicializado') {
      notInitialized++;
    }

    if (action.situation === 'realizada') {
      accomplished++;
    }

    if (action.situation === 'em andamento') {
      inProgress++;
    }

    if (action.situation === 'não finalizada') {
      notFinished++;
    }
  });

  return {
    sizeAction,
    notInitialized,
    accomplished,
    inProgress,
    notFinished
  };
}
