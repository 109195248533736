/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
export default function filterElements(valueKey: string, elements: any[], value: string, otherKey?: string, byYear?: boolean): any[] {
  const elementFiltered: any[] = [];

  if (value === '') {
    return [];
  }

  if (otherKey) {
    elements.forEach(element => {
      const elementSlice = element[valueKey][otherKey].slice(0, value.length).toLowerCase();

      const valueLowerCase = value.toLowerCase();
      if (elementFiltered.length >= 5) {
        return;
      }

      if (valueLowerCase === element[valueKey][otherKey]) {
        elementFiltered.push(element);
        return;
      }

      if (byYear === true && valueLowerCase === element[valueKey][otherKey].split('/')[2]) {
        elementFiltered.push(element);
        return;
      }

      if (valueLowerCase === elementSlice) {
        elementFiltered.push(element);
      }
    });
    return elementFiltered;
  }

  elements.forEach(element => {
    const elementSlice = element[valueKey].slice(0, value.length).toLowerCase();

    const valueLowerCase = value.toLowerCase();
    if (elementFiltered.length >= 5) {
      return;
    }

    if (valueLowerCase === element[valueKey]) {
      elementFiltered.push(element);
      return;
    }

    if (byYear === true && valueLowerCase === element[valueKey].split('/')[2]) {
      elementFiltered.push(element);
      return;
    }

    if (valueLowerCase === elementSlice) {
      elementFiltered.push(element);
    }
  });

  return elementFiltered;
}
