import React from 'react';
import Table from '../table';
import './styles.scss';

interface MatheMaticsDiagnosticAssessmentGeneralProps {
  display: string
}

export default function MathematicsProblemSolvingGeneral({display}: MatheMaticsDiagnosticAssessmentGeneralProps): React.ReactElement {
  return (
    <div className='mathematics-problem-solving-general-container'>
      <Table display={display} />
    </div>
  )
}
