/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {useState} from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import LoadContent from '../Load-content';
import programmaticContentApi from '../../class/ProgrammaticContent/programmaticContentApi';
import { useParams } from 'react-router-dom';
import type ProgrammaticContentResProtocol from '../../class/types/ProgrammaticContent/ProgrammaticContentApi-protocol';

interface ReadCheckingProps {
  displayReadChecking: boolean
  elementId: string
  activityInfo: Record<string, string>
  setElementaryDecentWork: React.Dispatch<React.SetStateAction<ProgrammaticContentResProtocol[]>>
  setDisplayReadChecking: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ReadCheckingWeeklyPlan({displayReadChecking, activityInfo, elementId, setElementaryDecentWork, setDisplayReadChecking}: ReadCheckingProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {id} = useParams();

  const setCheckingHuddle = () => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    programmaticContentApi.setReadChecking(id, activityInfo.matterId, activityInfo.twoMonths, elementId).then(response => {
      setElementaryDecentWork(response);
      setDisplayReadChecking(false);
      setIsLoading(false);
    });
  }

  return (
    <div className={`read-checking-element display-${String(displayReadChecking)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          confirmação de visualização
        </h1>
        <IoCloseOutline size='25' onClickCapture={() => {
          setDisplayReadChecking(false);
          setIsLoading(false);
        }} />
      </div>
      <div className='main-content-element'>
        <h2>Para prosseguir, escolha entre Cancelar ou Confirmar a visualização do conteúdo</h2>
      </div>
      <div className='button-confirm-content'>
        <button className='button-cancel button' onClickCapture={() => {
          setDisplayReadChecking(false);
          setIsLoading(false);
        }}>cancelar</button>
        {isLoading
          ? (
            <LoadContent
              isLoading={isLoading}
              style={
                {
                  button: {
                    width: 30,
                    marginRight: 40,
                  },
                  container: {
                    justifyContent: 'flex-end'
                  }
                }
              }
            />
          )
          : (
            <button className='button-confirm button' onClickCapture={setCheckingHuddle} >confirmar</button>
          )}
      </div>
    </div>
  )
}
