/* eslint-disable prefer-const */

import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type StudentReportPut, type StudentReportType } from '../types/StudentReport';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';

class UploadElementarySchoolStudentReportApi {
	private readonly user = lscache.get('user') as UserClientProtocol & {token: string};
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, classRoom, twoMonths, date, name, userName, userId }: any): Promise<StudentReportType[]> {
		let newElementaryStudentReport: StudentReportType[] = [];

		const formDate = new FormData();

		formDate.append('fileStudentReport', file);
    formDate.append('twoMonths', twoMonths);
    formDate.append('classRoom', classRoom);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/room/elementaryschool/studentReport',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementaryStudentReport = response.data as StudentReportType[];
		});

		return newElementaryStudentReport;
	}

  async setChecking(HTTPIId: string): Promise<StudentReportType[]> {
    let newElementaryStudentReport: StudentReportType[] = [];

    await axios.put(`${this.href}/checkout/room/elementaryschool/studentReport/${HTTPIId}`, {
      userId: this.user.id,
      userName: this.user.userName,
      date: this._date,
    }).then(response => {
      newElementaryStudentReport = response.data;
    });

    return newElementaryStudentReport;
  }

	async put({ file, name, studentReportId }: StudentReportPut): Promise<StudentReportType[]> {
		let newElementaryStudentReport: StudentReportType[] = [];

		const formData = new FormData();

		formData.append('fileStudentReport', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/room/elementaryschool/studentReport/${studentReportId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementaryStudentReport = response.data as StudentReportType[];
		});

		return newElementaryStudentReport;
	}

	async get(): Promise<StudentReportType[]> {
		let newElementaryStudentReport: StudentReportType[] = [];

		await axios.get(this.href + '/file/room/elementaryschool/studentReport').then(response => {
			newElementaryStudentReport = response.data as StudentReportType[];
		}).catch(e => {
			//
		});

		return newElementaryStudentReport;
	}

	async delete(elementId: string, fileName: string): Promise<StudentReportType[]> {
		let newElementaryStudentReport: StudentReportType[] = [];

		await axios.delete(this.href + `/file/room/elementaryschool/studentReport/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			newElementaryStudentReport = response.data as StudentReportType[];
		}).catch(e => {
			//
		});

		return newElementaryStudentReport;
	}

  async getData(teacherId: string): Promise<StudentReportType[]> {
    let newElementaryStudentReport: StudentReportType[] = [];

    await axios.get(this.href + `/file/room/elementaryschool/studentReport/get_by_user/${teacherId}`).then(response => {
      newElementaryStudentReport = response.data as StudentReportType[];
    }).catch(e => {
      //
    });

    return newElementaryStudentReport;
  }
}

export default new UploadElementarySchoolStudentReportApi();
