import { type StudentType } from '../../../class/types/Student/student-factory-protocol';

export default function searchStudent(searchName: string, students: StudentType[]): StudentType[] {
  const searchNameFormatted = searchName.toLowerCase().replaceAll(' ', '');
  const studentFiltered: StudentType[] = [];

  students.map((student) => {
    const studentNameFormatted = student.studentName.slice(0, searchNameFormatted.length).toLowerCase().replaceAll(' ', '');

    if (searchNameFormatted.includes(studentNameFormatted)) {
      studentFiltered.push(student);
    }
    return student
  });
  return studentFiltered.slice(0, 5);
}
