
import lscache from 'lscache';
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import filterById from '../../container/actions-file/Table-actions/services/filterById';
import returnInformation from './services/returnInformation';
import './style.scss';
import type ActionInformation from './type/action-information';

interface DashboardProps {
	display: string
}

export default function Dashboard({ display }: DashboardProps): React.ReactElement {
	const [actionInformation, setActionInformation] = useState<ActionInformation>();
	const { id } = useParams();

	useEffect(() => {
		const actions = lscache.get('actions') as any[];
		if (actions) {
			const newAction = filterById(actions, String(id));

			const actionInformation = returnInformation(newAction);

			setActionInformation(actionInformation);
		}
	}, [display]);

	const returnPercentage = (actionInformation: number | undefined, actionSize: number | undefined): number => {
		if (!actionInformation || !actionSize) {
			return 0;
		}

		const porcentagem = (actionInformation / actionSize) * 100;

		return Math.floor(porcentagem);
	};

  if (display === 'false') {
    return <></>
  }

	return (
		<div className='dashboard-element'>
			<div className='dashboard-content'>
				<Chart
					type='pie'
					width={650}
					height={700}
					series={[
						returnPercentage(actionInformation?.notFinalize, actionInformation?.sizeAction),
						returnPercentage(actionInformation?.initialized, actionInformation?.sizeAction),
						returnPercentage(actionInformation?.inProgress, actionInformation?.sizeAction),
						returnPercentage(actionInformation?.finalized, actionInformation?.sizeAction)
					]}
					options={{
						labels: ['Não inicializado', 'inicializado', 'Em andamento', 'Finalizado'],
						colors: ['#FF2E00', '#048eff', '#ffd700', '#118951'],
						legend: {
							fontSize: '16rem',
							offsetY: 70
						},
						chart: {
							width: '50px',
							height: '50px'
						},
						title: {
							align: 'center',
							margin: 30,
							style: {
								fontSize: '2rem'
							},
							text: 'Dashboard do andamento das ações'
						}
					}}
				/>
			</div>

		</div>
	);
}
