/* eslint-disable no-multi-spaces */

import React, { useState } from 'react';
import './style.scss';
import Header from '../../components/Header';
import Nav from '../../components/psychologist-files/Nav-psychologist';
import PsychologistStudentRegister from '../../container/Psychologist-student-register';
import PsychologistMonitoring from '../../container/Psychologist-monitoring';
import PsychologistReport from '../../container/psychologist-report/psychologist-report';
import StudentExamMain from '../../container/student-exam/student-exam-main';

type Elements = Array<{
  name: string
  boolean: string
}>;

function StudentExam() {
  const [elements, setElements] = useState([
    {
      name: 'strategy-1',
      boolean: 'true'
    },
  ]);

  const [display1, setDisplay1] = useState<string>('true');

  // const hundlesetElements = (elementsParams: Elements) => {
  //   setElements(elementsParams);
  //   setDisplay1(elements[0].boolean);
  // };

  return (
		<div className='App'>
			<Header />
			<main className='main-container-plans'>
				<div className='content-containers'>
					<div className='content' id={`display-${display1}`}>
						<StudentExamMain />
					</div>
				</div>
			</main>
		</div>
  );
}

export default StudentExam;
