
class ProgrammaticContentFactory {
	constructor(private readonly textarea: HTMLTextAreaElement) {}

	create() {
		return {
      date: this.date(),
			curriculumComponent: this.textarea.value,
		};
	}

	createPut() {
		return {
			curriculumComponent: this.textarea.value,
		};
	}

  date() {
    return new Date().toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    })
  }
}

export default ProgrammaticContentFactory;
