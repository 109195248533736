import Validator from '../Validator';
import PlanAPI from './PlanAPI';
import type { PlanType } from '../types/Plan/plansApi-protocol';
import PlansFactory from './plans-factory';

class PlanCustumer {
	private readonly planApi = PlanAPI;

	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>
	) {}

	async post(): Promise<any> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return [{
				error: true
			}];
		}

		let plans: PlanType[] = [];
		const plansFactory = new PlansFactory(this.inputs);

		const plan = plansFactory.create();

		await this.planApi.post(plan).then(response => {
			plans = response;
		});

		return plans;
	}

	async put(id: string): Promise<PlanType[]> {
		let actions: PlanType[] = [];

		const strategyFactory = new PlansFactory(this.inputs);

		const actionPut = strategyFactory.createPut();

		await this.planApi.put(actionPut, id).then((response: any[]) => {
			actions = response as PlanType[];
		});

		return actions;
	}
}

export default PlanCustumer;
