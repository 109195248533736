/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { type JustificationOfAbsencesType } from '../../../class/types/JustificationOfAbsences';
import React, { useEffect, useState } from 'react';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';
import LoadContent from '../../Load-content';
import lscache from 'lscache';
import './style.scss';
import studentObserverApi from '../../../class/StudentObserver/student-observer-api';

interface ContentTableType {
  studentObserver: JustificationOfAbsencesType
  index: number
  roomInformation: Record<string, string>
  setDisplayElementInformation: React.Dispatch<React.SetStateAction<boolean>>
  setStudentObserver: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setElement: React.Dispatch<React.SetStateAction<JustificationOfAbsencesType | undefined>>
}

export default function ContentTableStudentObserver({ studentObserver, setElement, setDisplayElementInformation, setStudentObserver, setDisplayEditElement, setElementId, roomInformation, index}: ContentTableType): React.ReactElement {
  const { id } = useParams();
  const [user, setUser] = useState<UserClientProtocol>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const huddleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(studentObserver);
    setDisplayElementInformation(true);
  }

  const huddleDelete = () => {
    if (studentObserver.id && typeof id === 'string') {
      setIsLoading(true);

      studentObserverApi.delete(id, roomInformation.matterId, roomInformation.twoMonths, studentObserver.id).then(response => {
        setIsLoading(false);
        setStudentObserver(response);
      });
    }
  };

  const huddleEdit = () => {
    setDisplayEditElement(true);
    setElementId(String(studentObserver.id));
  };

  useEffect(() => {
    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, []);

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [roomInformation]);

  return (
		<div className={`content-table-element-student-observer display-animation-${displayAnimation}`} onClick={huddleClick}>
			<ul className='content-table-ul-rom'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{studentObserver.student.name ?? ''}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{studentObserver.issuer.date.split(',')[0] ?? ''}
					</p>
				</li>
				<li className='header-table-ul-li' onClickCapture={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>

          )}
			</ul>
		</div>
  );
}
