/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useEffect, useState } from 'react';
import type { StudentWritingLRRes, TagTitleList, TagType } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import { IoIosArrowDown } from 'react-icons/io';
import { BsCheckLg } from 'react-icons/bs';
import './style.scss';
import lscache from 'lscache';

interface ContentTableType {
  student: StudentType
  index: number
  activityInfo: any
  displayList: TagTitleList[]
  writingLevel: StudentWritingLRRes
  writingLevelTags: any[]
}

interface TagSelectContentProps {
  defaultOption: string
  student: StudentType
  activityInfo: any
  tags: TagType[]
  adicionalTag: string[]
}

function TagSelectContent({ defaultOption, student, tags, activityInfo, adicionalTag }: TagSelectContentProps): React.ReactElement {
  const [displayOption, setDisplayOption] = useState(false);
  const [option, setOption] = useState<string>('');

  const huddleOption = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    setOption(target.textContent ?? '');
    setDisplayOption(false);
  }

  useEffect(() => {
    setOption('');
    setOption(defaultOption);
  }, [defaultOption, activityInfo]);

  return (
    <li className='input-select-content'>
      <label onClickCapture={() => {
        setDisplayOption(!displayOption)
      }} className='label-select-input-content' htmlFor={`${student.id}`}>
        <h1>{!option ? 'Nível de escrita' : option}</h1>
        <IoIosArrowDown className={'icon-arrow display-'} size='25' color='rgb(172, 172, 172)' />
      </label>
      <input type='text' id={`${student.id}`} value={option} />
      <ul className={`options-select-content display-${displayOption}`}>
        {tags.map((writingLevelTag, index) => (
          <li onClickCapture={huddleOption} key={`writing-Level-tag-${index}`}>
            {writingLevelTag.name}
          </li>
        ))}
        {adicionalTag.map(value => {
          return (
            <li onClickCapture={huddleOption}>
              {value}
            </li>
          )
        })}
      </ul>
    </li>
  );
}

export default function ContentTableStudentWritingLevelRecord({ student, writingLevel, writingLevelTags, displayList, activityInfo, index }: ContentTableType): React.ReactElement {
  const [user, setUser] = useState<UserClientProtocol>();
  const [displayOptionOne, setDisplayOptionOne] = useState(false);
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);
  const [optionOne, setOptionOne] = useState('');
  const [checked, setChecked] = useState(false);
  const [writingLevelSearched, setWritingLevelSearched] = useState<any>(undefined)

  useEffect(() => {
    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, []);

  const huddleClick = () => {
    try {
      const user = lscache.get('user') as UserClientProtocol;
      if (user.permission?.rule === 'professor') {
        return;
      }
    } catch (e) {
      return;
    }

    setChecked(!checked);
  };

  const huddleDisplayOptions = (set: React.Dispatch<React.SetStateAction<boolean>>, value: boolean) => {
    try {
      const user = lscache.get('user') as UserClientProtocol;
      if (user.permission?.rule === 'professor') {
        return;
      }
    } catch (e) {
      return;
    }

    set(!value);
  };

  const huddleClickLi = (e: React.MouseEvent<HTMLLIElement>, setOption: React.Dispatch<React.SetStateAction<string>>, setDisplayOption: React.Dispatch<React.SetStateAction<boolean>>) => {
    const li = e.target as HTMLLIElement;
    setOption(li.textContent ?? '');
    setDisplayOption(false);
  };

  useEffect(() => {
    try {
      const writingLevelSearched = writingLevel.find(value => value.studentId === student.id);
      if (!writingLevelSearched) {
        setOptionOne('');
        setChecked(false);
        setWritingLevelSearched(undefined)
        return;
      }
      setOptionOne(writingLevelSearched.writingLevel);
      setChecked(writingLevelSearched.writeTheName);
      setWritingLevelSearched(writingLevelSearched);
      return;
    } catch {
      setWritingLevelSearched(undefined);
      setOptionOne('');
      setChecked(false);
    }
  }, [writingLevel]);

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [student, activityInfo]);

  return (
    <div className={`content-table-element-student-writing-level-record display-animation-${displayAnimation}`}>
      <ul className='content-table-ul-rom'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            {student.studentName}
          </p>
        </li>
        <li className='input-radio-content'>
          <label className='radio-input-style-element' htmlFor={`student-radio-input ${student.id}`} onClickCapture={huddleClick}>
            <div className={`icon-content display-${checked}`}>
              <BsCheckLg className='icon' />
            </div>
          </label>
          <input type='checkbox' checked={checked} id={`student-radio-input ${student.id}`} />
        </li>
        {displayList.map((value, index) => {
          if (index === 0) {
            return <TagSelectContent
              student={student}
              defaultOption={optionOne}
              activityInfo={activityInfo}
              tags={value.tags ?? writingLevelTags}
              adicionalTag={['Transferido', 'Não Realizado']}
            />
          }
          return <TagSelectContent
            activityInfo={activityInfo}
            student={student}
            defaultOption={(!writingLevelSearched || !writingLevelSearched[`element${index}`]) ? 'NR' : writingLevelSearched[`element${index}`]}
            tags={value.tags ?? []}
            adicionalTag={['TR']}
          />
        })}
      </ul>
    </div>
  );
}
