import type { PlanType } from '../types/Plan/plansApi-protocol';

class PlansFactory {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>
	) {}

	create(): PlanType {
		return {
			planName: this.inputs[0].value,
			timeline: this.inputs[1].value
		};
	}

	createPut(): PlanType {
		return {
			planName: this.verifyValue(0),
			timeline: this.verifyValue(1)
		};
	}

	verifyValue(index: number): string {
		return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
	}
}

export default PlansFactory;
