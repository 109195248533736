/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import './style.scss';
import AddStudentToMonitoring from './add-student-to-monitoring';
import PsychologistApi from '../../class/Psychologist';
import LoadContent from '../../components/Load-content';
import TableContentPsychologistStudentRegister from '../../components/psychologist-files/table-content';
import EmptyComponent from '../../components/Empty-component';
import { type PsychologistAnamnese } from '../../class/types/Psychologist';
import StudentInfo from './info-student';
import EditStudentToMonitoring from './edit-student-to-monitoring';

interface PsychologistStudentRegisterProps {
  display: string
}

export default function PsychologistStudentRegister({ display }: PsychologistStudentRegisterProps): React.ReactElement {
  const [displayAddStudent, setDisplayAddStudent] = useState(false);
  const [studentPsychologist, setStudentPsychologist] = useState<PsychologistAnamnese[]>([]);
  const [studentSelected, setStudentSelected] = useState<PsychologistAnamnese>();
  const [displayStudentInfo, setDisplayStudentInfo] = useState<boolean>(false);
  const [displayEdit, setDisplayEdit] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const psychologist = new PsychologistApi();

    setIsLoading(true);
    psychologist.getAllStudent().then(response => {
      setStudentPsychologist(response);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  }, [display]);

  useEffect(() => {
  }, [displayStudentInfo]);

  return (
    <section className='psychologist-student-register'>
      <AddStudentToMonitoring display={displayAddStudent} setStudentPsychologist={setStudentPsychologist} setDisplay={setDisplayAddStudent} />
      <EditStudentToMonitoring display={displayEdit} setDisplay={setDisplayEdit} setStudentPsychologist={setStudentPsychologist} studentSelected={studentSelected} />
      <StudentInfo displayStudentInfo={displayStudentInfo} setDisplayStudentInfo={setDisplayStudentInfo} student={studentSelected} />
      <header className='psychologist-student-register-header'>
        <div className='psychologist-student-register-header-title'>
          <h1>Anamnese</h1>
          <h1>Anamnese registradas: {studentPsychologist.length}</h1>
        </div>
        <div className='button-content'>
          <button type="button" onClickCapture={() => {
            setDisplayAddStudent(!displayAddStudent);
          }}>Registrar Anamnese</button>
        </div>
      </header>
      <div className='section-student-list'>
        <div className='section-student-list-header'>
          <ul>
            <li>Nome do estudante</li>
            <li>Nome do Mãe</li>
            <li>Nome do Pai</li>
            <li>Data de Nascimento</li>
            <li></li>
            <li></li>
          </ul>
        </div>
        {isLoading
          ? (
            <LoadContent isLoading={isLoading} style={{container: {marginTop: '4rem'}}} />
          )
          : <div className='section-student-list-table'>
            {studentPsychologist.length !== 0
              ? (
                studentPsychologist.map(student => {
                  return <TableContentPsychologistStudentRegister setDisplayEdit={setDisplayEdit} setDisplayStudentInfo={setDisplayStudentInfo} setStudentSelected={setStudentSelected} student={student} setStudentPsychologist={setStudentPsychologist} />;
                })
              )
              : <EmptyComponent>Nenhum aluno foi registrado</EmptyComponent>}
          </div>}
      </div>
    </section>
  )
}
