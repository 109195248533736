
import Validator from '../Validator';
import { type UploadRoomObserverSheetType } from '../types/Upload_room-observer-sheet-api';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import UploadRoomObserverSheetApi from './room-observer-sheet-api';
import lscache from 'lscache';

class UploadRoomObserverSheetCostumer {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement> | undefined = undefined,
    private readonly classRoomId: string = '',
    private readonly classRoomName: string = '',
		private readonly roomObserverSheetId: string = '',
		private readonly textArea: HTMLTextAreaElement | undefined = undefined
	) {}

	get _user(): UserClientProtocol {
		const user = lscache.get('user') as UserClientProtocol;

		return user;
	}

	async post(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: true
			};
		}

		const file = this.inputs.item(1).files?.item(0);

		if (!file) {
			return {
				error: true
			};
		}

		const fileName = file.name ?? '';
		const date = this.inputs.item(0).value;

		const user = this._user;

		let roomObserverSheet: UploadRoomObserverSheetType[] = [];

		await UploadRoomObserverSheetApi.post({
			file,
      classRoomId: this.classRoomId,
      classRoom: this.classRoomName,
			date,
			name: fileName,
			userId: user.id ?? '',
			userName: user.userName
		}).then(response => {
			roomObserverSheet = response;
		}).catch(e => {
			//
		});

		return roomObserverSheet;
	}

	// async postDescription(): Promise<any> {
	// 	if (!this.textArea) {
	// 		return {
	// 			error: true
	// 		};
	// 	}

	// 	if (!this.textArea?.value) {
	// 		Validator.displayError(this.textArea, 'o campo esta vazio');
	// 		return {
	// 			error: true
	// 		};
	// 	}

	// 	const description = this.textArea.value;
	// 	let newElementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType[] = [];

	// 	await UploadWeeklyPlankApi.postDescription(this.decentWorkId, description).then(response => {
	// 		newElementarySchoolWeeklyPlan = response;
	// 	});

	// 	return newElementarySchoolWeeklyPlan;
	// }

	async put(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: false
			};
		}

		const file = this.inputs.item(0).files?.item(0);

		if (!file) {
			return {
				error: false
			};
		}

		const fileName = file.name ?? '';

		let roomObserverSheet: UploadRoomObserverSheetType[] = [];

		await UploadRoomObserverSheetApi.put({
			file,
			name: fileName,
			roomObserverSheetId: this.roomObserverSheetId,
      classRoomId: this.classRoomId,
		}).then(response => {
			roomObserverSheet = response;
		});

		return roomObserverSheet;
	}
}

export default UploadRoomObserverSheetCostumer;
