/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';

import UpLoadElementarySchoolWeeklyPlanCostumer from '../../../class/UploadElementarySchoolSchoolWeeklytWork/upload-weekly-plan-custumer';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { DisplayFileInfoTwo } from '../../../components/DisplayFile';
import { IoCloseOutline } from 'react-icons/io5';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import uploadPsychologistApi from '../../../class/UploadPsychologistReport/upload-psychologist-api';
import UploadPsychologistCostume from '../../../class/UploadPsychologistReport/upload-psychologist-custumer';

interface FormEditPsychologistProps {
  setDisplayFormEditPsychologistReport: React.Dispatch<React.SetStateAction<boolean>>
  setPsychologistReport: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  displayFormEditPsychologistReport: boolean
  elementId: string
}

export default function FormEditPsychologistReport({ setDisplayFormEditPsychologistReport, setPsychologistReport, displayFormEditPsychologistReport, elementId }: FormEditPsychologistProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormEditPsychologistReport(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const psychologistCostumer = new UploadPsychologistCostume(inputs, {} as UserClientProtocol);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    psychologistCostumer.put(elementId).then(response => {
      setPsychologistReport(response);
      setIsLoading(false);
      setDisplayFormEditPsychologistReport(false);
      clearForm(e);
    }).catch(e => {
      //
    });
  };

  const clearForm = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.target as HTMLFormElement;
    const inputs = form.querySelectorAll('input');

    inputs.item(0).value = ''
  }

  const inputFileEditHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  return (
    <div className={`form-edit-psychologist-report display-${String(displayFormEditPsychologistReport)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Editar Relatório
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormEditPsychologistReport(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-edit-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-file-element">
          <label htmlFor="file-edit-input-elementary-school-weekly-plan">
            <DisplayFileInfoTwo file={fileInfo} />
          </label>
          <input
            type="file"
            id='file-edit-input-elementary-school-weekly-plan'
            onChangeCapture={inputFileEditHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={{
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }}
                />
              )
              : (
                <>
                  <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                  <button type='submit' className='save-button'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
