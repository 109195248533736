/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import { type JustificationOfAbsencesType } from '../../../class/types/JustificationOfAbsences';

interface FormAddElementProps {
  displayElementInformation: boolean
  element: any | undefined
  setDisplayInformation: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DisplayInformationJustificationOfAbsences({displayElementInformation, element, setDisplayInformation}: FormAddElementProps): React.ReactElement {
  const { id } = useParams();

  const huddleClickClose = (clear?: boolean) => {
    setDisplayInformation(false);

    if (clear) {
      clearAllInputs();
    }
  };

  const formatDate = (_date: string | undefined) => {
    if (!_date) {
      return ''
    }
    return (new Date(_date)).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short',
    });
  }

  return (
    <div className={`display-information-justification-of-absences-content display-${displayElementInformation}`}>
      <div className='display-information-justification-of-absences'>
        <div className='header-form-add-element-content'>
          <h1>
            Observação
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <div className='information-content'>
          <h1 className='title-content'>Observação - aluno: <span>{element?.student.name}</span></h1>
          <div className='information-element'>
            <h1>Emissor: <span>{element?.issuer.name}</span></h1>
          </div>
          <div className='information-element'>
            <h1>Data de emissão: <span>{element?.issuer.date}</span></h1>
          </div>
          <div className='information-element'>
            <h1>Observação: <span>{element?.observation}</span></h1>
          </div>
        </div>
      </div>
    </div>
    )
}
