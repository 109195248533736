
/* eslint-disable react/jsx-key */

import React, { useRef, useState } from 'react';
import filterElements from './service/filter';
import './style.scss';

interface SearchElementType {
  elements: any[] | undefined
  elementKey: string
}

export default function SearchElementAddForm({ elements, elementKey }: SearchElementType): React.ReactElement {
  const input = useRef<any>();
  const [options, setOptions] = useState<any[]>([]);

  const huddleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!elements) {
      return;
    }

    const elementFiltered = filterElements(elements, value);

    if (elementFiltered.length >= 1) {
      setOptions(elementFiltered);
    } else {
      setOptions([]);
    }
  };

  const huddleClick = (ra: string) => {
    if (!ra) {
      return;
    }

    if (!elements) {
      return;
    }

    const elementFiltered = filterElements(elements, ra);

    if (elementFiltered.length > 1) {
      setOptions(elementFiltered);
    } else {
      setOptions([]);
    }

    input.current.value = ra;
  };

  return (
		<div className='search-element-add-form'>
			<div className='header-input-content'>
				<label htmlFor='input-content-search'>Ra do aluno:</label>
			</div>
			<div className='input-content'>
				<input type='text' id='input-content-search' placeholder='Procurar aluno por ra' ref={input} onChange={huddleChange} autoComplete='off' />
			</div>
			<div className='options-list'>
				<ul className='list-element'>
					{options
					  ? options.map(element => <li onClick={() => {
					  huddleClick(element.ra.content);
					  }}>
						<span>{element.ra.content}</span>
						<span>{element.studentName}</span>
					</li>)
					  : ''}
				</ul>
			</div>
		</div>
  );
}
