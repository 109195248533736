/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-floating-promises */
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import type DigitalHTTPIResProtocol from '../../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';

const img = require('./img/header-img.png');

export default function generatePDF(digitalHTTPI: DigitalHTTPIResProtocol) {
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      month: 'long',
      year: 'numeric'
    });
  }

  const pdf = new jsPDF({
    orientation: 'portrait',
  });

  const element = document.getElementById('day-of-httpi-table') as HTMLDivElement;

  html2canvas(element).then(canvas => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(img, 'PNG', 15, 5, 180, 0);

    pdf.addImage(imgData, 'PNG', 5, 40, 200, 0);

    pdf.save(`HTTPI mes de ${!digitalHTTPI.date ? '' : formatDate(digitalHTTPI.date).split(' ')[0]} - ${!digitalHTTPI.date ? '' : formatDate(digitalHTTPI.date).split(' ')[2]} ${digitalHTTPI.issue?.name}.pdf`);
  });
}
