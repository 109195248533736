/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-key */
import React from 'react';
import './styles.scss';
import { type QuestionElementType } from '../../../class/types/student-exams/student-exams-protocol';

interface ExamOptionContentProps {
  element: QuestionElementType
  questionId: string
  elementId: string
}

export default function ExamOptionContent({ element, questionId, elementId }: ExamOptionContentProps): React.ReactElement {
  return (
    <div className='exam-option-content'>
      <ul className='exam-option-content-ul'>
        {Object.keys(element.options ?? {}).map((key) => {
          if (!element.options) {
            return <></>;
          }
          return (
            <li>
              <input type="radio" id={`${questionId} ${elementId} ${key}`} name={elementId} required/>
              <label htmlFor={`${questionId} ${elementId} ${key}`}>{element.options[key].content}</label>
            </li>
          )
        })}
      </ul>
    </div>
  );
}
