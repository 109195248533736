import type MathematicsProblemSolvingTagType from '../types/MathematicsProblemSolving';
import { type MathematicsProblemSolvingObjType } from '../types/MathematicsProblemSolving';
import { type StudentType } from '../types/Student/student-factory-protocol';

export default class MathematicsProblemSolvingFactory {
  constructor(private readonly input: HTMLInputElement | undefined) {}

  createTag(): MathematicsProblemSolvingTagType {
    return {
      content: this.input?.value ?? '',
      date: this.getDate(),
    }
  }

  getDate(): string {
    return new Date().toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short',
    });
  }

  createMathObject(inputs: NodeListOf<HTMLInputElement>, students: StudentType[]): MathematicsProblemSolvingObjType {
    const mathObject: MathematicsProblemSolvingObjType = {};

    students.forEach(student => {
      if (!student.id) {
        return;
      }

      mathObject[student.id] = {
        studentId: student.id,
        studentName: student.studentName,
        mathematicsProblemSolving: [],
      };

      inputs.forEach(input => {
        const ids = input.id.split(' ');

        if (student.id === ids[0]) {
         mathObject[student.id].mathematicsProblemSolving.push({
          content: input.value,
          tagId: ids[1],
         })
        }
      });
    });

    return mathObject;
   }
}
