
import axios from 'axios';
import { type ElementarySchoolDecentWorkPut, type ElementarySchoolDecentWorkPost, type ElementarySchoolDecentWorkType } from '../types/Upload_ElementarySchoolDecenteWork';
import type ElementarySchoolDecentWorkApi from '../types/Upload_ElementarySchoolDecenteWork';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';

class UploadKindergartenSchoolDecentWorkApi implements ElementarySchoolDecentWorkApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, classRoom, date, name, userName, userId }: ElementarySchoolDecentWorkPost): Promise<ElementarySchoolDecentWorkType[]> {
		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		const formDate = new FormData();

		formDate.append('fileDecentWork', file);
    formDate.append('classRoom', classRoom);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/kindergartenschool/decentWork',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolDecentWorkType[];
		});

		return newElementarySchoolDecentWork;
	}

	async postDescription(decentWorkId: string, description: string): Promise<ElementarySchoolDecentWorkType[]> {
		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		await axios.post(`${this.href}/description/kindergartenschool/decentWork/${decentWorkId}`, {
			description
		}).then(response => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolDecentWorkType[];
		});

		return newElementarySchoolDecentWork;
	}

	async setChecking(decentWorkId: string): Promise<ElementarySchoolDecentWorkType[]> {
		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		await axios.post(`${this.href}/checking/kindergartenschool/decentWork/${decentWorkId}`).then(response => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolDecentWorkType[];
		});

		return newElementarySchoolDecentWork;
	}

	async put({ file, name, decentWorkId }: ElementarySchoolDecentWorkPut): Promise<ElementarySchoolDecentWorkType[]> {
		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		const formData = new FormData();

		formData.append('fileDecentWork', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/kindergartenschool/decentWork/${decentWorkId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolDecentWorkType[];
		});

		return newElementarySchoolDecentWork;
	}

	async get(): Promise<ElementarySchoolDecentWorkType[]> {
		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		await axios.get(this.href + '/file/kindergartenschool/decentWork').then(response => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolDecentWorkType[];
		}).catch(e => {
			//
		});

		return newElementarySchoolDecentWork;
	}

	async delete(elementId: string, fileName: string): Promise<ElementarySchoolDecentWorkType[]> {
		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		await axios.delete(this.href + `/file/kindergartenschool/decentWork/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolDecentWorkType[];
		}).catch(e => {
			//
		});

		return newElementarySchoolDecentWork;
	}
}

export default new UploadKindergartenSchoolDecentWorkApi();
