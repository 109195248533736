/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import searchStudentByName, { studentNameIsEqual } from './service/search-student-by-name';
import { type JustificationOfAbsencesType } from '../../../class/types/JustificationOfAbsences';
import JustificationOfAbsencesCostumer from '../../../class/JustificationOfAbsences/JustificationOfAbsences-custumer';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  student: StudentType[]
  classRoomInfo: Record<string, string>
  displayEditElement: boolean
  justificationOfAbsences: JustificationOfAbsencesType[]
  elementId: string
  setDisplayEditElement: (_boolean: React.SetStateAction<boolean>) => void
  setJustificationOfAbsences: React.Dispatch<React.SetStateAction<JustificationOfAbsencesType[]>>
}

export default function FormEditJustificationOfAbsences({ student, classRoomInfo, elementId, displayEditElement, justificationOfAbsences, setDisplayEditElement, setJustificationOfAbsences }: FormAddElementProps): React.ReactElement {
  const [options, setOptions] = useState<StudentType[]>();
  const [option, setOption] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>();
  const inputStudentName = useRef<any>();

  const inputJustification = useRef<any>();
  const inputDay = useRef<any>();
  const inputBeforeDate = useRef<any>();
  const inputAfterDate = useRef<any>();

  const { id } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (option === null || !id) {
      return;
    }

    const target = e.target as HTMLFormElement;

    const input = target.querySelectorAll('input');

    const justificationOfAbsences = new JustificationOfAbsencesCostumer(
      input,
      option,
      id,
      classRoomInfo.matterId,
      classRoomInfo.twoMonths,
      elementId,
    );
    if (typeof id !== 'string') {
      return;
    }

    setIsLoading(true);
    justificationOfAbsences.put().then(response => {
      setJustificationOfAbsences(response);
      setDisplayEditElement(false);
      clearAllInputs();
      setIsLoading(false);
    });
  };

  const huddleOptionClick = (optionSelected: any) => {
    setOption(optionSelected);

    inputStudentName.current.value = optionSelected.studentName;

    setOptions([]);
  }

  const huddleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = (e.target as HTMLInputElement).value;

    const studentIsEqual = studentNameIsEqual(text, student);
    if (studentIsEqual?.studentName) {
      setOptions([]);
      setOption(studentIsEqual);
      return;
    }

    const _student = searchStudentByName(text, student);

    setOptions(_student);
  }

  const huddleClickClose = (clear?: boolean) => {
    setDisplayEditElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  const displayInformation = (element: JustificationOfAbsencesType) => {
    setOption({
      studentName: element.student.name,
      id: element.student.id,
    });

    inputStudentName.current.value = element?.student.name;
    inputJustification.current.value = element?.justification;
    inputDay.current.value = element?.days;
    inputAfterDate.current.value = element.date.afterDate;
    inputBeforeDate.current.value = element.date.beforeDate;
  }

  useEffect(() => {
    const justification = justificationOfAbsences.find((value) => value.id === elementId);

    if (!justification) {
      return;
    }

    displayInformation(justification);
  }, [displayEditElement]);

  return (
    <div className={`form-add-container-justification-of-absences display-${displayEditElement}`}>
      <form method='POST' className='form-add-element-room' id='form-add-element-student-room' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Editar justificativa
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <div className='input-element-content'>
          <label htmlFor="">Justificativa:</label>
          <input type="text" ref={inputJustification} />
        </div>
        <div className='input-element-content'>
          <label htmlFor="">Quantidade de dias</label>
          <input type="number" min='0' ref={inputDay} />
        </div>
        <div className='input-element-content'>
          <label htmlFor="">Nome do Aluno</label>
          <input type="text" onChange={huddleChange} ref={inputStudentName} />
          <ul className="input-element-content-option">
            {!options ? '' : options.map(value => (
              <li onClick={() => {
                huddleOptionClick(value);
              }}>
                <h1>
                  {value.studentName}
                </h1>
              </li>
            ))}
          </ul>
        </div>
        <div className='input-element-content'>
          <div className='input-date-content'>
            <input type="date" ref={inputAfterDate} />
            <span>a</span>
            <input type="date" ref={inputBeforeDate} />
          </div>
        </div>
        <div className='body-form-add-element-content'>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  huddleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Adicionar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
