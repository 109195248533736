import React from 'react'
import './styles.scss';
import TableContent from '../table';
import { type MonitoringSheetGoalType } from '../../../class/types/municipal-education-plan-monitoring-sheet';

interface MunicipalEducationPlaMonitoringSheetStrategyProps {
  goalInfo: MonitoringSheetGoalType | undefined
  display: string
}

export default function MunicipalEducationPlanMonitoringSheetStrategy({display, goalInfo}: MunicipalEducationPlaMonitoringSheetStrategyProps) {
  return (
    <div className='municipal-education-plan-monitoring-sheet-strategy'>
      <TableContent goalInfo={goalInfo} display={display} />
    </div>
  );
}
