import React, { useState } from 'react';
import { type TextualGenreType, type TextualGenreTagType } from '../../../class/types/new-writing-hypothesi/textual-genre';
import LoadContent from '../../Load-content';
import { MdDelete } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';
import './styles.scss';
import TextualGenreTagsApi from '../../../class/new-writing-hypothesi/textual-genre-tags/textual-genre-tags-api';
import { useParams } from 'react-router-dom';

interface TextualGenreTagElementProps {
  index: number
  roomInfo: any
  textualGenreSelected: TextualGenreType | null
  textualGenreTag: TextualGenreTagType
  setDisplayFormEditTag: React.Dispatch<React.SetStateAction<boolean>>
  setTextualGenreTags: React.Dispatch<React.SetStateAction<TextualGenreTagType[]>>
  setTagSelected: React.Dispatch<React.SetStateAction<TextualGenreTagType | null>>
}

export default function TextualGenreTagElement({index, textualGenreTag, roomInfo, textualGenreSelected, setDisplayFormEditTag, setTextualGenreTags, setTagSelected}: TextualGenreTagElementProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const {id} = useParams();

  const huddleDelete = () => {
    const textualGenreTagsApi = new TextualGenreTagsApi();

    if (!id || !textualGenreSelected || !textualGenreTag.id) {
      return;
    }

    setIsLoading(true);
    textualGenreTagsApi.delete(id, roomInfo.twoMonths, textualGenreSelected.id, textualGenreTag.id).then(response => {
      setTextualGenreTags(response);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  }

  const edit = () => {
    setDisplayFormEditTag(true);
    setTagSelected(textualGenreTag);
  }

  return (
    <div className='textual-genre-tag-element'>
      <ul className='textual-genre-tag-element-ul'>
        <li>{index + 1}</li>
        <li>{textualGenreTag.tag}</li>
        <li>{textualGenreTag.date}</li>
        <li className='color-content'>
          <div className='textual-genre-tag-element-color-content' style={{
            backgroundColor: textualGenreTag.color,
            width: 50,
            height: 20,
          }}></div>
        </li>
        <li className='content-table-ul-li'>
          <FiEdit2 size='20' style={{ color: '#118951' }} onClickCapture={edit} />
        </li>
        <li className='header-table-ul-li' onClickCapture={huddleDelete}>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      fontSize: 20,
                      color: '#ff0000'
                    },
                    container: {
                      width: 20,
                    }
                  }
                }
              />
            )
            : (
              <MdDelete size='20' style={{ color: '#ff0000' }} onClickCapture={huddleDelete} />
            )}
        </li>
      </ul>
    </div>
  )
}
