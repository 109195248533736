/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import InputSelect from '../../../components/input-select';
import Chart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import generatePDF from './service/generetePDF';
import type MathematicsProblemSolvingTagType from '../../../class/types/MathematicsProblemSolving';
import MathematicsProblemSolvingApi from '../../../class/Mathematics-problem-solving/MathematicsProblemSolving-api';

const defaultBgImg = require('./img/undraw_Pie_chart_re_bgs8.png');

interface MathematicsDiagnosticAssessmentGeneralDashboardComparativeProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MathematicsDiagnosticAssessmentGeneralDashboardComparative({ display, setDisplay }: MathematicsDiagnosticAssessmentGeneralDashboardComparativeProps): React.ReactElement {
  const [mathematicDiagnosticDate, setMathematicDiagnosticDate] = useState<
  {
    right: number[]
    wrong: number[]
  }>({
      right: [],
      wrong: [],
    });
  const [mathematicDiagnosticDateTwo, setMathematicDiagnosticDateTwo] = useState<
  {
    right: number[]
    wrong: number[]
  }>({
      right: [],
      wrong: [],
    });

  const [optionSelected, setOptionSelected] = useState<number[]>([]);
  const [optionSelectedTwo, setOptionSelectedTwo] = useState<number[]>([]);

  const [textOfOption, setTextOfOption] = useState<string>('');
  const [textOfOptionTwoMonths, setTextOfOptionTwoMonths] = useState<string>('');
  const [textOfOptionTwoMonthsTwo, setTextOfOptionTwoMonthsTwo] = useState<string>('');

  const [deleteOption, setDeleteOption] = useState<boolean>(false);
  const [deleteOptionTwo, setDeleteOptionTwo] = useState<boolean>(false);

  const { id, roomName } = useParams();

  const twoMonths = [
    'twoMonths-one',
    'twoMonths-two',
    'twoMonths-tree',
    'twoMonths-for',
  ]

  const twoMonthsOptions = [
    '1º Bimestre',
    '2º Bimestre',
    '3º Bimestre',
    '4º Bimestre',
  ]

  const [options, setOptions] = useState<MathematicsProblemSolvingTagType[]>([]);

  const huddleInputSelectTwoMonths = (option: string) => {
    if (!id) {
      return;
    }

    setTextOfOptionTwoMonths(option);

    setDeleteOption(!deleteOption);
    setOptionSelected([0, 0]);

    const mathematicProblemSolvingApi = new MathematicsProblemSolvingApi();
    mathematicProblemSolvingApi.getDateByTwoMonths(id, twoMonths[twoMonthsOptions.indexOf(option)]).then(response => {
      setMathematicDiagnosticDate(response);
    }).catch(e => {

    });
  }
  const huddleInputSelectTwoMonthsTwo = (option: string) => {
    if (!id) {
      return;
    }

    setTextOfOptionTwoMonthsTwo(option);

    setDeleteOptionTwo(!deleteOptionTwo);
    setOptionSelectedTwo([0, 0]);

    const mathematicDiagnosticApi = new MathematicsProblemSolvingApi();
    mathematicDiagnosticApi.getDateByTwoMonths(id, twoMonths[twoMonthsOptions.indexOf(option)]).then(response => {
      setMathematicDiagnosticDateTwo(response);
    }).catch(e => {

    });
  }

  const huddleInputSelectNivel = (option: string) => {
    const indexTag = Number(option.split(' ')[0]) - 1;

    if (mathematicDiagnosticDate.right.length === 0 && mathematicDiagnosticDate.wrong.length === 0) {
      setOptionSelected([0, 0]);
      setTextOfOption('');
      return;
    }

    setOptionSelected([
      mathematicDiagnosticDate.right[indexTag],
      mathematicDiagnosticDate.wrong[indexTag]
    ]);
    setTextOfOption(option);
  }

  const huddleInputSelectNivelTwo = (option: string) => {
    const indexTag = Number(option.split(' ')[0]) - 1;

    if (mathematicDiagnosticDateTwo.right.length === 0 && mathematicDiagnosticDateTwo.wrong.length === 0) {
      setOptionSelected([0, 0]);
      setTextOfOption('');
      return;
    }

    setOptionSelectedTwo([
      mathematicDiagnosticDateTwo.right[indexTag],
      mathematicDiagnosticDateTwo.wrong[indexTag]
    ]);
    setTextOfOption(option);
  }

  const huddlePrint = () => {
    if (!roomName) {
      return;
    }

    generatePDF(roomName, textOfOptionTwoMonthsTwo);
  }

  const huddleCloseWindow = () => {
    setDisplay(!display);
  }

  useEffect(() => {
    if (!id) {
      return;
    }
    const mathematicsProblemSolvingApi = new MathematicsProblemSolvingApi();

    mathematicsProblemSolvingApi.getTags(id).then(response => {
      setOptions(response);
    }).catch(() => {
      //
    });
  }, [display]);

  return (
    <div className={`mathematics-problem-solving-general-dashboard-comparative display-${display}`}>
      <div className='mathematics-problem-solving-general-table-header'>
        <h1>Gráfico 1</h1>
        <InputSelect
          options={twoMonthsOptions}
          defaultValue='Bimestre'
          cb={huddleInputSelectTwoMonths}
          title=''
        />
        <InputSelect
          options={options.map((option, index) => `${index + 1} ${option.content}`)}
          defaultValue='Níveis'
          cb={huddleInputSelectNivel}
          deleteAll={deleteOption}
          title=''
        />
        <h1>Gráfico 2</h1>
        <InputSelect
          options={twoMonthsOptions}
          defaultValue='Bimestre'
          cb={huddleInputSelectTwoMonthsTwo}
          title=''
        />
        <InputSelect
          options={options.map((option, index) => `${index + 1} ${option.content}`)}
          defaultValue='Níveis'
          cb={huddleInputSelectNivelTwo}
          deleteAll={deleteOptionTwo}
          title=''
        />
        <button type='button' className='button-content' onClick={huddlePrint}>Imprimir</button>
        <button type='button' className='button-content' onClick={huddleCloseWindow}>Voltar</button>
      </div>
      <div className='mathematic-problem-solving-dashboard-content' id='mathematic-problem-solving-dashboard-comparative'>
        {
          optionSelected.length > 0
            ? (
              <>
                <div className='chart-comparative-content'>
                  <Chart
                    type='pie'
                    width={450}
                    series={optionSelected}
                    options={{
                      labels: ['Acertou', 'Error'],
                      colors: ['#00b8f0', '#ff0000'],
                      legend: {
                        fontSize: '16rem',
                        offsetY: 70,
                      },
                      chart: {
                        width: '50px',
                        height: '50px'
                      },
                      title: {
                        align: 'center',
                        margin: 30,
                        style: {
                          fontSize: '2rem'
                        },
                      }
                    }}
                  />
                  <Chart
                    type='pie'
                    width={450}
                    series={optionSelectedTwo}
                    options={{
                      labels: ['Acertou', 'Error'],
                      colors: ['#00b8f0', '#ff0000'],
                      legend: {
                        fontSize: '16rem',
                        offsetY: 70,
                      },
                      chart: {
                        width: '50px',
                        height: '50px'
                      },
                      title: {
                        align: 'center',
                        margin: 30,
                        style: {
                          fontSize: '2rem'
                        },
                      }
                    }}
                  />
                </div>
                <div className='mathematic-problem-solving-dashboard-info'>
                  <div>
                    <h1>Resolução de Problemas</h1>
                  </div>
                  <ul>
                    <li>
                      <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                        backgroundColor: '#00b8f0',
                      }}></div>
                      <h1>Acertou: {optionSelected[0]}</h1>
                    </li>
                    <li>
                      <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                        backgroundColor: '#ff0000',
                      }}></div>
                      <h1>Errou: {optionSelected[1]}</h1>
                    </li>
                  </ul>
                </div>
              </>
            )
            : (
              <img className='dashboard-default-image' src={defaultBgImg} alt="dashboard default image" />
            )
        }
      </div>
    </div>
  );
}
