import Validator from '../../Validator';
import type MunicipalEducationPlanMonitoringSheetType from '../../types/municipal-education-plan-monitoring-sheet';
import { type StrategyType, type IndicatorType, type MonitoringSheetGoalType, type MunicipalEducationPlanMonitoringSheetCostumerProtocol } from '../../types/municipal-education-plan-monitoring-sheet';
import MunicipalEducationPlanMonitoringSheetApi from '../municipal-education-plan-monitoring-sheet-api';
import MunicipalEducationPlanMonitoringSheetFactory from '../municipal-education-plan-monitoring-sheet-factory';

export default class MunicipalEducationPlanMonitoringSheetCostumer implements MunicipalEducationPlanMonitoringSheetCostumerProtocol {
  constructor(private readonly inputs?: NodeListOf<HTMLInputElement>) {}

  async post(): Promise<MunicipalEducationPlanMonitoringSheetType[] | Array<{error: boolean}>> {
    if (!this.inputs) {
      return [];
    }

    const validation = new Validator(this.inputs);

    if (validation.init()) {
      return [
        {
          error: true,
        }
      ]
    }

    const factory = new MunicipalEducationPlanMonitoringSheetFactory(this.inputs);

    const municipalEducationPlanMonitoringSheet = factory.create();

    let newMunicipalEducationPlanMonitoringSheet = [] as MunicipalEducationPlanMonitoringSheetType[];

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    if (!municipalEducationPlanMonitoringSheet) {
      return [];
    }

    await municipalEducationPlanMonitoringSheetApi.post(municipalEducationPlanMonitoringSheet).then(response => {
      newMunicipalEducationPlanMonitoringSheet = response;
    }).catch(e => {
      //
    });

    return newMunicipalEducationPlanMonitoringSheet;
  }

  async put(id: string): Promise<MunicipalEducationPlanMonitoringSheetType[] | Array<{error: boolean}>> {
    if (!this.inputs) {
      return [];
    }

    const validation = new Validator(this.inputs);

    if (validation.init()) {
      return [{
        error: true,
      }];
    }

    const factory = new MunicipalEducationPlanMonitoringSheetFactory(this.inputs);

    let newMunicipalEducationPlanMonitoring = [] as MunicipalEducationPlanMonitoringSheetType[]

    const municipalEducationPlanMonitoringSheet = factory.create();

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    if (!municipalEducationPlanMonitoringSheet) {
      return newMunicipalEducationPlanMonitoring;
    }

    await municipalEducationPlanMonitoringSheetApi.update(municipalEducationPlanMonitoringSheet, id).then(response => {
      newMunicipalEducationPlanMonitoring = response;
    }).catch(e => {
      //
    });

    return newMunicipalEducationPlanMonitoring;
  }

  async postGoal(
    id: string,
    goalId: string,
    goalText: HTMLTextAreaElement,
    deadLine: HTMLTextAreaElement,
    observation: HTMLTextAreaElement,
    ): Promise<MonitoringSheetGoalType[]> {
    let newMunicipalEducationPlanMonitoring: MonitoringSheetGoalType[] = [] as MonitoringSheetGoalType[];

    const goal = new MunicipalEducationPlanMonitoringSheetFactory().createGoalText(
        goalText,
        deadLine,
        observation,
    );

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    await municipalEducationPlanMonitoringSheetApi.postGoalText(id, goalId, goal).then(response => {
      newMunicipalEducationPlanMonitoring = response;
    }).catch(e => {
      //
    });

    return newMunicipalEducationPlanMonitoring;
  }

  async createInitialTargetIndicator(id: string, goalId: string): Promise<IndicatorType[]> {
    let newIndicator: IndicatorType[] = [] as IndicatorType[]

    const municipalEducationPlanMonitoringSheetFactory = new MunicipalEducationPlanMonitoringSheetFactory();

    const targetIndicator = municipalEducationPlanMonitoringSheetFactory.createInitialTargetIndicator();

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    await municipalEducationPlanMonitoringSheetApi.postTargetIndicator(id, goalId, targetIndicator).then(response => {
      newIndicator = response;
    }).catch(e => {
      //
    });

    return newIndicator;
  }

  async postTargetIndicator(
      id: string,
      goalId: string,
      indicatorId: string,
      expectGoal: NodeListOf<HTMLInputElement>,
      executedGoalDO: NodeListOf<HTMLInputElement>,
      executedGoalDE: NodeListOf<HTMLInputElement>,
      indicatorContent: HTMLInputElement,
      indicatorTerm: HTMLInputElement,
      reachedTerm: string,
    ): Promise<IndicatorType[]> {
    const indicator = new MunicipalEducationPlanMonitoringSheetFactory().createIndicator(
      expectGoal,
      executedGoalDO,
      executedGoalDE,
      indicatorContent,
      indicatorTerm,
      reachedTerm,
    );

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    return await municipalEducationPlanMonitoringSheetApi.putTargetIndicator(id, goalId, indicatorId, indicator);
  }

  async createInitialStrategy(id: string, goalId: string): Promise<StrategyType[]> {
    const municipalEducationPlanMonitoringSheet = new MunicipalEducationPlanMonitoringSheetFactory();

    const strategy = municipalEducationPlanMonitoringSheet.createInitialStrategy();

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    let strategies: StrategyType[] = [];

    await municipalEducationPlanMonitoringSheetApi.postInitialStrategy(id, goalId, strategy).then(response => {
      strategies = response;
    }).catch(e => {
      //
    });

    return strategies;
  }

  async putStrategy(id: string, goalId: string, strategyId: string, textArea: NodeListOf<HTMLTextAreaElement>, inputs: NodeListOf<HTMLInputElement>): Promise<StrategyType[]> {
    const municipalEducationPlanMonitoringSheetFactory = new MunicipalEducationPlanMonitoringSheetFactory();

    const strategy = municipalEducationPlanMonitoringSheetFactory.createStrategy(textArea, inputs);

    let strategies: StrategyType[] = [];

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    await municipalEducationPlanMonitoringSheetApi.putStrategy(id, goalId, strategyId, strategy).then(response => {
      strategies = response;
    }).catch(e => {
      //
    });

    return strategies;
  }
}
