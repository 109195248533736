/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import './styles.scss';
import InputSelect from '../../../components/input-select';
import Chart from 'react-apexcharts';
import MathematicDiagnosticAssessmentApi from '../../../class/mathematic-diagnostic-assessment/mathematic-diagnostic-assessment-api';
import { useParams } from 'react-router-dom';
import MathematicsDiagnosticAssessmentGeneralDashboardComparative from '../mathematics-diagnostic-assessment-general-dashboard-comparative';

const defaultBgImg = require('./img/undraw_Pie_chart_re_bgs8.png');

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [mathematicDiagnosticDate, setMathematicDiagnosticDate] = useState<
    {
      yes: number[]
      not: number[]
    }>({
      yes: [0, 0, 0, 0, 0, 0, 0],
      not: [0, 0, 0, 0, 0, 0, 0],
    });
  const [optionSelected, setOptionSelected] = useState<number[]>([]);
  const [textOfOption, setTextOfOption] = useState<string>('');
  const [textOfOptionTwoMonths, setTextOfOptionTwoMonths] = useState<string>('');

  const [displayComparative, setDisplayComparative] = useState<boolean>(false);

  const [deleteOption, setDeleteOption] = useState<boolean>(false);

  const { id } = useParams();

  const twoMonths = [
    'twoMonths-one',
    'twoMonths-two',
    'twoMonths-tree',
    'twoMonths-for',
  ]

  const twoMonthsOptions = [
    '1º Bimestre',
    '2º Bimestre',
    '3º Bimestre',
    '4º Bimestre',
  ]
  const options = [
    'Familiares ou Frequentes',
    'Opacos',
    'Transparentes',
    'Terminam com zero',
    'Generalização',
    'Zero intercalado',
    'Composto por algarismos iguais',
  ]

  const huddleInputSelectTwoMonths = (option: string) => {
    if (!id) {
      return;
    }

    setTextOfOptionTwoMonths(option);

    setDeleteOption(!deleteOption);
    setOptionSelected([0, 0]);

    const mathematicDiagnosticApi = new MathematicDiagnosticAssessmentApi();
    mathematicDiagnosticApi.getDataByTwoMonths(id, twoMonths[twoMonthsOptions.indexOf(option)]).then(response => {
      setMathematicDiagnosticDate(response);
    }).catch(e => {

    });
  }

  const huddleInputSelectNivel = (option: string) => {
    setOptionSelected([
      mathematicDiagnosticDate.not[options.indexOf(option)],
      mathematicDiagnosticDate.yes[options.indexOf(option)]
    ]);
    setTextOfOption(option);
  }

  const huddleDisplayComparative = () => {
    setDisplayComparative(true);
  }

  return (
    <>
      <MathematicsDiagnosticAssessmentGeneralDashboardComparative display={displayComparative} setDisplay={setDisplayComparative} />
      <div className='mathematics-diagnostic-assessment-general-table'>
        <div className='mathematics-diagnostic-assessment-general-table-header'>
          <h1>Filtros</h1>
          <InputSelect
            options={twoMonthsOptions}
            defaultValue='Bimestre'
            cb={huddleInputSelectTwoMonths}
            title=''
          />
          <InputSelect
            options={options}
            defaultValue='Níveis'
            cb={huddleInputSelectNivel}
            deleteAll={deleteOption}
            title=''
          />
          <button type='button' className='button-content' onClick={huddleDisplayComparative}>Comparar</button>
        </div>
        <div className='mathematic-diagnostic-dashboard-content'>
          {
            optionSelected.length > 0
              ? (
                <>
                  <Chart
                    type='pie'
                    width={700}
                    height={400}
                    series={optionSelected}
                    options={{
                      labels: ['Não', 'Sim'],
                      colors: ['#FF2E00', '#118951'],
                      legend: {
                        fontSize: '16rem',
                        offsetY: 70,
                      },
                      chart: {
                        width: '50px',
                        height: '50px'
                      },
                      title: {
                        align: 'center',
                        margin: 30,
                        style: {
                          fontSize: '2rem'
                        },
                        text: `Diagnóstico de Matemática / Nível - ${textOfOption}`
                      }
                    }}
                  />
                  <Chart
                    className='graphic-content'
                    type='bar'
                    width={400}
                    height={400}
                    options={{
                      chart: {
                        width: 800,
                        type: 'bar',
                        toolbar: {
                          show: true,
                        },
                      },
                      stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: ['#000000'],
                        width: 1,
                        dashArray: 0
                      },
                      colors: ['#FF2E00', '#118951'],
                      dataLabels: {
                        enabled: true,
                        formatter(val) {
                          return `${val} alunos(a)`;
                        },
                        offsetY: 10,
                        style: {
                          fontSize: '12px',
                          colors: ['#304758']
                        }
                      },
                      legend: {
                        show: false
                      },
                      plotOptions: {
                        bar: {
                          columnWidth: '45%',
                          distributed: true,
                          dataLabels: {
                            position: 'top'
                          }
                        }
                      },
                      xaxis: {
                        categories: ['Não', 'Sim'],
                        labels: {
                          style: {
                            colors: '#000000',
                            fontWeight: '600',
                            fontSize: '10px'
                          }
                        }
                      }
                    }}
                    series={[
                      {
                        data: optionSelected
                      }
                    ]}
                  />
                  <div className='mathematic-diagnostic-dashboard-info'>
                    <div>
                      <h1>Diagnóstico de Matemática</h1>
                    </div>
                    <ul>
                      <li>
                        <div style={{
                          width: 20,
                          height: 20,
                          borderRadius: '100%',
                          backgroundColor: '#FF2E00',
                        }}></div>
                        <h1>Não: {optionSelected[0]}</h1>
                      </li>
                      <li>
                        <div style={{
                          width: 20,
                          height: 20,
                          borderRadius: '100%',
                          backgroundColor: '#118951',
                        }}></div>
                        <h1>Sim: {optionSelected[1]}</h1>
                      </li>
                    </ul>
                  </div>
                </>
              )
              : (
                <img className='dashboard-default-image' src={defaultBgImg} alt="dashboard default image" />
              )
          }
        </div>
      </div>
    </>
  );
}
