/* eslint-disable multiline-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import './style.scss';
import studentWritingLevelRecordCoordinator from '../../../class/student-writing-level-record/student-writing-level-record-coordinator';
import LoadContent from '../../../components/Load-content';

interface DashboardDataType {
  seriesCount?: number[]
  writingTheName?: number
  series: number[]
  colors: string[]
  categories: string[]
}

interface DashBoardWritingLevelProps {
  setDataDashboard: React.Dispatch<React.SetStateAction<DashboardDataType>>
  displayDashboardWritingLevel: boolean
  dataDashboard: DashboardDataType
  display: string
  isLoading: boolean
  roomIds: string[]
}

export default function DashboardWritingLevel({ display, displayDashboardWritingLevel, isLoading, dataDashboard, setDataDashboard, roomIds }: DashBoardWritingLevelProps): React.ReactElement {
  const [displayDashboard, setDisplayDashboard] = useState<boolean>(false);

  useEffect(() => {
    if (display === 'false') {
      return;
    }
    setDisplayDashboard(false);
    studentWritingLevelRecordCoordinator.getAllDataStudentWritingRecordTwoMonthsCoordinator(roomIds, 'twoMonths-one').then(response => {
      let NRindex = 0;
      let NRSearched = false;

      response.category.map((value, index) => {
        if (NRSearched) {
          return value;
        }
        if (value === 'NR') {
          NRindex = index;
          NRSearched = true;
        }
        return value;
      });

      response.category.splice(NRindex, 1);
      response.series.splice(NRindex, 1);

      setDataDashboard({
        categories: response.category,
        series: response.series,
        colors: response.color,
        seriesCount: response.seriesCount,
        writingTheName: response.writingTheName,
      });
      setDisplayDashboard(true);
    });
  }, [display]);

  if (!displayDashboardWritingLevel) {
    return <></>;
  }

  return (
    <div className={`dashboard-writing-level-coordinator display-${displayDashboard}`}>
      {!isLoading ? (
        <>
          <Chart
            className='graphic-content'
            type='pie'
            width={700}
            series={dataDashboard.series}
            options={{
              labels: dataDashboard.categories,
              colors: dataDashboard.colors,
              legend: {
                fontSize: '16rem',
                offsetY: 70
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#000000'],
                width: 0.5,
                dashArray: 0
              },
              chart: {
                width: '50px',
                height: '50px'
              },
              title: {
                align: 'center',
                margin: 30,
                style: {
                  fontSize: '2rem'
                },
                text: 'Hipótese de Escrita Secretaria'
              }
            }}
          />
          <Chart
            className='graphic-content'
            type='bar'
            width={700}
            options={{
              chart: {
                width: 800,
                type: 'bar'
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#000000'],
                width: 1,
                dashArray: 0
              },
              colors: dataDashboard.colors,
              dataLabels: {
                enabled: true,
                formatter(val) {
                  return `${val}%`;
                },
                offsetY: 10,
                style: {
                  fontSize: '12px',
                  colors: ['#304758']
                }
              },
              legend: {
                show: false
              },
              plotOptions: {
                bar: {
                  columnWidth: '45%',
                  distributed: true,
                  dataLabels: {
                    position: 'top' // Top, center, bottom
                  }
                }
              },
              xaxis: {
                categories: dataDashboard.categories,
                labels: {
                  style: {
                    colors: '#000000',
                    fontWeight: '600',
                    fontSize: '10px'
                  }
                }
              }
            }}
            series={[
              {
                data: dataDashboard.series
              }
            ]}
          />
          {(dataDashboard.seriesCount?.reduce((previousValue, value) => previousValue += value, 0)) === 0
            ? ''
            : (
              <>
                <h1>{('Quantidade de alunos que escreve o próprio nome:  ' + `${!dataDashboard.writingTheName ? 0 : dataDashboard.writingTheName}`).toUpperCase()}</h1>
                <div className='category-count-information'>
                  <ul>
                    <li>
                      <h1>Total avaliado: {dataDashboard.seriesCount?.reduce((previousValue, value, index) => {
                        if (!dataDashboard.seriesCount?.length) {
                          return previousValue;
                        }
                        if (index === dataDashboard.seriesCount?.length - 1) {
                          return previousValue;
                        }
                        return previousValue += value
                      })}</h1>
                    </li>
                    {dataDashboard.categories.map((category: string, index) => {
                      return (
                        <li key={`${category.replaceAll(' ', '').concat(`${dataDashboard.seriesCount ? dataDashboard.seriesCount[index] : 0}`)}`}>
                          <div
                            className='color-content'
                            style={
                              {
                                backgroundColor: dataDashboard.colors[index],
                              }
                            }
                          >
                          </div>
                          <h1>
                            {category}: {dataDashboard.seriesCount ? dataDashboard.seriesCount[index] : 0}
                          </h1>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </>
            )}
        </>
      ) : <LoadContent isLoading={isLoading}/>}
    </div>
  );
}
