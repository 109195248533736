import { type ReadingFluencyType } from '../../../class/types/ReadingFluency/reading-fluencyApi-protocol';

interface InformationType {
  room: string
  year: string
  date: {
    before: string
    after: string
  }
}

export default class ReadingFluencySearch {
  private readonly information: InformationType;

  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly readingFluencies: ReadingFluencyType[],
    ) {
      this.information = {
        room: this.inputs[0].value,
        year: this.inputs[1].value,
        date: {
          before: this.inputs[2].value,
          after: this.inputs[3].value,
        }
      }
    }

    searchReadingFluency() {
      let readingFluenciesFiltered;

      readingFluenciesFiltered = this.searchReadingFluencyByKey('room', this.readingFluencies);

      readingFluenciesFiltered = this.searchReadingFluencyByKey('year', readingFluenciesFiltered);

      if (!this.information.date.after || !this.information.date.before) {
        return readingFluenciesFiltered;
      }

      readingFluenciesFiltered = this.filterReadingFluencyByDate(readingFluenciesFiltered);

      if (readingFluenciesFiltered.length === 0) {
        return this.readingFluencies;
      }

      return readingFluenciesFiltered;
    }

    private searchReadingFluencyByKey(key: 'room' | 'year', readingFluencies: ReadingFluencyType[]) {
      const readingFluenciesFiltered: ReadingFluencyType[] = [];

      if (this.information[key] === '' || !this.information[key]) {
        return readingFluencies
      }

      readingFluencies.forEach(readingFluency => {
        if (readingFluency[key].includes(this.information[key])) {
          readingFluenciesFiltered.push(readingFluency);
        }
      });

      return readingFluenciesFiltered;
    }

    private filterReadingFluencyByDate(readingFluencies: ReadingFluencyType[]): ReadingFluencyType[] {
      const newReadingFluencies: ReadingFluencyType[] = [];

      const firstDate = new Date(this.information.date.before);
      const lastDate = new Date(this.information.date.after);

      readingFluencies.forEach((value) => {
        const planDate = new Date(value.date);

        if (this.information.date.before.length === 0) {
          return;
        }
        if (planDate < firstDate) {
          return
        }

        if (this.information.date.after.length === 0) {
          return;
        }

        if (planDate > lastDate) {
          return
        }
        newReadingFluencies.push(value);
      });

      return newReadingFluencies;
    }
};
