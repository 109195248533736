import lscache from 'lscache';
import { type UserProtocol } from '../../types/User/User-factory-protocol';
import config from '../../../config/config';
import axios from 'axios';
import type NewWritingTheNameType from '../../types/new-writing-hypothesi/new-writing-the-name';

export default class NewWritingTheName {
  private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  async initWritingTheName(roomId: string, twoMonths: string): Promise<NewWritingTheNameType[]> {
    let writingTheName: NewWritingTheNameType[] = [];

    await axios.get(this.href + `/new-writing-hypothesis/writing-the-name/${roomId}/${twoMonths}`).then(response => {
      writingTheName = response.data;
    });

    return writingTheName
  }

  async post(roomId: string, twoMonths: string, studentId: string, writingTheName: boolean): Promise<NewWritingTheNameType[]> {
    let newWritingTheName: NewWritingTheNameType[] = [];

    await axios.post(this.href + `/new-writing-hypothesis/writing-the-name/${roomId}/${twoMonths}/${studentId}`, {
      writingTheName,
    }).then(response => {
      newWritingTheName = response.data;
    });

    return newWritingTheName;
  }

  async getAllWritingTheName(roomId: string, twoMonths: string): Promise<NewWritingTheNameType[]> {
    let writingTheName: NewWritingTheNameType[] = [];

    await axios.get(this.href + `/new-writing-hypothesis/get-all/writing-the-name/${roomId}/${twoMonths}`).then(response => {
      writingTheName = response.data;
    });

    return writingTheName;
  }
}
