export default function clearAllInputs(): void {
  const form = document.getElementById('form-add-element-plan') as HTMLFormElement;
  const inputs = form.querySelectorAll('input');

  inputs.forEach(input => {
    input.value = '';
  });

  const inputPlan = document.getElementById('plan-input') as HTMLInputElement;
  if (!inputPlan) {
    return;
  }

  inputPlan.value = '';
}
