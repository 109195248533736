/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */

import type { RoomType } from '../../../class/types/Room/Rooms-protocol';

/* eslint-disable @typescript-eslint/prefer-readonly */
interface InformationType {
  content: string
  schoolYear: string
}

export default class WritingLevelRecordFilter {
  private information: InformationType;
  private findings: RoomType[] = [];

  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly rooms: RoomType[]
  ) {
    this.information = {
      content: inputs.item(0).value,
      schoolYear: inputs.item(1).value
    };
  }

  searchElement(): RoomType[] {
    let findings: RoomType[];

    findings = this.searchElementByContent(this.information.content);

    findings = this.searchElementBySchoolYear(this.information.schoolYear, findings);

    this.findings = findings;
    return findings;
  }

  searchElementByContent(content: string): RoomType[] {
    const findings: RoomType[] = [];

    this.rooms.forEach(room => {
      const roomName = this.formatInformation(room.roomName, content.length);
      const contentFormatted = this.formatInformation(content, content.length);

      if (roomName === contentFormatted) {
        findings.push(room);
      }
    });

    return findings;
  }

  searchElementBySchoolYear(schoolYear: string, rooms: RoomType[]): RoomType[] {
    const findings: RoomType[] = [];

    if (!schoolYear || schoolYear.length === 0) {
      return rooms;
    }

    rooms.forEach(room => {
      if (!room.date) {
        return;
      }

      const date = room.date.split('/')[2];
      const roomName = this.formatInformation(date, schoolYear.length);
      const contentFormatted = this.formatInformation(schoolYear, schoolYear.length);

      if (roomName === contentFormatted) {
        findings.push(room);
      }
    });

    return findings;
  }

  formatInformation(content: string, length: number) {
    return content
      .slice(0, length)
      .replace(' ', '')
      .trim();
  }

  static roomIDs(findings: RoomType[]): string[] {
    const roomIDs = findings.map(room => room.id) as string[];

    return roomIDs;
  }
}
