/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */

import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { StrategyProtocol } from '../types/Strategy/Strategy-factory-protocol';
import type StrategyAPIProtocol from '../types/Strategy/StrategyAPI-protocol';
import type { StrategyResponseProtocol } from '../types/Strategy/StrategyAPI-protocol';

class StrategyAPI implements StrategyAPIProtocol {
	private readonly user = lscache.get('user');

	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
    lscache.setExpiryMilliseconds(60000);
	}

	async get(): Promise<StrategyResponseProtocol[]> {
		let strategy: StrategyResponseProtocol[] | PromiseLike<StrategyResponseProtocol[]> = [];
		if (this.href) {
			await axios.get(`${this.href}/get/all/strategy`).then(response => {
				strategy = response.data;
			});

			this.saveStrategy(strategy);
		}

		return strategy;
	}

	async post(action: StrategyProtocol): Promise<StrategyResponseProtocol[]> {
		let strategy: StrategyResponseProtocol[] | Promise<StrategyResponseProtocol[]> = [];

		if (this.href) {
			await axios.post(`${this.href}/post/strategy`, action).then(response => {
				strategy = response.data;
			});

			this.saveStrategy(strategy);
		}

		return strategy;
	}

	async deleteStrategy(id: string): Promise<StrategyResponseProtocol[]> {
		let strategy: StrategyResponseProtocol[] | Promise<StrategyResponseProtocol[]> = [];

		if (this.href) {
			await axios.delete(`${this.href}/delete/strategy/${id}`).then(response => {
				strategy = response.data;
			});

			this.saveStrategy(strategy);
		}

		return strategy;
	}

	async put(strategy: StrategyProtocol, id: string): Promise<StrategyResponseProtocol[]> {
		let strategies: StrategyResponseProtocol[] | Promise<StrategyResponseProtocol[]> = [];

		if (this.href) {
			await axios.put(`${this.href}/update/strategy/${id}`, strategy).then(response => {
				strategies = response.data;
			});

			this.saveStrategy(strategies);
		}

		return strategies;
	}

	private saveStrategy(strategies: StrategyResponseProtocol[]): void {
		lscache.set('strategy', strategies, config.processTemp);
	}
}

export default new StrategyAPI();
