
import React from 'react';
import './style.scss';
import Header from '../../components/Header';
import LoginContainer from '../../container/login-container';

export default function Login() {
  return (
		<div className='App'>
			<main className='main-container-login'>
				<Header />
				<div className='form-login-container'>
					<LoginContainer />
				</div>
			</main>
		</div>
  );
}
