import type { RoomType, TeacherInfoType } from '../types/Room/Rooms-protocol';

export default class RoomFactory {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly teacherInfo: TeacherInfoType
	) {}

	createRoom(): RoomType {
		return {
			roomName: this.inputs[0].value,
			schoolName: this.inputs[1].value,
			teacher: {
				name: this.teacherInfo.name,
				id: this.teacherInfo.id
			},
			date: this.getDate()
		};
	}

	createPut() {
		return {
			roomName: this.verifyValue(0),
			schoolName: this.verifyValue(1),
			teacher: {
				name: this.verifyValue(2),
				id: this.teacherInfo.id
			}
		};
	}

	verifyValue(index: number): string {
		return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
	}

	private getDate(): string {
		const date = new Date();

		return date.toLocaleDateString('UTC', {
			dateStyle: 'short'
		});
	}
}
