import type { StrategyOfPlanProtocol } from '../../../../class/types/Strategy-of-plan/Strategy-of-plan-factory-protocol';

export default function filterById(actions: StrategyOfPlanProtocol[], id: string): StrategyOfPlanProtocol[] {
  const newActions: StrategyOfPlanProtocol[] = [];
  actions.forEach((action: StrategyOfPlanProtocol) => {
    if (action.planId === id) {
      newActions.push(action);
    }
  });

  return newActions;
}
