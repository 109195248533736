/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect } from 'react';

import { type UploadRoomObserverSheetType } from '../../../class/types/Upload_room-observer-sheet-api';
import ContentTableRoomObservationSheet from '../../../components/upload-room-observer-sheet';
import FormEditUploadRoomObserverSheet from '../form-edit-room-observer-sheet';
import FormAddUploadRoomObserverSheet from '../form-add-room-observer-sheet';
import DecentWorkDisplayInformation from '../room-observer-sheet-display-information';
import UploadRoomObserverSheetApi from '../../../class/UploadRoomObserverSheet/room-observer-sheet-api';
// import FormDescribeDecentWork from '../form-description-room-observer-sheet';
import EmptyComponent from '../../../components/Empty-component';
import { useParams } from 'react-router-dom';
import FilterPlan from '../../../class/filterPlan';
import './style.scss';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [UploadRoomObserverSheet, setUploadRoomObserverSheet] = useState<UploadRoomObserverSheetType[]>([]);

  const [displayFormDescribeElementaryMonthlyPlan, setDisplayDescribeUploadRoomObserverSheet] = useState(false);
  const [displayFormEditElementaryMonthlyPlan, setDisplayEditUploadRoomObserverSheet] = useState(false);
  const [displayFormAddElementaryMonthlyPlan, setDisplayElementaryMonthlyPlan] = useState(false);
  const [displayDecentWorkInfo, setDisplayUploadRoomObserverSheetInfo] = useState(false);

  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const { roomName } = useParams();

  const [element, setElement] = useState<UploadRoomObserverSheetType>(elementDefault);
  const { id } = useParams();

  const huddleCreateNewElement = () => {
    setDisplayElementaryMonthlyPlan(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan([inputs.item(0), {value: ''}, inputs.item(1), inputs.item(2)], UploadRoomObserverSheet);

    setElementFiltered(filterPlan.searchRoomObserver());
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    UploadRoomObserverSheetApi.get(id).then((response) => {
      setUploadRoomObserverSheet(response);
      setElementFiltered(response);
    }).catch((e) => {
      //
    });
  }, []);

  useEffect(() => {
    setElementFiltered(UploadRoomObserverSheet)
  }, [UploadRoomObserverSheet]);

  return (
    <div className='table-container-upload-room-observer-sheet'>
      <h1 className='title-table'>Ficha de Observação de Sala - {roomName}</h1>
      <FormAddUploadRoomObserverSheet
        displayFormAddRoomObserverSheet={displayFormAddElementaryMonthlyPlan}
        setDisplayFormAddRoomObserverSheet={setDisplayElementaryMonthlyPlan}
        setElementarySchoolRoomObserverSheet={setUploadRoomObserverSheet}
        elementarySchoolRoomObserverSheet={UploadRoomObserverSheet}
      />
      <FormEditUploadRoomObserverSheet
        displayFormEditUploadRoomObserverSheet={displayFormEditElementaryMonthlyPlan}
        setDisplayFormEditUploadRoomObserverSheet={setDisplayEditUploadRoomObserverSheet}
        setElementarySchoolUploadRoomObserverSheet={setUploadRoomObserverSheet}
        setDisplayEditElement={setDisplayEditUploadRoomObserverSheet}
        elementId={elementId}
      />
      {/* <FormDescribeDecentWork
				displayFormDescribeMonthlyPlan={displayFormDescribeElementaryMonthlyPlan}
				setDisplayFormDescribeMonthlyPlan={setDisplayDescribeUploadRoomObserverSheet}
				setElementarySchoolMonthlyPlan={setUploadRoomObserverSheet}
				elementId={elementId}
			/> */}
      <DecentWorkDisplayInformation
        displayUploadRoomObserverSheetInfo={displayDecentWorkInfo}
        setDisplayUploadRoomObserverSheetInfo={setDisplayUploadRoomObserverSheetInfo}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar ficha de observação de sala</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>responsável</li>
          <li className='header-table-ul-li'>data</li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            elementFiltered?.length === 0
              ? <EmptyComponent>click em (Adicionar ficha de observação de sala) para adicionar uma nova ficha de observação de sala</EmptyComponent>
              : elementFiltered?.map((UploadRoomObserverSheet: UploadRoomObserverSheetType, index: number) => (
                <ContentTableRoomObservationSheet
                  index={index}
                  uploadRoomObserverSheet={UploadRoomObserverSheet}
                  setDisplayEditElement={setDisplayEditUploadRoomObserverSheet}
                  setDisplayUploadRoomObserverSheet={setDisplayUploadRoomObserverSheetInfo}
                  setDisplayDescribeRoomObserverSheet={setDisplayDescribeUploadRoomObserverSheet}
                  setElementId={setElementId}
                  setElement={setElement}
                  setUploadRoomObserverSheet={setUploadRoomObserverSheet}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
