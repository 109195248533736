/* eslint-disable @typescript-eslint/no-var-requires */
import JSPDF from 'jspdf';
import { type FinalResultResType } from '../../../../class/types/FinalResult/finalResult-type';
import autoTable from 'jspdf-autotable';

const img = require('./img/header-img.png');

export default function printFinalResultFiles(finalResult: FinalResultResType[], matterName: string, roomName: string): void {
  const doc = new JSPDF({
    orientation: 'landscape',
  });

  const generatePercentualFrequency = (present: number, frequencyLength: number) => {
    const percentual = Math.floor((present / frequencyLength) * 100);

    return `${percentual}%`;
  };

  doc.addImage(img, 'PNG', 50, 5, 180, 0);

  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951'
    },
    head: [
      ['Numero', 'Nome do Aluno', 'RA', 'C', 'F', '%', 'F Justificativa', 'Media'],
    ],
    body: (() => {
      const bodyArray: string[][] = [];

      finalResult.forEach((result, index) => {
        bodyArray.push([
          `${index + 1}`,
          result.studentName,
          result.ra.content ?? result.ra,
          String(result.frequencyData.present),
          String(result.frequencyData.absents),
          generatePercentualFrequency(result.frequencyData.present, result.frequencyData.frequencyLength),
          String(result.justificationOfAbsences ?? 0),
          String(result.cardReport.average)
        ]);
      })

      return bodyArray;
    })()
  });

  doc.save(`Boletim Conclusão Final ${roomName} - ${matterName}.pdf`);
}
