/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { useEffect, useState } from 'react';
import './style.scss';

import { AiFillHome } from 'react-icons/ai';

import { useParams } from 'react-router-dom';
import lscache from 'lscache';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import { type MonitoringSheetGoalType } from '../../../class/types/municipal-education-plan-monitoring-sheet';
import { BsFillClipboard2CheckFill } from 'react-icons/bs';
import {HiOutlineClipboardDocumentList} from 'react-icons/hi2';
import { IoDocuments } from 'react-icons/io5';

type Elements = Array<{
  name: string
  boolean: string
}>;

interface NavProps {
  elements: Elements
  setElements: (elements: Elements) => void
}

export default function Nav({ setElements, elements }: NavProps): React.ReactElement {
  const [goal, setGoal] = useState<MonitoringSheetGoalType[]>([]);
  const [user, setUser] = useState<UserClientProtocol>();

  const { id } = useParams();

  const huddleDisplay = (setDisplay: React.Dispatch<React.SetStateAction<boolean>>, display: boolean) => {
    setDisplay(!display);
  }

  const onClickLi = (elementName: string) => {
    const elementsResected = resetElements(elements);

    elementsResected.forEach(element => {
      if (element.name === elementName) {
        element.boolean = 'true';
      }
    });
    setElements(elementsResected);
  };

  const resetElements = (elements: Elements) => {
    elements.forEach(element => {
      element.boolean = 'false';
    });
    return elements;
  };

  useEffect(() => {
    const user = lscache.get('user');
    if (id) {
      setUser(user);

      const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

      municipalEducationPlanMonitoringSheetApi.getAllGoal(id).then(response => {
        setGoal(response);
      });
    }
  }, []);

  return (
    <nav className='nav-psychologist'>
      <ul className='ul-option'>
        <li className='option-content-1' id='estrategy-1' onClick={e => {
          window.location.href = window.location.origin;
        }}>
          <AiFillHome size='20' color='#fff' />
          <h1 id='estrategy-1'>Inicio</h1>
        </li>
        <li className='option-content-1' id='estrategy-1' onClick={e => {
          onClickLi('strategy-2');
        }}>
          <BsFillClipboard2CheckFill size='20' color='#fff' />
          <h1 id='estrategy-1'>Prontuário</h1>
        </li>
        <li className='option-content-1' id='estrategy-1' onClick={e => {
          onClickLi('strategy-1');
        }}>
          <HiOutlineClipboardDocumentList size='20' color='#fff' />
          <h1 id='estrategy-1'>Anamnese</h1>
        </li>
        <li onClick={e => {
          onClickLi('strategy-5');
        }}>
          <IoDocuments size='20' color='#fff' />
          <h1 id='estrategy-1'>Registro</h1>
        </li>
      </ul>
    </nav>
  );
}
