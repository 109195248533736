
import React, { useState } from 'react';
import './style.scss';
import Header from '../../components/Header';
import Nav from '../../components/actions-files/Nav-actions';
import ActionsContainer from '../../container/actions-file/Actions';
import InformationStrategy from '../../components/strategy-files/information-strategy';
import Dashboard from '../../components/Dashboard';

type Elements = Array<{
  name: string
  boolean: string
}>;

function Actions() {
  const [elements, setElements] = useState([
    {
      name: 'strategy-1',
      boolean: 'true'
    },
    {
      name: 'strategy-2',
      boolean: 'false'
    },
    {
      name: 'strategy-3',
      boolean: 'false'
    }
  ]);

  const [display1, setDisplay1] = useState<string>('true');
  const [display2, setDisplay2] = useState<string>('false');
  const [display3, setDisplay3] = useState<string>('false');

  const hundleSetElements = (elementsParams: Elements) => {
    setElements(elementsParams);
    setDisplay1(elements[0].boolean);
    setDisplay2(elements[1].boolean);
    setDisplay3(elements[2].boolean);
  };

  return (
		<div className='App'>
			<Header />
			<main className='main-container-action'>
				<Nav setElements={hundleSetElements} elements={elements} />
				<div className='content-containers'>
					<div className='content' id={`display-${display1}`}>
						<ActionsContainer display={display1} id='action-element' />
					</div>
					<div className='content' id={`display-${display2}`}>
						<InformationStrategy />
					</div>
					<div className='content' id={`display-${display3}`}>
						<Dashboard display={display3} />
					</div>
				</div>
			</main>
		</div>
  );
}

export default Actions;
