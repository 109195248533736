import React from 'react';
import './styles.scss';
import { type PsychologistAnamnese } from '../../../class/types/Psychologist';

interface TableContentPsychologistStudentRegisterProps {
  student: PsychologistAnamnese
  setStudentSelected: React.Dispatch<React.SetStateAction<PsychologistAnamnese | undefined>>
  setDisplayMedicalRecord: React.Dispatch<React.SetStateAction<boolean>>
}

export default function TableContentPsychologistMonitoring({ student, setDisplayMedicalRecord, setStudentSelected}: TableContentPsychologistStudentRegisterProps): React.ReactElement {
  const huddleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'path' || target.tagName === 'svg') {
      return;
    }

    setDisplayMedicalRecord(true);
    setStudentSelected(student);
  }

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short',
    });
  }

  return (
    <ul className='content-table-student-psychologist-monitoring' onClickCapture={huddleClick}>
      <li>{student.studentName}</li>
      <li>{student.motherName}</li>
      <li>{student.fatherName}</li>
      <li>{formatDate(student.birthday)}</li>
    </ul>
  )
}
