/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';
import uploadKindergartenSchoolStudentReportApi from '../../../class/UploadKindergartenSchoolStudentReport/upload-student-report-api';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import FilterPlan from '../../../class/filterPlan';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import './style.scss';
import { type StudentReportType } from '../../../class/types/StudentReport';
import EmptyComponent from '../../../components/Empty-component';
import ContentTableKindergartenStudentReport from '../../../components/StudentReport';
import ReadCheckingStudentReport from '../../../components/read-checking-student-report';
import FormEditStudentReport from '../form-edit-student-report';
import StudentReportDisplayInformation from '../student-report-display-information';
import InputSelect from '../../../components/input-select';
import filterByTwoMonths from '../service/filterByTwoMonths';
import FormAddStudentReport from '../form-add-student-report';
import LoadContent from '../../../components/Load-content';
import RuleVerification from '../../../components/rule-verification';
import FileDeliveryDate from '../file-delivery-data';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [kindergartenSchoolStudentReport, setKindergartenSchoolStudentReport] = useState<StudentReportType[]>([]);
  const [displayFormAddKindergartenSchoolStudentReport, setDisplayKindergartenSchoolStudentReport] = useState(false);
  const [displayStudentReportInfo, setDisplayStudentReportInfo] = useState(false);
  const [displayFormEditKindergartenStudentReport, setDisplayEditKindergartenStudentReport] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [elementFiltered, setElementFiltered] = useState<StudentReportType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainElement = useRef<any>();
  const [displayReadCheckingStudentReport, setDisplayReadCheckingStudentReport] = useState(false);
  const [displayFileDelivery, setDisplayFileDelivery] = useState(false);
  const [element, setElement] = useState<StudentReportType>(elementDefault);

  const huddleCreateNewElement = () => {
    setDisplayKindergartenSchoolStudentReport(true);
  };

  const huddleDisplayFileDelivery = () => {
    setDisplayFileDelivery(!displayFileDelivery);
  }

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    let filterStudentReportByTwoMonths = kindergartenSchoolStudentReport;

    if (inputs.item(2).value !== 'Todos' && inputs.item(2).value !== 'escolha um bimestre') {
      filterStudentReportByTwoMonths = filterByTwoMonths(kindergartenSchoolStudentReport, inputs.item(2).value)
    }

    const filterPlan = new FilterPlan([
      inputs.item(0),
      inputs.item(1),
      inputs.item(3),
      inputs.item(4)
    ], filterStudentReportByTwoMonths);

    setElementFiltered(filterPlan.searchPlans());
  };
  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      uploadKindergartenSchoolStudentReportApi.get().then((response) => {
        setIsLoading(false);
        if (permission === 'professor') {
          const studentReport = response.filter(value => value.issuer?.userId === user.id)
          setKindergartenSchoolStudentReport(studentReport);
          setElementFiltered(studentReport);
          return;
        }

        setKindergartenSchoolStudentReport(response);
        setElementFiltered(response);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    })
  }, [display]);

  useEffect(() => {
    setElementFiltered(kindergartenSchoolStudentReport);
  }, [kindergartenSchoolStudentReport])

  return (
    <div className='table-container-new-kindergarten-school-student-report'>
      <h1 className='title-table'>Ensino Infantil - Relatório do Estudante</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadCheckingStudentReport
        displayReadChecking={displayReadCheckingStudentReport}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadCheckingStudentReport}
        setElementaryStudentReport={setKindergartenSchoolStudentReport}
        type='kindergarten-school'
      />
      <FormAddStudentReport
        displayFormAddStudentReport={displayFormAddKindergartenSchoolStudentReport}
        setDisplayFormAddStudentReport={setDisplayKindergartenSchoolStudentReport}
        setKindergartenSchoolStudentReport={setKindergartenSchoolStudentReport}
      />
      <FormEditStudentReport
        displayFormEditStudentReport={displayFormEditKindergartenStudentReport}
        setDisplayFormEditStudentReport={setDisplayEditKindergartenStudentReport}
        setKindergartenSchoolStudentReport={setKindergartenSchoolStudentReport}
        setDisplayEditElement={setDisplayEditKindergartenStudentReport}
        elementId={elementId}
      />
      <StudentReportDisplayInformation
        displayStudentReportInfo={displayStudentReportInfo}
        setDisplayStudentReportInfo={setDisplayStudentReportInfo}
        element={element}
      />
      <FileDeliveryDate setDisplay={setDisplayFileDelivery} display={displayFileDelivery} />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar relatório do estudante</button>
        <RuleVerification rule={['coordenador']}>
          <button onClick={huddleDisplayFileDelivery}>Visualizar dados de entrega</button>
        </RuleVerification>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='name-content'>
          <div className='search-input-content input-content'>
            <input type='text' placeholder='professor' />
          </div>
          <div className='search-input-content input-content'>
            <input type='text' placeholder='nome da sala' />
          </div>
          <InputSelect
            options={[
              'Todos',
              '1º Bimestre',
              '2º Bimestre',
              '3º Bimestre',
              '4º Bimestre'
            ]}
            title=''
            defaultValue='escolha um bimestre'
          />

        </div>
        <div className='date-content'>
          <div className='search-input-content input-content input-date-content-one input-data'>
            <input type='date' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-date-content-two input-data'>
            <input type='date' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li'>Bimestre</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '8rem',
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar relatório do estudante) para adicionar um novo relatório</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork, index: number) => (
                    <ContentTableKindergartenStudentReport
                      index={index}
                      elementarySchoolStudentReport={elementarySchoolDecentWork}
                      setDisplayStudentReport={setDisplayStudentReportInfo}
                      setDisplayChecking={setDisplayReadCheckingStudentReport}
                      setDisplayEditElement={setDisplayEditKindergartenStudentReport}
                      setElementId={setElementId}
                      setElement={setElement}
                      setElementarySchoolStudentReport={setKindergartenSchoolStudentReport}
                      type={'kindergartenSchool'}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}
