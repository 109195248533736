/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { DisplayFileInfoTwo } from '../../../components/DisplayFile';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import LoadContent from '../../../components/Load-content';
import type FileAdmLegislationType from '../../../class/types/AdmLegislation';
import UploadAdmHoursCostumer from '../../../class/UploadAdmHours/upload-adm-hours-custumer';
import UploadAdmCalendarCostumer from '../../../class/UploadAdmCalendar/upload-adm-calendar-custumer';

interface FormEditCalendarProps {
  setDisplayFormEditAdmCalendar: React.Dispatch<React.SetStateAction<boolean>>
  setAdmCalendar: React.Dispatch<React.SetStateAction<FileAdmLegislationType[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  displayFormEditAdmCalendar: boolean
  elementId: string
  title: string
  type: 'general'
}

export default function FormEditAdmCalendar({ setDisplayFormEditAdmCalendar, setAdmCalendar, title, type, displayFormEditAdmCalendar, elementId }: FormEditCalendarProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormEditAdmCalendar(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const uploadAdmCalendarCostumer = new UploadAdmCalendarCostumer(inputs, elementId);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
   uploadAdmCalendarCostumer.put(type).then(response => {
      if (response.error) {
        setIsLoading(false);
        return;
      }
      setAdmCalendar(response);

      setDisplayFormEditAdmCalendar(false);
      setIsLoading(false);
      setFileInfo(undefined);
    }).catch(e => {
      //
    });
  };

  const inputFileEditHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  return (
    <div className={`form-edit-adm-calendar display-${String(displayFormEditAdmCalendar)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          {title}
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormEditAdmCalendar(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-edit-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-file-element">
          <label htmlFor={`file-edit-input-adm-calendar-${type}`}>
            <DisplayFileInfoTwo file={fileInfo} />
          </label>
          <input
            type="file"
            id={`file-edit-input-adm-calendar-${type}`}
            onChangeCapture={inputFileEditHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png, vnd.openxmlformats-officedocument.presentationml.presentation'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
