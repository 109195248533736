/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { DisplayFileInfoTwo } from '../../../components/DisplayFile';
import { type UploadRoomObserverSheetType } from '../../../class/types/Upload_room-observer-sheet-api';
import UploadRoomObserverSheetCostumer from '../../../class/UploadRoomObserverSheet/room-observer-sheet-custumer';
import { useParams } from 'react-router-dom';
import LoadContent from '../../../components/Load-content';

interface FormEditMonthlyPlanProps {
  setDisplayFormEditUploadRoomObserverSheet: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolUploadRoomObserverSheet: React.Dispatch<React.SetStateAction<UploadRoomObserverSheetType[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  displayFormEditUploadRoomObserverSheet: boolean
  elementId: string
}

export default function FormEditUploadRoomObserverSheet({ setDisplayFormEditUploadRoomObserverSheet, setElementarySchoolUploadRoomObserverSheet, displayFormEditUploadRoomObserverSheet, elementId }: FormEditMonthlyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();

  const huddleCancel = () => {
    setDisplayFormEditUploadRoomObserverSheet(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    if (!id) {
      return;
    }
    const uploadRoomObserverSheet = new UploadRoomObserverSheetCostumer(inputs, id, '', elementId);

    setIsLoading(true);
    uploadRoomObserverSheet.put().then(response => {
      if (response.error) {
        setIsLoading(false);
        return;
      }

      setElementarySchoolUploadRoomObserverSheet(response);

      setDisplayFormEditUploadRoomObserverSheet(false);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  };

  const inputFileEditHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  return (
    <div className={`form-edit-upload-room-observer-sheet display-${String(displayFormEditUploadRoomObserverSheet)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Editar Ficha de Observação de Sala
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormEditUploadRoomObserverSheet(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-edit-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-file-element">
          <label htmlFor="file-edit-input-upload-room-observer-sheet">
            <DisplayFileInfoTwo file={fileInfo} />
          </label>
          <input
            type="file"
            id='file-edit-input-upload-room-observer-sheet'
            onChangeCapture={inputFileEditHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
