/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useState, useEffect } from 'react';
import './style.scss';
import Header from '../../components/Header';
import Nav from '../../components/rooms-files/Nav-room';
import RoomContainer from '../../container/Room-files/room-container';
import Matter from '../../container/matters/matters';
import ProgrammaticContent from '../../container/programmatic-content/programmatic-content';
import CardReport from '../../container/cardReport-files/cardReport';
import Frequency from '../../container/frequency-files/frequency';
import FinalResult from '../../container/final-result-files/final-result';
import WritingLevelRecordContainer from '../../container/writing-level-record/writing-level-record';
import WritingLevelRecordContainerFinalResult from '../../container/writing-level-record-final-result/writing-level-record-final-result';
import SpellingLevel from '../../container/spelling-level/spelling-level';
import ClassRoomMonitoringProtocol from '../../container/classroom-monitoring-protocol/classroom-monitoring';
import JustificationOfAbsencesContainer from '../../container/justification-of-absences/room-container';
import UploadRoomObserverSheet from '../../container/upload-room-observer-sheet/room-observer-sheet';
import IndividualCardReport from '../../container/Individual-card-report/individual-card-report';
import axios from 'axios';
import config from '../../config/config';
import LoadContent from '../../components/Load-content';
import StudentObserverContainer from '../../container/student-observer/room-container';
import ATACardReport from '../../container/ATA/ATA';
import RoomObserver from '../../container/addendum-to-the-school-calendar/addendum-to-the-school-calendar';
import MathematicsDiagnosticAssessment from '../../container/mathematics-diagnostic-assessment/mathematics-diagnostic-assessment';
import MathematicsDiagnosticAssessmentGeneral from '../../container/mathematics-diagnostic-assessment-general/mathematics-diagnostic-assessment-general';
import MathematicsProblemSolvingTable from '../../container/mathematics-problem-solving/table';
import MathematicsProblemSolving from '../../container/mathematics-problem-solving/mathematics-problem-solving';
import MathematicsProblemSolvingGeneral from '../../container/mathematics-problem-solving-general/mathematics-problem-solving-general';
import AddTextualGenre from '../../container/new-writing-hypothesis/add-textual-genre/add-textual-genre';
import { NewWritingHypothesis } from '../../container/new-writing-hypothesis/writing-hyposthesis/writing-hypothesis';
import GraphNewWritingHypothesis from '../../container/new-writing-hypothesis/graph/graph';
import GraphNewWritingHypothesisGeneral from '../../container/new-writing-hypothesis/graph-genral/graph';
import NewWritingTheName from '../../container/new-writing-hypothesis/new-writing-name/new-writing-name';

type Elements = Array<{
  name: string
  boolean: string
}>;

function Room() {
  const [elements, setElements] = useState([
    {
      name: 'strategy-1',
      boolean: 'false'
    },
    {
      name: 'strategy-2',
      boolean: 'false'
    },
    {
      name: 'strategy-3',
      boolean: 'false'
    },
    {
      name: 'strategy-4',
      boolean: 'false'
    },
    {
      name: 'strategy-5',
      boolean: 'false'
    },
    {
      name: 'strategy-6',
      boolean: 'false'
    },
    {
      name: 'strategy-7',
      boolean: 'false'
    },
    {
      name: 'strategy-8',
      boolean: 'false'
    },
    {
      name: 'strategy-9',
      boolean: 'false'
    },
    {
      name: 'strategy-10',
      boolean: 'false'
    },
    {
      name: 'strategy-11',
      boolean: 'false'
    },
    {
      name: 'strategy-12',
      boolean: 'false'
    },
    {
      name: 'strategy-13',
      boolean: 'false'
    },
    {
      name: 'strategy-14',
      boolean: 'false'
    },
    {
      name: 'strategy-15',
      boolean: 'false'
    },
    {
      name: 'strategy-16',
      boolean: 'false'
    },
    {
      name: 'strategy-17',
      boolean: 'false'
    },
    {
      name: 'strategy-18',
      boolean: 'false'
    },
    {
      name: 'strategy-19',
      boolean: 'false'
    },
    {
      name: 'strategy-20',
      boolean: 'false'
    },
    {
      name: 'strategy-21',
      boolean: 'false'
    },
    {
      name: 'strategy-22',
      boolean: 'false'
    },
    {
      name: 'strategy-23',
      boolean: 'false'
    },
    {
      name: 'strategy-24',
      boolean: 'false'
    },
    {
      name: 'strategy-25',
      boolean: 'false'
    }
  ]);

  const [activityInfo, setActivityInfo] = useState({});

  const [display1, setDisplay1] = useState<string>('false');
  const [display2, setDisplay2] = useState<string>('false');
  const [display3, setDisplay3] = useState<string>('false');
  const [display4, setDisplay4] = useState<string>('false');
  const [display5, setDisplay5] = useState<string>('false');
  const [display6, setDisplay6] = useState<string>('false');
  const [display7, setDisplay7] = useState<string>('false');
  const [display8, setDisplay8] = useState<string>('false');
  const [display9, setDisplay9] = useState<string>('false');
  const [display10, setDisplay10] = useState<string>('false');
  const [display11, setDisplay11] = useState<string>('false');
  const [display12, setDisplay12] = useState<string>('false');
  const [display13, setDisplay13] = useState<string>('false');
  const [display14, setDisplay14] = useState<string>('false');
  const [display15, setDisplay15] = useState<string>('false');
   const [display16, setDisplay16] = useState<string>('false');
   const [display17, setDisplay17] = useState<string>('false');
   const [display18, setDisplay18] = useState<string>('false');
   const [display19, setDisplay19] = useState<string>('false');
   const [display20, setDisplay20] = useState<string>('false');
   const [display21, setDisplay21] = useState<string>('false');
   const [display22, setDisplay22] = useState<string>('false');
   const [display23, setDisplay23] = useState<string>('false');
   const [display24, setDisplay24] = useState<string>('false');
   const [display25, setDisplay25] = useState<string>('false');

  const [isLoadingPermission, setIsLoadingPermission] = useState(true);

  const huddlesElements = (elementsParams: Elements) => {
    setElements(elementsParams);
    setDisplay1(elements[0].boolean);
    setDisplay2(elements[1].boolean);
    setDisplay3(elements[2].boolean);
    setDisplay4(elements[3].boolean);
    setDisplay5(elements[4].boolean);
    setDisplay6(elements[5].boolean);
    setDisplay7(elements[6].boolean);
    setDisplay8(elements[7].boolean);
    setDisplay9(elements[8].boolean);
    setDisplay10(elements[9].boolean);
    setDisplay11(elements[10].boolean);
    setDisplay12(elements[11].boolean);
    setDisplay13(elements[12].boolean);
    setDisplay14(elements[13].boolean);
    setDisplay15(elements[14].boolean);
    setDisplay16(elements[15].boolean);
    setDisplay17(elements[16].boolean);
    setDisplay18(elements[17].boolean);
    setDisplay19(elements[18].boolean);
    setDisplay20(elements[19].boolean);
    setDisplay21(elements[20].boolean);
    setDisplay22(elements[21].boolean);
    setDisplay23(elements[22].boolean);
    setDisplay24(elements[23].boolean);
    setDisplay25(elements[24].boolean);
  };

  useEffect(() => {
    axios.get(config.apiUrl).then(() => {
      setIsLoadingPermission(false);
      elements[0].boolean = 'true';
      huddlesElements(elements)
    })
  }, []);

  return (
		<div className='App'>
			<Header />
			<main className='main-container-room'>
				<Nav setElements={huddlesElements} elements={elements} setActivityInfo={setActivityInfo} />
				<div className='content-containers'>
        <LoadContent isLoading={isLoadingPermission} />
					<div className='content' id={`display-${display1}`}>
						<RoomContainer display={display1} id='room-element' />
					</div>
					<div className='content' id={`display-${display2}`} >
						<Matter display={display2} id='matter-element' />
					</div>
					<div className='content' id={`display-${display3}`} >
						<ProgrammaticContent id='activity-element' activityInfo={activityInfo} />
					</div>
					<div className='content' id={`display-${display4}`}>
						<CardReport display={display4} id='cardReport-element' cardReport={activityInfo}/>
					</div>
					<div className='content' id={`display-${display5}`}>
						<Frequency display={display5} id='frequency-element' activityInfo={activityInfo} />
					</div>
					<div className='content' id={`display-${display6}`}>
						<FinalResult display={display6} id='frequency-element' activityInfo={activityInfo} />
					</div>
					<div className='content' id={`display-${display7}`}>
						<WritingLevelRecordContainer display={display7} id='frequency-element' activityInfo={activityInfo} />
					</div>
					<div className='content' id={`display-${display8}`}>
						<WritingLevelRecordContainerFinalResult id='frequency-element' display={display8}/>
					</div>
					<div className='content' id={`display-${display9}`}>
						<SpellingLevel display={display9} id='frequency-element' activityInfo={activityInfo}/>
					</div>
					<div className='content' id={`display-${display10}`}>
						<ClassRoomMonitoringProtocol id='classroom-monitoring-protocol'/>
					</div>
					{/* <div className='content' id={`display-${display11}`}>
						<RoomsObservationSheet id='classroom-monitoring-protocol'/>
					</div> */}
					<div className='content' id={`display-${display12}`}>
						<JustificationOfAbsencesContainer display={display12} roomInformation={activityInfo} id='classroom-monitoring-protocol'/>
					</div>
					<div className='content' id={`display-${display11}`}>
						<UploadRoomObserverSheet display={display11} id='classroom-monitoring-protocol'/>
					</div>
					<div className='content' id={`display-${display13}`}>
						<IndividualCardReport id='classroom-monitoring-protocol'/>
					</div>
					<div className='content' id={`display-${display14}`}>
						<StudentObserverContainer roomInformation={activityInfo} display={display14} id='classroom-monitoring-protocol'/>
					</div>
					<div className='content' id={`display-${display15}`}>
						<ATACardReport id='classroom-monitoring-protocol'/>
					</div>
					<div className='content' id={`display-${display16}`}>
						<RoomObserver display={display16} roomInformation={activityInfo} id='classroom-monitoring-protocol'/>
					</div>
					<div className='content' id={`display-${display17}`}>
						<MathematicsDiagnosticAssessment display={display17} roomInformation={activityInfo} id='classroom-monitoring-protocol'/>
					</div>
					<div className='content' id={`display-${display18}`}>
						<MathematicsDiagnosticAssessmentGeneral display={display18}/>
					</div>
					<div className='content' id={`display-${display19}`}>
						<MathematicsProblemSolving display={display19} roomInfo={activityInfo}/>
					</div>
					<div className='content' id={`display-${display20}`}>
						<MathematicsProblemSolvingGeneral display={display20}/>
					</div>
					<div className='content' id={`display-${display21}`}>
						<AddTextualGenre display={display21} roomInfo={activityInfo}/>
					</div>
          <div className='content' id={`display-${display22}`}>
            <NewWritingHypothesis display={display22} roomInfo={activityInfo} />
          </div>
          <div className='content' id={`display-${display23}`}>
            <GraphNewWritingHypothesis display={display23} roomInfo={activityInfo} />
          </div>
          <div className='content' id={`display-${display24}`}>
            <GraphNewWritingHypothesisGeneral display={display24} />
          </div>
          <div className='content' id={`display-${display25}`}>
            <NewWritingTheName display={display25} roomInfo={activityInfo} />
          </div>
				</div>
			</main>
		</div>
  );
}

export default Room;
