import axios from 'axios';
import { type SpellingLevelApiResType } from '../types/SpellingLevel/spellingLevelType';
import config from '../../config/config';

class SpellingLevelApi implements SpellingLevelApi {
	private get href() {
		return config.apiUrl;
	}

	async post(spellingLevel: SpellingLevelApiResType, roomId: string, twoMonthsName: string): Promise<SpellingLevelApiResType[]> {
		let newSpellingLevel: SpellingLevelApiResType[] = [];

		if (this.href) {
			await axios.post(`${this.href}/post/spellingLevel/${roomId}/${twoMonthsName}`, spellingLevel).then(response => {
				newSpellingLevel = response.data as SpellingLevelApiResType[];
			});
		}

		return newSpellingLevel;
	}

	async getAll(roomId: string, twoMonthsName: string): Promise<SpellingLevelApiResType[]> {
		let newSpellingLevel: SpellingLevelApiResType[] = [];

		if (this.href) {
			await axios.get(`${this.href}/get/spellingLevel/${roomId}/${twoMonthsName}`).then(response => {
				newSpellingLevel = response.data as SpellingLevelApiResType[];
			});
		}

		return newSpellingLevel;
	}

	async put(spellingLevel: SpellingLevelApiResType, roomId: string, twoMonthsName: string, spellingLevelId: string): Promise<SpellingLevelApiResType[]> {
		let newSpellingLevel: SpellingLevelApiResType[] = [];

		if (this.href) {
			await axios.put(`${this.href}/update/spellingLevel/${roomId}/${twoMonthsName}/${spellingLevelId}`, spellingLevel).then(response => {
				newSpellingLevel = response.data as SpellingLevelApiResType[];
			});
		}

		return newSpellingLevel;
	}

	async delete(roomId: string, twoMonthsName: string, spellingLevelId: string): Promise<SpellingLevelApiResType[]> {
		let newSpellingLevel: SpellingLevelApiResType[] = [];

		if (this.href) {
			await axios.delete(`${this.href}/delete/spellingLevel/${roomId}/${twoMonthsName}/${spellingLevelId}`).then(response => {
				newSpellingLevel = response.data as SpellingLevelApiResType[];
			});
		}

		return newSpellingLevel;
	}
}

export default new SpellingLevelApi();
