/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { type KindergartenSchoolMonthlyPlanType } from '../../../class/types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';
import returnMaxNumber from './service/returnMaxNumber';
import JustificationOfAbsencesAPI from '../../../class/JustificationOfAbsences/JustificationOfAbsencesAPI';
import BackToTop from '../../../components/back-to-top/back-to-top';

interface VisualizationElementarySchoolMonthlyPlanProps {
  displayViewElementarySchoolMonthlyPlan: boolean
  setDisplayViewElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
  elementarySchoolMonthlyPlans: KindergartenSchoolMonthlyPlanType[]
  elementId: string
}

export default function VisualizationElementarySchoolMonthlyPlan({
  displayViewElementarySchoolMonthlyPlan,
  elementId,
  elementarySchoolMonthlyPlans,
  setDisplayViewElementarySchoolMonthlyPlan
}: VisualizationElementarySchoolMonthlyPlanProps): JSX.Element {
  const [element, setElement] = useState<KindergartenSchoolMonthlyPlanType>();
  const [maxNumber, setMaxNumber] = useState<number>(0)
  const mainElement = useRef<any>()

  useEffect(() => {
    const element = elementarySchoolMonthlyPlans.find((value) => value.id === elementId);

    if (!element) {
      return;
    }

    const maxNumber = returnMaxNumber(element)

    setMaxNumber(maxNumber);

    setElement(element);
  }, [displayViewElementarySchoolMonthlyPlan]);

  const back = () => {
    setDisplayViewElementarySchoolMonthlyPlan(false);
  };

  return (
    <div
      className={`visualization-kindergarten-school-monthly-plan display-${displayViewElementarySchoolMonthlyPlan}`}
      ref={mainElement}
    >
      <BackToTop element={(mainElement.current as HTMLElement)} />
      <div className='button-content'>
        <button type='button' onClick={back}>Voltar</button>
      </div>
      <header>
        <h1>Plano Mensal Educação Infantil - {element?.class}</h1>
      </header>
      <div className='title-content'>
        <h1>
          Escola: <span>{element?.school}</span>
        </h1>
        <h1>
          Professor(a): <span>{element?.teacher}</span>
        </h1>
        <h1>
          Turma: <span>{element?.class}</span>
        </h1>
        <h1>
          Ano: <span>{element?.yearn}</span>
        </h1>
        <h1>
          Objetivo geral: <span>{element?.strategicObjective}</span>
        </h1>
        <h1>
          Objetivos específicos: <span>{element?.specificObjective}</span>
        </h1>
        <h1>
          Direitos de Aprendizagem e Desenvolvimento na Educação Infantil: <span>{element?.ldrece}</span>
        </h1>
        <h1>
          Estratégias/Recursos: <span>{element?.strategyEndResource}</span>
        </h1>
      </div>
      <div className='table-content'>
        <table className='table-element'>
          <thead>
            <tr>
              <td>CAMPOS DE EXPERIÊNCIAS</td>
              <td>OBJETIVOS DE APRENDIZAGEM E DESENVOLVIMENTO</td>
              <td>TEMAS</td>
              <td>RECURSO</td>
            </tr>
          </thead>
          <tbody>
            {[...'z-'.repeat(!maxNumber ? 0 : maxNumber - 1).split('-')].map((v, index) => {
              return (
                <tr>
                  <td>{element?.methodology.composeOfExperience.split(' - ')[index] ?? ''}</td>
                  <td>{element?.methodology.learningObjectiveAndDevelopment.split(' - ')[index] ?? ''}</td>
                  <td>{element?.methodology.topics.split(' - ')[index] ?? ''}</td>
                  <td>{element?.methodology.resource.split(' - ')[index] ?? ''}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className='commentary-content'>
        <h1>Comentário</h1>
        <div>
          <p>{element?.commentary}</p>
        </div>
      </div>
    </div>
  );
}
