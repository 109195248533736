
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect, useRef } from 'react';
import ContentTableStudent from '../../../components/student-files/ContentTable';
import FormEditElement from '../FormEditElement-students';
import EmptyComponent from '../../../components/Empty-component';
import FormAddElement from '../FormAddElement-students';
import SearchElement from '../../../components/Search-element';
import './style.scss';
import StudentAPI from '../../../class/Student/StudentAPI';
import BackToTop from '../../../components/back-to-top/back-to-top';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [elementId, setElementId] = useState<string>();
  const [students, setStudents] = useState<any[]>([]);
  const [studentFiltered, setStudentFiltered] = useState<any[]>([]);
  const mainElement = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);

  const huddleCreateNewElement = () => {
    setDisplayCreateNewElement(true);
  };

  useEffect(() => {
    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    StudentAPI.get().then(response => {
      setIsLoading(false);
      setStudents(response);
      setStudentFiltered(response);

      let count = 0;

      response.map(students => {
        if (students.situation === 'Ativo') {
          count++;
        }
        return students;
      });
    });
  }, [display]);

  return (
    <div className='table-container-student'>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setStudents={setStudents}
        setStudentsFiltered={setStudentFiltered}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        setDisplayEditElement={setDisplayEditElement}
        setStudents={setStudents}
        setStudentsFiltered={setStudentFiltered}
        students={students}
        elementId={elementId}
      />
      <div className='btn-create-new-student-container'>
        <button onClick={huddleCreateNewElement}>Adicionar novo aluno</button>
      </div>
      <SearchElement
        elements={students}
        elementKey={'studentName'}
        elementFiltered={studentFiltered}
        setElementFiltered={setStudentFiltered}
      />
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Nome</li>
          <li className='header-table-ul-li'>Responsável</li>
          <li className='header-table-ul-li'>RA</li>
          <li className='header-table-ul-li'>UF</li>
          <li className='header-table-ul-li'>Bairro</li>
          <li className='header-table-ul-li'>Situação</li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      {!isLoading
        ? (
          <main className='main-table' ref={mainElement}>
            {display === 'true'
              ? (
                studentFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar novo aluno) para adicionar um novo aluno</EmptyComponent>
                  : studentFiltered?.map((student: any, index: number) => (
                    <ContentTableStudent
                      index={index}
                      student={student}
                      setDisplayEditElement={setDisplayEditElement}
                      setStudents={setStudentFiltered}
                      setElementId={setElementId}
                      key={String(student.id)}
                    />
                  ))
              )
              : ''}
          </main>
        )
        : <LoadContent isLoading={isLoading} />}
    </div>
  );
}
