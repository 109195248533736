import React from 'react';
import Table from '../Table';
import './style.scss';

interface EstrategyProps {
  id: string
  display: string
}

export default function Coordinator({ id, display }: EstrategyProps): React.ReactElement {
  return (
		<div className={'coordinator-container'} id={id}>
			<Table display={display} />
		</div>
  );
}
