
/* eslint-disable @typescript-eslint/naming-convention */
import type { StudentWritingLRType, TagTitleList, TagType } from '../types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import type StudentWritingLRFactoryProtocol from '../types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import type { StudentType } from '../types/Student/student-factory-protocol';

class StudentWritingLRFactory implements StudentWritingLRFactoryProtocol {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly students: StudentType[] = []
	) {}

	create(): StudentWritingLRType {
		const inputFilteredByStudent = this.filterInputByStudent();

		const studentWritingLRObj = this.constructObj(inputFilteredByStudent);

		return studentWritingLRObj;
	}

	filterInputByStudent(): HTMLInputElement[][] {
		const filteredInputsByStudent: HTMLInputElement[][] = [];

		this.students.forEach(student => {
			const inputs: HTMLInputElement[] = [];

			this.inputs.forEach(input => {
				const inputStudentId = input.id.split(' ').pop();
				if (student.id === inputStudentId) {
					inputs.push(input);
				}
			});
			filteredInputsByStudent.push(inputs);
		});

		return filteredInputsByStudent;
	}

	constructObj(inputFilteredByStudent: HTMLInputElement[][]): StudentWritingLRType {
		const studentWritingLRObj: StudentWritingLRType = [];

		this.students.forEach((student, index) => {
			if (!student.id) {
				return;
			}

      const obj: any = {
        studentId: student.id ?? '',
				studentName: student.studentName,
				writeTheName: inputFilteredByStudent[index][0].checked,
				writingLevel: inputFilteredByStudent[index][1].value,
			};

      inputFilteredByStudent[index].forEach((input, index) => {
        if (index > 1) {
          obj[`element${String(index - 1)}`] = input.value;
        }
      });

			studentWritingLRObj.push(obj);
		});

		return studentWritingLRObj;
	}

	createTagObj(): TagType {
		return {
			name: this.inputs[0].value,
			color: this.inputs[1].value
		};
	}

  createTagTitleList(): TagTitleList {
    return {
      name: this.inputs[0].value,
    }
  }
}

export default StudentWritingLRFactory;
