import React from 'react';
import Table from '../table';
import './styles.scss';

interface MatheMaticsDiagnosticAssessmentGeneralProps {
  display: string
}

export default function MathematicsDiagnosticAssessmentGeneral({display}: MatheMaticsDiagnosticAssessmentGeneralProps): React.ReactElement {
  return (
    <div className='mathematics-diagnostic-assessment-general-container'>
      <Table display={display} />
    </div>
  )
}
