import React from 'react';
import './styles.scss';
import { type QuestionContentType, type QuestionElementType } from '../../../class/types/student-exams/student-exams-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import StudentExamsCostumer from '../../../class/Student-Exams/student-exams-costumer';
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import { useParams } from 'react-router-dom';

interface ImageElementContentProps {
  element: QuestionElementType
  elementId: string
  questionId: string
  setQuestion: React.Dispatch<React.SetStateAction<QuestionContentType[]>>
}

export default function ImageElementContent({
  element,
  elementId,
  questionId,
  setQuestion,
}: ImageElementContentProps): React.ReactElement {
  const {id} = useParams();

  const huddleDelete = () => {
    const studentExams = new StudentExamsApi();

    if (!id || !questionId || !elementId || !element.img?.fileName) {
      return;
    }

    studentExams.deleteQuestionElementImg(id, questionId, elementId, element.img?.fileName).then(response => {
      //
      setQuestion(response);
    }).catch(e => {

    });
  }

  return (
    <div className='image-element-content'>
      <div className='image-element-content-header'>
        <h1>IMAGEM</h1>
      </div>
      <div className='img-content'>
        <img src={element.img?.url} alt='' />
        <IoCloseOutline className='icon-content' onClick={huddleDelete} />
      </div>
    </div>
  );
}
