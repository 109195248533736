import React from 'react';
import Table from '../Table';
import './style.scss';

interface WritingLevelRecordContainerProps {
  id: string
}

export default function ReadingFluencyPage({ id}: WritingLevelRecordContainerProps): React.ReactElement {
  return (
		<div className={'writing-level-record-container'} id={id}>
			<Table />
		</div>
  );
}
