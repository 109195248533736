/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect, useRef } from 'react';
import EmptyComponent from '../../../components/Empty-component';
import FormAddElement from '../FormAddElement-digital-HTTPI';
import './style.scss';
import BackToTop from '../../../components/back-to-top/back-to-top';
import KindergartenSchoolDigitalHTTPIApi from '../../../class/KindergartenDigital-HTTPI/kindergartenDigitalHTTPIApi';
import ContentTableDigitalHTTPI from '../../../components/digital-HTTPI';
import type DigitalHTTPIResProtocol from '../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import FormEditElement from '../FormEditElement-digital-HTTPI';
import DayOfHTTPI from '../DayOfHTTPI/HTTPI';
import lscache from 'lscache';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import SearchDigitalHTTPI from '../../../components/Search-element-httpi';
import filterHTTPIByDate from './service/filterByDate';
import ReadCheckingDigitalHTTPI from '../../../components/read-checking-digital-HTTPI';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [elementId, setElementId] = useState<string>('');
  const [element, setElement] = useState<any>();
  const [displayDayOfHTTPI, setDisplayDayOfHTTPI] = useState(false);
  const [displayChecked, setDisplayChecked] = useState(false);
  const [kindergartenSchoolDigitalHTTPI, setKindergartenSchoolDigitalHTTPI] = useState<DigitalHTTPIResProtocol[]>([]);
  const [kindergartenSchoolDigitalHTTPIFiltered, setKindergartenSchoolDigitalHTTPIFiltered] = useState<DigitalHTTPIResProtocol[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainElement = useRef<any>();

  const afterDate = useRef<HTMLInputElement | null>(null);
  const beforeDate = useRef<HTMLInputElement | null>(null);

  const huddleCreateNewElement = () => {
    setDisplayCreateNewElement(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!afterDate || !beforeDate) {
      return;
    }

    if (!afterDate.current?.value || !beforeDate.current?.value) {
      setKindergartenSchoolDigitalHTTPIFiltered(kindergartenSchoolDigitalHTTPI)
      return;
    }

    setKindergartenSchoolDigitalHTTPIFiltered(filterHTTPIByDate(kindergartenSchoolDigitalHTTPIFiltered, afterDate.current?.value ?? '', beforeDate.current?.value ?? ''));
  }

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    KindergartenSchoolDigitalHTTPIApi.get().then(response => {
      setIsLoading(false);
      if (user.permission?.rule === 'professor') {
        const responseFilter = response.filter(value => value.issue?.id === user.id);
        setKindergartenSchoolDigitalHTTPI(responseFilter);
        return
      }
      setKindergartenSchoolDigitalHTTPI(response);
    });
  }, [display]);

  useEffect(() => {
    setKindergartenSchoolDigitalHTTPIFiltered(kindergartenSchoolDigitalHTTPI);
  }, [kindergartenSchoolDigitalHTTPI]);

  return (
    <div className='table-container-digital-httpi'>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setKindergartenSchoolDigitalHTTPI={setKindergartenSchoolDigitalHTTPI}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        setDisplayEditElement={setDisplayEditElement}
        setKindergartenSchoolDigitalHTTPI={setKindergartenSchoolDigitalHTTPI}
        kindergartenSchoolDigitalHTTPI={kindergartenSchoolDigitalHTTPI}
        elementId={elementId}
      />
      <DayOfHTTPI
        display={displayDayOfHTTPI}
        setDisplay={setDisplayDayOfHTTPI}
        element={element}
      />
      <ReadCheckingDigitalHTTPI
        displayReadChecking={displayChecked}
        elementId={elementId}
        setDisplayReadChecking={setDisplayChecked}
        setElementaryDigitalHTTPI={setKindergartenSchoolDigitalHTTPI}
        type='kindergarten-school'
      />
      <div className='header-digital-httpi'>
        <h1>Ensino Infantil - HTTPI Digital</h1>
      </div>
      <div className='btn-create-new-student-container'>
        <button onClick={huddleCreateNewElement}>Adicionar HTTPI</button>
      </div>
      <div className='search-digital-httpi-content'>
        <SearchDigitalHTTPI
          elementFiltered={kindergartenSchoolDigitalHTTPIFiltered}
          elements={kindergartenSchoolDigitalHTTPI}
          setElementFiltered={setKindergartenSchoolDigitalHTTPIFiltered}
        />
        <form method='POST' className='input-date-content' onSubmitCapture={huddleSubmit}>
          <div className='date-content' >
            <div className='input-content'>
              <input type="month" ref={afterDate} />
            </div>
            <h2>A</h2>
            <div className='input-content'>
              <input type="month" ref={beforeDate} />
            </div>
          </div>
          <div className='button-content'>
            <button>Buscar</button>
          </div>
        </form>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Nome</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-content'>Visualização</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '8rem'
              }
            }}
          />
          : (
            display === 'true'
              ? (
                kindergartenSchoolDigitalHTTPIFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar HTTPI) para adicionar um novo HTTPI</EmptyComponent>
                  : kindergartenSchoolDigitalHTTPIFiltered?.map((digitalHTTPI: any, index: number) => (
                    <ContentTableDigitalHTTPI
                      index={index}
                      type={'kindergartenSchool'}
                      digitalHTTPI={digitalHTTPI}
                      setDisplayEditElement={setDisplayEditElement}
                      setDisplayChecked={setDisplayChecked}
                      setElement={setElement}
                      setDisplayDigitalHTTPI={setDisplayDayOfHTTPI}
                      setDigitalHTTPI={setKindergartenSchoolDigitalHTTPI}
                      setElementId={setElementId}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}
