/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, {useState, useEffect} from 'react';
import { MdDelete } from 'react-icons/md';
import './style.scss';
import LoadContent from '../../Load-content';
import { FiEdit2, FiRepeat } from 'react-icons/fi';
import type NotificationType from '../../../class/types/notification';
import NotificationAPI from '../../../class/Notification/NotificationAPI';

interface ContentTableNotificationType {
  index: number
  notification: NotificationType
  setNotification: React.Dispatch<React.SetStateAction<NotificationType[]>>
  setNotificationSelected: React.Dispatch<React.SetStateAction<NotificationType>>
  setDisplayNotificationInformation: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayEdit: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ContentTableNotification({
  index,
  notification,
  setNotificationSelected,
  setDisplayNotificationInformation,
  setNotification,
  setDisplayEdit,
}: ContentTableNotificationType): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);

  // const huddleDelete = () => {
  //   setIsLoading(true);
  //   StudentAPI.deleteStudent(String(student.id)).then(response => {
  //     setStudents(response);
  //     setIsLoading(false);
  //   });
  // };

  const countVisualization = () => {
    if (!notification.visualization) {
      return 0;
    }
    const keys = Object.keys(notification.visualization);

    return keys.length;
  }

  const huddleDisplayNotificationInformation = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    const target = e.target as HTMLUListElement;

    if (['path', 'svg'].includes(target.tagName)) {
      return;
    }

    setNotificationSelected(notification);
    setDisplayNotificationInformation(true);
  }

  const huddleDelete = () => {
    if (!notification.id) {
      return;
    }
    setIsLoading(true);
    NotificationAPI.delete(notification.id).then(response => {
      setIsLoading(false);
      setNotification(response);
    });
  }

  const resendNotification = () => {
    if (!notification.id) {
      return;
    }

    NotificationAPI.resendNotification(notification.id).then(response => {
      setNotification(response);
    })
  }

  // const formateDate = (_date: string | undefined) => {
  //   if (!_date) {
  //     return '';
  //   }
  //   return (new Date(_date)).toLocaleDateString('pt-BR', {
  //     timeZone: 'America/Sao_Paulo',
  //     dateStyle: 'short'
  //   });
  // }

  const huddleEdit = () => {
    setDisplayEdit(true);
    setNotificationSelected(notification);
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [notification]);

  if (!notification) {
    return <></>;
  }

  return (
		<div className={`content-table-element-notification display-animation-${displayAnimation}`}>
			<ul className='content-table-ul' onClickCapture={(e) => {
        huddleDisplayNotificationInformation(e);
      }}>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{!notification.title ? '' : notification.title}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{!notification.issuer ? '' : notification.issuer.name}
					</h2>
				</li>
				<li className='content-table-ul-li notification-visualization-content'>
					<h2>
						{countVisualization()}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{notification.date ?? ''}
					</h2>
				</li>
        <li className='content-table-ul-li'>
					<FiRepeat size='20' style={{ color: '#118951' }} onClickCapture={resendNotification} />
				</li>
        <li className='content-table-ul-li'>
					<FiEdit2 size='20' style={{ color: '#118951' }} onClickCapture={huddleEdit} />
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClickCapture={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
			</ul>
		</div>
  );
}
