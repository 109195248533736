import React, { useState } from 'react';
import './styles.scss';
import type MedicalRecordType from '../../../class/types/Psychologist';
import { MdDelete } from 'react-icons/md'
import { FiEdit2 } from 'react-icons/fi';
import PsychologistApi from '../../../class/Psychologist';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol'
import LoadContent from '../../Load-content';
import { type PsychologistAnamnese } from '../../../class/types/Psychologist';

interface TableContentMedicalRecordProps {
  medicalRecord: MedicalRecordType
  student: PsychologistAnamnese
  setMedicalRecord: React.Dispatch<React.SetStateAction<MedicalRecordType[]>>
  huddleDisplayEdiRegister: () => void
  setMedicalRecordSelected: React.Dispatch<React.SetStateAction<MedicalRecordType | undefined>>
}

export default function TableContentMedicalRecord({ medicalRecord, student, setMedicalRecord, huddleDisplayEdiRegister, setMedicalRecordSelected }: TableContentMedicalRecordProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  }

  const huddleDelete = () => {
    const psychologistApi = new PsychologistApi();

    if (!student.id || !medicalRecord.id) {
      return;
    }

    setIsLoading(true);
    psychologistApi.deleteMedicRecord(student.id, medicalRecord.id).then(response => {
      setIsLoading(false);
      setMedicalRecord(response);
    }).catch(e => {
      //
    });
  }
  const editClick = () => {
    huddleDisplayEdiRegister();
    setMedicalRecordSelected(medicalRecord);
  }

  return (
    <ul className='table-content-medical-record-psychologist'>
      <li className='date-content'>
        <h1>{formatDate(medicalRecord.date)}</h1>
      </li>
      <li>
        <h1>{medicalRecord.observation}</h1>
      </li>
      <div className='button-content-psychologist'>
        <button onClickCapture={editClick}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </button>
        <button onClickCapture={huddleDelete}>
          {isLoading
            ? (
              <LoadContent isLoading={isLoading} style={{
                container: {
                  width: 25,
                  height: 25,
                },
                button: {
                  width: 20,
                },
              }} />
            )
            : <MdDelete size='20' style={{ color: '#ff0000', cursor: 'pointer' }} />}
        </button>
      </div>
    </ul>
  );
}
