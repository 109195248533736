/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-floating-promises */
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import type DigitalHTTPIResProtocol from '../../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import autoTable from 'jspdf-autotable';
import { type HTTPIType } from '../../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';

const img = require('./img/header-img.png');

export default function generatePDF(digitalHTTPI: DigitalHTTPIResProtocol, daysOfHTTPI: HTTPIType[], totalTime: string) {
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      month: 'long',
      year: 'numeric'
    });
  }

  const formatDateShort = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short',
    })
  }

  const pdf = new jsPDF({
    orientation: 'landscape',
  });

  pdf.addImage(img, 'PNG', 55, 5, 180, 0);

  autoTable(pdf, {
    startY: 40,
    headStyles: {
      fillColor: '#118951',
      lineColor: 'rgb(172, 172, 172)',
      lineWidth: 0.1,
    },
    head: [
      [
        {
          content: `Data: ${formatDate(digitalHTTPI.date)}`,
          styles: {
            cellWidth: 30
          }
        },
        {
          content: `Professor: ${digitalHTTPI.issue?.name}`,
          colSpan: 1,
          styles: {
            cellWidth: 100
          }
        },
        {
          content: `Total de horas: ${totalTime}`,
          colSpan: 3,
        },
      ],
      ['Data', 'Atividade', {
        content: 'Horário',
        colSpan: 2,
      }, 'Total de horas'],
      ['', '', 'Inicio', 'Fim ', ''],
    ],
    bodyStyles: {
      lineColor: 'rgb(172, 172, 172)',
      lineWidth: 0.2,
    },
    body: (() => {
      const bodyArray: string[][] = [];

      daysOfHTTPI.map((day) => {
        bodyArray.push([
          `${formatDateShort(day.date)}`,
          `${day.activity}`,
          `${day.time.after}`,
          `${day.time.before}`,
          `${day.time.totalTime}`,
        ])

        return day;
      });

      return bodyArray;
    })()
  });

  pdf.save(`HTTPI ${digitalHTTPI.issue?.name} mes de ${formatDate(digitalHTTPI.date)}`);
}
