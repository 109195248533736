/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { type QuestionContentType, type QuestionElementType } from '../../../class/types/student-exams/student-exams-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import StudentExamsCostumer from '../../../class/Student-Exams/student-exams-costumer';

interface TitleElementContentProps {
  element: QuestionElementType
  questionId: string
  elementId: string
  setQuestions: React.Dispatch<React.SetStateAction<QuestionContentType[]>>
}
let timeId: NodeJS.Timeout;

export default function TextElementContent({element, questionId, elementId, setQuestions}: TitleElementContentProps): React.ReactElement {
  const {id} = useParams();

  const [textAreaText, setTextAreaText] = useState<string | undefined>(element.content);
  const [saved, setSaved] = useState<boolean>(false);

  const huddleDelete = () => {
    if (!id || !questionId || !elementId) {
      return;
    }

    const studentExams = new StudentExamsApi();

    studentExams.deleteQuestionElementTitle(id, questionId, elementId).then(response => {
      setQuestions(response);
    }).catch(e => {
      //
    });
  }

  const huddlePostText = (text: string) => {
    const studentExams = new StudentExamsCostumer(null, null, undefined, undefined);

    if (!id || !questionId) {
      return;
    }
    studentExams.putQuestionElementTitle(id, questionId, elementId, text, 'text').then(response => {
      setQuestions(response);
      huddleSave();
    }).catch(e => {
      //
    })
  }

  const huddleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;

    setTextAreaText(target.value);

    clearTimeout(timeId);

    timeId = setTimeout(() => {
      huddlePostText(target.value);
    }, 2000);
  }

  const huddleSave = () => {
    if (saved) {
      return;
    }

    setSaved(true);

    setTimeout(() => {
      setSaved(false);
    }, 200);
  }
  useEffect(() => {
    setTextAreaText(element.content)
  }, [element]);

  return (
    <div className='text-element-content' >
      <div className='header-text-element-content'>
        <h1>TEXT</h1>
      </div>
      <div className='text-element-input-content'>
        <textarea className={`saved-${String(saved)}`} value={textAreaText ?? 'Preencha o Campo Titulo'} onChangeCapture={huddleChange}/>
        <IoCloseOutline className='icon-content' onClick={huddleDelete}/>
      </div>
    </div>
  );
}
