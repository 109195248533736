/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useEffect, useState } from 'react';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import { useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import LoadContent from '../../Load-content';
import RoomsApi from '../../../class/Room/RoomsApi';
import lscache from 'lscache';
import './style.scss';

interface ContentTableType {
  student: StudentType
  index: number
  setDisplayStudentInfo: React.Dispatch<React.SetStateAction<boolean>>
  setStudentRoom: React.Dispatch<React.SetStateAction<StudentType[]>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
}

export default function ContentTableRoom({ student, index, setDisplayStudentInfo, setElementId, setStudentRoom }: ContentTableType): React.ReactElement {
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserClientProtocol>();
  const { id } = useParams();

  const hundleDelete = () => {
    if (student.id && typeof id === 'string') {
      setIsLoading(true);
      RoomsApi.deleteStudentOnRoo(id, student.id).then(response => {
        setStudentRoom(response);
        setIsLoading(false);
      });
    }
  };

  const hundleStudentInfo = (e: any) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }
    setDisplayStudentInfo(true);
    setElementId(String(student.id));
  };

  const formateDate = (_date: string | undefined) => {
    if (!_date) {
      return '';
    }
    return (new Date(_date)).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      dateStyle: 'short'
    })
  }

  useEffect(() => {
    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, []);

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [student]);

  return (
    <div className={`content-table-element-rom display-animation-${displayAnimation}`} onClickCapture={hundleStudentInfo}>
      <ul className='content-table-ul-rom'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            {student.studentName}
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {student.responsible}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {student.ra.content}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {student.ra.uf}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {student.district}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {student.situation}
            <span>
              {formateDate(student.situationModified)}
            </span>
          </h2>
        </li>
        {user
          ? (user.permission?.rule === 'coordenador' || user.permission?.rule === 'console'
            ? (
              isLoading
                ? (
                  <li>
                    <LoadContent
                      isLoading={isLoading}
                      style={{
                        button: {
                          fontSize: 20,
                          color: '#ff0000'
                        },
                        container: {
                          width: 20,
                        }
                      }}
                    />
                  </li>
                )
                : (
                  <li className='header-table-ul-li' onClick={hundleDelete}>
                    <MdDelete size='20' style={{ color: '#ff0000' }} />
                  </li>
                ))
            : (<li> </li>))
          : (<li></li>)}
      </ul>
    </div>
  );
}
