import React, { useState } from 'react';
import './styles.scss';
import { type QuestionContentType, type QuestionElementOptionType } from '../../../../class/types/student-exams/student-exams-protocol';
import { FaTrash } from 'react-icons/fa';
import StudentExamsApi from '../../../../class/Student-Exams/student-exams-api';
import { useParams } from 'react-router-dom';
import StudentExamsCostumer from '../../../../class/Student-Exams/student-exams-costumer';

interface OptionElementProps {
  option: QuestionElementOptionType | undefined
  elementId: string
  optionId: string
  questionId: string
  setQuestion: React.Dispatch<React.SetStateAction<QuestionContentType[]>>
}

let timeoutId: NodeJS.Timeout

export default function OptionElement({option, optionId, questionId, elementId, setQuestion}: OptionElementProps): React.ReactElement {
  const [optionInElement, setOptionInElement] = useState(option?.content ?? '');
  const [selected, setSelected] = useState<boolean>(typeof option?.selected !== 'boolean' ? false : option?.selected);

  const [saved, setSaved] = useState<boolean>(false);

  const {id} = useParams();

  const huddleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    setOptionInElement(target.value);

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      huddlePostText(target.value);
    }, 2000);
  }

  const huddlePostText = (text: string) => {
    if (!id || !questionId || !elementId || !optionId) {
      return;
    }

    const studentExams = new StudentExamsCostumer(null, null, undefined, undefined);

    studentExams.putQuestionElementOptionOfOptions(id, questionId, elementId, optionId, text).then(response => {
      setQuestion(response);
      huddleSave();
    }).catch(e => {
      //
    })
  }

  const huddleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!id || !questionId || !elementId || !optionId) {
      return;
    }

    const target = e.target as HTMLInputElement

    const studentExamsCostumer = new StudentExamsCostumer(null, null, undefined, undefined);

    setSelected(target.checked);

    studentExamsCostumer.setQuestionElementOptionSelectedOfOptions(id, questionId, elementId, optionId, target.checked).then(response => {
      setQuestion(response);
      huddleSave();
    }).catch(e => {
      //
    });
  }

  const huddleSave = () => {
    if (saved) {
      return;
    }

    setSaved(true);

    setTimeout(() => {
      setSaved(false);
    }, 200);
  }

  const huddleDelete = () => {
    if (!id || !questionId || !elementId || !optionId) {
      return;
    }

    const studentExamsApi = new StudentExamsApi();
    studentExamsApi.deleteQuestionElementOptionOfOptions(id, questionId, elementId, optionId).then(response => {
      setQuestion(response);
    }).catch(e => {
      //
    });
  }

  return (
    <div className={`option-element saved-${String(saved)}`}>
      <div className='option-element-checkbox-content'>
        <input type="checkbox" onChangeCapture={huddleSelect} checked={selected} />
      </div>
      <div className='option-element-text-content'>
        <input type="text" value={optionInElement} onChange={huddleChange} />
      </div>
      <div className='option-element-trash-content'>
        <FaTrash size={20} className='icon-content' onClickCapture={huddleDelete} />
      </div>
    </div>
  )
}
