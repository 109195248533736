import axios from 'axios';
import config from '../../config/config';
import type DigitalHTTPIResProtocol from '../types/DigitalHTTPI/digitalHTTPI-protocol';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type HTTPIType } from '../types/DigitalHTTPI/digitalHTTPI-protocol';

export default new (class KindergartenDigitalHTTPIApi {
  private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  async get(): Promise<DigitalHTTPIResProtocol[]> {
    let newKindergartenDigitalHTTPI: DigitalHTTPIResProtocol[] = [];

    await axios.get(`${this.href}/kindergartenschool/digitalHTTPI`).then(response => {
      newKindergartenDigitalHTTPI = response.data as DigitalHTTPIResProtocol[];
    });

    return newKindergartenDigitalHTTPI
  }

  async setChecked(kindergartenDigitalHTTPIId: string): Promise<DigitalHTTPIResProtocol[]> {
    let newKindergartenDigitalHTTPI: DigitalHTTPIResProtocol[] = [];

    await axios.post(`${this.href}/kindergartenschool/digitalHTTPI/checked/${kindergartenDigitalHTTPIId}`).then(response => {
      newKindergartenDigitalHTTPI = response.data as DigitalHTTPIResProtocol[];
    });

    return newKindergartenDigitalHTTPI;
  }

  async post(kindergartenDigitalHTTPI: DigitalHTTPIResProtocol): Promise<DigitalHTTPIResProtocol[]> {
    let newKindergartenDigitalHTTPI: DigitalHTTPIResProtocol[] = [];

    await axios.post(`${this.href}/kindergartenschool/digitalHTTPI`, kindergartenDigitalHTTPI).then(response => {
      newKindergartenDigitalHTTPI = response.data as DigitalHTTPIResProtocol[];
    });

    return newKindergartenDigitalHTTPI
  }

  async put(kindergartenDigitalHTTPI: DigitalHTTPIResProtocol, kindergartenDigitalHTTPIId: string): Promise<DigitalHTTPIResProtocol[]> {
    let newKindergartenDigitalHTTPI: DigitalHTTPIResProtocol[] = [];

    await axios.put(`${this.href}/kindergartenschool/digitalHTTPI/${kindergartenDigitalHTTPIId}`, kindergartenDigitalHTTPI).then(response => {
      newKindergartenDigitalHTTPI = response.data as DigitalHTTPIResProtocol[];
    });

    return newKindergartenDigitalHTTPI
  }

  async delete(kindergartenDigitalHTTPIId: string): Promise<DigitalHTTPIResProtocol[]> {
    let newKindergartenDigitalHTTPI: DigitalHTTPIResProtocol[] = [];

    await axios.delete(`${this.href}/kindergartenschool/digitalHTTPI/${kindergartenDigitalHTTPIId}`).then(response => {
      newKindergartenDigitalHTTPI = response.data as DigitalHTTPIResProtocol[];
    });

    return newKindergartenDigitalHTTPI
  }

  async postDayOfHTTPI(kindergartenDigitalHTTPIId: string, dayOfHTTPI: HTTPIType): Promise<HTTPIType[]> {
    let digitalHTTPI: HTTPIType[] = [];

    await axios.post(`${this.href}/kindergartenschool/digitalHTTPI/dayOfHTTPI/${kindergartenDigitalHTTPIId}`, dayOfHTTPI).then(response => {
      digitalHTTPI = response.data as HTTPIType[];
    });
    return digitalHTTPI;
  }

  async putDayOfHTTPI(kindergartenDigitalHTTPIId: string, dayOfHTTPIId: string, dayOfHTTPI: HTTPIType): Promise<HTTPIType[]> {
    let digitalHTTPI: HTTPIType[] = [];

    await axios.put(`${this.href}/kindergartenschool/digitalHTTPI/dayOfHTTPI/${kindergartenDigitalHTTPIId}/${dayOfHTTPIId}`, dayOfHTTPI).then(response => {
      digitalHTTPI = response.data as HTTPIType[];
    });

    return digitalHTTPI;
  }

  async deleteDayOfHTTPI(kindergartenDigitalHTTPI: string, dayOfHTTPIId: string): Promise<HTTPIType[]> {
    let digitalHTTPI: HTTPIType[] = [];

    await axios.delete(`${this.href}/kindergartenschool/digitalHTTPI/dayOfHTTPI/${kindergartenDigitalHTTPI}/${dayOfHTTPIId}`).then(response => {
      digitalHTTPI = response.data as HTTPIType[];
    });

    return digitalHTTPI;
  }

  async getDayOfHTTPI(kindergartenDigitalHTTPI: string): Promise<HTTPIType[]> {
    let digitalHTTPI: HTTPIType[] = [];

    await axios.post(`${this.href}/kindergartenschool/digitalHTTPI/dayOfHTTPI/${kindergartenDigitalHTTPI}`).then(response => {
      digitalHTTPI = response.data as HTTPIType[];
    });

    return digitalHTTPI;
  }
})()
