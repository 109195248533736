import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { StrategyOfPlanProtocol } from '../types/Strategy-of-plan/Strategy-of-plan-factory-protocol';
import type StrategyOfPlanApiProtocol from '../types/Strategy-of-plan/StrategyAPI-protocol';
import type { UserProtocol } from '../types/User/User-factory-protocol';

class StrategyOfPlanApi implements StrategyOfPlanApiProtocol {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async get(): Promise<StrategyOfPlanProtocol[]> {
		let strategies: StrategyOfPlanProtocol[] = [];

		if (this.href) {
			await axios.get(`${this.href}/get/plan/strategies`).then(response => {
				strategies = response.data as StrategyOfPlanProtocol[];
			});
		}

		return strategies;
	}

	async post(action: StrategyOfPlanProtocol): Promise<StrategyOfPlanProtocol[]> {
		let strategiesOfPlan: StrategyOfPlanProtocol[] = [];

		if (this.href) {
			await axios.post(`${this.href}/post/plan/strategy`, action).then(response => {
				strategiesOfPlan = response.data as StrategyOfPlanProtocol[];
			});
		}

		return strategiesOfPlan;
	}

	async delete(id: string): Promise<StrategyOfPlanProtocol[]> {
		let strategy: StrategyOfPlanProtocol[] = [];

		if (this.href) {
			await axios.delete(`${this.href}/delete/plan/strategy/${id}`).then(response => {
				strategy = response.data as StrategyOfPlanProtocol[];
			});
		}

		return strategy;
	}

	async put(strategy: StrategyOfPlanProtocol, id: string): Promise<StrategyOfPlanProtocol[]> {
		let strategies: StrategyOfPlanProtocol[] = [];

		if (this.href) {
			await axios.put(`${this.href}/update/plan/strategy/${id}`, strategy).then(response => {
				strategies = response.data as StrategyOfPlanProtocol[];
			});
		}

		return strategies;
	}
}

export default new StrategyOfPlanApi();
