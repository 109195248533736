
import axios from 'axios';
import type ElementarySchoolDecentWorkApi from '../types/Upload_ElementarySchoolDecenteWork';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type ElementarySchoolWeeklyPlanType, type ElementarySchoolWeeklyPlanPost, type ElementarySchoolWeeklyPlanPut } from '../types/Upload_ElementarySchoolWeeklyPlan';

class UploadWorkshopWeeklyPlanApi implements ElementarySchoolDecentWorkApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, classRoom, date, name, userName, userId }: ElementarySchoolWeeklyPlanPost): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newWorkshopWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		const formDate = new FormData();

		formDate.append('fileWeeklyPlan', file);
    formDate.append('classRoom', classRoom);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/workshop/weeklyPlan',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newWorkshopWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newWorkshopWeeklyPlan;
	}

	async postDescription(decentWorkId: string,
    description: string,
    userId: string,
    userName: string): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		await axios.post(`${this.href}/description/workshop/weeklyPlan/${decentWorkId}`, {
      userId,
      userName,
      date: this._date,
      description
		}).then(response => {
			newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newElementaryWeeklyPlan;
	}

  async deleteDescription(decentWorkId: string, descriptionId: string, userId: string) {
    let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType | undefined;

    await axios
        .delete(
            `${this.href}/description/workshop/weeklyPlan/${decentWorkId}/${descriptionId}/${userId}`
        )
        .then((response) => {
            newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType;
        });

    return newElementaryWeeklyPlan;
}

  async setChecking(weeklyPlanId: string): Promise<ElementarySchoolWeeklyPlanType[]> {
    let newWorkshopWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

    await axios.post(`${this.href}/checking/workshop/weeklyPlan/${weeklyPlanId}`).then(response => {
      newWorkshopWeeklyPlan = response.data;
    });

    return newWorkshopWeeklyPlan;
  }

	async put({ file, name, weeklyPlanId }: ElementarySchoolWeeklyPlanPut): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newWorkshopWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		const formData = new FormData();

		formData.append('fileWeeklyPlan', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/workshop/weeklyPlan/${weeklyPlanId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newWorkshopWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newWorkshopWeeklyPlan;
	}

	async get(): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newWorkshopWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		await axios.get(this.href + '/file/workshop/weeklyPlan').then(response => {
			newWorkshopWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		}).catch(e => {
			//
		});

		return newWorkshopWeeklyPlan;
	}

	async delete(elementId: string, fileName: string): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newWorkshopWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		await axios.delete(this.href + `/file/workshop/weeklyPlan/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			newWorkshopWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		}).catch(e => {
			//
		});

		return newWorkshopWeeklyPlan;
	}

  async getData(teacherId: string): Promise<ElementarySchoolWeeklyPlanType[]> {
    let newWorkshopWeeklyPlan: ElementarySchoolWeeklyPlanType[] = []

    await axios.get(this.href + `/file/workshop/weeklyPlan/get_by_id/${teacherId}`).then(response => {
      newWorkshopWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
    });

    return newWorkshopWeeklyPlan;
  }
}

export default new UploadWorkshopWeeklyPlanApi();
