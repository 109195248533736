/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, {useState, useEffect} from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete, MdOutlineSaveAlt } from 'react-icons/md';
import StudentAPI from '../../../class/Student/StudentAPI';
import './style.scss';
import LoadContent from '../../Load-content';
import WaitingStudentApi from '../../../class/WaitingStudent/WaitingStudentApi';

interface ContentTableActionType {
  index: number
  student: any
  setStudents: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string | undefined>>
  displayExportWaitingStudent: boolean
  setDisplayExportWaitingStudent: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ContentTableWaitingStudent({
  index,
  student,
  setStudents,
  setDisplayEditElement,
  setElementId,
  displayExportWaitingStudent,
  setDisplayExportWaitingStudent
}: ContentTableActionType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);

  const huddleDelete = () => {
    setIsLoading(true);
    WaitingStudentApi.deleteStudent(String(student.id)).then(response => {
      setStudents(response);
      setIsLoading(false);
    });
  };

  const formateDate = (_date: string | undefined) => {
    if (!_date) {
      return '';
    }
    return (new Date(_date)).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      dateStyle: 'short'
    })
  }

  const hundleEdit = () => {
    setDisplayEditElement(true);
    setElementId(String(student.id));
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [student]);

  return (
		<div className={`content-table-element-waiting-student display-animation-${displayAnimation}`}>
			<ul className='content-table-ul'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{student.studentName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{student.responsible}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{student.ra.content}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{student.ra.uf}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{student.district}
					</h2>
				</li>
				<li className='content-table-ul-li' style={{display: 'none'}}>
					<h2>
						{student.situation}
						<span>
							{formateDate(student.situationModified)}
						</span>
					</h2>
				</li>
        <li className='content-table-ul-li' onClick={() => {
          setDisplayExportWaitingStudent(!displayExportWaitingStudent);
          setElementId(student.id);
        }}>
          <MdOutlineSaveAlt size='20' style={{ color: '#118951' }} />
        </li>
				<li className='content-table-ul-li' onClick={hundleEdit}>
					<FiEdit2 size='20' style={{ color: '#118951' }} />
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
			</ul>
		</div>
  );
}
