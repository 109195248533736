import React, { type FormEvent, useRef, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import PsychologistCostumer from '../../../../class/Psychologist/psychologist-costumer';
import displayError from '../../../../class/services/displayError';
import { type StudentType } from '../../../../class/types/Student/student-factory-protocol';
import LoadContent from '../../../../components/Load-content';
import type MedicalRecordType from '../../../../class/types/Psychologist';
import { type PsychologistAnamnese } from '../../../../class/types/Psychologist';

interface AddNewRegisterPsychologistProps {
  huddleDisplayAddNewRegister: () => void
  display: boolean
  student: PsychologistAnamnese
  setMedicalRecord: React.Dispatch<React.SetStateAction<MedicalRecordType[]>>
}

export default function AddNewRegisterPsychologist({ display, student, setMedicalRecord, huddleDisplayAddNewRegister }: AddNewRegisterPsychologistProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textArea = useRef<HTMLTextAreaElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    const input = target.querySelectorAll('input')[0];
    const textarea = target.querySelectorAll('textarea')[0];

    if (input.value.length === 0) {
      displayError(input, 'o campo esta vazio!!');
      return;
    }

    if (textarea.value.length === 0) {
      displayError(textarea, 'o campo esta vazio!!');
      return;
    }

    const psychologistCostumer = new PsychologistCostumer(input, textarea);

    setIsLoading(true);
    psychologistCostumer.postMedicRecord(student.id ?? '').then(response => {
      if (!response) {
        return;
      }
      setIsLoading(false);
      setMedicalRecord(response);
      huddleDisplayAddNewRegister();

      textarea.value = '';
      input.value = '';
    }).catch(e => {
      //
    });
  }

  return (
    <div className={`add-new-register-psychologist display-${String(display)}`}>
      <header className='new-register-psychologist'>
        <h1>Adicionar Registro</h1>
        <IoCloseOutline onClickCapture={huddleDisplayAddNewRegister} className='icon-content' size='25' />
      </header>
      <form method='POST' action='/' className='form-new-register-psychologist' onSubmit={huddleSubmit}>
        <div className='input-content'>
          <label htmlFor='register-psychologist-date'>Data da consulta</label>
          <input className='input-element' type="date" id="register-psychologist-date" ref={inputRef} />
        </div>
        <div className='input-content'>
          <label htmlFor='register-psychologist-observation'>Observação</label>
          <textarea ref={textArea} />
        </div>
        <div className='button-content-psychologist'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  container: {
                    width: 30,
                  },
                  button: {
                    width: 25,
                  },
                }}
              />
            )
            : <button type='submit'>Adicionar</button>}
        </div>
      </form>
    </div>
  )
}
