import React from 'react';
import './style.scss';

interface DisplayDocumentProps {
  url: string
  type: string
}

export default function DisplayDocument({ url, type }: DisplayDocumentProps): React.ReactElement {
  const officeTypes = [
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'vnd.openxmlformats-officedocument.presentationml.presentation',
    'msword'
  ];

  const formatUrl = (url: string) => {
    if (officeTypes.includes(type)) {
      return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`} width='100%' height={800}/>
    }

    if (['png', 'jpeg', 'svg'].includes(type)) {
      return <img src={url} width='100%' />
    }

    return <iframe src={url} width='100%' height={800}/>;
  }

  return (
        <div className='display-document-component'>
          {formatUrl(url)}
        </div>
  )
}
