import React from 'react';
import './styles.scss';
import { type QuestionElementType } from '../../../class/types/student-exams/student-exams-protocol';

interface ExamTitleContentProps {
  option: QuestionElementType
}

export default function ExamTitleContent({option}: ExamTitleContentProps): React.ReactElement {
  return (
    <div className='exam-title-content'>
      <h1 dangerouslySetInnerHTML={{
        __html: `${option.content as string}`
      }}></h1>
    </div>
  )
}
