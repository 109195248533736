/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import clearAllInputs from './service/clear-all-inputs';
import LoadContent from '../../../components/Load-content';
import InputSelect from '../../../components/input-select';
import RoomsApi from '../../../class/Room/RoomsApi';
import { type RoomsNames } from '../../../class/types/Room/Rooms-protocol';
import ReadingFluencyCustumer from '../../../class/reading-fluency/reading-fluency-custumer';
import { type ReadingFluencyType } from '../../../class/types/ReadingFluency/reading-fluencyApi-protocol';

interface FormAddElementProps {
  displayEditElement: boolean
  element: ReadingFluencyType | undefined
  setDisplayEditElement: (_boolean: React.SetStateAction<boolean>) => void
  setReadingFluency: React.Dispatch<React.SetStateAction<ReadingFluencyType[]>>
}

export default function FormEditElement({ displayEditElement, setDisplayEditElement, element, setReadingFluency}: FormAddElementProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roomNames, setRoomNames] = useState<RoomsNames[]>([]);
  const dateRef = useRef<any>();

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const inputs = (e.target as HTMLFormElement).querySelectorAll('input');

    setIsLoading(true);
    const readingFluencyCustumer = new ReadingFluencyCustumer(inputs);

    if (!element?.id) {
      return;
    }

    readingFluencyCustumer.put(element?.id).then(response => {
      if (response.length === 0) {
        setIsLoading(false);
        return;
      }
      setReadingFluency(response);
      setIsLoading(false);
      setDisplayEditElement(false);
    });
  };

  const searchRoomByYarn = (option: string) => {
    RoomsApi.getRoomName(option).then(response => {
      setRoomNames(response);
    });
  }

  const hundleClickClose = (clear?: boolean) => {
    setDisplayEditElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    RoomsApi.getRoomName(element?.year ?? '').then(response => {
      setRoomNames(response);
    });

    (dateRef.current as HTMLInputElement).value = element?.date ?? '';
  }, [displayEditElement])

  return (
    <div className={`form-add-reading-fluency display-${displayEditElement}`}>
      <form method='POST' className='form-add-element-coordinator' id='form-add-element-coordinator' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Editar
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <InputSelect
            title='Bimestres'
            defaultValue={element?.twoMonths}
            options={[
              '1° Bimestre',
              '2° Bimestre',
              '3° Bimestre',
              '4° Bimestre'
            ]}
            deleteAll={displayEditElement}
          />
          <InputSelect
            title='Ano Letivo'
            defaultValue={element?.year}
            options={'z-'.repeat(199).split('-').map((value, index) => `${1900 + index}`)}
            cb={searchRoomByYarn}
            deleteAll={displayEditElement}
          />
            <InputSelect
              title='Sala'
              defaultValue={element?.room}
              options={roomNames.map(room => room.name)}
              id={roomNames.map(room => room.id)}
              deleteAll={displayEditElement}
            />
          <div className='input-content'>
            <label htmlFor="data-input-content">Data</label>
            <input type="date" id='data-input-content' ref={dateRef}/>
          </div>
          <div className='buttons-content'>
            {
              isLoading ? (
                  <LoadContent
                    isLoading={isLoading}
                    style={
                      {
                        button: {
                          fontSize: 25,
                        },
                        container: {
                          width: 25,
                        }
                      }
                    }
                  />
                ) : (
                  <>
                    <button className='cancel-button' type='button' onClick={() => {
                      hundleClickClose(true);
                    }}>Cancelar</button>
                    {roomNames.length === 0 ? '' : (
                      <button className='save-button' type='submit'>Adicionar</button>
                    )}
                  </>
                )
            }
          </div>
        </div>
      </form>
    </div>
  );
}
