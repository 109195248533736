import React, { useEffect, useState } from 'react';
import './styles.scss';
import AddNewTag from '../add-new-tag';
import type MathematicsProblemSolvingTagType from '../../../class/types/MathematicsProblemSolving';
import RoomsApi from '../../../class/Room/RoomsApi';
import { useParams } from 'react-router-dom';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';
import { IoIosArrowDown } from 'react-icons/io';
import MathematicsProblemSolvingCostumer from '../../../class/Mathematics-problem-solving/MathematicsProblemSolving-costumer';
import { type MathematicsProblemSolvingObjType } from '../../../class/types/MathematicsProblemSolving';
import MathematicsProblemSolvingApi from '../../../class/Mathematics-problem-solving/MathematicsProblemSolving-api';
import PrintProblemSolving from './service/printProblemSolving';
import LoadContent from '../../../components/Load-content';
import SaveAlert from '../../../components/save-alert';
import DashboardProblemSolving from '../Dashboard-problem-solving';
import RuleVerification from '../../../components/rule-verification';

interface MathematicsProblemSolvingTableProps {
  display: string
  roomInfo: any
}

export default function MathematicsProblemSolvingTable({ display, roomInfo }: MathematicsProblemSolvingTableProps): React.ReactElement {
  const [displayAddNewTag, setDisplayAddNewTag] = useState<boolean>(false);
  const [problemsSalvingTag, setProblemsSalvingTag] = useState<MathematicsProblemSolvingTagType[]>([]);
  const [problemSolving, setProblemSolving] = useState<MathematicsProblemSolvingObjType>({});
  const [students, setStudents] = useState<StudentType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [displayDashboard, setDisplayDashboard] = useState<boolean>(false);
  const { id, roomName } = useParams();

  const huddleDisplayAddNewTag = () => {
    setDisplayAddNewTag(!displayAddNewTag);
  }

  const huddleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!id || !roomInfo.twoMonthsName) {
      return;
    }

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const mathematicsProblemSolvingCostumer = new MathematicsProblemSolvingCostumer(undefined, id);

    setIsLoading(true);
    mathematicsProblemSolvingCostumer.post(inputs, students, String(roomInfo.twoMonths)).then(response => {
      setIsLoading(false);
      setProblemSolving(response);
      setDisplayAlert(true);
      setTimeout(() => {
        setDisplayAlert(false);
      }, 3000)
    }).catch(() => {
      //
    });
  }

  const findProblemSolving = (studentId: string, tagId: string) => {
    if (!problemSolving[studentId]) {
      return 'NR';
    }

    const problemSolvingObj = problemSolving[studentId];

    const problemSolvingFind = problemSolvingObj.mathematicsProblemSolving.find((val) => {
      if (val.tagId === tagId) {
        return val;
      }

      return undefined
    });

    if (problemSolvingFind) {
      return problemSolvingFind.content;
    }

    return 'NR'
  }

  const huddlePrint = () => {
    if (!students || !roomName) {
      return;
    }

    PrintProblemSolving(students, problemSolving, problemsSalvingTag, String(roomInfo.twoMonthsName), roomName);
  }

  const huddleDisplayDashboard = () => {
    setDisplayDashboard(!displayDashboard);
  }

  useEffect(() => {
    if (!id || !roomInfo.twoMonths) {
      return;
    }

    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudents(response);
    }).catch(() => {
      //
    });

    const mathematicsProblemSolvingApi = new MathematicsProblemSolvingApi();

    mathematicsProblemSolvingApi.getAll(id, roomInfo.twoMonths).then(response => {
      setProblemSolving(response);
    }).catch(() => {
      //
    });
  }, [roomInfo, display]);
  return (
    <>
      <SaveAlert display={displayAlert} setDisplay={setDisplayAlert}>Resolução de Problemas salvo</SaveAlert>
      <AddNewTag display={displayAddNewTag} setDisplay={setDisplayAddNewTag} problemsSalvingTag={problemsSalvingTag} setProblemsSalvingTag={setProblemsSalvingTag} />
      <DashboardProblemSolving display={displayDashboard} setDisplay={setDisplayDashboard} roomInfo={roomInfo} tags={problemsSalvingTag} />
      <form className="mathematics-problem-solving-table" method='POST' onSubmit={huddleSubmit}>
        <div className='mathematics-problem-solving-table-header'>
          <h1>Resolução de Problemas - {roomInfo.twoMonthsName}</h1>
          <div className='button-container'>
            <RuleVerification rule={['coordenador']}>
              <>
                {isLoading
                  ? (
                    <LoadContent
                      isLoading={isLoading}
                      style={{
                        container: {
                          marginRight: 10,
                        },
                        button: {
                          width: 25,
                          height: 25,
                        }
                      }}
                    />
                  )
                  : <button type='submit'>Salvar</button>}
                <button type='button' onClick={huddleDisplayAddNewTag}>Adicionar tag</button>
              </>
            </RuleVerification>
            <button type='button' onClickCapture={huddleDisplayDashboard}>Gráfico</button>
            <button type='button' onClick={huddlePrint}>Imprimir</button>
          </div>
        </div>
        <table className='mathematics-problem-solving-table-content'>
          <thead>
            <tr>
              <th>Nome do Aluno</th>
              {problemsSalvingTag.map((tag, index) => {
                return <th key={tag.id}>{tag.content} {index + 1}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {students?.map(student => {
              return (
                <tr key={student.id}>
                  <td>{student.studentName}</td>
                  {problemsSalvingTag.map(tag => {
                    return (
                      <td key={tag.id}>
                        <InputSelect id={student.id ?? ''} tagId={tag.id ?? ''} defaultValue={findProblemSolving(student.id ?? '', tag.id ?? '')} />
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </form>
    </>
  );
}

function InputSelect({ id, tagId, defaultValue }: { id: string, tagId: string, defaultValue: string | undefined }): React.ReactElement {
  const [display, setDisplay] = useState<boolean>(false);
  const [option, setOption] = useState<string>('')

  const huddleOnClick = (e: React.MouseEvent<HTMLUListElement>) => {
    const element = e.target as HTMLElement;

    if (element.tagName.toUpperCase() === 'LI' && element.textContent) {
      setOption(element.textContent)
      setDisplay(false);
    }
  }

  useEffect(() => {
    setOption(defaultValue ?? '');
  }, [defaultValue]);

  return (
    <div className='mathematic-diagnostic-assessment-input-select'>
      <div className='mathematic-diagnostic-assessment-input-content'>
        <input id={`${id} ${tagId}`} type="text" disabled value={option} />
        <IoIosArrowDown className={`icon-arrow display-${String(display)}`} size={20} onClick={() => {
          setDisplay(!display)
        }} />
      </div>
      <ul className={`mathematic-diagnostic-assessment-input-select-options display-${String(display)}`} onClickCapture={huddleOnClick}>
        <li>A</li>
        <li>E</li>
        <li>NR</li>
      </ul>
    </div>
  );
}
