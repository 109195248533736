/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import type NotificationType from '../../../class/types/notification';
import './style.scss';
import { BsCalendarDate } from 'react-icons/bs';
import { HiOutlineMail, HiOutlineMailOpen } from 'react-icons/hi'
import NotificationCustumer from '../../../class/Notification/NotificationCustumer';
import lscache from 'lscache';

interface NotificationBellTableContentProps {
  notification: NotificationType
  setNotifications: React.Dispatch<React.SetStateAction<NotificationType[]>>
}

export default function NotificationBellTableContent({ notification, setNotifications }: NotificationBellTableContentProps): React.ReactElement {
  const huddleClickOpenNotification = () => {
    const notificationCustumer = new NotificationCustumer(undefined, undefined, notification);

    notificationCustumer.postVisualization().then(response => {
      setNotifications(response);
    });
  }

  const isVisualized = () => {
    const user = lscache.get('user');
    if (!notification.visualization) {
      return false;
    }

    if (!notification.visualization[user.id]) {
      return false;
    }

    return true;
  }

  return (
    <div className='notification-bell-table-content'>
      <div className='notification-bell-table-content-header'>
        <ul>
          <li>
            <h1 className='notification-title-content'>{notification.title}</h1>
          </li>
          <li>
            <BsCalendarDate className='icon-element' size={20} />
            <h1 className='date-content'>{notification.date}</h1>
          </li>
        </ul>
        {isVisualized()
          ? (
            <div className='visualization-confirmed-content'>
              <HiOutlineMailOpen className='icon-element email-open' size={20} />
            </div>
          )
          : (
            <div className='visualization-notification'>
              <button className='open-notification-button' onClickCapture={huddleClickOpenNotification}>
                <HiOutlineMail className='icon-element email-close' size={20} />
                <HiOutlineMailOpen className='icon-element email-open' size={20} />
              </button>
            </div>
          )}
      </div>
      <div className='notification-bell-content'>
        <p dangerouslySetInnerHTML={{
          __html: notification.content
        }}></p>
      </div>
    </div>
  );
}
