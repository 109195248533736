/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import FormAddElement from '../FormAddElement-plan';
import './style.scss';
import FormEditElement from '../FormEditElement-plan';
import EmptyComponent from '../../../components/Empty-component';
import PlanAPI from '../../../class/Plan/PlanAPI';
import ContentTablePlan from '../../../components/plan-files/ContentTable';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [elementId, setElementId] = useState<string>();
  const [strategies, setPlans] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(true);
  };

  useEffect(() => {
    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    PlanAPI.get().then((response: any[]) => {
      setIsLoading(false);
      setPlans(response);
    });
  }, [display]);

  return (
    <div className='table-container-plan'>
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setStrategies={setPlans}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        setDisplayEditElement={setDisplayEditElement}
        setStrategies={setPlans}
        elementId={elementId}
      />
      <div className='btn-create-new-action-container'>
        <button onClick={hundleCreateNewElement}>Criar novo plano municipal</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Plano</li>
          <li className='header-table-ul-li'>Cronograma</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table'>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
          />
          : (
            display === 'true'
              ? (
                strategies?.length === 0
                  ? <EmptyComponent>click em (criar nova estratégia) para criar uma estratégia</EmptyComponent>
                  : strategies?.map((action: any, index: number) => (
                    <ContentTablePlan
                      index={index}
                      action={action}
                      setDisplayEditElement={setDisplayEditElement}
                      setStrategies={setPlans}
                      setElementId={setElementId}
                      key={String(action.id)}
                    />
                  ))
              )
              : ''

          )
        }
      </main>
    </div>
  );
}
