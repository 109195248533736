import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import { type StudentObserverType } from '../types/StudentObserver/student-observer-protocol';
import lscache from 'lscache';

export default new (class StudentObserverApi {
  private readonly user = lscache.get('user') as UserProtocol;

  private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
  }

  async post(studentObserver: StudentObserverType, roomId: string, matterId: string, twoMonths: string): Promise<StudentObserverType[]> {
    let newStudentObserver: StudentObserverType[] = [];

    await axios.post(this.href + `/student/observer/${roomId}/${matterId}/${twoMonths}`, studentObserver).then(response => {
      newStudentObserver = response.data;
    });

    return newStudentObserver;
  }

  async put(studentObserver: StudentObserverType, roomId: string, matterId: string, twoMonths: string, studentObserverId: string): Promise<StudentObserverType[]> {
    let newStudentObserver: StudentObserverType[] = [];

    await axios.put(this.href + `/student/observer/${roomId}/${matterId}/${twoMonths}/${studentObserverId}`, studentObserver).then(response => {
      newStudentObserver = response.data as StudentObserverType[];
    });

    return newStudentObserver;
  }

  async get(roomId: string, matterId: string, twoMonths: string): Promise<StudentObserverType[]> {
    let newStudentObserver: StudentObserverType[] = [];

    await axios.get(this.href + `/student/observer/${roomId}/${matterId}/${twoMonths}`).then(response => {
      newStudentObserver = response.data as StudentObserverType[];
    });

    return newStudentObserver;
  }

  async delete(roomId: string, matterId: string, twoMonths: string, studentObserverId: string): Promise<StudentObserverType[]> {
    let newStudentObserver: StudentObserverType[] = [];

    await axios.delete(this.href + `/student/observer/${roomId}/${matterId}/${twoMonths}/${studentObserverId}`).then(response => {
      newStudentObserver = response.data as StudentObserverType[];
    });

    return newStudentObserver;
  }
})()
