/* eslint-disable @typescript-eslint/no-var-requires */
import JsPDF from 'jspdf';
import type MathematicsProblemSolvingTagType from '../../../../class/types/MathematicsProblemSolving';
import { type MathematicsProblemSolvingObjType } from '../../../../class/types/MathematicsProblemSolving';
import { type StudentType } from '../../../../class/types/Student/student-factory-protocol';
import autoTable from 'jspdf-autotable';

const img = require('./img/header-img.png');

export default function PrintProblemSolving(students: StudentType[], problemSolving: MathematicsProblemSolvingObjType, tags: MathematicsProblemSolvingTagType[], twoMonthsName: string, roomName: string) {
  const doc = new JsPDF({
    orientation: 'landscape',
  });

  doc.addImage(img, 'PNG', 50, 5, 180, 0);
  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951'
    },
    head: [
      ['Nome Do Aluno', ...tags.map((tag, index) => `${index + 1} - ${tag.content}`)]
    ],
    body: (() => {
      const bodyArray: string[][] = [];

      students.forEach(student => {
        bodyArray.push([student.studentName, ...tags.map(tag => {
          return findProblemSolving(problemSolving, student.id ?? '', tag.id ?? '');
        })])
      })

      return bodyArray;
    })()
  });

  doc.save(`Resolução de Problemas ${roomName} - ${twoMonthsName}`);
}

function findProblemSolving(problemSolving: MathematicsProblemSolvingObjType, studentId: string, tagId: string) {
  if (!problemSolving[studentId]) {
    return 'NR';
  }

  const problemSolvingObj = problemSolving[studentId];

  const problemSolvingFind = problemSolvingObj.mathematicsProblemSolving.find((val) => {
    if (val.tagId === tagId) {
      return val;
    }

    return undefined
  });

  if (problemSolvingFind) {
    return problemSolvingFind.content;
  }

  return 'NR'
}
