import { type HTTPIType } from '../../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';

export function subTime(afterTime: string, beforeTime: string) {
  const after = {
    hours: Number(afterTime.split(':')[0]),
    minutes: Number(afterTime.split(':')[1]),
  }
  const before = {
    hours: Number(beforeTime.split(':')[0]),
    minutes: Number(beforeTime.split(':')[1]),
  }

  before.minutes = before.minutes - after.minutes;

  if (before.minutes < 0) {
    before.minutes = 0;
    before.hours = after.hours - 1;
  }

  before.hours = before.hours - after.hours;

  if (before.hours < 0) {
    before.hours = 0;
  }

  return formatTime(before);
};

function formatTime(time: {
  minutes: number
  hours: number
}): string {
  const newMinutes = time.hours < 10 ? `0${time.hours}` : `${time.hours}`;
  const newSegundes = time.minutes < 10 ? `0${time.minutes}` : `${time.minutes}`;

  return `${newMinutes}:${newSegundes}`;
};

export function sumTime(dayOfHTTPI: HTTPIType[]) {
  let hours = 0;
  let minutes = 0;

  dayOfHTTPI.forEach(httpi => {
    const time = {
      hours: Number(httpi.time.totalTime.split(':')[0]),
      minutes: Number(httpi.time.totalTime.split(':')[1]),
    }

    minutes += time.minutes;

    if (minutes >= 60) {
      hours++;

      minutes -= 60;
    }

    hours += time.hours;
  });

  return formatTime({
    minutes,
    hours
  });
}
