/* eslint-disable @typescript-eslint/naming-convention */
import { type ReadingFluencyLevelType } from '../types/ReadingFluencyLevel/reading-fluency-level-api-protocol';
import type { StudentType } from '../types/Student/student-factory-protocol';

class ReadingFluencyLevelFactory {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly students: StudentType[] = []
	) {}

	create(): ReadingFluencyLevelType {
		const inputFilteredByStudent = this.filterInputByStudent();

		const studentWritingLRObj = this.constructObj(inputFilteredByStudent);

		return studentWritingLRObj;
	}

	filterInputByStudent(): HTMLInputElement[][] {
		const filteredInputsByStudent: HTMLInputElement[][] = [];

		this.students.forEach(student => {
			const inputs: HTMLInputElement[] = [];

			this.inputs.forEach(input => {
				const inputStudentId = input.id.split(' ').pop();
				if (student.id === inputStudentId) {
					inputs.push(input);
				}
			});
			filteredInputsByStudent.push(inputs);
		});

		return filteredInputsByStudent;
	}

	constructObj(inputFilteredByStudent: HTMLInputElement[][]): ReadingFluencyLevelType {
		const studentWritingLRObj: ReadingFluencyLevelType = {};

		this.students.forEach((student, index) => {
			if (!student.id) {
				return;
			}

			studentWritingLRObj[student.id] = {
        readingFluencyLevel: inputFilteredByStudent[index][0].value,
				studentId: student.id,
        studentName: student.studentName,
			};
		});

		return studentWritingLRObj;
	}
}

export default ReadingFluencyLevelFactory;
