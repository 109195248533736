/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useRef, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import NotificationCustumer from '../../../class/Notification/NotificationCustumer';
import type NotificationType from '../../../class/types/notification';
import LoadContent from '../../../components/Load-content';
import VerifyInput from '../service/verifyInput';

interface NotificationFormAddProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  setNotifications: React.Dispatch<React.SetStateAction<NotificationType[]>>
}

export default function NotificationFormAdd({
  display,
  setDisplay,
  setNotifications,
}: NotificationFormAddProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const titleInput = useRef<HTMLInputElement | null>(null);
  const contentInput = useRef<HTMLTextAreaElement | null>(null);

  const resetAllInput = () => {
    if (!titleInput.current || !contentInput.current) {
      return;
    }

    titleInput.current.value = '';
    contentInput.current.value = '';
  }

  const huddleCancel = () => {
    setDisplay(!display);
    resetAllInput();
  }

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // const form = e.target as HTMLFormElement;
    if (!titleInput || !contentInput || !titleInput.current || !contentInput.current) {
      return;
    }

    if (!VerifyInput(titleInput.current, contentInput.current)) {
      return;
    }

    const titleElement = titleInput.current;
    const contentElement = contentInput.current;

    const notificationCustumer = new NotificationCustumer(titleElement, contentElement);

    setIsLoading(true);

    notificationCustumer.post().then(response => {
      setNotifications(response);
      setDisplay(false);
      resetAllInput();
      setIsLoading(false);
    });
  }

  return (
    <div className={`notification-form-add display-${String(display)}`}>
      <header className='header-notification-form-add'>
        <h1>Adicionar Notificação</h1>
        <div className='close-button-content'>
          <button onClickCapture={() => {
            setDisplay(!display);
          }}>
            <IoCloseOutline size='25' />
          </button>
        </div>
      </header>
      <form method="POST" className='notification-form-add-input-content' onSubmitCapture={huddleSubmit}>
        <div className='form-input-content'>
          <label htmlFor="notification-form-add-input-title">Titulo:</label>
          <input type="text" id='notification-form-add-input-title' ref={titleInput} />
        </div>
        <div className='form-input-content text-area-input-content'>
          <label htmlFor="notification-form-add-input-content">Conteúdo:</label>
          <textarea id='notification-form-add-input-content' ref={contentInput} />
        </div>
        <div className='notification-form-add-submit-button'>
          <button className='cancel-button button-content' type='button' onClickCapture={huddleCancel}>Cancelar</button>
          {!isLoading
            ? (
              <button className='save-button button-content' type='submit'>Salvar</button>
            )
            : (<LoadContent
              isLoading={isLoading}
              style={
                {
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 100,
                  }
                }
              }
            />
            )}
        </div>
      </form>
    </div>
  );
}
