import axios from 'axios';
import lscache from 'lscache';
import { type UserProtocol } from '../../types/User/User-factory-protocol';
import config from '../../../config/config';
import { type TextualGenreTagType } from '../../types/new-writing-hypothesi/textual-genre';

export default class TextualGenreTagsApi {
  private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  async post(roomId: string, twoMonths: string, textualGenreId: string, data: TextualGenreTagType): Promise<TextualGenreTagType[]> {
    let tags: TextualGenreTagType[] = [];

    await axios.post(this.href + `/new-writing-hypothesi/textual-genre/tags/${roomId}/${twoMonths}/${textualGenreId}`, data).then(response => {
      tags = response.data
    }).catch(e => {
      //
    });

    return tags
  }

  async paste(roomId: string, twoMonths: string, textualGenreId: string, data: TextualGenreTagType[]): Promise<TextualGenreTagType[]> {
    let tags: TextualGenreTagType[] = [];

    await axios.put(this.href + `/new-writing-hypothesi/textual-genre/tags/paste/${roomId}/${twoMonths}/${textualGenreId}`, data).then(response => {
      tags = response.data
    }).catch(e => {
      //
    });

    return tags;
  }

  async update(roomId: string, twoMonths: string, textualGenreId: string, textualGenreTagId: string, data: TextualGenreTagType): Promise<TextualGenreTagType[]> {
    let tags: TextualGenreTagType[] = [];

    await axios.put(this.href + `/new-writing-hypothesi/textual-genre/tags/${roomId}/${twoMonths}/${textualGenreId}/${textualGenreTagId}`, data).then(response => {
      tags = response.data;
    }).catch(e => {
      //
    });

    return tags;
  }

  async delete(roomId: string, twoMonths: string, textualGenreId: string, textualGenreTagId: string): Promise<TextualGenreTagType[]> {
    let tags: TextualGenreTagType[] = [];

    await axios.delete(this.href + `/new-writing-hypothesi/textual-genre/tags/${roomId}/${twoMonths}/${textualGenreId}/${textualGenreTagId}`).then(response => {
      tags = response.data;
    }).catch(e => {
      //
    });

    return tags;
  }

  async getAll(roomId: string, twoMonths: string, textualGenreId: string): Promise<TextualGenreTagType[]> {
    let tags: TextualGenreTagType[] = [];

    await axios.get(this.href + `/new-writing-hypothesi/textual-genre/tags/${roomId}/${twoMonths}/${textualGenreId}`).then(response => {
      tags = response.data;
    }).catch(e => {
      //
    })

    return tags
  }
}
