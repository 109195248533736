import { type ElementarySchoolMonthlyPlanType } from '../types/ElementarySchoolMonthlyPlan/ElementaryMonthlyPlanAPI';

class ElementarySchoolMonthlyPlanFactory {
  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>
  ) {}

  create(): ElementarySchoolMonthlyPlanType {
    return {
      school: this.inputs[0].value,
      teacher: this.inputs[1].value,
      class: this.inputs[2].value,
      currentTime: this.inputs[3].value,
      yearn: this.inputs[4].value,
      curricularComponent: this.inputs[5].value,
      strategicObjective: this.inputs[6].value,
      specificObjective: this.inputs[7].value,
      date: this.formateDate(this.inputs[8].value),
      methodology: {
        thematicUnit: this.inputs[9].value,
        fieldOfAction: this.inputs[10].value,
        ability: this.inputs[11].value,
        learningObjectives: this.inputs[12].value,
        methodologyStrategy: this.inputs[13].value,
        assessment: this.inputs[14].value
      }
    };
  }

  put(): ElementarySchoolMonthlyPlanType {
    return {
      school: this.verifyValue(0),
      teacher: this.verifyValue(1),
      class: this.verifyValue(2),
      currentTime: this.verifyValue(3),
      yearn: this.verifyValue(4),
      curricularComponent: this.verifyValue(5),
      strategicObjective: this.verifyValue(6),
      specificObjective: this.verifyValue(7),
      date: this.formateDate(this.inputs[8].value),
      methodology: {
        thematicUnit: this.verifyValue(9),
        fieldOfAction: this.verifyValue(10),
        ability: this.verifyValue(11),
        learningObjectives: this.verifyValue(12),
        methodologyStrategy: this.verifyValue(13),
        assessment: this.verifyValue(14)
      },
      commentary: this.verifyValue(15)
    };
  }

  formateDate(_date: string): string {
    const date = new Date(_date);

    return date.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  }

  verifyValue(index: number): string {
    if (!this.inputs[index]) {
      return ''
    }
    return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
  }
}

export default ElementarySchoolMonthlyPlanFactory;
