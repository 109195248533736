/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import LoadContent from '../../../components/Load-content';
import clearAllInputs from '../service/clear-all-inputs';
import type FileAdmLegislationType from '../../../class/types/AdmLegislation';
import UploadAdmHoursCostumer from '../../../class/UploadAdmHours/upload-adm-hours-custumer';
import UploadAdmCalendarCostumer from '../../../class/UploadAdmCalendar/upload-adm-calendar-custumer';

interface FormAddCalendarProps {
  setDisplayFormAddAdmCalendar: React.Dispatch<React.SetStateAction<boolean>>
  setAdmCalendar: React.Dispatch<React.SetStateAction<FileAdmLegislationType[]>>
  displayFormAddAdmCalendar: boolean
  title: string
  type: 'general'
}

export default function FormAddAdmCalendar({ setDisplayFormAddAdmCalendar, setAdmCalendar, displayFormAddAdmCalendar, title, type}: FormAddCalendarProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteAll, setDeleteAll] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormAddAdmCalendar(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const uploadAdmCalendarCostumer = new UploadAdmCalendarCostumer(inputs);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    uploadAdmCalendarCostumer.post(type).then(response => {
      if (response.error) {
        setIsLoading(false);
        return;
      }
      setAdmCalendar(response);
      setDisplayFormAddAdmCalendar(false);
      setIsLoading(false);
      huddleClearAllInputs();
    }).catch(e => {
      //
    });
  };

  const huddleClearAllInputs = () => {
    clearAllInputs('form-add-adm-legislation');
    setDeleteAll(!deleteAll);
    setFileInfo(undefined);
  }

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  return (
    <div className={`form-add-adm-calendar display-${String(displayFormAddAdmCalendar)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          {title}
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddAdmCalendar(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-adm-legislation' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label htmlFor={`class-room-content-form-hours-${type}`}>Descrição:</label>
          <input id={`class-room-content-form-hours-${type}`} type="text" placeholder='descrição do documento' />
        </div>
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date"/>
        </div>
        <div className="input-file-element">
          <label htmlFor={`file-input-add-calendar-${type}`}>
            <DisplayFileInfo fileInfo={fileInfo} />
          </label>
          <input
            type="file"
            id={`file-input-add-calendar-${type}`}
            onChange={inputFileHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png, .pptx, vnd.openxmlformats-officedocument.presentationml.presentation'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
