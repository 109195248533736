import React from 'react';
import './style.scss';
import InputSelect from '../../../components/input-select';

interface MunicipalEducationPlanMonitoringSheetDashboardIndicatorProps {
  display: string
}

export default function MunicipalEducationPlanMonitoringSheetDashboardIndicator({ display }: MunicipalEducationPlanMonitoringSheetDashboardIndicatorProps): React.ReactElement {
  return (
    <div className='municipal-education-plan-monitoring-sheet-dashboard-indicator'>
      <section className='municipal-education-plan-monitoring-sheet-dashboard-indicator-header'>
        <h1>Gráfico dos Indicadores</h1>
      </section>
      <nav className='goal-dashboard-filter'>
        <div className='filter-content'>
          <h1>Meta</h1>
          <InputSelect
            title=''
            options={[
              'Meta 1',
              'Meta 2',
              'Meta 3'
            ]}
            defaultValue='Escolha uma Meta'
          />
        </div>
        <div className='filter-content'>
          <h1>Indicadores</h1>
          <InputSelect
            title=''
            options={[
              'Meta 1',
              'Meta 2',
              'Meta 3'
            ]}
            defaultValue='Escolha uma Meta'
          />
        </div>
      </nav>
    </div>
  );
}
