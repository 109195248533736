import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type ElementarySchoolWeeklyPlanPut } from '../types/Upload_ElementarySchoolWeeklyPlan';
import type FileAdmLegislationType from '../types/AdmLegislation';

class UploadAdmLegislation {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, description, date, name, userName, userId }: {
    file: File
    description: string
    date: string
    name: string
    userName: string
    userId: string
  }): Promise<FileAdmLegislationType[]> {
		let admLegislation: FileAdmLegislationType[] = [];

		const formDate = new FormData();

		formDate.append('fileAdmLegislation', file);
    formDate.append('description', description);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/legislacao',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			admLegislation = response.data as FileAdmLegislationType[];
		});

		return admLegislation;
	}

	async put({ file, name, weeklyPlanId }: ElementarySchoolWeeklyPlanPut): Promise<FileAdmLegislationType[]> {
		let admLegislation: FileAdmLegislationType[] = [];

		const formData = new FormData();

		formData.append('fileAdmLegislation', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/legislacao/${weeklyPlanId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			admLegislation = response.data as FileAdmLegislationType[];
		});

		return admLegislation;
	}

	async get(): Promise<FileAdmLegislationType[]> {
		let admLegislation: FileAdmLegislationType[] = [];

		await axios.get(this.href + '/file/legislacao').then(response => {
			admLegislation = response.data as FileAdmLegislationType[];
		}).catch(e => {
			//
		});

		return admLegislation;
	}

	async delete(elementId: string, fileName: string): Promise<FileAdmLegislationType[]> {
		let admLegislation: FileAdmLegislationType[] = [];

		await axios.delete(this.href + `/file/legislacao/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			admLegislation = response.data as FileAdmLegislationType[];
		}).catch(e => {
			//
		});

		return admLegislation;
	}
}

export default new UploadAdmLegislation();
