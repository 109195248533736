/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useState } from 'react';
import FormRecoverByEmailElement from '../../components/form-recover-by-email';
import Validator from '../../class/Validator';
import RecoverByEmail from '../../class/RecoverByEmail';
import lscache from 'lscache';
import './style.scss';
import displayErrorLogin from '../../class/services/displayErrorLogin';
import config from '../../config/config';

export default function RecoverByEmailContainer() {
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const huddleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const validation = new Validator(inputs);

    if (!validation.validation()) {
      await RecoverByEmail.recoverByEmail(inputs.item(0).value).then((response) => {
        setDisplayForm(true);
        setEmail(inputs.item(0).value);
      }).catch(() => {
        displayErrorLogin(inputs.item(0), 'o e-mail fornecido e invalido');
      });
    }
  };

  const huddleSubmitTwo = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    RecoverByEmail.codeIsValid(email, inputs.item(0).value).then(response => {
      if (!response) {
        return;
      }

      lscache.setExpiryMilliseconds(60000);
      lscache.set('user', {
        email
      }, config.processTemp);
      redirect(response);
    });
  };

  const redirect = (accessCode: string) => {
    window.location.href = window.location.origin + `/recover/password/${accessCode}`;
  };

  return (
		<div className='recover-by-email-container'>
			<div className='form-login-content'>
				<FormRecoverByEmailElement huddleSubmit={huddleSubmit} huddleSubmitTwo={huddleSubmitTwo} displayForm={displayForm} />
			</div>
		</div>
  );
}
