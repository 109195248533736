/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { StrategyOfPlanProtocol } from '../types/Strategy-of-plan/Strategy-of-plan-factory-protocol';

class StrategyOfPlanFactory implements StrategyOfPlanFactory {
	constructor(
		private readonly inputs: any[] | NodeListOf<HTMLInputElement>,
		private readonly id: string
	) {}

	create(): StrategyOfPlanProtocol {
		return {
			planId: this.id,
			strategyName: this.inputs[0].value,
			timeline: this.inputs[1].value,
			situation: 'não inicializado'
		};
	}

	createPut(): StrategyOfPlanProtocol {
		return {
			planId: this.id,
			strategyName: this.verifyValue(0),
			timeline: this.verifyValue(1),
			situation: this.verifyValue(2)
		};
	}

	verifyValue(index: number): string {
		return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
	}
}

export default StrategyOfPlanFactory;
