import type MedicalRecordType from '../types/Psychologist';
import { type PsychologistAnamnese } from '../types/Psychologist';

export default class PsychologistFactory {
  createMedicRecord(input: HTMLInputElement, textArea: HTMLTextAreaElement): MedicalRecordType {
    return {
      date: input.value ?? '',
      observation: textArea.value ?? '',
    }
  }

  createAnamnese(inputs: NodeListOf<HTMLInputElement>, textArea: NodeListOf<HTMLTextAreaElement>): PsychologistAnamnese {
    return {
      studentName: inputs.item(0).value ?? '',
      age: inputs.item(1).value ?? '',
      sex: inputs.item(2).value ?? '',
      birthday: inputs.item(3).value ?? '',
      fatherName: inputs.item(4).value ?? '',
      motherName: inputs.item(5).value ?? '',
      maritalStatus: inputs.item(6).value ?? '',
      originOfTheSon: inputs.item(7).value ?? '',
      theChildIsAwareOfTheSituation: inputs.item(8).value ?? '',
      breastFeeding: inputs.item(9).value ?? '',
      presentedSpeechProblems: inputs.item(10).value ?? '',
      difficultiesOrDelaysInSphincterControl: inputs.item(11).value ?? '',
      HaveNocturnalEnuresis: inputs.item(12).value ?? '',
      hisMotorDevelopmentWasOnTime: inputs.item(13).value ?? '',
      takeABathAlone: inputs.item(14).value ?? '',
      brushYourTeethAlone: inputs.item(15).value ?? '',
      useTheBathroomAlone: inputs.item(16).value ?? '',
      needAssistanceWithDressingOrUndressing: inputs.item(17).value ?? '',
      numberOfBrothers: {
        response: textArea.item(0).value ?? '',
        reactionOfSon: textArea.item(1).value ?? '',
      },
      theSonLiveWithWho: {
        response: textArea.item(2).value ?? '',
        reactionOfSon: textArea.item(3).value ?? '',
      },
      gestation: textArea.item(4).value ?? '',
      healthOfMotherTowardTheGestation: textArea.item(5).value ?? '',
      childbirth: textArea.item(6).value ?? '',
      disturbances: textArea.item(7).value ?? '',
      hasSpecialHabits: textArea.item(8).value ?? '',
      changeLetters: textArea.item(9).value ?? '',
      factsThatAffectedTheStudentsDevelopment: textArea.item(10).value ?? '',
      currentStatusOfTheChild: textArea.item(11).value ?? '',
      inSight: textArea.item(12).value ?? '',
      inSpeech: textArea.item(13).value ?? '',
      inLocomotion: textArea.item(14).value ?? '',
      atWhatAgeWereDiapersRemoved: textArea.item(15).value ?? '',
      respondToInterventionsWhenDisobeying: textArea.item(16).value ?? '',
      presentsEasyCrying: textArea.item(17).value ?? '',
      refusesAid: textArea.item(18).value ?? '',
      hasTouchResistance: textArea.item(19).value ?? '',
      theChildHasPreviouslyStudiedAtAnotherSchool: textArea.item(20).value ?? '',
      reasonForTransfer: textArea.item(21).value ?? '',
      youRepeatedASeries: textArea.item(22).value ?? '',
      theStudentReceivesHelpWithHomework: textArea.item(23).value ?? '',
      foreignLanguageCourse: textArea.item(24).value ?? '',
      sport: textArea.item(25).value ?? '',
      dance: textArea.item(26).value ?? '',
      musicalInstrument: textArea.item(27).value ?? '',
      makesFriendsEasily: textArea.item(28).value ?? '',
      easilyAdaptsToTheEnvironment: textArea.item(29).value ?? '',
      whoAreTheChildsPlaymates: textArea.item(30).value ?? '',
      chooseTheGroup: textArea.item(31).value ?? '',
      favoriteDistractions: textArea.item(32).value ?? '',
      prevailingSocialAttitudes: textArea.item(33).value ?? '',
      emotional: textArea.item(34).value ?? '',
      finalObservations: textArea.item(35).value,
    }
  }
}
