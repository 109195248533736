/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { type SpellingLevelApiResType } from '../../../class/types/SpellingLevel/spellingLevelType';
import React, { useEffect, useState } from 'react';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import SpellingLevelApi from '../../../class/SpellingLevel/SpellingLevelApi';
import { useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import RuleVerification from '../../rule-verification';

interface ContentTableType {
  spellingLevel: SpellingLevelApiResType
  activityInfo: Record<string, string>
  index: number
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setSpellingLevel: React.Dispatch<React.SetStateAction<SpellingLevelApiResType[]>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
}

export default function ContentTableSpellingLevel({ spellingLevel, index, activityInfo, setElementId, setDisplayEditElement, setSpellingLevel }: ContentTableType): React.ReactElement {
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);
  const [rule, setRule] = useState<string>('');
  const { id } = useParams();

  const hundleDelete = () => {
    if (!id) {
      return;
    }

    SpellingLevelApi.delete(id, activityInfo.twoMonths, String(spellingLevel.id)).then(response => {
      setSpellingLevel(response);
    });
  };

  const huddleEdit = () => {
    setDisplayEditElement(true);
    setElementId(String(spellingLevel.id));
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;

    setRule(user.permission?.rule ?? '');
  }, []);

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [activityInfo]);

  return (
    <div className={`content-table-element-spelling-level display-animation-${displayAnimation}`}>
      <ul className='content-table-ul-spelling-level'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            {spellingLevel.content}
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {spellingLevel.date ? spellingLevel.date : ''}
          </h2>
        </li>
        <RuleVerification rule={['coordenador']}>
          <>
            <li className='content-table-ul-li'>
              <FiEdit2 size='20' style={{ color: '#118951' }} onClick={huddleEdit} />
            </li>
            <li className='header-table-ul-li' onClick={hundleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          </>
        </RuleVerification>
      </ul>
    </div>
  );
}
