import { type StudentReportType } from '../../../class/types/StudentReport';

export default function filterByTwoMonths(studentReport: StudentReportType[], twoMonths: string): StudentReportType[] {
  const studentFiltered = studentReport.filter((report) => {
    if (report.twoMonths?.includes(twoMonths)) {
      return true
    }
    return false;
  });

  return studentFiltered
}
