/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react';
import { VscNewFile } from 'react-icons/vsc';
import './style.scss';

interface EmptyComponent {
	children?: any
}

export default function EmptyComponent({ children }: EmptyComponent): React.ReactElement | JSX.Element {
	return (
		<div className='empty-element-content'>
			<div className='empty-element'>
				<div className='icon-content'>
					<VscNewFile size='50' className='icon-element' />
				</div>
				<div className='children-content'>
					<h1>Nenhum resultado encontrado</h1>
					<p>{children}</p>
				</div>
			</div>
		</div>
	);
}
