
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import FormAddElement from '../FormAddElement-nutritionists';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import UserAPI from '../../../class/User/UserAPI';
import ContentTableTeacher from '../../../components/teacher-files/ContentTable-teacher';
import ContentTableNutritionist from '../../../components/nutritionist-files/ContentTable-nutritionist';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [nutritionist, setNutritionist] = useState<any[]>();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  useEffect(() => {
    UserAPI.getByPermission('nutritionist').then(response => {
      setNutritionist(response);
    });
  }, []);

  return (
    <div className='table-container-add-new-nutritionists'>
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setTeacher={setNutritionist}
      />
      <div className='btn-create-new-teacher'>
        <button onClick={hundleCreateNewElement}>Adicionar Nutricionista</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Nutricionista</li>
          <li className='header-table-ul-li'>id</li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            nutritionist?.length === 0
              ? <EmptyComponent>click em (Adicionar professor) para adicionar um professor</EmptyComponent>
              : nutritionist?.map((nutritionist: any, index: number) => (
                <ContentTableNutritionist
                  index={index}
                  user={nutritionist}
                  setNutritionistCostumer={setNutritionist}
                  key={String(nutritionist.id)}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
