import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { PlanType } from '../types/Plan/plansApi-protocol';
import type PlansApiProtocol from '../types/Plan/plansApi-protocol';
import type { UserProtocol } from '../types/User/User-factory-protocol';

class ActionApi implements PlansApiProtocol {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
    lscache.setExpiryMilliseconds(60000);
	}

	async post(plan: PlanType): Promise<PlanType[]> {
		let plans: PlanType[] = [];

		if (this.href) {
			await axios.post(`${this.href}/post/plan`, plan).then(response => {
				plans = response.data as PlanType[];
			});

			this.savePlans(plans);
		}

		return plans;
	}

	async delete(id: string): Promise<any> {
		let plans: PlanType[] = [];
		if (this.href) {
			await axios.delete(`${this.href}/delete/plan/${id}`).then(response => {
				plans = response.data as PlanType[];
			});

			this.savePlans(plans);
		}

		return plans;
	}

	async get(): Promise<PlanType[]> {
		let strategy: PlanType[] = [];
		if (this.href) {
			await axios.get(`${this.href}/get/plans`).then(response => {
				strategy = response.data as PlanType[];
			});

			this.savePlans(strategy);
		}

		return strategy;
	}

	async put(plan: PlanType, id: string): Promise<PlanType[]> {
		let plans: PlanType[] = [];

		if (this.href) {
			await axios.put(`${this.href}/update/plan/${id}`, plan).then(response => {
				plans = response.data as PlanType[];
			});

			this.savePlans(plans);
		}

		return plans;
	}

	private savePlans(strategies: PlanType[]): void {
		lscache.set('plans', strategies, config.processTemp);
	}
}

export default new ActionApi();
