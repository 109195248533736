/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import RoomCostumer from '../../../class/Room/Room-custumer';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import SearchElementAddForm from '../../../components/Search-element-add-form';
import clearAllInputs from './service/clear-all-inputs';
import searchStudentByRa from './service/search-student-by-ra';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import displayError from '../../../class/services/displayError';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  students: StudentType[]
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setStudentRoom: React.Dispatch<React.SetStateAction<StudentType[]>>
}

export default function FormAddElement({ displayCreateNewElement, students, setDisplayCreateNewElement, setStudentRoom }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>();

  const { id } = useParams();

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const input = target.querySelectorAll('input')[0];

    const student = searchStudentByRa(input.value, students)[0] as StudentType;

    const roomCostumer = new RoomCostumer();

    if (typeof id !== 'string') {
      return;
    }

    if (!student) {
      displayError(input, 'RA invalido!')
      return;
    }

    setIsLoading(true);
      roomCostumer.addStudentToRoom(student, id).then(response => {
      setStudentRoom(response);
      setDisplayElement(false);
      clearAllInputs();
      setIsLoading(false);
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-room display-${displayElement}`}>
      <form method='POST' className='form-add-element-room' id='form-add-element-student-room' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicionar aluno na sala
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <SearchElementAddForm
          elementKey='ra'
          elements={students}
        />
        <div className='body-form-add-element-content'>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Adicionar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
