export default function BellRotate(bellRotateElement: HTMLDivElement) {
  const animation = [
    {
      transform: 'rotate(0)'
    },
    {
      transform: 'rotate(40deg)'
    },
    {
      transform: 'rotate(0)'
    },
    {
      transform: 'rotate(-40deg)'
    },
    {
      transform: 'rotate(0)'
    },
    {
      transform: 'rotate(40deg)'
    },
    {
      transform: 'rotate(0)'
    },
    {
      transform: 'rotate(-40deg)'
    },
    {
      transform: 'rotate(0)'
    },
  ]
  const animationConfig: KeyframeAnimationOptions = {
      duration: 800,
      iterations: 1,
      easing: 'ease-in-out'
  }

  bellRotateElement.animate(animation, animationConfig).addEventListener('finish', () => {
    setTimeout(() => {
      BellRotate(bellRotateElement);
    }, 3000);
  });
}
