/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import ActionCostumer from '../../../class/Action/Action-costumer';
import type { ActionResponseProtocol } from '../../../class/types/Action/ActionAPI-protocol';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setActionsCostumer: (actions: ActionResponseProtocol[]) => void
}

export default function FormAddElement({ displayCreateNewElement, setDisplayCreateNewElement, setActionsCostumer }: FormAddElementProps): React.ReactElement {
  const { id } = useParams();

  const [displayElement, setDisplayElement] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    if (id) {
      setIsLoading(true);
      const actionCostumer = new ActionCostumer(inputs, id);
      actionCostumer.post().then(response => {
        if (response[0].error) {
          setIsLoading(false);
          return;
        }

        setActionsCostumer(response);
        setDisplayCreateNewElement(false);
        clearAllInputs();
        setIsLoading(false);
      });
    }
  };

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);
    clearAllInputs();
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container display-${displayElement}`}>
      <form method='POST' className='form-add-element' id='form-add-element' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Crie uma nova ação
          </h1>
          <IoCloseOutline onClick={hundleClickClose} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='acao'>Ação:</label>
            <input type='text' id='acao' placeholder='digite uma ação' />
          </div>
          <div className='input-content'>
            <label htmlFor='entregas'>Entregas:</label>
            <input type='text' id='entregas' placeholder='entregas' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Responsável:</label>
            <input type='text' id='responsavel' placeholder='responsavel' />
          </div>
          <div className='input-content'>
            <label htmlFor='prazo'>Prazo (inicio-fim)</label>
            <input type='text' id='prazo' placeholder='Prazo (inicio-fim)' />
          </div>
          <div className='buttons-content'>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={
                    {
                      button: {
                        fontSize: 25,
                      },
                      container: {
                        width: 25,
                      }
                    }
                  }
                />
              )
              : (
                <>
                  <button className='cancel-button' type='button' onClick={hundleClickClose}>Cancelar</button>
                  <button className='save-button' type='submit'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
