function createSpanElement(msg: string): HTMLSpanElement {
	const span = document.createElement('span');
	span.textContent = msg;
	span.className = 'error-element';
	return span;
}

export default function displayErrorLogin(input: HTMLInputElement, msg: string): void {
	const { parentElement } = input;
	if (parentElement) {
		const span = createSpanElement(msg);
		const otherParentElement = parentElement.parentElement;

		if (otherParentElement?.querySelectorAll('span')[0]) {
			return;
		}

		if (otherParentElement) {
			otherParentElement.appendChild(span);

			setTimeout(() => {
				otherParentElement.removeChild(span);
			}, 4000);
		}
	}
}
