import React from 'react';
import './styles.scss';
import MathematicsProblemSolvingTable from '../table';

interface MathematicsProblemSolvingProps {
  display: string
  roomInfo: any
}

export default function MathematicsProblemSolving({display, roomInfo}: MathematicsProblemSolvingProps): React.ReactElement {
  return (
    <div className='mathematics-problem-solving'>
      <MathematicsProblemSolvingTable display={display} roomInfo={roomInfo} />
    </div>
  );
}
