/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import UserAPI from '../../../class/User/UserAPI';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import LoadContent from '../../../components/Load-content';
import InputSelect from '../../../components/input-select';
import UpLoadElementaryStudentReportCostumer from '../../../class/UploadElementarySchoolStudentReport/upload-student-report-custumer';
import { type StudentReportType } from '../../../class/types/StudentReport';
import clearAllInputs from '../service/clear-all-inputs';

interface FormAddMonthlyPlanProps {
  setDisplayFormAddStudentReport: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolStudentReport: React.Dispatch<React.SetStateAction<StudentReportType[]>>
  displayFormAddStudentReport: boolean
  elementarySchoolStudentReport: StudentReportType[]
}

export default function FormAddStudentReport({ setDisplayFormAddStudentReport, setElementarySchoolStudentReport, displayFormAddStudentReport, elementarySchoolStudentReport }: FormAddMonthlyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteAll, setDeleteAll] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormAddStudentReport(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolMonthlyPlanCustomer = new UpLoadElementaryStudentReportCostumer(inputs);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true)
    UserAPI.getPermission(user.id).then(permission => {
      elementarySchoolMonthlyPlanCustomer.post().then(response => {
        if (response.error) {
          setIsLoading(false);
          return;
        }

        if (permission === 'professor') {
          const decentWork = response.filter((value: any) => value.issuer?.userId === user.id)
          setElementarySchoolStudentReport(decentWork);

          setDisplayFormAddStudentReport(false);
          setIsLoading(false);
          return;
        }

        setElementarySchoolStudentReport(response);
        setDisplayFormAddStudentReport(false);
        setIsLoading(false);
        huddleClearAllInputs();
      }).catch(e => {
        //
      });
    }).catch(e => {
      //
    })
  };

  const huddleClearAllInputs = () => {
    clearAllInputs('form-add-elementary-school-student-report');
    setDeleteAll(!deleteAll);
    setFileInfo(undefined);
  }

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  return (
    <div className={`form-add-school-teaching-work-plan display-${String(displayFormAddStudentReport)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Adicionar Novo Relatório do Estudante
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddStudentReport(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-elementary-school-student-report' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label htmlFor='class-room-content-form-add-kindergarten-school-teaching-work-plan'>Sala:</label>
          <input id='class-room-content-form-add-kindergarten-school-teaching-work-plan' type="text" />
        </div>
        <div className='input-element-content'>
          <InputSelect
            options={['1º Bimestre', '2º Bimestre', '3º Bimestre', '4º Bimestre']}
            title='Bimestre'
            defaultValue='Escolha um bimestre'
            deleteAll={deleteAll}
          />
        </div>
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date"/>
        </div>
        <div className="input-file-element">
          <label htmlFor="file-input-elementary-school-student-report">
            <DisplayFileInfo fileInfo={fileInfo} />
          </label>
          <input
            type="file"
            id='file-input-elementary-school-student-report'
            onChange={inputFileHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
