/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */

import React, { useEffect, useRef, useState } from 'react';
import StudentObserverCustumer from '../../../class/StudentObserver/student-observet-custumer';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import searchStudentByName from './service/search-student-by-ra';
import studentNameIsEqual from './service/studentNameIsEqual';
import { IoCloseOutline } from 'react-icons/io5';
import clearAllInputs from './service/clear-all-inputs';
import { useParams } from 'react-router-dom';
import displayError from '../../../class/services/displayError';
import LoadContent from '../../../components/Load-content';
import './style.scss';

interface FormAddElementProps {
  student: StudentType[]
  classRoomInfo: Record<string, string>
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setStudentObserver: React.Dispatch<React.SetStateAction<any[]>>
}

export default function FormAddElement({ student, classRoomInfo, displayCreateNewElement, setDisplayCreateNewElement, setStudentObserver }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [options, setOptions] = useState<StudentType[]>();
  const [option, setOption] = useState<StudentType>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const inputStudentName = useRef<HTMLInputElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const { id } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    const input = target.querySelectorAll('input');
    if (!option) {
      displayError(input.item(0), 'nome que você sugeriu e invalido!');
      return
    }

    if (textAreaRef.current?.value.length === 0) {
      if (!textAreaRef.current) {
        return;
      }
      displayError(textAreaRef.current, 'o campo precisa ser preenchido!');
      return;
    }

    if (!id || !textAreaRef.current) {
      return;
    }

    const studentObserverCustumer = new StudentObserverCustumer(
      input,
      textAreaRef.current,
      option,
      id,
      classRoomInfo.matterId,
      classRoomInfo.twoMonths,
    );

    if (typeof id !== 'string') {
      //
    }

    setIsLoading(true);
    studentObserverCustumer.post().then(response => {
      if (response.error) {
        setIsLoading(false);
        return;
      }
      setStudentObserver(response);
      setDisplayElement(false);
      setIsLoading(false);
      if (!textAreaRef.current) {
        return;
      }
      clearAllInputs('form-add-element-student-observer', textAreaRef.current);
    });
  };

  const huddleOptionClick = (optionSelected: StudentType) => {
    setOption(optionSelected);

    if (!inputStudentName.current) {
      return;
    }

    inputStudentName.current.value = optionSelected.studentName;

    setOptions([]);
  }

  const huddleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = (e.target as HTMLInputElement).value;

    if (!text) {
      setOptions([]);
      setOption(undefined);
    }

    const studentIsEqual = studentNameIsEqual(text, student);
    if (studentIsEqual?.studentName) {
      setOptions([]);
      setOption(studentIsEqual);
      return;
    }

    const _student = searchStudentByName(text, student);

    if (!_student) {
      setOptions([]);
      setOption(undefined);
      return;
    }

    setOptions(_student);
  }

  const huddleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      if (!textAreaRef.current) {
        return;
      }
      clearAllInputs('form-add-element-student-observer', textAreaRef.current);
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-student-observer display-${displayElement}`}>
      <form method='POST' className='form-add-element-room' id='form-add-element-student-observer' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicionar Ocorrência
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <section className='input-section'>
          <div className='input-element-content'>
            <label htmlFor="">Nome do Aluno</label>
            <input type="text" onChange={huddleChange} ref={inputStudentName} />
            <ul className="input-element-content-option">
              {!options ? '' : options.map(value => (
                <li onClick={() => {
                  huddleOptionClick(value);
                }}>
                  <h1>
                    {value.studentName}
                  </h1>
                </li>
              ))}
            </ul>
          </div>
          <div className='input-element-content'>
            <label htmlFor="">Ocorrência:</label>
            <textarea ref={textAreaRef} />
          </div>
          <div className='input-element-content'>
            <label htmlFor="input-date-element-student-observer">Date:</label>
            <input type='date' className='input-date-element' id='input-date-element-student-observer' />
          </div>
          <div className='body-form-add-element-content'>
            <div className='buttons-content'>
              {isLoading ? (
                <LoadContent
                  isLoading={isLoading}
                  style={{
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }}
                />
              ) : (
                <>
                  <button className='cancel-button' type='button' onClick={() => {
                    huddleClickClose(true);
                  }}>Cancelar</button>
                  <button className='save-button' type='submit'>Adicionar</button>
                </>
              )}
            </div>
          </div>

        </section>
      </form>
    </div>
  );
}
