/* eslint-disable @typescript-eslint/key-spacing */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import StudentExamsApi from '../../class/Student-Exams/student-exams-api';
import Header from '../../components/Header';
import lscache from 'lscache';
import { type StudentExamsType, type QuestionContentType } from '../../class/types/student-exams/student-exams-protocol';
import QuestionContent from './question-content';
import StudentExamsCostumer from '../../class/Student-Exams/student-exams-costumer';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol'; import LoadContent from '../../components/Load-content';

let time = (1000 * 60) * 25;

let userTime = 10800000;

export default function ExamContainer(): React.ReactElement {
  const [questions, setQuestions] = useState<QuestionContentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [studentExam, setStudentExam] = useState<StudentExamsType | undefined>(undefined);
  const { id } = useParams();

  const [unit, setUnit] = useState('25:00');
  const [userUnit, setUserUnit] = useState('00:00');
  const [displayTime, setDisplayTime] = useState(true);

  const huddleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const user = lscache.get('user') as UserClientProtocol & { ra: string };

    if (!id) {
      return;
    }

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const studentExamsCostumer = new StudentExamsCostumer(null, null, undefined, undefined);

    setIsLoading(true);
    studentExamsCostumer.postResponseStudent(id, user, questions, userUnit, inputs).then((response) => {
      setIsLoading(false);

      window.location.href = '/';
    }).catch(e => {
      //
    });
  }
  useEffect(() => {
    const studentExamApi = new StudentExamsApi()
    const user = lscache.get('user');

    if (!id) {
      return;
    }

    studentExamApi.getAllQuestionToStudent(id, user.ra).then(response => {
      setQuestions(response);
    }).catch(e => {
      //
    });

    studentExamApi.getExamId(id, user.ra).then(response => {
      setStudentExam(response);
    }).catch(e => {
      //
    })

    setInterval(() => {
      time -= 1000;

      userTime += 1000;

      const units = new Date(time).toLocaleDateString(navigator.language, {minute:'2-digit', second: '2-digit'}).substring(12, 20);

      const userUnits = new Date(userTime).toLocaleDateString(navigator.language, {hour: '2-digit', minute: '2-digit', second: '2-digit'}).substring(12, 20);

      setUnit(units);

      setUserUnit(userUnits);

      if (units === '00:00') {
        setDisplayTime(false);
      }
    }, 1000);
  }, [])
  return (
    <div className='exam-container'>
      <Header />
      <form className='exam-container-table' method='POST' action='/' onSubmitCapture={huddleSubmit}>
        <div className='exam-container-table-header'>
          <h1>{studentExam?.title}</h1>
          <ul>
            <li>{studentExam?.room.roomName}</li>
            <li>{studentExam?.matter.matterName}</li>
          </ul>
        </div>
        {questions.map((question, index) => {
          return <QuestionContent question={question} index={index} />
        })}
        <div className='button-submit-content'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    width: 30,
                  },
                  container: {
                    width: 30,
                    height: 30,
                  }
                }}
              />
            )
            : (!displayTime ? <button type='submit'>Enviar Respontas</button> : <h1>Espere o tempo acabar para enviar a prova {unit}</h1>)}
        </div>
      </form>
    </div>
  )
}
