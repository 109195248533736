import type StrategyFactoryProtocol from '../types/Strategy/Strategy-factory-protocol';
import type { StrategyProtocol } from '../types/Strategy/Strategy-factory-protocol';

class StrategyFactory implements StrategyFactoryProtocol {
	constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {}

	create(): StrategyProtocol {
		return {
			strategyName: this.inputs[0].value,
			responsible: this.inputs[1].value
		};
	}

	createPut() {
		return {
			strategyName: this.verifyValue(0),
			responsible: this.verifyValue(1),
			goal: this.verifyValue(2),
			goalResponsible: this.verifyValue(3),
			indicator: this.verifyValue(4),
			indicatorResponsible: this.verifyValue(5),
			cust: this.verifyValue(6),
			source: this.verifyValue(7)
		};
	}

	verifyValue(index: number): string {
		return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
	}
}

export default StrategyFactory;
