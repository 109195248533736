
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { useEffect, useState } from 'react';
import './style.scss';
import {AiFillInfoCircle} from 'react-icons/ai';
import {IoHome} from 'react-icons/io5'
import {BsGraphUp, BsFillClipboardDataFill} from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import lscache from 'lscache';

type Elements = Array<{
  name: string
  boolean: string
}>;

interface NavProps {
  elements: Elements
  setElements: (elements: Elements) => void
}

export default function Nav({ setElements, elements }: NavProps): React.ReactElement {
  const [strategyName, setStrategyName] = useState();
  const { id } = useParams();

  const onClickLi = (elementName: string) => {
    const elementsRested = resetElements(elements);

    elementsRested.forEach(element => {
      if (element.name === elementName) {
        element.boolean = 'true';
      }
    });
    setElements(elementsRested);
  };

  const resetElements = (elements: Elements) => {
    elements.forEach(element => {
      element.boolean = 'false';
    });
    return elements;
  };

  const redirect = (href: string) => {
    window.location.href = href;
  };

  useEffect(() => {
    let strategy: any = {};
    const strategies = lscache.get('plans');

    strategies.forEach((element: any) => {
      if (element.id === id) {
        strategy = element;
      }
    });
    setStrategyName(strategy.planName);
  }, []);

  return (
		<nav className='nav-component-strategy-of-plans'>
			<div className='action-title'>
				<h1>Plano</h1>
				<p>{strategyName}</p>
			</div>
			<ul className='ul-option'>
				<li className='option-content-1' id='estrategy-1' onClick={e => {
				  redirect('/');
				}}>
					<IoHome size='20' color='#fff' />
					<h1 id='estrategy-1'>Inicio</h1>
				</li>
				<li className='option-content-1' id='estrategy-1' onClick={() => {
				  onClickLi('strategy-1');
				}}>
					<BsFillClipboardDataFill size='20' d='estrategy-1' color='#FFF'/>
					<h1 id='estrategy-1'>Tabela</h1>
				</li>
				<li className='option-content-1' id='estrategy-2' onClick={() => {
				  onClickLi('strategy-2');
				}}>
					<AiFillInfoCircle size='20' d='estrategy-2' color='#FFF'/>
					<h1 id='estrategy-1'>Informações extras</h1>
				</li>
				<li className='option-content-1' id='estrategy-1' onClick={e => {
				  onClickLi('strategy-3');
				}}>
					<BsGraphUp size='20' id='strategy-1' color='#FFF'/>
					<h1 id='estrategy-1'>Gráfico</h1>
				</li>
			</ul>
		</nav>
  );
}
