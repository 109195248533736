/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {useState} from 'react';
import { MdDelete } from 'react-icons/md';
import './style.scss';
import LoadContent from '../../Load-content';
import { type ReadingFluencyType } from '../../../class/types/ReadingFluency/reading-fluencyApi-protocol';
import { FiEdit2 } from 'react-icons/fi';
import readingFluencyApi from '../../../class/reading-fluency/reading-fluencyApi';

interface ContentTableType {
  readingFluency: ReadingFluencyType
  index: number
  setReadingFluency: (readingFluency: ReadingFluencyType[]) => void
  setElement: React.Dispatch<React.SetStateAction<ReadingFluencyType | undefined>>
  setDisplayFormEditElement: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ContentTableReadingFluency({ readingFluency, index, setReadingFluency, setDisplayFormEditElement, setElement }: ContentTableType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>();

  const huddleDelete = () => {
    setIsLoading(true);
    if (!readingFluency.id) {
      return;
    }
    readingFluencyApi.delete((readingFluency.id)).then(response => {
      setReadingFluency(response);
      setIsLoading(false);
    });
  };

  const huddleEdit = () => {
    setElement(readingFluency);
    setDisplayFormEditElement(true);
  }

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short',
    });
  }

  const huddleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    if (target.tagName === 'path' || target.tagName === 'svg') {
      return;
    }

    if (!readingFluency.id) {
      return;
    }

    window.location.href = window.location.origin + `/fluencia-leitora/${readingFluency.id}/${readingFluency.roomId}/${readingFluency.room}/${readingFluency.twoMonths}/${readingFluency.year}`;
  }

  return (
		<div className='content-table-element-reading-fluency' onClickCapture={huddleClick}>
			<ul className='content-table-ul-coordinator'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{readingFluency.room}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{readingFluency.twoMonths}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{formatDate(readingFluency.date)}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{readingFluency.year}
					</h2>
				</li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
					<FiEdit2 size='20' style={{ color: '#118951' }} />
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
			</ul>
		</div>
  );
}
