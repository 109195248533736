/* eslint-disable @typescript-eslint/no-floating-promises */
import { type SpellingLevelApiResType } from '../../../class/types/SpellingLevel/spellingLevelType';
import React, { useState, useEffect } from 'react';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import ContentTableSpellingLevel from '../../../components/spelling-level-files/ContentTable-room';
import FormAddSpellingLevel from '../FormAddElement-spelling-level';
import SpellingLevelApi from '../../../class/SpellingLevel/SpellingLevelApi';
import FormEditElement from '../FormEditElement-spelling-level';
import EmptyComponent from '../../../components/Empty-component';
import './style.scss';
import { useParams } from 'react-router-dom';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import RuleVerification from '../../../components/rule-verification';

interface TableType {
  activityInfo: Record<string, string>
  display: string
}

export default function Table({ activityInfo, display }: TableType): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [rule, setRule] = useState<string>('');
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [spellingLevel, setSpellingLevel] = useState<SpellingLevelApiResType[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const { id } = useParams();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    SpellingLevelApi.getAll(id, activityInfo.twoMonths).then(response => {
      setSpellingLevel(response);
    });
    const user = lscache.get('user') as UserClientProtocol;
    if (user.id) {
      UserAPI.getPermission(user.id).then(rule => {
        if (rule) {
          setRule(rule);
        }
      });
    }
  }, [activityInfo]);

  return (
    <div className='table-container-spelling-level'>
      <FormAddSpellingLevel
        activityInfo={activityInfo}
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setSpellingLevel={setSpellingLevel}
      />
      <FormEditElement
        activityInfo={activityInfo}
        displayEditElement={displayEditElement}
        setDisplayEditElement={setDisplayEditElement}
        elementId={elementId}
        setSpellingLevel={setSpellingLevel}
      />
      <div className='header-writing-level-record'>
        <h1>Nível Descrição - {activityInfo.twoMonthsName}</h1>
      </div>
      <div className='btn-create-new-spelling-level-container'>
        <RuleVerification rule={['coordenador']}>
          <button onClick={hundleCreateNewElement}>Adicionar Nível Descrição</button>
        </RuleVerification>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Nível Descrição</li>
          <li className='header-table-ul-li'>data</li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            spellingLevel?.length === 0
              ? <EmptyComponent>click em (Adicionar Nível Descrição) para adicionar um novo Nível Descrição</EmptyComponent>
              : spellingLevel?.map((spellingLevel, index: number) => (
                <ContentTableSpellingLevel
                  index={index}
                  activityInfo={activityInfo}
                  spellingLevel={spellingLevel}
                  setElementId={setElementId}
                  setDisplayEditElement={setDisplayEditElement}
                  setSpellingLevel={setSpellingLevel}
                  key={String(spellingLevel.id)}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
