import React from 'react';
import NewSecretaryWritingHypothesisTable from '../table';

interface NewSecretaryWritingHypothesisProps {
  display: string
}

export default function NewSecretaryWritingHypothesis({display}: NewSecretaryWritingHypothesisProps): React.ReactElement {
  return (
    <div className='new-secretary-writing-hypothesis'>
      <NewSecretaryWritingHypothesisTable display={display} />
    </div>
  );
}
