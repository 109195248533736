/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';

import UploadElementarySchoolProjectCostumer from '../../../class/UploadElementarySchoolProjects/upload-project-custumer';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import InputSelect from '../../../components/input-select';

interface FormAddProjectProps {
  setDisplayFormAddProject: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolProject: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  displayFormAddProject: boolean
  elementarySchoolProject: ElementarySchoolDecentWorkType[]
}

export default function FormAddProject({ setDisplayFormAddProject, setElementarySchoolProject, displayFormAddProject, elementarySchoolProject }: FormAddProjectProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');

  const huddleCancel = () => {
    setDisplayFormAddProject(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolProjectCustomer = new UploadElementarySchoolProjectCostumer(inputs);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      elementarySchoolProjectCustomer.post().then(response => {
        if (response.error) {
          setIsLoading(false);
          return;
        }

        if (permission === 'professor' || permission === 'nutricionista') {
          const weeklyPlan = response.filter((value: any) => {
            if (value.issuer?.userId === user.id || value.visibility === 'Publico') {
              return true;
            }
            return false;
          });
          setElementarySchoolProject(weeklyPlan);

          setDisplayFormAddProject(false);
          setIsLoading(false);
          return;
        }

        setElementarySchoolProject(response);

        setDisplayFormAddProject(false);
        setIsLoading(false);
      }).catch(e => {
        //
      });
    }).catch(e => {
      //
    })
  };

  const setDateHuddle = () => {
    const date = (new Date()).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    }).split('/');

    const formattedDate = `${date[2]}-${date[1]}-${date[0]}`;

    setDate(formattedDate);
  }

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;

    if (user.permission?.rule === 'coordenador') {
      return;
    }

    setDateHuddle();
  }, [displayFormAddProject])

  return (
    <div className={`form-add-school-elementary-teaching-weekly-plan display-${String(displayFormAddProject)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Adicionar novo Projeto
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddProject(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label htmlFor='class-room-content-form-add-kindergarten-school-teaching-work-plan'>Sala:</label>
          <input id='class-room-content-form-add-kindergarten-school-teaching-work-plan' type="text" />
        </div>
        <InputSelect
          options={[
            'Publico',
            'Privado'
          ]}
          title='Visibilidade'
          defaultValue='Publico'
        />
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date"
            // min={date}
          />
        </div>
        <div className="input-file-element">
          <label htmlFor="file-input-add-elementary-school-project">
            <DisplayFileInfo fileInfo={fileInfo} />
          </label>
          <input
            type="file"
            id='file-input-add-elementary-school-project'
            onChange={inputFileHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={{
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }}
                />
              )
              : (
                <>
                  <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                  <button type='submit' className='save-button'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
