export default function searchByDate(notifications: any[], firstDateValue: string, lastDateValue: string): any[] {
  const newNotifications: any[] = [];

  const firstDate = new Date(firstDateValue);
  const lastDate = new Date(lastDateValue);

  notifications.forEach(notification => {
    const notificationDate = new Date(notification.date);
    if (firstDateValue.length === 0) {
      return;
    }
    if (notificationDate < firstDate) {
      return
    }

    if (lastDateValue.length === 0) {
      return;
    }

    if (notificationDate > lastDate) {
      return
    }
    newNotifications.push(notification);
  });

  if (newNotifications.length === 0) {
    return notifications;
  }

  return newNotifications;
}
