/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import searchStudentByName from './service/search-student-by-ra';
import JustificationOfAbsencesCostumer from '../../../class/JustificationOfAbsences/JustificationOfAbsences-custumer';
import { type JustificationOfAbsencesType } from '../../../class/types/JustificationOfAbsences';
import { studentNameIsEqual } from '../FormEditElement-room/service/search-student-by-name';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  student: StudentType[]
  classRoomInfo: Record<string, string>
  displayCreateNewElement: boolean
  justificationOfAbsences: JustificationOfAbsencesType[]
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setJustificationOfAbsences: React.Dispatch<React.SetStateAction<JustificationOfAbsencesType[]>>
}

export default function FormAddElement({ student, classRoomInfo, displayCreateNewElement, justificationOfAbsences, setDisplayCreateNewElement, setJustificationOfAbsences }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [options, setOptions] = useState<StudentType[]>();
  const [option, setOption] = useState<StudentType>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const inputStudentName = useRef<any>();

  const { id } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!option || !id) {
      return;
    }

    const target = e.target as HTMLFormElement;

    const input = target.querySelectorAll('input');

    const roomCostumer = new JustificationOfAbsencesCostumer(
      input,
      option,
      id,
      classRoomInfo.matterId,
      classRoomInfo.twoMonths,
    );
    if (typeof id !== 'string') {
      return;
    }

    setIsLoading(true);
    roomCostumer.post().then(response => {
      if (response[0].error) {
        setIsLoading(false);
        return;
      }
      setJustificationOfAbsences(response);
      setDisplayElement(false);
      clearAllInputs('form-add-element-justification-of-absences');
      setIsLoading(false);
    });
  };

  const huddleOptionClick = (optionSelected: StudentType) => {
    setOption(optionSelected);

    inputStudentName.current.value = optionSelected.studentName;

    setOptions([]);
  }

  const huddleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = (e.target as HTMLInputElement).value;

    if (!text) {
      setOptions([]);
      setOption(undefined);
    }

    const studentIsEqual = studentNameIsEqual(text, student);
    if (studentIsEqual?.studentName) {
      setOptions([]);
      setOption(studentIsEqual);
      return;
    }

    const _student = searchStudentByName(text, student);

    if (!_student) {
      setOptions([]);
      setOption(undefined);
      return;
    }

    setOptions(_student);
  }

  const huddleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      clearAllInputs('form-add-element-justification-of-absences');
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-justification-of-absences display-${displayElement}`}>
      <form method='POST' className='form-add-element-room' id='form-add-element-justification-of-absences' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicionar justificativa
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <div className='input-element-content'>
          <label htmlFor="">Justificativa:</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label htmlFor="">Quantidade de dias</label>
          <input type="number" min='0' />
        </div>
        <div className='input-element-content'>
          <label htmlFor="">Nome do Aluno</label>
          <input type="text" onChange={huddleChange} ref={inputStudentName} />
          <ul className="input-element-content-option">
            {!options ? '' : options.map(value => (
              <li onClick={() => {
                huddleOptionClick(value);
              }}>
                <h1>
                  {value.studentName}
                </h1>
              </li>
            ))}
          </ul>
        </div>
        <div className='input-element-content'>
          <div className='input-date-content'>
            <input type="date" />
            <span>a</span>
            <input type="date" />
          </div>
        </div>
        <div className='body-form-add-element-content'>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  huddleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Adicionar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
