/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import clearAllInputs from './service/clear-all-inputs';
import type { RoomType } from '../../../class/types/Room/Rooms-protocol';
import { IoIosArrowDown } from 'react-icons/io';
import UserAPI from '../../../class/User/UserAPI';
import RoomCostumer from '../../../class/Room/Room-custumer';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayEditElement: boolean
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setRooms: React.Dispatch<React.SetStateAction<RoomType[]>>
  rooms: RoomType[]
  element: RoomType | undefined
}

export default function FormEditElement({ displayEditElement, rooms, setDisplayEditElement, element, setRooms }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [option, setOption] = useState<string>();
  const [teachers, setTeachers] = useState<any[]>([]);
  const [teacherId, setTeacherId] = useState<string>('');
  const [room, setRoom] = useState<RoomType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const roomNameRef = useRef<HTMLInputElement | null>(null);
  const schoolNameRef = useRef<HTMLInputElement | null>(null);

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const roomCostumer = new RoomCostumer(inputs, {
      id: teacherId,
      name: inputs[2].value
    });

    if (element) {
      setIsLoading(true);
      roomCostumer.put(element.id ?? '').then(response => {
        setRooms(response);
        setDisplayEditElement(false);
        clearAllInputs();
        setIsLoading(false);
      });
    }
  };

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayEditElement(false);
    clearAllInputs();
  };

  const hundleClick = () => {
    setDisplayOptions(!displayOptions);
  };

  const hundleClickOption = (e: React.MouseEvent<HTMLUListElement>) => {
    const target = e.target as HTMLElement;

    if (target.textContent) {
      setOption(target.textContent);
      setDisplayOptions(false);
      setTeacherId(target.id);
    }
  };

  useEffect(() => {
    setDisplayElement(displayEditElement);
    UserAPI.getByPermission('teacher').then(response => {
      setTeachers(response);
    });
    if (!element) {
      return;
    }
    setRoom(element);
    (roomNameRef.current ?? {value: ''}).value = element.roomName;
    (schoolNameRef.current ?? {value: ''}).value = element.schoolName
  }, [displayEditElement]);

  return (
		<div className={`form-edit-container-rooms display-${displayElement}`}>
			<form
				method='POST'
				className='form-edit-element'
				id='form-edit-element-student'
				onSubmit={huddleSubmit}
			>
				<div className='header-form-edit-element'>
					<h1>Editar</h1>
				</div>
				<div className='body-form-edit-element'>
					<div className='input-content'>
						<label htmlFor='room-name-edit'>Sala:</label>
						<input
							type='text'
							id='room-name-edit'
							placeholder={'nome da sala'}
              ref={roomNameRef}
						/>
					</div>
					<div className='input-content'>
						<label htmlFor='school-name-edit'>Escola:</label>
						<input
							type='text'
							id='school-name-edit'
              ref={schoolNameRef}
							placeholder={'nome da escola'}
						/>
					</div>
					<div className='input-content input-list-content'>
						<label htmlFor='situation'>Professor:</label>
						<div className='input-element-content' onClick={hundleClick}>
							<input
								type='text'
								id='situation'
								value={!option ? (room?.teacher ? room.teacher.name : '') : option}
								disabled
							/>
							<IoIosArrowDown className={`icon-arrow display-${displayOptions}`} size={20} />
						</div>
						<ul
							className='input-option-list'
							id={`display-${displayOptions}`}
							onClick={e => {
							  hundleClickOption(e);
							}}
						>
							{teachers ? teachers.map(teacher => <li id={teacher.id}>{teacher.userName}</li>) : ''}
						</ul>
					</div>
					<div className='buttons-content'>
						{isLoading ? (
              <LoadContent
              isLoading={isLoading}
              style={{
                button: {
                  fontSize: 25,
                },
                container: {
                  width: 25,
                }
              }}
            />
            ) : (
              <>
            <button className='cancel-button' type='button' onClick={() => {
						  hundleClickClose();
						}}>Cancelar</button>
						<button className='save-button' type='submit'>Editar</button>
              </>
            )}
					</div>
				</div>
			</form>
		</div>
  );
}
