/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import './style.scss';
import { type FrequencyType } from '../../../class/types/Frequency/frequency-factory-protocol';

interface FrequencyInputElementProps {
  student: StudentType
  displayElement: boolean
  index: number
  element?: FrequencyType
}

export default function FrequencyInputElement({ student, element, displayElement, index }: FrequencyInputElementProps): React.ReactElement {
  const [inputValue, setInputValue] = useState<boolean>(false);
  if (student.situation === 'Transferido') {
    return <></>;
  }

  const huddleClick = () => {
    setInputValue(!inputValue);
  };

  useEffect(() => {
    if (element?.frequency[index].present) {
      setInputValue(element.frequency[index].present === 'true');

      return;
    }

    setInputValue(false);
  }, [displayElement]);

  return (
		<ul className='student-frequency-content-body-list'>
			<li>
				<h2>{index + 1}</h2>
			</li>
			<li>
				<h2>{student.studentName}</h2>
			</li>
			<li>
				<div className='frequency-input'>
					<input type='text' value={`${inputValue}`} id={student.id}/>
					<div className={`input-frequency-label display-${inputValue}`} onClickCapture={huddleClick}>
						<div className='input-radio-element'></div>
						<div className='frequency-name'>
							<p>C</p>
							<p>F</p>
						</div>
					</div>
				</div>
			</li>
		</ul>
  );
}
