/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { DisplayFileInfoTwo } from '../../../components/DisplayFile';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import UserAPI from '../../../class/User/UserAPI';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import UpLoadKindergartenMonthlyPlanCostumer from '../../../class/UploadKindergartenSchoolMonthlyPlan/upload-monthly-plan-custumer';
import LoadContent from '../../../components/Load-content';

interface FormEditMonthlyPlanProps {
  setDisplayFormEditMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  displayFormEditMonthlyPlan: boolean
  elementId: string
}

export default function FormEditDecentWork({ setDisplayFormEditMonthlyPlan, setElementarySchoolMonthlyPlan, displayFormEditMonthlyPlan, elementId }: FormEditMonthlyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormEditMonthlyPlan(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolMonthlyPlan = new UpLoadKindergartenMonthlyPlanCostumer(inputs, elementId);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      elementarySchoolMonthlyPlan.put().then(response => {
        if (response.error) {
          setIsLoading(false);
          return;
        }

        if (permission === 'professor') {
          const decentWork = response.filter((value: any) => value.issuer?.userId === user.id)
          setElementarySchoolMonthlyPlan(decentWork);

          setDisplayFormEditMonthlyPlan(false);
          setIsLoading(false);
          return;
        }

        setElementarySchoolMonthlyPlan(response);

        setDisplayFormEditMonthlyPlan(false);
        setIsLoading(false);
      }).catch(e => {
        //
      });
    }).catch(e => {
      //
    });
  };

  const inputFileEditHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  return (
    <div className={`form-edit-school-teaching-month-plan display-${String(displayFormEditMonthlyPlan)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Editar Plano de Mensal
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormEditMonthlyPlan(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-edit-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-file-element">
          <label htmlFor="file-edit-input-kindergarten-school-monthly-plan">
            <DisplayFileInfoTwo file={fileInfo} />
          </label>
          <input
            type="file"
            id='file-edit-input-kindergarten-school-monthly-plan'
            onChangeCapture={inputFileEditHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
