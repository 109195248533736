/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, {useState, useEffect} from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import './style.scss';
import ElementarySchoolMonthlyPlanAPI from '../../../class/ElementarySchoolMonthlyPlan/ElementarySchoolMonthlyPlanAPI';
import KindergartenschoolMonthlyPlanAPI from '../../../class/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import LoadContent from '../../Load-content';

interface ContentTableActionType {
  index: number
  elementarySchoolMonthlyPlan: any
  setElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayViewElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  type: 'elementary-school' | 'kindergartenSchool'
}
export default function ContentTableElementaryMonthlyPlan({
  index,
  elementarySchoolMonthlyPlan,
  setElementarySchoolMonthlyPlan,
  setDisplayViewElementarySchoolMonthlyPlan,
  setDisplayEditElement,
  setElementId,
  type
}: ContentTableActionType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);

  const huddleDelete = () => {
    setIsLoading(true);
    if (type === 'elementary-school') {
      ElementarySchoolMonthlyPlanAPI.delete(String(elementarySchoolMonthlyPlan.id)).then(response => {
        setElementarySchoolMonthlyPlan(response);
        setIsLoading(false);
      }).catch((e) => {
        //
      });
      return;
    }

    KindergartenschoolMonthlyPlanAPI.delete(String(elementarySchoolMonthlyPlan.id)).then(response => {
      setElementarySchoolMonthlyPlan(response);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(elementarySchoolMonthlyPlan.id));
    setDisplayEditElement(true);
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElementId(elementarySchoolMonthlyPlan.id ?? '');
    setDisplayViewElementarySchoolMonthlyPlan(true);
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [elementarySchoolMonthlyPlan]);

  return (
		<div className={`content-table-element-monthly-plan display-animation-${displayAnimation}`} onClick={huddleClick}>
			<ul className='content-table-ul'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{elementarySchoolMonthlyPlan.teacher}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{elementarySchoolMonthlyPlan.class}
					</h2>
				</li>
        <li className='content-table-ul-li status-icon-element'>
        {elementarySchoolMonthlyPlan.commentary
					  ? (
						<BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
					    )
					  : ''
					}
        </li>
				<li className='content-table-ul-li'>
					<h2>
						{elementarySchoolMonthlyPlan.date}
					</h2>
				</li>
				<li className='content-table-ul-li' onClick={huddleEdit}>
					<FiEdit2 size='20' style={{ color: '#118951' }} />
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>

          )}
			</ul>
		</div>
  );
}
