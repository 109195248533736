/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useState, useEffect } from 'react';
import FormAddElement from '../FormAddElement-frequency';
import './style.scss';
import { useParams } from 'react-router-dom';
import frequencyApi from '../../../class/Frequency/frequency-api';
import type { FrequencyType } from '../../../class/types/Frequency/frequency-factory-protocol';
import ContentTableFrequency from '../../../components/frequency-files/ContentTable-frequency';
import EmptyComponent from '../../../components/Empty-component';
import FrequencyInformation from '../display-frequency-information';
import MissedInformation from '../display-missed-information';
import FormEditElement from '../FormEditElement-frequency';

interface TableProps {
  activityInfo: Record<string, string>
  display: string
}

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function Table({ activityInfo, display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [frequencies, setFrequencies] = useState<FrequencyType[]>([]);
  const [frequencySelected, setFrequencySelected] = useState<FrequencyType>();
  const [displayFrequency, setDisplayFrequency] = useState(false);
  const [displayMissed, setDisplayMissed] = useState(false);
  const [element, setElement] = useState<FrequencyType>();
  const { id } = useParams();

  const huddleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };
  const huddleDisplayMissed = () => {
    setDisplayMissed(!displayMissed);
  };

  useEffect(() => {
    if (!activityInfo || !id) {
      return;
    }

    setDisplayMissed(false);
    setDisplayFrequency(false);
    frequencyApi.get(id, activityInfo.matterId, activityInfo.twoMonths).then(response => {
      setFrequencies(response);
    });
  }, [activityInfo]);

  return (
    <div className='table-container-frequency'>
      <FormAddElement
        activityInfo={activityInfo}
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setFrequency={setFrequencies}
      />
      <FrequencyInformation
        frequencySelected={frequencySelected}
        setDisplayFrequency={setDisplayFrequency}
        displayFrequency={displayFrequency}
        cardInformation={activityInfo}
      />
      <MissedInformation
        cardInformation={activityInfo}
        setDisplayMissed={setDisplayMissed}
        displayMissed={displayMissed}
      />
      <FormEditElement
        activityInfo={activityInfo}
        displayEditElement={displayEditElement}
        element={element}
        setDisplayEditElement={setDisplayEditElement}
        setFrequency={setFrequencies}
      />
      <div className='mid-content-activity'>
        <div className='mid-element-activity'>
          <div className='header-activity'>
            <h1>Frequência</h1>
            <h1>Materia: {activityInfo.matterName}</h1>
            <h1>{activityInfo.twoMonthsName}</h1>
          </div>
          <div className='btn-create-new-matter-container'>
            <button onClick={huddleCreateNewElement}>fazer um nova chamada</button>
            <button onClick={huddleDisplayMissed}>frequência</button>
          </div>
        </div>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li'>Presenças</li>
          <li className='header-table-ul-li'>Faltas</li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            frequencies?.length === 0
              ? <EmptyComponent>click em (fazer um nova chamada) para criar uma nova chamada</EmptyComponent>
              : frequencies?.map((frequency, index: number) => (
                <ContentTableFrequency
                  index={index}
                  activityInfo={activityInfo}
                  frequency={frequency}
                  setDisplayEditFrequency={setDisplayEditElement}
                  setDisplayFrequency={setDisplayFrequency}
                  setFrequencySelected={setFrequencySelected}
                  setFrequency={setFrequencies}
                  setElement={setElement}
                  key={String(frequency.id)}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
