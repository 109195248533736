/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './style.scss';
import ReadingFluencyLevelContext from '../../../pages/reading-fluency/hooks/reducer';
import getSeries from '../services/getSerires';

// {activityInfo.matterId} {activityInfo.twoMonths}

interface TableProps {
  display: string
}

export default function Table({display}: TableProps): React.ReactElement {
  const {room} = useParams();

  const [series, setSeries] = useState<number[]>([0, 0, 0, 0, 0])

  const { state } = useContext<Record<string, any>>(ReadingFluencyLevelContext);

  if (display === 'false') {
    return <></>
  }

  return (
		<form className='table-container-reading-fluency-level-record'>
      <Chart
				className='graphic-content'
				type='pie'
				width={800}
				series={getSeries(state.readingFluency)}
				options={{
				  labels: [
            'Conhece o alfabeto ou parte dele',
            'Soletrando',
            'Processo de Silabação',
            'Leitura Fluente',
            'Fluente +',
          ],
				  colors: ['#ff0800', '#ff0800', '#ffff00', '#0066ff', '#1d9e28'],
				  legend: {
				    fontSize: '16rem',
				    offsetY: 70
				  },
				  stroke: {
				    show: true,
				    curve: 'smooth',
				    lineCap: 'butt',
				    colors: ['#000000'],
				    width: 0.5,
				    dashArray: 0
				  },
				  chart: {
				    width: '50px',
				    height: '50px'
				  },
				  title: {
            align: 'center',
				    margin: 30,
				    style: {
				      fontSize: '2rem'
				    },
				    text: `Fluência Leitora | ${room ?? ''}`
				  }
				}}
			/>
    </form>
  );
}
