import React, { useEffect, useState } from 'react';
import type MunicipalEducationPlanMonitoringSheetType from '../../../class/types/municipal-education-plan-monitoring-sheet';
import { type IndicatorType, type MonitoringSheetGoalType } from '../../../class/types/municipal-education-plan-monitoring-sheet';
import './style.scss';
import MunicipalEducationPlanMonitoringSheetCostumer from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-costumer';
import { useParams } from 'react-router-dom';
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import ContentTableMunicipalEducationPlanMonitoringSheetIndicator from '../../../components/municipal-education-plan-monitoring-sheet-files/contentTable-municipal-education-plan-monitoring-sheet-indicators';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
  goalInfo: MonitoringSheetGoalType | undefined
}

export default function Table({ display, goalInfo }: TableProps): React.ReactElement {
  const [monitoringSheet, setMonitoringSheet] = useState<MunicipalEducationPlanMonitoringSheetType>();
  const [indicators, setIndicators] = useState<IndicatorType[]>([]);

  const [createIndicatorLoad, setCreateIndicatorLoad] = useState<boolean>(false);

  const contentLoadingStyle = {
    button: {
      fontSize: '25px',
    },
    container: {
      width: '70px',
    }
  }

  const {id} = useParams();

  const huddleCreateInitialTargetIndicator = () => {
    if (!id || !goalInfo?.id) {
      return;
    }

    const municipalEducationPlanMonitoringSheetCostumer = new MunicipalEducationPlanMonitoringSheetCostumer();

    setCreateIndicatorLoad(true);
    municipalEducationPlanMonitoringSheetCostumer.createInitialTargetIndicator(id, goalInfo?.id).then(response => {
      setIndicators(response)
      setCreateIndicatorLoad(false);
    }).catch(e => {
      //
    });
  }

  useEffect(() => {
    if (!id || !goalInfo?.id) {
      return;
    }

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    municipalEducationPlanMonitoringSheetApi.getAllTargetIndicator(id, goalInfo.id).then(response => {
      setIndicators(response);
    }).catch(e => {

    });

    municipalEducationPlanMonitoringSheetApi.get().then(response => {
      const monitoringSheet = response.find(value => value.id === id);

      if (!monitoringSheet) {
        return;
      }

      setMonitoringSheet(monitoringSheet);
    }).catch(e => {
      //
    });
  }, [display, goalInfo]);

  return (
    <div className='municipal-education-plan-monitoring-sheet-indicators-table'>
      <section className='municipal-education-plan-monitoring-sheet-indicators-section'>
        <header className='municipal-education-plan-monitoring-sheet-indicators-header'>
          <h1>Indicadores</h1>
          {createIndicatorLoad
? (
            <LoadContent
              isLoading={createIndicatorLoad}
              style={contentLoadingStyle}
            />
          )
: <button className='button-content' onClickCapture={huddleCreateInitialTargetIndicator}>Criar indicador</button>}
        </header>
      </section>
      <section className='municipal-education-plan-monitoring-sheet-indicators-table'>
        {indicators.map((indicators) => {
          return <ContentTableMunicipalEducationPlanMonitoringSheetIndicator setIndicators={setIndicators} goalInfo={goalInfo} indicator={indicators} monitoringSheet={monitoringSheet} key={'d'}/>
        })}
      </section>
    </div>
  );
}
