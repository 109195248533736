/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import MunicipalEducationPlanMonitoringSheetCostumer from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-costumer';
import type MunicipalEducationPlanMonitoringSheetType from '../../../class/types/municipal-education-plan-monitoring-sheet';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setMunicipalEducationPlanMonitoringSheet: React.Dispatch<React.SetStateAction<MunicipalEducationPlanMonitoringSheetType[]>>
}

export default function FormAddElement({ displayCreateNewElement, setDisplayCreateNewElement, setMunicipalEducationPlanMonitoringSheet}: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const municipalEducationPlanMonitoringSheetCostumer = new MunicipalEducationPlanMonitoringSheetCostumer(inputs);

    setIsLoading(true);
    municipalEducationPlanMonitoringSheetCostumer.post().then(response => {
      if ((response[0] as {error: boolean}).error) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setDisplayElement(false);
      setMunicipalEducationPlanMonitoringSheet(response as MunicipalEducationPlanMonitoringSheetType[]);
    }).catch(e => {
      //
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-home display-${displayElement}`}>
      <form method='POST' className='form-add-element' id='form-add-element' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Ficha de monitoramento do plano municipal de educação
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='acao'>Município:</label>
            <input type='text' id='acao' placeholder='nome do município' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Cod Município:</label>
            <input type='text' id='responsavel' placeholder='código município' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Microrregião:</label>
            <input type='text' id='responsavel' placeholder='microrregião' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Mesorregião:</label>
            <input type='text' id='responsavel' placeholder='mesorregião' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>UF:</label>
            <input type='text' id='responsavel' placeholder='UF' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Plano Municipal de Educação:</label>
            <input type='text' id='responsavel' placeholder='plano municipal de educação' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Períodos de Avaliação previstos:</label>
            <input type='text' id='responsavel' placeholder='períodos de avaliação' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Ano da primeira avaliação:</label>
            <input type='text' id='responsavel' placeholder='ano de primeira avaliação' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Comissão Coordenadora:</label>
            <input type='text' id='responsavel' placeholder='comissão coordenadora' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Lei:</label>
            <input type='text' id='responsavel' placeholder='lei' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Equipe Técnica:</label>
            <input type='text' id='responsavel' placeholder='equipe técnica' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Lei:</label>
            <input type='text' id='responsavel' placeholder='lei' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Telefone:</label>
            <input type='text' id='responsavel' placeholder='telefone' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Email:</label>
            <input type='text' id='responsavel' placeholder='email' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Inicio do Prazo:</label>
            <input type='number' id='responsavel' placeholder='inicio do prazo' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Fim do Prazo:</label>
            <input type='number' id='responsavel' placeholder='fim do prazo' />
          </div>
          <div className='buttons-content'>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={
                    {
                      button: {
                        fontSize: 25,
                      },
                      container: {
                        width: 25,
                      }
                    }
                  }
                />
              )
              : (
                <>
                  <button className='cancel-button' type='button' onClick={() => {
                    hundleClickClose(true);
                  }}>Cancelar</button>
                  <button className='save-button' type='submit'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
