/* eslint-disable react/jsx-key */
import React from 'react';
import './styles.scss';
import { type QuestionContentType } from '../../../class/types/student-exams/student-exams-protocol';
import ExamTitleContent from '../exam-title-content';
import ExamImgContent from '../exam-img-content';
import ExamOptionContent from '../exam-option-content';
import ExamTextContent from '../exam-text-content';

interface QuestionContentProps {
  question: QuestionContentType
  index: number
}

export default function QuestionContent({ question, index }: QuestionContentProps): React.ReactElement {
  return (
    <div className='question-content-response-from-student'>
      <div className='header-question-content-response-from-student'>
        <h1>Questão {index + 1}</h1>
      </div>
      <div className='question-content-table'>
        {question.elements
          ? Object.keys(question.elements).map(key => {
            if (!question.elements) {
              return <></>
            }

            if (question.elements[key].type === 'title') {
              return <ExamTitleContent option={question.elements[key]} />
            }

            if (question.elements[key].type === 'img') {
              return <ExamImgContent element={question.elements[key]} />
            }

            if (question.elements[key].type === 'options') {
              return <ExamOptionContent questionId={question.id ?? ''} elementId={key} element={question.elements[key]} />
            }

            if (question.elements[key].type === 'text') {
              return <ExamTextContent option={question.elements[key]} />
            }

            return <></>
          })
          : ''}
      </div>
    </div>
  )
}
