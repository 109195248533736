/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState, useEffect, useReducer, useContext } from 'react';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import { useParams } from 'react-router-dom';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import RoomsApi from '../../../class/Room/RoomsApi';
import ContentTableStudentWritingLevelRecord from '../../../components/student-writing-level-record/ContentTable-room';
import type { StudentWritingLRRes, TagType } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import SaveAlert from '../../../components/save-alert';
import StudentWritingLevelRecordTagsManager from '../../../class/student-writing-level-record/student-writing-level-record-tags-manager';
import UserAPI from '../../../class/User/UserAPI';
import RuleVerification from '../../../components/rule-verification';
import ContentTableReadingFluency from '../../../components/reading-fluency-files/ContentTable-reading-fluency';
import ContentTableReadingFluencyPage from '../../../components/reading-fluency-page/reading-fluency-page';
import ReadingFluencyCustumer from '../../../class/reading-fluency/reading-fluency-custumer';
import ReadingFluencyLevelCustumer from '../../../class/reading-fluency-level/reading-fluency-level-custumer';
import { type ReadingFluencyLevelResType } from '../../../class/types/ReadingFluencyLevel/reading-fluency-level-api-protocol';
import readingFluencyLevelApi from '../../../class/reading-fluency-level/reading-fluency-level-api';
import ReadingFluencyLevelContext from '../../../pages/reading-fluency/hooks/reducer';

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function Table(): React.ReactElement {
  const [user, setUser] = useState<UserClientProtocol>();
  const [rule, setRule] = useState<string>('');
  const [displaySaveAlert, setDisplaySaveAlert] = useState(false);
  const [students, setStudents] = useState<StudentType[]>([]);
  const [readingFluency, setReadingFluency] = useState<ReadingFluencyLevelResType[]>([]);
  const [displayDashboardWritingLevel, setDisplayDashboardWritingLevel] = useState(false);

  const { roomId, id } = useParams();

  const {dispatch} = useContext<Record<string, any>>(ReadingFluencyLevelContext);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const inputs = form.querySelectorAll('input');

    if (!id) {
      return;
    }

    const readingFluencyCustumer = new ReadingFluencyLevelCustumer(id, inputs, students);

    readingFluencyCustumer.post().then(response => {
      setReadingFluency(response);
      huddleDisplaySaveAlert();
      dispatch({
        type: 'SET/READING_FLUENCY',
        readingFluency: response,
      });
    })
  };

  const huddleDisplaySaveAlert = () => {
    if (displaySaveAlert) {
      return;
    }

    setDisplaySaveAlert(true);
    setTimeout(() => {
      setDisplaySaveAlert(false);
    }, 3000);
  };

  useEffect(() => {
    setDisplayDashboardWritingLevel(false);

    if (!roomId || !id) {
      return;
    }

    RoomsApi.getAllStudentByRoom(roomId).then(response => {
      setStudents(response);
    });

    readingFluencyLevelApi.getAll(id).then(response => {
      setReadingFluency(response);
      dispatch({
        type: 'SET/READING_FLUENCY',
        readingFluency: response,
      });
    })

    const user = lscache.get('user');
    if (user) {
      setUser(user);
      UserAPI.getPermission(user.id).then(rule => {
        if (rule) {
          setRule(rule);
        }
      });
    }
  }, []);

  return (
		<form className='table-container-reading-fluency-page' onSubmit={e => {
		  hundleSubmit(e);
		}}>
			<SaveAlert
				display={displaySaveAlert}
				setDisplay={setDisplaySaveAlert}
			>
                Fluência Leitora salvo
			</SaveAlert>
			<div className='mid-content-writing-level-record'>
				<div className='mid-element-writing-level-record'>
					<div className='header-writing-level-record'>
						<h1>Fluência Leitora</h1>
					</div>
					<div className='btn-create-new-matter-container'>
					</div>
				</div>
				<div className='mid-element-writing-level-record'>
					<div className='btn-create-new-matter-container'>
						<RuleVerification rule={['coordenador']}>
              <button type='submit'>Salvar</button>
            </RuleVerification>
					</div>
				</div>
			</div>
			<div className='header-table-element'>
				<ul className='header-table-ul'>
					<li className='header-table-ul-li'>Nº</li>
					<li className='header-table-ul-li'>Aluno</li>
					<li className='header-table-ul-li'>Nível de Fluência</li>
					<li></li>
					<li></li>
				</ul>
			</div>
			<main className='main-table'>
				{students?.length === 0
				  ? <EmptyComponent>click em (adicionar uma nova atividade) para adicionar uma nova atividade</EmptyComponent>
				  : students?.map((student: any, index: number) => (
					<ContentTableReadingFluencyPage
						index={index}
						student={student}
						readingFluency={readingFluency[index]}
						key={`writing-level-record-${index}`}
						spy={readingFluency}
						readingFluencyTags={[
              'Conhece o alfabeto ou parte dele',
              'Soletrando',
              'Processo de Silabação',
              'Leitura Fluente',
              'Fluente +',
            ]}
					/>
				  ))}
			</main>
		</form>
  );
}
