import { type FrequencyByTwoMonths } from '../../../class/types/IndividualCardReporting';

export default function FrequencyFinalResultFormat(presence: number, absents: number, frequency: FrequencyByTwoMonths) {
  let justificationOfAbsences =
    frequency['twoMonths-one'].justificationOfAbsences +
    frequency['twoMonths-two'].justificationOfAbsences +
    frequency['twoMonths-tree'].justificationOfAbsences +
    frequency['twoMonths-for'].justificationOfAbsences;

  if (!justificationOfAbsences) {
    justificationOfAbsences = 0;
  }

  let missed = absents - justificationOfAbsences;

  if (missed < 0) {
    missed = 0;
  }

  const sum = presence + missed;

  let porcentual = Math.round((presence / sum) * 100);

  if (!porcentual) {
    porcentual = 0;
  }

  return porcentual === 0 ? '-' : `${porcentual}%`;
}
