import type StrategyCustumerProtocol from '../types/custumer-protocol';
import Validator from '../Validator';
import ElementarySchoolDigitalHTTPIApi from './ElementaryDigitalHTTPIApi'
import ElementarySchoolDigitalHTTPIFactory from './ElementarySchoolDigitalHTTPI-factory';
import type DigitalHTTPIResProtocol from '../types/DigitalHTTPI/digitalHTTPI-protocol';
import { type HTTPIType } from '../types/DigitalHTTPI/digitalHTTPI-protocol';

class ElementarySchoolDigitalHTTPICostumer implements StrategyCustumerProtocol<DigitalHTTPIResProtocol> {
	private readonly elementarySchoolMonthlyPlanApi = ElementarySchoolDigitalHTTPIApi;

	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>
	) {}

	async post(): Promise<any> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return [{
				error: true
			}];
		}

		let matters: DigitalHTTPIResProtocol[] = [];

		const elementarySchoolMonthlyPlanFactory = new ElementarySchoolDigitalHTTPIFactory(this.inputs);

		const elementarySchoolMonthlyPlan = elementarySchoolMonthlyPlanFactory.create();

		await this.elementarySchoolMonthlyPlanApi.post(elementarySchoolMonthlyPlan).then((response: any) => {
			matters = response;
		});

		return matters;
	}

	async put(id: string): Promise<any[]> {
		let elementarySchool: DigitalHTTPIResProtocol[] = [];

		if (!this.inputs) {
			return [];
		}

		const elementarySchoolMonthlyPlanFactory = new ElementarySchoolDigitalHTTPIFactory(this.inputs);

		const elementarySchoolPut = elementarySchoolMonthlyPlanFactory.put();

		await this.elementarySchoolMonthlyPlanApi.put(elementarySchoolPut, id).then((response: DigitalHTTPIResProtocol[]) => {
			elementarySchool = response;
		});

		return elementarySchool;
	}

  async postDayOfHTTPI(id: string) {
    const validation = new Validator(this.inputs);

		if (validation.init()) {
      return [{
        error: true
			}];
		}

    let dayOfHTTPI: HTTPIType[] = [];

    const elementarySchoolDigitalHTTPIFactory = new ElementarySchoolDigitalHTTPIFactory(this.inputs);

		const dayOfHTTPIPost = elementarySchoolDigitalHTTPIFactory.createDayOfHTTPI();

    await this.elementarySchoolMonthlyPlanApi.postDayOfHTTPI(id, dayOfHTTPIPost).then(response => {
      dayOfHTTPI = response;
    });

    return dayOfHTTPI
  }

  async putDayOfHTTPI(id: string, dayOfHTTPIId: string) {
    const validation = new Validator(this.inputs);

		if (validation.init()) {
      return [{
        error: true
			}];
		}

    let dayOfHTTPI: HTTPIType[] = [];

    const elementarySchoolDigitalHTTPIFactory = new ElementarySchoolDigitalHTTPIFactory(this.inputs);

		const dayOfHTTPIPost = elementarySchoolDigitalHTTPIFactory.createDayOfHTTPI();

    await this.elementarySchoolMonthlyPlanApi.putDayOfHTTPI(id, dayOfHTTPIId, dayOfHTTPIPost).then(response => {
      dayOfHTTPI = response;
    });

    return dayOfHTTPI
  }
}

export default ElementarySchoolDigitalHTTPICostumer;
