/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import UploadPsychologistCostume from '../../../class/UploadPsychologistReport/upload-psychologist-custumer';
import InputSelect from '../../../components/input-select';

interface FormAddPsychologistReportProps {
  setDisplayFormAddPsychologistReport: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolPsychologistReport: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  displayFormAddPsychologistReport: boolean
  psychologistReport: ElementarySchoolDecentWorkType[]
}

export default function FormAddPsychologistReport({ setDisplayFormAddPsychologistReport, setElementarySchoolPsychologistReport, displayFormAddPsychologistReport, psychologistReport }: FormAddPsychologistReportProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teacherSelected, setTeacherSelected] = useState<UserClientProtocol>();

  const [teachers, setTeachers] = useState<UserClientProtocol[]>([]);

  const huddleCancel = () => {
    setDisplayFormAddPsychologistReport(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!teacherSelected) {
      return;
    }

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolMonthlyPlanCustomer = new UploadPsychologistCostume(inputs, teacherSelected);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      elementarySchoolMonthlyPlanCustomer.post().then(response => {
        if (response.error) {
          setIsLoading(false);
          return;
        }

        setElementarySchoolPsychologistReport(response);

        setDisplayFormAddPsychologistReport(false);
        setIsLoading(false);

        clearInputs(inputs);
      }).catch(e => {
        //
      });
    }).catch(e => {
      //
    })
  };

  const clearInputs = (inputs?: NodeListOf<HTMLInputElement>) => {
    inputs?.forEach(input => {
      input.value = '';
    });
    setFileInfo(null);
  }

  const huddleSetOption = (element: string) => {
    const option = teachers.filter(teacher => teacher.userName.includes(element));

    setTeacherSelected(option[0]);
  }

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;

    if (user.permission?.rule === 'coordenador') {
      return;
    }

    UserAPI.getByPermission('teacher').then((response) => {
      setTeachers(response);
    }).catch((e) => {
      //
    });
  }, [displayFormAddPsychologistReport]);

  return (
    <div className={`form-add-school-psychologist-report display-${String(displayFormAddPsychologistReport)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Adicionar novo Relatório
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddPsychologistReport(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <InputSelect
          options={teachers.map(teacher => teacher.userName)}
          id={teachers.map(teacher => teacher.id ?? '')}
          title='Professor'
          cb={huddleSetOption}
          deleteAll={displayFormAddPsychologistReport}
        />
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date"/>
        </div>
        <div className="input-file-element">
          <label htmlFor="file-input-elementary-school-weekly-plan">
            <DisplayFileInfo fileInfo={fileInfo} />
          </label>
          <input
            type="file"
            id='file-input-elementary-school-weekly-plan'
            onChange={inputFileHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={{
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }}
                />
              )
              : (
                <>
                  <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                  <button type='submit' className='save-button'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
