/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import FormLoginElement from '../../components/form-login-element';
import ValidatorRl from '../../class/User/Validator-register-end-login';
import './style.scss';
import UserManager from '../../class/User/User-manager';
import lscache from 'lscache';
import displayErrorLogin from '../../class/services/displayErrorLogin';
import config from '../../config/config';
import UserAPI from '../../class/User/UserAPI';

export default function LoginContainer() {
  const hundleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const validation = new ValidatorRl(inputs);

    if (!validation.validation()) {
      const userManager = new UserManager(inputs);

      await userManager.login().then(async response => {
        if (response.userName) {
          lscache.setExpiryMilliseconds(60000);
          lscache.set('user', response, config.processTemp);
          await UserAPI.getPermission(response.id).then(response => {
            lscache.set('rule', response, config.processTemp);
            redirect();
          });
        } else {
          displayErrorLogin(inputs[0], response.error);
        }
      }).catch(e => {
        throw new Error(e);
      });
    }
  };

  const redirect = () => {
    window.location.href = window.location.origin;
  };

  return (
		<div className='login-container'>
			<div className='form-login-content'>
				<FormLoginElement hundleSubmit={hundleSubmit} />
			</div>
			<div className='img-login-content'>
				<img src={require('./img/undraw_Thought_process_re_om58.png')} alt='' />
			</div>
		</div>
  );
}
