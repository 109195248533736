/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import UploadRoomObserverSheetCostumer from '../../../class/UploadRoomObserverSheet/room-observer-sheet-custumer';
import { useParams } from 'react-router-dom';
import { type UploadRoomObserverSheetType } from '../../../class/types/Upload_room-observer-sheet-api';
import LoadContent from '../../../components/Load-content';

interface FormAddMonthlyPlanProps {
  setDisplayFormAddRoomObserverSheet: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolRoomObserverSheet: React.Dispatch<React.SetStateAction<UploadRoomObserverSheetType[]>>
  displayFormAddRoomObserverSheet: boolean
  elementarySchoolRoomObserverSheet: UploadRoomObserverSheetType[]
}

export default function FormAddUploadRoomObserverSheet({ setDisplayFormAddRoomObserverSheet, setElementarySchoolRoomObserverSheet, displayFormAddRoomObserverSheet, elementarySchoolRoomObserverSheet }: FormAddMonthlyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const {id, roomName} = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormAddRoomObserverSheet(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    if (!id || !roomName) {
      return;
    }

    const elementarySchoolMonthlyPlanCustomer = new UploadRoomObserverSheetCostumer(inputs, id, roomName);

    setIsLoading(true);
    elementarySchoolMonthlyPlanCustomer.post().then(response => {
      if (response.error) {
        setIsLoading(false);
        return;
      }
      setElementarySchoolRoomObserverSheet(response);

      setDisplayFormAddRoomObserverSheet(false);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  };

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  return (
		<div className={`form-add-upload-room-observer-sheet display-${String(displayFormAddRoomObserverSheet)}`}>
			<div className='header-form-add-element-content'>
				<h1>
                        Adicionar uma nova ficha de observação de sala
				</h1>
				<IoCloseOutline onClick={() => {
				  setDisplayFormAddRoomObserverSheet(false);
				}} size='25'/>
			</div>
			<form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
				<div className='input-element-content'>
					<label>Data</label>
					<input type="date" />
				</div>
				<div className="input-file-element">
					<label htmlFor="file-input-upload-room-observer-sheet">
						<DisplayFileInfo fileInfo={fileInfo} />
					</label>
					<input
						type="file"
						id='file-input-upload-room-observer-sheet'
						onChange={inputFileHuddle}
						accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
					/>
				</div>
				<div className='button-content'>
					<div>
            {isLoading ? (
              <LoadContent
              isLoading={isLoading}
              style={{
                button: {
                  fontSize: 25,
                },
                container: {
                  width: 25,
                }
              }}
            />
            ) : (
              <>
						<button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
						<button type='submit' className='save-button'>Salvar</button>
              </>
            )}
					</div>
				</div>
			</form>
		</div>
  );
}
