export default function filterByDate(plans: any[], firstDateParams: string, lastDateParams: string): any[] {
  const newPlans: any = [];

  const firstDate = new Date(firstDateParams);
  const lastDate = new Date(lastDateParams);

  plans.forEach((value) => {
    const planDate = new Date(value.date);

    if (firstDateParams.length === 0) {
      return;
    }
    if (planDate < firstDate) {
      return
    }

    if (lastDateParams.length === 0) {
      return;
    }

    if (planDate > lastDate) {
      return
    }
    newPlans.push(value);
  });

  if (newPlans.length === 0) {
    return plans;
  }

  return newPlans;
}
