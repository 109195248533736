/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import type { MatterResProtocol } from '../../../class/types/Matter/matterApi-protocol';
import './style.scss';

interface MatterElementProps {
  matter: MatterResProtocol
  twoMonths: string
  twoMonthsName: string
  onClickLi: (elementName: string) => void
  setActivityInfo: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
}

export default function MatterElement({ matter, onClickLi, setActivityInfo, twoMonths, twoMonthsName }: MatterElementProps): React.ReactElement {
  const [displayEle, setDisplayEle] = useState(false);

  const huddleClick = (matterId: string | undefined) => {
    setDisplayEle(!displayEle);
  };

  const huddleClickLiActivity = (elementName: string): void => {
    onClickLi(elementName);

    setActivityInfo({
      matterId: matter.id,
      matterName: matter.matterName,
      twoMonths,
      twoMonthsName
    });
  };

  return (
		<div>
			<li className='matter-option-content' key={matter.id} onClick={e => {
			  huddleClick(matter.id);
			}}>
				<h1>{matter.matterName}</h1>
				<IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEle}`} />
			</li>
			<div className={`matter-element-content display-${displayEle}`}>
				<ul>
					<li onClick={() => {
					  huddleClickLiActivity('strategy-5');
					}}>
						<h1>Frequência dos alunos</h1>
					</li>
				  <li onClick={() => {
					  huddleClickLiActivity('strategy-12');
					}}>
						<h1>Justificativa de falta</h1>
					</li>
				  <li onClick={() => {
					  huddleClickLiActivity('strategy-14');
					}}>
						<h1>Ocorrências</h1>
					</li>
				  <li onClick={() => {
					  huddleClickLiActivity('strategy-16');
					}}>
						<h1>Adendo ao Calendário Escolar</h1>
					</li>
					<li onClick={() => {
					  huddleClickLiActivity('strategy-3');
					}}>
						<h1>Conteúdo Programático</h1>
					</li>
					<li onClick={() => {
					  huddleClickLiActivity('strategy-4');
					}}>
						<h1>Boletim</h1>
					</li>
				</ul>
			</div>
		</div>
  );
}
