/* eslint-disable multiline-ternary */
import React, { useState } from 'react';
import './style.scss';
import ElementarySchoolMonthlyPlanCostumer from '../../../class/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlan-custumer';
import { type KindergartenSchoolMonthlyPlanType } from '../../../class/types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';
import clearAllInputs from '../service/clear-all-inputs';
import LoadContent from '../../../components/Load-content';

interface FormAddMonthlyPlanProps {
  setDisplayFormAddMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<KindergartenSchoolMonthlyPlanType[]>>
  displayFormAddMonthlyPlan: boolean
  elementarySchoolMonthlyPlan: KindergartenSchoolMonthlyPlanType[]
}

export default function FormAddMonthlyPlan({ setDisplayFormAddMonthlyPlan, setElementarySchoolMonthlyPlan, displayFormAddMonthlyPlan, elementarySchoolMonthlyPlan }: FormAddMonthlyPlanProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormAddMonthlyPlan(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolMonthlyPlanCostumer = new ElementarySchoolMonthlyPlanCostumer(inputs);

    setIsLoading(true)
    elementarySchoolMonthlyPlanCostumer.post().then((response) => {
      if (response[0].error) {
        setIsLoading(false);
        return;
      }

      setElementarySchoolMonthlyPlan(response);
      setDisplayFormAddMonthlyPlan(false);
      clearAllInputs('form-add-monthly-plan');
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  return (
    <div className={`form-add-monthly-plan display-${String(displayFormAddMonthlyPlan)}`}>
      <div>
        <h1>Plano Mensal - Ensino Infantil</h1>
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label>Escola</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label>Professor(a):</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label>Turma:</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label>Tempo Vigente:</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label>Ano:</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label>Objetivo geral:</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label>Objetivos específicos:</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label>Direitos de Aprendizagem e Desenvolvimento na Educação Infantil:</label>
          <input type="text" />
        </div>
        <div className='input-element-content'>
          <label>Estratégias/Recursos:</label>
          <input type="text" />
        </div>
        <div className='input-element-content date'>
          <label>Data:</label>
          <input type="date" />
        </div>
        <div className='title'>
          <h1>Metodologia</h1>
        </div>
        <div className='input-element-content'>
          <div className='input-content'>
            <label>Campos de experiência</label>
            <input type="text" />
          </div>
          <div className='input-content'>
            <label>Objetivo de Aprendizagem e Desenvolvimento</label>
            <input type="text" />
          </div>
          <div className='input-content'>
            <label>Temas</label>
            <input type="text" />
          </div>
          <div className='input-content'>
            <label>Recurso</label>
            <input type="text" />
          </div>
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
