/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import config from '../../../config/config';
import lscache from 'lscache';
import { type UserProtocol } from '../../types/User/User-factory-protocol';
import { type TextualGenreTagType, type TextualGenreType } from '../../types/new-writing-hypothesi/textual-genre';
import { type WritingHypothesisResultType } from '../../types/new-writing-hypothesi/new-writing-hypothesis';

export default class NewWritingHypothesisApi {
  private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  async initWritingHypothesis(roomId: string, twoMonths: string, textualGenreId: string): Promise<TextualGenreType[]> {
    let textualGenre: TextualGenreType[] = [];

    await axios.get(this.href + `/new-writing-hypothesi/textual-genre/writing-level/${roomId}/${twoMonths}/${textualGenreId}`).then(response => {
      textualGenre = response.data;
    }).catch(e => {
      //
    });

    return textualGenre
  }

  async postWritingHypothesis(roomId: string, twoMonths: string, textualGenreId: string, studentId: string, writingLevel: {
    id?: string
    tag?: string
    color?: string
  }) {
    let textualGenres: TextualGenreType[] = [];

    await axios.post(this.href + `/new-writing-hypothesi/textual-genre/writing-level/${roomId}/${twoMonths}/${textualGenreId}/${studentId}`, writingLevel).then((response) => {
      textualGenres = response.data
    }).catch(e => {
      //
    });

    return textualGenres;
  }

  async generateWritingHypothesis(roomId: string, twoMonths: string, textualGenreId: string): Promise<WritingHypothesisResultType | undefined> {
    let textualGenre: WritingHypothesisResultType | undefined;

    await axios.get(this.href + `/new-writing-hypothesi/textual-genre/generate/writing-hypothesis/${roomId}/${twoMonths}/${textualGenreId}`).then(response => {
      textualGenre = response.data;
    }).catch(e => {
      //
    });

    return textualGenre;
  }
}
