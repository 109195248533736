import lscache from 'lscache';
import type DigitalHTTPIResProtocol from '../types/DigitalHTTPI/digitalHTTPI-protocol';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import { type HTTPIType } from '../types/DigitalHTTPI/digitalHTTPI-protocol';
import { subTime } from './services';

class ElementarySchoolDigitalHTTPIFactory {
  private readonly user = lscache.get('user') as UserClientProtocol;

  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
  ) {}

  create(): DigitalHTTPIResProtocol {
    return {
      date: this.inputs.item(0).value,
      issue: {
        id: this.user.id ?? '',
        date: this.formateDate(),
        name: this.user.userName,
        rule: this.user.permission?.rule ?? '',
      }

    }
  }

  put(): DigitalHTTPIResProtocol {
    return {
      date: this.inputs.item(0).value,
    };
  }

  formateDate(): string {
    const date = new Date();

    return date.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  }

  createDayOfHTTPI(): HTTPIType {
    return {
      activity: this.inputs[0].value,
      date: this.inputs[1].value,
      time: {
        after: this.inputs[2].value,
        before: this.inputs[3].value,
        totalTime: subTime(this.inputs[2].value, this.inputs[3].value),
      }
    }
  }
}

export default ElementarySchoolDigitalHTTPIFactory;
