/* eslint-disable no-mixed-spaces-and-tabs */

import React from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import type RoomObserverType from '../../../class/types/RoomObserver';

interface FormEditWeeklyPlanPlanProps {
  setDisplayWeeklyPlanInfo: React.Dispatch<React.SetStateAction<boolean>>
  displayWeeklyPlanInfo: boolean
  element: RoomObserverType | undefined
}

export default function WeeklyPlanDisplayInformation({
  setDisplayWeeklyPlanInfo,
  displayWeeklyPlanInfo,
  element
}: FormEditWeeklyPlanPlanProps): JSX.Element {
  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  return (
		<div className={`form-describe-school-teaching-work-plan display-${String(displayWeeklyPlanInfo)}`}>
			<div className='header-form-describe-element-content'>
				<h1>
                        Plano Mensal
				</h1>
				<IoCloseOutline onClick={() => {
				    setDisplayWeeklyPlanInfo(false);
				}} size='25'/>
			</div>
			<div className='main-monthly-plan-display-information' id='main-monthly-plan-display-information'>
				<div className="decent-work-information">
					<h1>
            Data:
            <span>{formatDate(element?.date ?? '')}</span>
          </h1>
				</div>
				<div className="decent-work-information">
					<h1>
            Observação:<br/>
            <span>{element?.content}</span>
          </h1>
				</div>
			</div>
		</div>
  );
}
