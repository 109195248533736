/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React from 'react';
import './style.scss';
import { MdEmail } from 'react-icons/md';

interface FormLoginElementProps {
  huddleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  huddleSubmitTwo: (e: React.FormEvent<HTMLFormElement>) => void
  displayForm: boolean
}

export default function FormRecoverByEmailElement({ huddleSubmit, huddleSubmitTwo, displayForm }: FormLoginElementProps): React.ReactElement {
  return (
		<div className='form-recover-by-email-main'>
			<form action='POST' className={`form-login-element display-${!displayForm}`} onSubmit={e => {
			  huddleSubmit(e);
			}}>
				<div className='form-login-title'>
					<h1>Recuperar senha</h1>
				</div>
				<div className='input-login-content'>
					<label htmlFor='user-email-recover'>Email: </label>
					<br />
					<div className='input-content-recover-by-email'>
						<MdEmail size={18} className='icon-user-login' />
						<input type='text' id='user-email-recover' placeholder='digite o seu email' />
					</div>
				</div>
				<div className='input-login-content button-login-content'>
					<button type='submit' className='button-login'>Recuperar</button>
				</div>
			</form>
			<form action='POST' className={`form-login-element display-${displayForm}`} onSubmit={e => {
			  huddleSubmitTwo(e);
			}}>
				<div className='form-login-title'>
					<h1>Digite o código enviado no seu email</h1>
				</div>
				<div className='input-login-content'>
					<label htmlFor='user-email-recover-code'>Código: </label>
					<br />
					<div className='input-content-recover-by-email'>
						<input type='text' id='user-email-recover-code' placeholder='digite o código enviado no seu email aqui' />
					</div>
				</div>
				<div className='input-login-content button-login-content'>
					<button type='submit' className='button-login'>Recuperar</button>
				</div>
			</form>
		</div>
  );
}
