/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';

interface BackToTopProps {
  element: HTMLElement
}

export default function BackToTop({element}: BackToTopProps): JSX.Element {
  const [display, setDisplay] = useState<boolean>(false);

  const huddleClick = () => {
    element.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }

  useEffect(() => {
    if (!element) {
      return;
    }
    element.addEventListener('scroll', (e) => {
      const target = e.target as HTMLElement;

      if (target.scrollTop >= 1000) {
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    });
  }, [element]);

  return (
    <div className={`container-back-to-top-button display-${display}`}>
      <button onClick={huddleClick}>
        <BsFillArrowUpCircleFill className='arrow-icon-back-to-top' />
      </button>
    </div>
  )
}
