/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {useState} from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import uploadElementarySchoolStudentReport from '../../class/UploadElementarySchoolStudentReport/upload-student-report-api';
import LoadContent from '../Load-content';
import uploadStudentReportApi from '../../class/UploadKindergartenSchoolStudentReport/upload-student-report-api';
import UploadWorkshopStudentReportApi from '../../class/UploadWorkshopStudentReport/upload-student-report-api';
import { type StudentReportType } from '../../class/types/StudentReport';

interface ReadCheckingProps {
  displayReadChecking: boolean
  elementId: string
  setElementaryStudentReport: React.Dispatch<React.SetStateAction<StudentReportType[]>>
  setDisplayReadChecking: React.Dispatch<React.SetStateAction<boolean>>
  type: 'elementary-school' | 'kindergarten-school' | 'workshop'
}

export default function ReadCheckingStudentReport({displayReadChecking, elementId, type, setElementaryStudentReport, setDisplayReadChecking}: ReadCheckingProps) {
  const [isLoading, setIsLoading] = useState(false);
  const setCheckingHuddle = () => {
    setIsLoading(true);
    if (type === 'elementary-school') {
      uploadElementarySchoolStudentReport.setChecking(elementId).then(response => {
        setElementaryStudentReport(response);
        setDisplayReadChecking(false);
        setIsLoading(false);
      });
      return;
    }

    if (type === 'workshop') {
      UploadWorkshopStudentReportApi.setChecking(elementId).then(response => {
        setElementaryStudentReport(response);
        setDisplayReadChecking(false);
        setIsLoading(false);
      });
      return;
    }

    uploadStudentReportApi.setChecking(elementId).then(response => {
      setElementaryStudentReport(response);
      setDisplayReadChecking(false);
      setIsLoading(false);
    });
  }

  return (
    <div className={`read-checking-element display-${String(displayReadChecking)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          confirmação de visualização
        </h1>
        <IoCloseOutline size='25' onClickCapture={() => {
          setDisplayReadChecking(false);
          setIsLoading(false);
        }} />
      </div>
      <div className='main-content-element'>
        <h2>
          Para prosseguir, escolha entre Cancelar ou Confirmar a visualização do documento
        </h2>
      </div>
      <div className='button-confirm-content'>
        <button className='button-cancel button' onClickCapture={() => {
          setDisplayReadChecking(false);
          setIsLoading(false);
        }}>cancelar</button>
        {isLoading
          ? (
            <LoadContent
              isLoading={isLoading}
              style={
                {
                  button: {
                    width: 30,
                    marginRight: 40,
                  },
                  container: {
                    justifyContent: 'flex-end'
                  }
                }
              }
            />
          )
          : (
            <button className='button-confirm button' onClickCapture={setCheckingHuddle} >confirmar</button>
          )}
      </div>
    </div>
  )
}
