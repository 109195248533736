/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';

interface SaveAlertProps {
  children: string
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SaveAlert({ children, display, setDisplay }: SaveAlertProps): React.ReactElement {
  const hundleClose = () => {
    setDisplay(false);
  };

  return (
		<div className={`save-alert-component display-${display}`}>
			<h1>
				<span>
					{children}
				</span>
				<IoCloseOutline className='icon-element' size='26' onClick={hundleClose}/>
			</h1>
		</div>
  );
}
