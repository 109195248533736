/* eslint-disable no-unneeded-ternary */
import type { StudentType } from '../types/Student/student-factory-protocol';
import type StudentProtocol from '../types/Student/student-factory-protocol';

class WaitingStudentFactory implements StudentProtocol {
	constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {}

	create(): StudentType {
		return {
			studentName: this.inputs[0].value,
			birthday: this.inputs[1].value,
			responsible: this.inputs[2].value,
			responsibleNumber: this.inputs[3].value,
			ra: {
				content: this.inputs[4].value,
				uf: this.inputs[5].value
			},
			situation: 'Ativo',
			district: this.inputs[6].value,
			sex: this.inputs[7].value,
      monetaryAid: this.verifyMonetaryAid(this.inputs[8].value)
		};
	}

	createPut(): StudentType {
		return {
			studentName: this.verifyValue(0),
			birthday: this.verifyValue(1),
			responsible: this.verifyValue(2),
			responsibleNumber: this.verifyValue(3),
			ra: {
				content: this.verifyValue(4),
				uf: this.verifyValue(5)
			},
			district: this.verifyValue(6),
			sex: this.verifyValue(7),
      monetaryAid: this.verifyMonetaryAid(this.inputs[8].value),
			situation: this.verifyValue(9),
		};
	}

  verifyMonetaryAid(value: string) {
    return ['Sim', 'sim'].includes(value) ? true : false;
  }

	verifyValue(index: number): string {
		return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
	}
}

export default WaitingStudentFactory;
