import lscache from 'lscache';
import type MunicipalEducationPlanMonitoringSheetType from '../../types/municipal-education-plan-monitoring-sheet';
import { type IndicatorType, type GoalType, type MunicipalEducationPlanMonitoringSheetFactoryProtocol, type StrategyType } from '../../types/municipal-education-plan-monitoring-sheet';
import { type UserClientProtocol } from '../../types/User/UserAPI-protocol';

export default class MunicipalEducationPlanMonitoringSheetFactory implements MunicipalEducationPlanMonitoringSheetFactoryProtocol {
  private readonly user = lscache.get('user') as UserClientProtocol;

  constructor(private readonly inputs?: NodeListOf<HTMLInputElement>) {}

  create(): MunicipalEducationPlanMonitoringSheetType | undefined {
    if (!this.inputs) {
      return undefined;
    }

    return {
      county: this.inputs.item(0).value,
      codCount: Number(this.inputs.item(1).value),
      microRegion: this.inputs.item(2).value,
      mesoRegion: this.inputs.item(3).value,
      uf: this.inputs.item(4).value,
      municipalEducationPlan: this.inputs.item(5).value,
      expectedEvaluationPeriods: this.inputs.item(6).value,
      yearOfFirstAssessment: this.inputs.item(7).value,
      coordinatingCommittee: {
        names: this.inputs.item(8).value,
        law: this.inputs.item(9).value,
      },
      technicalTeam: {
        names: this.inputs.item(10).value,
        law: this.inputs.item(11).value,
      },
      issuer: {
        id: this.user.id ?? '',
        name: this.user.userName ?? '',
        date: this.getDate(),
      },
      referenceContacts: {
        tell: this.inputs.item(12).value,
        email: this.inputs.item(13).value,
      },
      period: {
        init: this.inputs.item(14).value,
        end: this.inputs.item(15).value,
      }
    }
  }

  createGoalText(goalText: HTMLTextAreaElement, deadLine: HTMLTextAreaElement, observation: HTMLTextAreaElement): GoalType {
    return {
      goalText: goalText.value,
      deadLine: deadLine.value,
      observation: observation.value,
    }
  }

  createInitialTargetIndicator(): IndicatorType {
    return {
      content: '',
    }
  }

  createIndicator(
    expectGoal: NodeListOf<HTMLInputElement>,
    executedGoalDO: NodeListOf<HTMLInputElement>,
    executedGoalDE: NodeListOf<HTMLInputElement>,
    indicatorContent: HTMLInputElement,
    indicatorTerm: HTMLInputElement,
    reachedTheTerm: string,
  ): IndicatorType {
    return {
      content: indicatorContent.value,
      term: indicatorTerm.value,
      reachedTheTerm,
      indicators: {
        executedGoalDE: this.formatInputsIndicator(executedGoalDE),
        executedGoalDO: this.formatInputsIndicator(executedGoalDO),
        expectedGoal: this.formatInputsIndicator(expectGoal)
      }
    }
  }

  formatInputsIndicator(inputs: NodeListOf<HTMLInputElement>): string[] {
    const values: string[] = [];

    inputs.forEach(inputs => {
      values.push(inputs.value);
    });

    return values;
  }

  getDate(): string {
    return new Date().toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      dateStyle: 'medium'
    });
  }

  createInitialStrategy(): StrategyType {
    return {
      status: '',
    }
  }

  createStrategy(textArea: NodeListOf<HTMLTextAreaElement>, inputs: NodeListOf<HTMLInputElement>): StrategyType {
    return {
      strategies: textArea.item(0).value,
      term: textArea.item(1).value,
      budgetForecast: textArea.item(2).value,
      status: inputs.item(0).value,
      executedTheStrategy: inputs.item(1).value,
      observation: textArea.item(3).value,
    }
  }
}
