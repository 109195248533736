/* eslint-disable max-depth */

import type CardReportFactoryProtocol from '../types/CardReport/cardReport-factory-protocol';
import type { StudentType } from '../types/Student/student-factory-protocol';
import type { StudentObjType } from './services/transformInObj';
import transformInObj from './services/transformInObj';

class CardReportFactory implements CardReportFactoryProtocol {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly students: StudentType[],
		private readonly twoMonthsName: string,
		private readonly matterId: string
	) {}

	create(): StudentObjType {
		const newStudent = this.students.map(student => {
			if (!this.twoMonthsName) {
				return [];
			}

			if (this.matterExist(student, this.matterId)) {
				const index = this.matterIndex(student, this.matterId);

				if (typeof index !== 'undefined') {
					if (student.matter) {
						const twoMonthsExist = this.twoMonthsExist(student.matter[index].twoMonths, this.twoMonthsName);

						if (twoMonthsExist) {
							const twoMonthsIndex = this.twoMonthsIndex(student.matter[index].twoMonths, this.twoMonthsName);

							if (typeof twoMonthsIndex !== 'undefined') {
								student.matter[index].twoMonths[twoMonthsIndex].cardReport = [];
							}
						}
					}
				}
			}

			if (!student.matter) {
				student.matter = [];
			}

			this.inputs.forEach(input => {
				if (student.id === input.id) {
					if (!this.matterExist(student, this.matterId)) {
						if (student.matter) {
							student.matter.push(
								{
									matterId: this.matterId,
									twoMonths: []
								}
							);
						}
					}

					const index = this.matterIndex(student, this.matterId);

					if (typeof index === 'undefined') {
						return [];
					}

					if (!student.matter) {
						return [];
					}

					if (!this.twoMonthsExist(student.matter[index].twoMonths, this.twoMonthsName)) {
						student.matter[index].twoMonths.push({
							twoMonthsName: this.twoMonthsName,
							cardReport: []
						});
					}

					const twoMonthsIndex = this.twoMonthsIndex(student.matter[index].twoMonths, this.twoMonthsName);

					if (typeof twoMonthsIndex === 'undefined') {
						return [];
					}

					student.matter[index]?.twoMonths[twoMonthsIndex].cardReport.push(input.value);
				}
			});
			return student;
		}) as StudentType[];

		if (!newStudent) {
			return {};
		}

		const studentObj = transformInObj(newStudent);

		return studentObj;
	}

	twoMonthsExist(twoMonths: Array<{
		cardReport: string[]
		twoMonthsName: string
	}>, twoMonthsName: string): boolean {
		let twoMonthsExist = false;

		try {
			twoMonths.forEach(twoMonths => {
				if (twoMonths.twoMonthsName === twoMonthsName) {
					twoMonthsExist = true;
				}
			});
		} catch {
			return twoMonthsExist;
		}

		return twoMonthsExist;
	}

	twoMonthsIndex(twoMonths: Array<{
		cardReport: string[]
		twoMonthsName: string
	}>, twoMonthsName: string): number | undefined {
		let mainIndex;

		try {
			twoMonths.forEach((twoMonths, index) => {
				if (twoMonths.twoMonthsName === twoMonthsName) {
					mainIndex = index;
				}
			});
		} catch {
			return mainIndex;
		}

		return mainIndex;
	}

	matterExist(student: StudentType, matterId: string): boolean {
		let exist = false;

		try {
			student.matter?.forEach(matter => {
				if (matter.matterId === matterId) {
					exist = true;
				}
			});
		} catch {
			return exist;
		}

		return exist;
	}

	matterIndex(student: StudentType, matterId: string): number | undefined {
		let mainIndex;

		if (!student.matter) {
			return mainIndex;
		}

		student.matter?.forEach((matter, index) => {
			if (matter.matterId === matterId) {
				mainIndex = index;
			}
		});

		return mainIndex;
	}
}

export default CardReportFactory;
