import displayError from '../services/displayError';
import type MathematicsProblemSolvingTagType from '../types/MathematicsProblemSolving';
import { type MathematicsProblemSolvingObjType } from '../types/MathematicsProblemSolving';
import { type StudentType } from '../types/Student/student-factory-protocol';
import MathematicsProblemSolvingApi from './MathematicsProblemSolving-api';
import MathematicsProblemSolvingFactory from './MathematicsProblemSolving-factory';

export default class MathematicsProblemSolvingCostumer {
  constructor(
    private readonly input: HTMLInputElement | undefined,
    private readonly roomId: string | undefined,
    ) {}

  async postTag(): Promise<MathematicsProblemSolvingTagType[]> {
    if (!this.roomId) {
      return [];
    }

    if (!this.input) {
      return [];
    }

    if (this.input.value.length === 0) {
      displayError(this.input, 'o campo esta vazio')
      return [];
    }

    let problemSolvingTags: MathematicsProblemSolvingTagType[] = [];

    const mathematicsProblemSolvingFactory = new MathematicsProblemSolvingFactory(this.input);

    const tag = mathematicsProblemSolvingFactory.createTag();

    const mathematicsProblemSolvingApi = new MathematicsProblemSolvingApi();

    await mathematicsProblemSolvingApi.postTag(tag, this.roomId).then(response => {
      problemSolvingTags = response;
    })

    return problemSolvingTags;
  }

  async post(input: NodeListOf<HTMLInputElement>, students: StudentType[] | undefined, twoMonths: string): Promise<MathematicsProblemSolvingObjType> {
    if (!students || !input || !this.roomId) {
      return {};
    }

    const mathematicsProblemSolvingFactory = new MathematicsProblemSolvingFactory(undefined);

    const mathematicsProblemSolvingObj = mathematicsProblemSolvingFactory.createMathObject(input, students);

    const mathematicsProblemSolvingApi = new MathematicsProblemSolvingApi();

    let problemSolving: MathematicsProblemSolvingObjType = {};

    await mathematicsProblemSolvingApi.post(this.roomId, twoMonths, mathematicsProblemSolvingObj).then(response => {
      problemSolving = response;
    })

    return problemSolving;
  }
}
