/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-floating-promises */
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { type StudentExamsType, type ExamResponseType } from '../../../../class/types/student-exams/student-exams-protocol';

const img = require('./img/header-img.png');

export default function generatePDF(responses: ExamResponseType[], studentExam: StudentExamsType, porcentual: (ac: number, er: number) => string) {
  const pdf = new jsPDF({
    orientation: 'landscape',
  });

  pdf.addImage(img, 'PNG', 55, 5, 180, 0);

  autoTable(pdf, {
    startY: 40,
    headStyles: {
      fillColor: '#118951',
      lineWidth: 0.1,
    },
    head: [
      ['Nome Do Aluno', 'RA', 'Acertos', 'Erros', 'Porcentual', 'Tempo']
    ],
    bodyStyles: {
      fillColor: '#fff',
      lineWidth: 0.2,
    },
    body: (() => {
      const bodyArray: string[][] = [];

      responses.map((response) => {
        bodyArray.push([
          response.studentName,
          response.ra,
          `${response.correctCount?.correct ?? 0}`,
          `${response.correctCount?.errors ?? 0}`,
          porcentual(response.correctCount?.correct ?? 0, response.correctCount?.errors ?? 0),
          response.time ? response.time : '--'
        ])

        return response;
      });

      return bodyArray;
    })()
  });
  pdf.save(`Resultado - ${studentExam.title}.pdf`);
}
