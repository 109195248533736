import displayError from '../services/displayError';
import { type MatterResProtocol } from '../types/Matter/matterApi-protocol';
import { type RoomType } from '../types/Room/Rooms-protocol';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import {
  QuestionElementOptionType,
  type QuestionContentType,
  type StudentExamsType
} from '../types/student-exams/student-exams-protocol';
import StudentExamsApi from './student-exams-api';
import StudentExamsFactory from './student-exams-factory';
import StudentExamsResponse from './student-exams-response';

export default class StudentExamsCostumer {
  private readonly optionsElement = ['title', 'img', 'options', 'text'];

  constructor(
    private readonly title: HTMLInputElement | null,
    private readonly date: HTMLInputElement | null,
    private readonly room: RoomType | undefined,
    private readonly matter: MatterResProtocol | undefined,
  ) {}

  async post(): Promise<StudentExamsType[] | undefined> {
    if (!this.validate() || !this.date || !this.room || !this.matter || !this.title) {
      return;
    }

    const studentExams = StudentExamsFactory.postFactory(this.date.value, this.title.value, this.room, this.matter);

    const studentExamsApi = new StudentExamsApi();

    let newStudentExams: StudentExamsType[] = [];

    await studentExamsApi
      .post(studentExams)
      .then((response) => {
        newStudentExams = response;
      })
      .catch((e) => {
        //
      });

    return newStudentExams;
  }

  async update(studentExamsId: string): Promise<StudentExamsType[] | undefined> {
    if (!this.validate() || !this.date || !this.room || !this.matter || !this.title) {
      return;
    }

    const studentExamsObj = StudentExamsFactory.postFactory(
      this.date.value,
      this.title.value,
      this.room,
      this.matter,
    );

    const studentExamsApi = new StudentExamsApi();

    let newStudentExams: StudentExamsType[] = [];

    await studentExamsApi.update(studentExamsObj, studentExamsId).then((response) => {
      newStudentExams = response;
    });

    return newStudentExams;
  }

  async postQuestionElementTitle(
    studentExamsId: string,
    questionId: string,
    typeIndex: number
  ): Promise<QuestionContentType[]> {
    const studentExamsApi = new StudentExamsApi();

    let questions: QuestionContentType[] = [];

    await studentExamsApi
      .postQuestionElementTitle(studentExamsId, questionId, {
        type: this.optionsElement[typeIndex] as 'title' | 'img' | 'options' | 'text'
      })
      .then((response) => {
        questions = response;
      });
    return questions;
  }

  async putQuestionElementTitle(
    studentExamsId: string,
    questionId: string,
    elementId: string,
    content: string,
    type: 'title' | 'img' | 'options' | 'text',
  ): Promise<QuestionContentType[]> {
    const studentExamsApi = new StudentExamsApi();

    let questions: QuestionContentType[] = [];

    await studentExamsApi
      .putQuestionElementTitle(studentExamsId, questionId, elementId, {
        type,
        content
      })
      .then((response) => {
        questions = response;
      });
    return questions;
  }

  async postQuestionElementImage(studentExamsId: string, questionId: string, file: File) {
    const studentExamsApi = new StudentExamsApi();

    let question: QuestionContentType[] = [];

    await studentExamsApi
      .postQuestionElementImage(studentExamsId, questionId, file)
      .then((response) => {
        question = response;
      });

    return question;
  }

  async postQuestionElementOptions(studentExamsId: string, questionId: string) {
    const studentExamsApi = new StudentExamsApi();

    let question: QuestionContentType[] = [];

    await studentExamsApi
      .postQuestionElementOptions(studentExamsId, questionId, {
        type: 'options'
      })
      .then((response) => {
        question = response;
      });

    return question;
  }

  async postQuestionElementOptionOfOptions(
    studentExamsId: string,
    questionId: string,
    elementId: string
  ): Promise<QuestionContentType[]> {
    const studentExamsApi = new StudentExamsApi();

    let question: QuestionContentType[] = [];

    await studentExamsApi
      .postQuestionElementOptionOfOptions(studentExamsId, questionId, elementId, {
        content: 'Escreva um opção',
        selected: false
      })
      .then((response) => {
        question = response;
      });

    return question;
  }

  async putQuestionElementOptionOfOptions(
    studentExamsId: string,
    questionId: string,
    elementId: string,
    optionId: string,
    text: string
  ): Promise<QuestionContentType[]> {
    const studentExamsApi = new StudentExamsApi();

    let question: QuestionContentType[] = [];

    await studentExamsApi
      .putQuestionElementOptionOfOptions(studentExamsId, questionId, elementId, optionId, {
        content: text
      })
      .then((response) => {
        question = response;
      });

    return question;
  }

  async setQuestionElementOptionSelectedOfOptions(
    studentExamsId: string,
    questionId: string,
    elementId: string,
    optionId: string,
    selected: boolean
  ): Promise<QuestionContentType[]> {
    const studentExamsApi = new StudentExamsApi();

    let question: QuestionContentType[] = [];

    await studentExamsApi.putQuestionElementOptionOfOptions(studentExamsId, questionId, elementId, optionId, {
        selected,
      })
      .then((response) => {
        question = response;
      })
      .catch((e) => {
        //
      });

      return question;
  }

  async postResponseStudent(studentExamsId: string, user: UserClientProtocol & {ra: string}, questions: QuestionContentType[], time: string, inputs: NodeListOf<HTMLInputElement>): Promise<boolean> {
    const examResponse = StudentExamsFactory.createResponse(user, questions, inputs);

    const studentExamsResponse = new StudentExamsResponse();

    let success = false;

    await studentExamsResponse.postStudentExamsResponse(studentExamsId, user.ra, examResponse, time).then(response => {
      success = response
    }).catch(e => {
      //
    });

    return success;
  }

  private validate(): boolean {
    if (!this.date || !this.title) {
      return false;
    }

    if (this.title.value.length === 0 || !this.date.value) {
      displayError(this.date, 'Por favor crie um titulo!');
      return false;
    }

    if (this.date.value.length === 0 || !this.date.value) {
      displayError(this.date, 'Por favor selecione uma data!');
      return false;
    }
    if (!this.room) {
      displayError(this.date, 'Por favor selecione uma sala!');
      return false;
    }

    if (!this.matter && this.matter !== undefined) {
      displayError(this.matter, 'Por favor selecione uma matter!');
      return false;
    }

    return true;
  }
}
