/* eslint-disable @typescript-eslint/quotes */
import React, { useState } from 'react';

import Header from '../../components/Header'
import Nav from '../../components/municipal-education-plan-monitoring-sheet-files/Nav-municipal-education-plan-monitoring-sheet';
import { type MonitoringSheetGoalType } from '../../class/types/municipal-education-plan-monitoring-sheet';
import MunicipalEducationPlanMonitoringSheetGoal from '../../container/municipal-education-plan-monitoring-sheet-goal/municipal-education-plan-monitoring-sheet-goal';

import './style.scss';
import MunicipalEducationPlanMonitoringSheetGoalText from '../../container/municipal-education-plan-monitoring-sheet-goal-text';
import MunicipalEducationPlanMonitoringSheetIndicators from '../../container/municipal-education-plan-monitoring-sheet-indicators/municipal-education-plan-monitoring-sheet-indicators';
import MunicipalEducationPlanMonitoringSheetStrategy from '../../container/municipal-education-plan-monitoring-sheet-strategies/municipal-education-plan-monitoring-sheet-strategies';
import MunicipalEducationPlanMonitoringSheetDashboardIndicator from '../../container/municipal-education-plan-monitoring-sheet-dashboard-indicator/dashboard-indicator';

type Elements = Array<{
  name: string
  boolean: string
}>;

export default function MunicipalEducationPlanMonitoringSheetPage(): React.ReactElement {
  const [goalInfo, setGoalInfo] = useState<MonitoringSheetGoalType | undefined>(undefined);

  const [elements, setElements] = useState([
    {
      name: 'strategy-1',
      boolean: 'false'
    },
    {
      name: 'strategy-2',
      boolean: 'false'
    },
    {
      name: 'strategy-3',
      boolean: 'false'
    },
    {
      name: 'strategy-4',
      boolean: 'false'
    },
    {
      name: 'strategy-5',
      boolean: 'false'
    },
  ]);

  const [display1, setDisplay1] = useState<string>('false');
  const [display2, setDisplay2] = useState<string>('false');
  const [display3, setDisplay3] = useState<string>('false');
  const [display4, setDisplay4] = useState<string>('false');
  const [display5, setDisplay5] = useState<string>('false');

  const huddleSetElements = (elementsParams: Elements) => {
    setElements(elementsParams);
    setDisplay1(elements[0].boolean);
    setDisplay2(elements[1].boolean);
    setDisplay3(elements[2].boolean);
    setDisplay4(elements[3].boolean);
    setDisplay5(elements[4].boolean);
  };

  return (
    <div className='app'>
      <Header />
      <main className="municipal-education-planMonitoring-sheet-page">
        <Nav elements={elements} setElements={huddleSetElements} setGoalInfo={setGoalInfo} />
        <div className='content-container'>
          <div className='content' id={`display-${display1}`}>
            <MunicipalEducationPlanMonitoringSheetGoal display={display1} goalInfo={goalInfo} id='municipal-education-plan-monitoring-sheet-goal' />
          </div>
          <div className='content' id={`display-${display2}`}>
            <MunicipalEducationPlanMonitoringSheetGoalText display={display2} goalInfo={goalInfo} />
          </div>
          <div className='content' id={`display-${display3}`}>
            <MunicipalEducationPlanMonitoringSheetIndicators display={display3} goalInfo={goalInfo} />
          </div>
          <div className='content' id={`display-${display4}`}>
            <MunicipalEducationPlanMonitoringSheetStrategy display={display4} goalInfo={goalInfo} />
          </div>
          <div className='content' id={`display-${display5}`}>
            <MunicipalEducationPlanMonitoringSheetDashboardIndicator display={display5}/>
          </div>
        </div>
      </main>
    </div>
  );
}
