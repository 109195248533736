import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import type NotificationType from '../types/notification';
import { type NotificationViewType } from '../types/notification';

export default new (class NotificationAPI {
  private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

  constructor() {
    try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
  }

  async getAll(): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [];

    await axios.get(this.href + '/get/notification').then(response => {
        newNotifications = response.data as NotificationType[];
    });

    return newNotifications;
  }

  async post(notification: NotificationType): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [];

    await axios.post(this.href + '/post/notification', notification).then(response => {
      newNotifications = response.data as NotificationType[];
    });

    return newNotifications;
  }

  async put(notificationId: string, notification: NotificationType): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [];

    await axios.put(this.href + `/put/notification/${notificationId}`, notification).then(response => {
      newNotifications = response.data as NotificationType[];
    });

    return newNotifications;
  }

  async delete(notificationId: string): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [];

    await axios.delete(this.href + `/delete/notification/${notificationId}`).then(response => {
      newNotifications = response.data;
    });

    return newNotifications;
  }

  async postVisualization(notificationId: string, visualization: NotificationViewType): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [] as NotificationType[];

    await axios.post(this.href + `/post/notification/visualization/${notificationId}`, visualization).then(response => {
      newNotifications = response.data;
    });

    return newNotifications;
  }

  async resendNotification(notificationId: string): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [] as NotificationType[];

    await axios.get(this.href + `/resend/notification/visualization/${notificationId}`).then(response => {
      newNotifications = response.data;
    });

    return newNotifications;
  }

  async postVisualizationAllNotification(notificationId: string[], visualization: NotificationViewType): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [] as NotificationType[];

    await axios.post(this.href + '/post/notification/all/visualization/', {
      notificationId,
      visualization,
    }).then(response => {
      newNotifications = response.data;
    });

    return newNotifications;
  }
})();
