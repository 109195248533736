/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-negated-condition */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { type PsychologistAnamnese } from '../../../class/types/Psychologist';
import exportToPDF from './service/generatePdf';

interface FormAddElementProps {
  displayStudentInfo: boolean
  setDisplayStudentInfo: React.Dispatch<React.SetStateAction<boolean>>
  student: PsychologistAnamnese | undefined
}

export default function StudentInfo({ displayStudentInfo, setDisplayStudentInfo, student }: FormAddElementProps): JSX.Element {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const inputCRPRef = useRef<HTMLInputElement | null>(null);

  const huddleExport = () => {
    if (!inputCRPRef.current) {
      return;
    }

    exportToPDF(inputCRPRef.current?.value, student?.studentName ?? '');
  }

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
    });
  }

  const huddleClose = () => {
    setDisplayStudentInfo(false);
  }

  useEffect(() => {
    setDisplayElement(displayStudentInfo);
  }, [displayStudentInfo]);
  return (
    <div className={`student-info-psychologist display-${displayElement}`}>
      <div className='button-content'>
        <button onClick={huddleExport} className='button-element'>
          Imprimir
        </button>
        <button onClick={huddleClose} className='button-element'>
          Voltar
        </button>
      </div>
      <div className='crp-input-content'>
        <label htmlFor="CRP-element">CRP:</label>
        <input type="text" placeholder='CRP' id='CRP-element' ref={inputCRPRef} />
      </div>
      <div className='container-table-info-psychologist'>
        <div className='table-student-info-psychologist' id='table-student-info-psychologist'>
          <div className='table-header-student-info-psychologist' id='title-table-student-info-psychologist'>
            <h1>
              FICHA DE ANAMNESE
            </h1>
          </div>
          <div className='table-body-student-inf-psychologist'>
            <div className='td-table-student-info-psychologist' id='identification-student-info-psychologist'>
              <div className='div-title-inf-student-psychologist'>
                <h1>I - Identificação</h1>
              </div>
              <ul>
                <li>
                  <h1>Nome do Aluno: <span>{student?.studentName}</span></h1>
                </li>
                <li>
                  <h1>Idade: <span>{student?.age}</span></h1>
                </li>
                <li>
                  <h1>Sexo: <span>{student?.sex}</span></h1>
                </li>
                <li>
                  <h1>Data de nascimento: <span>{formatDate(student?.birthday ?? '')}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='family-date-student-info-psychologist'>
              <div className='div-title-inf-student-psychologist'>
                <h1>II - Dados Familiares</h1>
              </div>
              <ul>
                <li>
                  <h1>Nome da Mãe: <span>{student?.motherName}</span></h1>
                </li>
                <li>
                  <h1>Nome do Pai: <span>{student?.fatherName}</span></h1>
                </li>
                <li>
                  <h1>Numero de irmão (nome/idade/sexo): <br /> <span>{student?.numberOfBrothers.response}</span></h1>
                </li>
                <li>
                  <h1>Situações Civil dos Pais: <br /> <span>{student?.maritalStatus}</span></h1>
                </li>
                <li>
                  <h1>Reação da criança perante a situação: <br /> <span>{student?.numberOfBrothers.reactionOfSon}</span></h1>
                </li>
                <li>
                  <h1>Criança vive com quem: <br /> <span>{student?.theSonLiveWithWho.response}</span></h1>
                </li>
                <li>
                  <h1>Filho: <span>{student?.originOfTheSon}</span></h1>
                </li>
                <li>
                  <h1>A criança e ciente de sua situação: <span>{student?.theChildIsAwareOfTheSituation}</span></h1>
                </li>
                <li>
                  <h1>Observação: Reação da criança perante a situação: <br /> <span>{student?.theSonLiveWithWho.reactionOfSon}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='history-student-info-psychologist'>
              <div className='div-title-inf-student-psychologist'>
                <h1>III - Histórico</h1>
              </div>
              <ul>
                <li>
                  <h1>Gestação: <br /> <span>{student?.gestation}</span></h1>
                </li>
                <li>
                  <h1>Saúde da mãe durante a gravidez: <br /> <span>{student?.healthOfMotherTowardTheGestation}</span></h1>
                </li>
                <li>
                  <h1>Parto: <br /> <span>{student?.childbirth}</span></h1>
                </li>
                <li>
                  <h1>Amamentação: <span>{student?.breastFeeding}</span></h1>
                </li>
                <li>
                  <h1>Apresentou atraso ou problema de fala: <span>{student?.presentedSpeechProblems}</span></h1>
                </li>
                <li>
                  <h1>Dificuldades ou atraso no controle do esfíncter: <span>{student?.difficultiesOrDelaysInSphincterControl}</span></h1>
                </li>
                <li>
                  <h1>Tem enurese noturna: <span>{student?.HaveNocturnalEnuresis}</span></h1>
                </li>
                <li>
                  <h1>Seu desenvolvimento motor foi no tempo esperado?: <span>{student?.hisMotorDevelopmentWasOnTime}</span></h1>
                </li>
                <li>
                  <h1>Pertubações?: <br /><span>{student?.disturbances}</span></h1>
                </li>
                <li>
                  <h1>Possui hábitos especiais?: <br /><span>{student?.hasSpecialHabits}</span></h1>
                </li>
                <li>
                  <h1>Troca de letras, fonema?: <br /> <span>{student?.changeLetters}</span></h1>
                </li>
                <li>
                  <h1>Fatos que afetaram o desenvolvimento do(a) aluno(a) (acidentes, operações, traumas etc.) ou outras ocorrências?: <br /> <span>{student?.factsThatAffectedTheStudentsDevelopment}</span></h1>
                </li>
                <li>
                  <h1>Estado Atual da Criança?: <br /> <span>{student?.currentStatusOfTheChild}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='present-dificult-student-info-psychologist'>
              <div className='div-title-inf-student-psychologist'>
                <h1>IV - Apresenta alguma dificuldade</h1>
              </div>
              <ul>
                <li>
                  <h1>Na visão: <br /><span>{student?.inSight}</span></h1>
                </li>
                <li>
                  <h1>Na fala: <br /><span>{student?.inSpeech}</span></h1>
                </li>
                <li>
                  <h1>Locomoção: <br /><span>{student?.inLocomotion}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='activities-of-life'>
              <div className='div-title-inf-student-psychologist'>
                <h1>V - Atividades de vida diária</h1>
              </div>
              <ul>
                <li>
                  <h1>Toma banho sozinho?: <span>{student?.takeABathAlone}</span></h1>
                </li>
                <li>
                  <h1>Escova os dentes sozinho?: <span>{student?.brushYourTeethAlone}</span></h1>
                </li>
                <li>
                  <h1>Usa o banheiro sozinho: <span>{student?.useTheBathroomAlone}</span></h1>
                </li>
                <li>
                  <h1>Necessita de auxilio para se vestir ou despir: <span>{student?.needAssistanceWithDressingOrUndressing}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='at-what-age-were-diapers-removed-td-table-student-info'>
              <div className='div-title-inf-student-psychologist'>
                <h1>VI - Em que idade se deu a retirada das fraldas</h1>
              </div>
              <ul>
                <li>
                  <h1>
                    <span>
                      {student?.atWhatAgeWereDiapersRemoved}
                    </span>
                  </h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='own-tendencies-student-inf-psychologist'>
              <div className='div-title-inf-student-psychologist'>
                <h1>VII - Tendências Próprias</h1>
              </div>
              <ul>
                <li>
                  <h1>Atende as intervenções quando está desobedecendo?<br /><span>{student?.respondToInterventionsWhenDisobeying}</span></h1>
                </li>
                <li>
                  <h1>Apresenta choro fácil?<br /><span>{student?.presentsEasyCrying}</span></h1>
                </li>
                <li>
                  <h1>Recusa auxílio? <br /><span>{student?.refusesAid}</span></h1>
                </li>
                <li>
                  <h1>Tem resistência ao toque (afago, carinho)?<br /><span>{student?.hasTouchResistance}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='education-student-info-psychologist'>
              <div className='div-title-inf-student-psychologist'>
                <h1>VIII - Escolaridade</h1>
              </div>
              <ul>
                <li>
                  <h1>A criança já estudou antes em outra escola? Qual?<br /><span>{student?.theChildHasPreviouslyStudiedAtAnotherSchool}</span></h1>
                </li>
                <li>
                  <h1>Motivo da transferência:<br /><span>{student?.reasonForTransfer}</span></h1>
                </li>
                <li>
                  <h1>Já repetiu alguma série? Se sim, qual?:<br /><span>{student?.youRepeatedASeries}</span></h1>
                </li>
                <li>
                  <h1>A criança recebe algum tipo de orientação quanto aos deveres de casa? Quem oferece? Durante quanto tempo?<br /><span>{student?.theStudentReceivesHelpWithHomework}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='take-part-in-some-of-the-activities-below'>
              <div className='div-title-inf-student-psychologist'>
                <h1>IX - Participa de algumas das atividades abaixo</h1>
              </div>
              <ul>
                <li>
                  <h1>Curso  de língua  estrangeira.  Qual(is)?<br /><span>{student?.foreignLanguageCourse}</span></h1>
                </li>
                <li>
                  <h1>Modalidades  esportivas. Qual(is)? <br /><span>{student?.sport}</span></h1>
                </li>
                <li>
                  <h1>Dança.  Qual(is)?<br /><span>{student?.dance}</span></h1>
                </li>
                <li>
                  <h1>Instrumento  musical.  Qual(is)?<br /><span>{student?.musicalInstrument}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='sociability-student-info-psychologist'>
              <div className='div-title-inf-student-psychologist'>
                <h1>X - Sociabilidade</h1>
              </div>
              <ul>
                <li>
                  <h1>Faz amigos com facilidade?<br /><span>{student?.makesFriendsEasily}</span></h1>
                </li>
                <li>
                  <h1>Adapta-se facilmente ao meio?<br /><span>{student?.easilyAdaptsToTheEnvironment}</span></h1>
                </li>
                <li>
                  <h1>Quem são os companheiros da criança nas brincadeiras?<br /><span>{student?.whoAreTheChildsPlaymates}</span></h1>
                </li>
                <li>
                  <h1>Escolha de grupo<br /><span>{student?.chooseTheGroup}</span></h1>
                </li>
                <li>
                  <h1>Distrações preferidas: <br /><span>{student?.favoriteDistractions}</span></h1>
                </li>
                <li>
                  <h1>Atitudes sociais predominantes:<br /><span>{student?.prevailingSocialAttitudes}</span></h1>
                </li>
                <li>
                  <h1>Emocional: <br /><span>{student?.emotional}</span></h1>
                </li>
              </ul>
            </div>
            <div className='td-table-student-info-psychologist' id='observation-student-info-psychologist'>
              <div className='div-title-inf-student-psychologist'>
                <h1>Observação Final</h1>
              </div>
              <ul>
                <li>
                  <h1><span>{student?.finalObservations}</span></h1>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
