/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState, useEffect } from 'react';
import type { MatterResProtocol } from '../../../class/types/Matter/matterApi-protocol';
import ContentTableMatter from '../../../components/matter-files/ContentTable-matter';
import EmptyComponent from '../../../components/Empty-component';
import FormAddElement from '../FormAddElement-matter';
import { useParams } from 'react-router-dom';
import MatterAPI from '../../../class/Matter/MatterAPI';
import lscache from 'lscache';
import config from '../../../config/config';
import './style.scss';
import FormEditElement from '../FormEditElement-matter';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEdit, setDisplayEdit] = useState<boolean>(false);
  const [matter, setMatter] = useState<MatterResProtocol[]>([]);
  const [matterSelected, setMatterSelected] = useState<MatterResProtocol | undefined>();
  const { id } = useParams();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    lscache.setExpiryMilliseconds(60000);
    MatterAPI.get(id).then(response => {
      setMatter(response);
      lscache.set('matters', response, config.processTemp);
    });
  }, []);

  return (
    <div className='table-container-matter'>
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setMatter={setMatter}
      />
      <FormEditElement
        displayEditElement={displayEdit}
        matterSelected={matterSelected}
        setDisplayEditElement={setDisplayEdit}
        setMatter={setMatter}
      />
      <div className='btn-create-new-matter-container'>
        <button onClick={hundleCreateNewElement}>adicionar um nova materia</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Matéria</li>
          <li className='header-table-ul-li'>Prof responsável</li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            matter?.length === 0
              ? <EmptyComponent>click em (adicionar uma nova materia) para adicionar uma nova materia </EmptyComponent>
              : matter?.map((matter: any, index: number) => (
                <ContentTableMatter
                  setMatterSelected={setMatterSelected}
                  setDisplayMatterEdit={setDisplayEdit}
                  displayMatter={displayEdit}
                  index={index}
                  matter={matter}
                  setMatterCustumer={setMatter}
                  key={String(matter.id)}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
