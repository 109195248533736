/* eslint-disable @typescript-eslint/no-unsafe-call */
import ActionFactory from './Action-factory';
import ActionAPI from './ActionAPI';
import type {ActionResponseProtocol} from '../types/Action/ActionAPI-protocol';
import type CostumerProtocol from '../types/custumer-protocol';
import Validator from '../Validator';

class ActionCustumer implements CostumerProtocol<ActionResponseProtocol> {
	private readonly actionApi = ActionAPI;

	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly id: string,
	) {}

	async post(): Promise<any> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return [{
				error: true,
			}];
		}

		let actions: ActionResponseProtocol[] = [];
		const actionFactory = new ActionFactory(this.inputs, this.id);

		const action = actionFactory.create();

		await this.actionApi.post(action).then(response => {
			actions = response;
		});

		return actions;
	}

	async put(id: string): Promise<ActionResponseProtocol[]> {
		let actions: ActionResponseProtocol[] = [];

		const strategyFactory = new ActionFactory(this.inputs, this.id);

		const actionPut = strategyFactory.createPut();

		await this.actionApi.put(actionPut, id).then((response: any[]) => {
			actions = response as ActionResponseProtocol[];
		});

		return actions;
	}
}

export default ActionCustumer;
