
import Validator from '../Validator';
import { type ElementarySchoolDecentWorkType } from '../types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import UploadDecentWorkApi from './upload-decent-work-api';
import lscache from 'lscache';

class UpLoadDecentWorkCostumer {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement> | undefined = undefined,
		private readonly decentWorkId = '',
		private readonly textArea: HTMLTextAreaElement | undefined = undefined
	) {}

	get _user(): UserClientProtocol {
		const user = lscache.get('user') as UserClientProtocol;

		return user;
	}

	async post(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: true
			};
		}

		const file = this.inputs.item(2).files?.item(0);

		if (!file) {
			return {
				error: true
			};
		}

		const fileName = file.name ?? '';
		const date = this.inputs.item(1).value;
        const classRoom = this.inputs.item(0).value;

		const user = this._user;

		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		await UploadDecentWorkApi.post({
            file,
            classRoom,
			date,
			name: fileName,
			userId: user.id ?? '',
			userName: user.userName
		}).then(response => {
			newElementarySchoolDecentWork = response;
		}).catch(e => {
			//
		});

		return newElementarySchoolDecentWork;
	}

	async postDescription(): Promise<any> {
		if (!this.textArea) {
			return {
				error: true
			};
		}

		if (!this.textArea?.value) {
			Validator.displayError(this.textArea, 'o campo esta vazio');
			return {
				error: true
			};
		}

		const description = this.textArea.value;
		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		await UploadDecentWorkApi.postDescription(this.decentWorkId, description).then(response => {
			newElementarySchoolDecentWork = response;
		});

		return newElementarySchoolDecentWork;
	}

	async put(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: false
			};
		}

		const file = this.inputs.item(0).files?.item(0);

		if (!file) {
			return {
				error: false
			};
		}

		const fileName = file.name ?? '';

		let newElementarySchoolDecentWork: ElementarySchoolDecentWorkType[] = [];

		await UploadDecentWorkApi.put({
			file,
			name: fileName,
			decentWorkId: this.decentWorkId
		}).then(response => {
			newElementarySchoolDecentWork = response;
		});

		return newElementarySchoolDecentWork;
	}
}

export default UpLoadDecentWorkCostumer;
