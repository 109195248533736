/* eslint-disable no-mixed-spaces-and-tabs */

import React from 'react';

import { type UploadRoomObserverSheetType } from '../../../class/types/Upload_room-observer-sheet-api';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import DisplayDocument from '../../../components/displayDocument';
import formatFileName from '../../../services/formatFileName';

interface FormEditUploadRoomObserverSheetProps {
  setDisplayUploadRoomObserverSheetInfo: React.Dispatch<React.SetStateAction<boolean>>
  displayUploadRoomObserverSheetInfo: boolean
  element: UploadRoomObserverSheetType
}

export default function DecentWorkDisplayInformation({
  setDisplayUploadRoomObserverSheetInfo,
  displayUploadRoomObserverSheetInfo,
  element
}: FormEditUploadRoomObserverSheetProps): JSX.Element {
  // const formatDate = (date: string) => {
  //   const newDate = new Date(date);

  //   return newDate.toLocaleDateString('pt-BR', {
  //     timeZone: 'UTC',
  //     dateStyle: 'short'
  //   });
  // };

  return (
		<div className={`form-describe-upload-room-observer-sheet display-${String(displayUploadRoomObserverSheetInfo)}`}>
			<div className='header-form-describe-element-content'>
				<h1>
                        Ficha de Observação de Sala
				</h1>
				<button onClickCapture={() => {
				    setDisplayUploadRoomObserverSheetInfo(false);
				}}>Voltar</button>
			</div>
			<div className='main-monthly-plan-display-information' id='main-monthly-plan-display-information'>
				<div className="decent-work-information">
					<h1>Nome do arquivo: <span>{formatFileName(element.fileName)}</span></h1>
				</div>
				{/* <div className="decent-work-information">
					<h1>Status: <span>{element.checked ? 'Visualizado' : 'Não visualizado'}</span></h1>
				</div> */}
				<div className="decent-work-information">
					<h1>Emissor: <span>{element.issuer?.userName}</span></h1>
				</div>
				<div className="decent-work-information">
					<h1>Data de emissão: <span>{element.issuer?.emissionDate}</span></h1>
				</div>
				{/* <div className='description-content'>
                    <h1>Comentário</h1>
					<p>
						{element.description?.content}
					</p>
				</div> */}
        <div className='adm-hours-information-frame-content'>
          <DisplayDocument type={element.type} url={element.location.url} />
        </div>
			</div>
		</div>
  );
}
