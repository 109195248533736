/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-floating-promises */
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const img = require('./img/header-img.png');

export default function generatePDF(roomName: string, twoMonthsNameTwo: string) {
  const pdf = new jsPDF({
    orientation: 'portrait',
  });

  const element = document.getElementById('mathematic-diagnostic-dashboard-comparative') as HTMLDivElement;

  html2canvas(element).then(canvas => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(img, 'PNG', 15, 5, 180, 0);

    pdf.addImage(imgData, 'PNG', 15, 100, 200, 0);

    pdf.save(`Gráfico Diagnostico de Matemática ${roomName} - ${twoMonthsNameTwo}.pdf`);
  });
}
