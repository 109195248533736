import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type {UserProtocol} from '../types/User/User-factory-protocol';
import type ElementarySchoolMonthlyPlanProtocol from '../types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';
import {type KindergartenSchoolMonthlyPlanType} from '../types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';

class KindergartenSchoolMonthlyPlan implements ElementarySchoolMonthlyPlanProtocol {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async get(): Promise<KindergartenSchoolMonthlyPlanType[]> {
		let newElementarySchoolMonthlyPlan: KindergartenSchoolMonthlyPlanType[] = [];
		if (this.href) {
			await axios.get(`${this.href}/kindergartenschool/monthlyplan`).then(response => {
				newElementarySchoolMonthlyPlan = response.data as KindergartenSchoolMonthlyPlanType[];
			});
		}

		return newElementarySchoolMonthlyPlan;
	}

	async post(elementarySchoolMonthlyPlan: KindergartenSchoolMonthlyPlanType): Promise<KindergartenSchoolMonthlyPlanType[]> {
		let newElementarySchoolMonthlyPlan: KindergartenSchoolMonthlyPlanType[] = [];

		if (this.href) {
			await axios.post(`${this.href}/kindergartenschool/monthlyplan`, elementarySchoolMonthlyPlan).then(response => {
				newElementarySchoolMonthlyPlan = response.data as KindergartenSchoolMonthlyPlanType[];
			});
		}

		return newElementarySchoolMonthlyPlan;
	}

	async put(elementarySchoolMonthlyPlan: any, id: string): Promise<KindergartenSchoolMonthlyPlanType[]> {
		let newElementarySchoolMonthlyPlan: KindergartenSchoolMonthlyPlanType[] = [];

		if (this.href) {
			await axios.put(`${this.href}/kindergartenschool/monthlyplan/${id}`, elementarySchoolMonthlyPlan).then(response => {
				newElementarySchoolMonthlyPlan = response.data as KindergartenSchoolMonthlyPlanType[];
			});
		}

		return newElementarySchoolMonthlyPlan;
	}

	async delete(elementarySchoolMonthlyPlanId: string): Promise<KindergartenSchoolMonthlyPlanType[]> {
		let newElementarySchoolMonthlyPlan: KindergartenSchoolMonthlyPlanType[] = [];
		if (this.href) {
			await axios.delete(`${this.href}/kindergartenschool/monthlyplan/${elementarySchoolMonthlyPlanId}`).then(response => {
				newElementarySchoolMonthlyPlan = response.data as KindergartenSchoolMonthlyPlanType[];
			});
		}

		return newElementarySchoolMonthlyPlan;
	}
}

export default new KindergartenSchoolMonthlyPlan();
