/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-floating-promises */
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const img = require('./img/header-img.png');

const assinaturaCampo = require('./img/assinatura_campo.png');

export default function generatePDF(roomName: string, twoMonths: string, studentName: string) {
  const pdf = new jsPDF({
    orientation: 'landscape'
  });

  const element = document.getElementById('individual-card-report-table') as HTMLDivElement;

  html2canvas(element).then(canvas => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(img, 'PNG', 35, 5, 225, 0);

    if (twoMonths === 'Geral') {
      pdf.addImage(imgData, 'PNG', 15, 45, 270, 0);
    } else {
      pdf.addImage(imgData, 'PNG', 30, 45, 230, 0);
    }

    pdf.save(`boletim ${roomName} ${twoMonths === 'Geral' ? '' : twoMonths} ${studentName}.pdf`);
  });
}
