/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
// import uploadElementarySchoolATAApi from '../../class/UploadElementarySchoolStudentReport/upload-student-report-api';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import { MdDelete } from 'react-icons/md';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DisplayFileInfoTree } from '../DisplayFile';
import RuleVerification from '../rule-verification';
import LoadContent from '../Load-content';
import { FiEdit2 } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';
import UserAPI from '../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import { HiCheck } from 'react-icons/hi';
import { type StudentReportType } from '../../class/types/StudentReport';
import uploadFileKindergartenSchoolATAApi from '../../class/UploadKindergartenSchoolATA/upload-ATA-api';
import { IoMdDownload } from 'react-icons/io';

interface ContentTableKindergartenATAType {
  index: number
  kindergartenSchoolSchoolATA: StudentReportType
  setKindergartenSchoolATA: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayChecking: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayATA: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setElement: React.Dispatch<React.SetStateAction<StudentReportType>>
  type: 'elementary-school' | 'kindergartenSchool'
}
export default function ContentTableUploadFileATA({
  setKindergartenSchoolATA,
  kindergartenSchoolSchoolATA,
  setDisplayEditElement,
  setDisplayChecking,
  setDisplayATA,
  setElementId,
  setElement,
  index,
  type
}: ContentTableKindergartenATAType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const huddleDelete = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      if (type === 'kindergartenSchool') {
        uploadFileKindergartenSchoolATAApi.delete(String(kindergartenSchoolSchoolATA.id), kindergartenSchoolSchoolATA.location.fileName).then(response => {
          if (permission === 'professor') {
            const decentWork = response.filter((value: any) => value.issuer?.userId === user.id)
            setKindergartenSchoolATA(decentWork);
            setIsLoading(false);

            return;
          }

          setKindergartenSchoolATA(response);
          setIsLoading(false);
        }).catch((e) => {
          //
        });
      }
      // uploadElementarySchoolATAApi.delete(String(kindergartenSchoolSchoolATA.id), kindergartenSchoolSchoolATA.location.fileName).then(response => {
      //   if (permission === 'professor') {
      //     const decentWork = response.filter((value: any) => value.issuer?.userId === user.id)
      //     setKindergartenSchoolATA(decentWork);
      //     setIsLoading(false);

      //     return;
      //   }

      //   setKindergartenSchoolATA(response);
      //   setIsLoading(false);
      // }).catch((e) => {
      //   //
      // });
    }).catch(e => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(kindergartenSchoolSchoolATA.id));
    setDisplayEditElement(true);
  };

  const huddleSetChecking = () => {
    setElementId(String(kindergartenSchoolSchoolATA.id));

    setDisplayChecking(true);
  }

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(kindergartenSchoolSchoolATA);
    setDisplayATA(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 15) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [kindergartenSchoolSchoolATA]);

  return (
    <div className={`content-table-element-student-report display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={kindergartenSchoolSchoolATA.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(kindergartenSchoolSchoolATA.issuer?.userName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {kindergartenSchoolSchoolATA.classRoom}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(kindergartenSchoolSchoolATA.date ?? '')}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {kindergartenSchoolSchoolATA.twoMonths ?? ''}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
          {kindergartenSchoolSchoolATA.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
        <li>
          <a href={kindergartenSchoolSchoolATA.location ? kindergartenSchoolSchoolATA.location.url : ''} target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <HiCheck size='20' onClickCapture={huddleSetChecking} className='icon-hicheck-element' />
          </li>
        </RuleVerification>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
      </ul>
    </div>
  );
}
