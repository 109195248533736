export function subTime(afterTime: string, beforeTime: string) {
  const after = {
    hors: Number(afterTime.split(':')[0]),
    minutes: Number(afterTime.split(':')[1]),
  }
  const before = {
    hors: Number(beforeTime.split(':')[0]),
    minutes: Number(beforeTime.split(':')[1]),
  }

  const totalMinutesOne = after.hors * 60 + after.minutes;
  const totalMinutesTwo = before.hors * 60 + before.minutes;

  const diferencaMinutes = totalMinutesTwo - totalMinutesOne;

  const hors = Math.floor(diferencaMinutes / 60);
  const minutes = diferencaMinutes % 60

  return `${hors < 10 ? `0${hors}` : hors}:${minutes < 10 ? `0${minutes}` : minutes}`;
};
