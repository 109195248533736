
import type StrategyCustumerProtocol from '../types/custumer-protocol';
import Validator from '../Validator';
import type { MatterResProtocol } from '../types/Matter/matterApi-protocol';
import MatterFactory from './Matter-factory';
import MatterAPI from './MatterAPI';

class MatterCustumer implements StrategyCustumerProtocol<MatterResProtocol> {
	private readonly matterApi = MatterAPI;

	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly teacherId: string,
		private readonly roomId: string,
    private readonly matterSelectId: string | undefined,
	) {}

	async post(): Promise<any[]> {
		const validation = new Validator([this.inputs[0], this.inputs[1]]);

		if (validation.init()) {
			return [{
				error: true
			}];
		}

		let matters: MatterResProtocol[] = [];
		const matterFactory = new MatterFactory(this.inputs, this.teacherId, this.matterSelectId ?? '');

		const matter = matterFactory.create();

		await this.matterApi.post(this.roomId, matter).then((response: MatterResProtocol[]) => {
			matters = response;
		});

		return matters;
	}

	async put(matterId: string): Promise<any[]> {
		let matters: MatterResProtocol[] = [];
		const matterFactory = new MatterFactory(this.inputs, this.teacherId, this.matterSelectId ?? '');

		const matter = matterFactory.create();

		await this.matterApi.put(this.roomId, matterId, matter).then((response: MatterResProtocol[]) => {
			matters = response;
		});

		return matters;
	}
}

export default MatterCustumer;
