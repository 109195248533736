/* eslint-disable no-multi-spaces */

import React, { useState } from 'react';
import './style.scss';
import Header from '../../components/Header';
import Nav from '../../components/psychologist-files/Nav-psychologist';
import PsychologistStudentRegister from '../../container/Psychologist-student-register';
import PsychologistMonitoring from '../../container/Psychologist-monitoring';
import PsychologistReport from '../../container/psychologist-report/psychologist-report';

type Elements = Array<{
  name: string
  boolean: string
}>;

function Psychologist() {
  const [elements, setElements] = useState([
    {
      name: 'strategy-1',
      boolean: 'false'
    },
    {
      name: 'strategy-2',
      boolean: 'false'
    },
    {
      name: 'strategy-3',
      boolean: 'false'
    },
    {
      name: 'strategy-4',
      boolean: 'true'
    },
    {
      name: 'strategy-5',
      boolean: 'false'
    }
  ]);

  const [display1, setDisplay1] = useState<string>('false');
  const [display2, setDisplay2] = useState<string>('false');
  const [display3, setDisplay3] = useState<string>('false');
  const [display4, setDisplay4] = useState<string>('true');
  const [display5, setDisplay5] = useState<string>('false');

  const [action, setAction] = useState<any>();

  const hundlesetElements = (elementsParams: Elements) => {
    setElements(elementsParams);
    setDisplay1(elements[0].boolean);
    setDisplay2(elements[1].boolean);
    setDisplay3(elements[2].boolean);
    setDisplay4(elements[3].boolean);
    setDisplay5(elements[4].boolean);
  };

  return (
		<div className='App'>
			<Header />
			<main className='main-container-plans'>
				<Nav setElements={hundlesetElements} elements={elements} />
				<div className='content-containers'>
					<div className='content' id={`display-${display1}`}>
						<PsychologistStudentRegister display={display1} />
					</div>
					<div className='content' id={`display-${display2}`}>
						<PsychologistMonitoring display={display2} />
					</div>
          <div className='content' id={`display-${display5}`}>
            <PsychologistReport display={display5} id='psychologist-report' />
          </div>
          <div className='content default-background' id={`display-${display4}`}>
            <div className='default-background-title'>
              <h1>Gerenciamento de Consultas</h1>
            </div>
            <img src={require('./img/undraw_search_app_oso2.png')} alt="" />
          </div>
				</div>
			</main>
		</div>
  );
}

export default Psychologist;
