/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { PiDropboxLogoFill } from 'react-icons/pi';
import { GoPlus } from 'react-icons/go';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { FaGear } from 'react-icons/fa6'
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import { useParams } from 'react-router-dom';
import { type StudentExamsType, type ExamResponseType, type QuestionContentType } from '../../../class/types/student-exams/student-exams-protocol';
import StudentExamQuestionContent from '../student-exam-question-content';
import ConfigStudentExam from '../config-student-exam';
import StudentExamsResponse from '../student-exams-response';
import lscache from 'lscache';

export default function StudentExamMain(): React.ReactElement {
  const { id } = useParams();

  const [questions, setQuestions] = useState<QuestionContentType[]>([]);

  const [displayOption, setDisplayOption] = useState<boolean>(false);
  const [displayResponse, setDisplayResponse] = useState<boolean>(false);
  const [examResponse, setExamResponse] = useState<ExamResponseType[]>([]);
  const [studentExam, setStudentExam] = useState<StudentExamsType | undefined>(undefined);

  const huddleCreateQuestion = () => {
    const studentExams = new StudentExamsApi();

    if (!id) {
      return;
    }

    studentExams.createQuestion(id).then(response => {
      setQuestions(response);
    }).catch(e => {
      //
    });
  }

  const huddleDisplayOption = () => {
    setDisplayOption(!displayOption);
  }

  const huddleDisplayResponse = () => {
    setDisplayResponse(!displayResponse);
  }

  useEffect(() => {
    const user = lscache.get('user');

    if (!id) {
      return;
    }

    const studentExamsApi = new StudentExamsApi();

    studentExamsApi.getAllQuestion(id).then(response => {
      setQuestions(response);
    }).catch(e => {
      //
    });

    studentExamsApi.getExamResponse(id).then(response => {
      setExamResponse(response);
    }).catch(e => {
      //
    });

    studentExamsApi.getExamByIdForCoordinator(id).then(response => {
      setStudentExam(response);
    }).catch(e => {
      //
    });
  }, []);

  return (
    <div className='student-exam-main'>
      <ConfigStudentExam huddleDisplay={huddleDisplayOption} display={displayOption} studentExam={studentExam} setStudentExam={setStudentExam} />
      <StudentExamsResponse studentExam={studentExam} responses={examResponse} display={displayResponse} setResponse={setExamResponse} huddleDisplay={huddleDisplayResponse} />
      <div className='student-exam-main-header'>
        <div className='response-button-content'>
          <button onClickCapture={huddleDisplayResponse}>
            Resposta
            <PiDropboxLogoFill className='icon-content' />
            <span>
              {examResponse.length}
            </span>
          </button>
        </div>
        <div className='button-content'>
          <button className='back-button'>
            <RiArrowGoBackFill size={30} onClick={() => {
              window.location.href = '/';
            }} />
          </button>
          <button className='gear-button'>
            <FaGear className='gear-icon' onClickCapture={huddleDisplayOption} size={30} />
          </button>
        </div>
      </div>
      <div className='student-exam-main-title'>
        <h1>{studentExam?.title}</h1>
      </div>
      <div className='settings-bar-right'>
        <button onClick={huddleCreateQuestion}>
          <GoPlus size={40} />
        </button>
      </div>
      <div className='student-exam-main-question-content'>
        {questions.length > 0
          ? questions.map(question => {
            return <StudentExamQuestionContent setQuestion={setQuestions} question={question} />
          })
          : (
            <div className='default-img-content'>
              <img src={require('./img/undraw_Work_together_re_5yhn.png')} alt="" />
            </div>
          )}
      </div>
    </div>
  )
}
