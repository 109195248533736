/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useRef, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5'
import './styles.scss';
import InputSelect from '../../../components/input-select';
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import { type QuestionContentType } from '../../../class/types/student-exams/student-exams-protocol';
import { useParams } from 'react-router-dom';
import StudentExamsCostumer from '../../../class/Student-Exams/student-exams-costumer';
import { BsFillImageFill } from 'react-icons/bs';
import LoadContent from '../../../components/Load-content';

interface StudentExamsFormAddElementProps {
  questions: QuestionContentType
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  setQuestion: React.Dispatch<React.SetStateAction<QuestionContentType[]>>
}

export default function StudentExamFormAddElement({ display, questions, setDisplay, setQuestion }: StudentExamsFormAddElementProps): React.ReactElement {
  const [optionIndex, setOptionIndex] = useState<number>(0);
  const [displayInputImage, setDisplayInputImage] = useState<boolean>(false);
  const [displayImage, setDisplayImage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>();
  const { id } = useParams();

  const imgRef = useRef<HTMLImageElement | null>(null);

  const options = ['Titulo', 'Imagem', 'Opções', 'Texto']

  const huddleDisplay = () => {
    setDisplay(false);
  }

  const huddleCancel = () => {
    setDisplay(false);
    setFile(undefined);
    setDisplayImage(false);

    if (!imgRef.current) {
      return;
    }

    imgRef.current.src = '';
  }

  const huddleSelectOption = (option: string) => {
    setDisplayInputImage(false);

    if (option === 'Imagem') {
      setDisplayInputImage(true);
    }

    setOptionIndex(options.indexOf(option));
  }

  const huddleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!id || !questions.id) {
      return;
    }

    const studentExamsCostumer = new StudentExamsCostumer(null, null, undefined, undefined);

    setIsLoading(true)
    if (optionIndex === 1) {
      if (!file) {
        return;
      }

      studentExamsCostumer.postQuestionElementImage(id, questions.id, file).then(response => {
        setQuestion(response);
        setDisplay(false);
        setIsLoading(false);
        setDisplayImage(false);

        if (!imgRef.current) {
          return;
        }

        imgRef.current.src = '';
      }).catch(e => {
        //
      });

      return;
    }

    if (optionIndex === 2) {
      studentExamsCostumer.postQuestionElementOptions(id, questions.id).then(response => {
        setQuestion(response);
        setDisplay(false);
        setIsLoading(false);
      }).catch(e => {
        //
      });

      return;
    }

    if (optionIndex === 3) {
      studentExamsCostumer.postQuestionElementTitle(id, questions.id, optionIndex).then(response => {
        setQuestion(response);
        setDisplay(false);
        setIsLoading(false);
      }).catch(e => {
        //
      });

      return;
    }

    studentExamsCostumer.postQuestionElementTitle(id, questions.id, optionIndex).then(response => {
      setQuestion(response);
      setDisplay(false);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  }

  const huddleInputImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    const loader = new FileReader()

    if (!target.files || !target.files[0]) {
      return;
    }

    loader.onload = (e) => {
      if (!e.target || !imgRef.current || !imgRef) {
        return;
      }

      setDisplayImage(true);
      imgRef.current.src = e.target.result as string;
    }

    setFile(target.files[0]);
    loader.readAsDataURL(target.files[0]);
  }

  return (
    <div className={`student-exams-form-add-element display-${String(display)}`}>
      <div className='student-exams-form-add-element-content'>
        <div className='student-exams-form-add-element-header'>
          <h1>Adicionar Elemento</h1>
          <IoCloseOutline onClickCapture={huddleDisplay} size={25} />
        </div>
        <form className='form-content' onSubmit={huddleSubmit}>
          <div className='input-content'>
            <InputSelect
              title='Opções de Elemento'
              options={options}
              defaultValue='Escolha um opção'
              cb={huddleSelectOption}
            />
            <div className={`input-element img-input display-${String(displayInputImage)}`}>
              <label htmlFor={`${questions.id ?? ''}`}>
                {displayImage ? <></> : <BsFillImageFill size={100} />}
                <img src="" alt="" ref={imgRef} />
              </label>
              <input type="file" id={`${questions.id ?? ''}`} accept="image/*" onChangeCapture={huddleInputImage} />
            </div>
          </div>
          <div className='button-content'>
            <button type='button' className='cancel-button' onClick={huddleCancel}>
              Cancelar
            </button>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={
                    {
                      container: {
                        width: 25,
                        height: 25,
                      }
                    }
                  }
                />
              )
              : (
                <button type='submit' className=''>
                  Salvar
                </button>
              )}
          </div>
        </form>
      </div>
    </div>
  )
}
