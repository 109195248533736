/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import LoadContent from '../../../components/Load-content';
import uploadAdmLegislationApi from '../../../class/UploadAdmLegislation/upload-adm-legislation-api';
import ContentTableAdmLegislation from '../../../components/admLegislation';
import type FileAdmLegislationType from '../../../class/types/AdmLegislation';
import FormAddAdmLegislation from '../form-add-legislation';
import LegislationDisplayInformation from '../legislation-display-information';
import FormEditAdmLegislation from '../form-edit-legislation';
import FilterAdmLegislation from '../service/filterAdmLegislation';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [admLegislation, setAdmLegislation] = useState<FileAdmLegislationType[]>([]);
  const [displayFormAddAdmLegislation, setDisplayAdmLegislation] = useState(false);
  const [displayAdmLegislationInfo, setDisplayAdmLegislationInfo] = useState(false);
  const [displayFormEditAdmLegislation, setDisplayEditAdmLegislation] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [elementFiltered, setElementFiltered] = useState<FileAdmLegislationType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainElement = useRef<any>();

  const [element, setElement] = useState<FileAdmLegislationType>(elementDefault);

  const huddleCreateNewElement = () => {
    setDisplayAdmLegislation(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const elementsFiltered = FilterAdmLegislation(admLegislation, inputs);

    setElementFiltered(elementsFiltered);
  };
  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    uploadAdmLegislationApi.get().then((response) => {
      setAdmLegislation(response);
      setElementFiltered(response);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  }, [display]);

  useEffect(() => {
    setElementFiltered(admLegislation);
  }, [admLegislation])

  return (
    <div className='table-container-adm-legislation'>
      <h1 className='title-table'>Legislação</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddAdmLegislation
        displayFormAddAdmLegislation={displayFormAddAdmLegislation}
        setDisplayFormAddAdmLegislation={setDisplayAdmLegislation}
        setAdmLegislation={setAdmLegislation}
      />
      <FormEditAdmLegislation
        displayFormEditAdmLegislation={displayFormEditAdmLegislation}
        setDisplayFormEditAdmLegislation={setDisplayEditAdmLegislation}
        setAdmLegislation={setAdmLegislation}
        setDisplayEditElement={setDisplayEditAdmLegislation}
        elementId={elementId}
      />
      <LegislationDisplayInformation
        displayAdmLegislationInfo={displayAdmLegislationInfo}
        setDisplayAdmLegislationInfo={setDisplayAdmLegislationInfo}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar Documento</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome do documento' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='emissor' />
        </div>
        <div className='search-input-content input-content input-date-content-one input-data'>
          <input type='date' />
        </div>
        <h2>A</h2>
        <div className='search-input-content input-content input-date-content-two input-data'>
          <input type='date' />
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Nome do documento</li>
          <li className='header-table-ul-li'>Emissor</li>
          <li className='header-table-ul-li'>Data</li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '8rem',
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar documento) para adicionar um novo documento</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork, index: number) => (
                    <ContentTableAdmLegislation
                      index={index}
                      elementarySchoolStudentReport={elementarySchoolDecentWork}
                      setDisplayStudentReport={setDisplayAdmLegislationInfo}
                      setDisplayEditElement={setDisplayEditAdmLegislation}
                      setElementId={setElementId}
                      setElement={setElement}
                      setElementarySchoolStudentReport={setAdmLegislation}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}
