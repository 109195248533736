
import type CostumerProtocol from '../types/custumer-protocol';
import Validator from '../Validator';
import StrategyOfPlanFactory from './StrategyOfPlan-factory';
import StrategyOfPlanAPI from './StrategyOfPlanAPI';
import type { StrategyOfPlanProtocol } from '../types/Strategy-of-plan/Strategy-of-plan-factory-protocol';

class StrategyOfPlanCostumer implements CostumerProtocol<StrategyOfPlanProtocol> {
	private readonly strategyOfPlanApi = StrategyOfPlanAPI;

	constructor(
		private readonly inputs: any[] | NodeListOf<HTMLInputElement>,
		private readonly id: string
	) {}

	async post(): Promise<any> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return [{
				error: true
			}];
		}

		let actions: StrategyOfPlanProtocol[] = [];
		const strategyOfPlanFactory = new StrategyOfPlanFactory(this.inputs, this.id);

		const strategy = strategyOfPlanFactory.create();

		await this.strategyOfPlanApi.post(strategy).then(response => {
			actions = response;
		});

		return actions;
	}

	async put(id: string): Promise<StrategyOfPlanProtocol[]> {
		let strategies: StrategyOfPlanProtocol[] = [];

		const strategyOfPlanFactory = new StrategyOfPlanFactory(this.inputs, this.id);

		const actionPut = strategyOfPlanFactory.createPut();

		await this.strategyOfPlanApi.put(actionPut, id).then((response: any[]) => {
			strategies = response as StrategyOfPlanProtocol[];
		});

		return strategies;
	}
}

export default StrategyOfPlanCostumer;
