import axios from 'axios';
import { type MathematicDiagnosticAssessmentObjType } from '../../types/mathematic-diagnostic-assessment';
import config from '../../../config/config';

export default class MathematicDiagnosticAssessmentApi {
  private get href() {
    return config.apiUrl;
  }

  async post(
    roomId: string,
    twoMonths: string,
    mathObj: MathematicDiagnosticAssessmentObjType
  ): Promise<MathematicDiagnosticAssessmentObjType> {
    let newMathObj = {};
    await axios
      .post(`${this.href}/mathematicdiagnosticassessment/${roomId}/${twoMonths}`, mathObj)
      .then((response) => {
        newMathObj = response.data as MathematicDiagnosticAssessmentObjType;
      })
      .catch(() => {
        //
      });

    return newMathObj;
  }

  async get(roomId: string, twoMonths: string): Promise<MathematicDiagnosticAssessmentObjType> {
    let newMathObj = {};
    await axios
      .get(`${this.href}/mathematicdiagnosticassessment/${roomId}/${twoMonths}`)
      .then((response) => {
        newMathObj = response.data as MathematicDiagnosticAssessmentObjType;
      })
      .catch(() => {
        //
      });

    return newMathObj;
  }

  async getDataByTwoMonths(
    roomId: string,
    twoMonths: string
  ): Promise<{
    yes: number[]
    not: number[]
  }> {
    let newMathObj: {
      yes: number[]
      not: number[]
    } = {
      yes: [],
      not: []
    }

    await axios
      .get(`${this.href}/mathematicdiagnosticassessment/by/twoMonths/${roomId}/${twoMonths}`)
      .then((response) => {
        newMathObj = response.data;
      });

    return newMathObj;
  }
}
