/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import { type QuestionElementType, type QuestionContentType, type StudentExamsType, type QuestionElementOptionType, type ExamResponseType } from '../types/student-exams/student-exams-protocol';
import type StudentExamsResponse from './student-exams-response';

export default class StudentExamsApi {
  private readonly user = lscache.get('user');

	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
    lscache.setExpiryMilliseconds(60000);
	}

  public async post(studentExams: StudentExamsType): Promise<StudentExamsType[]> {
    let newStudentExams: StudentExamsType[] = [];

    await axios.post(this.href + '/student-exams', studentExams).then(response => {
      newStudentExams = response.data;
    }).catch(e => {
      //
    });

    return newStudentExams;
  }

  public async get(): Promise<StudentExamsType[]> {
    let newStudentExams: StudentExamsType[] = [];

    await axios.get(this.href + '/student-exams').then(response => {
      newStudentExams = response.data;
    });

    return newStudentExams;
  }

  public async getExamId(studentExamsId: string, ra: string): Promise<StudentExamsType> {
    let newStudentExams: StudentExamsType = {} as StudentExamsType;

    await axios.get(this.href + `/student-exams/by/id/${studentExamsId}/${ra}`).then(response => {
      newStudentExams = response.data;
    }).catch(e => {
      //
    });

    return newStudentExams;
  }

  public async getExamByIdForCoordinator(studentExamsId: string): Promise<StudentExamsType> {
    let newStudentExams: StudentExamsType = {} as StudentExamsType;

    await axios.get(this.href + `/student-exams/id/coordinator/${studentExamsId}`).then(response => {
      newStudentExams = response.data;
    });

    return newStudentExams;
  }

  public async getExamResponse(studentExamsId: string): Promise<ExamResponseType[]> {
    let newStudentExams: ExamResponseType[] = [];

    await axios.get(this.href + `/student-exam/response/${studentExamsId}`).then(response => {
      newStudentExams = response.data;
    });

    return newStudentExams;
  }

  public async getAllStudentId(studentId: string, ra: string): Promise<StudentExamsType[]> {
    let newStudentExams: StudentExamsType[] = [];

    await axios.get(this.href + `/student-exams/by/student/${studentId}/${ra}`).then(response => {
      newStudentExams = response.data;
    });

    return newStudentExams;
  }

  public async setVisibility(studentExamId: string, visibility: boolean): Promise<StudentExamsType> {
    let newStudentExams: StudentExamsType = {} as unknown as StudentExamsType;

    await axios.put(this.href + `/student-exams/set/visibility/${studentExamId}`, {
      visibility,
    }).then(response => {
      newStudentExams = response.data;
    }).catch(e => {
      //
    });

    return newStudentExams;
  }

  public async update(studentExams: StudentExamsType, studentId: string): Promise<StudentExamsType[]> {
    let newStudentExams: StudentExamsType[] = [];

    await axios.put(this.href + '/student-exams/' + studentId, studentExams).then(response => {
      newStudentExams = response.data;
    });

    return newStudentExams;
  }

  public async delete(studentExamsId: string): Promise<StudentExamsType[]> {
    let newStudentExams: StudentExamsType[] = [];

    await axios.delete(this.href + '/student-exams/' + studentExamsId).then(response => {
      newStudentExams = response.data;
    });

    return newStudentExams;
  }

  public async createQuestion(studentExamsId: string): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.post(this.href + '/student-exams/questions/' + studentExamsId).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async deleteQuestion(studentExamsId: string, questionId: string): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.delete(this.href + `/student-exams/questions/${studentExamsId}/${questionId}`).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async getAllQuestion(studentExamsId: string): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.get(this.href + '/student-exams/questions/' + studentExamsId).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async getAllQuestionToStudent(studentExamsId: string, ra: string): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.get(this.href + `/student-exams/questions/to/student/${studentExamsId}/${ra}`).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async postQuestionElementTitle(studentExamsId: string, questionId: string, element: QuestionElementType): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.post(this.href + `/student-exams/questions/element/title/${studentExamsId}/${questionId}`, element).then(response => {
      questions = response.data;
    })
    return questions;
  }

  public async putQuestionElementTitle(studentExamsId: string, questionId: string, elementId: string, element: QuestionElementType): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.put(this.href + `/student-exams/questions/element/title/${studentExamsId}/${questionId}/${elementId}`, element).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async deleteQuestionElementTitle(studentExamsId: string, questionId: string, elementId: string): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.delete(this.href + `/student-exams/questions/element/title/${studentExamsId}/${questionId}/${elementId}`).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async postQuestionElementImage(studentExamsId: string, questionId: string, file: File): Promise<QuestionElementType[]> {
    let questions: QuestionElementType[] = [];

    const formData = new FormData();

    formData.append('elementImg', file);

    await axios({
      method: 'post',
			url: this.href + `/student-exams/questions/element/img/${studentExamsId}/${questionId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async deleteQuestionElementImg(studentExamsId: string, questionId: string, elementId: string, fileName: string): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.delete(this.href + `/student-exams/questions/element/img/${studentExamsId}/${questionId}/${elementId}/${fileName}`).then(response => {
      questions = response.data;
    })

    return questions;
  }

  public async postQuestionElementOptions(studentExamsId: string, questionId: string, element: QuestionElementType): Promise<QuestionElementType[]> {
    let questions: QuestionElementType[] = [];

    await axios.post(this.href + `/student-exams/questions/element/options/${studentExamsId}/${questionId}`, element).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async deleteQuestionElementOptions(studentExamsId: string, questionId: string, elementId: string): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.delete(this.href + `/student-exams/questions/element/options/${studentExamsId}/${questionId}/${elementId}`).then(response => {
      questions = response.data;
    });

    return questions
  }

  public async postQuestionElementOptionOfOptions(studentExamsId: string, questionId: string, elementId: string, option: QuestionElementOptionType): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.post(this.href + `/student-exams/questions/element/options/${studentExamsId}/${questionId}/${elementId}`, option).then(response => {
      questions = response.data;
    });

    return questions;
  }

  public async putQuestionElementOptionOfOptions(studentExamsId: string, questionId: string, elementId: string, optionId: string, option: QuestionElementOptionType): Promise<QuestionContentType[]> {
    let question: QuestionContentType[] = [];

    await axios.put(this.href + `/student-exams/questions/element/options/${studentExamsId}/${questionId}/${elementId}/${optionId}`, option).then(response => {
      question = response.data;
    });

    return question;
  }

  public async deleteQuestionElementOptionOfOptions(studentExamsId: string, questionId: string, elementId: string, optionId: string): Promise<QuestionContentType[]> {
    let questions: QuestionContentType[] = [];

    await axios.delete(this.href + `/student-exams/questions/element/options/${studentExamsId}/${questionId}/${elementId}/${optionId}`).then(response => {
      questions = response.data;
    });

    return questions;
  }
}
