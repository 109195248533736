/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import FrequencyCustomer from '../../../class/Frequency/frequency-custumer';
import RoomsApi from '../../../class/Room/RoomsApi';
import type { FrequencyType } from '../../../class/types/Frequency/frequency-factory-protocol';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import FrequencyInputElementTwo from '../../../components/frequency-files/frequency-input-element-two';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  element: FrequencyType | undefined
  displayEditElement: boolean
  activityInfo: Record<string, string>
  setDisplayEditElement: (_boolean: React.SetStateAction<boolean>) => void
  setFrequency: React.Dispatch<React.SetStateAction<FrequencyType[]>>
}

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function FormEditElement({ element, displayEditElement, activityInfo, setDisplayEditElement, setFrequency }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const inputDate = useRef<any>()

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');
    if (!id) {
      return;
    }
    if (!element) {
      return;
    }

    const frequencyCustomer = new FrequencyCustomer(inputs, id, activityInfo.matterId, activityInfo.twoMonths, students, element.id);

    setIsLoading(true);
    frequencyCustomer.put().then(response => {
      setFrequency(response);
      setDisplayElement(false);
      setDisplayEditElement(false);
      clearAllInputs();
      setIsLoading(false);
    });
  };

  const huddleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayEditElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  const formatDate = (_date: string) => {
    const dateList = _date.split('/');

    const newDate = `${dateList[2]}-${dateList[1]}-${dateList[0]}`;

    return newDate;
  }

  useEffect(() => {
    setDisplayElement(displayEditElement);
    if (!id) {
      return;
    }

    (inputDate.current as HTMLInputElement).value = formatDate(element?.date ?? '');

    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudents(response);
    });
  }, [displayEditElement]);

  return (
    <div className={`form-edit-container-frequency display-${displayElement}`}>
      <form method='POST' className='form-add-element-activity' id='form-add-element-frequency' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Lançamento de frequência
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='student-frequency-content'>
            <div className='student-frequency-content-header'>
              <ul className='student-frequency-content-header-list'>
                <li>
                  <h2>N°</h2>
                </li>
                <li>
                  <h2>Aluno</h2>
                </li>
                <li>
                  <h2>Presença</h2>
                </li>
              </ul>
            </div>
            <div className='student-frequency-content-body'>
              {!students ? '' : element?.frequency.map((element, index) => <FrequencyInputElementTwo displayElement={displayElement} element={element} index={index} students={students} />)}
            </div>
          </div>
          <div className='input-date-content'>
            <input type="date" ref={inputDate} />
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  huddleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
