/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-negated-condition */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import clearAllInputs from './service/clear-all-inputs';
import { IoIosArrowDown } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import type { StrategyOfPlanProtocol } from '../../../class/types/Strategy-of-plan/Strategy-of-plan-factory-protocol';
import StrategyOfPlanCostumer from '../../../class/StrategyOfPlans/strategy-of-custumer';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayEditElement: boolean
  actions: StrategyOfPlanProtocol[]
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setStrategyOfPlanCustumer: (actions: StrategyOfPlanProtocol[]) => void
  elementId: string | undefined
}

export default function FormEditElement({ displayEditElement, actions, setDisplayEditElement, elementId, setStrategyOfPlanCustumer }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [option, setOption] = useState<string>();
  const { id } = useParams();
  const [strategy, setStrategy] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [
    textArea, inputTimeline, inputSituation
  ] =
    [useRef<any>(), useRef<any>(), useRef<any>()];

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const strategyOfPlanCostumer = new StrategyOfPlanCostumer([
      textArea.current, inputTimeline.current, inputSituation.current
    ], String(id));

    if (elementId) {
      setIsLoading(true);
      strategyOfPlanCostumer.put(elementId).then(response => {
        setStrategyOfPlanCustumer(response);
        setDisplayEditElement(false);
        clearAllInputs();
        setDisplayOptions(false);
        setOption(undefined);
        setIsLoading(false);
        textArea.current.value = '';
      });
    }
  };

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayEditElement(false);
    clearAllInputs();
    setDisplayOptions(false);
    setOption(undefined);
    textArea.current.value = '';
  };

  const hundleClick = () => {
    setDisplayOptions(!displayOptions);
  };

  const hundleClickOption = (e: React.MouseEvent<HTMLUListElement>) => {
    const target = e.target as HTMLElement;

    if (target.textContent) {
      setOption(target.textContent);
      setDisplayOptions(false);
    }
  };

  useEffect(() => {
    setDisplayElement(displayEditElement);

    const newStrategy = actions.filter(value => value.id === elementId)[0];

    if (!newStrategy) {
      return;
    }

    setStrategy(newStrategy);

    (textArea.current ?? {value: ''}).value = newStrategy.strategyName;
    (inputTimeline.current ?? {value: ''}).value = newStrategy.timeline;
    (inputSituation.current ?? {value: ''}).value = newStrategy.situation;
  }, [displayEditElement]);

  return (
    <div className={`form-edit-container display-${displayElement}`}>
      <form
        method='POST'
        className='form-edit-element'
        id='form-edit-element'
        onSubmit={hundleSubmit}
      >
        <div className='header-form-edit-element'>
          <h1>Editar</h1>
        </div>
        <div className='body-form-edit-element'>
          <div className='input-content'>
            <label htmlFor='action'>Estratégia:</label>
            <textarea
              id='action'
              placeholder={'Estratégia'}
              ref={textArea}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='shipping'>Cronograma:</label>
            <input
              type='text'
              id='shipping'
              placeholder={'Cronograma'}
              ref={inputTimeline}
            />
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='situation'>Situação:</label>
            <div className='input-element-content' onClick={hundleClick}>
              <input
                type='text'
                id='situation'
                value={!option ? strategy.situation : option}
                disabled
                ref={inputSituation}
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptions}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptions}`}
              onClick={e => {
                hundleClickOption(e);
              }}
            >
              <li>não inicializado</li>
              <li>realizada</li>
              <li>em andamento</li>
              <li>não finalizada</li>
            </ul>
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }
                }
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose();
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
