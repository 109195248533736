/* eslint-disable @typescript-eslint/no-floating-promises */
import lscache from 'lscache';
import type NotificationType from '../types/notification';
import NotificationFactory from './NotificationFactory';
import NotificationAPI from './NotificationAPI';

export default class NotificationCustumer {
  private readonly user = lscache.get('user');
  constructor(
    private readonly input?: HTMLInputElement,
    private readonly textArea?: HTMLTextAreaElement,
    private readonly notificationSelected?: NotificationType,
  ) {}

  async post(): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [];

    if (!this.input || !this.textArea) {
      return [];
    }

    const notificationFactory = new NotificationFactory();

    const notification = notificationFactory.create(this.user, this.input, this.textArea);

    await NotificationAPI.post(notification).then(response => {
      newNotifications = response;
    });

    return newNotifications;
  }

  async put(): Promise<NotificationType[]> {
    if (!this.notificationSelected) {
      return [];
    }

    if (!this.notificationSelected?.id) {
      return [];
    }

    if (!this.input || !this.textArea) {
      return [];
    }

    let newNotifications: NotificationType[] = [];

    const notificationFactory = new NotificationFactory();

    const notificationPut = notificationFactory.createPut(this.input, this.textArea);

    await NotificationAPI.put(this.notificationSelected.id, notificationPut).then(response => {
      newNotifications = response;
    });

    return newNotifications;
  }

  async postVisualization(): Promise<NotificationType[]> {
    if (!this.notificationSelected) {
      return [];
    }

    if (!this.notificationSelected?.id) {
      return [];
    }

    let newNotifications: NotificationType[] = [];

    const notificationFactory = new NotificationFactory();

    const visualizationPost = notificationFactory.createVisualization(this.user);

    await NotificationAPI.postVisualization(this.notificationSelected.id, visualizationPost).then(response => {
      newNotifications = response;
    });

    return newNotifications;
  }

  async postVisualizationAllNotification(notifications: NotificationType[]): Promise<NotificationType[]> {
    let newNotifications: NotificationType[] = [];

    const notificationId = this.getNotificationIds(notifications);

    const notificationFactory = new NotificationFactory();

    const visualizationPost = notificationFactory.createVisualization(this.user);

    await NotificationAPI.postVisualizationAllNotification(notificationId, visualizationPost).then(response => {
      newNotifications = response;
    })

    return newNotifications;
  }

  getNotificationIds(notifications: NotificationType[]): string[] {
    const notificationIds = notifications.map(notification => notification.id);

    if (typeof notificationIds[0] === 'undefined') {
      return [];
    }

    return (notificationIds as string[]);
  }
}
