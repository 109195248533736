/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type ProgrammaticContentResProtocol from '../types/ProgrammaticContent/ProgrammaticContentApi-protocol';
import type {UserProtocol} from '../types/User/User-factory-protocol';

class ProgrammaticContentApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async get(roomId: string, matterId: string, twoMonths: string): Promise<ProgrammaticContentResProtocol[]> {
		let programmaticContent: ProgrammaticContentResProtocol[] = [];
		if (this.href) {
			await axios.get(`${this.href}/get/all/programmaticContent/${roomId}/${matterId}/${twoMonths}`).then(response => {
				programmaticContent = response.data as ProgrammaticContentResProtocol[];
			});
		}

		return programmaticContent;
	}

	async post(roomId: string, matterId: string, twoMonths: string, activity: ProgrammaticContentResProtocol): Promise<ProgrammaticContentResProtocol[]> {
		let programmaticContent: ProgrammaticContentResProtocol[] = [];

		if (this.href) {
			await axios.post(`${this.href}/post/programmaticContent/${roomId}/${matterId}/${twoMonths}`, activity).then(response => {
				programmaticContent = response.data as ProgrammaticContentResProtocol[];
			});
		}

		return programmaticContent;
	}

  async setReadChecking(roomId: string, matterId: string, twoMonths: string, activityId: string): Promise<ProgrammaticContentResProtocol[]> {
		let programmaticContent: ProgrammaticContentResProtocol[] = [];

		if (this.href) {
			await axios.post(`${this.href}/setReadChecked/programmaticContent/${roomId}/${matterId}/${twoMonths}/${activityId}`).then(response => {
				programmaticContent = response.data as ProgrammaticContentResProtocol[];
			});
		}

		return programmaticContent;
	}

	async put(roomId: string, matterId: string, twoMonths: string, activityId: string, activity: ProgrammaticContentResProtocol): Promise<ProgrammaticContentResProtocol[]> {
		let programmaticContent: ProgrammaticContentResProtocol[] = [];

		if (this.href) {
			await axios.put(`${this.href}/update/programmaticContent/${roomId}/${matterId}/${twoMonths}/${activityId}`, activity).then(response => {
				programmaticContent = response.data as ProgrammaticContentResProtocol[];
			});
		}

		return programmaticContent;
	}

	async delete(roomId: string, matterId: string, twoMonths: string, activityId: string): Promise<ProgrammaticContentResProtocol[]> {
		let programmaticContent: ProgrammaticContentResProtocol[] = [];
		if (this.href) {
			await axios.delete(`${this.href}/delete/programmaticContent/${roomId}/${matterId}/${twoMonths}/${activityId}`).then(response => {
				programmaticContent = response.data as ProgrammaticContentResProtocol[];
			});
		}

		return programmaticContent;
	}
}

export default new ProgrammaticContentApi();
