import { type TextualGenreType } from '../../types/new-writing-hypothesi/textual-genre';
import TextualGenreApi from './textual-genre-api';

export default class TextualGenreCostumer {
  async post(roomId: string, twoMonths: string, textualGenre: string, roomName: string): Promise<TextualGenreType[]> {
    let result: TextualGenreType[] = [];

    const textualGenreApi = new TextualGenreApi();

    await textualGenreApi.post(roomId, twoMonths, {
      textualGenre,
      roomName,
      date: new Date().toLocaleDateString('pt-BR', {
        timeZone: 'UTC'
    }),
    }).then(response => {
      result = response;
    }).catch(e => {
      //
    });

    return result;
  }

  async update(roomId: string, twoMonths: string, textualGenreId: string, data: string) {
    let result: TextualGenreType[] = [];

    const textualGenreApi = new TextualGenreApi();

    await textualGenreApi.update(roomId, twoMonths, textualGenreId, data).then(response => {
      result = response;
    }).catch(e => {
      //
    });

    return result;
  }

  async getAll(roomId: string, twoMonths: string) {
    let result: TextualGenreType[] = [];

    const textualGenreApi = new TextualGenreApi();

    await textualGenreApi.getAll(roomId, twoMonths).then(response => {
      result = response;
    }).catch(e => {
      //
    });

    return result;
  }

  async delete(roomId: string, twoMonths: string, textualGenre: string) {
    let result: TextualGenreType[] = [];

    const textualGenreApi = new TextualGenreApi();

    await textualGenreApi.delete(roomId, twoMonths, textualGenre).then(response => {
      result = response;
    }).catch(e => {
      //
    });

    return result;
  }
}
