/* eslint-disable @typescript-eslint/no-var-requires */
import JsPDF from 'jspdf';
import { type StudentType } from '../../../../class/types/Student/student-factory-protocol';
import { type StudentWritingLRRes, type TagTitleList } from '../../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import autoTable from 'jspdf-autotable';

const img = require('./img/header-img.png');

export default function printWritingLevelRecord(students: StudentType[], writingLevel: StudentWritingLRRes, tags: TagTitleList[], roomName: string, twoMonthsNames: string) {
  const doc = new JsPDF({
    orientation: 'landscape'
  });

  doc.addImage(img, 'PNG', 50, 5, 180, 0);
  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951'
    },
    head: [
      ['Nome do Aluno', 'Escreve o Nome', ...tags.map(tag => tag.name)],
    ],
    body: (() => {
      const bodyArray: string[][] = [];

      students.forEach(student => {
        const writingLevelByStudent = writingLevel.find(writing => writing.studentId === student.id);

        if (!writingLevelByStudent) {
          bodyArray.push([
            student.studentName,
            'Não',
            'NR',
          ]);
          return;
        }

        bodyArray.push([
          student.studentName,
          writingLevelByStudent?.writeTheName ? 'Sim' : 'Não',
          !writingLevelByStudent?.writingLevel ? 'NR' : writingLevelByStudent?.writingLevel,
          ...tags.filter((tag, index) => {
            if (index === 0) {
              return false;
            }
            return true;
          }).map((tag, index) => (writingLevelByStudent as any)[`element${index + 1}`]),
        ]);
      });

      return bodyArray;
  })()
  });

  doc.save(`Registro Níveis de Escrita ${roomName} - ${twoMonthsNames}.pdf`);
}
