/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import Table from '../table'
import './style.scss';

interface StudentMonetaryAidProps {
  display: string
  id: string
}

export default function StudentMonetaryAid({display, id}: StudentMonetaryAidProps): React.ReactElement {
  return (
    <div className={`student-monetary-aid-container display-${display}`} id={id}>
      <Table display={display} />
    </div>
  );
}
