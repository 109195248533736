import { type ElementarySchoolWeeklyPlanType, type ElementarySchoolWeeklyPlanPost, type ElementarySchoolWeeklyPlanPut } from '../types/Upload_ElementarySchoolWeeklyPlan';
import type ElementarySchoolDecentWorkApi from '../types/Upload_ElementarySchoolDecenteWork';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import config from '../../config/config';
import axios from 'axios';

class UploadElementarySchoolHTTPIApi implements ElementarySchoolDecentWorkApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, classRoom, date, name, userName, userId }: ElementarySchoolWeeklyPlanPost): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newKindergartenSchoolHTTPI: ElementarySchoolWeeklyPlanType[] = [];

		const formDate = new FormData();

		formDate.append('fileHTTPI', file);
    formDate.append('classRoom', classRoom);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/kindergartenSchool/HTTPI',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newKindergartenSchoolHTTPI = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newKindergartenSchoolHTTPI;
	}

	async postDescription(decentWorkId: string,
    description: string,
    userId: string,
    userName: string): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newKindergartenSchoolHTTPI: ElementarySchoolWeeklyPlanType[] = [];

		await axios.post(`${this.href}/description/kindergartenSchool/HTTPI/${decentWorkId}`, {
      userId,
      userName,
      date: this._date,
      description
		}).then(response => {
			newKindergartenSchoolHTTPI = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newKindergartenSchoolHTTPI;
	}

  async deleteDescription(decentWorkId: string, descriptionId: string, userId: string) {
    let newKindergartenSchoolHTTPI: ElementarySchoolWeeklyPlanType | undefined;

    await axios
        .delete(
            `${this.href}/description/kindergartenSchool/HTTPI/${decentWorkId}/${descriptionId}/${userId}`
        )
        .then((response) => {
            newKindergartenSchoolHTTPI = response.data as ElementarySchoolWeeklyPlanType;
        });

    return newKindergartenSchoolHTTPI;
}

  async setChecking(weeklyPlanId: string): Promise<ElementarySchoolWeeklyPlanType[]> {
    let newKindergartenSchoolHTTPI: ElementarySchoolWeeklyPlanType[] = [];

    await axios.post(`${this.href}/checking/kindergartenSchool/HTTPI/${weeklyPlanId}`).then(response => {
      newKindergartenSchoolHTTPI = response.data;
    });

    return newKindergartenSchoolHTTPI;
  }

	async put({ file, name, weeklyPlanId }: ElementarySchoolWeeklyPlanPut): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newKindergartenSchoolHTTPI: ElementarySchoolWeeklyPlanType[] = [];

		const formData = new FormData();

		formData.append('fileHTTPI', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/kindergartenSchool/HTTPI/${weeklyPlanId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newKindergartenSchoolHTTPI = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newKindergartenSchoolHTTPI;
	}

	async get(): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newKindergartenSchoolHTTPI: ElementarySchoolWeeklyPlanType[] = [];

		await axios.get(this.href + '/file/kindergartenSchool/HTTPI').then(response => {
			newKindergartenSchoolHTTPI = response.data as ElementarySchoolWeeklyPlanType[];
		}).catch(e => {
			//
		});

		return newKindergartenSchoolHTTPI;
	}

	async delete(elementId: string, fileName: string): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newKindergartenSchoolHTTPI: ElementarySchoolWeeklyPlanType[] = [];

		await axios.delete(this.href + `/file/kindergartenSchool/HTTPI/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			newKindergartenSchoolHTTPI = response.data as ElementarySchoolWeeklyPlanType[];
		}).catch(e => {
			//
		});

		return newKindergartenSchoolHTTPI;
	}
}

export default new UploadElementarySchoolHTTPIApi();
