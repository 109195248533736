import Validator from '../Validator';
import { type ReadingFluencyType } from '../types/ReadingFluency/reading-fluencyApi-protocol';
import ReadingFluencyFactory from './reading-fluency-factory';
import readingFluencyApi from './reading-fluencyApi';

export default class ReadingFluencyCustumer {
	constructor(
		private readonly inputs?: NodeListOf<HTMLInputElement>,
	) {}

	async addReadingFluency(): Promise<ReadingFluencyType[]> {
		if (!this.inputs) {
			return [];
		}

		const readingFactory = new ReadingFluencyFactory(this.inputs);

		const validation = new Validator(this.inputs);

		if (!validation.init()) {
			const readingFluency = readingFactory.createRoom();

			const readingFluencies = await readingFluencyApi.post(readingFluency);

			return readingFluencies;
		}

		return [];
	}

	async put(id: string): Promise<ReadingFluencyType[]> {
		let readingFluencies: ReadingFluencyType[] = [];

		if (!this.inputs) {
			return [];
		}

		const readingFactory = new ReadingFluencyFactory(this.inputs);

		const readingFluencyPut = readingFactory.createPut();

		await readingFluencyApi.put(readingFluencyPut, id).then((response) => {
			readingFluencies = response;
		});

		return readingFluencies;
	}
}
