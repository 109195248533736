
import { type KindergartenSchoolMonthlyPlanType } from '../../../../class/types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';

export default function returnMaxNumber(element: KindergartenSchoolMonthlyPlanType): number {
  const numbers = [
    element.methodology.composeOfExperience.split(' - ').length,
    element.methodology.learningObjectiveAndDevelopment.split(' - ').length,
    element.methodology.resource.split(' - ').length,
    element.methodology.topics.split(' - ').length
  ]

  const maxNumber = Math.max(...numbers);

  return maxNumber;
};
