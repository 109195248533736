import JsPDF from 'jspdf';
import { type ElementarySchoolWeeklyPlanType } from '../../../../class/types/Upload_ElementarySchoolWeeklyPlan';
import autoTable from 'jspdf-autotable';

export default async function printDeliveryData(
  teacherName: string,
  months: Array<{
    month: string
    days: string[]
  }>,
  filterDataByDate: (day: string) => ElementarySchoolWeeklyPlanType[],
  getWeek: (day: string) => string
): Promise<void> {
  const doc = new JsPDF({
    orientation: 'portrait',
  });

  try {
    months.forEach((month) => {
      autoTable(doc, {
        headStyles: {
          fillColor: '#118951',
        },
        head: [
          [
            {
              content: month.month,
              colSpan: 5
            }
          ],
          ['Dia', 'Data', 'Nome', 'Sala', 'Documento']
        ],
        body: (() => {
          const bodyArray: string[][] = [];

          month.days.forEach((day) => {
            const dayArray = [];
            const documentInfo = filterDataByDate(day);

            dayArray.push(getWeek(day));
            dayArray.push(day);

            if (documentInfo.length > 0) {
              dayArray.push(documentInfo[0].issuer?.userName ?? '');
              dayArray.push(documentInfo[0].classRoom ?? '');
              const fileNameFormatted = `${documentInfo[0].fileName} ${documentInfo.length > 1 ? ` + ${documentInfo.length + 1}` : ''}`;

              dayArray.push(fileNameFormatted);
            } else {
              dayArray.push('');
              dayArray.push('');
              dayArray.push('');
            }

            bodyArray.push(dayArray);
          });

          return bodyArray
        })()
      });
    });
  } catch (err) {
    //
  }

  doc.save(`ROTINA SEMANAL - ${teacherName}`);
}
