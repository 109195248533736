/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import lscache from 'lscache';
import './style.scss';
import clearAllInputs from './service/clear-all-inputs';
import PlanCustumer from '../../../class/Plan/plans-custumer';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayEditElement: boolean
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setStrategies: React.Dispatch<React.SetStateAction<any[] | undefined>>
  elementId: string | undefined
}

export default function FormEditElement({ displayEditElement, setDisplayEditElement, elementId, setStrategies }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const inputPlans = useRef<HTMLInputElement | null>(null);
  const inputTimeLine = useRef<HTMLInputElement | null>(null);

  let plans: Record<string, string> = {};

  try {
    lscache.get('plans').forEach((element: any) => {
      if (element.id === elementId) {
        plans = element;
      }
    });
  } catch (e) {
    //
  }

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const planCostumer = new PlanCustumer(inputs);

    if (elementId) {
      setIsLoading(true);
      planCostumer.put(elementId).then(response => {
        setStrategies(response);
        setDisplayEditElement(false);
        clearAllInputs();
        setIsLoading(false);
      });
    }
  };

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayEditElement(false);
    clearAllInputs();
  };

  useEffect(() => {
    setDisplayElement(displayEditElement);

    (inputPlans.current ?? {value: ''}).value = plans.planName;
    (inputTimeLine.current ?? {value: ''}).value = plans.timeline;
  }, [displayEditElement]);

  return (
    <div className={`form-edit-container-plan display-${displayElement}`}>
      <form
        method='POST'
        className='form-edit-element'
        id='form-edit-element-plan'
        onSubmit={hundleSubmit}
      >
        <div className='header-form-edit-element'>
          <h1>Editar</h1>
        </div>
        <div className='body-form-edit-element'>
          <div className='input-content'>
            <label htmlFor='strategy'>Plano de Ação:</label>
            <input
              type='text'
              id='strategy'
              placeholder={'Objetivos estratégicos'}
              ref={inputPlans}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='timeline'>Cronograma:</label>
            <input
              type='text'
              id='timeline'
              placeholder={'Cronograma'}
              ref={inputTimeLine}
            />
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }
                }
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose();
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
