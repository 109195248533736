import { type KindergartenSchoolMonthlyPlanType } from '../types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';

class KindergartenSchoolMonthlyPlanFactory {
  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>
  ) {}

  create(): KindergartenSchoolMonthlyPlanType {
    return {
      school: this.inputs[0].value,
      teacher: this.inputs[1].value,
      class: this.inputs[2].value,
      currentTime: this.inputs[3].value,
      yearn: this.inputs[4].value,
      strategicObjective: this.inputs[5].value,
      specificObjective: this.inputs[6].value,
      ldrece: this.inputs[7].value,
      strategyEndResource: this.inputs[8].value,
      date: this.formateDate(this.inputs[9].value),
      methodology: {
        composeOfExperience: this.inputs[10].value,
        learningObjectiveAndDevelopment: this.inputs[11].value,
        topics: this.inputs[12].value,
        resource: this.inputs[13].value
      }
    };
  }

  put(): KindergartenSchoolMonthlyPlanType {
    return {
      school: this.verifyValue(0),
      teacher: this.verifyValue(1),
      class: this.verifyValue(2),
      currentTime: this.verifyValue(3),
      yearn: this.verifyValue(4),
      strategicObjective: this.verifyValue(5),
      specificObjective: this.verifyValue(6),
      ldrece: this.verifyValue(7),
      strategyEndResource: this.verifyValue(8),
      date: this.formateDate(this.verifyValue(9)),
      methodology: {
        composeOfExperience: this.verifyValue(10),
        learningObjectiveAndDevelopment: this.verifyValue(11),
        topics: this.verifyValue(12),
        resource: this.verifyValue(13)
      },
      commentary: this.verifyValue(14)
    };
  }

  formateDate(_date: string): string {
    const date = new Date(_date);

    return date.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  }

  verifyValue(index: number): string {
    if (!this.inputs[index]) {
      return ''
    }
    return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
  }
}

export default KindergartenSchoolMonthlyPlanFactory;
