/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import LoadContent from '../../Load-content';
import { MdDelete } from 'react-icons/md';
import './style.scss';
import type RoomObserverType from '../../../class/types/RoomObserver';
import { useParams } from 'react-router-dom';
import RoomObserverApi from '../../../class/RoomObserver/RoomObserverApi';
import { FiEdit2 } from 'react-icons/fi';

interface ContentTableType {
  roomObserver: RoomObserverType
  index: number
  roomInformation: {
    matterId: string
    matterName: string
    twoMonths: string
    twoMonthsName: string
  }
  setElement: React.Dispatch<React.SetStateAction<RoomObserverType | undefined>>
  setRoomObservationSheet: React.Dispatch<React.SetStateAction<RoomObserverType[]>>
  setDisplayEditRoomObserver: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayInformation: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ContentTableRoomObserver({
  roomObserver,
  roomInformation,
  index,
  setElement,
  setDisplayEditRoomObserver,
  setRoomObservationSheet,
  setDisplayInformation,
}: ContentTableType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false)
  const {id} = useParams();

  const huddleDelete = () => {
    if (roomObserver.id && typeof id === 'string') {
      setIsLoading(true);
      RoomObserverApi.delete(id, roomInformation.matterId, roomInformation.twoMonths, roomObserver.id).then(response => {
        setRoomObservationSheet(response);
        setIsLoading(false);
      });
    }
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;

    if (['svg', 'path'].includes(target.tagName)) {
      return;
    }

    setElement(roomObserver)
    setDisplayInformation(true);
  }

  const huddleEdit = () => {
    setDisplayEditRoomObserver(true);
    setElement(roomObserver);
  }

  const formatContent = (txt: string) => {
    if (txt.length > 70) {
      return txt.slice(0, 70).concat('...');
    }

    return txt;
  }

  const date = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC'
    })
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [roomObserver]);

  return (
		<div className={`content-table-element-rom-observation-sheet display-animation-${displayAnimation}`} onClickCapture={huddleClick}>
			<ul className='content-table-ul-rom'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{formatContent(roomObserver.content)}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{date(roomObserver.date)}
					</h2>
				</li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
			</ul>
		</div>
  );
}
