/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import config from '../../config/config';
import lscache from 'lscache';
import { type ExamResponseType } from '../types/student-exams/student-exams-protocol';

export default class StudentExamsResponseApi {
  private readonly user = lscache.get('user');

	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
    lscache.setExpiryMilliseconds(60000);
	}

  async postStudentExamsResponse(studentExamsId: string, ra: string, response: ExamResponseType, time: string): Promise<boolean> {
    let success = false;

    await axios.post(this.href + `/student-exam/response/${studentExamsId}/${ra}/${time}`, response).then(response => {
      success = response.data;
    }).catch(e => {
      //
    });

    return success;
  }

  async getAllStudentExamResponse(studentExamsId: string): Promise<ExamResponseType[]> {
    let examsResponse: ExamResponseType[] = []

    await axios.get(this.href + `/student-exam/response/${studentExamsId}`).then(response => {
      examsResponse = response.data;
    }).catch(e => {
      //
    });

    return examsResponse;
  }

  async getResult(studentExamsId: string): Promise<ExamResponseType[]> {
    let examsResponse: ExamResponseType[] = [];

    await axios.get(this.href + `/student-exam/get/result/${studentExamsId}`).then(response => {
      examsResponse = response.data;
    });

    return examsResponse;
  }

  async resetResult(studentExamsId: string): Promise<ExamResponseType[]> {
    let examResponse: ExamResponseType[] = [];

    await axios.delete(this.href + `/student-exam/reset/result/${studentExamsId}`).then(response => {
      examResponse = response.data;
    });

    return examResponse;
  }
}
