import type { StudentType } from '../types/Student/student-factory-protocol';
import Validator from '../Validator';
import StudentFactory from './Waiting-student-factory';
import StudentAPI from './WaitingStudentApi';

class WaitingStudentCostumer {
	private readonly studentApi = StudentAPI;
	constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {}

	async post(): Promise<any> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: true
			};
		}

		let newStudent: StudentType[] = [];
		const studentFactory = new StudentFactory(this.inputs);

		const student = studentFactory.create();

		await this.studentApi.post(student).then((response: StudentType[]) => {
			newStudent = response;
		});

		return newStudent;
	}

	async put(id: string): Promise<StudentType[]> {
		let student: StudentType[] = [];

		const studentFactory = new StudentFactory(this.inputs);

		const studentPut = studentFactory.createPut();

		await this.studentApi.put(studentPut, id).then((response: any[]) => {
			student = response as StudentType[];
		});

		return student;
	}
}

export default WaitingStudentCostumer;
