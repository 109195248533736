
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import FormAddElement from '../FormAddElement-psychologist';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import UserAPI from '../../../class/User/UserAPI';
import ContentTableTeacher from '../../../components/teacher-files/ContentTable-teacher';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [coordinator, setTeacher] = useState<any[]>();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  useEffect(() => {
    UserAPI.getByPermission('psychologist').then(response => {
      setTeacher(response);
    });
  }, []);

  return (
    <div className='table-container-teacher'>
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setTeacher={setTeacher}
      />
      <div className='btn-create-new-teacher'>
        <button onClick={hundleCreateNewElement}>Adicionar Psicóloga(o)</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>id</li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            coordinator?.length === 0
              ? <EmptyComponent>click em (Adicionar professor) para adicionar um professor</EmptyComponent>
              : coordinator?.map((coordinator: any, index: number) => (
                <ContentTableTeacher
                  index={index}
                  user={coordinator}
                  setTeacherCostumer={setTeacher}
                  key={String(coordinator.id)}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
