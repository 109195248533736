import React, { type FormEvent, useRef, useState, useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import PsychologistCostumer from '../../../../class/Psychologist/psychologist-costumer';
import displayError from '../../../../class/services/displayError';
import LoadContent from '../../../../components/Load-content';
import type MedicalRecordType from '../../../../class/types/Psychologist';
import { type PsychologistAnamnese } from '../../../../class/types/Psychologist';

interface AddNewRegisterPsychologistProps {
  huddleDisplayEditRegister: () => void
  display: boolean
  student: PsychologistAnamnese
  medicalRecordSelected: MedicalRecordType | undefined
  setMedicalRecord: React.Dispatch<React.SetStateAction<MedicalRecordType[]>>
}

export default function EditNewRegisterPsychologist({ display, student, medicalRecordSelected, setMedicalRecord, huddleDisplayEditRegister }: AddNewRegisterPsychologistProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textArea = useRef<HTMLTextAreaElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    const input = target.querySelectorAll('input')[0];
    const textarea = target.querySelectorAll('textarea')[0];

    if (input.value.length === 0) {
      displayError(input, 'o campo esta vazio!!');
      return;
    }

    if (textarea.value.length === 0) {
      displayError(textarea, 'o campo esta vazio!!');
      return;
    }

    if (!medicalRecordSelected) {
      return;
    }

    const psychologistCostumer = new PsychologistCostumer(input, textarea);

    setIsLoading(true);
    psychologistCostumer.putMedicalRecord(student.id ?? '', medicalRecordSelected.id ?? '').then(response => {
      if (!response) {
        return;
      }
      setIsLoading(false);
      setMedicalRecord(response);
      huddleDisplayEditRegister();

      textarea.value = '';
      input.value = '';
    }).catch(e => {
      //
    });
  }

  useEffect(() => {
    if (inputRef.current === null || textArea.current === null) {
      return;
    }

    inputRef.current.value = medicalRecordSelected?.date ?? '';
    textArea.current.value = medicalRecordSelected?.observation ?? '';
  }, [display]);

  return (
    <div className={`edit-new-register-psychologist display-${String(display)}`}>
      <header className='new-register-psychologist'>
        <h1>Editar Registro</h1>
        <IoCloseOutline onClickCapture={huddleDisplayEditRegister} className='icon-content' size='25' />
      </header>
      <form method='POST' action='/' className='form-edit-register-psychologist' onSubmit={huddleSubmit}>
        <div className='input-content'>
          <label htmlFor='register-psychologist-date'>Data da consulta</label>
          <input className='input-element' type="date" id="register-psychologist-date" ref={inputRef} />
        </div>
        <div className='input-content'>
          <label htmlFor='register-psychologist-observation'>Observação</label>
          <textarea ref={textArea} />
        </div>
        <div className='button-content-psychologist'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  container: {
                    width: 30,
                  },
                  button: {
                    width: 25,
                  },
                }}
              />
            )
            : <button type='submit'>Adicionar</button>}
        </div>
      </form>
    </div>
  )
}
