/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-no-undef */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import FormAddElement from '../FormAddElement-municipal-education-plan-monitoring-sheet-home';

import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import LoadContent from '../../../components/Load-content';
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import ContentTableMunicipalEducationPlanMonitoringSheet from '../../../components/municipal-education-plan-monitoring-sheet-files/ContentTable-municipal-education-plan-monitoring-sheet-files';
import type MunicipalEducationPlanMonitoringSheetType from '../../../class/types/municipal-education-plan-monitoring-sheet';
import FormEditElement from '../FormEditElement-municipal-education-plan-monitoring-sheet-home';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [element, setElement] = useState<MunicipalEducationPlanMonitoringSheetType | undefined>(undefined);
  const [municipalEducationPlanMonitoringSheet, setMunicipalEducationPlanMonitoringSheet] = useState<MunicipalEducationPlanMonitoringSheetType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(true);
  };

  useEffect(() => {
    if (display === 'false') {
      return
    }

    setIsLoading(true);
    const municipalEducationPlanMonitoringSheet = new MunicipalEducationPlanMonitoringSheetApi();

    municipalEducationPlanMonitoringSheet.get().then(response => {
      setMunicipalEducationPlanMonitoringSheet(response);
      setIsLoading(false);
    }).catch(e => {
      //
    })
  }, [display]);

  return (
    <div className='monitoring-education-plan-monitoring-sheet-table'>
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setMunicipalEducationPlanMonitoringSheet={setMunicipalEducationPlanMonitoringSheet}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        setDisplayEditElement={setDisplayEditElement}
        setMunicipalEducationPlanMonitoringSheet={setMunicipalEducationPlanMonitoringSheet}
        element={element}
      />
      <div className='municipal-education-plan-monitoring-sheet'>
        <h1>Ficha De Monitoramento do Plano Municipal De Educação</h1>
      </div>
      <div className='btn-create-new-action-container'>
        <button onClick={hundleCreateNewElement}>Criar nova Ficha De Monitoramento</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Município</li>
          <li className='header-table-ul-li'>Ano da Primeira Avaliação</li>
          <li className='header-table-ul-li'>Data de Lançamento</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table'>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
          />
          : (
        display === 'true' ? (
          municipalEducationPlanMonitoringSheet?.length === 0
          ? <EmptyComponent>click em (criar nova estratégia) para criar uma estratégia</EmptyComponent>
          : municipalEducationPlanMonitoringSheet?.map((action: any, index: number) => (
            <ContentTableMunicipalEducationPlanMonitoringSheet
              index={index}
              municipalEducationPlanMonitoringSheet={action}
              setDisplayEditElement={setDisplayEditElement}
              setMunicipalEducationPlanMonitoringSheet={setMunicipalEducationPlanMonitoringSheet}
              setElement={setElement}
              key={String(action.id)}
            />
          ))
        ) : ''
          )
        }
      </main>
    </div>
  );
}
