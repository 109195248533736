/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import LoadContent from '../../../components/Load-content';
import type FileAdmLegislationType from '../../../class/types/AdmLegislation';
import FilterAdmLegislation from '../service/filterAdmLegislation';
import uploadAdmHoursApi from '../../../class/UploadAdmHours/upload-adm-hours-api';
import ContentTableAdmHours from '../../../components/admHours';
import FormAddAdmCalendar from '../form-add-calendar';
import FormEditAdmCalendar from '../form-edit-calendar';
import CalendarDisplayInformation from '../calendar-display-information';
import uploadAdmCalendarApi from '../../../class/UploadAdmCalendar/upload-adm-calendar-api';
import ContentTableAdmCalendar from '../../../components/admCalendar';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
  title: string
  titleEdit: string
  titleAdd: string
  titleInfo: string
  emptyText: string
  type: 'general'
}

export default function Table({ display, title, titleEdit, titleAdd, emptyText, titleInfo, type}: TableProps): React.ReactElement {
  const [admLegislation, setAdmLegislation] = useState<FileAdmLegislationType[]>([]);
  const [displayFormAddAdmLegislation, setDisplayAdmLegislation] = useState(false);
  const [displayAdmLegislationInfo, setDisplayAdmLegislationInfo] = useState(false);
  const [displayFormEditAdmCalendar, setDisplayEditAdmLegislation] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [elementFiltered, setElementFiltered] = useState<FileAdmLegislationType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainElement = useRef<any>();

  const [element, setElement] = useState<FileAdmLegislationType>(elementDefault);

  const huddleCreateNewElement = () => {
    setDisplayAdmLegislation(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const elementsFiltered = FilterAdmLegislation(admLegislation, inputs);

    setElementFiltered(elementsFiltered);
  };
  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    uploadAdmCalendarApi.get(type).then((response) => {
      setAdmLegislation(response);
      setElementFiltered(response);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  }, [display]);

  useEffect(() => {
    setElementFiltered(admLegislation);
  }, [admLegislation])

  return (
    <div className='table-container-adm-ATA-parents-meeting'>
      <h1 className='title-table'>{title}</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddAdmCalendar
        displayFormAddAdmCalendar={displayFormAddAdmLegislation}
        setDisplayFormAddAdmCalendar={setDisplayAdmLegislation}
        setAdmCalendar={setAdmLegislation}
        title={titleAdd}
        type={type}
      />
      <FormEditAdmCalendar
        displayFormEditAdmCalendar={displayFormEditAdmCalendar}
        setDisplayFormEditAdmCalendar={setDisplayEditAdmLegislation}
        setAdmCalendar={setAdmLegislation}
        setDisplayEditElement={setDisplayEditAdmLegislation}
        elementId={elementId}
        title={titleEdit}
        type={type}
      />
      <CalendarDisplayInformation
        displayAdmCalendarInfo={displayAdmLegislationInfo}
        setDisplayAdmCalendarInfo={setDisplayAdmLegislationInfo}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar Calendário</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome do documento' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='emissor' />
        </div>
        <div className='search-input-content input-content input-date-content-one input-data'>
          <input type='date' />
        </div>
        <h2>A</h2>
        <div className='search-input-content input-content input-date-content-two input-data'>
          <input type='date' />
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Nome do documento</li>
          <li className='header-table-ul-li'>Emissor</li>
          <li className='header-table-ul-li'>Data</li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '8rem',
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>{emptyText}</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork, index: number) => (
                    <ContentTableAdmCalendar
                      index={index}
                      admCalendar={elementarySchoolDecentWork}
                      setDisplayCalendar={setDisplayAdmLegislationInfo}
                      setDisplayEditElement={setDisplayEditAdmLegislation}
                      setElementId={setElementId}
                      setElement={setElement}
                      setAdmCalendar={setAdmLegislation}
                      type={type}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}
