import type { ActionResponseProtocol } from '../../../class/types/Action/ActionAPI-protocol';
import type ActionInformation from '../type/action-information';

export default function returnInformation(actions: ActionResponseProtocol[]): ActionInformation {
  const sizeAction = actions.length;
  let initialized = 0;
  let notFinalize = 0;
  let inProgress = 0;
  let finalized = 0;

  actions.forEach(action => {
    if (action.situation === 'inicializado') {
      initialized++;
    }

    if (action.situation === 'não inicializado') {
      notFinalize++;
    }

    if (action.situation === 'em andamento') {
      inProgress++;
    }

    if (action.situation === 'finalizado') {
      finalized++;
    }
  });

  return {
    initialized,
    sizeAction,
    notFinalize,
    inProgress,
    finalized
  };
}
