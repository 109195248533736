/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, { useEffect, useState } from 'react';
import type { FinalResultResType } from '../../../class/types/FinalResult/finalResult-type';
import './style.scss';

interface ContentTableActionType {
  index: number
  finalResult: FinalResultResType
  cardReport: Record<string, string>
}

export default function ContentTableFinalResult({
  index,
  finalResult,
  cardReport
}: ContentTableActionType): React.ReactElement {
  useEffect(() => {
    //
  }, [cardReport]);
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const generatePercentualFrequency = () => {
    const percentual = Math.floor((finalResult.frequencyData.present / finalResult.frequencyData.frequencyLength) * 100);

    return `${percentual}%`;
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [finalResult]);

  return (
		<div className={`content-table-element-finalResult display-animation-${displayAnimation}`}>
			<ul className='content-table-ul'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						<h2>{finalResult.studentName}</h2>
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>{finalResult.ra.content ?? finalResult.ra}</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>{finalResult.frequencyData.present}</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>{finalResult.frequencyData.absents}</h2>
				</li>
				<li className='content-table-ul-li percent-content'>
					<h2>{generatePercentualFrequency()}</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>{finalResult.justificationOfAbsences ?? ''}</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>{finalResult.cardReport.average}</h2>
				</li>
			</ul>
		</div>
  );
}
