import React, { useState } from 'react';
import InputSelect from '../../input-select';
import type { MonitoringSheetGoalType, StrategyType } from '../../../class/types/municipal-education-plan-monitoring-sheet';
import './styles.scss';
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import { useParams } from 'react-router-dom';
import MunicipalEducationPlanMonitoringSheetCostumer from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-costumer';
import LoadContent from '../../Load-content';

interface ContentTableProps {
  setStrategies: React.Dispatch<React.SetStateAction<StrategyType[]>>
  strategy: StrategyType
  goalInfo: MonitoringSheetGoalType | undefined
}

export default function ContentTableMunicipalEducationPlanMonitoringSheetStrategies({ setStrategies, strategy, goalInfo }: ContentTableProps): React.ReactElement {
  const { id } = useParams();

  const [saveLoad, setSaveLoad] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);

  const loadContentStyle = {
    button: {
      fontSize: '25px',
    },
    container: {
      width: '70px',
    }
  }

  const deleteStrategy = () => {
    if (!id || !goalInfo || !goalInfo.id || !strategy.id) {
      return;
    }

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    setDeleteLoad(true);
    municipalEducationPlanMonitoringSheetApi.deleteStrategy(id, goalInfo.id, strategy.id).then(response => {
      setStrategies(response);
      setDeleteLoad(false);
    }).catch(e => {
      //
    });
  }

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id || !goalInfo || !goalInfo.id || !strategy.id) {
      return;
    }

    const target = e.target as HTMLFormElement;

    const textArea = target.querySelectorAll('textarea');

    const inputs = target.querySelectorAll('input');

    const municipalEducationPlanMonitoringSheet = new MunicipalEducationPlanMonitoringSheetCostumer();

    setSaveLoad(true);
    municipalEducationPlanMonitoringSheet.putStrategy(id, goalInfo.id, strategy.id, textArea, inputs).then(response => {
      setStrategies(response);
      setSaveLoad(false);
    }).catch((e) => {
      //
    });
  }

  return (
    <form className='content-table-municipal-education-plan-monitoring-sheet-strategy' onSubmit={huddleSubmit}>
      <div className='button-content'>
        {saveLoad
          ? (
            <LoadContent
              style={loadContentStyle}
              isLoading={saveLoad}
            />
          )
          : <button className='button-element save-button' type='submit'>Salvar</button>}
        {
          deleteLoad
            ? (
              <LoadContent
                style={loadContentStyle}
                isLoading={deleteLoad}
              />
            )
            : <button className='button-element delete-button' type='button' onClick={deleteStrategy}>Apagar</button>
        }
      </div>
      <table className='content-table-municipal-education-plan-monitoring-sheet-strategy-table'>
        <thead>
          <tr>
            <th className='gray-background'>Estrategias da meta</th>
            <th className='gray-background'>Prazo</th>
            <th className='gray-background'>Previsões Orçamentárias</th>
            <th className='gray-background'>Status</th>
            <th className='gray-background'>Executou estratégia?</th>
            <th className='gray-background'>Observações</th>
          </tr>
        </thead>
        <tbody>
          <td>
            <textarea className='strategy-text-area' defaultValue={strategy.strategies}></textarea>
          </td>
          <td className='term'>
            <textarea className='strategy-text-area' defaultValue={strategy.term}></textarea>
          </td>
          <td>
            <textarea className='strategy-text-area' defaultValue={strategy.budgetForecast}></textarea>
          </td>
          <td>
            <InputSelect
              title=''
              options={[
                'Não Iniciada',
                'Em Desenvolvimento',
                'Concluído',
              ]}
              defaultValue={strategy.status}
            />
          </td>
          <td>
            <InputSelect
              title=''
              options={[
                'Sim',
                'Não',
                'Parcialmente'
              ]}
              defaultValue={strategy.executedTheStrategy}
            />
          </td>
          <td>
            <textarea className='strategy-text-area' defaultValue={strategy.observation} ></textarea>
          </td>
        </tbody>
      </table>
    </form>
  );
}
