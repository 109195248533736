import React from 'react';
import { type MonitoringSheetGoalType } from '../../../class/types/municipal-education-plan-monitoring-sheet';
import Table from '../table';
import './style.scss';

export interface MunicipalEducationPlanMonitoringSheetIndicatorProps {
  display: string
  goalInfo: MonitoringSheetGoalType | undefined
}

export default function MunicipalEducationPlanMonitoringSheetIndicators({display, goalInfo}: MunicipalEducationPlanMonitoringSheetIndicatorProps): React.ReactElement {
  return (
    <div className='municipal-education-plan-monitoring-sheet-indicators'>
      <Table display={display} goalInfo={goalInfo} />
    </div>
  )
}
