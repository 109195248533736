/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-type-arguments */
/* eslint-disable react/jsx-key */

import React, { useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import filterElements from './service/filter';
import './style.scss';

interface SearchElementType {
  elements: any[] | undefined
  elementKey: string
  elementFiltered: any[]
  placeholder?: string
  otherKey?: string
  byYear?: boolean
  setElementFiltered: React.Dispatch<React.SetStateAction<any[]>>
  removeRepeat?: boolean
}

export default function SearchElement({ elements, elementKey, otherKey, placeholder, byYear, elementFiltered, setElementFiltered, removeRepeat }: SearchElementType): React.ReactElement {
  const input = useRef<any>();
  const [options, setOptions] = useState<any[]>([]);

  const removeRepeats = (array: any[]) => {
    const newArray: any[] = [];
    array.map(value => {
      let isThru = false
      newArray.map(nValue => {
        if (value.date === nValue.date) {
          isThru = true;
        }
        return nValue;
      });

      if (!isThru) {
        newArray.push(value);
      }

      return value;
    });
    return newArray;
  }

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const inputElement = input.current;

    if (!inputElement) {
      return;
    }

    if (!elements) {
      return;
    }

    if (!inputElement.value) {
      setElementFiltered(elements);
      setOptions([]);
      return;
    }

    const elementFiltered = filterElements(elementKey, elements, inputElement.value, otherKey, byYear);

    setOptions([]);
    setElementFiltered(elementFiltered);
  };

  const hundleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!elements) {
      return;
    }

    const elementFiltered = filterElements(elementKey, elements, value, otherKey, byYear);

    if (removeRepeat) {
      setOptions(removeRepeats(elementFiltered));
      return;
    }
    setOptions(elementFiltered);
  };

  const hundleClick = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    const li = e.target as HTMLLIElement;

    if (!li.textContent) {
      return;
    }

    input.current.value = li.textContent;

    if (!elements) {
      return;
    }

    const elementFiltered = filterElements(elementKey, elements, li.textContent, otherKey, byYear);

    if (elementFiltered.length > 1) {
      setOptions(elementFiltered);
    } else {
      setOptions([]);
    }

    if (removeRepeat) {
      setOptions(removeRepeats(elementFiltered));
      return;
    }

    setElementFiltered(elementFiltered);
  };

  return (
    <form method='POST' className='search-element' onSubmit={hundleSubmit}>
      <div className='input-content'>
        <FiSearch size='20' className='search-element' />
        <input type='text' placeholder={!placeholder ? 'Procurar por nome' : placeholder} ref={input} onChange={hundleChange} />
      </div>
      <div className='options-list'>
        <ul className='list-element' onClick={hundleClick}>
          {options
            ? options.map(element => {
              if (otherKey) {
                return <li>{element[elementKey][otherKey]}</li>
              }
              return <li>{element[elementKey]}</li>
            })
            : ''}
        </ul>
      </div>
    </form>
  );
}
