/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './style.scss';
import MathematicDiagnosticAssessmentApi from '../../../class/mathematic-diagnostic-assessment/mathematic-diagnostic-assessment-api';
import { useParams } from 'react-router-dom';
import InputSelect from '../../../components/input-select';
import generatePDF from './service/generetePDF';

const defaultBgImg = require('./img/undraw_Statistic_chart_re_w0pk.png');

interface MathDiagnosticDashboardContentProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  roomInfo: any
}

export default function MathDiagnosticDashboardContent({ roomInfo, display, setDisplay }: MathDiagnosticDashboardContentProps): React.ReactElement {
  const [mathematicDiagnosticDate, setMathematicDiagnosticDate] = useState<
    {
      yes: number[]
      not: number[]
    }>({
      yes: [0, 0, 0, 0, 0, 0, 0],
      not: [0, 0, 0, 0, 0, 0, 0],
    })
  const [optionSelected, setOptionSelected] = useState<number[]>([]);
  const [textOfOption, setTextOfOption] = useState<string>('');
  const options = [
    'Familiares ou Frequentes',
    'Opacos',
    'Transparentes',
    'Terminam com zero',
    'Generalização',
    'Zero intercalado',
    'Composto por algarismos iguais',
  ]
  const { id } = useParams();

  const huddleDisplay = () => {
    setDisplay(!display);
  }

  const huddleSelect = (option: string) => {
    setOptionSelected([
      mathematicDiagnosticDate.not[options.indexOf(option)],
      mathematicDiagnosticDate.yes[options.indexOf(option)]
    ]);
    setTextOfOption(option);
  }

  const huddlePrint = () => {
    generatePDF(roomInfo.twoMonthsName);
  }

  useEffect(() => {
    setOptionSelected([]);
    setTextOfOption('');
    if (!id) {
      return;
    }

    const mathematicDiagnosticApi = new MathematicDiagnosticAssessmentApi();

    mathematicDiagnosticApi.getDataByTwoMonths(id, roomInfo.twoMonths).then(response => {
      setMathematicDiagnosticDate(response);
    }).catch(e => {
      //
    })
  }, [display]);

  if (!display) {
    return <></>;
  }

  return (
    <div className={`math-diagnostic-dashboard-content display-${String(display)}`}>
      <div className='math-diagnostic-dashboard-content-header'>
        <h1>Gráfico Diagnóstico de Matemática - {roomInfo.twoMonthsName}</h1>
        <div className='button-content'>
          <button type="button" onClick={huddlePrint}>Imprimir</button>
          <button type="button" onClick={huddleDisplay}>Voltar</button>
        </div>
      </div>
      <div className='math-diagnostic-dashboard-content-input-selector'>
        <InputSelect
          title='Selecione o Nível'
          defaultValue='Nível'
          cb={huddleSelect}
          options={options}
        />
      </div>
      <div className='mathematic-diagnostic-dashboard-content' id='mathematic-diagnostic-dashboard-content'>
        {
          optionSelected.length > 0
            ? (
              <>
                <Chart
                  type='pie'
                  width={window.innerWidth < 650 ? 350 : 700}
                  series={optionSelected}
                  options={{
                    labels: ['Não', 'Sim'],
                    colors: ['#FF2E00', '#118951'],
                    legend: {
                      fontSize: '16rem',
                      offsetY: 70,
                    },
                    chart: {
                      width: '50px',
                      height: '50px'
                    },
                    title: {
                      align: 'center',
                      margin: 30,
                      style: {
                        fontSize: window.innerWidth < 650 ? '1.0rem' : '2rem'
                      },
                      text: `Diagnóstico de Matemática / Nível - ${textOfOption}`
                    }
                  }}
                />
                <Chart
                  className='graphic-content'
                  type='bar'
                  width={window.innerWidth < 650 ? 290 : 700}
                  height={window.innerWidth < 650 ? 290 : 700}
                  options={{
                    chart: {
                      width: 800,
                      type: 'bar',
                      toolbar: {
                        show: true,
                      },
                    },
                    stroke: {
                      show: true,
                      curve: 'smooth',
                      lineCap: 'butt',
                      colors: ['#000000'],
                      width: 1,
                      dashArray: 0
                    },
                    colors: ['#FF2E00', '#118951'],
                    dataLabels: {
                      enabled: true,
                      formatter(val) {
                        return `${val} alunos(a)`;
                      },
                      offsetY: 10,
                      style: {
                        fontSize: '12px',
                        colors: ['#304758']
                      }
                    },
                    legend: {
                      show: false
                    },
                    plotOptions: {
                      bar: {
                        columnWidth: '45%',
                        distributed: true,
                        dataLabels: {
                          position: 'top' // Top, center, bottom
                        }
                      }
                    },
                    xaxis: {
                      categories: ['Não', 'Sim'],
                      labels: {
                        style: {
                          colors: '#000000',
                          fontWeight: '600',
                          fontSize: '10px'
                        }
                      }
                    }
                  }}
                  series={[
                    {
                      data: optionSelected
                    }
                  ]}
                />
                <div className='mathematic-diagnostic-dashboard-info'>
                  <div>
                    <h1>Diagnóstico de Matemática</h1>
                  </div>
                  <ul>
                    <li>
                      <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                        backgroundColor: '#FF2E00',
                      }}></div>
                      <h1>Não: {optionSelected[0]}</h1>
                    </li>
                    <li>
                      <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                        backgroundColor: '#118951',
                      }}></div>
                      <h1>Sim: {optionSelected[1]}</h1>
                    </li>
                  </ul>
                </div>
              </>
            )
            : (
              <img className='dashboard-default-image' src={defaultBgImg} alt="dashboard default image" />
            )
        }
      </div>
    </div>
  );
}
