/* eslint-disable @typescript-eslint/restrict-template-expressions */

import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { StudentType } from '../types/Student/student-factory-protocol';
import type { UserProtocol } from '../types/User/User-factory-protocol';
import type { StudentObjType } from './services/transformInObj';

class CardReportApi {
  private readonly user = lscache.get('user') as UserProtocol;
  private get href() {
    return config.apiUrl;
  }

  constructor() {
    try {
      axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
    } catch {
      //
    }
  }

  async post(student: StudentObjType, roomId: string): Promise<StudentType[]> {
    let newStudent: StudentType[] = [];

    if (this.href) {
      await axios.post(`${this.href}/post/cardReport/${roomId}`, student).then(response => {
        newStudent = response.data as StudentType[];
      });
    }

    return newStudent;
  }
}

export default new CardReportApi();
