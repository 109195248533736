/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import './style.scss';
import DashboardWritingLevel from '../Dashboard';
import { IoIosArrowDown } from 'react-icons/io';
import StudentWritingRecordCoordinatorApi from '../../../class/student-writing-level-record/student-writing-level-record-coordinator';
import ToCompareDashboard from '../../writing-level-record-final-result/Dashboard/components/to-compare-dashboard';
import studentWritingLevelRecordApi from '../../../class/student-writing-level-record/student-writing-level-record-api';
import { type TagTitleList } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import { type RoomType } from '../../../class/types/Room/Rooms-protocol';
import InputSelect from '../../../components/input-select';

interface DashboardContainerProps {
  display: string
  displayDashboardContainer: boolean
  setDisplayDashboardContainer: React.Dispatch<React.SetStateAction<boolean>>
  roomIds: string[]
  rooms: RoomType[]
}

interface DashboardDataType {
  seriesCount?: number[]
  writingTheName?: number
  series: number[]
  colors: string[]
  categories: string[]
}

export default function DashboardContainer({ displayDashboardContainer, display, rooms, roomIds, setDisplayDashboardContainer }: DashboardContainerProps): React.ReactElement {
  const [roomNames, setRoomNames] = useState<string>('');

  const twoMonthsOption = ['twoMonths-one', 'twoMonths-two'];

  const twoMonths = [
    'twoMonths-one',
    'twoMonths-two',
    'twoMonths-tree',
    'twoMonths-for',
  ]

  const twoMonthsOptions = [
    '1º Bimestre',
    '2º Bimestre',
    '3º Bimestre',
    '4º Bimestre',
  ]
  const options = [
    'Familiares ou Frequentes',
    'Opacos',
    'Transparentes',
    'Terminam com zero',
    'Generalização',
    'Zero intercalado'
  ]

  useEffect(() => {
    let names = rooms.map((room) => room.roomName)[0];
    if (rooms.length > 1) {
      names = names + ` +${rooms.length - 1}`;
    }
    setRoomNames(names);
  }, [rooms]);

  return (
    <div className={`dashboard-container-mathematics-diagnostic-assessment-secretary display-${displayDashboardContainer}`}>
      <div className='dashboard-container-mathematics-diagnostic-assessment-secretary-title'>
        <h1>Filtros</h1>
        <InputSelect
          options={twoMonthsOptions}
          defaultValue='Bimestre'
          title=''
        />
        <InputSelect
          options={options}
          defaultValue='Níveis'
          title=''
        />
      </div>
    </div>
  );
}
