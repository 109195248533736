/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import { MdDelete } from 'react-icons/md';
import LoadContent from '../Load-content';
import { FiEdit2 } from 'react-icons/fi';
import lscache from 'lscache';
import './style.scss';
import type DigitalHTTPIResProtocol from '../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import kindergartenDigitalHTTPIApi from '../../class/KindergartenDigital-HTTPI/kindergartenDigitalHTTPIApi';
import ElementaryDigitalHTTPIApi from '../../class/ElementaryDigital-HTTPI/ElementaryDigitalHTTPIApi';
import RuleVerification from '../rule-verification';
import { HiCheck } from 'react-icons/hi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import WorkshopDigitalHTTPIApi from '../../class/workshopDigital-HTTPI/WorkshopDigitalHTTPIApi';

interface ContentTableKindergartenType {
  index: number
  digitalHTTPI: DigitalHTTPIResProtocol
  type: 'elementary-school' | 'kindergartenSchool' | 'workshop'
  setDigitalHTTPI: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayChecked: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setDisplayDigitalHTTPI: React.Dispatch<React.SetStateAction<boolean>>
  setElement: React.Dispatch<React.SetStateAction<any>>
}
export default function ContentTableDigitalHTTPI({
  digitalHTTPI,
  setDisplayEditElement,
  setDigitalHTTPI,
  setDisplayDigitalHTTPI,
  setDisplayChecked,
  setElementId,
  setElement,
  index,
  type,
}: ContentTableKindergartenType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const huddleDelete = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    if (type === 'kindergartenSchool') {
      kindergartenDigitalHTTPIApi.delete(String(digitalHTTPI.id)).then(response => {
        if (user.permission?.rule === 'professor') {
          const decentWork = response.filter((value: any) => value.issue?.id === user.id)

          setDigitalHTTPI(decentWork);
          setIsLoading(false);

          return;
        }

        setDigitalHTTPI(response);
        setIsLoading(false);
      }).catch((e) => {
        //
      });
      return;
    }
    if (type === 'workshop') {
        WorkshopDigitalHTTPIApi.delete(String(digitalHTTPI.id)).then(response => {
        if (user.permission?.rule === 'professor') {
          const decentWork = response.filter((value: any) => value.issue?.id === user.id)

          setDigitalHTTPI(decentWork);
          setIsLoading(false);

          return;
        }

        setDigitalHTTPI(response);
        setIsLoading(false);
      }).catch((e) => {
        //
      });
    }

    ElementaryDigitalHTTPIApi.delete(String(digitalHTTPI.id)).then(response => {
      if (user.permission?.rule === 'professor') {
        const decentWork = response.filter((value: any) => value.issue?.id === user.id)

        setDigitalHTTPI(decentWork);
        setIsLoading(false);

        return;
      }

      setDigitalHTTPI(response);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(digitalHTTPI.id));
    setDisplayEditElement(true);
  };

  const huddleSetChecking = () => {
    setDisplayChecked(true);
    setElementId(String(digitalHTTPI.id));
  }

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      month: 'long',
      year: 'numeric'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(digitalHTTPI);
    setDisplayDigitalHTTPI(true);
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [digitalHTTPI]);

  return (
    <div className={`content-table-element-digital-httpi display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {digitalHTTPI.issue?.name}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(digitalHTTPI.date)}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
          {digitalHTTPI.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <HiCheck size='20' onClickCapture={huddleSetChecking} style={{ color: '#118951' }} id='icon-hicheck-element' className='checked-icon' />
          </li>
        </RuleVerification>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>

          )}
      </ul>
    </div>
  );
}
