/* eslint-disable @typescript-eslint/no-unsafe-return */

import StrategyAPI from './StrategyAPI';
import StrategyFactory from './Strategy-factory';
import type StrategyCustumerProtocol from '../types/custumer-protocol';
import type { StrategyResponseProtocol } from '../types/Strategy/StrategyAPI-protocol';
import Validator from '../Validator';

class StrategyCustumer implements StrategyCustumerProtocol<StrategyResponseProtocol> {
	private readonly strategyApi = StrategyAPI;

	constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {}

	async post(): Promise<any[]> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return [{
				error: true
			}];
		}

		let strategies: StrategyResponseProtocol[] = [];
		const actionFactory = new StrategyFactory(this.inputs);

		const action = actionFactory.create();

		await this.strategyApi.post(action).then((response: StrategyResponseProtocol[]) => {
			strategies = response;
		});

		return strategies;
	}

	async put(id: string): Promise<any[]> {
		let strategies: any[] = [];

		const strategyFactory = new StrategyFactory(this.inputs);

		const strategyPut = strategyFactory.createPut();

		await this.strategyApi.put(strategyPut, id).then((response: any[]) => {
			strategies = response;
		});

		return strategies;
	}
}

export default StrategyCustumer;
