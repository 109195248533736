
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { BsFileEarmarkPlusFill, BsFillFileEarmarkWordFill, BsFillImageFill } from 'react-icons/bs';
import './style.scss';
import { FaFilePdf, FaFileWord } from 'react-icons/fa';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import { FcDocument } from 'react-icons/fc';
import { SiMicrosoftpowerpoint } from 'react-icons/si';

interface DisplayFileInfoProps {
  fileInfo: File | null | undefined

}
interface DisplayFileInfoTwoProps {
  file: File | null | undefined

}
interface DisplayFileInfoTreeProps {
  type: string | undefined
}

interface ReturnIconExtensionProps {
  type: string
}

function ReturnIconExtension({ type }: ReturnIconExtensionProps): JSX.Element {
  let element = <></>;

  switch (type) {
    case 'pdf':
      element = <FaFilePdf className='icon-content pdf-icon' />;
      break;

    case 'jpeg':
      element = <BsFillImageFill className='icon-content img-icon' />;
      break;

    case 'png':
      element = <BsFillImageFill className='icon-content img-icon' />;
      break;
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      element = <FaFileWord className='icon-content img-docx' />;
      break;
    case 'docx':
      element = <BsFillFileEarmarkWordFill className='icon-content img-docx' />;
      break;
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      element = <PiMicrosoftExcelLogoFill className='icon-content img-xlsx' />;
      break;
    case 'msword':
      element = <FcDocument className='icon-content img-icon'/>;
      break;
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
      element = <SiMicrosoftpowerpoint className='icon-content pptx-icon'/>;
      break;
    default:
      break;
  }

  return element;
}

const formatName = (name: string): string => {
  if (name.length < 30) {
    return name;
  }

  const newName = name.slice(0, 30).concat('...');

  return newName;
};

export default function DisplayFileInfo({ fileInfo }: DisplayFileInfoProps): JSX.Element {
  if (!fileInfo) {
    return <BsFileEarmarkPlusFill className='icon-element' size={50} />;
  }

  const fileExtension = fileInfo.type.split('/')[1];

  return (
		<div className='file-information'>
			<h1>{formatName(fileInfo.name)}</h1>
			<ReturnIconExtension type={fileExtension} />
		</div>
  );
}

export function DisplayFileInfoTwo({ file }: DisplayFileInfoTwoProps): JSX.Element {
  const formatType = file?.type?.split('/').pop();

  if (!formatType || !file) {
    return <BsFileEarmarkPlusFill style={{ color: '#118951' }} className='icon-element' size={50} />;
  }

  return (
		<div className='file-information'>
			<h1>{formatName(file.name)}</h1>
			<ReturnIconExtension type={formatType} />
		</div>
  );
}

export function DisplayFileInfoTree({ type }: DisplayFileInfoTreeProps): JSX.Element {
  if (!type) {
    return <BsFileEarmarkPlusFill className='icon-element' style={{ color: '#118951' }} size='50'/>;
  }

  return <ReturnIconExtension type={type} />;
}
