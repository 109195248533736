/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState, useEffect } from 'react';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import type { RoomType } from '../../../class/types/Room/Rooms-protocol';
import RoomsApi from '../../../class/Room/RoomsApi';
import lscache from 'lscache';
import ContentTableRoomTeacher from '../../../components/rooms-teacher-files/ContentTable-room';
import LoadContent from '../../../components/Load-content';
import SearchElement from '../../../components/Search-element';

export default function Table(): React.ReactElement {
  const [rooms, setRooms] = useState<RoomType[]>([]);
  const [roomsFiltered, setRoomsFiltered] = useState<RoomType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const user = lscache.get('user');
    setIsLoading(true);
    RoomsApi.getAllRoomsByTeacher(user.id).then(response => {
      setIsLoading(false);
      setRooms(response);
      setRoomsFiltered(response);
    });
  }, []);

  return (
    <div className='table-container-rooms-teacher'>
      <div className='search-content'>
        <SearchElement
          elements={rooms}
          setElementFiltered={setRoomsFiltered}
          elementKey='roomName'
          placeholder='Procurar por nome da sala'
          elementFiltered={roomsFiltered}
        />
        <SearchElement
          elements={rooms}
          setElementFiltered={setRoomsFiltered}
          elementKey='date'
          elementFiltered={roomsFiltered}
          placeholder='Procurar por ano'
          byYear
        />
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Prof responsável</li>
          <li className='header-table-ul-li'>Ano letivo</li>
        </ul>
      </div>
      {!isLoading
        ? (
          <main className='main-table'>
            {roomsFiltered?.length === 0
              ? <EmptyComponent />
              : roomsFiltered?.map((room: RoomType, index: number) => (
                <ContentTableRoomTeacher
                  index={index}
                  room={room}
                  key={String(room.id)}
                />
              ))}
          </main>
        )
        : <LoadContent isLoading={isLoading} />}
    </div>
  );
}
