/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, {useState, useEffect} from 'react';
import { MdDelete } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import frequencyApi from '../../../class/Frequency/frequency-api';
import type { FrequencyType } from '../../../class/types/Frequency/frequency-factory-protocol';
import './style.scss';
import { FiEdit2 } from 'react-icons/fi';
import LoadContent from '../../Load-content';

interface ContentTableType {
  frequency: FrequencyType
  activityInfo: Record<string, string>
  index: number
  setDisplayEditFrequency: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayFrequency: React.Dispatch<React.SetStateAction<boolean>>
  setFrequencySelected: React.Dispatch<React.SetStateAction<FrequencyType | undefined>>
  setFrequency: React.Dispatch<React.SetStateAction<FrequencyType[]>>
  setElement: React.Dispatch<React.SetStateAction<FrequencyType | undefined>>
}

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function ContentTableFrequency({
  frequency,
  index,
  activityInfo,
  setDisplayEditFrequency,
  setFrequencySelected,
  setDisplayFrequency,
  setFrequency,
  setElement
}: ContentTableType): React.ReactElement {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const huddleEdit = () => {
    setElement(frequency);
    setDisplayEditFrequency(true);
  }

  const huddleDelete = () => {
    if (id && frequency.id) {
      setIsLoading(true);
      frequencyApi.delete(id, activityInfo.matterId, activityInfo.twoMonths, frequency.id).then(response => {
        setFrequency(response);
        setIsLoading(false);
      }).catch(e => {
        //
      });
    }
  };

  const countPresent = (key: string) => {
    let count = 0;

    frequency.frequency.forEach(student => {
      if (student.present === key) {
        count++;
      }
    });
    return count;
  };

  const huddleClick = (e: React.MouseEvent<HTMLUListElement>) => {
    if (!frequency.id) {
      return;
    }

    const target = e.target as HTMLElement;
    if (target.tagName === 'svg' || target.tagName === 'path' || target.classList.contains('delete-button-content')) {
      return;
    }

    setFrequencySelected(frequency);
    setDisplayFrequency(true);
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [frequency]);

  return (
		<div className={`content-table-element-activity display-animation-${displayAnimation}`}>
			<ul className='content-table-ul-roms' onClickCapture={huddleClick}>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{frequency.date}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{countPresent('true')}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{countPresent('false')}
					</h2>
				</li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
					<FiEdit2 size='20' style={{ color: '#118951' }} />
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>

          )}
			</ul>
		</div>
  );
}
