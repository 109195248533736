/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import PlanCustumer from '../../../class/Plan/plans-custumer';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setStrategies: React.Dispatch<React.SetStateAction<any[] | undefined>>
}

export default function FormAddElement({ displayCreateNewElement, setDisplayCreateNewElement, setStrategies }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const planCustumer = new PlanCustumer(inputs);

    setIsLoading(true);
    planCustumer.post().then(response => {
      if (response[0].error) {
        setIsLoading(false);
        throw new Error('');
      }

      setStrategies(response);
      clearAllInputs();
      setDisplayCreateNewElement(false);
      setIsLoading(false);
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-plan display-${displayElement}`}>
      <form method='POST' className='form-add-element' id='form-add-element-plan' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Crie um novo plano municipal pela primeira infância
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='plan-input'>Plano:</label>
            <input type='text' id='plan-input' placeholder='digite o nome do plano' />
          </div>
          <div className='input-content'>
            <label htmlFor='timeline'>Cronograma:</label>
            <input type='text' id='timeline' placeholder='cronograma' />
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }
                }
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
