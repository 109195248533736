import type { RoomType } from '../types/Room/Rooms-protocol';
import type RoomObserverType from '../types/RoomObserver';
import RoomObserverApi from './RoomObserverApi';
import RoomObserverFactory from './RoomObserverFactory';
import displayError from '../services/displayError';

export default class RoomObserverCustumer {
	constructor(
    private readonly roomId: string,
    private readonly matterId: string,
    private readonly twoMonths: string,
		private readonly textArea?: HTMLTextAreaElement,
		private readonly input?: HTMLInputElement,
	) {}

	async postRoomObserver(): Promise<RoomObserverType[]> {
		if (!this.input || !this.textArea) {
			return [];
		}

		const roomObserverFactory = new RoomObserverFactory(this.textArea, this.input);

    if (this.textArea.value.length === 0) {
      displayError(this.input, 'O campo não pode estar vazio!!')
      return []
    }

		if (this.input.value.length === 0) {
      displayError(this.input, 'O campo não pode estar vazio!!')
      return []
    }
    const roomObserverPost = roomObserverFactory.create();
    const roomObserver = await RoomObserverApi.post(this.roomId, this.matterId, this.twoMonths, roomObserverPost);

		return roomObserver;
	}

	async put(id: string): Promise<RoomObserverType[]> {
		let observer: RoomObserverType[] = [];

		if (!this.input || !this.textArea) {
			return [];
		}

    if (this.input.value.length === 0) {
      displayError(this.input, 'O campo não pode estar vazio!!');
      return [];
    }
    if (this.textArea.value.length === 0) {
      displayError(this.textArea, 'O campo não pode estar vazio!!');
      return [];
    }

		const roomFactory = new RoomObserverFactory(this.textArea, this.input);

		const roomObserverPut = roomFactory.create();

		await RoomObserverApi.put(this.roomId, this.matterId, this.twoMonths, id, roomObserverPut).then((response) => {
			observer = response;
		});

		return observer;
	}
}
