import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type {UserProtocol} from '../types/User/User-factory-protocol';
import { type JustificationOfAbsencesPut, type JustificationOfAbsencesType } from '../types/JustificationOfAbsences';

class JustificationOfAbsences {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async get(roomId: string, matterId: string, twoMonths: string): Promise<JustificationOfAbsencesType[]> {
		let newJustificationOfAbsences: JustificationOfAbsencesType[] = [];
    if (this.href) {
			await axios.get(`${this.href}/justificationOfAbsences/${roomId}/${matterId}/${twoMonths}`).then(response => {
				newJustificationOfAbsences = response.data as JustificationOfAbsencesType[];
			});
		}

		return newJustificationOfAbsences;
	}

	async post(roomId: string, matterId: string, twoMonths: string, justificationOfAbsences: JustificationOfAbsencesPut): Promise<JustificationOfAbsencesType[]> {
		let newJustificationOfAbsences: JustificationOfAbsencesType[] = [];

		if (this.href) {
			await axios.post(`${this.href}/justificationOfAbsences/${roomId}/${matterId}/${twoMonths}`, justificationOfAbsences).then(response => {
				newJustificationOfAbsences = response.data as JustificationOfAbsencesType[];
			});
		}

		return newJustificationOfAbsences;
	}

	async put(roomId: string, matterId: string, twoMonths: string, justificationOfAbsencesId: string, justificationOfAbsences: JustificationOfAbsencesPut): Promise<JustificationOfAbsencesType[]> {
    let newJustificationOfAbsences: JustificationOfAbsencesType[] = [];

		if (this.href) {
			await axios.put(`${this.href}/justificationOfAbsences/${roomId}/${matterId}/${twoMonths}/${justificationOfAbsencesId}`, justificationOfAbsences).then(response => {
				newJustificationOfAbsences = response.data as JustificationOfAbsencesType[];
			});
		}

		return newJustificationOfAbsences;
	}

	async delete(roomId: string, matterId: string, twoMonths: string, justificationOfAbsencesId: string): Promise<JustificationOfAbsencesType[]> {
		let newJustificationOfAbsences: JustificationOfAbsencesType[] = [];
		if (this.href) {
			await axios.delete(`${this.href}/justificationOfAbsences/${roomId}/${matterId}/${twoMonths}/${justificationOfAbsencesId}`).then(response => {
				newJustificationOfAbsences = response.data as JustificationOfAbsencesType[];
			});
		}

		return newJustificationOfAbsences;
	}
}

export default new JustificationOfAbsences();
