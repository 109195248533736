import React from 'react';
import MathematicsDiagnosticAssessmentTable from '../table';

interface MathematicsDiagnosticAssessmentTableProps {
  display: string
  roomInformation: any
  id: string
}

export default function MathematicsDiagnosticAssessment({display, roomInformation, id}: MathematicsDiagnosticAssessmentTableProps): React.ReactElement {
  return (
    <div className='mathematics-diagnostic-assessment' id={id}>
      <MathematicsDiagnosticAssessmentTable display={display} roomInformation={roomInformation} />
    </div>
  );
}
