import React from 'react';
import './styles.scss';
import { type QuestionElementType } from '../../../class/types/student-exams/student-exams-protocol';

interface ExamImgContentProps {
  element: QuestionElementType
}

export default function ExamImgContent({element}: ExamImgContentProps): React.ReactElement {
  return (
    <div className='exam-img-content'>
      <img src={element.img?.url} alt="" />
    </div>
  );
}
