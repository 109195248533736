/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import config from '../../config/config';
import lscache from 'lscache';
import type IndividualCardReportType from '../types/IndividualCardReporting';

export default new (class IndividualCardReport {
  private readonly user = lscache.get('user');
	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  private get href() {
		return config.apiUrl;
	}

  async getReadingFluency(roomId: string, studentId: string): Promise<IndividualCardReportType | undefined> {
    let readingFluency: IndividualCardReportType | undefined;

    await axios.get(this.href + `/individualcardreport/${roomId}/${studentId}`).then(response => {
      readingFluency = response.data as IndividualCardReportType;
    });

    return readingFluency
  }
})()
