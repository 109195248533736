import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import type NotificationType from '../types/notification';
import { type NotificationViewType } from '../types/notification';

export default class NotificationFactory {
  create(user: UserClientProtocol, input: HTMLInputElement, textArea: HTMLTextAreaElement): NotificationType {
    return {
      title: input.value,
      content: textArea.value,
      date: this.getDate(),
      issuer: {
        id: user.id ?? '',
        name: user.userName,
      },
    }
  }

  createPut(input: HTMLInputElement, textArea: HTMLTextAreaElement): any {
    return {
      title: input.value,
      content: textArea.value,
      date: this.getDate(),
    }
  }

  createVisualization(user: UserClientProtocol): NotificationViewType {
    return {
      userId: user.id ?? '',
      name: user.userName,
      date: this.getDate(),
      time: this.getTime(),
    }
  }

  getDate() {
    return (new Date()).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    })
  }

  getTime() {
    return (new Date()).toLocaleTimeString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      timeStyle: 'short',
    })
  }
};
