
import React from 'react';
import './style.scss';
import Header from '../../../components/Header';
import RecoverByEmailContainer from '../../../container/recover-by-email-container';

export default function RecoverByEmail() {
  return (
		<div className='App'>
			<main className='main-container-register'>
				<Header />
				<div className='form-register-container'>
					<RecoverByEmailContainer />
				</div>
			</main>
		</div>
  );
}
