/* eslint-disable @typescript-eslint/no-unused-vars */

import lscache from 'lscache';
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import type { StrategyOfPlanProtocol } from '../../../class/types/Strategy-of-plan/Strategy-of-plan-factory-protocol';
import filterById from './services/filterById';
import returnInformation from './services/returnInformation';
import './style.scss';
import type ActionInformation from './type/action-information';

interface DashboardProps {
  display: string
  action: StrategyOfPlanProtocol[]
}

export default function Dashboard({ display, action }: DashboardProps): React.ReactElement {
  const [actions, setActions] = useState<StrategyOfPlanProtocol[]>([]);
  const [actionInformation, setActionInformation] = useState<ActionInformation>();
  const { id } = useParams();

  useEffect(() => {
    if (action) {
      const newAction = filterById(action, String(id));
      setActions(newAction);

      const actionInformation = returnInformation(newAction);

      setActionInformation(actionInformation);
    }
  }, [display]);

  const returnPercentage = (actionInformation: number | undefined, actionSize: number | undefined): number => {
    if (!actionInformation || !actionSize) {
      return 0;
    }

    const porcentage = (actionInformation / actionSize) * 100;

    return Math.floor(porcentage);
  };

  if (display === 'false') {
    return <></>
  }

  return (
		<div className='dashboard-element'>
			<div className='dashboard-content'>
				<Chart
					type='pie'
					width={650}
					height={700}
					series={[
					  returnPercentage(actionInformation?.notInitialized, actionInformation?.sizeAction),
					  returnPercentage(actionInformation?.inProgress, actionInformation?.sizeAction),
					  returnPercentage(actionInformation?.accomplished, actionInformation?.sizeAction),
					  returnPercentage(actionInformation?.notFinished, actionInformation?.sizeAction)
					]}

// '#FF2E00', '#048eff', '#FFFF00', '#32CD32'
					options={{
					  labels: ['Não inicializado', 'Em andamento', 'Finalizado', 'Não finalizado'],
					  colors: ['#FF2E00', '#FFF700', '#118951', '#ff0000'],
					  legend: {
					    fontSize: '16rem',
					    offsetY: 70
					  },
					  chart: {
					    width: '50px',
					    height: '50px'
					  },
					  title: {
					    align: 'center',
					    margin: 30,
					    style: {
					      fontSize: '2rem'
					    },
					    text: 'Dashboard do andamento das estratégias'
					  }
					}}
				/>
			</div>

		</div>
  );
}
