import React, { useEffect, useState } from 'react';
import './styles.scss';
import AddNewRegisterPsychologist from './add-new-register';
import type MedicalRecordType from '../../../class/types/Psychologist';
import PsychologistApi from '../../../class/Psychologist';
import TableContentMedicalRecord from '../../../components/psychologist-files/table-content-medical-record';
import LoadContent from '../../../components/Load-content';
import EditNewRegisterPsychologist from './edit-new-register';
import { type PsychologistAnamnese } from '../../../class/types/Psychologist';

interface MedicalRecordByStudentProps {
  student: PsychologistAnamnese | undefined
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MedicalRecordByStudent({ student, display, setDisplay }: MedicalRecordByStudentProps): React.ReactElement {
  const [displayAddNewRegister, setDisplayAddNewRegister] = useState<boolean>(false);
  const [medicalRecord, setMedicalRecord] = useState<MedicalRecordType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayEditRegister, setDisplayEditRegister] = useState<boolean>(false);
  const [medicalRecordSelected, setMedicalRecordSelected] = useState<MedicalRecordType>();

  const huddleDisplayAddNewRegister = () => {
    setDisplayAddNewRegister(!displayAddNewRegister);
  }

  const huddleDisplayEditRegister = () => {
    setDisplayEditRegister(!displayEditRegister);
  }

  useEffect(() => {
    if (!student) {
      return;
    }

    const psychologist = new PsychologistApi();

    setIsLoading(true);
    psychologist.getAllMedicRecord(student.id ?? '').then(response => {
      setMedicalRecord(response);
      setIsLoading(false);
    }).catch(e => {

    })
  }, [display])

  if (!student) {
    return <></>;
  }

  return (
    <section className={`medical-record-by-student display-${String(display)}`}>
      <AddNewRegisterPsychologist student={student} setMedicalRecord={setMedicalRecord} huddleDisplayAddNewRegister={huddleDisplayAddNewRegister} display={displayAddNewRegister} />
      <EditNewRegisterPsychologist student={student} medicalRecordSelected={medicalRecordSelected} setMedicalRecord={setMedicalRecord} huddleDisplayEditRegister={huddleDisplayEditRegister} display={displayEditRegister} />
      <header className='medical-record-by-student-header'>
        <h1>Prontuário: {student.studentName}</h1>
        <button className='button-content' onClick={() => {
          setDisplay(false);
        }}>Voltar</button>
      </header>
      <div className='medical-record-by-student-nav'>
        <button className='button-content' onClickCapture={huddleDisplayAddNewRegister}>Adicionar Registro</button>
      </div>
      <div className='medical-record-table'>
        <div className='medical-record-table-header'>
          <ul className='medical-record-table-header-list'>
            <li>
              <h1>Date</h1>
            </li>
            <li>
              <h1>Observação</h1>
            </li>
          </ul>
        </div>
        <div className='medical-record-table-main'>
          {isLoading
            ? (
              <LoadContent isLoading={isLoading} style={{container: {marginTop: '5rem'}}} />
            )
            : medicalRecord.map((medicalRecord) => {
              return <TableContentMedicalRecord key={medicalRecord.id} student={student} setMedicalRecord={setMedicalRecord} huddleDisplayEdiRegister={huddleDisplayEditRegister} setMedicalRecordSelected={setMedicalRecordSelected} medicalRecord={medicalRecord} />
            })}
        </div>
      </div>
    </section>
  );
}
