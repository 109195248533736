/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect, useRef } from 'react';
import EmptyComponent from '../../../components/Empty-component';
import FormAddElement from '../FormAddElement-digital-HTTPI';
import './style.scss';
import BackToTop from '../../../components/back-to-top/back-to-top';
import ElementarySchoolDigitalHTTPIApi from '../../../class/ElementaryDigital-HTTPI/ElementaryDigitalHTTPIApi';
import ContentTableDigitalHTTPI from '../../../components/digital-HTTPI';
import type DigitalHTTPIResProtocol from '../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import FormEditElement from '../FormEditElement-digital-HTTPI';
import DayOfHTTPI from '../DayOfHTTPI/HTTPI';
import lscache from 'lscache';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import SearchDigitalHTTPI from '../../../components/Search-element-httpi';
import filterHTTPIByDate from './service/filterByDate';
import ReadCheckingDigitalHTTPI from '../../../components/read-checking-digital-HTTPI';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [elementarySchoolDigitalHTTPIFiltered, setElementarySchoolDigitalHTTPIFiltered] = useState<DigitalHTTPIResProtocol[]>([]);
  const [elementarySchoolDigitalHTTPI, setElementarySchoolDigitalHTTPI] = useState<DigitalHTTPIResProtocol[]>([]);
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [displayDayOfHTTPI, setDisplayDayOfHTTPI] = useState(false);
  const [displayChecked, setDisplayChecked] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [element, setElement] = useState<any>();
  const mainElement = useRef<any>();

  const afterDate = useRef<HTMLInputElement | null>(null);
  const beforeDate = useRef<HTMLInputElement | null>(null);

  const huddleCreateNewElement = () => {
    setDisplayCreateNewElement(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!afterDate || !beforeDate) {
      return;
    }

    if (!afterDate.current?.value || !beforeDate.current?.value) {
      setElementarySchoolDigitalHTTPIFiltered(elementarySchoolDigitalHTTPI)
      return;
    }

    setElementarySchoolDigitalHTTPIFiltered(filterHTTPIByDate(elementarySchoolDigitalHTTPIFiltered, afterDate.current?.value ?? '', beforeDate.current?.value ?? ''));
  }

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    ElementarySchoolDigitalHTTPIApi.get().then(response => {
      setIsLoading(false);
      if (user.permission?.rule === 'professor') {
        const responseFilter = response.filter(value => value.issue?.id === user.id);
        setElementarySchoolDigitalHTTPI(responseFilter);
        return
      }
      setElementarySchoolDigitalHTTPI(response);
    });
  }, [display]);

  useEffect(() => {
    setElementarySchoolDigitalHTTPIFiltered(elementarySchoolDigitalHTTPI);
  }, [elementarySchoolDigitalHTTPI]);

  return (
    <div className='table-elementary-container-digital-httpi'>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setElementarySchoolDigitalHTTPI={setElementarySchoolDigitalHTTPI}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        setDisplayEditElement={setDisplayEditElement}
        setElementarySchoolDigitalHTTPI={setElementarySchoolDigitalHTTPI}
        elementarySchoolDigitalHTTPI={elementarySchoolDigitalHTTPI}
        elementId={elementId}
      />
      <DayOfHTTPI
        display={displayDayOfHTTPI}
        setDisplay={setDisplayDayOfHTTPI}
        element={element}
      />
      <ReadCheckingDigitalHTTPI
        displayReadChecking={displayChecked}
        elementId={elementId}
        setDisplayReadChecking={setDisplayChecked}
        setElementaryDigitalHTTPI={setElementarySchoolDigitalHTTPI}
        type='elementary-school'
      />
      <div className='header-digital-httpi'>
        <h1>Ensino Fundamental - HTTPI Digital</h1>
      </div>
      <div className='btn-create-new-student-container'>
        <button onClick={huddleCreateNewElement}>Adicionar HTTPI</button>
      </div>
      <div className='search-digital-httpi-content'>
        <SearchDigitalHTTPI
          elementFiltered={elementarySchoolDigitalHTTPIFiltered}
          elements={elementarySchoolDigitalHTTPI}
          setElementFiltered={setElementarySchoolDigitalHTTPIFiltered}
        />
        <form method='POST' className='input-date-content' onSubmitCapture={huddleSubmit}>
          <div className='date-content'>
            <div className='input-content'>
              <input type="month" ref={afterDate} />
            </div>
            <h2>a</h2>
            <div className='input-content'>
              <input type="month" ref={beforeDate} />
            </div>
          </div>
          <div className='button-content'>
            <button>Buscar</button>
          </div>
        </form>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Nome</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-content'>Visualização</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '0rem'
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementarySchoolDigitalHTTPIFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar HTTPI) para adicionar um novo HTTPI</EmptyComponent>
                  : elementarySchoolDigitalHTTPIFiltered?.map((digitalHTTPI: any, index: number) => (
                    <ContentTableDigitalHTTPI
                      index={index}
                      type={'elementary-school'}
                      digitalHTTPI={digitalHTTPI}
                      setDisplayEditElement={setDisplayEditElement}
                      setDisplayChecked={setDisplayChecked}
                      setElement={setElement}
                      setDisplayDigitalHTTPI={setDisplayDayOfHTTPI}
                      setDigitalHTTPI={setElementarySchoolDigitalHTTPI}
                      setElementId={setElementId}
                    />
                  ))
              )
              : ''
          )}
      </main>
    </div>
  );
}
