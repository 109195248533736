/* eslint-disable multiline-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import type { MouseEvent } from 'react';
import React, { useEffect, useState, useRef } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import MatterCustumer from '../../../class/Matter/Matter-custumer';
import type { MatterResProtocol } from '../../../class/types/Matter/matterApi-protocol';
import UserAPI from '../../../class/User/UserAPI';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import RoomsApi from '../../../class/Room/RoomsApi';
import MatterAPI from '../../../class/Matter/MatterAPI';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setMatter: React.Dispatch<React.SetStateAction<MatterResProtocol[]>>
}

export default function FormAddElement({ displayCreateNewElement, setDisplayCreateNewElement, setMatter }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [displayTeacherOptions, setDisplayTeacherOptions] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [inputValueTwo, setInputValueTwo] = useState('');
  const [teacherId, setTeacherId] = useState('');
  const [teachers, setTeacher] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matters, setMatters] = useState<MatterResProtocol[]>([]);
  const [matterIdSelected, setMatterIdSelected] = useState<string>('');
  const [displayMatterOptions, setDisplayMatterOptions] = useState<boolean>(false);
  const inputRef = useRef<any>();
  const { id } = useParams();

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');
    if (!id) {
      return;
    }

    const matterCustumer = new MatterCustumer(inputs, teacherId, id, matterIdSelected);

    setIsLoading(true);
    matterCustumer.post().then(response => {
      if (response[0].error) {
        setIsLoading(false);
        return;
      }

      if (response[0]) {
        setMatter(response);
        setDisplayElement(false);
        clearAllInputs();
        setIsLoading(false);
      }
    });
  };

  const hundleClickOption = () => {
    setDisplayTeacherOptions(!displayTeacherOptions);
  };

  const hundleLiClick = (e: MouseEvent<HTMLLIElement>) => {
    const target = e.target as HTMLLIElement;

    if (!target.textContent) {
      return;
    }

    setTeacherId(target.id);
    setInputValue(target.textContent);
    setDisplayTeacherOptions(false);
  };

  const selectMatter = (matter: MatterResProtocol) => {
    setDisplayMatterOptions(false);
    setMatterIdSelected(matter.id ?? '');

    setInputValueTwo(matter.matterName);
  }

  const huddleClickOptionMatter = () => {
    setDisplayMatterOptions(!displayMatterOptions);
  }

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    clearAllInputs();
    setInputValue('');
    setInputValueTwo('');
    setMatterIdSelected('');
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
    UserAPI.getByPermission('teacher').then(response => {
      setTeacher(response);
    });

    if (!id) {
      return;
    }

    MatterAPI.get(id).then(response => {
      setMatters(response);
    });
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-matter display-${displayElement}`}>
      <form method='POST' className='form-add-element-roms' id='form-add-element-roms' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicionar uma nova matéria
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose();
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='room-name'>Matéria:</label>
            <input type='text' id='room-name' placeholder='digite o nome da matéria' ref={inputRef} autoComplete='off' />
          </div>
          <div className='input-content'>
            <div className='input-element'>
              <input type='text' id='teacher' placeholder='digite o nome da sala' value={inputValue} autoComplete='off' />
              <label className='teacher-content' htmlFor='teacher' onClick={hundleClickOption}>{!inputValue ? 'Professor' : inputValue}</label>
              <IoIosArrowDown className={`icon-arrow display-${displayTeacherOptions}`} size='25' color='rgb(172, 172, 172)' />
            </div>
            <ul className={`teacher-option display-${displayTeacherOptions}`}>
              {teachers?.map((teacher: any) => <li key={teacher.id} id={teacher.id} onClick={hundleLiClick}>{teacher.userName}</li>)}
            </ul>
          </div>
          <div className='input-content'>
            <div className='input-element'>
              <input type='text' id='teacher' placeholder='digite o nome da sala' value={inputValueTwo} autoComplete='off' />
              <label className='teacher-content' htmlFor='teacher' onClick={huddleClickOptionMatter}>{!inputValueTwo ? 'Vinculo' : inputValueTwo}</label>
              <IoIosArrowDown className={`icon-arrow display-${displayMatterOptions}`} size='25' color='rgb(172, 172, 172)' />
            </div>
            <ul className={`teacher-option display-${displayMatterOptions}`}>
              {matters?.map((matter) => <li key={matter.id} id={matter.id} onClick={() => {
                selectMatter(matter);
              }}>{matter.matterName}</li>)}
            </ul>
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose();
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Adicionar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
