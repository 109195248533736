import { type ReadingFluencyType } from '../types/ReadingFluency/reading-fluencyApi-protocol';

export default class ReadingFluencyFactory {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
	) {}

	createRoom(): ReadingFluencyType {
		return {
      twoMonths: this.inputs[0].value,
      year: this.inputs[1].value,
      room: this.inputs[2].value,
      roomId: '-' + this.inputs[2].id.split('-')[1],
      date: this.inputs[3].value,
    }
	}

	createPut(): ReadingFluencyType {
		return {
      twoMonths: this.inputs[0].value,
      year: this.inputs[1].value,
      room: this.inputs[2].value,
      roomId: '-' + this.inputs[2].id.split('-')[1],
      date: this.inputs[3].value,
    };
	}

	verifyValue(index: number): string {
		return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
	}

	private getDate(): string {
		const date = new Date();

		return date.toLocaleDateString('UTC', {
			dateStyle: 'short'
		});
	}
}
