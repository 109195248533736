
import Validator from '../Validator';
import { type ElementarySchoolDecentWorkType } from '../types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import UploadAdmLegislationApi from './upload-adm-legislation-api';
import lscache from 'lscache';

class UploadAdmLegislationCostumer {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement> | undefined = undefined,
		private readonly admLegislationId = '',
	) {}

	get _user(): UserClientProtocol {
		const user = lscache.get('user') as UserClientProtocol;

		return user;
	}

	async post(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: true
			};
		}

		const file = this.inputs.item(2).files?.item(0);

		if (!file) {
			return {
				error: true
			};
		}

		const fileName = file.name ?? '';
    const description = this.inputs.item(0).value;
		const date = this.inputs.item(1).value;

		const user = this._user;

		let newAdmLegislationPlan: ElementarySchoolDecentWorkType[] = [];

		await UploadAdmLegislationApi.post({
			file,
      description,
			date,
			name: fileName,
			userId: user.id ?? '',
			userName: user.userName
		}).then(response => {
			newAdmLegislationPlan = response;
		}).catch(e => {
			//
		});

		return newAdmLegislationPlan;
	}

	async put(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: false
			};
		}

		const file = this.inputs.item(0).files?.item(0);

		if (!file) {
			return {
				error: false
			};
		}

		const fileName = file.name ?? '';

		let newAdmLegislationPlan: ElementarySchoolDecentWorkType[] = [];

		await UploadAdmLegislationApi.put({
			file,
			name: fileName,
			weeklyPlanId: this.admLegislationId,
		}).then(response => {
			newAdmLegislationPlan = response;
		});

		return newAdmLegislationPlan;
	}
}

export default UploadAdmLegislationCostumer;
