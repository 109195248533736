import type StudentMonetaryResType from '../../../../class/types/student-monetary-aid'

export default function formatCF(studentMonetaryAid: StudentMonetaryResType, twoMonths: 'twoMonths-one' | 'twoMonths-two' | 'twoMonths-tree' | 'twoMonths-for'): number {
  const frequency = studentMonetaryAid.frequency;
  let sumMissed = 0;
  let sumPresence = 0;

  frequency.map(value => {
    sumMissed += Number(value.frequency[twoMonths].missed);
    sumPresence += Number(value.frequency[twoMonths].presence);

    return value;
  });

  const porcentual = Math.floor(sumPresence / (sumMissed + sumPresence) * 100);

  return porcentual;
}
