
import type CardReportCustomerProtocol from '../types/CardReport/cardReport-custumer-protocol';
import type { StudentType } from '../types/Student/student-factory-protocol';
import CardReportFactory from './cardReport-factory';
import cardReportApi from './cardReportApi';

class CardReportCustomer implements CardReportCustomerProtocol {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly students: StudentType[],
		private readonly twoMonthsName: string,
		private readonly roomId: string,
		private readonly matterId: string
	) {}

	async post(): Promise<Array<StudentType | undefined>> {
		const cardReportFactory = new CardReportFactory(this.inputs, this.students, this.twoMonthsName, this.matterId);

		const cardReport = cardReportFactory.create();

		if (!cardReport) {
			return [];
		}

		const newCardReport = await cardReportApi.post(cardReport, this.roomId);
		return newCardReport;
	}
}

export default CardReportCustomer;
