import React from 'react';

import './style.scss';
import { BiLoaderAlt } from 'react-icons/bi';

interface LoadContentProps {
  isLoading: boolean
  style?: {
    button?: React.CSSProperties
    container?: React.CSSProperties
  }
}

export default function LoadContent({isLoading, style}: LoadContentProps): JSX.Element {
  if (!isLoading) {
    return <></>;
  }

  return (
    <div className='loading-container' style={style?.container}>
      <BiLoaderAlt className='loading-icon' style={style?.button}/>
    </div>
  )
}
