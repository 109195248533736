/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState } from 'react';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';
import './style.scss';
import InputSelect from '../../../components/input-select';
import RoomsApi from '../../../class/Room/RoomsApi';
import StudentMonetaryAidContentTable from '../../../components/student-monetary-aid-files/content-table';
import type StudentMonetaryResType from '../../../class/types/student-monetary-aid';
import LoadContent from '../../../components/Load-content';

interface StudentMonetaryAidInfoProps {
  studentSelected: StudentType | undefined
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

// room > matter > twoMonths > frequency

export default function StudentMonetaryAidInfo({ display, setDisplay, studentSelected }: StudentMonetaryAidInfoProps): React.ReactElement {
  const [rooms, setRooms] = useState<StudentMonetaryResType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [year, setYear] = useState<string>('');

  const searchRoomByStudent = () => {
    setIsLoading(true);
    setRooms([]);
    RoomsApi.getAllRoomByStudent(year, studentSelected?.id ?? '').then((response) => {
      setRooms(response);
      setIsLoading(false);
    }).catch(e => {
      setIsLoading(false);
    });
  }

  const huddleBackButton = () => {
    setDisplay(false);
    setRooms([]);
  };

  const getYear = () => {
    const date = new Date();
    return String(date.getFullYear());
  }

  return (
    <div className={`student-monetary-aid-info-container display-${String(display)}`}>
      <div className='student-monetary-aid-info-header'>
        <div className='student-monetary-aid-back-button'>
          <button onClick={huddleBackButton}>Voltar</button>
        </div>
        <div className='student-monetary-aid-student-name'>
          <h1>Nome do aluno</h1>
          <span>{studentSelected?.studentName}</span>
        </div>
        <div className='student-monetary-aid-info-input-container'>
          <InputSelect
            options={(() => {
              const numbers = [];
              for (let i = 1999; i < 2200; i++) {
                numbers.push(String(i));
              }

              return numbers;
            })()}
            title='Ano'
            cb={(option) => {
              setYear(option);
            }}
            defaultValue={'ano letivo'}
          />
        </div>
        <div className='student-monetary-aid-submit-button'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    width: 20,
                    height: 20,
                  }
                }}
              />
            )
            : (
              <button onClick={searchRoomByStudent}>Buscar</button>
            )}
        </div>
      </div>
      <div className='table-student-monetary-rooms-table'>
        {isLoading
          ? (
            <LoadContent isLoading={isLoading} />
          )
          : (rooms.length === 0
            ? (
              <div className='empty-element'>
                <img src={require('./img/undraw_Data_re_80ws.png')} alt="" />
              </div>
            )
            : rooms.map(room => {
              return (
                <StudentMonetaryAidContentTable
                  studentMonetary={room}
                  key={room.id}
                />
              )
            }))}
      </div>
    </div>
  );
}
