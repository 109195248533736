/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useReducer } from 'react';

export const initialState = {
  readingFluency: [],
}

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET/READING_FLUENCY':
      return {
        readingFluency: [...action.readingFluency]
      }
    default:
      return state
  }
}

const ReadingFluencyLevelContext = createContext({});

export default ReadingFluencyLevelContext;
