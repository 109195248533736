import { type FrequencyByTwoMonths } from '../../../class/types/IndividualCardReporting';

export default function SumJustificationOfAbsences(frequency: FrequencyByTwoMonths) {
  let justificationOfAbsences =
    frequency['twoMonths-one'].justificationOfAbsences +
    frequency['twoMonths-two'].justificationOfAbsences +
    frequency['twoMonths-tree'].justificationOfAbsences +
    frequency['twoMonths-for'].justificationOfAbsences;

  if (!justificationOfAbsences) {
    justificationOfAbsences = 0;
  }

  return justificationOfAbsences;
}
