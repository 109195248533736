/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-return-assign */

import React, { useEffect, useState } from 'react';
import type { CardReportType, StudentType } from '../../../class/types/Student/student-factory-protocol';
import './style.scss';

interface ContentTableActionType {
  index: number
  student: StudentType
  cardReport: Record<string, string>
  setStudents: React.Dispatch<React.SetStateAction<any[]>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
}

export default function ContentTableCardReport({
  index,
  student,
  cardReport
}: ContentTableActionType): React.ReactElement {
  const [inputOne, setInputOne] = useState('');
  const [inputTwo, setInputTwo] = useState('');
  const [inputTree, setInputTree] = useState('');
  const [inputFor, setInputFor] = useState('');
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const verifyValue = (index: number) => {
    if (!student.matter) {
      return '';
    }

    const cardRecord: CardReportType | undefined = student.matter.find(matter => matter.matterId === cardReport.matterId);

    if (typeof cardRecord === 'undefined') {
      return '';
    }

    try {
      const twoMonths = cardRecord.twoMonths.find(twoMonths => cardReport.twoMonths === twoMonths.twoMonthsName);

      if (typeof twoMonths === 'undefined') {
        return '';
      }

      return twoMonths.cardReport[index];
    } catch {
      return '';
    }
  };

  // const returnAverage = () => (Number(verifyValue(0)) + Number(verifyValue(1)) + Number(verifyValue(2)) + Number(verifyValue(3))) / 4;

  const returnAverage = () => {
    const value = [Number(verifyValue(0)), Number(verifyValue(1)), Number(verifyValue(2)), Number(verifyValue(3))];

    let count = 0;

    const mount = value.reduce((previous, value) => {
      if (!value) {
        return previous;
      }

      count++;
      return previous += value;
    }, 0);

    return isNaN(Math.round(mount / count)) ? 0 : Math.round(mount / count);
  }

  useEffect(() => {
    setInputOne(verifyValue(0));
    setInputTwo(verifyValue(1));
    setInputTree(verifyValue(2));
    setInputFor(verifyValue(3));
  }, [cardReport]);

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [student]);

  return (
		<div className={`content-table-element-cardReport display-animation-${displayAnimation}`}>
			<ul className='content-table-ul'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{student.studentName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<input
						className='input-content-table-ul-li'
						name={student.id}
						id={student.id}
						type='text' max='10'
						value={inputOne}
						onChange={e => {
						  const { target } = e;
						  setInputOne(target.value);
						}}
					/>
				</li>
				<li className='content-table-ul-li'>
					<input
						className='input-content-table-ul-li'
						name={student.id}
						id={student.id}
						type='text' max='10'
						value={inputTwo}
						onChange={e => {
						  const { target } = e;
						  setInputTwo(target.value);
						}}
					/>
				</li>
				<li className='content-table-ul-li'>
					<input
						className='input-content-table-ul-li'
						name={student.id}
						id={student.id}
						type='text' max='10'
						value={inputTree}
						onChange={e => {
						  const { target } = e;
						  setInputTree(target.value);
						}}
					/>
				</li>
				<li className='content-table-ul-li'>
					<input
						className='input-content-table-ul-li'
						name={student.id}
						id={student.id}
						type='text'
						value={inputFor}
						onChange={e => {
						  const { target } = e;
						  setInputFor(target.value);
						}}
					/>
				</li>
				<li className='content-table-ul-li'>
					<h2>{returnAverage()}</h2>
				</li>
			</ul>
		</div>
  );
}
