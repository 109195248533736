import React from 'react';
import Table from '../Table';
import './style.scss';

interface StrategyProps {
  id: string
  display: string
  roomInformation: Record<string, string>
}

export default function StudentObserverContainer({ id, roomInformation, display }: StrategyProps): React.ReactElement {
  return (
		<div className={'actions-container'} id={id}>
			<Table display={display} roomInformation={roomInformation} />
		</div>
  );
}
