import React from 'react';
import StudentExamsTable from '../table';

interface StudentExamsProps {
  display: string
}

export default function StudentExams({display}: StudentExamsProps): React.ReactElement {
  return (
    <div>
      <StudentExamsTable display={display} />
    </div>
  );
}
