/* eslint-disable no-return-assign */
import { type ReadingFluencyLevelResType } from '../../../class/types/ReadingFluencyLevel/reading-fluency-level-api-protocol';

export default function getSeries(readingFluency: ReadingFluencyLevelResType[]) {
  const series: number[] = [];

  if (!readingFluency || readingFluency.length === 0) {
    return [0, 0, 0, 0, 0]
  }

  const label = [
    'Conhece o alfabeto ou parte dele',
    'Soletrando',
    'Processo de Silabação',
    'Leitura Fluente',
    'Fluente +',
  ].map(text => {
    return text.replaceAll(' ', '').trim().toLowerCase();
  });

  label.forEach(text => {
    let count = 0;
    readingFluency.forEach(value => {
      if (text.includes(value.readingFluencyLevel.replaceAll(' ', '').trim().toLocaleLowerCase())) {
        count++;
      }
    });
    series.push(count);
  });

  const sum = series.reduce((sum, value) => sum += value, 0);

  const newSeries = series.map((num) => {
    return Math.round((num / sum) * 100);
  }) ?? [0, 0, 0, 0, 0];

  return newSeries;
}
