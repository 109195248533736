/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-type-arguments */
/* eslint-disable react/jsx-key */

import React, { useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import filterElements from './service/filter';
import './style.scss';
import type DigitalHTTPIResProtocol from '../../class/types/DigitalHTTPI/digitalHTTPI-protocol';

interface SearchElementType {
  elements: DigitalHTTPIResProtocol[] | undefined
  elementFiltered: DigitalHTTPIResProtocol[]
  setElementFiltered: React.Dispatch<React.SetStateAction<DigitalHTTPIResProtocol[]>>
}

export default function SearchDigitalHTTPI({ elements, elementFiltered, setElementFiltered }: SearchElementType): React.ReactElement {
  const input = useRef<any>();
  const [options, setOptions] = useState<any[]>([]);

  const hundleSubmit = () => {
    const inputElement = input.current;

    if (!inputElement) {
      return;
    }

    if (!elements) {
      return;
    }

    if (!inputElement.value) {
      setElementFiltered(elements);
      setOptions([]);
      return;
    }

    const elementFiltered = filterElements(elements, inputElement.value);

    setOptions([]);
    setElementFiltered(elementFiltered);
  };

  const removeRepeats = (array: any[]) => {
    return array.filter((value, index) => array.indexOf(value) === index);
  }

  const hundleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!elements) {
      return;
    }

    const elementFiltered = filterElements(elements, value);

    setOptions(removeRepeats(elementFiltered));
    hundleSubmit()
  };

  const hundleClick = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    const li = e.target as HTMLLIElement;

    if (!li.textContent) {
      return;
    }

    input.current.value = li.textContent;

    if (!elements) {
      return;
    }

    const elementFiltered = filterElements(elements, li.textContent);

    if (elementFiltered.length > 1) {
      setOptions(removeRepeats(elementFiltered));
    } else {
      setOptions([]);
    }

    setElementFiltered(elementFiltered);
  };

  return (
    <form method='POST' className='search-element-digital-httpi'>
      <div className='input-content'>
        <FiSearch size='20' className='search-element' />
        <input type='text' placeholder='Procurar por nome' ref={input} onChange={hundleChange} />
      </div>
      <div className='options-list'>
        <ul className='list-element' onClick={hundleClick}>
          {options
            ? options.map(element => <li>{element.issue.name}</li>)
            : ''}
        </ul>
      </div>
    </form>
  );
}
