/* eslint-disable @typescript-eslint/member-ordering */
import type { UserProtocol } from '../types/User/User-factory-protocol';

class UserFactory implements UserFactory {
	constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {}

	create(): UserProtocol {
		return {
			userName: this.inputs[0].value,
			email: this.inputs[1].value,
			password: this.inputs[2].value,
			date: this.getDate()
		};
	}

	createPut(): any {
		return {
			userName: this.verifyValue(0),
			email: this.verifyValue(1)
		};
	}

	createLogin(): UserProtocol {
		return {
			email: this.inputs[0].value,
			password: this.inputs[1].value
		};
	}

	private getDate(): string {
		const date = new Date();

		return date.toLocaleString('UTC', {
			dateStyle: 'short'
		});
	}

	verifyValue(index: number): string {
		return this.inputs[index].value
			? this.inputs[index].value
			: this.inputs[index].placeholder;
	}
}

export default UserFactory;
