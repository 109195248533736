import axios from 'axios';
import type IbgeApiProtocol from '../types/IBGEType/IBGEApiTypes';
import type { StateType } from '../types/IBGEType/IBGEApiTypes';

class IbgeApi implements IbgeApiProtocol {
	async getUf(): Promise<StateType[]> {
		let state: StateType[] = [];

		await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then(response => {
			state = response.data as StateType[];
		});

		return state;
	}
}

export default new IbgeApi();
