export default function compareString(s1: string, s2: string): number {
 const str1 = s1.trim().toLowerCase().split(' ');
 const str2 = s2.trim().toLowerCase().split(' ');

 let arrMin = [];
 let arrMax = [];

 if (str1.length > str2.length) {
  arrMax = str1;
  arrMin = str2;
 } else {
  arrMax = str2;
  arrMin = str1;
 }

 let count = 0

 for (let i = 0; i < arrMax.length; i++) {
  if (arrMin.includes(arrMax[i])) {
    count++;
  }
 }

 return count / arrMax.length * 100;
}
