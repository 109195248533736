import React from 'react';
import Table from '../Table';
import './style.scss';

interface StrategyProps {
  id: string
  display: string
  title: string
  titleEdit: string
  titleAdd: string
  titleInfo: string
  emptyText: string
  type: 'general'
}

export default function AdmHours({ id, display, title, titleEdit, titleAdd, titleInfo, type, emptyText }: StrategyProps): React.ReactElement {
  return (
		<div className={'adm-hours-container'} id={id}>
			<Table
        display={display}
        title={title}
        titleEdit={titleEdit}
        titleAdd={titleAdd}
        titleInfo={titleInfo}
        type={type}
        emptyText={emptyText}
      />
		</div>
  );
}
