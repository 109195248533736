
import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { UserProtocol } from '../types/User/User-factory-protocol';
import type RoomObserverType from '../types/RoomObserver';

class Rooms {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  async post(roomId: string, matterId: string, twoMonths: string, roomObserver: RoomObserverType): Promise<RoomObserverType[]> {
    let newRoomObserver: RoomObserverType[] = [];

    await axios.post(this.href + `/room/observer/${roomId}/${matterId}/${twoMonths}`, roomObserver).then(response => {
      newRoomObserver = response.data as RoomObserverType[];
    });

    return newRoomObserver
  }

  async get(roomId: string, matterId: string, twoMonths: string): Promise<RoomObserverType[]> {
    let newRoomObserver: RoomObserverType[] = [];

    await axios.get(this.href + `/room/observer/${roomId}/${matterId}/${twoMonths}`).then(response => {
      newRoomObserver = response.data;
    });

    return newRoomObserver;
  }

  async put(
    roomId: string,
    matterId: string,
    twoMonths: string,
    roomObserverId: string,
    roomObserver: RoomObserverType): Promise<RoomObserverType[]> {
      let newRoomObserver: RoomObserverType[] = [];

      await axios.put(this.href + `/room/observer/${roomId}/${matterId}/${twoMonths}/${roomObserverId}`, roomObserver).then(response => {
        newRoomObserver = response.data as RoomObserverType[];
      });

      return newRoomObserver;
    }

    async delete(roomId: string, matterId: string, twoMonths: string, roomObserverId: string): Promise<RoomObserverType[]> {
      let newRoomObserver: RoomObserverType[] = [];

      await axios.delete(this.href + `/room/observer/${roomId}/${matterId}/${twoMonths}/${roomObserverId}`).then(response => {
        newRoomObserver = response.data as RoomObserverType[];
      });

      return newRoomObserver;
    }
}

export default new Rooms();
