// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function Table(): React.ReactElement {
  const { roomName } = useParams();

  return (
    <div className={'table-container-classroom-monitoring'}>
      <header className='header-container-classroom-monitoring'>
        <h1>Protocolo de Acompanhamento de Sala - {roomName}</h1>
      </header>
      <main className='table-classroom-monitoring'>
        <section className='title-table table-row'>
          <div className='main-table-row'>
            <ul className='table-row-element'>
              <li>
                <h1>1. Aspectos organizacionais</h1>
              </li>
              <li>
                <h1>Indicadores</h1>
              </li>
            </ul>
          </div>
        </section>
        <section className='table-row row'>
          <div className='title-of-row'>
            <h1>1.1 Otimização doTempo</h1>
          </div>
          <div className='main-table-row'>
            <ul className='table-row-element'>
              <li>
                <h1>A aula tem início nos primeiros 5 minutos e os atrasos, caso ocorram, são administrados.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>A aula é realizada mediante ritmo estimulante e adequado ao nível de dificuldade proposto.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor evita a ocorrência de interrupções em sala de aula, não desperdiçando o tempo de ensino e de aprendizagem.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor usa tom de voz adequado e atenta-se a todos os alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor permite ausências dos alunos (ir ao banheiro ou tomar água) de forma coerente.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O ritmo de instrução é ajustado para atender aos alunos que aprendem com maior ou menor facilidade.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Os alunos que não terminam as atividades durante a aula recebem orientação especial, para que se mantenham no ritmo da turma.</h1>
              </li>
            </ul>
          </div>
        </section>
        <section className='table-row row'>
          <div className='title-of-row'>
            <h1>1.2 Otimização do Espaço</h1>
          </div>
          <div className='main-table-row'>
            <ul className='table-row-element'>
              <li>
                <h1>O ambiente mantém-se organizado.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>A disposição dos alunos está adequada à aula.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor usa critérios coerentes de agrupamento dos alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor circula pela sala de aula.</h1>
              </li>
            </ul>
            <ul className='table-row-element green-row'>
              <li>
                <h1>Os professores utilizam espaços escolares além da sala de aula:</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Biblioteca/Sala de Leitura.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Quadra.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Laboratório de Informática.</h1>
              </li>
            </ul>
          </div>
        </section>
        <section className='title-table table-row'>
          <div className='main-table-row'>
            <ul className='table-row-element'>
              <li>
                <h1>2. Aspectos pedagógicos</h1>
              </li>
              <li>
                <h1>Indicadores</h1>
              </li>
            </ul>
          </div>
        </section>
        <section className='table-row row'>
          <div className='title-of-row'>
            <h1>2.1 Utilização de Material Pedagógico</h1>
          </div>
          <div className='main-table-row'>
            <ul className='table-row-element green-row'>
              <li>
                <h1>Verificar se o professor:</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Usa o Caderno do Professor para desenvolvimento da aula.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Aplica as Situações de Aprendizagem proposta no Livro do Aluno.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Utiliza o Livro do Aluno como orientação para lição de casa.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Utiliza o Livro do Aluno como registro em sala de aula.</h1>
              </li>
            </ul>
            <ul className='table-row-element green-row'>
              <li>
                <h1>O professor utiliza outros materiais para o planejamento das aulas para desenvolvimento do Currículo Oficial:</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Livro didático.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Outros recursos pessoais (revistas, jornais etc.).</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Recursos digitais.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Material concreto.</h1>
              </li>
            </ul>
          </div>
        </section>
        <section className='table-row row'>
          <div className='title-of-row'>
            <h1>2.2 Processos e estratégias de ensino-aprendizagem</h1>
          </div>
          <div className='main-table-row'>
            <ul className='table-row-element green-row'>
              <li>
                <h1>Verificar se o professor:</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Demonstra conhecimento do material e domínio do conteúdo.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Informa aos alunos sobre os objetivos da aula e as atividades a serem realizadas.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Considera os conhecimentos prévios dos alunos no desenvolvimento dos conteúdos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Promove contextualização entre o conteúdo e as vivências do aluno.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Apresenta explicações claras sobre as atividades e situações de aprendizagem.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Acompanha o desenvolvimento das atividades de forma interativa.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Propõe a aplicação das habilidades desenvolvidas na sala de aula em outros contextos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Adequá a linguagem à informação ou explicação quando não compreendida pelos alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Oferece atividades para reforço da aprendizagem.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Propicia atividades diversificadas que garantam que a recuperação continua aconteça de forma satisfatória.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Estimula e dá clareza ao aluno da importância de se
                  fazer atividades em casa para a sua aprendizagem.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Propõe atividades de apoio aos alunos com diferentes níveis de aprendizagem, diversificando estratégias para atender as necessidades destes alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Apresenta devolutivas construtivas aos alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Trabalha em conjunto com o professor auxiliar, na proposição e realização de ações docentes que respondam às necessidades dos alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Registra o progresso do aluno em Ficha individual durante o processo de recuperação.</h1>
              </li>
            </ul>
          </div>
        </section>
        <section className='title-table table-row'>
          <div className='main-table-row'>
            <ul className='table-row-element'>
              <li>
                <h1>3. Relação Professor/aluno</h1>
              </li>
              <li>
                <h1>Indicadores</h1>
              </li>
            </ul>
          </div>
        </section>
        <section className='table-row row'>
          <div className='title-of-row'>
            <h1></h1>
          </div>
          <div className='main-table-row'>
            <ul className='table-row-element'>
              <li>
                <h1>O professor reforça e valoriza as intervenções dos alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor realiza mediação de conflitos de forma positiva.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Percebe-se atitudes de respeito mútuo entre professor e alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Percebe-se atitudes de respeito mútuo entre aluno e aluno.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor propicia oportunidades de trabalho cooperativo entre os alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor passa aos alunos orientações precisas, promovendo concentração e autonomia.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor favorece a participação do aluno com atividades da oralidade.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Propõe atividades extraclasse para estímulo da leitura e da escrita.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor estimula a participação e o envolvimento de todos os alunos na aprendizagem.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>O professor demonstra ter alta expectativa quanto à aprendizagem de todos os alunos.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Os alunos questionam ideias e pontos de vista de forma apropriada.</h1>
              </li>
            </ul>
            <ul className='table-row-element'>
              <li>
                <h1>Os alunos demonstram entusiasmo pela matéria.</h1>
              </li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
}
