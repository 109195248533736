/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import lscache from 'lscache';
import type ReadingFluencyLevelApiProtocol from '../types/ReadingFluencyLevel/reading-fluency-level-api-protocol';
import { type ReadingFluencyLevelType, type ReadingFluencyLevelResType } from '../types/ReadingFluencyLevel/reading-fluency-level-api-protocol';
import config from '../../config/config';

class ReadingFluencyLevelApi implements ReadingFluencyLevelApiProtocol {
  private readonly user = lscache.get('user');
	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  private get href() {
		return config.apiUrl;
	}

  async post(readingFluencyId: string, readingFluencyLevel: ReadingFluencyLevelType): Promise<ReadingFluencyLevelResType[]> {
    let newReadingFluencyLevel: ReadingFluencyLevelResType[] = [];

    await axios.post(`${this.href}/readingFluency/level/${readingFluencyId}`, readingFluencyLevel).then((response) => {
      newReadingFluencyLevel = response.data as ReadingFluencyLevelResType[]
    })

    return newReadingFluencyLevel
  }

  async getAll(readingFluencyLevelId: string): Promise<ReadingFluencyLevelResType[]> {
    let newReadingFluencyLevel: ReadingFluencyLevelResType[] = [];

    await axios.get(`${this.href}/readingFluency/level/${readingFluencyLevelId}`).then(response => {
      newReadingFluencyLevel = response.data as ReadingFluencyLevelResType[];
    });

    return newReadingFluencyLevel
  }
}

export default new ReadingFluencyLevelApi();
