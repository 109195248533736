import React, {useRef, useEffect, useState} from 'react';
import './style.scss';
import { type MonitoringSheetGoalType } from '../../class/types/municipal-education-plan-monitoring-sheet';
import MunicipalEducationPlanMonitoringSheetCostumer from '../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-costumer';
import { useParams } from 'react-router-dom';
import MunicipalEducationPlanMonitoringSheetApi from '../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import SaveAlert from '../../components/save-alert';

interface MunicipalEducationPlanMonitoringSheetGoalTextProps {
  display: string
  goalInfo: MonitoringSheetGoalType | undefined
}

export default function MunicipalEducationPlanMonitoringSheetGoalText({ display, goalInfo }: MunicipalEducationPlanMonitoringSheetGoalTextProps): React.ReactElement {
  const goalText = useRef<HTMLTextAreaElement | null>(null);
  const deadLine = useRef<HTMLTextAreaElement | null>(null);
  const observation = useRef<HTMLTextAreaElement | null>(null);

  const [displaySaveAlert, setDisplaySaveAlert] = useState<boolean>(false);

  const {id} = useParams();

  const save = () => {
    if (!id || !goalInfo?.id) {
      return;
    }

    if (!goalText.current || !deadLine.current || !observation.current) {
      return;
    }

    const municipalEducationPlanMonitoringSheetCostumer = new MunicipalEducationPlanMonitoringSheetCostumer(undefined);

    municipalEducationPlanMonitoringSheetCostumer.postGoal(id, goalInfo?.id, goalText.current, deadLine.current, observation.current).then(response => {
      huddleDisplaySaveAlert();
    }).catch(e => {
      //
    });
  }

  useEffect(() => {
    if (!id || !goalInfo?.number) {
      return;
    }

    if (!goalText.current) {
      return;
    }

    const monitoringGoalSheet = new MunicipalEducationPlanMonitoringSheetApi();

    monitoringGoalSheet.getAllGoal(id).then(response => {
      const goal = response[Number(goalInfo?.number) - 1];

      if (!goal.goal) {
        (goalText.current as HTMLTextAreaElement).value = '';
        (deadLine.current as HTMLTextAreaElement).value = '';
        (observation.current as HTMLTextAreaElement).value = '';
        return;
      }

      (goalText.current as HTMLTextAreaElement).value = goal.goal.goalText;
      (deadLine.current as HTMLTextAreaElement).value = goal.goal.deadLine;
      (observation.current as HTMLTextAreaElement).value = goal.goal.observation;
    }).catch((e) => {

    });
  }, [goalInfo, display]);

  const huddleDisplaySaveAlert = () => {
    if (displaySaveAlert) {
      return;
    }
    setDisplaySaveAlert(true);

    setTimeout(() => {
      setDisplaySaveAlert(false);
    }, 3000);
  }

  return (
    <div className='municipal-education-plan-monitoring-sheet-goal-text'>
      <main className='municipal-education-plan-monitoring-sheet-goal-text-main'>
        <header className='municipal-education-plan-monitoring-sheet-goal-text-header'>
          <h1>Texto da Meta {goalInfo?.number}</h1>
          <button className='save-button' onClickCapture={save}>Salvar</button>
        </header>
        <SaveAlert display={displaySaveAlert} setDisplay={setDisplaySaveAlert}>Texto da Meta atualizado</SaveAlert>
        <table className='municipal-education-plan-monitoring-sheet-goal-text-table'>
          <thead className='municipal-education-plan-monitoring-sheet-goal-text-table-header'>
            <th className='background-gray'>Meta</th>
            <th className='background-gray' colSpan={3}>Texto da Meta</th>
            <th className='background-gray'>Prazo</th>
            <th className='background-gray'>Observações/Relato Sintético (opcional)</th>
          </thead>
          <tbody className='municipal-education-plan-monitoring-sheet-goal-text-table-body'>
            <tr>
              <td align='center'>{goalInfo?.number}</td>
              <td className='column-small table-td-textarea' colSpan={3}>
                <textarea ref={goalText}/>
              </td>
              <td className='table-td-textarea'>
                <textarea ref={deadLine} />
              </td>
              <td className='table-td-textarea'>
                <textarea ref={observation} />
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    </div>
  );
}
