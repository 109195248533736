/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete, MdDescription } from 'react-icons/md';
import './style.scss';
import { type ElementarySchoolDecentWorkType } from '../../class/types/Upload_ElementarySchoolDecenteWork';
import { DisplayFileInfoTree } from '../DisplayFile';
import { FaEye } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import RuleVerification from '../rule-verification';
import UserAPI from '../../class/User/UserAPI';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import uploadElementarySchoolProjectApi from '../../class/UploadElementarySchoolProjects/upload-project-api';
import LoadContent from '../Load-content';
import { HiCheck } from 'react-icons/hi';
import { IoMdDownload } from 'react-icons/io';

interface ContentTableProjectType {
  index: number
  elementarySchoolProject: ElementarySchoolDecentWorkType
  setElementarySchoolProject: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayDescribeElementarySchoolProject: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  type: 'elementary-school' | 'kindergartenSchool' | 'workshop'
  setElement: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType>>
  setDisplayProject: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayReadChecking: React.Dispatch<React.SetStateAction<boolean>>
}
export default function ContentTableElementaryProject({
  setDisplayDescribeElementarySchoolProject,
  setElementarySchoolProject,
  setDisplayReadChecking,
  setDisplayEditElement,
  setDisplayProject,
  setElementId,
  setElement,
  elementarySchoolProject,
  index,
  type
}: ContentTableProjectType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const user = lscache.get('user') as UserClientProtocol;

  if (!user || !user.id) {
    return <></>;
  }
  const huddleDelete = () => {
    if (!user || !user.id) {
      return <></>;
    }
    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      uploadElementarySchoolProjectApi.delete(String(elementarySchoolProject.id), elementarySchoolProject.location.fileName).then(response => {
        if (permission === 'professor' || permission === 'nutricionista') {
          const projects = response.filter((value: any) => {
            if (value.issuer?.userId === user.id || value.visibility === 'Publico') {
              return true;
            }
            return false;
          });
          setElementarySchoolProject(projects);
          setIsLoading(false);
          return;
        }
        setElementarySchoolProject(response);
        setIsLoading(false);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(elementarySchoolProject.id));
    setDisplayEditElement(true);
  };

  const hundleDisplayReadChecking = () => {
    setElementId(String(elementarySchoolProject.id));
    setDisplayReadChecking(true);
  }

  const huddleDescribe = () => {
    setElementId(String(elementarySchoolProject.id));

    setDisplayDescribeElementarySchoolProject(true);
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(elementarySchoolProject);
    setDisplayProject(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 15) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [elementarySchoolProject]);

  if (!elementarySchoolProject) {
    return <></>
  }

  return (
    <div className={`content-table-projects display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={elementarySchoolProject.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(elementarySchoolProject.issuer?.userName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {elementarySchoolProject.classRoom}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(elementarySchoolProject.date)}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
          {elementarySchoolProject.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
        <li>
          <a href={elementarySchoolProject.location ? elementarySchoolProject.location.url : ''} target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <MdDescription size='20' style={{ color: '#118951' }} onClick={huddleDescribe} />
          </li>
        </RuleVerification>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <HiCheck size='20' onClickCapture={hundleDisplayReadChecking} className='icon-hicheck-element' />
          </li>
        </RuleVerification>
        {user.id === elementarySchoolProject.issuer?.userId
          ? (
            <>
              <li className='content-table-ul-li' onClick={huddleEdit}>
                <FiEdit2 size='20' style={{ color: '#118951' }} />
              </li>
              {isLoading
                ? (
                  <li>
                    <LoadContent
                      isLoading={isLoading}
                      style={{
                        button: {
                          fontSize: 20,
                          color: '#ff0000'
                        },
                        container: {
                          width: 20,
                        }
                      }}
                    />
                  </li>
                )
                : (
                  <li className='header-table-ul-li' onClick={huddleDelete}>
                    <MdDelete size='20' style={{ color: '#ff0000' }} />
                  </li>

                )}
            </>
          )
          : (
            user.permission?.rule === 'coordenador'
              ? (
                <>
                  <li className='content-table-ul-li' onClick={huddleEdit}>
                    <FiEdit2 size='20' style={{ color: '#118951' }} />
                  </li>
                  {isLoading
                    ? (
                      <li>
                        <LoadContent
                          isLoading={isLoading}
                          style={{
                            button: {
                              fontSize: 20,
                              color: '#ff0000'
                            },
                            container: {
                              width: 20,
                            }
                          }}
                        />
                      </li>
                    )
                    : (
                      <li className='header-table-ul-li' onClick={huddleDelete}>
                        <MdDelete size='20' style={{ color: '#ff0000' }} />
                      </li>

                    )}
                </>
              )
              : (
                <>
                  <li></li>
                  <li></li>
                </>
              )
          )}
      </ul>
    </div>
  );
}
