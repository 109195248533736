/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import { MdDelete } from 'react-icons/md';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DisplayFileInfoTree } from '../DisplayFile';
import LoadContent from '../Load-content';
import { FiEdit2 } from 'react-icons/fi';
import { IoMdDownload } from 'react-icons/io';
import lscache from 'lscache';
import './style.scss';
import type FileAdmCalendarType from '../../class/types/AdmLegislation';
import uploadAdmCalendarApi from '../../class/UploadAdmCalendar/upload-adm-calendar-api';

interface ContentTableCalendarType {
  index: number
  admCalendar: FileAdmCalendarType
  setAdmCalendar: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayCalendar: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setElement: React.Dispatch<React.SetStateAction<FileAdmCalendarType>>
  type: 'general'
}
export default function ContentTableAdmCalendar({
  setAdmCalendar,
  admCalendar,
  setDisplayEditElement,
  setDisplayCalendar,
  setElementId,
  setElement,
  index,
  type
}: ContentTableCalendarType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const huddleDelete = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    uploadAdmCalendarApi.delete(type, String(admCalendar.id), admCalendar.location.fileName).then(response => {
      setAdmCalendar(response);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(admCalendar.id));
    setDisplayEditElement(true);
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(admCalendar);
    setDisplayCalendar(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 30) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [admCalendar]);

  return (
    <div className={`content-table-element-adm-calendar display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={admCalendar.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(admCalendar.fileName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {admCalendar.issuer?.userName}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(admCalendar.date ?? '')}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
          {admCalendar.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
        <li>
          <a href={admCalendar.location ? admCalendar.location.url : ''} target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
      </ul>
    </div>
  );
}
