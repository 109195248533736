import React, { useState } from 'react';
import './styles.scss';
import type NewWritingTheNameType from '../../../class/types/new-writing-hypothesi/new-writing-the-name';
import { BsCheckLg } from 'react-icons/bs';
import NewWritingTheName from '../../../class/new-writing-hypothesi/new-writing-the-name/new-writing-the-name';
import { useParams } from 'react-router-dom';
import LoadContent from '../../Load-content';

export interface NewWritingTheNameContentTableProps {
  writingTheName: NewWritingTheNameType
  setWritingTheName: React.Dispatch<React.SetStateAction<NewWritingTheNameType[]>>
  roomInfo: any
  index: number
}

export default function NewWritingTheNameContentTable({ writingTheName, setWritingTheName, roomInfo, index }: NewWritingTheNameContentTableProps): React.ReactElement {
  const [writingTheNameBol, setWritingTheNameBol] = useState<boolean>(writingTheName.writingTheName);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const onClickWritingTheNameBtn = () => {
    const writingBol = !writingTheNameBol;
    setWritingTheNameBol(writingBol);

    const newWritingTheName = new NewWritingTheName();

    if (!id || !roomInfo.twoMonths || !writingTheName) {
      return;
    }
    setIsLoading(true);
    newWritingTheName.post(id, roomInfo.twoMonths, writingTheName.studentId, writingBol).then(response => {
      setIsLoading(false);
      setWritingTheName(response);
    }).catch(e => {
      //
    })
  }

  return (
    <div className='new-writing-the-name-content-table'>
      <ul>
        <li>{index + 1}</li>
        <li>{writingTheName.studentName}</li>
        <li className='writing-the-name-btn-content'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  container: {
                    width: 40,
                    height: 40,
                  }
                }}
              />
            )
            : (
              <button className='writing-the-name-btn' onClickCapture={onClickWritingTheNameBtn}>
                <BsCheckLg className={`icon display-${String(writingTheNameBol)}`} size={10} />
              </button>
            )}
        </li>
      </ul>
    </div >
  );
}
