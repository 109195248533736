
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-floating-promises */
import type { ActionResponseProtocol } from '../../../class/types/Action/ActionAPI-protocol';
import React, { useEffect, useState } from 'react';
import ContentTableAction from '../../../components/actions-files/ContentTable-action';
import filterBySituation from './services/filterBySituation';
import FormEditElement from '../FormEditElement-action';
import FormAddElement from '../FormAddElement-action';
import EmptyComponent from '../../../components/Empty-component';
import InputContent from '../../../components/actions-files/Input-content';
import { useParams } from 'react-router-dom';
import filterById from './services/filterById';
import ActionAPI from '../../../class/Action/ActionAPI';
import './style.scss';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [actions, setActions] = useState<ActionResponseProtocol[]>([]);
  const [elementId, setElementId] = useState<string>();

  const { id } = useParams();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(true);
  };

  const setActionsCustumer = (actions: ActionResponseProtocol[]) => {
    const newAction = filterById(actions, String(id));
    setActions(newAction);
  };

  const filterBySituationCustumer = (situation: string) => {
    const newAction = filterBySituation(situation, String(id));

    setActions(newAction);
  };

  useEffect(() => {
    ActionAPI.get().then(response => {
      setActionsCustumer(response);
    });
  }, []);

  return (
    <div className='table-container-action'>
      <FormAddElement
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        displayCreateNewElement={displayCreateNewElement}
        setActionsCostumer={setActionsCustumer}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        elementId={elementId}
        setDisplayEditElement={setDisplayEditElement}
        setActionsCustumer={setActionsCustumer}
      />
      <div className='btn-create-new-action-container'>
        <button onClick={hundleCreateNewElement}>Criar nova ação</button>
      </div>
      <div className='filter-content-action'>
        <h1>Filtros</h1>
        <ul className='filter'>
          <InputContent
            title='Situação'
            filterBySituationCustumer={filterBySituationCustumer}
            contents={['inicializado', 'em andamento', 'finalizado', 'não Inicializado']} />
        </ul>
      </div>
      <div className='header-table-element-action'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Ações</li>
          <li className='header-table-ul-li'>Entregas</li>
          <li className='header-table-ul-li'>Responsável</li>
          <li className='header-table-ul-li'>Prazo (inicio-fim)</li>
          <li className='header-table-ul-li'>Situação</li>
        </ul>
      </div>
      <main className='main-table-action'>
        {display === 'true'
          ? (
            actions.length === 0
              ? <EmptyComponent>click em (criar nova ação) para criar um ação</EmptyComponent>
              : actions.map((action, index) => (
                <ContentTableAction
                  action={action}
                  index={index}
                  setActionsCostumer={setActionsCustumer}
                  setElementId={setElementId}
                  setDisplayEditElement={setDisplayEditElement}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
