/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import PsychologistApi from '.';
import type MedicalRecordType from '../types/Psychologist';
import { type PsychologistAnamnese } from '../types/Psychologist';
import PsychologistFactory from './psychologist-factory';

export default class PsychologistCostumer {
  constructor(private readonly input: HTMLInputElement | undefined = undefined, private readonly textArea: HTMLTextAreaElement | undefined = undefined) {}

  async postMedicRecord(studentId: string) {
    const psychologistFactory = new PsychologistFactory();

    if (!this.input || !this.textArea) {
      return [];
    }

    const medicalRecord = psychologistFactory.createMedicRecord(this.input, this.textArea);

    const psychologistApi = new PsychologistApi();

    let newMedicalRecord: MedicalRecordType[] = [];

    await psychologistApi.postMedicalRecord(studentId, medicalRecord).then(response => {
      newMedicalRecord = response;
    }).catch(e => {
      //
    });

    return newMedicalRecord
  }

  async putMedicalRecord(studentId: string, medicalRecordId: string) {
    const psychologistFactor = new PsychologistFactory()

    if (!this.input || !this.textArea) {
      return;
    }

    const medicalRecord = psychologistFactor.createMedicRecord(this.input, this.textArea);

    const psychologistApi = new PsychologistApi();

    let newMedicalRecord: MedicalRecordType[] = [];

    psychologistApi.putMedicalRecord(studentId, medicalRecordId, medicalRecord).then(response => {
      newMedicalRecord = response;
    }).catch(e => {
      //
    });

    return newMedicalRecord;
  }

  async postAnamnese(inputs: NodeListOf<HTMLInputElement>, textArea: NodeListOf<HTMLTextAreaElement>) {
    const psychologistFactory = new PsychologistFactory();

    const medicalRecord = psychologistFactory.createAnamnese(inputs, textArea);

    const psychologistApi = new PsychologistApi();

    let anamnese: PsychologistAnamnese[] = [];
    await psychologistApi.post(medicalRecord).then(response => {
      anamnese = response;
    }).catch(e => {
      //
    });

    return anamnese;
  }

  async putAnamnese(studentId: string, inputs: NodeListOf<HTMLInputElement>, textArea: NodeListOf<HTMLTextAreaElement>) {
    const psychologistFactory = new PsychologistFactory();

    const anamnese = psychologistFactory.createAnamnese(inputs, textArea);

    const psychologistApi = new PsychologistApi();

    let newAnamnese: PsychologistAnamnese[] = [];

    await psychologistApi.putStudent(anamnese, studentId).then(response => {
      newAnamnese = response;
    }).catch(e => {

    });

    return newAnamnese
  }
}
