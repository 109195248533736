/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-mixed-spaces-and-tabs */

import React from 'react';
import './style.scss';
import DisplayDocument from '../../../components/displayDocument';
import formatFileName from '../../../services/formatFileName';
import { FaEye, FaFileAlt } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';

interface FormEditStudentReportProps {
  setDisplayStudentReportInfo: React.Dispatch<React.SetStateAction<boolean>>
  displayStudentReportInfo: boolean
  element: any
}

export default function StudentReportDisplayInformation({
  setDisplayStudentReportInfo,
  displayStudentReportInfo,
  element
}: FormEditStudentReportProps): JSX.Element {
  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  return (
    <div className={`kindergarten-school-student-report-information display-${String(displayStudentReportInfo)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          Relatório do Estudante
        </h1>
        <button onClick={() => {
          setDisplayStudentReportInfo(false);
        }}>Voltar</button>
      </div>
      <div className='main-monthly-plan-display-information' id='main-monthly-plan-display-information'>
        <div className="decent-work-information">
          <h1><FaFileAlt className='file-description-icon' /> <span>{formatFileName(element.fileName)}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1><MdDateRange className='file-description-icon' /> <span>{formatDate(element.date ?? ' ')}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1><FaEye className='file-description-icon' /> <span>{element.checked ? `Visualizações: ${Object.keys(element.visualizations ?? { generic: 'generic' }).length ?? 1}` : 'Não visualizado'}</span></h1>
        </div>
        {!element.visualizations
          ? ''
          : (
            <div className="decent-work-information">
              <h1><span>Visualizações:</span></h1>
              <ul className='visualization-list'>
                {Object.keys(element.visualizations).map(key => (
                  <li key={key}>{element.visualizations[key].user} <span>visualizou em {element.visualizations[key].date}</span></li>
                ))}
              </ul>
            </div>
          )
        }
        <div className="decent-work-information">
          <h1>Emissor: <span>{element.issuer?.userName}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1>Data de emissão: <span>{element.issuer?.emissionDate}</span></h1>
        </div>
        <div className='adm-hours-information-frame-content'>
          <DisplayDocument type={element.type} url={element.location.url} />
        </div>
      </div>
    </div>
  );
}
