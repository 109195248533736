/* eslint-disable multiline-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import type DigitalHTTPIResProtocol from '../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import clearAllInputs from './service/clear-all-inputs';
import displayError from '../../../class/services/displayError';
import LoadContent from '../../../components/Load-content';
import lscache from 'lscache';
import './style.scss';
import WorkshopDigitalHTTPICostumer from '../../../class/workshopDigital-HTTPI/WorkshopDigitalHTTPI-custumer';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setWorkshopDigitalHTTPI: React.Dispatch<React.SetStateAction<DigitalHTTPIResProtocol[]>>
}

export default function FormAddElement({ displayCreateNewElement, setDisplayCreateNewElement, setWorkshopDigitalHTTPI }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const user = lscache.get('user') as UserClientProtocol;
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    if (inputs.item(0).value.length === 0) {
      displayError(inputs.item(0), 'Preencha o campo antes de prosseguir!');
      return;
    }

    const workshopDigitalHTTPI = new WorkshopDigitalHTTPICostumer(inputs);

    setIsLoading(true);
    workshopDigitalHTTPI.post().then(response => {
      if (response.error) {
        displayError(inputs[0], response.error);
        setIsLoading(false)
        return;
      }

      if (user.permission?.rule === 'professor') {
        const responseFilter = response.filter((value: any) => value.issue.id === user.id);

        setWorkshopDigitalHTTPI(responseFilter);
        clearAllInputs();
        setDisplayCreateNewElement(false);
        setIsLoading(false)
        return;
      }

      setWorkshopDigitalHTTPI(response);
      clearAllInputs();
      setDisplayCreateNewElement(false);
      setIsLoading(false)
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-workshop-digital-httpi display-${displayElement}`}>
      <form method='POST' className='form-add-element' id='form-add-element-student' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicionar HTTPI
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='student-date'>Data:</label>
            <input className='input-element' type='month' id='student-date' placeholder='nome do aluno' pattern={'[0-0]{2}-[0-9]{4}-[0-9]'} />
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
