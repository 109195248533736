/* eslint-disable multiline-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import displayErro from '../../../class/services/displayError';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import type DigitalHTTPIResProtocol from '../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import ElementarySchoolDigitalHTTPICostumer from '../../../class/ElementaryDigital-HTTPI/ElementarySchoolDigitalHTTPI-custumer';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';

interface FormAddElementProps {
  displayEditElement: boolean
  elementarySchoolDigitalHTTPI: DigitalHTTPIResProtocol[]
  elementId: string
  setDisplayEditElement: (_boolean: React.SetStateAction<boolean>) => void
  setElementarySchoolDigitalHTTPI: React.Dispatch<React.SetStateAction<DigitalHTTPIResProtocol[]>>
}

export default function FormEditElement({ displayEditElement, elementId, elementarySchoolDigitalHTTPI, setDisplayEditElement, setElementarySchoolDigitalHTTPI }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dateInput = useRef<HTMLInputElement | null>(null)

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const user = lscache.get('user') as UserClientProtocol;
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const studentCustumer = new ElementarySchoolDigitalHTTPICostumer(inputs);

    setIsLoading(true);
    studentCustumer.put(elementId).then((response: any) => {
      if (response.error) {
        displayErro(inputs[0], response.error);
        setIsLoading(false)
        return;
      }

      if (user.permission?.rule === 'professor') {
        const responseFilter = response.filter((value: any) => value.issue.id === user.id)
        setElementarySchoolDigitalHTTPI(responseFilter);
        clearAllInputs();
        setDisplayEditElement(false);
        setIsLoading(false)
        return;
      }

      setElementarySchoolDigitalHTTPI(response);
      clearAllInputs();
      setDisplayEditElement(false);
      setIsLoading(false)
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayEditElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    setDisplayElement(displayEditElement);

    elementarySchoolDigitalHTTPI.forEach(value => {
      if (value.id === elementId) {
        (dateInput.current ? dateInput.current : { value: '' }).value = value.date;
      }
    })
  }, [displayEditElement]);

  return (
    <div className={`form-add-container-elementary-school-digital-httpi display-${displayElement}`}>
      <form method='POST' className='form-add-element' id='form-add-element-student' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Editar HTTPI
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='student-date'>Data:</label>
            <input className='input-element' ref={dateInput} type='month' id='student-date' placeholder='nome do aluno' />
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
