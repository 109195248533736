import type { ActionResponseProtocol } from '../../../../class/types/Action/ActionAPI-protocol';

export default function filterById(actions: ActionResponseProtocol[], id: string): ActionResponseProtocol[] {
  const newActions: ActionResponseProtocol[] = [];
  actions.forEach((action: ActionResponseProtocol) => {
    if (action.strategyId === id) {
      newActions.push(action);
    }
  });

  return newActions;
}
