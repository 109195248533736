/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { type StudentExamsType, type ExamResponseType, type QuestionElementType } from '../../../class/types/student-exams/student-exams-protocol';
import { useParams } from 'react-router-dom';
import StudentExamsResponseApi from '../../../class/Student-Exams/student-exams-response';
import generatePDF from './service/generetePDF';
import { IoCloseOutline } from 'react-icons/io5';

interface StudentExamsResponseProps {
  responses: ExamResponseType[]
  display: boolean
  studentExam: StudentExamsType | undefined
  huddleDisplay: () => void
  setResponse: React.Dispatch<React.SetStateAction<ExamResponseType[]>>
}

export default function StudentExamsResponse({ display, responses, studentExam, setResponse, huddleDisplay }: StudentExamsResponseProps): React.ReactElement {
  const {id} = useParams();
  const [displayExamResponse, setDisplayExamResponse] = useState<boolean>(false);
  const [displayExamResponseIndex, setDisplayExamResponseIndex] = useState<number>(0);
  const [studentResponse, setStudentResponse] = useState<Array<Record<string, any>>>([]);

  const huddleGetResult = () => {
    const studentExamResponse = new StudentExamsResponseApi();

    if (!id) {
      return;
    }

    studentExamResponse.getResult(id).then(result => {
      setResponse(result);
    }).catch(e => {
      //
    });
  }

  const resetResult = () => {
    const studentExamResponse = new StudentExamsResponseApi();

    if (!id) {
      return;
    }

    studentExamResponse.resetResult(id).then(result => {
      setResponse(result);
    }).catch(e => {
      //
    });
  }

  const print = () => {
    if (!studentExam) {
      return;
    }

    generatePDF(responses, studentExam, returnPorcentual);
  }

  const returnPorcentual = (corrects: number, errors: number): string => {
    const porcentual = Math.floor((corrects / (corrects + errors)) * 100);
    if (!porcentual) {
      return '0%';
    }
    return `${porcentual}%`;
  }

  const huddleClick = (index: number | undefined) => {
    setDisplayExamResponse(!displayExamResponse);

    if (typeof index === 'undefined') {
      return;
    }
    setDisplayExamResponseIndex(index);
    filterResponse(index);
  }

  const filterResponse = (index: number) => {
    if (responses.length === 0) {
      return;
    }
    if (!responses[index].response) {
      return;
    }

    const data: Array<Record<string, any>> = [];

    responses[index].response.forEach(response => {
      if (!response) {
        return;
      }

      if (!response.elements) {
        return
      }
      const element = response.elements[Object.keys(response.elements).pop() ?? ''];

      let option = ''

      Object.keys(element.options ?? {}).forEach(key => {
        if (!element.options) {
          return;
        }
        if (element.options[key].selected) {
          option = element.options[key].content as string
          data.push({
            correct: (response as any).correct.isCorrect ?? false,
            option,
            elements: response.elements,
          });
        }
      });
    });

    setStudentResponse(data);
  }

  return (
    <div className={`student-exams-response display-${String(display)}`}>
      <div className={`exam-student-response-information display-${String(displayExamResponse)}`}>
        <div className='exam-student-response-information-header'>
          <h1>Gabarito</h1>
          <IoCloseOutline color={'#fff'} style={{
            cursor: 'pointer',
          }} onClickCapture={() => {
            huddleClick(undefined);
          }} size={25} />
        </div>
        <div className='test-answer-sheet-student-name'>
          <h1>Nome do aluno: {!responses[displayExamResponseIndex] ? '' : responses[displayExamResponseIndex].studentName}</h1>
        </div>
        <div className='test-answer-sheet-container'>
          {studentResponse.map((response, index) => {
            return (
              <div className={`test-answer-sheet-element ${response.correct ? 'correct' : 'not-correct'}`}>
                <div className='test-answer-sheet-header'>
                  <h1>Questão {index + 1}</h1>
                </div>
                <div className='test-answer-sheet'>
                  <h1>Resposta: {response.option}</h1>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='student-exams-response-header'>
        <h1>Respostas dos alunos</h1>
        <div className='btn-container'>
          <button onClickCapture={resetResult}>Reiniciar Prova</button>
          <button onClickCapture={huddleGetResult}>Gerar Resultado</button>
          <button onClickCapture={print}>Imprimir</button>
          <button onClickCapture={huddleDisplay}>Voltar</button>
        </div>
      </div>
      <div className='student-exams-response-table'>
        <div className='student-exams-response-table-header'>
          <ul>
            <li>Nome do aluno</li>
            <li>Ra</li>
            <li>Acertos</li>
            <li>Erros</li>
            <li>Porcentual</li>
            <li>Tempo</li>
          </ul>
        </div>
        {responses.map((response, index) => {
          return (
            <ul className='student-response-content' onClickCapture={() => {
              huddleClick(index)
            }}>
              <li>{response.studentName}</li>
              <li>{response.ra}</li>
              <li>{response.correctCount?.correct}</li>
              <li>{response.correctCount?.errors}</li>
              <li>{returnPorcentual(response.correctCount?.correct ?? 0, response.correctCount?.errors ?? 0)}</li>
              <li>{response.time ? response.time : '--'}</li>
            </ul>
          );
        })}
      </div>
    </div>
  )
}
