/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import type NotificationType from '../../../class/types/notification';
import './style.scss';
import NotificationBellTableContent from '../../../components/notification-bell/table-content';
import NotificationCustumer from '../../../class/Notification/NotificationCustumer';

interface NotificationBellProps {
  notifications: NotificationType[]
  displayNotification: boolean
  setDisplayNotification: React.Dispatch<React.SetStateAction<boolean>>
  setNotifications: React.Dispatch<React.SetStateAction<NotificationType[]>>
}

export default function NotificationsBell({notifications, displayNotification, setDisplayNotification, setNotifications}: NotificationBellProps): React.ReactElement {
  const openAllNotification = () => {
    const notificationCustumer = new NotificationCustumer();

    notificationCustumer.postVisualizationAllNotification(notifications).then(response => {
      setNotifications(response);
    });
  }

  return (
    <div className={`notification-bell-element display-${String(displayNotification)}`}>
      <div className='notification-bell-element-header'>
        <button className='close-button' onClick={() => {
          setDisplayNotification(false);
        }}>Fechar</button>
      </div>
      <div className='notification-bell-element-list'>
        <div className='notification-bell-element-list-title'>
            <h1>Notificações</h1>
            <button className='open-all-notification' onClickCapture={openAllNotification}>
              Abrir todas notificações
            </button>
        </div>
        <div className='notification-bell-list'>
          {notifications.map(notification => {
            return <NotificationBellTableContent
              notification={notification}
              setNotifications={setNotifications}
              key={`1231-${notification.id ?? ''}`}
            />
          })}
        </div>
      </div>
    </div>
  );
}
