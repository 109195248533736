/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import LoadingContent from '../../../../../components/Load-content';
import Chart from 'react-apexcharts';
import './style.scss';

interface DashboardProps {
  display: boolean
  series: number[]
  categories: string[]
  isLoading: boolean
  colors: string[]
  option: string
  roomName: string
}

export default function Dashboard({ display, series, isLoading, categories, colors, option, roomName }: DashboardProps): React.ReactElement {
  return (
    <div className={`dashboard-writing-level-record-final-result-component display-${display}`}>
      {!isLoading
        ? (
          <>
            <Chart
              className='graphic-content'
              type='pie'
              width={700}
              series={series}
              options={{
                labels: categories,
                colors,
                legend: {
                  fontSize: '16rem',
                  offsetY: 70
                },
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: ['#000000'],
                  width: 0.5,
                  dashArray: 0
                },
                chart: {
                  width: '50px',
                  height: '50px'
                },
                title: {
                  align: 'center',
                  margin: 30,
                  style: {
                    fontSize: '2rem'
                  },
                  text: `Hipótese de Escrita | ${option} - ${roomName}`
                }
              }}
            />
            <Chart
              className='graphic-content'
              type='bar'
              width={700}
              options={{
                chart: {
                  width: 800,
                  type: 'bar'
                },
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: ['#000000'],
                  width: 1,
                  dashArray: 0
                },
                colors,
                dataLabels: {
                  enabled: true,
                  formatter(val) {
                    return `${val}%`;
                  },
                  offsetY: 10,
                  style: {
                    fontSize: '12px',
                    colors: ['#304758']
                  }
                },
                legend: {
                  show: false
                },
                plotOptions: {
                  bar: {
                    columnWidth: '45%',
                    distributed: true,
                    dataLabels: {
                      position: 'top' // Top, center, bottom
                    }
                  }
                },
                xaxis: {
                  categories,
                  labels: {
                    style: {
                      colors: '#000000',
                      fontWeight: '600',
                      fontSize: '10px'
                    }
                  }
                }
              }}
              series={[
                {
                  data: series
                }
              ]}
            />
          </>
        )
        : <LoadingContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '100px'
              }
            }}
          />}
    </div>
  );
}
