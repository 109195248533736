import React from 'react';
import Table from '../Table';
import './style.scss';

interface StrategyProps {
  id: string
  display: string
  roomInformation: any
}

export default function RoomObserver({ id, display, roomInformation }: StrategyProps): React.ReactElement {
  return (
		<div className={'room-observer'} id={id}>
			<Table display={display} roomInformation={roomInformation} />
		</div>
  );
}
