/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState, useRef } from 'react';
import StudentAPI from '../../../class/Student/StudentAPI';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';
import './style.scss';
import ContentTableStudentMonetaryAid from '../../../components/student-files/student-monetary-aid-files';
import searchStudent from '../service/searchStudent';
import studentIsEqual from '../service/studentIsEqual';
import { FiSearch } from 'react-icons/fi';
import StudentMonetaryAidInfo from '../student-monetary-aid-info';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [students, setStudents] = useState<StudentType[]>([]);
  const [studentsFiltered, setStudentsFiltered] = useState<StudentType[]>([]);
  const [studentSelected, setStudentSelected] = useState<StudentType>();
  const [listOption, setListOption] = useState<StudentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayStudentMonetaryAidInfo, setDisplayStudentMonetaryAidInfo] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const huddleInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    if (target.value.length === 0) {
      setListOption([]);
      setStudentsFiltered(students);
      return;
    }

    const studentFiltered = searchStudent(target.value, students);

    if (studentFiltered.length === 0) {
      setStudentsFiltered(students);
      setListOption([]);
      return
    }

    const isEqual = studentIsEqual(target.value, studentsFiltered);

    if (isEqual.isEqual) {
      setStudentsFiltered(studentFiltered);
      setListOption([]);
      return;
    }

    setStudentsFiltered(studentFiltered);
    setListOption(studentFiltered);
  };

  const hundleClickLi = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const { target } = e;

    if (!inputRef.current) {
      return;
    }

    const textContent = (target as HTMLLIElement).textContent ?? ''

    inputRef.current.value = textContent;

    const studentFiltered = searchStudent(textContent, students);
    const isEqual = studentIsEqual(textContent, studentFiltered);
    if (isEqual.isEqual) {
      setStudentsFiltered(studentFiltered);
      setListOption([]);
      return;
    }

    setListOption(studentFiltered);
    setStudentsFiltered(studentFiltered);
  }

  useEffect(() => {
    if (display === 'false') {
      return;
    }
    setIsLoading(true)
    StudentAPI.getAllStudentByMonetaryAid().then(response => {
      setIsLoading(false)
      setStudents(response);
      setStudentsFiltered(response);
    });
  }, [display]);

  return (
    <div className='student-monetary-aid-table-container'>
      <StudentMonetaryAidInfo
        display={displayStudentMonetaryAidInfo}
        setDisplay={setDisplayStudentMonetaryAidInfo}
        studentSelected={studentSelected}
      />
      <div className='student-monetary-aid-title'>
        <h1>Alunos que Recebem Bolsa Familia</h1>
      </div>
      <form className='student-monetary-aid-filters'>
        <div className='input-content'>
          <FiSearch size='20' className='search-element' />
          <input type="text" placeholder='nome do aluno' onChange={huddleInputSearch} ref={inputRef} />
        </div>
        <div className='student-monetary-aid-list-option'>
          <ul className='student-monetary-aid-list-option'>
            {listOption.map(student => {
              return <li onClick={hundleClickLi}>{student.studentName}</li>
            })}
          </ul>
        </div>
      </form>
      <div className='student-monetary-aid-header'>
        <ul className='student-monetary-aid-table'>
          <li className='student-monetary-aid-li'>
            Nº
          </li>
          <li className='student-monetary-aid-li'>
            Nome do Aluno
          </li>
          <li className='student-monetary-aid-li'>
            Responsavel
          </li>
          <li className='student-monetary-aid-li'>
            RA
          </li>
          <li className='student-monetary-aid-li'>
            Bairro
          </li>
          <li className='student-monetary-aid-li'>
            UF
          </li>
          <li className='student-monetary-aid-li'>
            Situação
          </li>
        </ul>
      </div>
      {isLoading
        ? <LoadContent
          isLoading={isLoading}
        />
        : (
          <div className='table-container-student-monetary-aid'>
            {studentsFiltered.map((student, index) => {
              return <ContentTableStudentMonetaryAid
                setStudentSelected={setStudentSelected}
                setDisplayStudentMonetaryAidInfo={setDisplayStudentMonetaryAidInfo}
                student={student}
                index={index}
                key={student.id}
              />
            })}
          </div>
        )
      }
    </div>
  )
}
