/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-alert */

import React, { useState, useEffect } from 'react';
import lscache from 'lscache';
import RecoverByEmail from '../../class/RecoverByEmail';
import { useParams } from 'react-router-dom';
import FormRecoverPasswordElement from '../../components/recover-password';
import './style.scss';
import VerifyPassword from './service/varifyPassword';

export default function RecoverPasswordContainer() {
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const { accessCode } = useParams();

  const huddleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    if (!VerifyPassword.verifyPassword(inputs.item(0), inputs.item(1))) {
      return;
    }

    if (!accessCode) {
      return;
    }

    RecoverByEmail.resetPassword(email, accessCode, inputs.item(1).value).then(() => {
      lscache.remove('user');
      window.location.href = window.location.origin;
    }).catch(e => {
      alert('algo não esperado ocorreu, por favor reinicio o processo ou tente mais tarde');
    });
  };

  useEffect(() => {
    const user = lscache.get('user');

    if (!user) {
      return;
    }

    setEmail(user.email);
  }, []);

  return (
		<div className='recover-password-container'>
			<div className='form-login-content'>
				<FormRecoverPasswordElement huddleSubmit={huddleSubmit} displayForm={displayForm} />
			</div>
		</div>
  );
}
