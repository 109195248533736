/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState, useEffect, useRef } from 'react';
import type { RoomType } from '../../../class/types/Room/Rooms-protocol';
import ContentTableRoom from '../../../components/rooms-files/ContentTable-room';
import FormEditElement from '../FormEditElement-rooms';
import FormAddElement from '../FormAddElement-rooms';
import EmptyComponent from '../../../components/Empty-component';
import RoomsFilter from '../service/rooms-filter';
import BackToTop from '../../../components/back-to-top/back-to-top';
import RoomsApi from '../../../class/Room/RoomsApi';
import './style.scss';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomType[]>([]);
  const [roomsFiltered, setRoomsFiltered] = useState<RoomType[]>([]);
  const [element, setElement] = useState<RoomType>();
  const mainElement = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);

  const huddleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    if (!rooms) {
      return;
    }

    const roomFilter = new RoomsFilter(inputs, rooms);

    const findings = roomFilter.searchElement();

    if (!findings) {
      setRoomsFiltered(rooms);
      return;
    }

    setRoomsFiltered(findings);
  };

  useEffect(() => {
    if (display === 'false') {
      return;
    }
    setIsLoading(true);
    RoomsApi.getAllRoomInfo().then(response => {
      setIsLoading(false);
        setRooms(response);
        setRoomsFiltered(response);
      });
  }, [display]);

  useEffect(() => {
    setRoomsFiltered(rooms);
  }, [rooms])

  return (
    <div className='table-container-rooms'>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setRoms={setRoomsFiltered}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        setDisplayEditElement={setDisplayEditElement}
        setRooms={setRooms}
        rooms={rooms}
        element={element}
      />
      <div className='btn-create-new-rooms-container'>
        <button onClick={huddleCreateNewElement}>Criar nova sala</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome da sala' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='escola' />
        </div>
        <div className='search-date-input-content input-content'>
          <label htmlFor='search-date-input'>Ano Letivo:</label>
          <input type='text' id='search-date-input' />
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Prof responsável</li>
          <li className='header-table-ul-li'>Escola</li>
          <li className='header-table-ul-li'>Ano letivo</li>
          <li></li>
          <li></li>
        </ul>
      </div>
      {!isLoading
        ? (
          <main className='main-table' ref={mainElement}>
            {display === 'true'
              ? (
                roomsFiltered?.length === 0
                  ? <EmptyComponent>click em (criar uma nova sala) para criar uma sala </EmptyComponent>
                  : roomsFiltered?.map((room: any, index: number) => (
                    <ContentTableRoom
                      index={index}
                      room={room}
                      setDisplayEditElement={setDisplayEditElement}
                      setElement={setElement}
                      setRoomCostumer={setRoomsFiltered}
                      key={String(room.id)}
                    />
                  ))
              )
              : ''}
          </main>
        )
        : <LoadContent isLoading={isLoading} />}
    </div>
  );
}
