/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';

import uploadKindergartenSchoolDecentWorkApi from '../../class/UploadKindergartenSchoolSchoolDecentWork/upload-decent-work-api';
import { type ElementarySchoolDecentWorkType } from '../../class/types/Upload_ElementarySchoolDecenteWork';
import { MdDelete, MdDescription } from 'react-icons/md';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DisplayFileInfoTree } from '../DisplayFile';
import uploadDecentWorkApi from '../../class/UploadDecentWork/upload-decent-work-api';
import RuleVerification from '../rule-verification';
import { FiEdit2 } from 'react-icons/fi';
import { HiCheck } from 'react-icons/hi';
import { FaEye } from 'react-icons/fa';
import UserAPI from '../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import LoadContent from '../Load-content';
import { IoMdDownload } from 'react-icons/io';

interface ContentTableActionType {
  elementarySchoolDecentWork: ElementarySchoolDecentWorkType
  index: number
  type: 'elementary-school' | 'kindergartenSchool'
  setDisplayReadChecking: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayDescribeElementaryDecentWork: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolDecentWork: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayDecentWorkInfo: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setElement: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType>>
}
export default function ContentTableElementaryDecentWork({
  index,
  elementarySchoolDecentWork,
  setElementarySchoolDecentWork,
  setDisplayDescribeElementaryDecentWork,
  setDisplayReadChecking,
  setDisplayEditElement,
  setDisplayDecentWorkInfo,
  setElement,
  setElementId,
  type
}: ContentTableActionType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const huddleDelete = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      if (type === 'kindergartenSchool') {
        uploadKindergartenSchoolDecentWorkApi.delete(String(elementarySchoolDecentWork.id), elementarySchoolDecentWork.location.fileName).then(response => {
          if (permission === 'professor') {
            const decentWork = response.filter((value: any) => value.issuer?.userId === user.id)
            setElementarySchoolDecentWork(decentWork);
            setIsLoading(false);

            return;
          }

          setElementarySchoolDecentWork(response);
          setIsLoading(false);
        }).catch((e) => {
          //
        });
        return;
      }
      uploadDecentWorkApi.delete(String(elementarySchoolDecentWork.id), elementarySchoolDecentWork.location.fileName).then(response => {
        if (permission === 'professor') {
          const decentWork = response.filter((value: any) => value.issuer?.userId === user.id)
          setElementarySchoolDecentWork(decentWork);

          setIsLoading(false);
          return;
        }

        setElementarySchoolDecentWork(response);
        setIsLoading(false);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(elementarySchoolDecentWork.id));
    setDisplayEditElement(true);
  };

  const huddleDescribe = () => {
    setElementId(String(elementarySchoolDecentWork.id));

    setDisplayDescribeElementaryDecentWork(true);
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(elementarySchoolDecentWork);
    setDisplayDecentWorkInfo(true);
  };

  const hundleDisplayReadChecking = () => {
    setElementId(elementarySchoolDecentWork.id ?? '');
    setDisplayReadChecking(true);
  }

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 15) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [elementarySchoolDecentWork]);

  return (
    <div className={`content-table-element-elementary-school-decent-work display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={elementarySchoolDecentWork.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(elementarySchoolDecentWork.issuer?.userName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {elementarySchoolDecentWork.classRoom}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(elementarySchoolDecentWork.date)}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
          {elementarySchoolDecentWork.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
        <li>
          <a href={elementarySchoolDecentWork.location ? elementarySchoolDecentWork.location.url : ''} target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <MdDescription size='20' style={{ color: '#118951' }} onClick={huddleDescribe} />
          </li>
        </RuleVerification>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <HiCheck size='20' onClickCapture={hundleDisplayReadChecking} className='icon-hicheck-element' />
          </li>
        </RuleVerification>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>

          )}
      </ul>
    </div>
  );
}
