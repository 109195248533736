import { type StudentType } from '../types/Student/student-factory-protocol';
import { type StudentObserverType } from '../types/StudentObserver/student-observer-protocol';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';

export default class StudentObserverFactory {
  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly textArea: HTMLTextAreaElement,
    private readonly student: StudentType,
    private readonly issuer: UserClientProtocol,
    ) {}

  create(): StudentObserverType {
    return {
      student: {
        id: this.student.id ?? '',
        name: this.student.studentName,
      },
      observation: this.textArea.value ?? '',
      date: this.inputs[1].value,
      issuer: {
        id: this.issuer.id ?? '',
        name: this.issuer.userName,
        date: this.createDate(),
      }
    }
  }

  createPut(): StudentObserverType {
    return {
      student: {
        id: this.student.id ?? '',
        name: this.student.studentName,
      },
      observation: this.textArea.value ?? '',
      date: this.verifyValue(1),
      issuer: {
        id: this.issuer.id ?? '',
        name: this.issuer.userName,
        date: this.createDate(),
      }
    }
  }

  createDate(): string {
    return (new Date()).toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      dateStyle: 'short',
      timeStyle: 'short',
    })
  }

  verifyValue(index: number): string {
    if (!this.inputs[index]) {
      return ''
    }
    return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
  }
}
