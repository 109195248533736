/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { type ErrorProtocol } from '../types/custumer-protocol';
import type { TagTitleList, TagType } from '../types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import Validator from '../Validator';
import studentWritingLevelRecordApi from './student-writing-level-record-api';
import StudentWritingLRFactory from './student-writing-level-record-factory';

class StudentWritingLevelRecordTagsManager {
	constructor(
    private readonly inputs: NodeListOf<HTMLInputElement> | undefined = undefined,
  ) {}

	async tags(roomId: string): Promise<TagType[]> {
		return await studentWritingLevelRecordApi.getTag(roomId);
	}

	async postTag(roomId: string): Promise<any> {
		if (typeof this.inputs === 'undefined') {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs, false);

		if (validation.init()) {
			return {
				error: true
			};
		}

		const tagObj = new StudentWritingLRFactory(this.inputs).createTagObj();

		return await studentWritingLevelRecordApi.postTag(tagObj, roomId);
	}

  async postTagTitleList(roomId: string, twoMonths: string): Promise<TagTitleList[] | ErrorProtocol> {
    if (!roomId || !this.inputs) {
      return {
        error: true
      }
    }

    const tagFactory = new StudentWritingLRFactory(this.inputs)

    const tagTitleList = tagFactory.createTagTitleList();

    return await studentWritingLevelRecordApi.postTagTitleList(roomId, tagTitleList, twoMonths);
  }

  async postTagToList(roomId: string, tagTitleListId: string, twoMonths: string): Promise<TagType[] | ErrorProtocol> {
    if (!roomId || !this.inputs || !tagTitleListId) {
      return {
        error: true,
      }
    }

    const tagFactory = new StudentWritingLRFactory(this.inputs);

    const tag = tagFactory.createTagObj();

    return await studentWritingLevelRecordApi.postToTagList(roomId, tagTitleListId, tag, twoMonths);
  }
}

export default StudentWritingLevelRecordTagsManager;
