import React from 'react';
import NewWritingTheNameTable from '../table';

export interface NewWritingTheNameProps {
  display: string
  roomInfo: any
}

export default function NewWritingTheName({display, roomInfo}: NewWritingTheNameProps) {
  return (
    <div className='new-writing-the-name-container'>
      <NewWritingTheNameTable display={display} roomInfo={roomInfo} />
    </div>
  );
}
