
import React, { useState } from 'react';
import './style.scss';
import Header from '../../components/Header';
import Nav from '../../components/strategy-of-plan-files/Nav-strategy-of-plans';
import PlansContainer from '../../container/strategy-of-plans-files/plans';
import InformationStrategy from '../../components/strategy-of-plan-files/information-strategy';
import Dashboard from '../../components/strategy-of-plan-files/Dashboard';

type Elements = Array<{
  name: string
  boolean: string
}>;

function Plans() {
  const [elements, setElements] = useState([
    {
      name: 'strategy-1',
      boolean: 'true'
    },
    {
      name: 'strategy-2',
      boolean: 'false'
    },
    {
      name: 'strategy-3',
      boolean: 'false'
    }
  ]);

  const [display1, setDisplay1] = useState<string>('true');
  const [display2, setDisplay2] = useState<string>('false');
  const [display3, setDisplay3] = useState<string>('false');

  const [action, setAction] = useState<any>();

  const hundlesetElements = (elementsParams: Elements) => {
    setElements(elementsParams);
    setDisplay1(elements[0].boolean);
    setDisplay2(elements[1].boolean);
    setDisplay3(elements[2].boolean);
  };

  return (
		<div className='App'>
			<Header />
			<main className='main-container-plans'>
				<Nav setElements={hundlesetElements} elements={elements} />
				<div className='content-containers'>
					<div className='content' id={`display-${display1}`}>
						<PlansContainer id='action-element' display={display1} setAction={setAction} />
					</div>
					<div className='content' id={`display-${display2}`}>
						<InformationStrategy />
					</div>
					<div className='content' id={`display-${display3}`}>
						<Dashboard display={display3} action={action} />
					</div>
				</div>
			</main>
		</div>
  );
}

export default Plans;
