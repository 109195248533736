
import React, { useState } from 'react';
import { FaUserAlt, FaLock } from 'react-icons/fa';

import './style.scss';

interface FormLoginElementProps {
  hundleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export default function FormLoginElement({ hundleSubmit }: FormLoginElementProps): React.ReactElement {
  const [displayPassword, setDisplayPassword] = useState<boolean>(false);

  const redirect = () => {
    window.location.href = window.location.origin + '/registrar';
  };

  return (
		<div className='form-main'>
			<form action='POST' className='form-login-element' onSubmit={e => {
			  hundleSubmit(e);
			}}>
				<div className='form-login-title'>
					<h1>Login de usuário</h1>
				</div>
				<div className='input-login-content'>
					<label htmlFor='user-name'>E-mail: </label>
					<br />
					<div className='input-content'>
						<FaUserAlt size={18} className='incon-user-login' />
						<input type='text' id='user-name' placeholder='exemplo@gmail.com' />
					</div>
				</div>
				<div className='input-login-content'>
					<label htmlFor='user-password'>Senha: </label>
					<br />
					<div className='input-content'>
						<FaLock size={18} className='incon-user-login'/>
						<input type={!displayPassword ? 'password' : 'text'} id='user-password' placeholder='digite a sua senha' />
					</div>
				</div>
				<div className='input-display-password'>
					<div>
						<input type='checkbox' id='display-password' placeholder='digite a sua senha' onInputCapture={() => {
						  setDisplayPassword(!displayPassword);
						}} />
						<label htmlFor='display-password'>mostrar senha?</label>
					</div>
					<a href='/recover/password/by/email'>esqueceu sua senha?</a>
				</div>
				<div className='input-login-content button-login-content'>
					<button type='submit' className='button-login'>Entrar</button>
					<button type='button' className='button-login' onClick={redirect}>Registrar</button>
				</div>
			</form>
		</div>
  );
}
