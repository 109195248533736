import { type JustificationOfAbsencesPut, type JustificationOfAbsencesType } from '../types/JustificationOfAbsences';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';

class JustificationOfAbsencesFactory {
  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly student: any,
    private readonly issuer?: UserClientProtocol
  ) {}

  create(): JustificationOfAbsencesType {
    return {
      justification: this.inputs[0].value,
      days: Number(this.inputs[1].value),
      date: {
        afterDate: this.inputs[3].value,
        beforeDate: this.inputs[4].value,
      },
      student: {
        id: this.student.id ?? '',
        name: this.student.studentName,
      },
      issuer: {
        id: this.issuer?.id ?? '',
        date: this.createDate(),
        userName: this.issuer?.userName ?? '',
      }
    }
  }

  put(): JustificationOfAbsencesPut {
    return {
      justification: this.verifyValue(0),
      days: Number(this.verifyValue(1)),
      date: {
        afterDate: this.verifyValue(3),
        beforeDate: this.verifyValue(4),
      },
      student: {
        id: this.student.id ?? '',
        name: this.student.studentName,
      },
    };
  }

  createDate(): string {
    return (new Date()).toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      dateStyle: 'short',
      timeStyle: 'short',
    })
  }

  verifyValue(index: number): string {
    if (!this.inputs[index]) {
      return ''
    }
    return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
  }
}

export default JustificationOfAbsencesFactory;
