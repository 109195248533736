/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import ElementarySchoolMonthlyPlanCostumer from '../../../class/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlan-custumer';
import { type KindergartenSchoolMonthlyPlanType } from '../../../class/types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';
import clearAllInputs from '../service/clear-all-inputs';
import RuleVerification from '../../../components/rule-verification';
import LoadContent from '../../../components/Load-content';

interface FormAddMonthlyPlanProps {
  setDisplayFormEditMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<KindergartenSchoolMonthlyPlanType[]>>
  displayFormEditMonthlyPlan: boolean
  elementarySchoolMonthlyPlan: KindergartenSchoolMonthlyPlanType[]
  elementId: string
}

export default function FormEditMonthlyPlan({ setDisplayFormEditMonthlyPlan, setElementarySchoolMonthlyPlan, elementId, displayFormEditMonthlyPlan, elementarySchoolMonthlyPlan }: FormAddMonthlyPlanProps): JSX.Element {
  const [element, setElement] = useState<KindergartenSchoolMonthlyPlanType>();
  const dateInput = useRef<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormEditMonthlyPlan(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolMonthlyPlanCostumer = new ElementarySchoolMonthlyPlanCostumer(inputs);

    setIsLoading(true)
    elementarySchoolMonthlyPlanCostumer.put(elementId).then((response) => {
      setElementarySchoolMonthlyPlan(response);
      setDisplayFormEditMonthlyPlan(false);
      clearAllInputs('form-edit-monthly-plan');
      setIsLoading(false)
    }).catch((e) => {
      //
    });
  };

  const formatDate = (_date: string) => {
    const dateList = _date.split('/');

    const newDate = `${dateList[2]}-${dateList[1]}-${dateList[0]}`;

    return newDate;
  }

  useEffect(() => {
    const element = elementarySchoolMonthlyPlan.find((value) => value.id === elementId);
    if (!element) {
      return;
    }

    (dateInput.current as HTMLInputElement).value = formatDate(element.date);

    setElement(element);
  }, [displayFormEditMonthlyPlan]);
  return (
    <div className={`form-edit-kindergarten-monthly-plan display-${String(displayFormEditMonthlyPlan)}`}>
      <div>
        <h1>Plano Mensal - Ensino Infantil</h1>
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-edit-monthly-plan' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label>Escola</label>
          <input type="text" placeholder={element?.school} />
        </div>
        <div className='input-element-content'>
          <label>Professor(a):</label>
          <input type="text" placeholder={element?.teacher} />
        </div>
        <div className='input-element-content'>
          <label>Turma:</label>
          <input type="text" placeholder={element?.class} />
        </div>
        <div className='input-element-content'>
          <label>Tempo Vigente:</label>
          <input type="text" placeholder={element?.currentTime} />
        </div>
        <div className='input-element-content'>
          <label>Ano:</label>
          <input type="text" placeholder={element?.yearn} />
        </div>
        <div className='input-element-content'>
          <label>Objetivo geral:</label>
          <input type="text" placeholder={element?.strategicObjective} />
        </div>
        <div className='input-element-content'>
          <label>Objetivos específicos:</label>
          <input type="text" placeholder={element?.specificObjective} />
        </div>
        <div className='input-element-content'>
          <label>Direitos de Aprendizagem e Desenvolvimento na Educação Infantil:</label>
          <input type="text" placeholder={element?.ldrece} />
        </div>
        <div className='input-element-content'>
          <label>Estratégias/Recursos:</label>
          <input type="text" placeholder={element?.strategyEndResource} />
        </div>
        <div className='input-element-content date'>
          <label>Data:</label>
          <input type="date" ref={dateInput} />
        </div>
        <div className='title'>
          <h1>Metodologia</h1>
        </div>
        <div className='input-element-content'>
          <div className='input-content'>
            <label>Campos de experiência</label>
            <input type="text" placeholder={element?.methodology.composeOfExperience} />
          </div>
          <div className='input-content'>
            <label>Objetivo de Aprendizagem e Desenvolvimento</label>
            <input type="text" placeholder={element?.methodology.learningObjectiveAndDevelopment} />
          </div>
          <div className='input-content'>
            <label>Temas</label>
            <input type="text" placeholder={element?.methodology.topics} />
          </div>
          <div className='input-content'>
            <label>Recurso</label>
            <input type="text" placeholder={element?.methodology.resource} />
          </div>
        </div>
        <RuleVerification rule={['coordenador']}>
          <>
            <div className='title'>
              <h1>Comentário</h1>
            </div>
            <div className='input-element-content'>
              <div className='input-content'>
                <label>Comentário</label>
                <input type="text" placeholder={element?.commentary ?? ''} />
              </div>
            </div>
          </>
        </RuleVerification>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
