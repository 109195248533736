/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import FormRegisterElement from '../../components/form-register-element';
import ValidatorRl from '../../class/User/Validator-register-end-login';
import './style.scss';
import UserManager from '../../class/User/User-manager';

export default function RegisterContainer() {
  const redirect = () => {
    window.location.href = window.location.origin + '/login';
  };

  const hundleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const validation = new ValidatorRl(inputs);

    validation.init().then(async response => {
      if (!response) {
        const userManager = new UserManager(inputs);
        await userManager.register().then(response => {
          if (response.userName) {
            redirect();
          }
        });
      }
    });
  };

  return (
		<div className='register-container'>
			<div className='form-register-content'>
				<FormRegisterElement hundleSubmit={hundleSubmit} />
			</div>
			<div className='img-register-content'>
				<img src={require('./img/undraw_connected_world_wuay.png')} alt='' />
			</div>
		</div>
  );
}
