
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';

export default function filterElements(elements: StudentType[], value: string): any[] {
  const elementFiltered: any[] = [];

  if (value === '') {
    return [];
  }

  elements.forEach(element => {
    const elementSlice = element.ra.content.slice(0, value.length).toLowerCase();

    const valueLowerCase = value.toLowerCase();
    if (elementFiltered.length >= 5) {
      return;
    }

    if (valueLowerCase === element.ra.content.toUpperCase()) {
      return;
    }

    if (valueLowerCase === element.ra.content.toLowerCase()) {
      elementFiltered.push(element);
      return;
    }

    if (valueLowerCase === elementSlice.toLowerCase()) {
      elementFiltered.push(element);
    }
  });

  return elementFiltered;
}
