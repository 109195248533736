import { type StudentType } from '../../types/Student/student-factory-protocol';
import { type MathematicDiagnosticAssessmentObjType } from '../../types/mathematic-diagnostic-assessment';

export default class MathematicDiagnosticAssessmentFactory {
 createMathObject(inputs: NodeListOf<HTMLInputElement>, students: StudentType[]): MathematicDiagnosticAssessmentObjType {
  const mathObject: MathematicDiagnosticAssessmentObjType = {};

  students.forEach(student => {
    if (!student.id) {
      return;
    }

    mathObject[student.id] = {
      studentId: student.id,
      studentName: student.studentName,
      mathematicDiagnostic: [] as string[],
    };

    inputs.forEach(input => {
      if (student.id === input.id) {
       mathObject[student.id].mathematicDiagnostic.push(input.value)
      }
    });
  });

  return mathObject;
 }
}
