/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-floating-promises */
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const img = require('./img/header-img.png');

export default function generatePDF(
  roomName: string,
  twoMonthsOne: string,
  twoMonthsTwo: string,
  typeOne: string,
  typeTwo: string,
  ) {
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      month: 'long',
      year: 'numeric'
    });
  }

  const pdf = new jsPDF({
    orientation: 'portrait',
  });

  const element = document.getElementById('print-graphic-content-writing-hipotese-final-result') as HTMLDivElement;

  html2canvas(element).then(canvas => {
    const imgData = canvas.toDataURL('image/png');

    pdf.setFontSize(10);
    pdf.text(`${twoMonthsOne} - ${twoMonthsTwo}`, 15, 45, {
      maxWidth: 100,
    });
    pdf.text(`${typeOne} - ${typeTwo}`, 15, 50, {
      maxWidth: 100,
    });
    pdf.addImage(img, 'PNG', 15, 5, 180, 0);

    pdf.addImage(imgData, 'PNG', 5, 70, 200, 0);

    pdf.save(`Hipótese de Escrita Comparação ${roomName}.pdf`);
  });
}
