import React from 'react';
import Table from '../Table';
import './style.scss';

interface StrategyProps {
  id: string
  activityInfo: Record<string, string>
}

export default function ProgrammaticContent({ id, activityInfo }: StrategyProps): React.ReactElement {
  return (
		<div className={'matter-container'} id={id}>
			<Table activityInfo={activityInfo} />
		</div>
  );
}
