import React from 'react';
import './style.scss';
import DashboardWritingLevel from '../Dashboard';

// {activityInfo.matterId} {activityInfo.twoMonths}

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  return (
		<div className='table-container-writing-level-record-final-result'>
			<DashboardWritingLevel display={display} />
		</div>
  );
}
