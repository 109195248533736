import { type StudentType } from '../../../../class/types/Student/student-factory-protocol';

export default function studentNameIsEqual(text: string, students: StudentType[]): StudentType | undefined {
  let newStudent: StudentType | undefined;

  const textFormat = text.replaceAll(' ', '').toLowerCase();

  students.forEach((student) => {
    const studentName = student.studentName.replaceAll(' ', '').toLowerCase();
    if (studentName === textFormat) {
      newStudent = student
    }
  });

  return newStudent;
}
