import React, { useState } from 'react';
import './styles.scss';
import { type TextualGenreType } from '../../../class/types/new-writing-hypothesi/textual-genre';
import { MdDelete } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';
import TextualGenreApi from '../../../class/new-writing-hypothesi/textual-genre/textual-genre-api';
import { useParams } from 'react-router-dom';
import LoadContent from '../../Load-content';

interface TextualGenreTableContentProps {
  textualGenre: TextualGenreType
  index: number
  roomInfo: any
  huddleDisplayTextualGenreTagTitle: () => void
  huddleEdit: () => void
  setTextualGenre: React.Dispatch<React.SetStateAction<TextualGenreType[]>>
  setTextualGenreSelected: React.Dispatch<React.SetStateAction<TextualGenreType | null>>
}

export function TextualGenreTableContent({ textualGenre, index, roomInfo, huddleEdit, setTextualGenre, setTextualGenreSelected, huddleDisplayTextualGenreTagTitle }: TextualGenreTableContentProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const edit = () => {
    setTextualGenreSelected(textualGenre);
    huddleEdit();
  }

  const huddleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const tagName = target.tagName

    if (['svg', 'SVG', 'path', 'PATH'].includes(tagName)) {
      return;
    }

    setTextualGenreSelected(textualGenre);
    huddleDisplayTextualGenreTagTitle();
  }

  const huddleDelete = () => {
    if (!id) {
      return;
    }

    const textualGenreApi = new TextualGenreApi();

    setIsLoading(true);
    textualGenreApi.delete(id, roomInfo.twoMonths, textualGenre.id).then(response => {
      setTextualGenre(response);
      setIsLoading(false);
    }).catch(e => {
      //
    })
  }

  return (
    <div className='textual-genre-table-content' onClick={huddleOnClick}>
      <ul className='textual-genre-table-content-ul'>
        <li>{index + 1}</li>
        <li>{textualGenre.genre}</li>
        <li>{textualGenre.date}</li>
        <li className='content-table-ul-li'>
          <FiEdit2 size='20' style={{ color: '#118951' }} onClickCapture={edit} />
        </li>
        <li className='header-table-ul-li' onClickCapture={huddleDelete}>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      fontSize: 20,
                      color: '#ff0000'
                    },
                    container: {
                      width: 20,
                    }
                  }
                }
              />
            )
            : (
              <MdDelete size='20' style={{ color: '#ff0000' }} onClickCapture={huddleDelete} />
            )}
        </li>
      </ul>
    </div>
  )
}
