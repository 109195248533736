import lscache from 'lscache';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import config from '../../config/config';
import axios from 'axios';

export default new (class ATACardReport {
  private readonly user = lscache.get('user') as UserProtocol;
  private get href() {
    return config.apiUrl;
  }

  constructor() {
    try {
      axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
    } catch {
      //
    }
  }

  async getATACardReport(roomId: string, twoMonths: string) {
    let cardReport: any[] = [];
    await axios.get(`${this.href}/get/atacardreport/${roomId}/${twoMonths}`).then(response => {
      cardReport = response.data;
    });

    return cardReport;
  }
})()
