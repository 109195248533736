
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import { useParams } from 'react-router-dom';
import SaveAlert from '../../../components/save-alert';
import finalResultApi from '../../../class/FinalResult/finalResult-api';
import type { FinalResultResType } from '../../../class/types/FinalResult/finalResult-type';
import ContentTableFinalResult from '../../../components/finalResult-files/ContentTable';
import lscache from 'lscache';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import RuleVerification from '../../../components/rule-verification';
import printFinalResultFiles from './service/print-final-result-files';

interface TableProps {
  activityInfo: Record<string, string>
  display: string
}

export default function Table({ activityInfo, display }: TableProps): React.ReactElement {
  const [displaySaveAlert, setDisplaySaveAlert] = useState(false);
  const [finalResults, setFinalResult] = useState<FinalResultResType[]>([]);
  const formRef = useRef<any>();
  const [user, setUser] = useState<UserClientProtocol>();
  const { id, roomName } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement> | any) => {
    if (!id) {
      return;
    }

    finalResultApi.generateFinalResult(id, activityInfo.matterId).then(response => {
      setFinalResult(response);
      huddleDisplaySaveAlert();
    });
  };

  const huddleDisplaySaveAlert = () => {
    if (displaySaveAlert) {
      return;
    }

    setDisplaySaveAlert(true);

    setTimeout(() => {
      setDisplaySaveAlert(false);
    }, 3000);
  };

  const print = () => {
    if (!activityInfo.matterName || !roomName) {
      return;
    }
    printFinalResultFiles(finalResults, activityInfo.matterName, roomName);
  }

  useEffect(() => {
    if (!activityInfo || !id) {
      return;
    }

    finalResultApi.getFinalResult(id, activityInfo.matterId).then(response => {
      setFinalResult(response);
    });
    const user = lscache.get('user') as UserClientProtocol;
    if (user) {
      setUser(user);
    }
  }, [activityInfo]);

  return (
    <div className='table-container-final-result'>
      <SaveAlert display={displaySaveAlert} setDisplay={setDisplaySaveAlert}>Conclusão Final gerado</SaveAlert>
      <div className='header-cardReport'>
        <div className='header-cardReport-info'>
          <h1>Conclusão Final</h1>
          <h1>Materia: {activityInfo.matterName}</h1>
        </div>
        <div className='save-button-cardReport'>
          <RuleVerification rule={['coordenador']}>
            <button type='submit' onClick={huddleSubmit}>Gerar conclusão final</button>
          </RuleVerification>
          <button type='button' onClick={print}>Imprimir</button>
        </div>
      </div>
      <div className='table-container'>
        <div className='header-table-element'>
          <ul className='header-table-ul'>
            <li className='header-table-ul-li'>Nº</li>
            <li className='header-table-ul-li'>Aluno</li>
            <li className='header-table-ul-li'>RA</li>
            <li className='header-table-ul-li'>C</li>
            <li className='header-table-ul-li'>F</li>
            <li className='header-table-ul-li percent-content'>%</li>
            <li className='header-table-ul-li'>F justificadas</li>
            <li className='header-table-ul-li'>Media</li>
          </ul>
        </div>
        <form className='main-table' method='POST' onSubmit={huddleSubmit} ref={formRef}>
          {display === 'true'
            ? (
              finalResults?.length === 0
                ? <EmptyComponent />
                : finalResults?.map((finalResult, index: number) => (
                  <ContentTableFinalResult
                    index={index}
                    cardReport={activityInfo}
                    finalResult={finalResult}
                    key={String(finalResult.studentId)}
                  />
                ))
            )
            : ''}
        </form>
      </div>
    </div>
  );
}
