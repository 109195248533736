import type StrategyCustumerProtocol from '../types/custumer-protocol';
import Validator from '../Validator';
import elementarySchoolMonthlyPlanAPI from './ElementarySchoolMonthlyPlanAPI';
import ElementarySchoolMonthlyPlanFactory from './ElementarySchoolMonthlyPlan-factory';
import {type ElementarySchoolMonthlyPlanType} from '../types/ElementarySchoolMonthlyPlan/ElementaryMonthlyPlanAPI';

class ElementarySchoolMonthlyPlanCostumer implements StrategyCustumerProtocol<ElementarySchoolMonthlyPlanType> {
	private readonly elementarySchoolMonthlyPlanApi = elementarySchoolMonthlyPlanAPI;

	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
	) {}

	async post(): Promise<any> {
		const validation = new Validator(this.inputs);
		if (validation.init()) {
			return [{
				error: true,
			}];
		}

		let matters: ElementarySchoolMonthlyPlanType[] = [];

		const elementarySchoolMonthlyPlanFactory = new ElementarySchoolMonthlyPlanFactory(this.inputs);

		const elementarySchoolMonthlyPlan = elementarySchoolMonthlyPlanFactory.create();

		await this.elementarySchoolMonthlyPlanApi.post(elementarySchoolMonthlyPlan).then((response) => {
			matters = response;
		});

		return matters;
	}

	async put(id: string): Promise<any[]> {
		let elementarySchool: ElementarySchoolMonthlyPlanType[] = [];

		if (!this.inputs) {
			return [];
		}

		const elementarySchoolMonthlyPlanFactory = new ElementarySchoolMonthlyPlanFactory(this.inputs);

		const elementarySchoolPut = elementarySchoolMonthlyPlanFactory.put();

		await this.elementarySchoolMonthlyPlanApi.put(elementarySchoolPut, id).then((response: ElementarySchoolMonthlyPlanType[]) => {
			elementarySchool = response;
		});

		return elementarySchool;
	}
}

export default ElementarySchoolMonthlyPlanCostumer;
