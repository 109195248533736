import displayErrorLogin from '../../../class/services/displayErrorLogin';

class VerifyPassword {
  verifyPassword(password: HTMLInputElement, passwordTwo: HTMLInputElement) {
    let passwordIsValid = true;

    passwordIsValid = this.verifyLength(password, passwordTwo);

    if (password.value !== passwordTwo.value) {
      passwordIsValid = false;
      displayErrorLogin(password, 'as senhas não coincidem');

      return passwordIsValid;
    }

    return passwordIsValid;
  }

  verifyLength(password: HTMLInputElement, passwordTwo: HTMLInputElement): boolean {
    if (password.value.length < 8 || passwordTwo.value.length < 8) {
      displayErrorLogin(password, 'as senha precisa ter no mínimo 8  caracteres');
      return false;
    }

    return true;
  }
}

export default new VerifyPassword();
