import lscache from 'lscache';
import { type UserClientProtocol } from '../../../../class/types/User/UserAPI-protocol';
import compareString from './compareString';
import { type KindergartenSchoolMonthlyPlanType } from '../../../../class/types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';

export default function filterPlanByUser(elements: KindergartenSchoolMonthlyPlanType[]): KindergartenSchoolMonthlyPlanType[] {
  const user = lscache.get('user') as UserClientProtocol;

  if (user.permission?.rule === 'coordenador') {
    return elements;
  }

  const newElements: KindergartenSchoolMonthlyPlanType[] = [];

  elements.map((value) => {
    const porcentual = compareString(value.teacher, user.userName);

    if (porcentual > 50) {
      newElements.push(value);
    }
    return value;
  });

  return newElements;
}
