
import displayErrorLogin from '../services/displayErrorLogin';

import type ValidatorRegisterLoginProtocol from '../types/Validator-register-end-login-protocol';

class ValidatorRl implements ValidatorRegisterLoginProtocol {
	private error = false;

	// Private readonly userManager: UserManagerProtocol;

	constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {
		// This.userManager = new UserManager(inputs);
	}

	async init(): Promise<boolean> {
		this.validation();
		if (this.error) {
			return this.error;
		}

		this.validationLength();
		if (this.error) {
			return this.error;
		}

		if (this.error) {
			return this.error;
		}

		this.passwordValidator();

		return this.error;
	}

	validation(): any {
		try {
			this.inputs.forEach(input => {
				if (this.isEmpty(input.value)) {
					this.error = true;
					displayErrorLogin(input, 'campo esta vazio!');

					throw new Error('campo vazio');
				}
			});
		} catch (e) {
			//
		}

		return this.error;
	}

	validationLength(): any {
		if (this.inputs[0].value.length < 8) {
			displayErrorLogin(this.inputs[0], 'nome de usuário precisa ter no mínimo 8 caracteres');
			this.error = true;
		}

		if (this.inputs[2].value.length < 8) {
			displayErrorLogin(this.inputs[1], 'a senha precisa ser maior que 8 caracteres');
			this.error = true;
		}

		return this.error;
	}

	passwordValidator(): any {
		if (this.inputs[2].value !== this.inputs[3].value) {
			displayErrorLogin(this.inputs[2], 'as senhas não coincidem');
			this.error = true;
		}

		return this.error;
	}

	isEmpty(value: string): boolean {
		return value.length === 0;
	}
}

export default ValidatorRl;
