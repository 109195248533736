import React from 'react';
import Table from '../Table';
import './style.scss';

interface WritingLevelRecordContainerProps {
  id: string
  display: string
}

export default function ReadingFluencyDashboard({id, display}: WritingLevelRecordContainerProps): React.ReactElement {
  return (
		<div className={'writing-level-record-container'} id={id}>
			<Table display={display} />
		</div>
  );
}
