import axios from 'axios';
import type ElementarySchoolDecentWorkApi from '../types/Upload_ElementarySchoolDecenteWork';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import {
    type ElementarySchoolWeeklyPlanType,
    type ElementarySchoolWeeklyPlanPost,
    type ElementarySchoolWeeklyPlanPut
} from '../types/Upload_ElementarySchoolWeeklyPlan';

class UploadKindergartenSchoolWeeklyPlanApi implements ElementarySchoolDecentWorkApi {
    private readonly user = lscache.get('user') as UserProtocol;
    private get href() {
        return config.apiUrl;
    }

    private get _date(): string {
        const date = new Date();

        return date.toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            dateStyle: 'short',
            timeStyle: 'short'
        });
    }

    constructor() {
        try {
            axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
        } catch {
            //
        }
    }

    async post({
        file,
        classRoom,
        date,
        name,
        userName,
        userId
    }: ElementarySchoolWeeklyPlanPost): Promise<ElementarySchoolWeeklyPlanType[]> {
        let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

        const formDate = new FormData();

        formDate.append('fileWeeklyPlan', file);
        formDate.append('classRoom', classRoom);
        formDate.append('date', date);
        formDate.append('localDate', this._date);
        formDate.append('name', name);
        formDate.append('userName', userName);
        formDate.append('userId', userId);

        await axios({
            method: 'post',
            url: this.href + '/file/kindergartenschool/weeklyPlan',
            data: formDate,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
        });

        return newElementaryWeeklyPlan;
    }

    async postDescription(
        decentWorkId: string,
        description: string,
        userId: string,
        userName: string
    ): Promise<ElementarySchoolWeeklyPlanType[]> {
        let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

        await axios
            .post(`${this.href}/description/kindergartenschool/weeklyPlan/${decentWorkId}`, {
                userId,
                userName,
                date: this._date,
                description
            })
            .then((response) => {
                newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
            });

        return newElementaryWeeklyPlan;
    }

    async deleteDescription(decentWorkId: string, descriptionId: string, userId: string) {
        let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType | undefined;

        await axios
            .delete(
                `${this.href}/description/kindergartenschool/weeklyPlan/${decentWorkId}/${descriptionId}/${userId}`
            )
            .then((response) => {
                newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType;
            });

        return newElementaryWeeklyPlan;
    }

    async setChecking(weeklyPlanId: string): Promise<ElementarySchoolWeeklyPlanType[]> {
        let newElementarySchoolWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

        await axios
            .post(`${this.href}/checking/kindergartenschool/weeklyPlan/${weeklyPlanId}`)
            .then((response) => {
                newElementarySchoolWeeklyPlan = response.data;
            });

        return newElementarySchoolWeeklyPlan;
    }

    async put({
        file,
        name,
        weeklyPlanId
    }: ElementarySchoolWeeklyPlanPut): Promise<ElementarySchoolWeeklyPlanType[]> {
        let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

        const formData = new FormData();

        formData.append('fileWeeklyPlan', file);
        formData.append('name', name);

        await axios({
            method: 'put',
            url: `${this.href}/file/kindergartenschool/weeklyPlan/${weeklyPlanId}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
        });

        return newElementaryWeeklyPlan;
    }

    async get(): Promise<ElementarySchoolWeeklyPlanType[]> {
        let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

        await axios
            .get(this.href + '/file/kindergartenschool/weeklyPlan')
            .then((response) => {
                newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
            })
            .catch((e) => {
                //
            });

        return newElementaryWeeklyPlan;
    }

    async getData(teacherId: string): Promise<ElementarySchoolWeeklyPlanType[]> {
        let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

        await axios
            .get(this.href + `/file/kindergartenschool/weeklyPlan/get_data_by_id/${teacherId}`)
            .then((response) => {
                newElementaryWeeklyPlan = response.data;
            });

        return newElementaryWeeklyPlan;
    }

    async delete(elementId: string, fileName: string): Promise<ElementarySchoolWeeklyPlanType[]> {
        let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

        await axios
            .delete(this.href + `/file/kindergartenschool/weeklyPlan/${elementId}`, {
                data: {
                    fileName
                }
            })
            .then((response) => {
                newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
            })
            .catch((e) => {
                //
            });

        return newElementaryWeeklyPlan;
    }
}

export default new UploadKindergartenSchoolWeeklyPlanApi();
