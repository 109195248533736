/* eslint-disable react/jsx-key */
import React from 'react';
import './styles.scss';
import { type QuestionElementType, type QuestionContentType } from '../../../class/types/student-exams/student-exams-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import { useParams } from 'react-router-dom';
import StudentExamsCostumer from '../../../class/Student-Exams/student-exams-costumer';
import OptionElement from './option-element';

interface OptionsElementContentProps {
  element: QuestionElementType
  questionId: string
  elementId: string
  setQuestion: React.Dispatch<React.SetStateAction<QuestionContentType[]>>
}

export default function OptionsElementContent({ element, questionId, elementId, setQuestion }: OptionsElementContentProps): React.ReactElement {
  const { id } = useParams();

  const huddleDelete = () => {
    if (!id || !questionId || !elementId) {
      return;
    }

    const studentExamsApi = new StudentExamsApi();

    studentExamsApi.deleteQuestionElementOptions(id, questionId, elementId).then(response => {
      setQuestion(response);
    }).catch(e => {
      //
    })
  }

  const huddleAddNewOption = () => {
    const studentExamsCostumer = new StudentExamsCostumer(null, null, undefined, undefined);

    if (!id || !questionId || !elementId) {
      return;
    }

    studentExamsCostumer.postQuestionElementOptionOfOptions(id, questionId, elementId).then(response => {
      setQuestion(response);
    }).catch(e => {

    })
  }

  return (
    <div className='option-element-content'>
      <div className='option-element-content-header'>
        <h1>OPÇÕES</h1>
      </div>
      <div className='option-element-content-body'>
        <div className='options-element-content-table'>
          <div className='options-element-content-table-option-content'>
            {!element.options
              ? ''
              : Object.keys(element.options).map(key => {
                return <OptionElement option={element.options ? element.options[key] : undefined} optionId={key} questionId={questionId} elementId={elementId} setQuestion={setQuestion} key={key} />
              })}
          </div>
          <div className='option-element-content-add-new-option'>
            <button onClickCapture={huddleAddNewOption}>Adicionar nava opção</button>
          </div>
        </div>
        <IoCloseOutline className='icon-content' onClick={huddleDelete} />
      </div>
    </div>
  );
}
