/* eslint-disable no-mixed-spaces-and-tabs */

import React from 'react';
import './style.scss';
import type FileAdmLegislationType from '../../../class/types/AdmLegislation';
import DisplayDocument from '../../../components/displayDocument';

interface FormEditStudentReportProps {
  setDisplayAdmLegislationInfo: React.Dispatch<React.SetStateAction<boolean>>
  displayAdmLegislationInfo: boolean
  titleInfo: string
  element: FileAdmLegislationType
}

export default function LegislationDisplayInformation({
  setDisplayAdmLegislationInfo,
  displayAdmLegislationInfo,
  titleInfo,
  element
}: FormEditStudentReportProps): JSX.Element {
  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const formatFileName = (fileName: string) => {
    if (fileName.length > 50) {
      return fileName.slice(0, 50).concat('...');
    }

    return fileName;
  }

  return (
		<div className={`adm-slid-information display-${String(displayAdmLegislationInfo)}`}>
			<div className='header-form-describe-element-content'>
				<h1>
          {titleInfo}
				</h1>
				<button onClickCapture={() => {
				    setDisplayAdmLegislationInfo(false);
				}}>Voltar</button>
			</div>
			<div className='main-monthly-plan-display-information' id='main-monthly-plan-display-information'>
				<div className="decent-work-information">
					<h1>Nome do arquivo: <span>{formatFileName(formatFileName(element.fileName))}</span></h1>
				</div>
        <div className="decent-work-information">
					<h1>Descrição: <span>{element.description.content}</span></h1>
				</div>
				<div className="decent-work-information">
					<h1>Data: <span>{formatDate(element.date ?? '')}</span></h1>
				</div>
				<div className="decent-work-information">
					<h1>Emissor: <span>{element.issuer?.userName}</span></h1>
				</div>
				<div className="decent-work-information">
					<h1>Data de emissão: <span>{element.issuer?.emissionDate}</span></h1>
				</div>
        <div className='adm-hours-information-frame-content'>
          <DisplayDocument type={element.type} url={element.location.url} />
        </div>
			</div>
		</div>
  );
}
