/* eslint-disable @typescript-eslint/no-unsafe-call */

import lscache from 'lscache';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { PlanType } from '../../../class/types/Plan/plansApi-protocol';

import './style.scss';

export default function InformationStrategy(): React.ReactElement {
  const [strategy, setStrategy] = useState<PlanType>();
  const { id } = useParams();

  useEffect(() => {
    lscache.get('plans').forEach((element: PlanType) => {
      if (element.id === id) {
        setStrategy(element);
      }
    });
  }, []);

  return (
		<div className='information-strategy-element'>
			<div className='information-main'>
				<div className='information-header'>
					<h1>Informações</h1>
				</div>
				<div className='information-body'>
					<div className='information-content'>
						<h1>Estratégia:</h1>
						<h2>{strategy?.planName}</h2>
					</div>
					<div className='information-content'>
						<h1>Cronograma:</h1>
						<h2>{strategy?.timeline}</h2>
					</div>
				</div>
			</div>
		</div>
  );
}
