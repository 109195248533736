/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import Chart from 'react-apexcharts';
import './styles.scss';
import type StudentMonetaryResType from '../../../class/types/student-monetary-aid';
import formatSeries from './services/formatSeries';
import formatCF from './services/formatCF';

interface DashBoardStudentMonetaryAidProps {
  studentMonetaryAid: StudentMonetaryResType
  twoMonths: 'twoMonths-one' | 'twoMonths-two' | 'twoMonths-tree' | 'twoMonths-for'
}

export default function DashBoardStudentMonetaryAid({studentMonetaryAid, twoMonths}: DashBoardStudentMonetaryAidProps): React.ReactElement {
  return (
    <div className='dashboard-student-monetary-aid-container'>
      <Chart
        options={
          {
            chart: {
              id: 'basic-bar'
            },
            colors: ['#008697'],
            xaxis: {
              categories: (() => {
                const categories = studentMonetaryAid.frequency.map(value => value.matter.name);

                categories.push('CF');

                return categories;
              })(),
            },
            dataLabels: {
              formatter: (val) => {
                return `${val}%`
              },
            }
          }
        }
        width={800}
        type='bar'
        series={[
          {
            name: 'Porcentagem',
            data: formatSeries(studentMonetaryAid, twoMonths),
          },
        ]}
      />
    </div>
  );
}
