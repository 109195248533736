
import React from 'react';
import './style.scss';
import Header from '../../components/Header';
import RegisterContainer from '../../container/register-container';

export default function Register() {
  return (
		<div className='App'>
			<main className='main-container-register'>
				<Header />
				<div className='form-register-container'>
					<RegisterContainer />
				</div>
			</main>
		</div>
  );
}
