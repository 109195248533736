/* eslint-disable @typescript-eslint/no-var-requires */
import JSPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { type FrequencyMissed } from '../../../../class/types/Frequency/frequency-factory-protocol';

const img = require('../../img/header-img.png');

export default function printMissedInformation(frequency: FrequencyMissed[], roomName: string, twoMonthsName: string): void {
  const doc = new JSPDF({
    orientation: 'landscape',
  });

  const returnPorcentual = (presence: number, missed: number) => {
    let calc = (presence / (presence + missed)) * 100;

    if (!calc) {
      calc = 0;
    }

    return calc;
  }

  doc.addImage(img, 'PNG', 50, 5, 180, 0);

  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951'
    },
    head: [
      ['Numero', 'Nome do Aluno', 'Presenças', 'Faltas', 'Porcentual']
    ],
    body: (() => {
      const bodyArray: string[][] = [];

      frequency.forEach((freq, index) => {
        bodyArray.push([
          `${index + 1}`,
          freq.studentName,
          String(freq.presence),
          String(freq.missed),
          `${Math.floor(returnPorcentual(freq.presence, freq.missed))}%`
        ]);
      });

      return bodyArray;
    })(),
  });

  doc.save(`Frequência Bimestral ${roomName} - ${twoMonthsName}.pdf`);
}
