/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useRef } from 'react';
import lscache from 'lscache';
import './style.scss';
import clearAllInputs from './service/clear-all-inputs';
import type { ActionResponseProtocol } from '../../../class/types/Action/ActionAPI-protocol';
import { IoIosArrowDown } from 'react-icons/io';
import ActionCustumer from '../../../class/Action/Action-costumer';
import { useParams } from 'react-router-dom';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayEditElement: boolean
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setActionsCustumer: (actions: ActionResponseProtocol[]) => void
  elementId: string | undefined
}

export default function FormEditElement({ displayEditElement, setDisplayEditElement, elementId, setActionsCustumer }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [option, setOption] = useState<string>();
  const { id } = useParams();

  const inputAction = useRef<HTMLInputElement | null>(null);
  const inputShipping = useRef<HTMLInputElement | null>(null);
  const inputResponsible = useRef<HTMLInputElement | null>(null);
  const inputDeadLine = useRef<HTMLInputElement | null>(null);
  const inputSituation = useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  let strategy: Record<string, string> = {};

  try {
    lscache.get('actions').forEach((element: any) => {
      if (element.id === elementId) {
        strategy = element;
      }
    });
  } catch (e) {
    //
  }

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');
    const strategyCustumer = new ActionCustumer(inputs, String(id));

    if (elementId) {
      setIsLoading(true);
      strategyCustumer.put(elementId).then(response => {
        setActionsCustumer(response);
        setDisplayEditElement(false);
        clearAllInputs();
        setDisplayOptions(false);
        setOption(undefined);
        setIsLoading(false);
      });
    }
  };

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayEditElement(false);
    clearAllInputs();
    setDisplayOptions(false);
    setOption(undefined);
  };

  const hundleClick = () => {
    setDisplayOptions(!displayOptions);
  };

  const hundleClickOption = (e: React.MouseEvent<HTMLUListElement>) => {
    const target = e.target as HTMLElement;

    if (target.textContent) {
      setOption(target.textContent);
      setDisplayOptions(false);
    }
  };

  useEffect(() => {
    setDisplayElement(displayEditElement);
    (inputAction.current ?? {value: ''}).value = strategy.actionName;
    (inputShipping.current ?? {value: ''}).value = strategy.shipping;
    (inputResponsible.current ?? {value: ''}).value = strategy.responsible;
    (inputDeadLine.current ?? {value: ''}).value = strategy.deadline;
    (inputSituation.current ?? {value: ''}).value = strategy.situation;
  }, [displayEditElement]);

  return (
    <div className={`form-edit-container display-${displayElement}`}>
      <form
        method='POST'
        className='form-edit-element'
        id='form-edit-element'
        onSubmit={hundleSubmit}
      >
        <div className='header-form-edit-element'>
          <h1>Editar</h1>
        </div>
        <div className='body-form-edit-element'>
          <div className='input-content'>
            <label htmlFor='action'>Ação:</label>
            <input
              type='text'
              id='action'
              placeholder={'Ação'}
              ref={inputAction}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='shipping'>Entrega:</label>
            <input
              type='text'
              id='shipping'
              placeholder={'Entregas'}
              ref={inputShipping}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='responsible'>Responsável:</label>
            <input
              type='text'
              id='responsible'
              placeholder={'Responsavel'}
              ref={inputResponsible}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='deadline'>Prazo (inicio-fim):</label>
            <input
              type='text'
              id='deadline'
              placeholder={'Responsável pela meta'}
              ref={inputDeadLine}
            />
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='situation'>Situação:</label>
            <div className='input-element-content' onClick={hundleClick}>
              <input
                type='text'
                id='situation'
                value={!option ? strategy.situation : option}
                disabled
                ref={inputSituation}
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptions}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptions}`}
              onClick={e => {
                hundleClickOption(e);
              }}
            >
              <li>inicializado</li>
              <li>em andamento</li>
              <li>finalizado</li>
              <li>não inicializado</li>
            </ul>
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }
                }
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose();
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Editar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
