
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import './style.scss';
import clearAllInputs from './service/clear-all-inputs';
import SpellingLevelCustomer from '../../../class/SpellingLevel/SpellingLevel-custumer';
import { useParams } from 'react-router-dom';
import SpellingLevelApi from '../../../class/SpellingLevel/SpellingLevelApi';
import { type SpellingLevelApiResType } from '../../../class/types/SpellingLevel/spellingLevelType';

interface FormAddElementProps {
  displayEditElement: boolean
  activityInfo: Record<string, string>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setSpellingLevel: React.Dispatch<React.SetStateAction<SpellingLevelApiResType[]>>
  elementId: string | undefined
}

export default function FormEditElement({ displayEditElement, activityInfo, setDisplayEditElement, elementId, setSpellingLevel }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [spellingLevelFormEdit, setSpellingLevelFormEdit] = useState<SpellingLevelApiResType>();
  const { id } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id) {
      return;
    }

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const spellingLevelCustomer = new SpellingLevelCustomer(inputs, id, activityInfo.twoMonths);

    if (elementId) {
      spellingLevelCustomer.put(elementId).then(response => {
        setSpellingLevel(response);
        setDisplayEditElement(false);
        clearAllInputs();
      });
    }
  };

  const huddleClickClose = () => {
    setDisplayElement(false);
    setDisplayEditElement(false);
    clearAllInputs();
  };

  useEffect(() => {
    setDisplayElement(displayEditElement);

    if (!id) {
      return;
    }

    SpellingLevelApi.getAll(id, activityInfo.twoMonths).then(response => {
      response.forEach((element: any) => {
        if (element.id === elementId) {
          setSpellingLevelFormEdit(element);
        }
      });
    });
  }, [displayEditElement]);

  return (
		<div className={`form-edit-container-spelling-level display-${displayElement}`}>
			<form
				method='POST'
				className='form-edit-element'
				id='form-spelling-level'
				onSubmit={huddleSubmit}
			>
				<div className='header-form-edit-element'>
					<h1>Editar</h1>
				</div>
				<div className='body-form-edit-element'>
					<div className='input-content'>
						<label htmlFor='spelling-level'>Nível Descrição:</label>
						<input
							type='text'
							id='spelling-level'
							placeholder={!spellingLevelFormEdit ? 'Nível Descrição' : spellingLevelFormEdit.content}
						/>
					</div>
					<div className='buttons-content'>
						<button className='cancel-button' type='button' onClick={() => {
						  huddleClickClose();
						}}>Cancelar</button>
						<button className='save-button' type='submit'>Salvar</button>
					</div>
				</div>
			</form>
		</div>
  );
}
