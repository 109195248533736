import React from 'react';
import type FileAdmLegislationType from '../../../class/types/AdmLegislation';

export default function FilterAdmLegislation(
  elements: FileAdmLegislationType[],
  inputs: NodeListOf<HTMLInputElement>
): FileAdmLegislationType[] {
  const fileName = inputs.item(0).value.replaceAll(' ', '').toLowerCase();
  const issuerName = inputs.item(1).value.replaceAll(' ', '').toLowerCase();
  const firstDate = inputs.item(2).value;
  const lastDate = inputs.item(3).value;

  let elementsFiltered: FileAdmLegislationType[] = elements;

  if (fileName) {
    elementsFiltered = elementsFiltered.filter((value) => {
      if (value.fileName.replaceAll(' ', '').toLowerCase().slice(0, fileName.length) === fileName) {
        return true;
      }
      return false;
    });
  }
  if (issuerName) {
    elementsFiltered = elementsFiltered.filter((value) => {
      if (value.issuer?.userName.replaceAll(' ', '').toLowerCase().slice(0, issuerName.length) === issuerName) {
        return true;
      }
      return false;
    });
  }

  if (elementsFiltered.length === 0) {
    elementsFiltered = elements;
  }
  if (firstDate && lastDate) {
    elementsFiltered = filterPlanByDate(elementsFiltered, firstDate, lastDate);
  }

  return elementsFiltered;
}

function filterPlanByDate(
  elements: FileAdmLegislationType[],
  paramFirstDate: string,
  paramLastDate: string
): FileAdmLegislationType[] {
  const newPlans: any = [];

  const firstDate = new Date(paramFirstDate);
  const lastDate = new Date(paramLastDate);

  elements.forEach((value) => {
    const planDate = new Date(value.date);

    if (paramFirstDate.length === 0) {
      return;
    }
    if (planDate < firstDate) {
      return;
    }

    if (paramLastDate.length === 0) {
      return;
    }

    if (planDate > lastDate) {
      return;
    }
    newPlans.push(value);
  });

  if (newPlans.length === 0) {
    return elements;
  }

  return newPlans;
}
