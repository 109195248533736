import { type FrequencyType } from '../../../class/types/IndividualCardReporting';

export default function FrequencyFormat(frequency: FrequencyType) {
  const presence = frequency.presence;
  let missed = frequency.missed - frequency.justificationOfAbsences;

  if (missed < 0) {
    missed = 0;
  }

  const sum = presence + missed;

  let porcentual = Math.floor((presence / sum) * 100);

  if (!porcentual) {
    porcentual = 0;
  }

  return `${porcentual}%`;
}
