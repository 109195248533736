/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './styles.scss';
import LoadContent from '../../../components/Load-content';
import InputSelect from '../../../components/input-select';
import PsychologistCostumer from '../../../class/Psychologist/psychologist-costumer';
import { type PsychologistAnamnese } from '../../../class/types/Psychologist';

interface AddStudentToMonitoringProps {
  studentSelected: PsychologistAnamnese | undefined
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  setStudentPsychologist: React.Dispatch<React.SetStateAction<PsychologistAnamnese[]>>
}

export default function EditStudentToMonitoring({ display, studentSelected, setDisplay, setStudentPsychologist}: AddStudentToMonitoringProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleClickClose = () => {
    setDisplay(!display);
  }

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!studentSelected) {
      return;
    }

    const target = e.target as HTMLFormElement;
    const input = target.querySelectorAll('input');
    const textarea = target.querySelectorAll('textarea');

    const psychologistCostumer = new PsychologistCostumer();

    psychologistCostumer.putAnamnese(studentSelected.id ?? '', input, textarea).then(response => {
      setDisplay(false);
      setStudentPsychologist(response);
      setDisplay(false);
    }).catch(e => {
      //
    });
  }

  if (!display) {
    return <></>
  }

  return (
    <div className={`add-student-to-monitoring-psychologist display-${String(display)}`}>
      <header className='add-student-to-monitoring-psychologist-header'>
        <h1>Editar Anamnese</h1>
        <IoCloseOutline onClick={huddleClickClose} className='icon-content' size='25' />
      </header>
      <form method='POST' action='/' className='add-student-to-monitoring-psychologist-section' onSubmit={huddleSubmit}>
        <h1 className='title-container'>Identificação</h1>
        <div className='input-content'>
          <label htmlFor="">Nome do Aluno:</label>
          <input type="text" name="student-name" placeholder='Nome do aluno' id="" defaultValue={studentSelected?.studentName} />
        </div>
        <div className='input-content'>
          <label htmlFor="">Idade:</label>
          <input type="text" name="student-name" placeholder='Idade do aluno' id="" defaultValue={studentSelected?.age} />
        </div>
        <div className='input-content'>
          <InputSelect
            title='Gênero'
            options={['Feminino', 'Masculino']}
            defaultValue={studentSelected?.sex}
          />
        </div>
        <div className='input-content'>
          <label htmlFor="">Data de Nascimento:</label>
          <input type="date" name="student-name" placeholder='Idade do aluno' defaultValue={studentSelected?.birthday} id="" />
        </div>
        <h1 className='title-container'>Dados Familiares</h1>
        <div className='input-content'>
          <label htmlFor="">Nome do Pai:</label>
          <input type="text" name="student-name" placeholder='Nome da pai' defaultValue={studentSelected?.fatherName} id="" />
        </div>
        <div className='input-content'>
          <label htmlFor="">Nome da Mãe:</label>
          <input type="text" name="student-name" placeholder='Nome da Mãe' defaultValue={studentSelected?.motherName} id="" />
        </div>
        <div className='input-content'>
          <label htmlFor="">Numero de irmãos (nome/sexo/Idade):</label>
          <textarea name="" id="" defaultValue={studentSelected?.numberOfBrothers.response}></textarea>
        </div>
        <div className='input-content'>
          <InputSelect
            title='Situação civil dos Pais'
            options={['Separados', 'Casado', 'Namorados', 'União Estável']}
            defaultValue={studentSelected?.maritalStatus}
          />
        </div>
        <div className='input-content'>
          <label htmlFor="">Reação da criança perante a situação:</label>
          <textarea name="" id="" defaultValue={studentSelected?.numberOfBrothers.reactionOfSon}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">A criança vive com quem:</label>
          <textarea name="" id="" defaultValue={studentSelected?.theSonLiveWithWho.response}></textarea>
        </div>
        <div className='input-content'>
          <InputSelect
            title='Origem do filho: (Biológico/ Adotado)'
            options={['Adotado', 'Biológico']}
            defaultValue={studentSelected?.originOfTheSon}
          />
        </div>
        <div className='input-content'>
          <InputSelect
            title='A criança e ciente da situação'
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.theChildIsAwareOfTheSituation}
          />
        </div>
        <div className='input-content'>
          <label htmlFor="">Observação Reação da criança à situação: </label>
          <textarea name="" id="" defaultValue={studentSelected?.theSonLiveWithWho.reactionOfSon}></textarea>
        </div>
        <h1 className='title-container'>Hitorico</h1>
        <div className='input-content'>
          <label htmlFor="">Gestação (completa/ prematura):</label>
          <textarea name="" id="" defaultValue={studentSelected?.gestation}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Saúde da mãe durante a gestação:</label>
          <textarea name="" id="" defaultValue={studentSelected?.healthOfMotherTowardTheGestation}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Parto:</label>
          <textarea name="" id="" defaultValue={studentSelected?.childbirth}></textarea>
        </div>
        <div className='input-content'>
          <InputSelect
            title='Amamentação:'
            options={['Materna', 'Artificial']}
            defaultValue={studentSelected?.breastFeeding}
          />
        </div>
        <div className='input-content'>
          <InputSelect
            title='Apresentou problemas de fala'
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.presentedSpeechProblems}
          />
        </div>
        <div className='input-content'>
          <InputSelect
            title='Dificuldades   ou atraso no controle do esfíncter'
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.difficultiesOrDelaysInSphincterControl}
          />
        </div>
        <div className='input-content'>
          <InputSelect
            title='Tem enurese noturna? '
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.HaveNocturnalEnuresis}
          />
        </div>
        <div className='input-content'>
          <InputSelect
            title='Seu desenvolvimento motor foi no tempo esperado?'
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.healthOfMotherTowardTheGestation}
          />
        </div>
        <div className='input-content'>
          <label htmlFor="">Perturbações (pesadelos, sonambulismo, agitação, etc.):</label>
          <textarea name="" id="" defaultValue={studentSelected?.disturbances}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Possui hábitos especiais (requer a presença de alguém, medos, etc.):</label>
          <textarea name="" id="" defaultValue={studentSelected?.hasSpecialHabits}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Troca letras, fonemas? Quais?</label>
          <textarea name="" id="" defaultValue={studentSelected?.changeLetters}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Fatos que afetaram o desenvolvimento do(a) aluno(a) (acidentes, operações, traumas etc.) ou outras ocorrências:</label>
          <textarea name="" id="" defaultValue={studentSelected?.factsThatAffectedTheStudentsDevelopment}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Estado Atual da Criança </label>
          <textarea name="" id="" defaultValue={studentSelected?.currentStatusOfTheChild}></textarea>
        </div>
        <h1 className='title-container'>Apresenta alguma dificuldade</h1>
        <div className='input-content'>
          <label htmlFor="">Na visão:</label>
          <textarea name="" id="" defaultValue={studentSelected?.inSight}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Na fala</label>
          <textarea name="" id="" defaultValue={studentSelected?.inSpeech}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Locomoção</label>
          <textarea name="" id="" defaultValue={studentSelected?.inLocomotion}></textarea>
        </div>
        <h1 className='title-container'>Atividades de vida diária</h1>
        <div className='input-content'>
          <InputSelect
            title='Toma banho sozinho? '
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.takeABathAlone}
          />
        </div>
        <div className='input-content'>
          <InputSelect
            title='Escova os dentes sozinho?'
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.brushYourTeethAlone}
          />
        </div>
        <div className='input-content'>
          <InputSelect
            title='Usa o banheiro sozinho? '
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.useTheBathroomAlone}
          />
        </div>
        <div className='input-content'>
          <InputSelect
            title='Necessita de auxilio para se vestir ou despir? '
            options={['Sim', 'Não']}
            defaultValue={studentSelected?.needAssistanceWithDressingOrUndressing}
          />
        </div>
        <div className='input-content'>
          <label htmlFor="">Em que idade se deu a retirada das fraldas?</label>
          <textarea name="" id="" defaultValue={studentSelected?.atWhatAgeWereDiapersRemoved}></textarea>
        </div>
        <h1 className='title-container'>Tendências Próprias</h1>
        <div className='input-content'>
          <label htmlFor="">Atende as intervenções quando está desobedecendo?</label>
          <textarea name="" id="" defaultValue={studentSelected?.respondToInterventionsWhenDisobeying}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Apresenta choro fácil?</label>
          <textarea name="" id="" defaultValue={studentSelected?.presentsEasyCrying}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Recusa auxílio?</label>
          <textarea name="" id="" defaultValue={studentSelected?.refusesAid}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Tem resistência ao toque (afago, carinho)</label>
          <textarea name="" id="" defaultValue={studentSelected?.hasTouchResistance}></textarea>
        </div>
        <h1 className='title-container'>Escolaridade</h1>
        <div className='input-content'>
          <label htmlFor="">A criança já estudou antes em outra escola? Qual?  </label>
          <textarea name="" id="" defaultValue={studentSelected?.theChildHasPreviouslyStudiedAtAnotherSchool}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Motivo da transferência:</label>
          <textarea name="" id="" defaultValue={studentSelected?.reasonForTransfer}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Já repetiu alguma série?, se sim qual?</label>
          <textarea name="" id="" defaultValue={studentSelected?.youRepeatedASeries}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">A criança recebe algum tipo de orientação quanto aos deveres de casa? Quem oferece? Durante quanto tempo?</label>
          <textarea name="" id="" defaultValue={studentSelected?.theStudentReceivesHelpWithHomework}></textarea>
        </div>
        <h1 className='title-container'>Participa de algumas das atividades abaixo?</h1>
        <div className='input-content'>
          <label htmlFor="">Curso  de língua  estrangeira.  Qual(is)?</label>
          <textarea name="" id="" defaultValue={studentSelected?.foreignLanguageCourse}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Modalidades  esportivas. Qual(is)?</label>
          <textarea name="" id="" defaultValue={studentSelected?.sport}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Dança.Qual(is)?</label>
          <textarea name="" id="" defaultValue={studentSelected?.dance}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Instrumento  musical. Qual(is)?</label>
          <textarea name="" id="" defaultValue={studentSelected?.musicalInstrument}></textarea>
        </div>
        <h1 className='title-container'>Sociabilidade?</h1>
        <div className='input-content'>
          <label htmlFor="">Faz amigos com facilidade?</label>
          <textarea name="" id="" defaultValue={studentSelected?.makesFriendsEasily}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Adapta-se facilmente ao meio?</label>
          <textarea name="" id="" defaultValue={studentSelected?.easilyAdaptsToTheEnvironment}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Quem são os companheiros da criança nas brincadeiras?</label>
          <textarea name="" id="" defaultValue={studentSelected?.whoAreTheChildsPlaymates}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Escolha de grupo</label>
          <textarea name="" id="" defaultValue={studentSelected?.chooseTheGroup}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Distrações preferidas:</label>
          <textarea name="" id="" defaultValue={studentSelected?.favoriteDistractions}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Atitudes sociais predominantes:</label>
          <textarea name="" id="" defaultValue={studentSelected?.prevailingSocialAttitudes}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Emocionais:</label>
          <textarea name="" id="" defaultValue={studentSelected?.emotional}></textarea>
        </div>
        <div className='input-content'>
          <label htmlFor="">Observações finais:</label>
          <textarea name="" id="" defaultValue={studentSelected?.finalObservations}></textarea>
        </div>
        <div className='button-content'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    width: '30px',
                  },
                  container: {
                    width: '30px',
                  }
                }}
              />
            )
            : <button type='submit'>Eitar aluno</button>}
        </div>
      </form>
    </div>
  );
}
