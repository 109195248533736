import React, { useState } from 'react';
import { type MonitoringSheetGoalType } from '../../../../class/types/municipal-education-plan-monitoring-sheet';
import './style.scss';
import { IoIosArrowDown } from 'react-icons/io';
import MunicipalEducationPlanMonitoringSheetApi from '../../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import { useParams } from 'react-router-dom';

interface AddNewGoalContainerProps {
  goal: MonitoringSheetGoalType
  setGoalInfo: React.Dispatch<React.SetStateAction<MonitoringSheetGoalType | undefined>>
  setGoal: React.Dispatch<React.SetStateAction<MonitoringSheetGoalType[]>>
  onClickLi: (element: string) => void
}

export default function AddNewGoalContainer({goal, onClickLi, setGoalInfo, setGoal}: AddNewGoalContainerProps): React.ReactElement {
  const [display, setDisplay] = useState<boolean>(false);

  const { id } = useParams();

  const huddleDisplay = () => {
    setDisplay(!display);
  }

  const huddleDelete = () => {
    if (!id || !goal.id) {
      return;
    }

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    municipalEducationPlanMonitoringSheetApi.deleteGoal(id, goal.id).then(response => {
      setGoal(response);
    }).catch(e => {
      //
    });
  }

  return (
    <>
      <li className='option-element' onClickCapture={huddleDisplay}>
        <div className='goal-li-element'>
          <h1>{goal.name}</h1>
          <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${String(display)}`} />
        </div>
      </li>
      <ul className={`goal-options-element display-${String(display)}`}>
        <li onClickCapture={() => {
          setGoalInfo(goal);
          onClickLi('strategy-1')
        }}>
          <h1>Meta</h1>
        </li>
        <li onClickCapture={() => {
          setGoalInfo(goal);
          onClickLi('strategy-2');
        }}>
          <h1>Texto da Meta</h1>
        </li>
        <li onClickCapture={() => {
          setGoalInfo(goal);
          onClickLi('strategy-3');
        }}>
          <h1>Indicadores</h1>
        </li>
        <li onClickCapture={() => {
          setGoalInfo(goal);
          onClickLi('strategy-4');
        }}>
          <h1>Estrategias</h1>
        </li>
        <li className='delete-content' onClickCapture={huddleDelete}>
          <h1>Apagar Meta</h1>
        </li>
      </ul>
    </>
  )
}
