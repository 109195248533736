import React from 'react';
import Table from '../Table-actions';
import './style.scss';

interface StrategyProps {
  id: string
  display: string
}

export default function Actions({ id, display }: StrategyProps): React.ReactElement {
  return (
		<div className={'actions-container'} id={id}>
			<Table display={display} />
		</div>
  );
}
