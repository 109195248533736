/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-floating-promises */

import lscache from 'lscache';
import React, { useState, useEffect } from 'react';
import type { UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import UserManager from '../../class/User/User-manager';
import Validator from '../../class/Validator';
import './style.scss';

interface ConfigUserType {
  id: string
}

export default function ConfigUser({ id }: ConfigUserType): React.ReactElement {
  const [user, setUser] = useState<UserClientProtocol>();

  const [userName, setUserName] = useState<string>();
  const [email, setEmail] = useState<string>();

  const hundleSetElement = (e: React.ChangeEvent<HTMLInputElement>, setElement: React.Dispatch<React.SetStateAction<any>>) => {
    const { value } = e.target;

    setElement(value);
  };

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const validation = new Validator(inputs);

    if (validation.validation(true)) {
      return;
    }

    const userCustumer = new UserManager(inputs);

    if (!user || !user.id) {
      return;
    }

    userCustumer.put(user.id).then(response => {
      window.location.reload();
    });
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    setUserName(!user?.userName ? '' : user?.userName);
    setEmail(!user?.email ? '' : user?.email);
    setUser(user);
  }, []);

  return (
		<div className='config-user' id={id}>
			<form
				method='POST'
				className='form-config-user'
				id='form-edit-element'
				onSubmit={hundleSubmit}
			>
				<div className='header-config-user'>
					<h1>Configuração</h1>
				</div>
				<div className='body-config-user'>
					<div className='input-content'>
						<label htmlFor='strategy'>Nome:</label>
						<div>
							<input
								type='text'
								id='strategy'
								value={userName}
								onChange={e => {
								  hundleSetElement(e, setUserName);
								}}
							/>
						</div>
					</div>
					<div className='input-content'>
						<label htmlFor='strategy'>e-mail:</label>
						<div>
							<input
								type='text'
								id='strategy'
								value={email}
								onChange={e => {
								  hundleSetElement(e, setEmail);
								}}
								placeholder='e-mail'
							/>
						</div>
					</div>
					<div className='buttons-content'>
						<button className='save-button' type='submit'>Salvar</button>
					</div>
				</div>
			</form>
		</div>
  );
}
