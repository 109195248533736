/* eslint-disable @typescript-eslint/no-unsafe-return */
import type StrategyCustumerProtocol from '../types/custumer-protocol';
import Validator from '../Validator';
import { type SpellingLevelApiResType } from '../types/SpellingLevel/spellingLevelType';
import SpellingLevelFactory from './SpellingLevel-factory';
import SpellingLevelApi from './SpellingLevelApi';

class SpellingLevelCustumer implements StrategyCustumerProtocol<SpellingLevelApiResType> {
	private readonly spellingLevelApi = SpellingLevelApi;

	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly roomId: string,
		private readonly twoMonthsName: string) {}

	async post(): Promise<any[]> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return [{
				error: true
			}];
		}

		let newSpellingLevel: SpellingLevelApiResType[] = [];
		const spellingLevelFactory = new SpellingLevelFactory(this.inputs);

		const spellingLevel = spellingLevelFactory.create();

		await this.spellingLevelApi.post(spellingLevel, this.roomId, this.twoMonthsName).then((response: SpellingLevelApiResType[]) => {
			newSpellingLevel = response;
		});

		return newSpellingLevel;
	}

	async put(id: string): Promise<any[]> {
		let strategies: any[] = [];

		const spellingLevelFactory = new SpellingLevelFactory(this.inputs);

		const spellingPut = spellingLevelFactory.createPut();

		await this.spellingLevelApi.put(spellingPut, this.roomId, this.twoMonthsName, id).then((response: any[]) => {
			strategies = response;
		});

		return strategies;
	}
}

export default SpellingLevelCustumer;
