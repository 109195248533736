/* eslint-disable @typescript-eslint/no-extraneous-class */
import { type MatterResProtocol } from '../types/Matter/matterApi-protocol';
import { type RoomType } from '../types/Room/Rooms-protocol';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import { type ExamResponseType, type QuestionContentType, type StudentExamsType} from '../types/student-exams/student-exams-protocol';

export default class StudentExamsFactory {
  static postFactory(date: string, title: string, room: RoomType, matter: MatterResProtocol): StudentExamsType {
    return {
      title,
      date,
      room,
      matter
    }
  }

  static createResponse(user: UserClientProtocol & {ra: string}, questions: QuestionContentType[], inputs: NodeListOf<HTMLInputElement>): ExamResponseType {
    const inputsValid: HTMLInputElement[] = [];

    inputs.forEach(input => {
      if (input.checked) {
        inputsValid.push(input);
      }
    });

    inputsValid.map(input => {
      const [questionId, elementId, optionId] = input.id.split(' ');

      questions.map((question: any) => {
        if (question.id === questionId) {
          question.elements[elementId].options[optionId].selected = input.checked;

          return question
        }
        return question;
      })

      return input;
    })

    return {
      id: user.id,
      studentName: user.userName,
      ra: user.ra,
      response: questions,
    };
  }
}
