import type StudentMonetaryResType from '../../../../class/types/student-monetary-aid';
import formatCF from './formatCF';

export default function formatSeries(studentMonetary: StudentMonetaryResType, twoMonths: 'twoMonths-one' | 'twoMonths-two' | 'twoMonths-tree' | 'twoMonths-for'): number[] {
  const frequency = studentMonetary.frequency;

  const series: number[] = [];

  frequency.map(value => {
    const presence = Number(value.frequency[twoMonths].presence)
    const missed = Number(value.frequency[twoMonths].missed);

    const sum = presence + missed;

    series.push(Math.floor((presence / sum) * 100));

    return value;
  });

  series.push(formatCF(studentMonetary, twoMonths));

  return series;
}
