/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { BsCalendarDate } from 'react-icons/bs';
import { BiSolidUserVoice } from 'react-icons/bi';
import { FaEye } from 'react-icons/fa';
import type NotificationType from '../../../class/types/notification';

interface NotificationInformationProps {
  display: boolean
  notification: NotificationType
  visualizationKeys: string[]
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

interface NotificationInformationTableElementProps {
  index: number
  visualization: any
}

function NotificationInformationTableElement({index, visualization}: NotificationInformationTableElementProps) {
  if (!visualization) {
    return <></>;
  }
  return (
    <ul className='notification-information-table-element'>
      <li>
        <h1>{index + 1}</h1>
      </li>
      <li>
        <h1>{visualization.userId}</h1>
      </li>
      <li>
        <h1>{visualization.name}</h1>
      </li>
      <li>
        <h1>{visualization.date}, {visualization.time}</h1>
      </li>
    </ul>
  )
}

export default function NotificationInformation({display, setDisplay, notification, visualizationKeys}: NotificationInformationProps): React.ReactElement {
  if (!notification.date) {
    return <></>
  }

  const countVisualization = () => {
    if (!notification.visualization) {
      return 0;
    }
    const keys = Object.keys(notification.visualization);

    return keys.length;
  }

  return (
    <div className={`notification-information-content display-${display}`}>
      <header className='header-title-notification-information-content'>
        <div className='title-notification-information-content'>
          <h1>Notificação</h1>
        </div>
        <div className='close-button-title-notification-content' onClick={() => {
              setDisplay(false);
          }}>
          <button className='close-button'>
            <IoCloseOutline size='25' />
          </button>
        </div>
      </header>
      <section className='notification-main'>
        <div className='notification-issuer-content'>
          <h1 className='notification-title'>
            Titulo:
            <span>{notification.title}</span>
          </h1>
          <ul className='issuer-information-list'>
            <li>
              <BiSolidUserVoice size={20} />
              <span>{!notification.issuer.name ? '' : notification.issuer.name}</span>
            </li>
            <li>
              <BsCalendarDate size={20} />
              <span>{!notification.date ? '' : notification.date}</span>
            </li>
            <li>
              <FaEye size={20} />
              <span>{countVisualization()}</span>
            </li>
          </ul>
        </div>
        <div className='notification-content-container'>
          <div className='notification-content-title-container'>
            <h1>Conteúdo:</h1>
          </div>
          <div className='notification-content-text-container'>
            <p>{!notification.content ? '' : notification.content}</p>
          </div>
        </div>
        <div className='notification-visualization-content'>
          <div className='notification-visualization-content-title'>
            <h1>Visualizações</h1>
          </div>
          <div className='notification-visualization-list-content'>
            <div className='notification-visualization-list-header'>
              <ul className='notification-visualization-list-header-ul'>
                <li>
                  <h1>Nº</h1>
                </li>
                <li>
                  <h1>Id</h1>
                </li>
                <li>
                  <h1>Nome</h1>
                </li>
                <li>
                  <h1>Data</h1>
                </li>
              </ul>
            </div>
            <div className='notification-visualization-list-main'>
              {visualizationKeys.length === 0 ? '' : visualizationKeys.map((key: string, index: number) => {
                return <NotificationInformationTableElement index={index} visualization={!notification.visualization ? '' : notification.visualization[key]} />
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
