/* eslint-disable multiline-ternary */
/* eslint-disable no-mixed-spaces-and-tabs */

import React from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import DisplayDocument from '../../../components/displayDocument';
import formatFileName from '../../../services/formatFileName';

interface FormEditProjectProps {
  setDisplayProjectInfo: React.Dispatch<React.SetStateAction<boolean>>
  displayProjectInfo: boolean
  element: ElementarySchoolDecentWorkType
}

export default function ProjectDisplayInformation({
  setDisplayProjectInfo,
  displayProjectInfo,
  element
}: FormEditProjectProps): JSX.Element {
  const formatDate = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  return (
    <div className={`project-display-information display-${String(displayProjectInfo)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          Projeto
        </h1>
        <button onClick={() => {
          setDisplayProjectInfo(false);
        }}>Voltar</button>
      </div>
      <div className='main-monthly-plan-display-information' id='main-monthly-plan-display-information'>
        <div className="decent-work-information">
          <h1>
            Nome do arquivo:
            <span>{formatFileName(element.fileName)}</span>
          </h1>
        </div>
        <div className="decent-work-information">
          <h1>
            Data:
            <span>{formatDate(element.date)}</span>
          </h1>
        </div>
        <div className="decent-work-information">
          <h1>
            Status:
            <span>{element.checked ? 'Visualizado' : 'Não visualizado'}</span>
          </h1>
        </div>
        {(element as any).visibility ? (
          <div className="decent-work-information">
            <h1>
              Visibilidade:
              <span>{(element as any).visibility}</span>
            </h1>
          </div>
        ) : ''}
        <div className="decent-work-information">
          <h1>
            Emissor:
            <span>{element.issuer?.userName}</span>
          </h1>
        </div>
        <div className="decent-work-information">
          <h1>
            Data de emissão:
            <span>{element.issuer?.emissionDate}</span>
          </h1>
        </div>
        <div className='adm-hours-information-frame-content'>
          <DisplayDocument type={element.type} url={element.location.url} />
        </div>
        <div className='description-content'>
          <h1>Comentário</h1>
          <p>
            {element.description?.content}
          </p>
        </div>
      </div>
    </div>
  );
}
