/* eslint-disable @typescript-eslint/no-var-requires */
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { type MathematicDiagnosticAssessmentObjType } from '../../../class/types/mathematic-diagnostic-assessment';

const img = require('./img/header-img.png');

export default function printMathematicsDiagnostic(
  mathematicDiagnostic: MathematicDiagnosticAssessmentObjType,
  twoMonthsNames: string,
  roomName: string,
): void {
  const doc = new JsPDF({
    orientation: 'landscape',
  });

  doc.addImage(img, 'PNG', 50, 5, 180, 0);
  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951'
    },
    head: [
      [
        'Nome do Aluno',
        'Familiares ou Frequentes',
        'Opacos',
        'Transparentes',
        'Terminam com zero',
        'Generalização',
        'Zero intercalado'
      ]
    ],
    bodyStyles: {
      lineColor: '#0000',
      lineWidth: 0.2,
    },
    body: (() => {
      const mathDiagnostic: string[][] = [] as string[][];

      for (const key in mathematicDiagnostic) {
        mathDiagnostic.push([
          mathematicDiagnostic[key].studentName,
          ...mathematicDiagnostic[key].mathematicDiagnostic
        ]);
      }

      return mathDiagnostic
    })()
  });

  doc.save(`Diagnostico de Matemática ${roomName} - ${twoMonthsNames}.pdf`)
}
