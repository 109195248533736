/* eslint-disable react/jsx-key */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { type ElementarySchoolMonthlyPlanType } from '../../../class/types/ElementarySchoolMonthlyPlan/ElementaryMonthlyPlanAPI';
import React, { useEffect, useRef, useState } from 'react';
import returnMaxNumber from '../service/returnMaxNumber';
import './style.scss';
import BackToTop from '../../../components/back-to-top/back-to-top';

interface VisualizationElementarySchoolMonthlyPlanProps {
  setDisplayViewElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
  displayViewElementarySchoolMonthlyPlan: boolean
  elementarySchoolMonthlyPlans: ElementarySchoolMonthlyPlanType[]
  elementId: string
}

export default function VisualizationElementarySchoolMonthlyPlan({
  displayViewElementarySchoolMonthlyPlan,
  elementId,
  elementarySchoolMonthlyPlans,
  setDisplayViewElementarySchoolMonthlyPlan
}: VisualizationElementarySchoolMonthlyPlanProps): JSX.Element {
  const [element, setElement] = useState<ElementarySchoolMonthlyPlanType>();
  const [maxNumber, setMaxNumber] = useState<number>();
  const mainElement = useRef<any>();

  useEffect(() => {
    const element = elementarySchoolMonthlyPlans.find((value) => value.id === elementId);

    if (!element) {
      return;
    }

    const maxNumber = returnMaxNumber(element)

    setMaxNumber(maxNumber);

    setElement(element);
  }, [displayViewElementarySchoolMonthlyPlan]);

  const back = () => {
    setDisplayViewElementarySchoolMonthlyPlan(false);
  };

  return (
    <div
      className={`visualization-elementary-school-monthly-plan display-${displayViewElementarySchoolMonthlyPlan}`}
      ref={mainElement}
    >
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <div className='button-content'>
        <button type='button' onClick={back}>Voltar</button>
      </div>
      <header>
        <h1>Plano Mensal Ensino Fundamental - {element?.class}</h1>
      </header>
      <div className='title-content'>
        <h1>
          Escola: <span>{element?.school}</span>
        </h1>
        <h1>
          Professor(a): <span>{element?.teacher}</span>
        </h1>
        <h1>
          Turma: <span>{element?.class}</span>
        </h1>
        <h1>
          Ano: <span>{element?.yearn}</span>
        </h1>
        <h1>
          Componente Curricular: <span>{element?.curricularComponent}</span>
        </h1>
        <h1>
          Objetivo geral: <span>{element?.strategicObjective}</span>
        </h1>
        <h1>
          Objetivos específicos: <span>{element?.specificObjective}</span>
        </h1>
      </div>
      <div className='table-content'>
        <table className='table-element'>
          <thead>
            <tr>
              <td>UNIDADE TEMÁTICA</td>
              <td>CAMPOS DE EXPERIÊNCIAS</td>
              <td>HABILIDADE</td>
              <td>OBJETIVOS DE APRENDIZAGEM</td>
              <td>METODOLOGIA/ ESTRATÉGIAS</td>
              <td>AVALIAÇÃO</td>
            </tr>
          </thead>
          <tbody>
            {'z-'.repeat(!maxNumber ? 0 : maxNumber - 1).split('-').map((v, index) => {
              return (
                <tr>
                  <td>{element?.methodology.thematicUnit.split(' - ')[index] ?? ''}</td>
                  <td>{element?.methodology.fieldOfAction.split(' - ')[index] ?? ''}</td>
                  <td>{element?.methodology.ability.split(' - ')[index] ?? ''}</td>
                  <td>{element?.methodology.learningObjectives.split(' - ')[index] ?? ''}</td>
                  <td>{element?.methodology.methodologyStrategy.split(' - ')[index] ?? ''}</td>
                  <td>{element?.methodology.assessment.split(' - ')[index] ?? ''}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className='commentary-content'>
        <h1>Comentário</h1>
        <div>
          <p>{element?.commentary}</p>
        </div>
      </div>
    </div>
  );
}
