import axios from 'axios';
import config from '../../../config/config';
import { type UserProtocol } from '../../types/User/User-factory-protocol';
import { type AllTextualGenresType, type TextualGenreType } from '../../types/new-writing-hypothesi/textual-genre';
import lscache from 'lscache';

export default class TextualGenreApi {
  private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  async post(roomId: string, twoMonths: string, data: any): Promise<TextualGenreType[]> {
    let textualGenre: TextualGenreType[] = [];

    await axios.post(this.href + `/new-writing-hypothesi/textual-genre/${roomId}/${twoMonths}`, data).then(response => {
      textualGenre = response.data;
    }).catch(e => {
      //
    });

    return textualGenre;
  }

  async delete(roomId: string, twoMonths: string, textualGenreId: string): Promise<TextualGenreType[]> {
    let textualGenre: TextualGenreType[] = [];

    await axios.delete(this.href + `/new-writing-hypothesi/textual-genre/${roomId}/${twoMonths}/${textualGenreId}`).then(response => {
      textualGenre = response.data;
    }).catch(e => {
      //
    });

    return textualGenre;
  }

  async update(roomId: string, twoMonths: string, textualGenreId: string, textualGenre: string): Promise<TextualGenreType[]> {
    let textualGenreRes: TextualGenreType[] = [];

    await axios.put(this.href + `/new-writing-hypothesi/textual-genre/${roomId}/${twoMonths}/${textualGenreId}`, {
      textualGenre,
    }).then(response => {
      textualGenreRes = response.data;
    }).catch(e => {
      //
    });

    return textualGenreRes;
  }

  async getAll(roomId: string, twoMonths: string): Promise<TextualGenreType[]> {
    let textualGenre: TextualGenreType[] = [];

    await axios.get(this.href + `/new-writing-hypothesi/textual-genre/${roomId}/${twoMonths}`).then(res => {
      textualGenre = res.data;
    }).catch(e => {
      //
    });

    return textualGenre;
  }

  async getAllTextualGenre(): Promise<AllTextualGenresType[]> {
    let textualGenre: AllTextualGenresType[] = [];

    await axios.get(this.href + '/new-writing-hypothesis/all/textual-genres').then(res => {
      textualGenre = res.data;
    });

    return textualGenre;
  }
}
