/* eslint-disable no-mixed-spaces-and-tabs */

import React from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import './style.scss';
import DisplayDocument from '../../../components/displayDocument';
import formatFileName from '../../../services/formatFileName';

interface FormEditPsychologistReportProps {
  setDisplayPsychologistInfo: React.Dispatch<React.SetStateAction<boolean>>
  displayPsychologistInfo: boolean
  element: ElementarySchoolDecentWorkType
}

export default function PsychologistDisplayInformation({
  setDisplayPsychologistInfo,
  displayPsychologistInfo,
  element
}: FormEditPsychologistReportProps): JSX.Element {
  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  return (
		<div className={`psychologist-report-information display-${String(displayPsychologistInfo)}`}>
			<div className='header-form-describe-element-content'>
				<h1>
          Relatório Psicológico
				</h1>
				<button onClick={() => {
				    setDisplayPsychologistInfo(false);
				}}>Voltar</button>
			</div>
			<div className='main-monthly-plan-display-information' id='main-monthly-plan-display-information'>
				<div className="decent-work-information">
					<h1>
            Nome do arquivo:
            <span>{formatFileName(element.fileName)}</span>
          </h1>
				</div>
				<div className="decent-work-information">
					<h1>
            Data:
            <span>{formatDate(element.date)}</span>
          </h1>
				</div>
				<div className="decent-work-information">
					<h1>
            Emissor:
            <span>{element.issuer?.userName}</span>
          </h1>
				</div>
				<div className="decent-work-information">
					<h1>
            Data de emissão:
            <span>{element.issuer?.emissionDate}</span>
          </h1>
				</div>
        <div className='adm-hours-information-frame-content'>
          <DisplayDocument type={element.type} url={element.location.url} />
        </div>
			</div>
		</div>
  );
}
