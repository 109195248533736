/* eslint-disable @typescript-eslint/prefer-optional-chain */

import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import { type KindergartenSchoolMonthlyPlanType } from '../../../class/types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';
import ContentTableElementaryMonthlyPlan from '../../../components/monthly-plan-file/ContentTable';
import ElementarySchoolMonthlyPlanAPI from '../../../class/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';
import VisualizationElementarySchoolMonthlyPlan from '../visualization-elementary-school-monthly-plan';

import FormEditMonthlyPlan from '../form-edit-monthly-plan';
import FormAddElementaryMonthlyPlan from '../form-add-monthly-plan';
import filterPlanByUser from './service/filterPlanByUser';
import BackToTop from '../../../components/back-to-top/back-to-top';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [elementarySchoolMonthlyPlans, setElementarySchoolMonthlyPlan] = useState<KindergartenSchoolMonthlyPlanType[]>([]);
  const [filteredElementarySchoolMonthlyPlans, setFilteredElementarySchoolMonthlyPlans] = useState<KindergartenSchoolMonthlyPlanType[]>();
  const [displayFormAddElementaryMonthlyPlan, setDisplayElementaryMonthlyPlan] = useState(false);
  const [displayEditElement, setDisplayEditElement] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elementId, setElementId] = useState<string>('');
  const [displayViewElementarySchoolMonthlyPlan, setDisplayViewElementarySchoolMonthlyPlan] = useState(false);
  const mainElement = useRef<any>();

  const huddleCreateNewElement = () => {
    setDisplayElementaryMonthlyPlan(true);
  };

  useEffect(() => {
    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    ElementarySchoolMonthlyPlanAPI.get().then((response) => {
      setIsLoading(false);
      setElementarySchoolMonthlyPlan(response);
    }).catch((e) => {
      //
    });
  }, [display]);

  useEffect(() => {
    setFilteredElementarySchoolMonthlyPlans(filterPlanByUser(elementarySchoolMonthlyPlans))
  }, [elementarySchoolMonthlyPlans])

  return (
    <div className='table-container-kindergarten-school-monthly-plan'>
      <h1 className='title-table'>Ensino Infantil - Plano Mensal</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddElementaryMonthlyPlan
        displayFormAddMonthlyPlan={displayFormAddElementaryMonthlyPlan}
        setDisplayFormAddMonthlyPlan={setDisplayElementaryMonthlyPlan}
        setElementarySchoolMonthlyPlan={setElementarySchoolMonthlyPlan}
        elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlans}
      />
      <FormEditMonthlyPlan
        displayFormEditMonthlyPlan={displayEditElement}
        elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlans}
        setDisplayFormEditMonthlyPlan={setDisplayEditElement}
        elementId={elementId}
        setElementarySchoolMonthlyPlan={setElementarySchoolMonthlyPlan}
      />
      <VisualizationElementarySchoolMonthlyPlan
        elementId={elementId}
        elementarySchoolMonthlyPlans={elementarySchoolMonthlyPlans}
        setDisplayViewElementarySchoolMonthlyPlan={setDisplayViewElementarySchoolMonthlyPlan}
        displayViewElementarySchoolMonthlyPlan={displayViewElementarySchoolMonthlyPlan}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar plano mensal</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Turma</li>
          <li className='header-table-ul-li'>Visualizado</li>
          <li className='header-table-ul-li'>Data</li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
          />
          : (display === 'true'
              ? (
                filteredElementarySchoolMonthlyPlans?.length === 0
                  ? <EmptyComponent>click em (adicionar plano mensal) para adicionar um novo plano mensal</EmptyComponent>
                  : filteredElementarySchoolMonthlyPlans?.map((elementarySchoolMonthlyPlan: KindergartenSchoolMonthlyPlanType, index: number) => (
                    <ContentTableElementaryMonthlyPlan
                      index={index}
                      elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlan}
                      setElementarySchoolMonthlyPlan={setElementarySchoolMonthlyPlan}
                      setDisplayViewElementarySchoolMonthlyPlan={setDisplayViewElementarySchoolMonthlyPlan}
                      setElementId={setElementId}
                      setDisplayEditElement={setDisplayEditElement}
                      key={String(elementarySchoolMonthlyPlan.id)}
                      type={'kindergartenSchool'}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}
