/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/naming-convention */

import axios from 'axios';
import config from '../../config/config';
import type { DataWritingLevel } from '../types/Student-writing-level-record/student-writing-level-record-factory-protocol';

class StudentWritingRecordCoordinatorApi {
  private get href() {
    return config.apiUrl;
  }

  async getAllDataStudentWritingRecordCoordinator(roomIDs: string[]): Promise<DataWritingLevel> {
    let config: DataWritingLevel = {} as DataWritingLevel;
    if (this.href) {
      await axios
        .post(`${this.href}/get/writinghypothesis/coordinator`, {
          roomIDs
        })
        .then((response) => {
          config = response.data as DataWritingLevel;
        });
    }

    return config;
  }

  async getAllDataStudentWritingRecordTwoMonthsCoordinator(
    roomIDs: string[],
    twoMonths: string
  ): Promise<DataWritingLevel> {
    let config: DataWritingLevel = {} as DataWritingLevel;
    if (this.href) {
      await axios
        .post(`${this.href}/get/writinghypothesis/coordinator/twoMonths`, {
          roomIDs,
          twoMonths
        })
        .then((response) => {
          config = response.data as DataWritingLevel;
        });
    }

    return config;
  }

  async getAllDataStudentWritingRecordSemesterCoordinator(
    roomIDs: string[],
    semester: string
  ): Promise<DataWritingLevel> {
    let config: DataWritingLevel = {} as DataWritingLevel;
    if (this.href) {
      await axios
        .post(`${this.href}/get/writinghypothesis/coordinator/semester`, {
          roomIDs,
          semester
        })
        .then((response) => {
          config = response.data as DataWritingLevel;
        });
    }

    return config;
  }

  async getDataWritingLevelCoordinatorCostumer(
    id: string,
    roomId: string[]
  ): Promise<DataWritingLevel> {
    let data = {} as DataWritingLevel;

    const dataError: DataWritingLevel = {
      series: [100],
      seriesCount: [0],
      color: ['red'],
      category: ['dados não encontrado'],
      writingTheName: 0
    };

    const type = id.split(' ')[0];
    const content = id.split(' ')[1];

    if (!content && !type) {
      return dataError;
    }

    if (type === 'school-year') {
      await this.getAllDataStudentWritingRecordCoordinator(roomId).then((response) => {
        data = response;
      });
    }

    if (type === 'twoMonths') {
      await this.getAllDataStudentWritingRecordTwoMonthsCoordinator(roomId, content).then(
        (response) => {
          data = response;
        }
      );
    }

    if (type === 'semester') {
      await this.getAllDataStudentWritingRecordSemesterCoordinator(roomId, content).then(
        (response) => {
          data = response;
        }
      );
    }

    if (!data) {
      return dataError;
    }

    if (![...data.series].pop() && [...data.series].pop() !== 0) {
      return dataError;
    }

    return data;
  }

  async getDataHypothesisTagTitleCoordinator(
    roomId: string[],
    twoMonths: string,
    tagTitleId: string,
    index: number
  ): Promise<DataWritingLevel> {
    let data: DataWritingLevel = {} as DataWritingLevel;
    await axios
      .post(
        `${this.href}/get/hypothesis/tagTitle/coordinator/${twoMonths}/${index}`, {
          roomId,
          tagTitleId,
        })
      .then((response) => {
        data = response.data as DataWritingLevel;
      });
      return data;
    }

    async getDataHypothesisTagTitleCoordinatorBySemester(roomId: string[], semester: string, tagTitleId: string): Promise<DataWritingLevel> {
      let data: DataWritingLevel = {} as DataWritingLevel;
      await axios.post(
        `${this.href}/get/hypothesis/tagTitle/coordinator/semester`,
        {
          tagTitleId,
          roomIds: roomId,
          semester,
        }
      ).then((response) => {
        data = response.data as DataWritingLevel;
      });

      return data;
    }
}

export default new StudentWritingRecordCoordinatorApi();
