/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import './style.scss';
import { type ElementarySchoolDecentWorkType } from '../../class/types/Upload_ElementarySchoolDecenteWork';
import { DisplayFileInfoTree } from '../DisplayFile';

interface ContentTableWeeklyPlanType {
  index: number
  elementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType
  setElement: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType>>
  setDisplayWeeklyPlan: React.Dispatch<React.SetStateAction<boolean>>

}
export default function ContentTablePsychologistReportHome({
  setDisplayWeeklyPlan,
  setElement,
  elementarySchoolWeeklyPlan,
  index,
}: ContentTableWeeklyPlanType): React.ReactElement {
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(elementarySchoolWeeklyPlan);
    setDisplayWeeklyPlan(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 30) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [elementarySchoolWeeklyPlan]);

  if (!elementarySchoolWeeklyPlan) {
    return <></>
  }

  return (
    <div className={`content-table-psychologist-report-home display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={elementarySchoolWeeklyPlan.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(elementarySchoolWeeklyPlan.fileName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(elementarySchoolWeeklyPlan.date)}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
        </li>
        <li>
        </li>
        <li className='content-table-ul-li'>
        </li>
        <li className='header-table-ul-li'>
          <MdDelete size='20' style={{ color: '#ff0000' }} />
        </li>
      </ul>
    </div>
  );
}
