/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import RoomObserverCustumer from '../../../class/RoomObserver/RoomObserverCustumer';
import type RoomObserverType from '../../../class/types/RoomObserver';

interface FormAddWeeklyPlanPlanProps {
  setDisplayFormAddWeeklyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolWeeklyPlan: React.Dispatch<React.SetStateAction<RoomObserverType[]>>
  displayFormAddWeeklyPlan: boolean
  elementarySchoolWeeklyPlan: RoomObserverType[]
  elementSelected: RoomObserverType | undefined
  roomInformation: {
    matterId: string
    matterName: string
    twoMonths: string
    twoMonthsName: string
  }
}

export default function FormEditWeeklyPlan({ setDisplayFormAddWeeklyPlan, setElementarySchoolWeeklyPlan, roomInformation, displayFormAddWeeklyPlan, elementSelected}: FormAddWeeklyPlanPlanProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {id} = useParams();

  const huddleCancel = () => {
    setDisplayFormAddWeeklyPlan(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const input = form.querySelectorAll('input')[0];

    if (!textAreaRef.current || !id) {
      return;
    }

    const roomObservation = new RoomObserverCustumer(id, roomInformation.matterId, roomInformation.twoMonths, textAreaRef.current, input);

    setIsLoading(true);
    roomObservation.put(elementSelected?.id ?? '').then(response => {
      setIsLoading(false);
      if (response.length === 0) {
        return;
      }
      setDisplayFormAddWeeklyPlan(false);
      setElementarySchoolWeeklyPlan(response);
    });
  };

  useEffect(() => {
    if (!elementSelected) {
      return;
    }

    (!textAreaRef.current ? {value: ''} : textAreaRef.current).value = elementSelected?.content ?? '';
    (!inputRef.current ? {value: ''} : inputRef.current).value = elementSelected.date;
  }, [displayFormAddWeeklyPlan]);

  return (
    <div className={`form-add-school-elementary-teaching-weekly-plan display-${String(displayFormAddWeeklyPlan)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Editar Adendo ao Calendário Escolar
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddWeeklyPlan(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-element-content">
          <label htmlFor="input-room-observer">
            Adendo
          </label>
          <textarea
            id='input-room-observer'
            ref={textAreaRef}
          />
        </div>
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date" ref={inputRef} />
        </div>
        <div className='button-content'>
          <div>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={{
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }}
                />
              )
              : (
                <>
                  <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                  <button type='submit' className='save-button'>Editar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
