/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, {useState, useEffect} from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import ActionAPI from '../../../class/Action/ActionAPI';
import type { ActionResponseProtocol } from '../../../class/types/Action/ActionAPI-protocol';
import './style.scss';
import LoadContent from '../../Load-content';

interface ContentTableType {
  action: ActionResponseProtocol
  index: number
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setActionsCostumer: (actions: ActionResponseProtocol[]) => void
  setElementId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export default function ContentTable({ action, index, setActionsCostumer, setElementId, setDisplayEditElement }: ContentTableType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const huddleDelete = () => {
    if (action.id) {
      setIsLoading(true);
      ActionAPI.deleteAction(String(action.id)).then(response => {
        setActionsCostumer(response);
        setIsLoading(false);
      });
    }
  };

  const huddleEdit = () => {
    setDisplayEditElement(true);
    setElementId(String(action.id));
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [action]);

  return (
		<div className={`content-table-element-action display-animation-${displayAnimation}`}>
			<ul className='content-table-ul-action'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{action.actionName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{action.shipping}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{action.responsible}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{action.deadline}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{action.situation}
					</h2>
				</li>
				<li className='content-table-ul-li' onClick={huddleEdit}>
					<FiEdit2 size='20' style={{ color: '#118951' }} />
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>

          )}
			</ul>
		</div>
  );
}
