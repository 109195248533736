/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-negated-condition */

import React from 'react';
import './style.scss';
import { type FrequencyMissed } from '../../../class/types/Frequency/frequency-factory-protocol';

interface FrequencyInputElementProps {
  frequency: FrequencyMissed
  index: number
}

export default function MissedInformationElement({ frequency, index }: FrequencyInputElementProps): React.ReactElement {
  const returnPorcentual = (presence: number, missed: number) => {
    let calc = (presence / (presence + missed)) * 100;

    if (!calc) {
      calc = 0;
    }

    return calc;
  }

  return (
		<ul className='student-missed-information-content-body-list'>
			<li>
				{index + 1}
			</li>
			<li>
				{frequency.studentName}
			</li>
			<li>
				{frequency.presence}
			</li>
			<li>
				{frequency.missed}
			</li>
      <li>{Math.floor(returnPorcentual(frequency.presence, frequency.missed))}%</li>
		</ul>
  );
}
