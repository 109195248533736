/* eslint-disable @typescript-eslint/no-var-requires */
import autoTable from 'jspdf-autotable';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';
import JSPDF from 'jspdf';

const img = require('./img/header-img.png');

export default function printStudentByRoom(students: StudentType[], roomName: string) {
  const doc = new JSPDF({
    orientation: 'landscape',
  });

  doc.addImage(img, 'PNG', 50, 5, 180, 0);

  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951'
    },
    head: [
      ['Numero', 'Nome do Aluno', 'Responsavel', 'RA', 'UF', 'Bairro', 'Situação'],
    ],
    body: (() => {
      const bodyArray: string[][] = [];

      students.forEach((student, index) => {
        bodyArray.push([
          `${index + 1}`,
          student.studentName,
          student.responsible,
          student.ra.content,
          student.ra.uf,
          student.district,
          student.situation ?? '',
        ])
      });

      return bodyArray;
    })()
  })

  doc.save(`Alunos - ${roomName}.pdf`);
}
