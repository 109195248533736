import React, {useEffect, useState} from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './style.scss';
import type ProgrammaticContentResProtocol from '../../../class/types/ProgrammaticContent/ProgrammaticContentApi-protocol';

interface Props {
  displayProgrammaticContentInformation: boolean
  setDisplayProgrammaticContentInformation: React.Dispatch<React.SetStateAction<boolean>>
  elementId: string
  programmaticContent: ProgrammaticContentResProtocol[]
}

export default function ProgrammaticContentInformation({ displayProgrammaticContentInformation, elementId, setDisplayProgrammaticContentInformation, programmaticContent }: Props) {
  const [element, setElement] = useState<ProgrammaticContentResProtocol | null>(null);

  const huddleClickClose = () => {
    setDisplayProgrammaticContentInformation(!displayProgrammaticContentInformation);
  }

  useEffect(() => {
    programmaticContent.map(value => {
      if (value.id === elementId) {
        setElement(value);
      }
      return value;
    })
  }, [displayProgrammaticContentInformation]);

  return (
    <div className={`programmatic-content-information-element display-${String(displayProgrammaticContentInformation)}`}>
      <div className='programmatic-content-information-container'>
        <div className='header-form-add-element-content'>
          <h1>
            Conteúdo programático
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose()
          }} size='25' />
        </div>
        <div className='main-programmatic-content-information'>
          <div className='main-programmatic-content-curriculumComponent'>
              <h1>{element ? element.curriculumComponent : ''}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
