/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import UploadWorkshopHTTPICostumer from '../../../class/UploadWorkShopHTTPI/upload-HTTPI-custumer';

interface FormEditWeeklyPlanProps {
  setDisplayFormDescribeWorkshopHTTPI: React.Dispatch<React.SetStateAction<boolean>>
  setWorkshopHTTPI: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  displayFormDescribeWorkshopHTTPI: boolean
  elementId: string
}

export default function FormDescribeWorkshopHTTPI({ setDisplayFormDescribeWorkshopHTTPI, setWorkshopHTTPI, displayFormDescribeWorkshopHTTPI, elementId }: FormEditWeeklyPlanProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const huddleCancel = () => {
    setDisplayFormDescribeWorkshopHTTPI(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const textArea = form.querySelectorAll('textarea').item(0);

    const workshopHTTPICostumer = new UploadWorkshopHTTPICostumer(undefined, elementId, textArea);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      workshopHTTPICostumer.postDescription().then(response => {
        if (response.error) {
          setIsLoading(false);
          return;
        }

        if (permission === 'professor') {
          const decentWork = response.filter((value: any) => value.issuer?.userId === user.id)
          setWorkshopHTTPI(decentWork);

          textArea.value = ''
          setDisplayFormDescribeWorkshopHTTPI(false);
          setIsLoading(false);
          return;
        }

        setWorkshopHTTPI(response);

        textArea.value = ''

        setDisplayFormDescribeWorkshopHTTPI(false);
        setIsLoading(false);
      }).catch(e => {
        //
      });
    }).catch(e => {
      //
    })
  };

  return (
    <div className={`form-describe-school-teaching-work-plan display-${String(displayFormDescribeWorkshopHTTPI)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          Adicionar Comentário
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormDescribeWorkshopHTTPI(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-describe-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-file-element">
          <label htmlFor="textArea-describe-input-kindegarten-school-word-plan">
            Comentário
          </label>
          <textarea id='textArea-describe-input-kindegarten-school-word-plan' />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
