/* eslint-disable react/jsx-key */

import React, { useEffect, useState } from 'react';
import './styles.scss';
import { type QuestionContentType } from '../../../class/types/student-exams/student-exams-protocol';
import { BsThreeDotsVertical } from 'react-icons/bs';
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import { useParams } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import StudentExamFormAddElement from '../student-exam-form-add-element';
import TitleElementContent from '../title-element';
import ImageElementContent from '../image-element';
import OptionsElementContent from '../options-element';
import TextElementContent from '../text-element';

export interface StudentExamQuestionContentProps {
  question: QuestionContentType
  setQuestion: React.Dispatch<React.SetStateAction<QuestionContentType[]>>
}

export default function StudentExamQuestionContent({ question, setQuestion }: StudentExamQuestionContentProps) {
  const [displayConfig, setDisplayConfig] = useState(false);
  const [displayFormAddElement, setDisplayFormAddElement] = useState<boolean>(false);

  const { id } = useParams();

  const huddleDisplayConfig = () => {
    setDisplayConfig(!displayConfig);
  }

  const huddleDisplayFormAddElement = () => {
    setDisplayFormAddElement(!displayFormAddElement);
  }

  const huddleDelete = () => {
    setDisplayConfig(false);

    if (!id || !question.id) {
      return;
    }

    const studentExamsApi = new StudentExamsApi();

    studentExamsApi.deleteQuestion(id, question.id).then(response => {
      setQuestion(response);
    }).catch(e => {
      //
    });
  }

  return (
    <div className='student-exam-question-content'>
      <StudentExamFormAddElement setDisplay={setDisplayFormAddElement} setQuestion={setQuestion} display={displayFormAddElement} questions={question} />
      <div className='student-exam-question-content-options-button-display'>
        <button onClick={huddleDisplayConfig}>
          <BsThreeDotsVertical size={20} />
        </button>
        <div className={`config-display display-${String(displayConfig)}`}>
          <ul>
            {/* <li>
              Configurar
            </li> */}
            <li onClick={huddleDelete}>
              Apagar
            </li>
          </ul>
        </div>
      </div>
      <div className='student-exam-question-content-elements'>
        {question.elements
          ? (
            Object.keys(question.elements).map(key => {
              if (!question.elements) {
                return <></>;
              }
              if (!question.elements[key]) {
                return <></>
              }
              if (question.elements[key].type === 'img') {
                return <ImageElementContent element={question.elements[key]} elementId={key} questionId={question.id ?? ''} setQuestion={setQuestion} />
              }

              if (question.elements[key].type === 'options') {
                return <OptionsElementContent element={question.elements[key]} elementId={key} questionId={question.id ?? ''} setQuestion={setQuestion} />
              }

              if (question.elements[key].type === 'text') {
                return <TextElementContent element={question.elements[key]} elementId={key} questionId={question.id ?? ''} setQuestions={setQuestion} />
              }

              return <TitleElementContent setQuestions={setQuestion} questionId={question.id ?? ''} elementId={key} element={question.elements[key]} />
            })
          )
          : ''}
      </div>
      <div className='button-add-element-student-exam-content'>
        <button onClick={huddleDisplayFormAddElement}>
          <h1>Adicionar um Novo Elemento</h1>
          <FiPlus className='icon-content' size={20} />
        </button>
      </div>
    </div>
  )
}
