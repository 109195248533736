/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import './style.scss'
import type MunicipalEducationPlanMonitoringSheetType from '../../../class/types/municipal-education-plan-monitoring-sheet';
import { type MonitoringSheetGoalType } from '../../../class/types/municipal-education-plan-monitoring-sheet';
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import { useParams } from 'react-router-dom';

interface TableContentProps {
  display: string
  goalInfo: MonitoringSheetGoalType | undefined
}

export default function TableContent({ display, goalInfo }: TableContentProps): React.ReactElement {
  const [monitoringSheetGoal, setMonitoringSheetGoal] = useState<MunicipalEducationPlanMonitoringSheetType | undefined>();
  const { id } = useParams();

  const [goal, setGoal] = useState<MonitoringSheetGoalType>();

  const [year, setYear] = useState<number>(0);

  useEffect(() => {
    if (!display || !id) {
      return;
    }

    const municipalEducationMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    municipalEducationMonitoringSheetApi.get().then(response => {
      const responseFiltered = response.find((value) => value.id === id);
      if (!responseFiltered) {
        return;
      }

      setYear(Number(responseFiltered?.period.end) - Number(responseFiltered?.period.init));
      setMonitoringSheetGoal(responseFiltered);
    }).catch(() => {
      //
    });

    municipalEducationMonitoringSheetApi.getAllGoal(id).then(response => {
      const responseFormatted = response[Number(goalInfo?.number) - 1]
      setGoal(responseFormatted);
    }).catch(e => {
      //
    });
  }, [goalInfo, display]);

  if (!goalInfo || !display) {
    return <></>;
  }

  return (
    <div className='municipal-education-plan-monitoring-sheet-goal-table-content'>
      <main className='municipal-education-plan-monitoring-sheet-goal-main'>
        <header className='municipal-education-plan-monitoring-sheet-goal-table-header'>
          <h1>Ficha de monitoramento do plano municipal de educação - {goalInfo.name}</h1>
        </header>
        {/* <div>
          <button type='button' onClick={() => {
            generatePDF();
          }}>Imprimir</button>
        </div> */}
        <div id='municipal-education-plan-monitoring-sheet-goal-table'>
          <table className='municipal-education-plan-monitoring-sheet-goal-table'>
            <thead>
              <tr className='municipal-education-plan-monitoring-sheet-goal-table-header'>
                <td className='table-td-background-gray'>
                  Município
                </td>
                <td>
                  {monitoringSheetGoal?.county}
                </td>
                <td className='table-td-background-gray'>
                  Cod. Municipal
                </td>
                <td>
                  {monitoringSheetGoal?.codCount}
                </td>
                <td className='table-td-background-gray'>
                  Microrregião
                </td>
                <td>
                  {monitoringSheetGoal?.microRegion}
                </td>
                <td className='table-td-background-gray'>
                  Mesorregião
                </td>
                <td>
                  {monitoringSheetGoal?.mesoRegion}
                </td>
                <td className='table-td-background-gray'>
                  UF
                </td>
                <td colSpan={Number(Math.floor(year * 0.3).toFixed(1))}>
                  {monitoringSheetGoal?.uf}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='table-td-background-gray'>Plano Municipal de Educação:</td>
                <td colSpan={year + 1}>{monitoringSheetGoal?.municipalEducationPlan}</td>
              </tr>
              <tr>
                <td className='table-td-background-gray'>Período de Avaliação Previsto:</td>
                <td>{monitoringSheetGoal?.expectedEvaluationPeriods}</td>
                <td colSpan={Number(Math.floor(year * 0.2).toFixed(1))} className='table-td-background-gray'>Ano da primeira avaliação</td>
                <td colSpan={Number(Math.floor(year * 0.90).toFixed(1))}>{monitoringSheetGoal?.yearOfFirstAssessment}</td>
              </tr>
              <tr>
                <td className='table-td-background-gray table-td-spaced'>Comissão de Coordenadora</td>
                <td colSpan={Number(Math.floor(year * 0.60).toFixed(1))}>{monitoringSheetGoal?.coordinatingCommittee.names}</td>
                <td colSpan={Number(Math.floor(year * 0.50).toFixed(1))}>{monitoringSheetGoal?.coordinatingCommittee.law}</td>
              </tr>
              <tr>
                <td className='table-td-background-gray table-td-spaced'>Equipe Técnica:</td>
                <td colSpan={Number(Math.floor(year * 0.60).toFixed(1))}>{monitoringSheetGoal?.technicalTeam.names}</td>
                <td colSpan={Number(Math.floor(year * 0.50).toFixed(1))}>{monitoringSheetGoal?.technicalTeam.law}</td>
              </tr>
              <tr>
                <td className='table-td-background-gray'>Contatos de referência:</td>
                <td>Telefone:</td>
                <td colSpan={Number(Math.floor(year * 0.45).toFixed(1))}>{monitoringSheetGoal?.referenceContacts.tell}</td>
                <td>E-mail:</td>
                <td colSpan={Number(Math.floor(year * 0.50).toFixed(1))}>{monitoringSheetGoal?.referenceContacts.email}</td>
              </tr>
              <tr>
                <td className='table-td-background-gray table-td-spaced' align='center'>Meta</td>
                <td className='table-td-background-gray table-td-spaced' colSpan={year - (year - 4)}>Texto da meta</td>
                <td className='table-td-background-gray table-td-spaced'>Prazo</td>
                <td className='table-td-background-gray table-td-spaced' colSpan={year - (year - 6)}>Observações/Relato sintético (opcional)</td>
              </tr>
              <tr>
                <td className='table-td-spaced' align='center'>{goalInfo.number}</td>
                <td className='table-td-spaced' colSpan={year - (year - 4)}>{goal?.goal?.goalText}</td>
                <td className='table-td-spaced'>{goal?.goal?.deadLine}</td>
                <td className='table-td-spaced' colSpan={year - (year - 6)}>{goal?.goal?.observation}</td>
              </tr>
              {goal?.targetIndicator
                ? Object.keys(goal.targetIndicator).map((_key: string, _index: number) => {
                  return (
                    <>
                      <tr>
                        <td rowSpan={2} className='table-td-spaced table-td-background-gray'>INDICADOR {_index + 1}</td>
                        <td colSpan={year - 3}>{goal.targetIndicator[_key].content}</td>
                        <td>Prazo</td>
                        <td>{goal.targetIndicator[_key].term}</td>
                        <td>Alcançou indicador?</td>
                        <td>{goal.targetIndicator[_key].reachedTheTerm}</td>
                      </tr>
                      <tr>
                        {'-v'.repeat(year).split('-').map((value, index) => {
                          return <td>{Number(monitoringSheetGoal?.period.init) + index}</td>
                        })}
                      </tr>
                      <tr>
                        <td>Meta prevista</td>
                        {'-v'.repeat(year).split('-').map((value, index) => {
                          return <td>{goal.targetIndicator[_key].indicators?.expectedGoal[index] ?? ''}</td>
                        })}
                      </tr>
                      <tr>
                        <td>Meta executada no período (dado oficial)</td>
                        {'-v'.repeat(year).split('-').map((value, index) => {
                          return <td>{goal.targetIndicator[_key].indicators?.executedGoalDO[index] ?? ''}</td>
                        })}
                      </tr>
                      <tr>
                        <td>Meta executada no período (dado extraoficial)	</td>
                        {'-v'.repeat(year).split('-').map((value, index) => {
                          return <td>{goal.targetIndicator[_key].indicators?.executedGoalDE ?? ''}</td>
                        })}
                      </tr>
                    </>
                  )
                }) : <></>}
              {goal?.strategy ? (
                <>
                  <tr>
                    <td className='table-td-background-gray'>Meta</td>
                    <td className='table-td-background-gray' align='center'>{goal.number}</td>
                    <td className='table-td-background-gray' colSpan={Number(year)}>{goal.goal.goalText}</td>
                  </tr>
                  <tr>
                    <td colSpan={Number(Math.floor(year * 0.20).toFixed(1))}>Estratégias (da meta acima indicada)</td>
                    <td>Prazo</td>
                    <td colSpan={Number(Math.floor(year * 0.30).toFixed(1))}>Previsões Orçamentárias</td>
                    <td>Status</td>
                    <td>Executou estratégia?</td>
                    <td colSpan={Number(Math.floor(year * 0.40).toFixed(1))}>Observações</td>
                  </tr>
                </>
              ) : <></>}
              {goal?.strategy ? (
                Object.keys(goal?.strategy).map((key: string) => {
                  return (
                    <tr>
                      <td colSpan={Number(Math.floor(year * 0.20).toFixed(1))}>{goal?.strategy[key].strategies}</td>
                      <td>{goal?.strategy[key].term}</td>
                      <td colSpan={Number(Math.floor(year * 0.30).toFixed(1))}>{goal?.strategy[key].budgetForecast}</td>
                      <td>{goal?.strategy[key].status}</td>
                      <td>{goal?.strategy[key].executedTheStrategy}</td>
                      <td colSpan={Number(Math.floor(year * 0.40).toFixed(1))}>{goal?.strategy[key].observation}</td>
                    </tr>
                  )
                })
              ) : <></>}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  )
}
