import React, { useEffect, useState } from 'react';
import './style.scss';
import { type TextualGenreTagType, type TextualGenreType } from '../../../../../class/types/new-writing-hypothesi/textual-genre';
import TextualGenreFormAddTag from '../form-add-tags';
import TextualGenreTagsApi from '../../../../../class/new-writing-hypothesi/textual-genre-tags/textual-genre-tags-api';
import { useParams } from 'react-router-dom';
import LoadContent from '../../../../../components/Load-content';
import TextualGenreTagElement from '../../../../../components/new-writing-hypothesis/textual-genre-tag-element';
import TextualGenreFormEditTag from '../form-edit-tags';
import lscache from 'lscache';
import EmptyComponent from '../../../../../components/Empty-component';

interface TextualGenreTagTableProps {
  display: boolean
  elementSelected: TextualGenreType | null
  roomInfo: any
  setDisplay: () => void
}

export default function TextualGenreTagTable({ display, elementSelected, roomInfo, setDisplay }: TextualGenreTagTableProps): React.ReactElement {
  const [displayFormAddTag, setDisplayFormAddTag] = useState(false);
  const [displayFormEditTag, setDisplayFormEditTag] = useState(false);
  const [textualGenreTags, setTextualGenreTags] = useState<TextualGenreTagType[]>([]);
  const [tagSelected, setTagSelected] = useState<TextualGenreTagType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [displayPasteButton, setDisplayPasteButton] = useState(false);

  const { id } = useParams();

  const copyTextualGenreTags = () => {
    lscache.setExpiryMilliseconds(60000);

    lscache.set('copy', {
      type: 'textualGenreTag',
      id: elementSelected?.id,
      tags: textualGenreTags,
    }, 60 * 10);

    setDisplayPasteButton(false);
  }

  const pasteTextualGenreTags = () => {
    const copy = lscache.get('copy');

    if (!copy || !id || !elementSelected?.id || copy.tags.length === 0) {
      return;
    }

    const textualGenreTagsApi = new TextualGenreTagsApi();

    textualGenreTagsApi.paste(id, roomInfo.twoMonths, elementSelected.id, copy.tags as TextualGenreTagType[]).then(response => {
      setTextualGenreTags(response);
    }).catch(e => {
      //
    })
  }

  useEffect(() => {
    if (!id || !elementSelected) {
      return;
    }
    setDisplayPasteButton(false);

    const textualGenreTagApi = new TextualGenreTagsApi();

    setIsLoading(true);
    textualGenreTagApi.getAll(id, roomInfo.twoMonths, elementSelected.id).then(response => {
      setTextualGenreTags(response);
      setIsLoading(false);
    }).catch(e => {
      //
    })

    const tagCopy = lscache.get('copy');

    if (!tagCopy) {
      return;
    }

    if (tagCopy.type !== 'textualGenreTag') {
      return;
    }

    if (tagCopy.id === elementSelected.id) {
      return;
    }

    setDisplayPasteButton(true);
  }, [display])
  return (
    <div className={`textual-genre-tag-table display-${String(display)}`}>
      <TextualGenreFormAddTag
        display={displayFormAddTag}
        setDisplay={setDisplayFormAddTag}
        roomInfo={roomInfo}
        textualGenreSelected={elementSelected}
        setTextualGenreTags={setTextualGenreTags}
      />
      <TextualGenreFormEditTag
        display={displayFormEditTag}
        setDisplay={setDisplayFormEditTag}
        roomInfo={roomInfo}
        textualGenreSelected={elementSelected}
        setTextualGenreTags={setTextualGenreTags}
        textualGenreTagSelected={tagSelected}
      />
      <div className='textual-genre-tag-table-header'>
        <div className='textual-genre-tag-table-header-title'>
          <h1>Níveis de Escrita - {elementSelected?.genre}</h1>
          <button className='textual-genre-tag-table-header-button' onClick={setDisplay}>Voltar</button>
        </div>
        <div className='textual-genre-tag-table-header'>
          <button onClick={() => {
            setDisplayFormAddTag(true)
          }}>Adicionar Nível de Escrita</button>
          {displayPasteButton ? <button onClick={pasteTextualGenreTags}>Colar Níveis de Escrita</button> : ''}
          <button onClick={copyTextualGenreTags}>Copiar Níveis de Escrita</button>
        </div>
      </div>
      <div className='textual-genre-tag-table-main'>
        <div className='textual-genre-tag-table-main-title'>
          <ul>
            <li>N°</li>
            <li>Nível de Escrita</li>
            <li>Data</li>
            <li className='color-content'>Cor</li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className='textual-genre-tag-table-main-elements'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  container: {
                    marginTop: '10rem',
                  }
                }}
              />
            )
            : (
              textualGenreTags.length === 0
                ? <EmptyComponent>click em (Adicionar Níveis de Escrita) para adicionar um novo nível de escrita</EmptyComponent>
                : textualGenreTags.map((tag, index) => {
                  return <TextualGenreTagElement
                    key={tag.id}
                    index={index}
                    roomInfo={roomInfo}
                    setTextualGenreTags={setTextualGenreTags}
                    textualGenreSelected={elementSelected}
                    setDisplayFormEditTag={setDisplayFormEditTag}
                    setTagSelected={setTagSelected}
                    textualGenreTag={tag}
                  />
                })
            )}
        </div>
      </div>
    </div>
  )
}
