import axios from 'axios';
import config from '../../config/config';
import type MedicalRecordType from '../types/Psychologist';
import { type PsychologistAnamnese } from '../types/Psychologist';

export default class PsychologistApi {
  async post(student: PsychologistAnamnese): Promise<PsychologistAnamnese[]> {
    let students: PsychologistAnamnese[] = [];

    await axios.post(config.apiUrl + '/psychologist', student).then(response => {
      students = response.data as PsychologistAnamnese[];
    })

    return students;
  }

  async putStudent(student: PsychologistAnamnese, studentId: string): Promise<PsychologistAnamnese[]> {
    let students: PsychologistAnamnese[] = [];

    await axios.put(config.apiUrl + `/psychologist/${studentId}`, student).then(response => {
      students = response.data;
    }).catch(e => {
      //
    });

    return students;
  }

  async getAllStudent(): Promise<PsychologistAnamnese[]> {
    let students: PsychologistAnamnese[] = [];

    await axios.get(config.apiUrl + '/psychologist').then(response => {
      students = response.data;
    });

    return students;
  }

  async deleteStudent(studentId: string): Promise<PsychologistAnamnese[]> {
    let students: PsychologistAnamnese[] = [];

    await axios.delete(config.apiUrl + '/psychologist/' + studentId).then(response => {
      students = response.data;
    });

    return students;
  }

  async postMedicalRecord(studentId: string, medicalRecord: MedicalRecordType): Promise<MedicalRecordType[]> {
    let newMedicalRecord: MedicalRecordType[] = [];

    await axios.post(config.apiUrl + `/psychologist/medicalRecord/${studentId}`, medicalRecord).then(response => {
      newMedicalRecord = response.data;
    });

    return newMedicalRecord;
  }

  async putMedicalRecord(studentId: string, medicalRecordId: string, medicalRecord: MedicalRecordType): Promise<MedicalRecordType[]> {
    let newMedicRecord: MedicalRecordType[] = [];

    await axios.put(config.apiUrl + `/psychologist/medicalRecord/${studentId}/${medicalRecordId}`, medicalRecord).then(response => {
      newMedicRecord = response.data;
    });

    return newMedicRecord;
  }

  async getAllMedicRecord(studentId: string): Promise<MedicalRecordType[]> {
    let newMedicRecord: MedicalRecordType[] = [];

    await axios.get(config.apiUrl + `/psychologist/medicalRecord/${studentId}`).then(response => {
      newMedicRecord = response.data;
    });
    return newMedicRecord
  }

  async deleteMedicRecord(studentId: string, medicalRecordId: string): Promise<MedicalRecordType[]> {
    let newMedicRecord: MedicalRecordType[] = [];

    await axios.delete(config.apiUrl + `/psychologist/medicalRecord/${studentId}/${medicalRecordId}`).then(response => {
      newMedicRecord = response.data;
    }).catch(e => {
      //
    });

    return newMedicRecord;
  }
}
