/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React from 'react';
import './style.scss';
import { MdEmail } from 'react-icons/md';

interface FormLoginElementProps {
  huddleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  displayForm: boolean
}

export default function FormRecoverPasswordElement({ huddleSubmit, displayForm }: FormLoginElementProps): React.ReactElement {
  return (
		<div className='form-recover-password-main'>
			<form action='POST' className={`form-login-element display-${!displayForm}`} onSubmit={e => {
			  huddleSubmit(e);
			}}>
				<div className='form-login-title'>
					<h1>Digite uma nova senha</h1>
				</div>
				<div className='input-login-content'>
					<label htmlFor='password-one'>Senha: </label>
					<br />
					<div className='input-content-recover-by-email'>
						<MdEmail size={18} className='icon-user-login' />
						<input type='text' id='password-one' placeholder='digite a sua nova senha' />
					</div>
				</div>
				<div className='input-login-content'>
					<label htmlFor='password-two'>Repita a senha: </label>
					<br />
					<div className='input-content-recover-by-email'>
						<MdEmail size={18} className='icon-user-login' />
						<input type='text' id='password-two' placeholder='repita a senha anterior' />
					</div>
				</div>
				<div className='input-login-content button-login-content'>
					<button type='submit' className='button-login'>Recuperar</button>
				</div>
			</form>
		</div>
  );
}
