/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState, useRef } from 'react';

import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import UploadWorkshopHTTPICostumer from '../../../class/UploadWorkShopHTTPI/upload-HTTPI-custumer';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';

interface FormAddWeeklyPlanPlanProps {
  setDisplayFormAddWorkshopHTTPI: React.Dispatch<React.SetStateAction<boolean>>
  setWorkshopHTTPI: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  displayFormAddWorkshopHTTPI: boolean
  workshopHTTPI: ElementarySchoolDecentWorkType[]
}

export default function FormAddWorkshopHTTPI({ setDisplayFormAddWorkshopHTTPI, setWorkshopHTTPI, displayFormAddWorkshopHTTPI, workshopHTTPI }: FormAddWeeklyPlanPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputDataRef = useRef<HTMLInputElement | null>(null);

  const huddleCancel = () => {
    setDisplayFormAddWorkshopHTTPI(false);
    clearAllInput();
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const workshopHTTPICustomer = new UploadWorkshopHTTPICostumer(inputs);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      workshopHTTPICustomer.post().then(response => {
        if (response.error) {
          setIsLoading(false);
          return;
        }

        if (permission === 'professor') {
          const decentWork = response.filter((value: any) => value.issuer?.userId === user.id)
          setWorkshopHTTPI(decentWork);

          setDisplayFormAddWorkshopHTTPI(false);
          setIsLoading(false);
          clearAllInput();
          return;
        }
        setWorkshopHTTPI(response);

        setDisplayFormAddWorkshopHTTPI(false);
        setIsLoading(false);
        clearAllInput();
      }).catch(e => {
        //
      });
    }).catch(e => {
      //
    })
  };

  const clearAllInput = () => {
    setFileInfo(undefined);
    if (!inputRef.current || !inputDataRef.current) {
      return;
    }

    inputRef.current.value = '';
    inputDataRef.current.value = '';
  }

  const setDateHuddle = () => {
    const date = (new Date()).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    }).split('/');

    const formattedDate = `${date[2]}-${date[1]}-${date[0]}`;

    setDate(formattedDate);
  }

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  useEffect(() => {
    setDateHuddle();
  }, [displayFormAddWorkshopHTTPI])

  return (
    <div className={`form-add-school-elementary-teaching-weekly-plan display-${String(displayFormAddWorkshopHTTPI)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Adicionar nova HTTPI
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddWorkshopHTTPI(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label htmlFor='class-room-content-form-add-kindergarten-school-teaching-work-plan'>Sala:</label>
          <input ref={inputRef} id='class-room-content-form-add-kindergarten-school-teaching-work-plan' type="text" />
        </div>
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date" ref={inputDataRef}
            // min={date}
          />
        </div>
        <div className="input-file-element">
          <label htmlFor="file-input-upload-workshop-httpi">
            <DisplayFileInfo fileInfo={fileInfo} />
          </label>
          <input
            type="file"
            id='file-input-upload-workshop-httpi'
            onChange={inputFileHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={{
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }}
                />
              )
              : (
                <>
                  <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                  <button type='submit' className='save-button'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
