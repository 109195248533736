export default class FilterPlan {
  private readonly teacherName: string;
  private readonly inputRoomName: string;
  private readonly firstDate: string;
  private readonly lastDate: string;

  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement> | any,
    private readonly plans: any[],
  ) {
    this.teacherName = this.inputs[0].value;
    this.inputRoomName = this.inputs[1].value;
    this.firstDate = this.inputs[2].value;
    this.lastDate = this.inputs[3].value;
  }

  searchPlans(): any[] {
    const newPlans: any[] = [];
    let plansFilterByNameTeacher = [];

    if (this.teacherName.length > 0) {
      plansFilterByNameTeacher = this.searchByNameTeacher();
    } else {
      plansFilterByNameTeacher = this.plans;
    }

    plansFilterByNameTeacher.forEach((plan: any) => {
      if (!plan.classRoom) {
        return;
      }
      const classRoomName = (plan.classRoom as string)
        .slice(0, this.inputRoomName.length)
        .trim()
        .replaceAll(' ', '')
        .toLowerCase();

      const inputClassRoomName = this.inputRoomName
        .trim()
        .replaceAll(' ', '')
        .toLowerCase();

      if (classRoomName === inputClassRoomName) {
        newPlans.push(plan);
      }
    });

    return this.filterPlanByDate(newPlans);
  }

  searchRoomObserver(): any[] {
    const newPlans: any[] = [];

    this.plans.forEach((plan: any) => {
      if (!plan.classRoom.roomName) {
        return;
      }
      const classRoomName = (plan.classRoom.roomName as string)
        .slice(0, this.inputRoomName.length)
        .trim()
        .replaceAll(' ', '')
        .toLowerCase();

      const inputClassRoomName = this.inputRoomName
        .trim()
        .replaceAll(' ', '')
        .toLowerCase();

      if (classRoomName === inputClassRoomName) {
        newPlans.push(plan);
      }
    });

    return this.filterPlanByDate(newPlans);
  }

  searchByNameTeacher(): any[] {
    const newPlans: any[] = [];

    this.plans.forEach(plan => {
      if (!plan.issuer.userName) {
        return;
      }
      const teacherName = (plan.issuer.userName as string)
        .slice(0, this.teacherName.length)
        .trim()
        .replaceAll(' ', '')
        .toLowerCase();

      const inputTeacherName = this.teacherName
        .trim()
        .replaceAll(' ', '')
        .toLowerCase();

      if (teacherName === inputTeacherName) {
        newPlans.push(plan);
      }
    });

    return newPlans;
  }

  filterPlanByDate(plans: any[]): any[] {
    const newPlans: any = [];

    const firstDate = new Date(this.firstDate);
    const lastDate = new Date(this.lastDate);

    plans.forEach((value) => {
      const planDate = new Date(value.date);

      if (this.firstDate.length === 0) {
        return;
      }
      if (planDate < firstDate) {
        return
      }

      if (this.lastDate.length === 0) {
        return;
      }

      if (planDate > lastDate) {
        return
      }
      newPlans.push(value);
    });

    if (newPlans.length === 0) {
      return plans;
    }

    return newPlans;
  }
};
