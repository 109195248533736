/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-negated-condition */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import './style.scss';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';

import StudentAPI from '../../../class/Student/StudentAPI';
import { IoCloseOutline } from 'react-icons/io5';

interface FormAddElementProps {
  displayStudentInfo: boolean
  setDisplayStudentInfo: React.Dispatch<React.SetStateAction<boolean>>
  students: StudentType[]
  elementId: string | undefined
}

export default function StudentInfo({ displayStudentInfo, students, setDisplayStudentInfo, elementId }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [option, setOption] = useState<string>();
  const [date, setDate] = useState<string>('');
  const [student, setStudent] = useState<StudentType>();

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayStudentInfo(false);
  };

  const formatDate = (date: string) => {
    const objDate = new Date(date);

    return objDate.toLocaleDateString('UTC', {
      timeZone: 'UTC',
    });
  };

  useEffect(() => {
    setDisplayElement(displayStudentInfo);

    students.forEach((element: any) => {
      if (element.id === elementId) {
        setStudent(element);
        setDate(element.birthday);
      }
    });
  }, [displayStudentInfo]);
  return (
    <div className={`student-info display-${displayElement}`}>
      <form
        method='POST'
        className='form-edit-element'
        id='form-edit-element-student'
      >
        <div className='header-form-add-element-content'>
          <h1>
            Informação do Aluno
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose();
          }} size='25' />
        </div>
        <div className='main-content-element'>
          <div className='header-form-edit-element'>
            <h1>
              Aluno(a):
            </h1>
            <span>{!student ? '' : student.studentName}</span>
          </div>
          <div className='body-form-edit-element'>
            <div className='input-content'>
              <h1>Data de nascimento:</h1>
              <h2 className='info-element'>{formatDate(date)}</h2>
            </div>
            <div className='input-content'>
              <h1 >Responsável pelo aluno(a):</h1>
              <h2 className='info-element'>{!student?.responsible ? 'Responsável pelo aluno' : student?.responsible}</h2>
            </div>
            <div className='input-content'>
              <h1 >Numero de contato:</h1>
              <h2 className='info-element'>{!student?.responsibleNumber ? 'Numero de contato' : student?.responsibleNumber}</h2>
            </div>
            <div className='input-content'>
              <h1 >RA:</h1>
              <h2 className='info-element'>{!student?.ra?.content ? '' : student?.ra?.content} - {!student?.ra?.uf ? '' : student?.ra?.uf}</h2>
            </div>
            <div className='input-content'>
              <h1 >Bairro:</h1>
              <h2 className='info-element'>{!student?.district ? 'bairro' : student?.district}</h2>
            </div>
            <div className='input-content input-list-content'>
              <h1 >Sexo:</h1>
              <h2 className='info-element'>{!option ? student?.sex : option}</h2>
            </div>
            <div className='input-content input-list-content'>
              <h1 >Situação:</h1>
              <h2 className='info-element'>{!student?.situation ? '' : student?.situation} {!student?.situationModified ? '' : `(situação atualizada em ${student?.situationModified})`}</h2>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
