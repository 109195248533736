/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import Table from '../table';

interface NotificationManagerProps {
  display: string
  id: string
}

export default function NotificationManager({display, id}: NotificationManagerProps): React.ReactElement {
  return (
    <div className={`notification-container display-${display}`} id={id}>
      <Table />
    </div>
  );
}
