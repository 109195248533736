/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useEffect, useState } from 'react';
import type { StudentWritingLRRes } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import { IoIosArrowDown } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import lscache from 'lscache';
import './style.scss';
import { type ReadingFluencyLevelResType } from '../../../class/types/ReadingFluencyLevel/reading-fluency-level-api-protocol';

interface ContentTableType {
  student: StudentType
  index: number
  spy: any
  readingFluency: ReadingFluencyLevelResType
  readingFluencyTags: string[]
}

export default function ContentTableReadingFluencyPage({ student, spy, readingFluency, readingFluencyTags, index }: ContentTableType): React.ReactElement {
  const { id } = useParams();
  const [user, setUser] = useState<UserClientProtocol>();
  const [displayOption, setDisplayOption] = useState(false);
  const [option, setOption] = useState('');

  useEffect(() => {
    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, []);

  const huddleDisplayOptions = () => {
    try {
      const user = lscache.get('user') as UserClientProtocol;
      if (user.permission?.rule === 'professor') {
        return;
      }
    } catch (e) {
      return;
    }

    setDisplayOption(!displayOption);
  };

  const huddleClickLi = (e: React.MouseEvent<HTMLLIElement>) => {
    const li = e.target as HTMLLIElement;
    setOption(li.textContent ?? '');
    setDisplayOption(false);
  };

  useEffect(() => {
    try {
      setOption(readingFluency.readingFluencyLevel);
    } catch {
      setOption('');
    }
  }, [spy, readingFluency]);

  return (
		<div className='content-table-element-reading-fluency-page'>
			<ul className='content-table-ul-rom'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{student.studentName}
					</p>
				</li>
				<li className='input-select-content'>
					<label onClickCapture={huddleDisplayOptions} className='label-select-input-content' htmlFor={`${student.id}`}>
						<h1>{!option ? 'Nível de fluência' : option}</h1>
						<IoIosArrowDown className={'icon-arrow display-'} size='25' color='rgb(172, 172, 172)' />
					</label>
					<input type='text' id={`${student.id}`} value={option} />
					<ul className={`options-select-content display-${displayOption}`}>
						{readingFluencyTags.map((tag, index) => (
							<li onClickCapture={huddleClickLi} key={`writing-Level-tag-${index}`}>
								{tag}
							</li>
						))}
            <li onClickCapture={huddleClickLi}>
              Transferido
            </li>
					</ul>
				</li>
			</ul>
		</div>
  );
}
