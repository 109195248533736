import React, { useEffect, useState } from 'react';
import './styles.scss';
import { IoCloseOutline } from 'react-icons/io5';
import StudentExamsApi from '../../../class/Student-Exams/student-exams-api';
import { useParams } from 'react-router-dom';
import { type StudentExamsType } from '../../../class/types/student-exams/student-exams-protocol';

interface ConfigStudentExamProps {
  display: boolean
  studentExam: StudentExamsType | undefined
  huddleDisplay: () => void
  setStudentExam: React.Dispatch<React.SetStateAction<StudentExamsType | undefined>>
}

export default function ConfigStudentExam({display, studentExam, huddleDisplay, setStudentExam}: ConfigStudentExamProps): React.ReactElement {
  const [visibility, setVisibility] = useState(false);

  const {id} = useParams();

  const huddleVisibility = () => {
    setVisibility(!visibility);

    if (!id) {
      return;
    }

    const studentExamApi = new StudentExamsApi();

    studentExamApi.setVisibility(id, !visibility).then(response => {
      setStudentExam(response);
    }).catch(e => {
      //
    });
  }

  useEffect(() => {
    if (typeof studentExam?.visibility !== 'boolean') {
      return;
    }
    setVisibility(studentExam?.visibility);
  }, [display]);
  return (
    <div className={`config-student-exams display-${String(display)}`}>
      <div className='config-student-exam-header'>
        <h3>Configuração</h3>
        <IoCloseOutline className='icon-content' onClickCapture={huddleDisplay} size={30} />
      </div>
      <div className='config-student-exam-input-container'>
        <div className='input-content'>
          <h3>Alterar disponibilidade da prova:</h3>
          <button className={`button-exam-visibility display-${String(visibility)}`} type='button' onClickCapture={huddleVisibility}>
            <div className='icon-move-center'></div>
          </button>
        </div>
      </div>
    </div>
  );
}
