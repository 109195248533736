import type {StudentType} from '../../types/Student/student-factory-protocol';

export type StudentObjType = Record<string, StudentType>;

export default function transformInObj(newStudent: StudentType[]): StudentObjType {
	const studentObj: StudentObjType = {};

	newStudent.forEach(student => {
		if (!student.id) {
			return;
		}

		studentObj[student.id] = student;
	});

	return studentObj;
}
