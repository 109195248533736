
import Validator from '../Validator';
import { type StudentReportType } from '../types/StudentReport';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import UploadStudentReportApi from './upload-student-report-api';
import lscache from 'lscache';

class UploadWorkshopStudentReportCostumer {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement> | undefined = undefined,
		private readonly HTTPIId = '',
	) {}

	get _user(): UserClientProtocol {
		const user = lscache.get('user') as UserClientProtocol;

		return user;
	}

	async post(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: true
			};
		}

		const file = this.inputs.item(3).files?.item(0);

		if (!file) {
			return {
				error: true
			};
		}

		const fileName = file.name ?? '';
		const date = this.inputs.item(2).value;
    const twoMonths = this.inputs.item(1).value;
    const classRoom = this.inputs.item(0).value;

		const user = this._user;

		let WorkshopStudentReport: StudentReportType[] = [];

		await UploadStudentReportApi.post({
			file,
      classRoom,
      twoMonths,
			date,
			name: fileName,
			userId: user.id ?? '',
			userName: user.userName
		}).then(response => {
			WorkshopStudentReport = response;
		}).catch(e => {
			//
		});

		return WorkshopStudentReport;
	}

	async put(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: false
			};
		}

		const file = this.inputs.item(0).files?.item(0);

		if (!file) {
			return {
				error: false
			};
		}

		const fileName = file.name ?? '';

		let WorkshopStudentReport: StudentReportType[] = [];

		await UploadStudentReportApi.put({
			file,
			name: fileName,
			studentReportId: this.HTTPIId,
		}).then(response => {
			WorkshopStudentReport = response;
		});

		return WorkshopStudentReport;
	}
}

export default UploadWorkshopStudentReportCostumer;
