import type { Frequency, FrequencyType } from '../types/Frequency/frequency-factory-protocol';
import type FrequencyFactoryProtocol from '../types/Frequency/frequency-factory-protocol';
import type { StudentType } from '../types/Student/student-factory-protocol';

class FrequencyFactory implements FrequencyFactoryProtocol {
  private date = '';

  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly students: StudentType[],
    private readonly roomId: string
  ) {}

  createFrequency(): Frequency {
    const frequency: Frequency = [];

    const inputs = this.formateInputs();

    inputs.forEach(input => {
      const student = this.students.find(student => student.id === input.id);

      if (student) {
        frequency.push({
          studentId: student.id ?? '',
          present: input.value,
          studentName: student.studentName
        });
      }
    });

    return frequency;
  }

  create(): FrequencyType {
    return {
      roomId: this.roomId,
      frequency: this.createFrequency(),
      datePost: this.createDate(),
      date: this.date
    };
  }

  createDate(): string {
    const date = new Date();

    return date.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  }

  formateInputs(): HTMLInputElement[] {
    const newInputs: HTMLInputElement[] = [];

    this.inputs.forEach(input => {
      newInputs.push(input);
    });

    const afterInput = newInputs.pop();
    if (afterInput) {
      this.date = this.formatDate(afterInput.value);
    }

    return newInputs;
  }

  formatDate(srt: string): string {
    const date = new Date(srt);

    return date.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  }
}

export default FrequencyFactory;
