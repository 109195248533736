export default function filterHTTPIByDate(digitalHTTPI: any[], firstDate: string, lastDate: string): any[] {
  const newPlans: any = [];

  const _firstDate = new Date(firstDate);
  const _lastDate = new Date(lastDate);

  digitalHTTPI.forEach((value) => {
    const planDate = new Date(value.date);

    if (firstDate.length === 0) {
      return;
    }
    if (planDate < _firstDate) {
      return
    }

    if (lastDate.length === 0) {
      return;
    }

    if (planDate > _lastDate) {
      return
    }
    newPlans.push(value);
  });

  if (newPlans.length === 0) {
    return digitalHTTPI;
  }

  return newPlans;
}
