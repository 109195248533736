export default function clearAllInputs(): void {
  const form = document.getElementById('form-add-element-frequency') as HTMLFormElement;

  const inputs = formateInputs(form.querySelectorAll('input'));

  const afterInput = inputs.pop();

  if (afterInput) {
    afterInput.value = '';
  }

  inputs.forEach(input => {
    input.value = 'false';
  });
}

function formateInputs(inputs: NodeListOf<HTMLInputElement>): HTMLInputElement[] {
  const newInputs: HTMLInputElement[] = [];

  inputs.forEach(input => {
    newInputs.push(input);
  });

  return newInputs;
}
