/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { useEffect, useState } from 'react';
import './style.scss';

import { AiFillHome } from 'react-icons/ai';

import { useParams } from 'react-router-dom';
import lscache from 'lscache';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { IoIosArrowDown } from 'react-icons/io';
import { GoGoal } from 'react-icons/go';
import { FiPlus } from 'react-icons/fi';
import { VscGraph } from 'react-icons/vsc'
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import { type MonitoringSheetGoalType } from '../../../class/types/municipal-education-plan-monitoring-sheet';
import AddNewGoalContainer from './add-new-goal-container';

type Elements = Array<{
  name: string
  boolean: string
}>;

interface NavProps {
  elements: Elements
  setElements: (elements: Elements) => void
  setGoalInfo: React.Dispatch<React.SetStateAction<MonitoringSheetGoalType | undefined>>
}

export default function Nav({ setElements, setGoalInfo, elements }: NavProps): React.ReactElement {
  const [goal, setGoal] = useState<MonitoringSheetGoalType[]>([]);
  const [user, setUser] = useState<UserClientProtocol>();
  const roomName = useParams().roomName;

  const [display1, setDisplay1] = useState<boolean>(false);
  const [display2, setDisplay2] = useState<boolean>(false);

  const { id } = useParams();

  const huddleDisplay = (setDisplay: React.Dispatch<React.SetStateAction<boolean>>, display: boolean) => {
    setDisplay(!display);
  }

  const huddleAddNewGoal = () => {
    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    if (!id) {
      return;
    }

    municipalEducationPlanMonitoringSheetApi.postGoal(id).then(response => {
      setGoal(response);
    });
  }

  const onClickLi = (elementName: string) => {
    const elementsResected = resetElements(elements);

    elementsResected.forEach(element => {
      if (element.name === elementName) {
        element.boolean = 'true';
      }
    });
    setElements(elementsResected);
  };

  const resetElements = (elements: Elements) => {
    elements.forEach(element => {
      element.boolean = 'false';
    });
    return elements;
  };

  useEffect(() => {
    const user = lscache.get('user');
    if (id) {
      setUser(user);

      const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

      municipalEducationPlanMonitoringSheetApi.getAllGoal(id).then(response => {
        setGoal(response);
      });
    }
  }, []);

  return (
    <nav className='nav-municipal-education-plan-monitoring-sheet'>
      <div className='action-title'>
        <h1>Ficha de monitoramento do plano municipal de educação</h1>
      </div>
      <ul className='ul-option'>
        <li className='option-content-1' id='estrategy-1' onClick={e => {
          window.location.href = window.location.origin;
        }}>
          <AiFillHome size='20' color='#fff' />
          <h1 id='estrategy-1'>Inicio</h1>
        </li>
        <li className='option-content-1' onClickCapture={() => {
          huddleDisplay(setDisplay1, display1);
        }}>
          <GoGoal className='' color='#fff' size='20' />
          <div className='option-title-content'>
            <h1>Metas</h1>
            <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${display1}`} />
          </div>
        </li>
        <ul className={`option-of-rooms display-${display1}`}>
          <li className='option-element' onClick={() => {
            huddleAddNewGoal();
          }}>
            <div className='add-new-goal'>
              <h1>Adicionar Meta</h1>
              <FiPlus className='plus-icon-element' size={20} />
            </div>
          </li>
          {!goal ? '' : goal.map((value) => <AddNewGoalContainer setGoalInfo={setGoalInfo} onClickLi={onClickLi} goal={value} setGoal={setGoal} key={value.id} />)}
        </ul>
        {/* <li className='option-content-1' onClickCapture={() => {
          huddleDisplay(setDisplay2, display2);
        }}>
          <VscGraph className='' color='#fff' size='20' />
          <div className='option-title-content'>
            <h1>Gráficos</h1>
            <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${display2}`} />
          </div>
        </li>
        <ul className={`option-of-rooms display-${display2}`}>
          <li className='option-element' onClick={() => {
            onClickLi('strategy-5');
          }}>
            <div>
              <h1>Indicador</h1>
            </div>
          </li>
          <li className='option-element' onClick={() => {
            huddleAddNewGoal();
          }}>
            <div>
              <h1>Estratega</h1>
            </div>
          </li>
        </ul> */}
      </ul>
    </nav>
  );
}
