import React from 'react';
import './styles.scss';
import { IoCloseOutline } from 'react-icons/io5';
import studentAPI from '../../../class/Student/StudentAPI';
import WaitingStudentApi from '../../../class/WaitingStudent/WaitingStudentApi';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';

interface ExportWaitingStudentProps {
  display: boolean
  elementId: string | undefined
  setStudents: React.Dispatch<React.SetStateAction<StudentType[]>>
  setStudentFiltered: React.Dispatch<React.SetStateAction<StudentType[]>>
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ExportWaitingStudent({display, elementId, setStudents, setStudentFiltered, setDisplay}: ExportWaitingStudentProps): React.ReactElement {
  function hundleClickClose() {
    setDisplay(!display)
  }

  const huddleSend = () => {
    if (!elementId) {
      return;
    }

    WaitingStudentApi.postStudent(elementId).then(response => {
      setStudents(response);
      setStudentFiltered(response);
      setDisplay(false);
    }).catch(e => {
      //
    })
  }

  return (
    <div className={`export-waiting-student display-${String(display)}`}>
      <div className='header-export-waiting-student'>
        <IoCloseOutline onClick={() => {
            hundleClickClose();
          }} size='25' color='white' style={{
            cursor: 'pointer'
          }} />
      </div>
      <div className='title-export-waiting-student'>
        <h1>Você quer registrar o aluno e tirar da lista de espera?</h1>
      </div>
      <div className='btn-waiting-student-container'>
        <button onClick={() => {
          hundleClickClose();
        }}>Cancelar</button>
        <button onClick={huddleSend}>Enviar</button>
      </div>
    </div>
  )
}
