import type RoomObserverType from '../types/RoomObserver';

export default class RoomObserverFactory {
  constructor(
    private readonly textArea: HTMLTextAreaElement,
    private readonly inputData: HTMLInputElement,
  ) {}

  create(): RoomObserverType {
    return {
      content: this.textArea.value,
      date: this.inputData.value,
    }
  }
}
