
import React, { useState } from 'react';
import type { MatterResProtocol } from '../../../class/types/Matter/matterApi-protocol';
import './style.scss';

interface MatterElementProps {
  matter: MatterResProtocol
  onClickLi: (elementName: string) => void
  setActivityInfo: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
}

export default function MatterElementFinalResult({ matter, onClickLi, setActivityInfo}: MatterElementProps): React.ReactElement {
  const [displayEle, setDisplayEle] = useState(false);

  const hundleClick = (matterId: string | undefined) => {
    // If (!matterId) {
    // 	return;
    // }
    setDisplayEle(!displayEle);
  };

  const hundleClickLiActivity = (elementName: string): void => {
    onClickLi(elementName);

    setActivityInfo({
      matterId: matter.id,
      matterName: matter.matterName,
    });
  };

  return (
		<div>
			<li className='matter-element' key={matter.id} onClick={e => {
			  hundleClick(matter.id);
			  hundleClickLiActivity('strategy-6');
			}}>
				<h1>{matter.matterName}</h1>
			</li>
		</div>
  );
}
