/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type FinalResultApiProtocol from '../types/FinalResult/finalResult-type';
import type { FinalResultResType } from '../types/FinalResult/finalResult-type';
import type { UserProtocol } from '../types/User/User-factory-protocol';

class FinalResultApi implements FinalResultApiProtocol {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async getFinalResult(roomId: string, matterId: string): Promise<FinalResultResType[]> {
		let finalResult: FinalResultResType[] = [];
		if (this.href) {
			await axios.get(`${this.href}/get/existing/finalresult/${roomId}/${matterId}`).then(response => {
				finalResult = response.data as FinalResultResType[];
			});
		}

		return finalResult;
	}

	async generateFinalResult(roomId: string, matterId: string): Promise<FinalResultResType[]> {
		let finalResult: FinalResultResType[] = [];
		if (this.href) {
			await axios.get(`${this.href}/get/finalresult/${roomId}/${matterId}`).then(response => {
				finalResult = response.data as FinalResultResType[];
			});
		}

		return finalResult;
	}
}

export default new FinalResultApi();
