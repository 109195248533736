import axios from 'axios';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import config from '../../config/config';
import type MathematicsProblemSolvingTagType from '../types/MathematicsProblemSolving';
import lscache from 'lscache';
import { type MathematicsProblemSolvingObjType } from '../types/MathematicsProblemSolving';

export default class MathematicsProblemSolvingApi {
  private readonly user = lscache.get('user') as UserProtocol;
  private get href() {
    return config.apiUrl;
  }

  constructor() {
    try {
      axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
    } catch {
      //
    }
  }

  async postTag(
    problemSolvingTag: MathematicsProblemSolvingTagType,
    roomId: string
  ): Promise<MathematicsProblemSolvingTagType[]> {
    let problemSolvingTags: MathematicsProblemSolvingTagType[] = [];

    await axios
      .post(`${this.href}/mathematicsproblemsolving/tag/${roomId}`, problemSolvingTag)
      .then((response) => {
        problemSolvingTags = response.data as MathematicsProblemSolvingTagType[];
      });

    return problemSolvingTags;
  }

  async getTags(roomId: string): Promise<MathematicsProblemSolvingTagType[]> {
    let problemSolvingTags: MathematicsProblemSolvingTagType[] = [];

    await axios.get(`${this.href}/mathematicsproblemsolving/tag/${roomId}`).then((response) => {
      problemSolvingTags = response.data as MathematicsProblemSolvingTagType[];
    });

    return problemSolvingTags;
  }

  async delete(roomId: string, tagId: string): Promise<MathematicsProblemSolvingTagType[]> {
    let problemSolvingTags: MathematicsProblemSolvingTagType[] = [];

    await axios
      .delete(`${this.href}/mathematicsproblemsolving/tag/${roomId}/${tagId}`)
      .then((response) => {
        problemSolvingTags = response.data as MathematicsProblemSolvingTagType[];
      });

    return problemSolvingTags;
  }

  async post(
    roomId: string,
    twoMonths: string,
    mathematicProblemSolving: MathematicsProblemSolvingObjType
  ): Promise<MathematicsProblemSolvingObjType> {
    let problemSolving: MathematicsProblemSolvingObjType = {};

    await axios
      .post(
        `${this.href}/mathematicsproblemsolving/${roomId}/${twoMonths}`,
        mathematicProblemSolving
      )
      .then((response) => {
        problemSolving = response.data as MathematicsProblemSolvingObjType;
      });

    return problemSolving;
  }

  async getAll(roomId: string, twoMonths: string): Promise<MathematicsProblemSolvingObjType> {
    let problemSolving: MathematicsProblemSolvingObjType = {};

    await axios
      .get(`${this.href}/mathematicsproblemsolving/${roomId}/${twoMonths}`, problemSolving)
      .then((response) => {
        problemSolving = response.data;
      });

    return problemSolving;
  }

  async getDateByTwoMonths(
    roomId: string,
    twoMonths: string
  ): Promise<{
    right: number[]
    wrong: number[]
  }> {
    let dataProblemSolving: {
      right: number[]
      wrong: number[]
    } = {
      right: [],
      wrong: [],
    };

    await axios.get(`${this.href}/mathematicsproblemsolving/data/by/twoMonths/${roomId}/${twoMonths}`).then(response => {
      dataProblemSolving = response.data;
    });

    return dataProblemSolving;
  }
}
