import axios from 'axios';
import config from '../../../config/config';
import type MunicipalEducationPlanMonitoringSheetType from '../../types/municipal-education-plan-monitoring-sheet';
import { type IndicatorType, type GoalType, type MonitoringSheetGoalType, type MunicipalEducationPlanMonitoringSheetApiProtocol, type StrategyType } from '../../types/municipal-education-plan-monitoring-sheet';

export default class MunicipalEducationPlanMonitoringSheetApi implements MunicipalEducationPlanMonitoringSheetApiProtocol {
  async post(municipalEducationPlanMonitoringSheet: MunicipalEducationPlanMonitoringSheetType): Promise<MunicipalEducationPlanMonitoringSheetType[]> {
    let newMunicipalEducationPlanMonitoringSheet = [] as MunicipalEducationPlanMonitoringSheetType[];

    await axios.post(config.apiUrl + '/municipal-education-plan-monitoring-sheet', municipalEducationPlanMonitoringSheet).then(response => {
      newMunicipalEducationPlanMonitoringSheet = response.data as MunicipalEducationPlanMonitoringSheetType[]
    }).catch(e => {
      //
    });

    return newMunicipalEducationPlanMonitoringSheet;
  }

  async get(): Promise<MunicipalEducationPlanMonitoringSheetType[]> {
    let newMunicipalEducationPlanMonitoringSheet = [] as MunicipalEducationPlanMonitoringSheetType[];
    await axios.get(config.apiUrl + '/municipal-education-plan-monitoring-sheet').then(response => {
      newMunicipalEducationPlanMonitoringSheet = response.data;
    });

    return newMunicipalEducationPlanMonitoringSheet;
  }

  async update(municipalEducationPlanMonitoringSheet: MunicipalEducationPlanMonitoringSheetType, id: string): Promise<MunicipalEducationPlanMonitoringSheetType[]> {
    let newMunicipalEducationPlanMonitoringSheet: MunicipalEducationPlanMonitoringSheetType[] = [] as MunicipalEducationPlanMonitoringSheetType[];

    await axios.put(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/${id}`, municipalEducationPlanMonitoringSheet).then(response => {
      newMunicipalEducationPlanMonitoringSheet = response.data;
    }).catch(e => {
      //
    });

    return newMunicipalEducationPlanMonitoringSheet
  }

  async delete(id: string): Promise<MunicipalEducationPlanMonitoringSheetType[]> {
    let newMunicipalEducationPlanMonitoring = [] as MunicipalEducationPlanMonitoringSheetType[];

    await axios.delete(config.apiUrl + '/municipal-education-plan-monitoring-sheet/' + id).then(response => {
      newMunicipalEducationPlanMonitoring = response.data;
    }).catch(e => {
      //
    });

    return newMunicipalEducationPlanMonitoring;
  }

  async postGoal(id: string): Promise<MonitoringSheetGoalType[]> {
    let newMonitoringSheet = [] as MonitoringSheetGoalType[];

    await axios.post(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/${id}`).then(response => {
      newMonitoringSheet = response.data;
    });

    return newMonitoringSheet;
  }

  async getAllGoal(id: string): Promise<MonitoringSheetGoalType[]> {
    let newMonitoringSheet = [] as MonitoringSheetGoalType[];

    await axios.get(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/${id}`).then(response => {
      newMonitoringSheet = response.data;
    })

    return newMonitoringSheet;
  }

  async deleteGoal(id: string, goalId: string): Promise<MonitoringSheetGoalType[]> {
    let newMonitoringSheet = [] as MonitoringSheetGoalType[];

    await axios.delete(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/${id}/${goalId}`).then(response => {
      newMonitoringSheet = response.data;
    });

    return newMonitoringSheet;
  }

  async postGoalText(id: string, goalId: string, goal: GoalType): Promise<MonitoringSheetGoalType[]> {
    let newMonitoringSheet = [] as MonitoringSheetGoalType[];

    await axios.post(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/text/${id}/${goalId}`, goal).then(response => {
      newMonitoringSheet = response.data;
    }).catch(e => {
      //
    });

    return newMonitoringSheet;
  }

  async postTargetIndicator(id: string, goalId: string, targetIndicator: IndicatorType): Promise<IndicatorType[]> {
    let newTargetIndicator: IndicatorType[] = [] as IndicatorType[];

    await axios.post(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/targetIndicator/${id}/${goalId}`, targetIndicator).then(response => {
      newTargetIndicator = response.data;
    }).catch(e => {
      //
    });

    return newTargetIndicator;
  }

  async getAllTargetIndicator(id: string, goalId: string): Promise<IndicatorType[]> {
    let newTargetIndicator: IndicatorType[] = [] as IndicatorType[];

    await axios.get(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/targetIndicator/${id}/${goalId}`).then(response => {
      newTargetIndicator = response.data;
    }).catch(e => {
      //
    });

    return newTargetIndicator;
  }

  async deleteTargetIndicator(id: string, goalId: string, indicatorId: string): Promise<IndicatorType[]> {
    let newTargetIndicator: IndicatorType[] = [] as IndicatorType[];

    await axios.delete(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/targetIndicator/${id}/${goalId}/${indicatorId}`).then(response => {
      newTargetIndicator = response.data;
    }).catch(e => {
      //
    });

    return newTargetIndicator;
  }

  async putTargetIndicator(id: string, goalId: string, indicatorId: string, indicator: IndicatorType): Promise<IndicatorType[]> {
    let newTargetIndicator: IndicatorType[] = [] as IndicatorType[];

    await axios.put(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/targetIndicator/${id}/${goalId}/${indicatorId}`, indicator).then(response => {
      newTargetIndicator = response.data as IndicatorType[];
    });

    return newTargetIndicator;
  }

  async getAllStrategy(id: string, goalId: string): Promise<StrategyType[]> {
    let newStrategy: StrategyType[] = [];

    await axios.get(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/strategy/${id}/${goalId}`).then(response => {
      newStrategy = response.data;
    });

    return newStrategy;
  }

  async postInitialStrategy(id: string, goalId: string, strategy: any): Promise<StrategyType[]> {
    let newStrategy: StrategyType[] = [];

    await axios.post(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/strategy/${id}/${goalId}`, strategy).then(response => {
      newStrategy = response.data;
    });

    return newStrategy;
  }

  async putStrategy(id: string, goalId: string, strategyId: string, strategy: StrategyType): Promise<StrategyType[]> {
    let newStrategy: StrategyType[] = [];

    await axios.put(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/strategy/${id}/${goalId}/${strategyId}`, strategy).then(response => {
      newStrategy = response.data;
    });

    return newStrategy;
  }

  async deleteStrategy(id: string, goalId: string, strategyId: string): Promise<StrategyType[]> {
    let newStrategy: StrategyType[] = [];

    await axios.delete(`${config.apiUrl}/municipal-education-plan-monitoring-sheet/goal/strategy/${id}/${goalId}/${strategyId}`).then(response => {
      newStrategy = response.data;
    });

    return newStrategy;
  }
}
