import React from 'react';
import LoadContent from '../Load-content';
import './styles.scss';

interface LoadingPopupProps {
  display: boolean
  children: any
}

export default function LoadingPopup({display, children}: LoadingPopupProps): React.ReactElement {
  return (
    <div className={`loading-popup display-${String(display)}`}>
      <LoadContent
        isLoading={display}
        style={{
          container: {

          },
          button: {
            width: '30px',
            height: '30px',
          }
        }}
      />
      <h2>{children}</h2>
    </div>
  );
}
