
import type StrategyCustumerProtocol from '../types/custumer-protocol';
import Validator from '../Validator';
import elementarySchoolMonthlyPlanAPI from './KindergartenschoolMonthlyPlanAPI';
import { type KindergartenSchoolMonthlyPlanType } from '../types/KindergartenschoolMonthlyPlan/KindergartenschoolMonthlyPlanAPI';
import ElementarySchoolMonthlyPlanFactory from './KindergartenschoolMonthlyPlan-factory';

class KindergartenSchoolMonthlyPlanCostumer implements StrategyCustumerProtocol<KindergartenSchoolMonthlyPlanType> {
	private readonly elementarySchoolMonthlyPlanApi = elementarySchoolMonthlyPlanAPI;

	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>
	) {}

	async post(): Promise<any> {
		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return [{
				error: true
			}];
		}

		let matters: KindergartenSchoolMonthlyPlanType[] = [];

		const elementarySchoolMonthlyPlanFactory = new ElementarySchoolMonthlyPlanFactory(this.inputs);

		const elementarySchoolMonthlyPlan = elementarySchoolMonthlyPlanFactory.create();

		await this.elementarySchoolMonthlyPlanApi.post(elementarySchoolMonthlyPlan).then((response) => {
			matters = response;
		});

		return matters;
	}

	async put(id: string): Promise<any[]> {
		let elementarySchool: KindergartenSchoolMonthlyPlanType[] = [];

		if (!this.inputs) {
			return [];
		}

		const elementarySchoolMonthlyPlanFactory = new ElementarySchoolMonthlyPlanFactory(this.inputs);

		const elementarySchoolPut = elementarySchoolMonthlyPlanFactory.put();

		await this.elementarySchoolMonthlyPlanApi.put(elementarySchoolPut, id).then((response: KindergartenSchoolMonthlyPlanType[]) => {
			elementarySchool = response;
		});

		return elementarySchool;
	}
}

export default KindergartenSchoolMonthlyPlanCostumer;
