/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { useState } from 'react';
import type MunicipalEducationPlanMonitoringSheetType from '../../../class/types/municipal-education-plan-monitoring-sheet';
import { type MonitoringSheetGoalType, type IndicatorType } from '../../../class/types/municipal-education-plan-monitoring-sheet';
import './style.scss';
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';
import { useParams } from 'react-router-dom';
import InputSelect from '../../input-select';
import MunicipalEducationPlanMonitoringSheetCostumer from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-costumer';
import LoadContent from '../../Load-content';

interface ContentTableMunicipalEducationPlanMonitoringSheetIndicatorProps {
  indicator: IndicatorType
  goalInfo: MonitoringSheetGoalType | undefined
  monitoringSheet: MunicipalEducationPlanMonitoringSheetType | undefined
  setIndicators: React.Dispatch<React.SetStateAction<IndicatorType[]>>
}

export default function ContentTableMunicipalEducationPlanMonitoringSheetIndicator({ indicator, goalInfo, monitoringSheet, setIndicators }: ContentTableMunicipalEducationPlanMonitoringSheetIndicatorProps): React.ReactElement {
  const yearSub = Number(monitoringSheet?.period.end) - Number(monitoringSheet?.period.init);

  const { id } = useParams();
  const [saveLoad, setSaveLoad] = useState<boolean>(false);
  const [deleteLoad, setDeleteLoad] = useState<boolean>(false);

  const [reachedTheTerm, setReachedTheTerm] = useState<string>('Não');

  const loadContentStyle = {
    button: {
      fontSize: '25px',
    },
    container: {
      width: '70px',
    }
  }

  const huddleDelete = () => {
    if (!id || !goalInfo?.id || !indicator.id) {
      return;
    }

    const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();

    setDeleteLoad(true);
    municipalEducationPlanMonitoringSheetApi.deleteTargetIndicator(id, goalInfo?.id, indicator.id).then(response => {
      setIndicators(response);
      setDeleteLoad(false);
    }).catch(e => {
      //
    });
  }

  const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id || !goalInfo?.id || !indicator.id) {
      return;
    }

    const formElement = e.target as HTMLElement;

    const expectGoal = formElement.querySelectorAll('#input-expectedGoal') as NodeListOf<HTMLInputElement>;
    const executedGoalDO = formElement.querySelectorAll('#input-executedGoalDO') as NodeListOf<HTMLInputElement>;
    const executedGoalDE = formElement.querySelectorAll('#input-executedGoalDE') as NodeListOf<HTMLInputElement>;

    const indicatorInputTerm = formElement.querySelector('#indicator-input-term') as HTMLInputElement;
    const indicatorInputContent = formElement.querySelector('#indicator-input-content') as HTMLInputElement;

    const municipalEducationPlanMonitoringSheet = new MunicipalEducationPlanMonitoringSheetCostumer();

    setSaveLoad(true);
    municipalEducationPlanMonitoringSheet.postTargetIndicator(
      id,
      goalInfo.id,
      indicator.id,
      expectGoal,
      executedGoalDO,
      executedGoalDE,
      indicatorInputContent,
      indicatorInputTerm,
      reachedTheTerm,
    ).then(response => {
      setSaveLoad(false);
    }).catch(e => {
      //
    });
  }

  return (
    <form className='content-table-municipal-education-plan-monitoring-sheet-indicator' onSubmit={formSubmit}>
      <div className='button-content'>
        {saveLoad
          ? (
            <LoadContent
              isLoading={saveLoad}
              style={loadContentStyle}
            />
          )
          : <button className='button-element save-button' type='submit'>Salvar</button>}
        {deleteLoad
        ? (
          <LoadContent
            isLoading={deleteLoad}
            style={loadContentStyle}
          />
        )
 : <button className='button-element delete-button' onClick={huddleDelete}>Apagar</button>}
      </div>
      <table className='content-table-municipal-education-plan-monitoring-sheet-indicator-table'>
        <thead className='content-table-municipal-education-plan-monitoring-sheet-indicator-table-header'>
          <tr>
            <th rowSpan={2} className='td-large td-background-gray'>INDICADOR {monitoringSheet?.period.end}</th>
            <th colSpan={yearSub - 3} className='td-large td-background-gray'>
              <input type='text' id='indicator-input-content' defaultValue={indicator.content} />
            </th>
            <th className='td-large td-background-gray'>Prazo</th>
            <th className='td-large td-background-gray'>
              <input type="text" id='indicator-input-term' defaultValue={indicator.term} />
            </th>
            <th className='td-large td-background-gray'>Alcançou o indicador</th>
            <th className='td-large td-background-gray'>
              <InputSelect
                title=''
                options={[
                  'Sim',
                  'Não'
                ]}
                defaultValue={indicator.reachedTheTerm ?? 'Não'}
                cb={(option: string) => {
                  setReachedTheTerm(option);
                }}
              />
            </th>
          </tr>
          <tr>
            {'v-'.repeat(yearSub).split('-').map((value, index) => {
              return <td key={`indicator-td-${index}`} align='center' className='td-background-gray'>{Number(monitoringSheet?.period.init) + index}</td>
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='td-min-height'>Meta prevista</td>
            {'v-'.repeat(yearSub).split('-').map((value, index) => {
              return <td key={`indicator-td-${index}`} align='center'>
                <input type="text" id='input-expectedGoal' defaultValue={indicator.indicators?.expectedGoal[index]} />
              </td>
            })}
          </tr>
          <tr>
            <td className='td-min-height'>
              Meta executada no período (dado oficial)
            </td>
            {'v-'.repeat(yearSub).split('-').map((value, index) => {
              return <td key={`indicator-td-${index}`} align='center'>
                <input type="text" id='input-executedGoalDO' defaultValue={indicator.indicators?.executedGoalDO[index]} />
              </td>
            })}
          </tr>
          <tr>
            <td className='td-min-height'>
              Meta executada no período (dado extraoficial)
            </td>
            {'v-'.repeat(yearSub).split('-').map((value, index) => {
              return <td key={`indicator-td-${index}`} align='center'>
                <input type="text" id='input-executedGoalDE' defaultValue={indicator.indicators?.executedGoalDE[index]} />
              </td>
            })}
          </tr>
        </tbody>
      </table>
    </form>
  )
}
