import React from 'react';
import TableContent from '../table';
import './style.scss';
import { type MonitoringSheetGoalType } from '../../../class/types/municipal-education-plan-monitoring-sheet';

interface MunicipalEducationPlanMonitoringSheetGoalProps {
  id: string
  display: string
  goalInfo: MonitoringSheetGoalType | undefined
}

export default function MunicipalEducationPlanMonitoringSheetGoal({display, id, goalInfo}: MunicipalEducationPlanMonitoringSheetGoalProps): React.ReactElement {
  return (
    <div className='municipal-education-plan-monitoring-sheet-goal' id={id}>
      <TableContent display={display} goalInfo={goalInfo} />
    </div>
  );
}
