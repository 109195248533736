/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import './style.scss';
import { type HTTPIType } from '../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import elementarySchoolDigitalHTTPIApi from '../../../../class/ElementaryDigital-HTTPI/ElementaryDigitalHTTPIApi';

interface ContentTableDigitalHTTPIProps {
  dayOfHTTPI: HTTPIType
  setDayOfHTTPI: React.Dispatch<React.SetStateAction<HTTPIType[]>>
  elementId: string
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementSelected: React.Dispatch<React.SetStateAction<HTTPIType | undefined>>
}

export default function ContentTableDigitalHTTPI({dayOfHTTPI, elementId, setDisplayEditElement, setElementSelected, setDayOfHTTPI}: ContentTableDigitalHTTPIProps) {
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short',
    })
  }

  const huddleDelete = () => {
    if (!dayOfHTTPI) {
      return;
    }

    elementarySchoolDigitalHTTPIApi.deleteDayOfHTTPI(elementId, dayOfHTTPI.id ?? '').then(response => {
      setDayOfHTTPI(response);
    });
  }

  const huddleEdit = () => {
    setElementSelected(dayOfHTTPI);
    setDisplayEditElement(true);
  }

  return (
    <ul className='table'>
      <li>
        <h1>{formatDate(dayOfHTTPI.date)}</h1>
      </li>
      <li>
        <h1>{dayOfHTTPI.activity}</h1>
      </li>
      <li className='time-content'>
        <h1>{dayOfHTTPI.time.after}</h1>
        <h1>{dayOfHTTPI.time.before}</h1>
      </li>
      <li>
        <h1>{dayOfHTTPI.time.totalTime}</h1>
      </li>
      <li className='config-content'>
        <div>
          <FiEdit2 size='20' style={{ color: '#118951' }} onClickCapture={huddleEdit} />
        </div>
        <div>
          <MdDelete size='20' style={{ color: '#ff0000' }} onClickCapture={huddleDelete} />
        </div>
      </li>
    </ul>
  )
}
