import { type StudentType } from '../../../class/types/Student/student-factory-protocol';

export default function studentIsEqual(studentName: string, students: StudentType[]): {
  isEqual: boolean
  student: StudentType | undefined
} {
  const searchNameStudentNameFormatted = studentName.toLowerCase().replaceAll(' ', '');
  const studentNameFormatted = students[0].studentName.toLowerCase().replaceAll(' ', '');

  if (searchNameStudentNameFormatted === studentNameFormatted) {
    return {
      isEqual: true,
      student: students[0]
    }
  }

   return {
    isEqual: false,
    student: undefined,
   }
}
