/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useState, useEffect } from 'react';
import './style.scss';
import { FiSearch } from 'react-icons/fi';
import RoomsApi from '../../../class/Room/RoomsApi';
import type { RoomType } from '../../../class/types/Room/Rooms-protocol';
import ContentTableRoomWritingLevelCoordinator from '../../../components/rooms-writing-level-coordinator-files/ContentTable-room';
import EmptyComponent from '../../../components/Empty-component';
import WritingLevelRecordFilter from '../service/Writing-level-record-filter';
import DashboardContainer from '../Dashboard-container';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [rooms, setRooms] = useState<RoomType[]>([]);
  const [roomsFiltered, setRoomsFiltered] = useState<RoomType[]>([]);
  const [displayDashboard, setDisplayDashboard] = useState<boolean>(false);
  const [roomIds, setRoomIds] = useState<string[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    if (!rooms) {
      return;
    }
    const writingLevelRecordFilter = new WritingLevelRecordFilter(inputs, rooms);

    const findings = writingLevelRecordFilter.searchElement();

    if (!findings) {
      setRoomsFiltered(rooms);
      return;
    }

    setRoomsFiltered(findings);
    setRoomIds(WritingLevelRecordFilter.roomIDs(findings));
  };

  const huddleDisplayDashboard = () => {
    setDisplayDashboard(!displayDashboard);
  };

  useEffect(() => {
    if (display === 'false') {
      return;
    }
    setIsLoading(true);
    RoomsApi.get().then(response => {
      setIsLoading(false);
      setRooms(response);
      setRoomsFiltered(response);
      setRoomIds(WritingLevelRecordFilter.roomIDs(response));
    });
  }, [display]);

  return (
    <div className='table-container-writing-level-record-coordinator'>
      <DashboardContainer
        display={display}
        rooms={roomsFiltered}
        roomIds={!roomIds ? [''] : roomIds}
        displayDashboardContainer={displayDashboard}
        setDisplayDashboardContainer={setDisplayDashboard}
      />
      <div className='title-writing-level-record-coordinator'>
        <h1>Diagnostico de Matemática
        </h1>
      </div>
      <header className='header-content'>
        <form method='POST' action='' className='search-filter-content' onSubmit={e => {
          huddleSubmit(e);
        }}>
          <div className='search-input-content input-content'>
            <input type='text' placeholder='nome da sala' />
            <FiSearch size='20' className='search-filter-element' />
          </div>
          <div className='search-date-input-content input-content'>
            <label htmlFor='search-date-input'>Ano Letivo:</label>
            <input type='text' id='search-date-input' />
          </div>
          <div className='submit-form-button-content'>
            <button type='submit'>Buscar</button>
          </div>
        </form>
        <div className='generate-graph-button'>
          <button type='button' onClick={huddleDisplayDashboard}>Gerar gráfico</button>
        </div>
      </header>
      <main className='main-content'>
        <section className='header-table-element'>
          <ul className='header-table-ul'>
            <li className='header-table-ul-li'>Nº</li>
            <li className='header-table-ul-li'>Turma</li>
            <li className='header-table-ul-li'>Professor</li>
            <li className='header-table-ul-li'>Escola</li>
            <li className='header-table-ul-li'>Ano letivo</li>
          </ul>
        </section>
        <section className='main-table-content'>
          {isLoading
            ? <LoadContent
              isLoading={isLoading}
              style={{
                container: {
                  marginTop: '8rem'
                }
              }}
            />
            : (
              display === 'true'
                ? (
                  roomsFiltered?.length === 0
                    ? <EmptyComponent />
                    : roomsFiltered?.map((room: RoomType, index: number) => (
                      <ContentTableRoomWritingLevelCoordinator
                        index={index}
                        room={room}
                        key={String(room.id)}
                      />
                    ))
                )
                : ''
            )
          }
        </section>
      </main>
    </div >
  );
}
