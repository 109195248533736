
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import lscache from 'lscache';
import './style.scss';
import StrategyCustumer from '../../../class/Strategy/Strategy-custumer';
import clearAllInputs from './service/clear-all-inputs';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayEditElement: boolean
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setStrategies: React.Dispatch<React.SetStateAction<any[] | undefined>>
  elementId: string | undefined
}

export default function FormEditElement({ displayEditElement, setDisplayEditElement, elementId, setStrategies }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const inputStrategy = useRef<HTMLInputElement | null>(null);
  const inputResponsible = useRef<HTMLInputElement | null>(null);
  const inputGoal = useRef<HTMLInputElement | null>(null);
  const inputGoalResponsible = useRef<HTMLInputElement | null>(null);
  const inputIndicator = useRef<HTMLInputElement | null>(null);
  const inputIndicatorResponsible = useRef<HTMLInputElement | null>(null);
  const inputCust = useRef<HTMLInputElement | null>(null);
  const inputSource = useRef<HTMLInputElement | null>(null);

  let strategy: Record<string, string> = {};

  try {
    lscache.get('strategy').forEach((element: any) => {
      if (element.id === elementId) {
        strategy = element;
      }
    });
  } catch (e) {
    //
  }

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const strategyCustumer = new StrategyCustumer(inputs);

    if (elementId) {
      setIsLoading(true);
      strategyCustumer.put(elementId).then(response => {
        setStrategies(response);
        setDisplayEditElement(false);
        clearAllInputs();
        setIsLoading(false);
      });
    }
  };

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayEditElement(false);
    clearAllInputs();
  };

  useEffect(() => {
    setDisplayElement(displayEditElement);

    (inputStrategy.current ?? {value: ''}).value = strategy.strategyName;
    (inputResponsible.current ?? {value: ''}).value = strategy.responsible;
    (inputIndicator.current ?? {value: ''}).value = strategy.indicator;
    (inputGoal.current ?? {value: ''}).value = strategy.goal;
    (inputGoalResponsible.current ?? {value: ''}).value = strategy.goalResponsible;
    (inputIndicatorResponsible.current ?? {value: ''}).value = strategy.indicatorResponsible;
    (inputCust.current ?? {value: ''}).value = strategy.cust;
    (inputSource.current ?? {value: ''}).value = strategy.source;
  }, [displayEditElement]);

  return (
    <div className={`form-edit-container-strategy display-${displayElement}`}>
      <form
        method='POST'
        className='form-edit-element'
        id='form-edit-element'
        onSubmit={hundleSubmit}
      >
        <div className='header-form-edit-element'>
          <h1>Editar</h1>
        </div>
        <div className='body-form-edit-element'>
          <div className='input-content'>
            <label htmlFor='strategy'>Objetivos estratégicos:</label>
            <input
              type='text'
              id='strategy'
              placeholder={'Objetivos estratégicos'}
              ref={inputStrategy}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Responsável:</label>
            <input
              type='text'
              id='responsavel'
              placeholder={'Responsável'}
              ref={inputResponsible}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='goal'>Meta:</label>
            <input
              type='text'
              id='goal'
              placeholder={'Meta'}
              ref={inputGoal}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel-meta'>Responsável pela meta:</label>
            <input
              type='text'
              id='responsavel-meta'
              placeholder={'Responsável pela meta'}
              ref={inputGoalResponsible}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='indicador'>Indicador:</label>
            <input
              type='text'
              id='indicador'
              placeholder={'Indicador'}
              ref={inputIndicator}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel-indicador'>Responsável pelo Indicador:</label>
            <input
              type='text'
              id='responsavel-indicador'
              placeholder={'Responsável pelo indicador'}
              ref={inputIndicatorResponsible}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='cust'>Estimativa de custo:</label>
            <input
              type='text'
              id='cust'
              placeholder={'Estimativa de custo'}
              ref={inputCust}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='source'>Fonte:</label>
            <input
              type='text'
              id='source'
              placeholder={'Fonte'}
              ref={inputSource}
            />
          </div>
          <div className='buttons-content'>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={
                    {
                      button: {
                        fontSize: 25,
                      },
                      container: {
                        width: 25,
                      }
                    }
                  }
                />
              )
              : (
                <>
                  <button className='cancel-button' type='button' onClick={() => {
                    hundleClickClose();
                  }}>Cancelar</button>
                  <button className='save-button' type='submit'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
