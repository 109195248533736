/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import './style.scss';
import DashboardWritingLevel from '../Dashboard';
import { IoIosArrowDown } from 'react-icons/io';
import StudentWritingRecordCoordinatorApi from '../../../class/student-writing-level-record/student-writing-level-record-coordinator';
import ToCompareDashboard from '../../writing-level-record-final-result/Dashboard/components/to-compare-dashboard';
import studentWritingLevelRecordApi from '../../../class/student-writing-level-record/student-writing-level-record-api';
import { type TagTitleList } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import { type RoomType } from '../../../class/types/Room/Rooms-protocol';

interface DashboardContainerProps {
  display: string
  displayDashboardContainer: boolean
  setDisplayDashboardContainer: React.Dispatch<React.SetStateAction<boolean>>
  roomIds: string[]
  rooms: RoomType[]
}

interface HuddleClickLiType {
  setOptionTag: React.Dispatch<React.SetStateAction<string>>
  setTypeTag: React.Dispatch<React.SetStateAction<string>>
  setTwoMonthsSelected: React.Dispatch<React.SetStateAction<string>>
  setTags: React.Dispatch<React.SetStateAction<TagTitleList[]>>
  setOption: React.Dispatch<React.SetStateAction<string>>
  setDisplayOption: React.Dispatch<React.SetStateAction<boolean>>
  setDataDashboard: React.Dispatch<React.SetStateAction<DashboardDataType>>
}
interface HuddleClickLiTagType {
  tags: TagTitleList[]
  optionTag: string
  typeTag: string
  twoMonthsSelected: string
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setOption: React.Dispatch<React.SetStateAction<string>>
  setDisplayOption: React.Dispatch<React.SetStateAction<boolean>>
  setDataDashboard: React.Dispatch<React.SetStateAction<DashboardDataType>>
}

interface DashboardDataType {
  seriesCount?: number[]
  writingTheName?: number
  series: number[]
  colors: string[]
  categories: string[]
}

const defaultDataDashboard: DashboardDataType = {
  colors: ['#ff0000'],
  categories: ['dados não encontrado'],
  series: [100],
  seriesCount: [0]
};

export default function DashboardContainer({ displayDashboardContainer, display, rooms, roomIds, setDisplayDashboardContainer }: DashboardContainerProps): React.ReactElement {
  const [optionOne, setOptionOne] = useState<string>('Bimestre');
  const [displayOption, setDisplayOption] = useState<boolean>(false);
  const [dataDashBoard, setDataDashboard] = useState<DashboardDataType>(defaultDataDashboard);

  const [roomNames, setRoomNames] = useState<string>('');

  const [displayOptionTwo, setDisplayOptionTwo] = useState<boolean>(false);
  const [displayOptionTree, setDisplayOptionTree] = useState<boolean>(false);

  const [displayTagOptionOne, setDisplayTagOptionOne] = useState<boolean>(false);
  const [displayTagOptionTwo, setDisplayTagOptionTwo] = useState<boolean>(false);
  const [displayTagOptionThree, setDisplayTagOptionThree] = useState<boolean>(false);

  const [optionTwo, setOptionTwo] = useState<string>('Bimestres');
  const [optionTree, setOptionTree] = useState<string>('Bimestres');

  const [tagOptionOne, setTagOptionOne] = useState<string>('Tags');
  const [tagOptionTwo, setTagOptionTwo] = useState<string>('Tags');
  const [tagOptionThree, setTagOptionThree] = useState<string>('Tags');

  const [tagsOne, setTagsOne] = useState<TagTitleList[]>([]);
  const [tagsTwo, setTagsTwo] = useState<TagTitleList[]>([]);
  const [tagsThree, setTagsThree] = useState<TagTitleList[]>([]);

  const [twoMonthsSelectedOne, setTwoMonthsSelectedOne] = useState<string>('')
  const [twoMonthsSelectedTwo, setTwoMonthsSelectedTwo] = useState<string>('')
  const [twoMonthsSelectedThree, setTwoMonthsSelectedThree] = useState<string>('')

  const [typeTagOne, setTypeTagOne] = useState<string>('');
  const [typeTagTwo, setTypeTagTwo] = useState<string>('');
  const [typeTagThree, setTypeTagThree] = useState<string>('');

  const [dataDashboardTwo, setDataDashboardTwo] = useState<DashboardDataType>(defaultDataDashboard);
  const [dataDashboardTree, setDataDashboardTree] = useState<DashboardDataType>(defaultDataDashboard);

  const [displayToCompareDashboard, setDisplayToCompareDashboard] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState<boolean>(false);
  const [isLoadingThree, setIsLoadingThree] = useState<boolean>(false);

  const twoMonthsOption = ['twoMonths-one', 'twoMonths-two'];

  const huddleDisplay = () => {
    setDisplayDashboardContainer(false);
  };

  const huddleClickLi = async (e: React.MouseEvent<HTMLUListElement>, {
    setOptionTag,
    setTypeTag,
    setTwoMonthsSelected,
    setTags,
    setDisplayOption,
    setOption,
    setDataDashboard,
  }: HuddleClickLiType) => {
    const target = e.target as HTMLLIElement;
    const elementId = target.id;

    const [type, twoMonth] = elementId.split(' ');

    setOption(!target.textContent ? '' : target.textContent);
    setDisplayOption(false);

    setTypeTag(type);
    setTwoMonthsSelected(twoMonth);

    setDataDashboard(defaultDataDashboard);
    let passed = false;

    setOptionTag('Tags');
    for await (const roomId of roomIds) {
      if (passed) {
        return;
      }
      if (type === 'semester') {
        await studentWritingLevelRecordApi.getAllTagTitleList(roomId, twoMonthsOption[Number(twoMonth) - 1]).then(response => {
          if (response) {
            setTags(response);
          }
        });
        return;
      }
      await studentWritingLevelRecordApi.getAllTagTitleList(roomId, twoMonth).then(response => {
        if (response) {
          setTags(response);
          passed = true;
        }
      });
    };

    // StudentWritingRecordCoordinatorApi.getDataWritingLevelCoordinatorCostumer(elementId, roomIds).then(response => {
    //   if (!response) {
    //     return;
    //   }

    //   setDataDashboard({
    //     series: response.series,
    //     categories: response.category,
    //     colors: response.color,
    //     seriesCount: response.seriesCount,
    //     writingTheName: response.writingTheName,
    //   });
    // });
  };

  const huddleClickLiTag = (e: React.MouseEvent<HTMLUListElement>, {
    typeTag,
    twoMonthsSelected,
    optionTag,
    tags,
    setOption,
    setIsLoading,
    setDisplayOption,
    setDataDashboard
  }: HuddleClickLiTagType) => {
    const target = e.target as HTMLLIElement;
    const optionSelected = target.textContent;

    setOption(!optionSelected ? '' : optionSelected);
    setDisplayOption(false);

    if (optionSelected === 'Nível de Escrita' || !optionSelected) {
      setIsLoading(true);

      StudentWritingRecordCoordinatorApi.getDataWritingLevelCoordinatorCostumer(`${typeTag} ${twoMonthsSelected}`, roomIds).then(response => {
        setIsLoading(false);
        if (!response) {
          return;
        }

        setDataDashboard({
          series: response.series,
          categories: response.category,
          colors: response.color,
          seriesCount: response.seriesCount,
          writingTheName: response.writingTheName,
        });
      });
      return;
    }

    let _index = 0;
    const tagId = tags.find((tag, index) => {
      if (tag.name.includes(optionSelected)) {
        _index = index + 1;
        return true;
      }
      return false;
    })?.id;

    if (typeTag === 'semester' && tagId) {
      setIsLoading(true);
      StudentWritingRecordCoordinatorApi.getDataHypothesisTagTitleCoordinatorBySemester(roomIds, twoMonthsSelected, tagId).then(response => {
        if (response.series.length === 0) {
          setDataDashboard({
            colors: ['#ff0000'],
            categories: ['dados não encontrado'],
            series: [100],
            seriesCount: [0]
          });
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        setDataDashboard({
          series: response.series,
          categories: response.category,
          colors: response.color,
          seriesCount: response.seriesCount,
        });
      });
      return;
    }

    setIsLoading(true);

    StudentWritingRecordCoordinatorApi.getDataHypothesisTagTitleCoordinator(roomIds, twoMonthsSelected, optionSelected, _index).then(response => {
      setIsLoading(false);
      if (!response) {
        return;
      }

      response.series.pop();
      response.category.pop();

      setDataDashboard({
        series: response.series,
        categories: response.category,
        colors: response.color,
        seriesCount: response.seriesCount,
        writingTheName: response.writingTheName
      });
    });

    // StudentWritingRecordCoordinatorApi.getDataWritingLevelCoordinatorCostumer(elementId, roomIds).then(response => {
    //   if (!response) {
    //     return;
    //   }

    //   setDataDashboard({
    //     series: response.series,
    //     categories: response.category,
    //     colors: response.color
    //   });
    // });
  };

  const huddleDisplayOption = () => {
    setDisplayOption(!displayOption);
  };

  const huddleDisplayOptionToCompare = (get: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
    set(!get);
  };

  const huddleDisplayToCompareDashboard = () => {
    setDisplayToCompareDashboard(!displayToCompareDashboard);
  };

  const resetDashboard = () => {
    const defaultDashboard: DashboardDataType = {
      colors: ['#ff0000'],
      categories: ['dados não encontrado'],
      series: [100],
      seriesCount: [0]
    };
    setDataDashboard(defaultDashboard);
    setDataDashboardTwo(defaultDashboard);
    setDataDashboardTree(defaultDashboard);
  }

  useEffect(() => {
    let names = rooms.map((room) => room.roomName)[0];
    if (rooms.length > 1) {
      names = names + ` +${rooms.length - 1}`;
    }

    setRoomNames(names)
    resetDashboard();
  }, [rooms]);

  return (
    <div className={`dashboard-container-writing-level-coordinator display-${displayDashboardContainer}`}>
      <div className='dashboard-container-writing-level-coordinator-main'>
        <header className='dashboard-container-writing-level-coordinator-header'>
          <button type='button' onClick={huddleDisplay}>Voltar</button>
          <div className={`input-content display-${!displayToCompareDashboard}`}>
            <div className='input-container-element' onClickCapture={() => {
              huddleDisplayOption();
            }}>
              <input type='text' value={optionOne} disabled={true} />
              <IoIosArrowDown className={`icon-arrow display-${displayOption}`} size={20} />
            </div>
            <ul className={`list-option-content display-${displayOption}`} onClickCapture={e => {
              huddleClickLi(e, {
                setOptionTag: setTagOptionOne,
                setTypeTag: setTypeTagOne,
                setTwoMonthsSelected: setTwoMonthsSelectedOne,
                setTags: setTagsOne,
                setDisplayOption,
                setOption: setOptionOne,
                setDataDashboard,
              });
            }}>
              <li id='school-year'>Ano Letivo</li>
              <li id='twoMonths twoMonths-one'>1º Bimestre</li>
              <li id='twoMonths twoMonths-two'>2º Bimestre</li>
              <li id='twoMonths twoMonths-tree'>3º Bimestre</li>
              <li id='twoMonths twoMonths-for'>4º Bimestre</li>
              <li id='semester 1'>1º Semestre</li>
              <li id='semester 2'>2º Semestre</li>
            </ul>
          </div>
          <div className={`input-content display-${!displayToCompareDashboard}`}>
            <div className='input-container-element' onClickCapture={() => {
              huddleDisplayOptionToCompare(displayTagOptionOne, setDisplayTagOptionOne);
            }}>
              <input type='text' value={tagOptionOne} disabled={true} />
              <IoIosArrowDown className={`icon-arrow display-${displayTagOptionOne}`} size={20} />
            </div>
            <ul className={`list-option-content display-${displayTagOptionOne}`} onClickCapture={e => {
              huddleClickLiTag(e, {
                tags: tagsOne,
                optionTag: tagOptionOne,
                typeTag: typeTagOne,
                twoMonthsSelected: twoMonthsSelectedOne,
                setIsLoading,
                setOption: setTagOptionOne,
                setDisplayOption: setDisplayTagOptionOne,
                setDataDashboard,
              });
            }}>
              {/* <li id='school-year'>Ano Letivo</li> */}
              <li id='tag-title-content'>Nível de Escrita</li>
              {tagsOne.map(tag => {
                return <li id='tag-title-content' key={tag.id}>{tag.name}</li>
              })}
            </ul>
          </div>
          <button type='button' className='compare-button' onClick={huddleDisplayToCompareDashboard}>
            {displayToCompareDashboard ? 'Mostrar separadamente' : 'Comparar'}
          </button>
          <div className={`to-compare-input-container  display-${displayToCompareDashboard}`}>
            <h1>Gráfico um:</h1>
            <div className='input-content input-content-to-compare'>
              <div className='input-container-element' onClickCapture={() => {
                huddleDisplayOptionToCompare(displayOptionTwo, setDisplayOptionTwo);
              }}>
                <input type='text' value={optionTwo} disabled={true} />
                <IoIosArrowDown className={`icon-arrow display-${displayOptionTwo}`} size={20} />
              </div>
              <ul className={`list-option-content display-${displayOptionTwo}`} onClickCapture={e => {
                huddleClickLi(e, {
                  setOptionTag: setTagOptionTwo,
                  setTypeTag: setTypeTagTwo,
                  setTwoMonthsSelected: setTwoMonthsSelectedTwo,
                  setTags: setTagsTwo,
                  setOption: setOptionTwo,
                  setDisplayOption: setDisplayOptionTwo,
                  setDataDashboard: setDataDashboardTwo
                });
              }}>
                <li id='twoMonths twoMonths-one'>1º Bimestre</li>
                <li id='twoMonths twoMonths-two'>2º Bimestre</li>
                <li id='twoMonths twoMonths-tree'>3º Bimestre</li>
                <li id='twoMonths twoMonths-for'>4º Bimestre</li>
                <li id='semester 1'>1º Semestre</li>
                <li id='semester 2'>2º Semestre</li>
              </ul>
            </div>
            <div className={`input-content input-content-tag display-${displayToCompareDashboard}`}>
              <div className='input-container-element' onClickCapture={() => {
                huddleDisplayOptionToCompare(displayTagOptionTwo, setDisplayTagOptionTwo);
              }}>
                <input type='text' value={tagOptionTwo} disabled={true} />
                <IoIosArrowDown className={`icon-arrow display-${displayTagOptionTwo}`} size={20} />
              </div>
              <ul className={`list-option-content display-${displayTagOptionTwo}`} onClickCapture={e => {
                huddleClickLiTag(e, {
                  tags: tagsTwo,
                  optionTag: tagOptionTwo,
                  typeTag: typeTagTwo,
                  twoMonthsSelected: twoMonthsSelectedTwo,
                  setIsLoading: setIsLoadingTwo,
                  setOption: setTagOptionTwo,
                  setDisplayOption: setDisplayTagOptionTwo,
                  setDataDashboard: setDataDashboardTwo,
                });
              }}>
                {/* <li id='school-year'>Ano Letivo</li> */}
                <li id='tag-title-content'>Nível de Escrita</li>
                {tagsTwo.map(tag => {
                  return <li id='tag-title-content' key={tag.id}>{tag.name}</li>
                })}
              </ul>
            </div>
          </div>
          <div className={`to-compare-input-container  display-${displayToCompareDashboard}`}>
            <h1>Gráfico dois: </h1>
            <div className='input-content input-content-to-compare'>
              <div className='input-container-element' onClickCapture={() => {
                huddleDisplayOptionToCompare(displayOptionTree, setDisplayOptionTree);
              }}>
                <input type='text' value={optionTree} disabled={true} />
                <IoIosArrowDown className={`icon-arrow display-${displayOptionTree}`} size={20} />
              </div>
              <ul className={`list-option-content display-${displayOptionTree}`} onClickCapture={e => {
                huddleClickLi(e, {
                  setOptionTag: setTagOptionThree,
                  setTypeTag: setTypeTagThree,
                  setTwoMonthsSelected: setTwoMonthsSelectedThree,
                  setTags: setTagsThree,
                  setOption: setOptionTree,
                  setDisplayOption: setDisplayOptionTree,
                  setDataDashboard: setDataDashboardTree
                });
              }}>
                <li id='twoMonths twoMonths-one'>1º Bimestre</li>
                <li id='twoMonths twoMonths-two'>2º Bimestre</li>
                <li id='twoMonths twoMonths-tree'>3º Bimestre</li>
                <li id='twoMonths twoMonths-for'>4º Bimestre</li>
                <li id='semester 1'>1º Semestre</li>
                <li id='semester 2'>2º Semestre</li>
              </ul>
            </div>
            <div className={`input-content input-content-tag display-${displayToCompareDashboard}`}>
              <div className='input-container-element' onClickCapture={() => {
                huddleDisplayOptionToCompare(displayTagOptionThree, setDisplayTagOptionThree);
              }}>
                <input type='text' value={tagOptionThree} disabled={true} />
                <IoIosArrowDown className={`icon-arrow display-${displayTagOptionThree}`} size={20} />
              </div>
              <ul className={`list-option-content display-${displayTagOptionThree}`} onClickCapture={e => {
                huddleClickLiTag(e, {
                  tags: tagsThree,
                  optionTag: tagOptionThree,
                  typeTag: typeTagThree,
                  twoMonthsSelected: twoMonthsSelectedThree,
                  setIsLoading: setIsLoadingThree,
                  setOption: setTagOptionThree,
                  setDisplayOption: setDisplayTagOptionThree,
                  setDataDashboard: setDataDashboardTree,
                });
              }}>
                {/* <li id='school-year'>Ano Letivo</li> */}
                <li id='tag-title-content'>Nível de Escrita</li>
                {tagsThree.map(tag => {
                  return <li id='tag-title-content' key={tag.id}>{tag.name}</li>
                })}
              </ul>
            </div>
          </div>
        </header>
        <div className='dashboard-container'>
          <DashboardWritingLevel
            isLoading={isLoading}
            dataDashboard={dataDashBoard}
            setDataDashboard={setDataDashboard}
            displayDashboardWritingLevel={!displayToCompareDashboard}
            roomIds={roomIds}
            display={display}
          />
          <ToCompareDashboard
            display={displayToCompareDashboard}
            dataDashboardTwo={dataDashboardTwo}
            isLoadingThree={isLoadingThree}
            isLoadingTwo={isLoadingTwo}
            dataDashboardTree={dataDashboardTree}
            optionTree={optionTree}
            optionTwo={optionTwo}
            roomName={roomNames}
          />
        </div>
      </div>
    </div>
  );
}
