import React from 'react';
import './style.scss';

export default function Header(): React.ReactElement {
  return (
		<header className='header-component' style={{ position: 'relative', zIndex: 1 }}>
			<img src={require('./icons/icon-escola.png')} alt="" />
      <h1>Secretaria Municipal de Educação - Itaóca SP</h1>
      <img className='prefeitura-icon' src={require('./icons/itaocanovopng.webp')} alt="" />
		</header>
  );
}
