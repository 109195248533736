/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/naming-convention */

import axios from 'axios';
import config from '../../config/config';
import type { DataWritingLevel, StudentWritingLRRes, StudentWritingLRType, TagTitleList, TagType } from '../types/Student-writing-level-record/student-writing-level-record-factory-protocol';

class StudentWritingLRApi {
	private get href() {
		return config.apiUrl;
	}

	async post(studentWritingLRObj: StudentWritingLRType, roomId: string, twoMonthsName: string): Promise<StudentWritingLRRes> {
		let studentWritingLR: StudentWritingLRRes = [];

		if (this.href) {
			await axios.post(`${this.href}/post/writinghypothesis/${roomId}/${twoMonthsName}`, studentWritingLRObj).then(response => {
				studentWritingLR = response.data as StudentWritingLRRes;
			});
		}

		return studentWritingLR;
	}

	async get(roomId: string, twoMonthsName: string): Promise<StudentWritingLRRes> {
		let studentWritingLRO: StudentWritingLRRes = [];
		if (this.href) {
			await axios.get(`${this.href}/get/writinghypothesis/${roomId}/${twoMonthsName}`).then(response => {
				studentWritingLRO = response.data as StudentWritingLRRes;
			});
		}

		return studentWritingLRO;
	}

	async postTag(tag: TagType, roomId: string): Promise<TagType[]> {
		let tags: TagType[] = [];

		if (this.href) {
			await axios.post(`${this.href}/post/tagWritingLevel/${roomId}`, tag).then(response => {
				tags = response.data as TagType[];
			});
		}

		return tags;
	}

	async getTag(roomId: string): Promise<TagType[]> {
		let tags: TagType[] = [];
		if (this.href) {
			await axios.get(`${this.href}/get/tagWritingLevel/${roomId}`).then(response => {
				tags = response.data as TagType[];
			});
		}

		return tags;
	}

	async deleteTag(roomId: string, tagId: string): Promise<TagType[]> {
		let tags: TagType[] = [];
		if (this.href) {
			await axios.delete(`${this.href}/delete/tagWritingLevel/${roomId}/${tagId}`).then(response => {
				tags = response.data as TagType[];
			});
		}

		return tags;
	}

	async getDataWritingLevel(roomId: string, twoMonthsName: string): Promise<DataWritingLevel> {
		let config: DataWritingLevel = {} as DataWritingLevel;
		if (this.href) {
			await axios.get(`${this.href}/get/data/writinghypothesis/${roomId}/${twoMonthsName}`).then(response => {
				config = response.data as DataWritingLevel;
			});
		}

		return config;
	}

	async getDataWritingLevelFinalResult(roomId: string): Promise<DataWritingLevel> {
		let config: DataWritingLevel = {} as DataWritingLevel;
		if (this.href) {
			await axios.get(`${this.href}/get/data/finalResult/writinghypothesis/${roomId}`).then(response => {
				config = response.data as DataWritingLevel;
			});
		}

		return config;
	}

	async getDataWritingHypothesisBySemester(roomId: string, semester: string): Promise<DataWritingLevel> {
		let config: DataWritingLevel = {} as DataWritingLevel;
		if (this.href) {
			await axios.get(`${this.href}/get/data/finalResult/bySemester/writinghypothesis/${roomId}/${semester}`).then(response => {
				config = response.data as DataWritingLevel;
			});
		}

		return config;
	}

	async getDataWritingLevelFinalResultCostumer(id: string, roomId: string): Promise<DataWritingLevel> {
		let data = {} as DataWritingLevel;

		const dataError: DataWritingLevel = {
			series: [100],
      seriesCount: [0],
			color: ['red'],
			category: ['dados não encontrado'],
      writingTheName: 0,
		};

		const type = id.split(' ')[0];
		const content = id.split(' ')[1];

		if (!content && !type) {
			return dataError;
		}

		if (type === 'school-year') {
			await this.getDataWritingLevelFinalResult(roomId).then(response => {
				data = response;
			});
		}

		if (type === 'twoMonths') {
			await this.getDataWritingLevel(roomId, content).then(response => {
				data = response;
			});
		}

		if (type === 'semester') {
			await this.getDataWritingHypothesisBySemester(roomId, content).then(response => {
				data = response;
			});
		}

		if (!data) {
			return dataError;
		}

		if (![...data.series].pop() && [...data.series].pop() !== 0) {
			return dataError;
		}

		return data;
	}

  async getDateSpecificTagTitle(roomId: string, twoMonths: string, tagTitleListId: string, index: number) {
    let writingHypothesiDate;

    await axios.get(this.href + `/get/writinghypothesis/${roomId}/${twoMonths}/${tagTitleListId}/${index}`).then(response => {
      writingHypothesiDate = response.data;
    });

    return writingHypothesiDate;
  }

  async postTagTitleList(roomId: string, tagTitleList: TagTitleList, twoMonths: string): Promise<TagTitleList[]> {
    let newTagTitleList: TagTitleList[] = [];

    if (this.href) {
      await axios.post(this.href + `/post/tagTitleList/${roomId}/${twoMonths}`, tagTitleList).then(response => {
        newTagTitleList = response.data;
      });
    }

    return newTagTitleList;
  }

  async deleteTagTitleList(roomId: string, tagTitleListId: string, twoMonths: string): Promise<TagTitleList[]> {
    let newTagTitleList: TagTitleList[] = [];

    if (this.href) {
      await axios.delete(this.href + `/delete/tagTitleList/${roomId}/${tagTitleListId}/${twoMonths}`).then(response => {
        newTagTitleList = response.data;
      });
    }

    return newTagTitleList
  }

  async getAllTagTitleList(roomId: string, twoMonths: string): Promise<TagTitleList[]> {
    let newTagTitleList: TagTitleList[] = [];

    if (this.href) {
      await axios.get(this.href + `/get/tagTitleList/${roomId}/${twoMonths}`).then(response => {
        newTagTitleList = response.data;
      });
    }

    return newTagTitleList;
  }

  async postToTagList(roomId: string, tagTitleListId: string, tag: TagType, twoMonths: string): Promise<TagType[]> {
    let newTagTitleList: TagType[] = [];

    if (this.href) {
      await axios.post(this.href + `/post/tagList/${roomId}/${tagTitleListId}/${twoMonths}`, tag).then(response => {
        newTagTitleList = response.data;
      });
    }

    return newTagTitleList;
  }

  async getAllToTagList(roomId: string, tagTitleListId: string, twoMonths: string): Promise<TagType[]> {
    let newTagTitleList: TagType[] = [];

    if (this.href) {
      await axios.get(this.href + `/post/tagList/${roomId}/${tagTitleListId}/${twoMonths}`).then(response => {
        newTagTitleList = response.data;
      });
    }

    return newTagTitleList;
  }

  async deleteToTagList(roomId: string, tagTitleListId: string, tagId: string, twoMonths: string): Promise<TagType[]> {
    let newTagTitleList: TagType[] = [];

    if (this.href) {
      await axios.delete(this.href + `/delete/tagList/${roomId}/${tagTitleListId}/${tagId}/${twoMonths}`).then(response => {
        newTagTitleList = response.data;
      });
    }

    return newTagTitleList;
  }

  async getDataTagTitleBySemester(roomId: string, index: number, tagTitleId: string) {
    let dataTagTitleBySemester: DataWritingLevel = {} as DataWritingLevel;

    await axios.get(`${this.href}/get/writinghypothesis/bySemester/tagTitle/${roomId}/${index}/${tagTitleId}`).then(response => {
      dataTagTitleBySemester = response.data as DataWritingLevel;
    });

    return dataTagTitleBySemester;
  }
}

export default new StudentWritingLRApi();
