/* eslint-disable @typescript-eslint/no-var-requires */
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const img = require('./img/header-img.png');

export default async function printStrategy(
  strategy: any[],
): Promise<void> {
  const doc = new JsPDF({
    orientation: 'landscape',
  });

  doc.addImage(img, 'PNG', 50, 5, 180, 0);

  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951',
    },
    head: [
      ['N°', 'Estratégia', 'Cronograma', 'Situação']
    ],
    body: (() => {
      const bodyArray: string[][] = [];

      strategy.forEach((action, index) => {
        bodyArray.push([index + 1, action.strategyName, action.timeline, action.situation]);
      });
      return bodyArray
    })()
  });

  doc.save(`Estrategias - ${new Date().toLocaleDateString('pt-BR', {
    timeZone: 'UTC'
  })}.pdf`);
}
