import React from 'react';
import Table from '../Table';
import './style.scss';

interface WritingLevelRecordContainerProps {
  id: string
  display: string
}

export default function WritingLevelRecordContainer({ id, display }: WritingLevelRecordContainerProps): React.ReactElement {
  return (
		<div className={'writing-level-record-container-final-result'} id={id}>
			<Table display={display}/>
		</div>
  );
}
