/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { FrequencyMissed, FrequencyType } from '../types/Frequency/frequency-factory-protocol';
import type { UserProtocol } from '../types/User/User-factory-protocol';

class FrequencyApi {
  private readonly user = lscache.get('user') as UserProtocol;
  private get href() {
    return config.apiUrl;
  }

  constructor() {
    try {
      axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
    } catch {
      //
    }
  }

  async get(roomId: string, matterId: string, twoMonths: string): Promise<FrequencyType[]> {
    let frequencies: FrequencyType[] = [];
    if (this.href) {
      await axios.get(`${this.href}/get/all/frequency/${roomId}/${matterId}/${twoMonths}`).then(response => {
        frequencies = response.data as FrequencyType[];
      });
    }

    return frequencies;
  }

  async getMissed(roomId: string, matterId: string, twoMonths: string): Promise<FrequencyMissed[]> {
    let frequencyMissed: FrequencyMissed[] = [];

    if (!this.href) {
      return frequencyMissed;
    }

    await axios.get(`${this.href}/get/all/missed/${roomId}/${matterId}/${twoMonths}`).then(response => {
      frequencyMissed = response.data as FrequencyMissed[];
    });

    return frequencyMissed;
  }

  async post(roomId: string, matterId: string, twoMonths: string, frequency: FrequencyType): Promise<FrequencyType[]> {
    let frequencies: FrequencyType[] = [];

    if (this.href) {
      await axios.post(`${this.href}/post/room/frequency/${roomId}/${matterId}/${twoMonths}`, frequency).then(response => {
        frequencies = response.data as FrequencyType[];
      });
    }

    return frequencies;
  }

  async put(
    roomId: string,
    matterId: string,
    twoMonths: string,
    frequencyId: string,
    frequency: FrequencyType
  ): Promise<FrequencyType[]> {
    let frequencies: FrequencyType[] = [];

    if (!this.href) {
      return frequencies;
    }

    await axios.put(this.href + `/put/frequency/${roomId}/${matterId}/${twoMonths}/${frequencyId}`, frequency).then(response => {
      frequencies = response.data as FrequencyType[];
    });

    return frequencies;
  }

  async delete(roomId: string, matterId: string, twoMonths: string, frequencyId: string): Promise<FrequencyType[]> {
    let frequencies: FrequencyType[] = [];
    if (this.href) {
      await axios.delete(`${this.href}/delete/frequency/${roomId}/${matterId}/${twoMonths}/${frequencyId}`).then(response => {
        frequencies = response.data as FrequencyType[];
      });
    }

    return frequencies;
  }
}

export default new FrequencyApi();
