/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import { type SpellingLevelApiResType } from '../../../class/types/SpellingLevel/spellingLevelType';
import SpellingLevelCustumer from '../../../class/SpellingLevel/SpellingLevel-custumer';
import { useParams } from 'react-router-dom';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setSpellingLevel: React.Dispatch<React.SetStateAction<SpellingLevelApiResType[]>>
  activityInfo: Record<string, string>
}

export default function FormAddSpellingLevel({ displayCreateNewElement, activityInfo, setDisplayCreateNewElement, setSpellingLevel }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const { id } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!id) {
      return;
    }

    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const spellingLevelCustomer = new SpellingLevelCustumer(inputs, id, activityInfo.twoMonths);

    spellingLevelCustomer.post().then(response => {
      setSpellingLevel(response);
      setDisplayCreateNewElement(false);
      clearAllInputs();
    });
  };

  const huddleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
		<div className={`form-add-container-spelling-level display-${displayElement}`}>
			<form method='POST' className='form-add-element' id='form-add-element-spelling-level' onSubmit={e => {
			  huddleSubmit(e);
			}}>
				<div className='header-form-add-element-content'>
					<h1>
                        Adicionar nível Descrição
					</h1>
					<IoCloseOutline onClick={() => {
					  huddleClickClose(false);
					}} size='25'/>
				</div>
				<div className='body-form-add-element-content'>
					<div className='input-content'>
						<label htmlFor='spelling-level'>Nível Descrição:</label>
						<input className='input-element' type='text' id='spelling-level' placeholder='nível Descrição' />
					</div>
					<div className='buttons-content'>
						<button className='cancel-button' type='button' onClick={() => {
						  huddleClickClose(true);
						}}>Cancelar</button>
						<button className='save-button' type='submit'>Salvar</button>
					</div>
				</div>
			</form>
		</div>
  );
}
