/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import { MdDelete } from 'react-icons/md';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DisplayFileInfoTree } from '../DisplayFile';
import LoadContent from '../Load-content';
import { FiEdit2 } from 'react-icons/fi';
import lscache from 'lscache';
import './style.scss';
import type FileAdmLegislationType from '../../class/types/AdmLegislation';
import uploadAdmHoursApi from '../../class/UploadAdmHours/upload-adm-hours-api';
import { IoMdDownload } from 'react-icons/io';

interface ContentTableKindergartenType {
  index: number
  admATA: FileAdmLegislationType
  setAdmATA: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayATA: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setElement: React.Dispatch<React.SetStateAction<FileAdmLegislationType>>
  type: 'general'
}
export default function ContentTableAdmHours({
  setAdmATA,
  admATA,
  setDisplayEditElement,
  setDisplayATA,
  setElementId,
  setElement,
  index,
  type
}: ContentTableKindergartenType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const huddleDelete = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    uploadAdmHoursApi.delete(type, String(admATA.id), admATA.location.fileName).then(response => {
      setAdmATA(response);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(admATA.id));
    setDisplayEditElement(true);
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(admATA);
    setDisplayATA(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 30) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [admATA]);

  return (
    <div className={`content-table-element-adm-slides display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={admATA.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(admATA.fileName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {admATA.issuer?.userName}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(admATA.date ?? '')}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
          {admATA.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
        <li>
          <a href={admATA.location ? admATA.location.url : ''} download target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
      </ul>
    </div>
  );
}
