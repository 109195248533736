
/* eslint-disable no-negated-condition */

/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import './style.scss';

interface InputContentProps {
  title: string
  contents: string[]
  filterBySituationCustumer: (situation: string) => void
}

export default function InputContent({ title, contents, filterBySituationCustumer }: InputContentProps): React.ReactElement {
  const [inputValue, setInputValue] = useState<string>('');

  const [displayOptions, setDisplayOption] = useState<boolean>(false);

  const hundleClickOption = (e: React.FormEvent<HTMLLIElement>) => {
    const target = e.target as HTMLLIElement;
    if (target.textContent) {
      filterBySituationCustumer(target.textContent);

      setInputValue(target.textContent);
      setDisplayOption(false);
    }
  };

  const hundleDisplayOptions = () => {
    setDisplayOption(!displayOptions);
  };

  return (
		<li className='input-main'>
			<div className='input-content' onClick={hundleDisplayOptions}>
				<label htmlFor='situation' className={`display-${String(displayOptions)}`}>
					<span>{!inputValue ? title : inputValue}</span> <IoIosArrowDown id='icon-arrow' />
				</label>
				<input id='situation' type='text' disabled />
			</div>
			<ul className={`situation-options-content display-${String(displayOptions)}`}>
				{contents.map(_string => <li onClick={e => {
				  hundleClickOption(e);
				}}>{_string}</li>)}
				<li onClick={e => {
				  hundleClickOption(e);
				}}>Todos</li>
			</ul>
		</li>
  );
}
