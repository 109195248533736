import React from 'react';
import './style.scss';
import AddTextualGenreTable from '../table';

interface AddTextualGenreProps {
  display: string
  roomInfo: any
}

export default function AddTextualGenre({display, roomInfo}: AddTextualGenreProps) {
  return (
    <div className='new-writing-hypothesis-add-textual-genre'>
      <AddTextualGenreTable roomInfo={roomInfo} display={display} />
    </div>
  );
}
