import lscache from 'lscache';
import type { StrategyOfPlanProtocol } from '../../../../class/types/Strategy-of-plan/Strategy-of-plan-factory-protocol';
import filterById from './filterById';

export default function filterBySituation(situationFilter: string, id: string, actions: StrategyOfPlanProtocol[]): StrategyOfPlanProtocol[] {
  const newActions: StrategyOfPlanProtocol[] = [];

  const actionFilter = filterById(actions, String(id));

  if (situationFilter.toLowerCase() === 'todos') {
    return actionFilter;
  }

  actionFilter.forEach(action => {
    if (action.situation?.toLowerCase() === situationFilter.toLowerCase()) {
      newActions.push(action);
    }
  });

  return newActions;
}
