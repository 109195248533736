/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import './style.scss';

import type DigitalHTTPIResProtocol from '../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import AddHTTPI from '../Add-HTTPI';
import { type HTTPIType } from '../../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';
import kindergartenSchoolDigitalHTTPIApi from '../../../../class/KindergartenDigital-HTTPI/kindergartenDigitalHTTPIApi';
import ContentTableDigitalHTTPI from '../content-table';
import { sumTime } from '../Add-HTTPI/services';
import EditHTTPI from '../Edit-HTTPI';
import generatePDF from './service/generetePDF';
import LoadContent from '../../../../components/Load-content';

interface DayOfHTTPIProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  element: DigitalHTTPIResProtocol
}

export default function DayOfHTTPI({ display, setDisplay, element }: DayOfHTTPIProps): JSX.Element {
  const [displayAddHTTPI, setDisplayAddHTTPI] = useState(false);
  const [displayEditHTTPI, setDisplayEditHTTPI] = useState(false);
  const [elementSelected, setElementSelected] = useState<HTTPIType>();
  const [dayOfHTTPI, setDayOfHTTPI] = useState<HTTPIType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!element) {
      return;
    }

    setIsLoading(true);
    kindergartenSchoolDigitalHTTPIApi.getDayOfHTTPI(element.id ?? '').then(response => {
      setDayOfHTTPI(response);
      setIsLoading(false);
    });
  }, [display]);

  if (!element) {
    return (
      <></>
    )
  }
  const huddleGeneratePDF = () => {
    generatePDF(element, dayOfHTTPI, sumTime(dayOfHTTPI))
  }

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      month: 'long',
      year: 'numeric'
    });
  }

  return (
    <div className={`day-of-HTTPI-kindergarten-school-content display-${String(display)}`}>
      <AddHTTPI
        element={element}
        display={displayAddHTTPI}
        setDayOfHTTPI={setDayOfHTTPI}
        setDisplay={setDisplayAddHTTPI}
      />
      <EditHTTPI
        display={displayEditHTTPI}
        setDisplay={setDisplayEditHTTPI}
        element={element}
        elementSelected={elementSelected}
        setDayOfHTTPI={setDayOfHTTPI}

      />
      <div className={'day-of-HTTPI-main-content'}>
        <div className='header-content'>
          <div>
            <h1>HTTPI mes de {!element.date ? '' : formatDate(element.date).split(' ')[0]} - {!element.date ? '' : formatDate(element.date).split(' ')[2]}</h1>
            <h1>Professor:
              <span>{!element.issue?.name ? '' : element.issue.name}</span>
            </h1>
            <div className='button-content'>
              <button className='save-button' onClickCapture={() => {
                setDisplayAddHTTPI(!displayAddHTTPI);
              }}>Adicionar atividade</button>
              <button className='save-button' onClickCapture={huddleGeneratePDF}>Imprimir</button>
            </div>
          </div>
          <div>
            <button className='save-button' onClickCapture={() => {
              setDisplay(!display);
            }}>Voltar</button>
          </div>
        </div>
        {isLoading
          ? (
            <LoadContent
              isLoading={isLoading}
              style={
                {
                  button: {
                    marginTop: 100,
                  }
                }
              }
            />
          )
          : (
            <div className='day-of-httpi-table' id='day-of-httpi-table'>
              <div className='day-of-httpi-table-header'>
                <ul className='title-table'>
                  <li>
                    <h1>Data:
                      <span>HTTPI mes de {!element.date ? '' : formatDate(element.date).split(' ')[0]} - {!element.date ? '' : formatDate(element.date).split(' ')[2]}</span>
                    </h1>
                  </li>
                  <li>
                    <h1>Professor:
                      <span>{element.issue?.name}</span>
                    </h1>
                  </li>
                  <li>
                    <h1>Total de Horas:
                      <span>{!dayOfHTTPI ? '' : sumTime(dayOfHTTPI)}</span>
                    </h1>
                  </li>
                </ul>
                <ul className='table'>
                  <li>
                    <h1>Data</h1>
                  </li>
                  <li>
                    <h1>Atividade</h1>
                  </li>
                  <li>
                    <h1>Horário</h1>
                    <div className='time-content'>
                      <h1>Inicio</h1>
                      <h1>Fim</h1>
                    </div>
                  </li>
                  <li>
                    <h1>Total de horas</h1>
                  </li>
                </ul>
              </div>
              <div className='table-main'>
                {!dayOfHTTPI
                  ? ''
                  : dayOfHTTPI.map(httpi => {
                    return <ContentTableDigitalHTTPI
                      dayOfHTTPI={httpi}
                      setElementSelected={setElementSelected}
                      setDisplayEditElement={setDisplayEditHTTPI}
                      setDayOfHTTPI={setDayOfHTTPI}
                      elementId={element.id ?? ''}
                      key={httpi.id ?? ''}
                    />
                  })}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
