/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import type DigitalHTTPIResProtocol from '../../../class/types/DigitalHTTPI/digitalHTTPI-protocol';

/* eslint-disable @typescript-eslint/no-unsafe-call */
export default function filterElements(elements: DigitalHTTPIResProtocol[], value: string): any[] {
  const elementFiltered: any[] = [];

  if (value === '') {
    return [];
  }

  elements.forEach(element => {
    const elementSlice = element.issue?.name.slice(0, value.length).toLowerCase();

    const valueLowerCase = value.toLowerCase();
    if (elementFiltered.length >= 5) {
      return;
    }

    if (valueLowerCase === element.issue?.name) {
      elementFiltered.push(element.issue?.name);
      return;
    }

    if (valueLowerCase === elementSlice) {
      elementFiltered.push(element);
    }
  });

  return elementFiltered;
}
