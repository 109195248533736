/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect, useRef } from 'react';
import ContentTableCardReport from '../../../components/cardReport-files/ContentTable';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import CardReportCustomer from '../../../class/CardReport/cardReport-custumer';
import EmptyComponent from '../../../components/Empty-component';
import { useParams } from 'react-router-dom';
import SaveAlert from '../../../components/save-alert';
import RoomsApi from '../../../class/Room/RoomsApi';
import './style.scss';

interface TableProps {
  cardReport: Record<string, string>
  display: string
}

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function Table({ cardReport, display }: TableProps): React.ReactElement {
  const [students, setStudents] = useState<StudentType[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [displaySaveAlert, setDisplaySaveAlert] = useState(false);
  const formRef = useRef<any>();
  const { id } = useParams();

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement> | any) => {
    e.preventDefault();

    const form = formRef.current;

    const inputs = form.querySelectorAll('input');

    if (!id) {
      return;
    }

    const cardReportCustomer = new CardReportCustomer(inputs, students, cardReport.twoMonths, id, cardReport.matterId);

    cardReportCustomer.post().then(response => {
      const responseReset = response as StudentType[];
      setStudents(responseReset);
      hundleDisplaySaveAlert();
    });
  };

  const hundleDisplaySaveAlert = () => {
    if (displaySaveAlert) {
      return;
    }

    setDisplaySaveAlert(true);

    setTimeout(() => {
      setDisplaySaveAlert(false);
    }, 3000);
  };

  useEffect(() => {
    if (!cardReport || !id) {
      return;
    }

    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudents(response);
    });
  }, [cardReport]);

  return (
    <div className='table-container-cardReport'>
      <SaveAlert display={displaySaveAlert} setDisplay={setDisplaySaveAlert}>Boletim salvo</SaveAlert>
      <div className='header-cardReport'>
        <div className='header-cardReport-info'>
          <h1>Boletim</h1>
          <h1>Materia: {cardReport.matterName}</h1>
          <h1>{cardReport.twoMonthsName}</h1>
        </div>
        <div className='save-button-cardReport'>
          <button type='submit' onClick={hundleSubmit}>Salvar</button>
        </div>
      </div>
      <div className='table-container'>
        <div className='header-table-element'>
          <ul className='header-table-ul'>
            <li className='header-table-ul-li'>Nº</li>
            <li className='header-table-ul-li'>Aluno</li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li className='header-table-ul-li'>Media</li>
          </ul>
        </div>
        <form className='main-table' method='POST' onSubmit={hundleSubmit} ref={formRef}>
          {display === 'true'
            ? (
              students?.length === 0
                ? <EmptyComponent>Adicione alunos, para aparecerem aqui.</EmptyComponent>
                : students?.map((student: any, index: number) => (
                  <ContentTableCardReport
                    index={index}
                    setElementId={setElementId}
                    student={student}
                    cardReport={cardReport}
                    setStudents={setStudents}
                    key={String(student.id)}
                  />
                ))
            )
            : ''}
        </form>
      </div>
    </div>
  );
}
