import React from 'react';
import Table from '../Table-actions';
import './style.scss';

interface EstrategyProps {
  id: string
  display: string
  setAction: any
}

export default function Plans({ id, display, setAction }: EstrategyProps): React.ReactElement {
  return (
		<div className={'actions-container'} id={id}>
			<Table display={display} setAction={setAction} />
		</div>
  );
}
