import type StudentWritingLRCustomerProtocol from '../types/Student-writing-level-record/student-writing-level-record-customer-protocol';
import type { StudentType } from '../types/Student/student-factory-protocol';
import StudentWritingLRFactory from './student-writing-level-record-factory';
import StudentWritingLRApi from './student-writing-level-record-api';
import type { StudentWritingLRRes } from '../types/Student-writing-level-record/student-writing-level-record-factory-protocol';

/* eslint-disable @typescript-eslint/naming-convention */
class StudentWritingLRCustomer implements StudentWritingLRCustomerProtocol {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
		private readonly students: StudentType[],
		private readonly roomId: string,
		private readonly twoMonthsName: string
	) {}

	async post(): Promise<StudentWritingLRRes> {
		const studentWritingLRFactory = new StudentWritingLRFactory(this.inputs, this.students);

		const studentWritingLRObj = studentWritingLRFactory.create();

		const studentWritingLR = await StudentWritingLRApi.post(studentWritingLRObj, this.roomId, this.twoMonthsName);

		return studentWritingLR;
	}
}

export default StudentWritingLRCustomer;
