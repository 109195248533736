import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './styles.scss';
import MathematicsProblemSolvingCostumer from '../../../class/Mathematics-problem-solving/MathematicsProblemSolving-costumer';
import { useParams } from 'react-router-dom';
import MathematicsProblemSolvingApi from '../../../class/Mathematics-problem-solving/MathematicsProblemSolving-api';
import type MathematicsProblemSolvingTagType from '../../../class/types/MathematicsProblemSolving';
import LoadContent from '../../../components/Load-content';
import { MdDelete } from 'react-icons/md';
import displayError from '../../../class/services/displayError';

interface AddNewTagProps {
  display: boolean
  problemsSalvingTag: MathematicsProblemSolvingTagType[]
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  setProblemsSalvingTag: React.Dispatch<React.SetStateAction<MathematicsProblemSolvingTagType[]>>
}

interface TableContentProps {
  tag: MathematicsProblemSolvingTagType
  index: number
  setProblemsSalvingTag: React.Dispatch<React.SetStateAction<MathematicsProblemSolvingTagType[]>>

}

export default function AddNewTag({display, problemsSalvingTag, setProblemsSalvingTag, setDisplay}: AddNewTagProps): React.ReactElement {
  const {id} = useParams();

  const huddleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!id) {
      return;
    }

    const target = e.target as HTMLFormElement;

    const input = target.querySelectorAll('input')[0];

    const mathematicsProblemSolvingCostumer = new MathematicsProblemSolvingCostumer(input, id);

    if (problemsSalvingTag.length === 8) {
      displayError(input, 'Você atingiu o limite de tags')
      return;
    }

    mathematicsProblemSolvingCostumer.postTag().then(response => {
      if (response.length === 0) {
        return;
      }
      setProblemsSalvingTag(response);

      input.value = '';
    }).catch(() => {
      //
    });
  }

  useEffect(() => {
    if (!id) {
      return;
    }

    const mathematicsProblemSolvingApi = new MathematicsProblemSolvingApi();

    mathematicsProblemSolvingApi.getTags(id).then(response => {
      setProblemsSalvingTag(response);
    }).catch(() => {
      //
    });
  }, [display])

  return (
    <div className={`mathematics-problem-solving-add-new-tag display-${String(display)}`}>
      <div className='mathematics-problem-solving-add-new-tag-header'>
        <h1>Adicionar nova tag</h1>
        <IoCloseOutline className='icon-content' onClickCapture={() => {
          setDisplay(!display);
        }} size='25' />
      </div>
      <form onSubmit={huddleSubmit} action='/' method='POST' className='mathematics-problem-solving-add-new-tag-form-content'>
        <div className='input-content'>
          <input type="text" placeholder='Digite o nome da tag' />
        </div>
        <div className='button-content'>
          <button>Adicionar</button>
        </div>
      </form>
      <div className='mathematics-problem-solving-add-new-tag-table'>
        <div className='mathematics-problem-solving-add-new-tag-table-header'>
          <ul>
            <li>
              <h1>N°</h1>
            </li>
            <li>
              <h1>Tag</h1>
            </li>
            <li></li>
          </ul>
        </div>
        <div className='mathematics-problem-solving-add-new-tag-table-content'>
          {problemsSalvingTag.map((tag, index) => {
            return <TableContent index={index} tag={tag} key={tag.id} setProblemsSalvingTag={setProblemsSalvingTag} />
          })}
        </div>
      </div>
    </div>
  )
}

function TableContent({tag, index, setProblemsSalvingTag}: TableContentProps): React.ReactElement {
  const {id} = useParams();

  const huddleDelete = () => {
    if (!id) {
      return;
    }

    const mathematicsProblemsSalvingApi = new MathematicsProblemSolvingApi();

    mathematicsProblemsSalvingApi.delete(id, tag.id ?? '').then(response => {
      setProblemsSalvingTag(response);
    }).catch(e => {
      //
    })
  }

  return (
    <div className='mathematics-problem-solving-add-new-tag-table-list-content'>
      <ul>
        <li>
          <h1>{index + 1}</h1>
        </li>
        <li>
          <h1>{tag.content}</h1>
        </li>
        <li onClickCapture={huddleDelete}>
        <MdDelete size='15' className='delete-icon' style={{ color: '#ff0000' }} />
        </li>
      </ul>
    </div>
  )
}
