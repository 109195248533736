import lscache from 'lscache';
import type NotificationType from '../../../../class/types/notification';

export default function verifyNotificationCount(notifications: NotificationType[]): number {
  const user = lscache.get('user');
  let count = 0;
  notifications.forEach((notification) => {
    if (typeof notification.visualization === 'undefined') {
      count++;
      return;
    }
    if (!notification.visualization[user.id]) {
      count++;
    }
  });

  return count;
}
