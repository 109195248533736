
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-floating-promises */
import type { StrategyOfPlanProtocol } from '../../../class/types/Strategy-of-plan/Strategy-of-plan-factory-protocol';
import React, { useEffect, useState } from 'react';
import ContentTableStrategyOfPlan from '../../../components/strategy-of-plan-files/ContentTable';
import StrategyOfPlanAPI from '../../../class/StrategyOfPlans/StrategyOfPlanAPI';
import filterBySituation from './services/filterBySituation';
import FormEditElement from '../FormEditElement-strategy-of-plans';
import FormAddElement from '../FormAddElement-strategy-of-plans';
import EmptyComponent from '../../../components/Empty-component';
import { useParams } from 'react-router-dom';
import InputContent from '../../../components/actions-files/Input-content';
import filterById from './services/filterById';
import './style.scss';
import printStrategy from '../service/print';

interface TableProps {
  display: string
  setAction: any
}

export default function Table({ display, setAction }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [actions, setStrategyOfPlan] = useState<StrategyOfPlanProtocol[]>([]);
  const [actionsFiltered, setActionFiltered] = useState<StrategyOfPlanProtocol[]>([]);
  const [elementId, setElementId] = useState<string>();

  const { id } = useParams();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(true);
  };

  const setStrategyOfPlanCostumer = (strategyOfPlan: StrategyOfPlanProtocol[]) => {
    const newStrategyOfPlan = filterById(strategyOfPlan, String(id));
    setAction(newStrategyOfPlan);
    setStrategyOfPlan(newStrategyOfPlan);
    setActionFiltered(newStrategyOfPlan);
  };

  const filterBySituationCustumer = (situation: string) => {
    if (situation === 'Todos') {
      setActionFiltered(actions)
      return;
    }

    let newStrategyOfPlan = filterBySituation(situation, String(id), actions);

    if (newStrategyOfPlan.length === 0) {
      newStrategyOfPlan = actions;
      return;
    }

    setActionFiltered(newStrategyOfPlan);
  };

  const print = () => {
    printStrategy(actionsFiltered)
  }

  useEffect(() => {
    StrategyOfPlanAPI.get().then(response => {
      setStrategyOfPlanCostumer(response);
    });
  }, []);

  return (
    <div className='table-container-plans'>
      <FormAddElement
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        displayCreateNewElement={displayCreateNewElement}
        setStrategyOfPlanCustumer={setStrategyOfPlanCostumer}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        elementId={elementId}
        actions={actions}
        setDisplayEditElement={setDisplayEditElement}
        setStrategyOfPlanCustumer={setStrategyOfPlanCostumer}
      />
      <div className='btn-create-new-action-container'>
        <button onClick={hundleCreateNewElement}>Criar uma nova estratégia</button>
        <button onClick={print}>Imprimir</button>
      </div>
      <div className='filter-content-action'>
        <h1>Filtros</h1>
        <ul className='filter'>
          <InputContent
            title='Situação'
            filterBySituationCustumer={filterBySituationCustumer}
            contents={['realizada', 'em andamento', 'não finalizada', 'não inicializado']}
          />
        </ul>
      </div>
      <div className='header-table-element-action' >
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Estratégia</li>
          <li className='header-table-ul-li'>Cronograma</li>
          <li className='header-table-ul-li'>Situação</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table-action'>
        {display === 'true'
          ? (
            actionsFiltered.length === 0
              ? <EmptyComponent>click em (criar uma nova estratégia) para criar uma estrategia</EmptyComponent>
              : actionsFiltered.map((action, index) => (
                <ContentTableStrategyOfPlan
                  action={action}
                  index={index}
                  setStrategyOfPlanCostumer={setStrategyOfPlanCostumer}
                  setElementId={setElementId}
                  setDisplayEditElement={setDisplayEditElement}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}
