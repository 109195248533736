/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import type { RoomType } from '../../../class/types/Room/Rooms-protocol';
import './style.scss';

interface ContentTableType {
  room: RoomType
  index: number
}

export default function ContentTableRoomTeacher({ room, index }: ContentTableType): React.ReactElement {
  const hundleClick = (e: any) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    if (room.id) {
      window.location.href = `${window.location.origin}/salas/${room.roomName}/${room.id}`;
    }
  };

  return (
		<div className='content-table-element-roms-teacher'>
			<ul className='content-table-ul-roms' onClick={hundleClick}>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{room.roomName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{room.teacher ? room.teacher.name : ''}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{room.date ? room.date.split('/')[2] : ''}
					</h2>
				</li>
			</ul>
		</div>
  );
}
