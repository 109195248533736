import React, { useState } from 'react';
import LoadContent from '../../Load-content';
import { MdDelete } from 'react-icons/md';
import PsychologistApi from '../../../class/Psychologist';
import './styles.scss';
import { type PsychologistAnamnese } from '../../../class/types/Psychologist';
import { FiEdit2 } from 'react-icons/fi';

interface TableContentPsychologistStudentRegisterProps {
  student: PsychologistAnamnese
  setStudentPsychologist: React.Dispatch<React.SetStateAction<PsychologistAnamnese[]>>
  setStudentSelected: React.Dispatch<React.SetStateAction<PsychologistAnamnese | undefined>>
  setDisplayStudentInfo: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayEdit: React.Dispatch<React.SetStateAction<boolean>>
}

export default function TableContentPsychologistStudentRegister({student, setDisplayEdit, setStudentPsychologist, setStudentSelected, setDisplayStudentInfo}: TableContentPsychologistStudentRegisterProps): React.ReactElement {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const huddleDelete = (id: string) => {
    const psychologist = new PsychologistApi();

    setDeleteLoading(true);
    psychologist.deleteStudent(id).then(response => {
      setStudentPsychologist(response);
      setDeleteLoading(false);
    }).catch(e => {
      //
    });
  }

  const huddleEdit = () => {
    setStudentSelected(student);
    setDisplayEdit(true);
  }

  const huddleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'path' || target.tagName === 'svg') {
      return;
    }
    setDisplayStudentInfo(true);
    setStudentSelected(student);
  }

  const formatData = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      dateStyle: 'short',
      timeZone: 'UTC',
    })
  }

  return (
    <ul className='content-table-student-psychologist' onClick={huddleClick}>
                <li>{student.studentName}</li>
                <li>{student.motherName}</li>
                <li>{student.fatherName}</li>
                <li>{formatData(student.birthday)}</li>
                <li onClick={huddleEdit}>
                  <FiEdit2 size='20' style={{ color: '#118951' }} />
                </li>
                {deleteLoading
                  ? (
                    <li>
                      <LoadContent isLoading={deleteLoading} style={
                        {
                          button: {
                            width: '20px',
                          },
                          container: {
                            width: '20px',
                            height: '20px',
                          }
                        }
                      } />
                    </li>
                  )
                  : <li className='header-table-ul-li' onClick={() => {
                    if (!student.id) {
                      return;
                    }
                    huddleDelete(student.id);
                  }}>
                    <MdDelete size='20' style={{ color: '#ff0000', cursor: 'pointer' }} />
                  </li>}
              </ul>
  )
}
