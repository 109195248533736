function createSpanElement(msg: string): HTMLSpanElement {
	const span = document.createElement('span');
	span.textContent = msg;
	span.className = 'error-element';
	return span;
}

export default function displayError(input: HTMLElement, msg: string): void {
	const { parentElement } = input;

	const span = createSpanElement(msg);

	if (parentElement?.querySelectorAll('span')[0]) {
		return;
	}

	if (parentElement) {
		parentElement.appendChild(span);

		setTimeout(() => {
			parentElement.removeChild(span);
		}, 4000);
	}
}
