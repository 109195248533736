/* eslint-disable multiline-ternary */
import React, { useEffect, useRef, useState } from 'react';
import clearAllInputs from '../service/clear-all-inputs';
import { type ElementarySchoolMonthlyPlanType } from '../../../class/types/ElementarySchoolMonthlyPlan/ElementaryMonthlyPlanAPI';
import ElementarySchoolMonthlyPlanCostumer from '../../../class/ElementarySchoolMonthlyPlan/ElementarySchoolMonthlyPlan-custumer';
import RuleVerification from '../../../components/rule-verification';
import './style.scss';
import LoadContent from '../../../components/Load-content';

interface FormAddMonthlyPlanProps {
  setDisplayFormEditMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<ElementarySchoolMonthlyPlanType[]>>
  displayFormEditMonthlyPlan: boolean
  elementarySchoolMonthlyPlan: ElementarySchoolMonthlyPlanType[]
  elementId: string
}

export default function FormEditMonthlyPlan({ setDisplayFormEditMonthlyPlan, setElementarySchoolMonthlyPlan, elementId, displayFormEditMonthlyPlan, elementarySchoolMonthlyPlan }: FormAddMonthlyPlanProps): JSX.Element {
  const [element, setElement] = useState<ElementarySchoolMonthlyPlanType>();
  const dateInput = useRef<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormEditMonthlyPlan(false);
    clearAllInputs('form-edit-monthly-plan')
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolMonthlyPlanCostumer = new ElementarySchoolMonthlyPlanCostumer(inputs);

    setIsLoading(true);
    elementarySchoolMonthlyPlanCostumer.put(elementId).then((response) => {
      setElementarySchoolMonthlyPlan(response);
      setDisplayFormEditMonthlyPlan(false);
      clearAllInputs('form-edit-monthly-plan');
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const formatDate = (_date: string) => {
    const dateList = _date.split('/');

    const newDate = `${dateList[2]}-${dateList[1]}-${dateList[0]}`;

    return newDate;
  }

  useEffect(() => {
    const element = elementarySchoolMonthlyPlan.find((value) => value.id === elementId);

    (dateInput.current as HTMLInputElement).value = formatDate(element?.date ?? '');

    setElement(element);
  }, [displayFormEditMonthlyPlan]);

  return (
    <div className={`form-edit-monthly-plan display-${String(displayFormEditMonthlyPlan)}`}>
      <div>
        <h1>Plano Mensal - Ensino Fundamental</h1>
      </div>
      <form method='POST' action='/' className='main-form-dit-monthly-plan' id='form-edit-monthly-plan' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label>Escola</label>
          <input type="text" placeholder={element?.school} />
        </div>
        <div className='input-element-content'>
          <label>Professor(a):</label>
          <input type="text" placeholder={element?.teacher} />
        </div>
        <div className='input-element-content'>
          <label>Turma:</label>
          <input type="text" placeholder={element?.class} />
        </div>
        <div className='input-element-content'>
          <label>Tempo Vigente:</label>
          <input type="text" placeholder={element?.currentTime} />
        </div>
        <div className='input-element-content'>
          <label>Ano:</label>
          <input type="text" placeholder={element?.yearn} />
        </div>
        <div className='input-element-content'>
          <label>Componente curricular:</label>
          <input type="text" placeholder={element?.curricularComponent} />
        </div>
        <div className='input-element-content'>
          <label>Objetivo geral:</label>
          <input type="text" placeholder={element?.strategicObjective} />
        </div>
        <div className='input-element-content'>
          <label>Objetivos específicos:</label>
          <input type="text" placeholder={element?.specificObjective} />
        </div>
        <div className='input-element-content date'>
          <label>Data:</label>
          <input type="date" ref={dateInput} />
        </div>
        <div className='title'>
          <h1>Metodologia</h1>
        </div>
        <div className='input-element-content'>
          <div className='input-content'>
            <label>Unidade Temática</label>
            <input type="text" placeholder={element?.methodology.thematicUnit} />
          </div>
          <div className='input-content'>
            <label>Campo de atuação</label>
            <input type="text" placeholder={element?.methodology.fieldOfAction} />
          </div>
          <div className='input-content'>
            <label>Habilidade</label>
            <input type="text" placeholder={element?.methodology.ability} />
          </div>
          <div className='input-content'>
            <label>Objetivos de Aprendizagem</label>
            <input type="text" placeholder={element?.methodology.learningObjectives} />
          </div>
          <div className='input-content'>
            <label>Metodologia/Estratégia</label>
            <input type="text" placeholder={element?.methodology.methodologyStrategy} />
          </div>
          <div className='input-content'>
            <label>Avaliação</label>
            <input type="text" placeholder={element?.methodology.assessment} />
          </div>
        </div>
        <RuleVerification rule={['coordenador']}>
          <>
            <div className='title'>
              <h1>Comentário</h1>
            </div>
            <div className='input-element-content'>
              <div className='input-content'>
                <label>Comentário</label>
                <input type="text" placeholder={element?.commentary ?? ''} />
              </div>
            </div>
          </>
        </RuleVerification>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
