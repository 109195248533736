
import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { StudentType } from '../types/Student/student-factory-protocol';
import type { UserProtocol } from '../types/User/User-factory-protocol';

class StudentApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post(student: StudentType): Promise<StudentType[]> {
		let newStudent: StudentType[] = [];

		if (this.href) {
			await axios.post(`${this.href}/post/student`, student).then(response => {
				newStudent = response.data as StudentType[];
			});
		}

		return newStudent;
	}

	async put(strategy: StudentType, id: string): Promise<any> {
		let students: StudentType[] = [];

		if (this.href) {
			await axios.put(`${this.href}/update/student/${id}`, strategy).then(response => {
				students = response.data as StudentType[];
			});
		}

		return students;
	}

	async deleteStudent(id: string): Promise<any> {
		let students: StudentType[] = [];

		if (this.href) {
			await axios.delete(`${this.href}/delete/student/${id}`).then(response => {
				students = response.data as StudentType[];
			});
		}

		return students;
	}

	async get(alphabet = true): Promise<StudentType[]> {
		let students: StudentType[] = [];

		await axios.get(`${this.href}/get/students/`, {
      data: {
        alphabet,
      }
    }).then(response => {
			students = response.data as StudentType[];
		});

		return students;
	}

  async getAllStudentByMonetaryAid(): Promise<StudentType[]> {
    let students: StudentType[] = [];

    await axios.get(`${this.href}/get/students/monetary/aid`).then(response => {
      students = response.data;
    });

    return students;
  }
}

export default new StudentApi();
