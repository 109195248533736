import axios from 'axios';
import config from '../../config/config';

export type DocumentType = 'elementary-weekly-plan' | 'kindergarten-school-weekly-plan' | 'workshop-weeklyPlan';

export default class DocumentSendingManagerApi {
  private get href() {
		return config.apiUrl;
	}

  async get(documentType: DocumentType): Promise<{
    date: number[]
    nextDate: string
  } | undefined> {
    let dayToPostWeeklyPlan: {
        date: number[]
        nextDate: string
      } | undefined;

    if (this.href) {
      await axios.get(this.href + `/document-sending-manager/${documentType}`).then(response => {
        dayToPostWeeklyPlan = response.data;
      });
    }

    return dayToPostWeeklyPlan;
  }
}
