import React from 'react';
import Table from '../Table';
import './style.scss';

interface EstrategyProps {
  id: string
  cardReport: Record<string, string>
  display: string
}

export default function CardReport({ id, cardReport, display }: EstrategyProps): React.ReactElement {
  return (
		<div className={'matter-container'} id={id}>
			<Table display={display} cardReport={cardReport} />
		</div>
  );
}
