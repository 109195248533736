import displayError from '../../../class/services/displayError';

export default function VerifyInput(input: HTMLInputElement, textArea: HTMLTextAreaElement): boolean {
  const inputText = input.value;
  const textAreaValue = textArea.value;

  if (inputText.length === 0) {
    displayError(input, 'o campo precisa ser preenchido!!');
    return false;
  }
  if (textAreaValue.length === 0) {
    displayError(textArea, 'o campo precisa ser preenchido!!');
    return false;
  }

  return true;
}
