/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import EmptyComponent from '../../../components/Empty-component';
import lscache from 'lscache';

import FormAddWeeklyPlan from '../form-add-addendum-to-the-school-calendar';

import BackToTop from '../../../components/back-to-top/back-to-top';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import RoomObserverApi from '../../../class/RoomObserver/RoomObserverApi';
import type RoomObserverType from '../../../class/types/RoomObserver';
import ContentTableRoomObserver from '../../../components/addendum-to-the-school-calendar/ContentTable-addendum-to-the-school-calendar';
import FormEditWeeklyPlan from '../form-edit-addendum-to-the-school-calendar';
import filterByDate from '../service/filter-by-date';
import WeeklyPlanDisplayInformation from '../addendum-to-the-school-calendar-information';

interface TableProps {
  display: string
  roomInformation: {
    matterId: string
    matterName: string
    twoMonths: string
    twoMonthsName: string
  }
}

export default function Table({ display, roomInformation }: TableProps): React.ReactElement {
  const [displayEditElement, setDisplayEditElement] = useState(false);
  const [displayFormAddElementaryWeeklyPlan, setDisplayElementaryWeeklyPlan] = useState(false);
  const [displayInfo, setDisplayInfo] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elementarySchoolWeeklyPlan, setElementarySchoolWeeklyPlan] = useState<RoomObserverType[]>([]);
  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [element, setElement] = useState<RoomObserverType>();
  const mainElement = useRef<any>();

  const { id } = useParams();

  const huddleCreateNewElement = () => {
    setDisplayElementaryWeeklyPlan(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = filterByDate(elementarySchoolWeeklyPlan, inputs[0].value, inputs[1].value);

    setElementFiltered(filterPlan);
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id || !id) {
      return;
    }
    if (display === 'false') {
      return;
    }
    RoomObserverApi.get(id, roomInformation.matterId, roomInformation.twoMonths).then(response => {
      setElementarySchoolWeeklyPlan(response);
    });
  }, [display, roomInformation]);

  useEffect(() => {
    setElementFiltered(elementarySchoolWeeklyPlan);
  }, [elementarySchoolWeeklyPlan]);

  return (
    <div className='table-container-elementary-room-observer'>
      <ul className='table-header-information'>
        <li>Adendo ao Calendário Escolar</li>
        <li>Bimestre: {roomInformation.twoMonthsName}</li>
        <li>Matéria: {roomInformation.matterName}</li>
      </ul>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddWeeklyPlan
        displayFormAddWeeklyPlan={displayFormAddElementaryWeeklyPlan}
        roomInformation={roomInformation}
        setDisplayFormAddWeeklyPlan={setDisplayElementaryWeeklyPlan}
        setElementarySchoolWeeklyPlan={setElementarySchoolWeeklyPlan}
        elementarySchoolWeeklyPlan={elementarySchoolWeeklyPlan}
      />
      <FormEditWeeklyPlan
        elementSelected={element}
        displayFormAddWeeklyPlan={displayEditElement}
        roomInformation={roomInformation}
        setDisplayFormAddWeeklyPlan={setDisplayEditElement}
        setElementarySchoolWeeklyPlan={setElementarySchoolWeeklyPlan}
        elementarySchoolWeeklyPlan={elementarySchoolWeeklyPlan}
      />
      <WeeklyPlanDisplayInformation
        displayWeeklyPlanInfo={displayInfo}
        setDisplayWeeklyPlanInfo={setDisplayInfo}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar Adendo</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content input-data'>
          <input type='date' placeholder='nome da sala' />
        </div>
        <h2>A</h2>
        <div className='search-input-content input-content input-data'>
          <input type='date' placeholder='nome da sala' />
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Adendo</li>
          <li className='header-table-ul-li'>Data</li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '10rem',
              }
            }}
          />
          : (
            (display === 'true' && isLoading === false)
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar Adendo ao Calendário Escolar) para adicionar uma novo adendo</EmptyComponent>
                  : elementFiltered?.map((roomObserver: RoomObserverType, index: number) => (
                    <ContentTableRoomObserver
                      index={index}
                      roomObserver={roomObserver}
                      roomInformation={roomInformation}
                      setElement={setElement}
                      setDisplayInformation={setDisplayInfo}
                      setDisplayEditRoomObserver={setDisplayEditElement}
                      setRoomObservationSheet={setElementarySchoolWeeklyPlan}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}
