/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, {useState, useEffect} from 'react';
import type { MatterResProtocol } from '../../../class/types/Matter/matterApi-protocol';
import { useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import LoadContent from '../../Load-content';
import MatterAPI from '../../../class/Matter/MatterAPI';
import './style.scss';
import { FiEdit2 } from 'react-icons/fi';

interface ContentTableType {
  matter: MatterResProtocol
  index: number
  displayMatter: boolean
  setMatterCustumer: React.Dispatch<React.SetStateAction<MatterResProtocol[]>>
  setDisplayMatterEdit: React.Dispatch<React.SetStateAction<boolean>>
  setMatterSelected: React.Dispatch<React.SetStateAction<MatterResProtocol | undefined>>
}

export default function ContentTableMatter({ matter, index, displayMatter, setMatterSelected, setDisplayMatterEdit, setMatterCustumer: setMatterCostumer }: ContentTableType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);
  const { id } = useParams();

  const huddleEdit = () => {
    setMatterSelected(matter);
    setDisplayMatterEdit(!displayMatter);
  }

  const huddleDelete = () => {
    if (id && matter.id) {
      setIsLoading(true);
      MatterAPI.delete(id, matter.id).then(response => {
        setMatterCostumer(response);
        setIsLoading(false);
      }).catch(e => {
        //
      });
    }
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [matter]);

  return (
		<div className={`content-table-element-matter display-animation-${displayAnimation}`}>
			<ul className='content-table-ul-roms' >
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{matter.matterName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{matter.teacherInfo ? matter.teacherInfo.teacherName : ''}
					</h2>
				</li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
			</ul>
		</div>
  );
}
