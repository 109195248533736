import React from 'react';
import Table from '../Table';
import './style.scss';

interface EstrategyProps {
  id: string
}

export default function IndividualCardReport({ id}: EstrategyProps): React.ReactElement {
  return (
		<div className={'individual-card-report'} id={id}>
			<Table/>
		</div>
  );
}
