/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */

import type { RoomType } from '../../../class/types/Room/Rooms-protocol';
import React, {useState, useEffect} from 'react';
import { MdDelete } from 'react-icons/md';
import LoadContent from '../../Load-content';
import { FiEdit2 } from 'react-icons/fi';
import RoomsApi from '../../../class/Room/RoomsApi';
import './style.scss';

interface ContentTableType {
  room: RoomType
  index: number
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElement: React.Dispatch<React.SetStateAction<RoomType | undefined>>
  setRoomCostumer: React.Dispatch<React.SetStateAction<RoomType[]>>
}

export default function ContentTableRoom({ room, index, setDisplayEditElement, setElement, setRoomCostumer }: ContentTableType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);

  const huddleDelete = () => {
    if (room.id) {
      setIsLoading(true);
      RoomsApi.delete(room.id).then(response => {
        setRoomCostumer(response);
        setIsLoading(false);
      });
    }
  };

  const hundleEdit = () => {
    if (room.id) {
      setDisplayEditElement(true);
      setElement(room);
    }
  };

  const hundleClick = (e: any) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    if (room.id) {
      window.location.href = `${window.location.origin}/salas/${room.roomName}/${room.id}`;
    }
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [room]);

  return (
		<div className={`content-table-element-roms display-animation-${displayAnimation}`}>
			<ul className='content-table-ul-roms' onClick={hundleClick}>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{room.roomName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{room.teacher ? room.teacher.name : ''}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{room.schoolName ? room.schoolName : ''}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{room.date ? room.date.split('/')[2] : ''}
					</h2>
				</li>
				<li className='content-table-ul-li' onClick={hundleEdit}>
					<FiEdit2 size='20' style={{ color: '#118951' }} />
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
			</ul>
		</div>
  );
}
