export default function compareDataDashboard(
  dataDashboardOne: number[],
  dataDashboardTwo: number[]
): number[] {
  const newSeries: number[] = [];

  dataDashboardOne.forEach((data, index) => {
    const maths = dataDashboardTwo[index] - data;
    newSeries.push(maths);
  });

  return newSeries;
}
