/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type ActionAPIProtocol from '../types/Action/ActionAPI-protocol';
import type {ActionResponseProtocol} from '../types/Action/ActionAPI-protocol';
import type {UserProtocol} from '../types/User/User-factory-protocol';

class ActionApi implements ActionAPIProtocol {
	private readonly user = lscache.get('user') as UserProtocol;

	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
    lscache.setExpiryMilliseconds(60000);
	}

	async get(): Promise<ActionResponseProtocol[]> {
		let actions: ActionResponseProtocol[] = [];

		if (this.href) {
			await axios.get(`${this.href}/get/action`).then(response => {
				actions = response.data as ActionResponseProtocol[];
			});

			this.saveAction(actions);
		}

		return actions;
	}

	async post(action: ActionResponseProtocol): Promise<ActionResponseProtocol[]> {
		let actions: ActionResponseProtocol[] = [];

		if (this.href) {
			await axios.post(`${this.href}/post/action`, action).then(response => {
				actions = response.data as ActionResponseProtocol[];
			});

			this.saveAction(actions);
		}

		return actions;
	}

	async deleteAction(id: string): Promise<ActionResponseProtocol[]> {
		let actions: ActionResponseProtocol[] | Promise<ActionResponseProtocol[]> = [];

		if (this.href) {
			await axios.delete(`${this.href}/delete/action/${id}`).then(response => {
				actions = response.data as ActionResponseProtocol[];
			});

			this.saveAction(actions);
		}

		return actions;
	}

	async put(strategy: ActionResponseProtocol, id: string): Promise<ActionResponseProtocol[]> {
		let strategies: ActionResponseProtocol[] | Promise<ActionResponseProtocol[]> = [];

		if (this.href) {
			await axios.put(`${this.href}/update/action/${id}`, strategy).then(response => {
				strategies = response.data as ActionResponseProtocol[];
			});

			this.saveAction(strategies);
		}

		return strategies;
	}

	private saveAction(strategies: ActionResponseProtocol[]): void {
		lscache.set('actions', strategies, config.processTemp);
	}
}

export default new ActionApi();
