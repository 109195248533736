import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type ElementarySchoolWeeklyPlanPut } from '../types/Upload_ElementarySchoolWeeklyPlan';
import type FileAdmLegislationType from '../types/AdmLegislation';

type OptionType = 'general';

class UploadAdmCalendarApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post(option: OptionType, { file, description, date, name, userName, userId }: {
    file: File
    description: string
    date: string
    name: string
    userName: string
    userId: string
  }): Promise<FileAdmLegislationType[]> {
		let admCalendar: FileAdmLegislationType[] = [];

		const formDate = new FormData();

		formDate.append('fileNameCalendar', file);
    formDate.append('description', description);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/calendar/' + option,
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			admCalendar = response.data as FileAdmLegislationType[];
		});

		return admCalendar;
	}

	async put(option: OptionType, { file, name, weeklyPlanId }: ElementarySchoolWeeklyPlanPut): Promise<FileAdmLegislationType[]> {
		let admCalendar: FileAdmLegislationType[] = [];

		const formData = new FormData();

		formData.append('fileNameCalendar', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/calendar/${option}/${weeklyPlanId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			admCalendar = response.data as FileAdmLegislationType[];
		});

		return admCalendar;
	}

	async get(option: OptionType): Promise<FileAdmLegislationType[]> {
		let admCalendar: FileAdmLegislationType[] = [];

		await axios.get(this.href + '/file/calendar/' + option).then(response => {
			admCalendar = response.data as FileAdmLegislationType[];
		}).catch(e => {
			//
		});

		return admCalendar;
	}

	async delete(option: OptionType, elementId: string, fileName: string): Promise<FileAdmLegislationType[]> {
		let admCalendar: FileAdmLegislationType[] = [];

		await axios.delete(this.href + `/file/calendar/${option}/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			admCalendar = response.data as FileAdmLegislationType[];
		}).catch(e => {
			//
		});

		return admCalendar;
	}
}

export default new UploadAdmCalendarApi();
