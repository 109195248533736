import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import lscache from 'lscache';
import { type UserProtocol } from '../../../../class/types/User/User-factory-protocol';

export default async function exportToPDF(text: string, studentName: string) {
  const pdf = new JSPDF();

  const user = lscache.get('user') as UserProtocol;

  const titleOfTableElement = document.getElementById('title-table-student-info-psychologist') as HTMLElement;
  const identificationElement = document.getElementById('identification-student-info-psychologist') as HTMLElement;
  const familyDateElement = document.getElementById('family-date-student-info-psychologist') as HTMLElement;
  const historyElement = document.getElementById('history-student-info-psychologist') as HTMLElement;
  const presentDificultElement = document.getElementById('present-dificult-student-info-psychologist') as HTMLElement;
  const activitiesOfLife = document.getElementById('activities-of-life') as HTMLElement;
  const atWhatAgeWareDiapersRemovedElement = document.getElementById('at-what-age-were-diapers-removed-td-table-student-info') as HTMLElement;
  const ownTendenciesStudentInfPsychologist = document.getElementById('own-tendencies-student-inf-psychologist') as HTMLElement;
  const educationStudentInfoPsychologist = document.getElementById('education-student-info-psychologist') as HTMLElement;
  const takePartInSomeOfTheActivitiesBelow = document.getElementById('take-part-in-some-of-the-activities-below') as HTMLElement;
  const sociabilityStudentInfoPsychologist = document.getElementById('sociability-student-info-psychologist') as HTMLElement;
  const finalObservationStudentInfoPsychologist = document.getElementById('observation-student-info-psychologist') as HTMLElement;

  pdf.setFontSize(13);
  pdf.text(`${user.userName ?? ''} – PSICÓLOGA CRP ${text}`, 110, 5, {
    align: 'center',
  });

  const elementWidth = 210 * 0.90
  const elementX = 210 * 0.05

  await html2canvas(titleOfTableElement).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', elementX, 15, elementWidth, 0);
  });
  await html2canvas(identificationElement).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 27, elementWidth, 0);
  });
  await html2canvas(familyDateElement).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 88, elementWidth, 0);
  });

  pdf.addPage();

  await html2canvas(historyElement).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 10, elementWidth, 0);
  });

  pdf.addPage();

  await html2canvas(presentDificultElement).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 10, elementWidth, 0);
  });

  await html2canvas(activitiesOfLife).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 110, elementWidth, 0);
  });

  await html2canvas(atWhatAgeWareDiapersRemovedElement).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 160, elementWidth, 0);
  });

  pdf.addPage();

  await html2canvas(ownTendenciesStudentInfPsychologist).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 10, elementWidth, 0);
  });

  await html2canvas(educationStudentInfoPsychologist).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 120, elementWidth, 0);
  });

  pdf.addPage();

  await html2canvas(takePartInSomeOfTheActivitiesBelow).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 10, elementWidth, 0);
  });

  pdf.addPage();

  await html2canvas(sociabilityStudentInfoPsychologist).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 10, elementWidth, 0);
  });

  await html2canvas(finalObservationStudentInfoPsychologist).then((canvas: any) => {
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', elementX, 200, elementWidth, 0);
  });

  pdf.save(`FICHA DE ANAMNESE - ${studentName}.pdf`);
}
