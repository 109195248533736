
import axios from 'axios';
import type ElementarySchoolDecentWorkApi from '../types/Upload_ElementarySchoolDecenteWork';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type ElementarySchoolWeeklyPlanType, type ElementarySchoolWeeklyPlanPost, type ElementarySchoolWeeklyPlanPut } from '../types/Upload_ElementarySchoolWeeklyPlan';

type ElementarySchoolProjectPostType = {
  visibility: string
} | ElementarySchoolWeeklyPlanPost;

class UploadElementarySchoolProjectApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, classRoom, date, visibility, name, userName, userId }: {
    file: File
    classRoom: string
    date: string
    visibility: string
    name: string
    userName: string
    userId: string
  }): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryProject: ElementarySchoolWeeklyPlanType[] = [];

		const formDate = new FormData();

		formDate.append('fileProject', file);
    formDate.append('classRoom', classRoom);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);
    formDate.append('visibility', visibility);

		await axios({
			method: 'post',
			url: this.href + '/file/elementaryschool/projects',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementaryProject = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newElementaryProject;
	}

	async postDescription(decentWorkId: string, description: string): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryProject: ElementarySchoolWeeklyPlanType[] = [];

		await axios.post(`${this.href}/description/elementaryschool/projects/${decentWorkId}`, {
			description
		}).then(response => {
			newElementaryProject = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newElementaryProject;
	}

  async setChecking(weeklyPlanId: string): Promise<ElementarySchoolWeeklyPlanType[]> {
    let newElementarySchoolProject: ElementarySchoolWeeklyPlanType[] = [];

    await axios.post(`${this.href}/checking/elementaryschool/projects/${weeklyPlanId}`).then(response => {
      newElementarySchoolProject = response.data;
    });

    return newElementarySchoolProject;
  }

	async put({ file, name, weeklyPlanId, visibility }: {
    file: File | undefined | null
    name: string
    weeklyPlanId: string
    visibility: string
  }): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryProject: ElementarySchoolWeeklyPlanType[] = [];

		const formData = new FormData();

    if (file) {
      formData.append('fileProject', file);
      formData.append('name', name);
    }
    formData.append('visibility', visibility);

		await axios({
			method: 'put',
			url: `${this.href}/file/elementaryschool/projects/${weeklyPlanId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementaryProject = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newElementaryProject;
	}

	async get(): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryProject: ElementarySchoolWeeklyPlanType[] = [];

		await axios.get(this.href + '/file/elementaryschool/projects').then(response => {
      newElementaryProject = response.data as ElementarySchoolWeeklyPlanType[];
		}).catch(e => {
			//
		});

		return newElementaryProject;
	}

	async delete(elementId: string, fileName: string): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryProject: ElementarySchoolWeeklyPlanType[] = [];

		await axios.delete(this.href + `/file/elementaryschool/projects/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			newElementaryProject = response.data as ElementarySchoolWeeklyPlanType[];
		}).catch(e => {
			//
		});

		return newElementaryProject;
	}

  async getData(teacherId: string): Promise<ElementarySchoolWeeklyPlanType[]> {
    let newElementaryProject: ElementarySchoolWeeklyPlanType[] = [];

    await axios.get(this.href + `/file/elementaryschool/projects/get_by_id/${teacherId}`).then(response => {
      newElementaryProject = response.data;
    }).catch(e => {
      //
    });

    return newElementaryProject;
  }
}

export default new UploadElementarySchoolProjectApi();
