/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {useState, useEffect} from 'react';
import type { RoomType } from '../../../class/types/Room/Rooms-protocol';
import './style.scss';

interface ContentTableType {
  room: RoomType
  index: number
}

export default function ContentTableRoomWritingLevelCoordinator({ room, index }: ContentTableType): React.ReactElement {
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [room]);

  return (
		<div className={`content-table-element-rooms-writing-level-coordinator display-animation-${displayAnimation}`}>
			<ul className='content-table-ul-roms'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{room.roomName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{room.teacher ? room.teacher.name : ''}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{room.schoolName ? room.schoolName : ''}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{room.date ? room.date.split('/')[2] : ''}
					</h2>
				</li>
			</ul>
		</div>
  );
}
