/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import EmptyComponent from '../../../components/Empty-component';
import FilterPlan from '../../../class/filterPlan';
import BackToTop from '../../../components/back-to-top/back-to-top';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import uploadPsychologistApi from '../../../class/UploadPsychologistReport/upload-psychologist-api';
import ContentTablePsychologistReport from '../../../components/psychologist-report';
import FormEditPsychologistReport from '../form-edit-psychologist-report';
import PsychologistDisplayInformation from '../psychologist-report-display-information';
import FormAddPsychologistReport from '../form-add-psychologist-report';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayFormDescribeElementaryWeeklyPlan, setDisplayDescribeElementaryWeeklyPlan] = useState(false);
  const [displayFormEditElementaryWeeklyPlan, setDisplayEditElementaryWeeklyPlan] = useState(false);
  const [displayFormAddElementaryWeeklyPlan, setDisplayElementaryWeeklyPlan] = useState(false);
  const [displayWeeklyPlanInfo, setDisplayWeeklyPlan] = useState(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elementarySchoolWeeklyPlan, setElementarySchoolWeeklyPlan] = useState<ElementarySchoolDecentWorkType[]>([]);
  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [displayReadChecking, setDisplayReadChecking] = useState<boolean>(false);

  const [element, setElement] = useState<ElementarySchoolDecentWorkType>(elementDefault);
  const mainElement = useRef<any>();
  const huddleCreateNewElement = () => {
    setDisplayElementaryWeeklyPlan(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan(inputs, elementarySchoolWeeklyPlan);

    setElementFiltered(filterPlan.searchPlans());
  };

  useEffect(() => {
    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    uploadPsychologistApi.get().then((response) => {
      setIsLoading(false);
      setElementarySchoolWeeklyPlan(response);
      setElementFiltered(response);
    }).catch((e) => {
      //
    });
  }, [display]);

  useEffect(() => {
    setElementFiltered(elementarySchoolWeeklyPlan);
  }, [elementarySchoolWeeklyPlan]);

  return (
    <div className='table-container-psychologist-report'>
      <h1 className='title-table'>Relatório</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddPsychologistReport
        displayFormAddPsychologistReport={displayFormAddElementaryWeeklyPlan}
        setDisplayFormAddPsychologistReport={setDisplayElementaryWeeklyPlan}
        setElementarySchoolPsychologistReport={setElementarySchoolWeeklyPlan}
        psychologistReport={elementarySchoolWeeklyPlan}
      />
      <FormEditPsychologistReport
        displayFormEditPsychologistReport={displayFormEditElementaryWeeklyPlan}
        setDisplayFormEditPsychologistReport={setDisplayEditElementaryWeeklyPlan}
        setPsychologistReport={setElementarySchoolWeeklyPlan}
        setDisplayEditElement={setDisplayEditElementaryWeeklyPlan}
        elementId={elementId}
      />
      <PsychologistDisplayInformation
        displayPsychologistInfo={displayWeeklyPlanInfo}
        setDisplayPsychologistInfo={setDisplayWeeklyPlan}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar Relatório</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Nome do Arquivo</li>
          <li className='header-table-ul-li'>Data</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
              isLoading={isLoading}
              style={{
                container: {
                  marginTop: '10rem',
                }
              }}
          />
          : (
            (display === 'true' && isLoading === false)
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar relatório) para adicionar um novo relatório</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType, index: number) => (
                    <ContentTablePsychologistReport
                      index={index}
                      elementarySchoolWeeklyPlan={elementarySchoolWeeklyPlan}
                      setDisplayReadChecking={setDisplayReadChecking}
                      setDisplayEditElement={setDisplayEditElementaryWeeklyPlan}
                      setDisplayDescribeElementarySchoolWeeklyPlan={setDisplayDescribeElementaryWeeklyPlan}
                      setElementId={setElementId}
                      setElement={setElement}
                      setDisplayWeeklyPlan={setDisplayWeeklyPlan}
                      setElementarySchoolWeeklyPlan={setElementarySchoolWeeklyPlan}
                      type={'elementary-school'}
                    />
                  ))
              )
              : ''
          )
      }
      </main>
    </div>
  );
}
