/* eslint-disable no-negated-condition */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, { useState, useEffect } from 'react';
// import type { FrequencyType } from '../../../class/types/Frequency/frequency-factory-protocol';
// import FrequencyInformationElement from '../../../components/frequency-files/frequency-information';
import './style.scss';
import { type FrequencyMissed } from '../../../class/types/Frequency/frequency-factory-protocol';
import frequencyApi from '../../../class/Frequency/frequency-api';
import { useParams } from 'react-router-dom';
import MissedInformationElement from '../../../components/frequency-files/missed-information';
import printMissedInformation from './service/print-missed-information';

interface FrequencyInformationProps {
  cardInformation: Record<string, string>
  displayMissed: boolean
  setDisplayMissed: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MissedInformation({ cardInformation, displayMissed, setDisplayMissed }: FrequencyInformationProps): React.ReactElement {
  const [frequency, setFrequency] = useState<FrequencyMissed[]>([]);
  const { id, roomName } = useParams();

  const hundleClick = () => {
    setDisplayMissed(false);
  };

  const print = () => {
    if (!roomName) {
      return;
    }

    printMissedInformation(frequency, roomName, cardInformation.twoMonthsName);
  }

  useEffect(() => {
    if (!displayMissed) {
      setFrequency([]);
      return;
    }
    if (!id) {
      return;
    }
    frequencyApi.getMissed(id, cardInformation.matterId, cardInformation.twoMonths).then(response => {
      setFrequency(response);
    }).catch(e => {
      //
    })
  }, [displayMissed]);

  return (
		<div className={`missed-container-information display-${displayMissed}`}>
			<div className='header-frequency-container'>
				<div className='header-frequency-element'>
					<h1>Frequência</h1>
					<h1>Materia: {cardInformation.matterName}</h1>
					<h1>{cardInformation.twoMonthsName}</h1>
				</div>
				<div className='button-content'>
          <button type='button' onClick={print}>Imprimir</button>
					<button type='button' onClick={hundleClick}>Voltar</button>
				</div>
			</div>
			<div className='header-table-element-frequency'>
				<ul className='header-table-ul'>
					<li className='header-table-ul-li'>Nº</li>
					<li className='header-table-ul-li'>Nome</li>
					<li className='header-table-ul-li'>Presenças</li>
					<li className='header-table-ul-li'>Faltas</li>
          <li className='header-table-ul-li'>Porcentual</li>
        </ul>
			</div>
			<div className='body-tabele-element-frequency'>
				{frequency.map((frequency, index) => <MissedInformationElement
					frequency={frequency}
					index={index}
				/>)}
			</div>
		</div>
  );
}
