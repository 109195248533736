/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState } from 'react';
import { AiFillHome, AiOutlineUnorderedList } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { GoGraph } from 'react-icons/go';
import UserAPI from '../../class/User/UserAPI';
import lscache from 'lscache';

import './style.scss';

type Elements = Array<{
  name: string
  boolean: string
}>;

interface NavProps {
  elements: Elements
  setElements: (elements: Elements) => void
}

export default function Nav({ setElements, elements }: NavProps): React.ReactElement {
  const [displayLogout, setDisplayLogout] = useState(false);

  const { room, twoMonths } = useParams();
  const user = lscache.get('user');

  const onClickLi = (elementName: string) => {
    const elementsResected = resetElements(elements);

    elementsResected.forEach(element => {
      if (element.name === elementName) {
        element.boolean = 'true';
      }
    });
    setElements(elementsResected);
  };

  const huddleDisplay = (set: (e: React.SetStateAction<boolean>) => void, get: boolean): void => {
    set(!get);
  };

  const resetElements = (elements: Elements) => {
    elements.forEach(element => {
      element.boolean = 'false';
    });
    return elements;
  };

  const redirect = (href: string) => {
    window.location.href = href;
  }

  return (
    <nav className='nav-component-reading-fluency'>
      <div onClick={() => {
        huddleDisplay(setDisplayLogout, displayLogout);
      }} className='strategy-title'>
        <div className={`display-${displayLogout}`}>
          <h1>Fluência Leitora</h1>
          <p>
            {room} - {twoMonths}
          </p>
        </div>
      </div>
      <ul className='ul-option'>
        <li className='option-content-1' id='strategy-1' onClick={e => {
          redirect('/');
        }}>
          <AiFillHome size='20' color='#fff' />
          <h1 id='strategy-1'>Inicio</h1>
        </li>
        <li className='option-content-1' id='strategy-1' onClickCapture={() => {
          onClickLi('strategy-1');
        }}>
          <AiOutlineUnorderedList size='20' id='strategy-1' color='#FFF'/>
          <h1 id='strategy-1'>Tabela</h1>
        </li>
        <li className='option-content-1' id='strategy-1' onClickCapture={() => {
          onClickLi('strategy-2');
        }}>
          <GoGraph size='20' id='strategy-1' color='#FFF'/>
          <h1 id='strategy-1'>Dashboard</h1>
        </li>
      </ul>
    </nav>
  );
}
