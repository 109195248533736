/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, {useState, useEffect} from 'react';

import { type UploadRoomObserverSheetType } from '../../class/types/Upload_room-observer-sheet-api';
import uploadRoomObserverSheetApi from '../../class/UploadRoomObserverSheet/room-observer-sheet-api';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DisplayFileInfoTree } from '../DisplayFile';
import { useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';

import './style.scss';
import LoadContent from '../Load-content';
import { IoMdDownload } from 'react-icons/io';

interface ContentTableRoomObserverSheetType {
  uploadRoomObserverSheet: UploadRoomObserverSheetType
  index: number
  setDisplayDescribeRoomObserverSheet: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayUploadRoomObserverSheet: React.Dispatch<React.SetStateAction<boolean>>
  setUploadRoomObserverSheet: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setElement: React.Dispatch<React.SetStateAction<UploadRoomObserverSheetType>>
}
export default function ContentTableUploadRoomObserverSheet({
  uploadRoomObserverSheet,
  index,
  setDisplayUploadRoomObserverSheet,
  setUploadRoomObserverSheet,
  setDisplayEditElement,
  setElementId,
  setElement,
}: ContentTableRoomObserverSheetType): React.ReactElement {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const huddleDelete = () => {
    if (!id) {
      return;
    }

    setIsLoading(true);
    uploadRoomObserverSheetApi.delete(String(uploadRoomObserverSheet.id), id, uploadRoomObserverSheet.location.fileName).then(response => {
      setUploadRoomObserverSheet(response);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(uploadRoomObserverSheet.id));
    setDisplayEditElement(true);
  };

  // const huddleDescribe = () => {
  //   setElementId(String(uploadRoomObserverSheet.id));

  //   setDisplayDescribeRoomObserverSheet(true);
  // };

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(uploadRoomObserverSheet);
    setDisplayUploadRoomObserverSheet(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 15) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [uploadRoomObserverSheet]);

  return (
    <div className={`content-table-upload-room-observer-sheet display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={uploadRoomObserverSheet.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(uploadRoomObserverSheet.issuer?.userName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(uploadRoomObserverSheet.date ?? '')}
          </h2>
        </li>
        <li>
          <a href={uploadRoomObserverSheet.location ? uploadRoomObserverSheet.location.url : ''} target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
      </ul>
    </div>
  );
}
