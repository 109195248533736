/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable  @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { MdDelete } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import type { TagTitleList, TagType } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import './style.scss';
import studentWritingLevelRecordApi from '../../../class/student-writing-level-record/student-writing-level-record-api';

interface ContentTableType {
  setWritingLevelTags: React.Dispatch<React.SetStateAction<TagType[]>>
  huddleDisplayList: (response: TagType[]) => void
  element: TagTitleList
  activityInfo: any
  tag: TagType
  index: number
}

export default function ContentTableTag({ tag, setWritingLevelTags, huddleDisplayList, element, activityInfo, index }: ContentTableType): React.ReactElement {
  const { id } = useParams();

  const hundleDelete = () => {
    if (!id) {
      return;
    }

    if (element.tag === 'tagOfTitle' && element.id) {
      studentWritingLevelRecordApi.deleteToTagList(id, element.id, String(tag.id), activityInfo.twoMonths).then(response => {
        huddleDisplayList(response);
      });
      return;
    }

    studentWritingLevelRecordApi.deleteTag(id, String(tag.id)).then(response => {
      setWritingLevelTags(response);
      huddleDisplayList(response);
    });
  };

  return (
		<div className='content-table-element-tags'>
			<ul className='content-table-ul-tag'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{tag.name}
					</h2>
				</li>
				<li className='content-table-ul-li'>
					<div className='content-color' style={
						{
						  width: 25,
						  height: 25,
						  backgroundColor: tag.color
						}
					} />
				</li>
				<li className='content-table-ul-li' onClick={hundleDelete}>
					<MdDelete size='20' className='' style={{ color: '#ff0000' }} />
				</li>
			</ul>
		</div>
  );
}
