import React from 'react';
import Table from '../Table';
import './style.scss';

interface EstrategyProps {
  id: string
}

export default function RoomsTeacher({ id }: EstrategyProps): React.ReactElement {
  return (
		<div className={'room-teacher-container'} id={id}>
			<Table />
		</div>
  );
}
