/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useRef } from 'react'
import { IoCloseOutline } from 'react-icons/io5'
import { useParams } from 'react-router-dom'
import clearAllInputs from './service/clear-all-inputs'
import './style.scss'
import LoadContent from '../../../components/Load-content'
import ActivityCustumer from '../../../class/ProgrammaticContent/programmaticCustumer'

interface FormAddElementProps {
  displayCreateNewElement: boolean
  activityInfo: Record<string, string>
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setProgrammaticContent: React.Dispatch<React.SetStateAction<any[]>>
}

export default function FormAddElement({ displayCreateNewElement, activityInfo, setDisplayCreateNewElement, setProgrammaticContent }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const { id } = useParams()

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const textarea = textareaRef.current

    if (!id || !textarea) {
      return
    }

    const activityCustumer = new ActivityCustumer(textarea, id, activityInfo.matterId, activityInfo.twoMonths)

    setIsLoading(true);
    activityCustumer.post().then(response => {
      if (response[0].error) {
        setIsLoading(false);
        return
      }

      if (response[0]) {
        setProgrammaticContent(response)
        setDisplayElement(false)
        clearAllInputs()
        clearAll()
        setIsLoading(false);
      }
    });
  }

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false)
    setDisplayCreateNewElement(false)

    if (clear) {
      clearAll()
    }
  }

  const clearAll = () => {
    (textareaRef.current ? textareaRef.current : {value: ''}).value = '';
  }

  useEffect(() => {
    setDisplayElement(displayCreateNewElement)
  }, [displayCreateNewElement])

  return (
    <div className={`form-add-container-activity display-${displayElement}`}>
      <form method='POST' className='form-add-element-activity' id='form-add-element-activity' onSubmit={e => {
        hundleSubmit(e)
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicionar um novo conteúdo programático
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false)
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='curriculum-component'>Conteúdo Programático</label>
            <textarea ref={textareaRef} className='curriculum-component-textarea' id='curriculum-component' autoComplete='off' />
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose(true)
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Adicionar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
