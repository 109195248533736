/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */

import type { RoomType } from '../../../class/types/Room/Rooms-protocol';

/* eslint-disable @typescript-eslint/prefer-readonly */
interface InformationType {
  content: string
  schoolName: string
  schoolYear: string
}

export default class RoomsFilter {
  private information: InformationType;

  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly rooms: RoomType[]
  ) {
    this.information = {
      content: inputs.item(0).value,
      schoolName: inputs.item(1).value,
      schoolYear: inputs.item(2).value
    };
  }

  searchElement(): RoomType[] {
    let findings: RoomType[];

    findings = this.searchElementByContent(this.information.content);

    findings = this.searchElementBySchool(this.information.schoolName, findings);

    findings = this.searchElementBySchoolYear(this.information.schoolYear, findings);

    return findings;
  }

  searchElementByContent(content: string): RoomType[] {
    const findings: RoomType[] = [];

    this.rooms.forEach(room => {
      if (room.roomName.includes(content)) {
        findings.push(room);
      }
    });

    return findings;
  }

  searchElementBySchool(schoolName: string, rooms: RoomType[]): RoomType[] {
    const findings: RoomType[] = [];

    if (!schoolName || schoolName.length === 0) {
      return rooms;
    }

    rooms.forEach(room => {
      if (!room.schoolName) {
        return;
      }

      if (room.schoolName.includes(schoolName)) {
        findings.push(room);
      }
    });

    return findings;
  }

  searchElementBySchoolYear(schoolYear: string, rooms: RoomType[]): RoomType[] {
    const findings: RoomType[] = [];

    if (!schoolYear || schoolYear.length === 0) {
      return rooms;
    }

    rooms.forEach(room => {
      if (!room.date) {
        return;
      }

      const date = room.date.split('/')[2];

      if (date.includes(schoolYear)) {
        findings.push(room);
      }
    });

    return findings;
  }

  static roomIDs(findings: RoomType[]): string[] {
    const roomIDs = findings.map(room => room.id) as string[];

    return roomIDs;
  }
}
