/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {useState, useEffect} from 'react';
import { MdDelete } from 'react-icons/md';
import type { ActionResponseProtocol } from '../../../class/types/Action/ActionAPI-protocol';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import UserAPI from '../../../class/User/UserAPI';
import './style.scss';
import LoadContent from '../../Load-content';

interface ContentTableType {
  user: UserClientProtocol
  index: number
  setTeacherCostumer: (actions: ActionResponseProtocol[]) => void
}

export default function ContentTableTeacher({ user, index, setTeacherCostumer }: ContentTableType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);

  const huddleDelete = () => {
    if (user.id) {
      setIsLoading(true);
      UserAPI.deletePermission(user.id, 'teacher').then(response => {
        setTeacherCostumer(response);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [user]);

  return (
		<div className={`content-table-element-teacher display-animation-${displayAnimation}`}>
			<ul className='content-table-ul-coordinator'>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<p>
						{user.userName}
					</p>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{user.id}
					</h2>
				</li>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
			</ul>
		</div>
  );
}
