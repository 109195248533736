
/* eslint-disable react/jsx-key */
import React from 'react';
import type { MatterResProtocol } from '../../../class/types/Matter/matterApi-protocol';
import MatterElement from '../matter-element';
import './style.scss';

interface MatterListProps {
  matters: MatterResProtocol[]
  twoMonths: string
  twoMonthsName: string
  onClickLi: (elementName: string) => void
  setActivityInfo: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
}

export default function MatterList({ matters, twoMonths, twoMonthsName, onClickLi, setActivityInfo }: MatterListProps): React.ReactElement {
  return (
		<ul className='matter-list'>
			{matters.map(matter => <MatterElement
				twoMonths={twoMonths}
				twoMonthsName={twoMonthsName}
				onClickLi={onClickLi}
				matter={matter}
				setActivityInfo={setActivityInfo}
			/>)}
		</ul>
  );
}
