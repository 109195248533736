import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type UploadRoomObserverSheetPut, type UploadRoomObserverSheetPost, type UploadRoomObserverSheetType } from '../types/Upload_room-observer-sheet-api';

class UploadRoomObserverSheetApi implements UploadRoomObserverSheetApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, classRoom, classRoomId, date, name, userName, userId }: UploadRoomObserverSheetPost): Promise<UploadRoomObserverSheetType[]> {
		let newElementaryWeeklyPlan: UploadRoomObserverSheetType[] = [];

		const formDate = new FormData();

		formDate.append('fileRoomObserverSheet', file);
    formDate.append('classRoom', classRoom);
    formDate.append('classRoomId', classRoomId);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/room/observersheet',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementaryWeeklyPlan = response.data as UploadRoomObserverSheetType[];
		});

		return newElementaryWeeklyPlan;
	}

	// async postDescription(decentWorkId: string, classRoomId: string, description: string): Promise<UploadRoomObserverSheetType[]> {
	// 	let newElementaryWeeklyPlan: UploadRoomObserverSheetType[] = [];

	// 	await axios.post(`${this.href}/description/kindergartenschool/weeklyPlan/${decentWorkId}`, {
	// 		description
	// 	}).then(response => {
	// 		newElementaryWeeklyPlan = response.data as UploadRoomObserverSheetType[];
	// 	});

	// 	return newElementaryWeeklyPlan;
	// }

	async put({ file, name, roomObserverSheetId, classRoomId }: UploadRoomObserverSheetPut): Promise<UploadRoomObserverSheetType[]> {
		let newElementaryWeeklyPlan: UploadRoomObserverSheetType[] = [];

		const formData = new FormData();

		formData.append('fileRoomObserverSheet', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/room/observersheet/${classRoomId}/${roomObserverSheetId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementaryWeeklyPlan = response.data as UploadRoomObserverSheetType[];
		});

		return newElementaryWeeklyPlan;
	}

	async get(classRoomId: string): Promise<UploadRoomObserverSheetType[]> {
		let newElementaryWeeklyPlan: UploadRoomObserverSheetType[] = [];

		await axios.get(this.href + `/file/room/observersheet/${classRoomId}`).then(response => {
			newElementaryWeeklyPlan = response.data as UploadRoomObserverSheetType[];
		}).catch(e => {
			//
		});

		return newElementaryWeeklyPlan;
	}

	async delete(elementId: string, classRoomId: string, fileName: string): Promise<UploadRoomObserverSheetType[]> {
		let newElementaryWeeklyPlan: UploadRoomObserverSheetType[] = [];

		await axios.delete(this.href + `/file/room/observersheet/${classRoomId}/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			newElementaryWeeklyPlan = response.data as UploadRoomObserverSheetType[];
		}).catch(e => {
			//
		});

		return newElementaryWeeklyPlan;
	}
}

export default new UploadRoomObserverSheetApi();
