/* eslint-disable react/jsx-key */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable no-mixed-spaces-and-tabs */

import React, { useState } from 'react';
import './style.scss';

import DisplayDocument from '../../../components/displayDocument';
import formatFileName from '../../../services/formatFileName';
import { MdDateRange, MdDelete } from 'react-icons/md';
import { FaEye, FaFileAlt } from 'react-icons/fa';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import UploadKindergartenSchoolWeeklyPlanApi from '../../../class/UploadKindergartenSchoolWeeklyPlan/upload-weekly-plan-api'
import lscache from 'lscache';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import LoadContent from '../../../components/Load-content';
import CommentsElement from '../../../components/comments-element';

interface KindergartenSchoolWeeklyPlanInformationProps {
  setDisplayWeeklyPlanInfo: React.Dispatch<React.SetStateAction<boolean>>
  setWeeklyPlanSchoolWeeklyPlan: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  weeklyPlanSchoolWeeklyPlan: ElementarySchoolDecentWorkType[]
  setElement: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType>>
  displayWeeklyPlanInfo: boolean
  element: any
}

export default function WeeklyPlanDisplayInformation({
  setWeeklyPlanSchoolWeeklyPlan,
  weeklyPlanSchoolWeeklyPlan,
  setDisplayWeeklyPlanInfo,
  displayWeeklyPlanInfo,
  setElement,
  element
}: KindergartenSchoolWeeklyPlanInformationProps): JSX.Element {
  const [descriptionDeleteLoading, setDescriptionDeleteLoading] = useState(false);
  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const deleteDescription = (descriptionId: string) => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user.id) {
      return;
    }

    setDescriptionDeleteLoading(true)
    UploadKindergartenSchoolWeeklyPlanApi.deleteDescription(element.id, descriptionId, user.id).then(response => {
      setDescriptionDeleteLoading(false);
      if (!response) {
        return;
      }

      weeklyPlanSchoolWeeklyPlan.findIndex((value, index) => {
        if (response?.id === value?.id) {
          weeklyPlanSchoolWeeklyPlan[index] = response;

          setElement(response);
          setWeeklyPlanSchoolWeeklyPlan(weeklyPlanSchoolWeeklyPlan);
          return true;
        }

        return false;
      })
    }).catch(e => {

    });
  }

  const IsTheUser = ({ children, userId }: { children: any, userId: string }) => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user) {
      return '';
    }

    if (user.id !== userId) {
      return '';
    }

    return children
  }

  return (
    <div className={`kindergarten-school-weekly-plan-information display-${String(displayWeeklyPlanInfo)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          Rotina Semanal
        </h1>
        <button onClickCapture={() => {
          setDisplayWeeklyPlanInfo(false);
        }}>Voltar</button>
      </div>
      <div className='main-monthly-plan-display-information' id='main-monthly-plan-display-information'>
        <div className="decent-work-information">
          <h1><FaFileAlt className='file-description-icon' /> <span>{formatFileName(element.fileName)}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1><MdDateRange className='file-description-icon' /> <span>{formatDate(element.date)}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1><FaEye className='file-description-icon' /> <span>{element.checked ? `Visualizações: ${Object.keys(element.visualizations ?? { generic: 'generic' }).length ?? 1}` : 'Não visualizado'}</span></h1>
        </div>
        {!element.visualizations
          ? ''
          : (
            <div className="decent-work-information">
              <h1><span>Visualizações:</span></h1>
              <ul className='visualization-list'>
                {Object.keys(element.visualizations).map(key => (
                  <li key={key}>{element.visualizations[key].user} <span>visualizou em {element.visualizations[key].date}</span></li>
                ))}
              </ul>
            </div>
          )
        }
        <div className="decent-work-information">
          <h1>Emissor: <span>{element.issuer?.userName}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1>Data de emissão: <span>{element.issuer?.emissionDate}</span></h1>
        </div>
        <div className='adm-hours-information-frame-content'>
          <DisplayDocument type={element.type} url={element.location.url} />
        </div>
        {!element.description
          ? ''
          : (
            <div className='description-content'>
              {!element.description
                ? ''
                : (
                  <div className='description-title'>
                    <div className='comment-title-content'>
                      <span className='tag'>Comentário</span>
                      {!element.description.issue
                        ? ''
                        : (
                          <>
                            <h1>{element.description?.issue?.userName}</h1>
                            <span className='date-content'>{element.description?.date}</span>
                          </>
                        )
                      }
                    </div>
                  </div>
                )}
              <p>
                {element.description?.content}
              </p>
            </div>
          )}
        {!element.comments
          ? ''
          : Object.keys(element.comments).map((key) => (
            <CommentsElement
              api={UploadKindergartenSchoolWeeklyPlanApi}
              comment={element.comments[key]}
              elementId={element.id}
              elements={weeklyPlanSchoolWeeklyPlan}
              setElement={setElement}
              setElements={setWeeklyPlanSchoolWeeklyPlan}
              commentId={key}
            />
          ))}
      </div>
    </div>
  );
}
