/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, { useState, useEffect } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import StrategyAPI from '../../../class/Strategy/StrategyAPI';
import './style.scss';
import LoadContent from '../../Load-content';

interface ContentTableActionType {
  index: number
  action: any
  setStrategies: React.Dispatch<React.SetStateAction<any[] | undefined>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export default function ContentTableAction({
  index,
  action,
  setStrategies,
  setDisplayEditElement,
  setElementId
}: ContentTableActionType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const hundleDelete = () => {
    setIsLoading(true);
    StrategyAPI.deleteStrategy(String(action.id)).then(response => {
      setStrategies(response);
      setIsLoading(false);
    });
  };

  const huddleEdit = () => {
    setDisplayEditElement(true);
    setElementId(String(action.id));
  };

  const huddleClick = (e: any) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    window.location.href = `${window.location.origin}/acoes/${action.id}`;
  };

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [action])

  return (
    <div className={`content-table-element-strategy-file display-animation-${displayAnimation}`}>
      <ul className='content-table-ul' onClick={huddleClick}>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            {action.strategyName}
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {action.responsible}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {action.date}
          </h2>
        </li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={hundleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
      </ul>
    </div>
  );
}
