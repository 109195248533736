/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import './styles.scss';
import InputSelect from '../../../components/input-select';
import Chart from 'react-apexcharts';
import MathematicDiagnosticAssessmentApi from '../../../class/mathematic-diagnostic-assessment/mathematic-diagnostic-assessment-api';
import { useParams } from 'react-router-dom';
import generatePDF from './service/generetePDF';

const defaultBgImg = require('./img/undraw_Pie_chart_re_bgs8.png');

interface MathematicsDiagnosticAssessmentGeneralDashboardComparativeProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MathematicsDiagnosticAssessmentGeneralDashboardComparative({ display, setDisplay }: MathematicsDiagnosticAssessmentGeneralDashboardComparativeProps): React.ReactElement {
  const [mathematicDiagnosticDate, setMathematicDiagnosticDate] = useState<
    {
      yes: number[]
      not: number[]
    }>({
      yes: [0, 0, 0, 0, 0, 0, 0],
      not: [0, 0, 0, 0, 0, 0, 0],
    });
  const [mathematicDiagnosticDateTwo, setMathematicDiagnosticDateTwo] = useState<
    {
      yes: number[]
      not: number[]
    }>({
      yes: [0, 0, 0, 0, 0, 0, 0],
      not: [0, 0, 0, 0, 0, 0, 0],
    });

  const [optionSelected, setOptionSelected] = useState<number[]>([]);
  const [optionSelectedTwo, setOptionSelectedTwo] = useState<number[]>([]);

  const [textOfOption, setTextOfOption] = useState<string>('');
  const [textOfOptionTwoMonths, setTextOfOptionTwoMonths] = useState<string>('');
  const [textOfOptionTwoMonthsTwo, setTextOfOptionTwoMonthsTwo] = useState<string>('');

  const [deleteOption, setDeleteOption] = useState<boolean>(false);
  const [deleteOptionTwo, setDeleteOptionTwo] = useState<boolean>(false);

  const { id, roomName } = useParams();

  const twoMonths = [
    'twoMonths-one',
    'twoMonths-two',
    'twoMonths-tree',
    'twoMonths-for',
  ]

  const twoMonthsOptions = [
    '1º Bimestre',
    '2º Bimestre',
    '3º Bimestre',
    '4º Bimestre',
  ]
  const options = [
    'Familiares ou Frequentes',
    'Opacos',
    'Transparentes',
    'Terminam com zero',
    'Generalização',
    'Zero intercalado',
    'Composto por algarismos iguais',
  ]

  const huddleInputSelectTwoMonths = (option: string) => {
    if (!id) {
      return;
    }

    setTextOfOptionTwoMonths(option);

    setDeleteOption(!deleteOption);
    setOptionSelected([0, 0]);

    const mathematicDiagnosticApi = new MathematicDiagnosticAssessmentApi();
    mathematicDiagnosticApi.getDataByTwoMonths(id, twoMonths[twoMonthsOptions.indexOf(option)]).then(response => {
      setMathematicDiagnosticDate(response);
    }).catch(e => {

    });
  }
  const huddleInputSelectTwoMonthsTwo = (option: string) => {
    if (!id) {
      return;
    }

    setTextOfOptionTwoMonthsTwo(option);

    setDeleteOptionTwo(!deleteOptionTwo);
    setOptionSelectedTwo([0, 0]);

    const mathematicDiagnosticApi = new MathematicDiagnosticAssessmentApi();
    mathematicDiagnosticApi.getDataByTwoMonths(id, twoMonths[twoMonthsOptions.indexOf(option)]).then(response => {
      setMathematicDiagnosticDateTwo(response);
    }).catch(e => {

    });
  }

  const huddleInputSelectNivel = (option: string) => {
    setOptionSelected([
      mathematicDiagnosticDate.not[options.indexOf(option)],
      mathematicDiagnosticDate.yes[options.indexOf(option)]
    ]);
    setTextOfOption(option);
  }

  const huddleInputSelectNivelTwo = (option: string) => {
    setOptionSelectedTwo([
      mathematicDiagnosticDateTwo.not[options.indexOf(option)],
      mathematicDiagnosticDateTwo.yes[options.indexOf(option)]
    ]);
    setTextOfOption(option);
  }

  const huddlePrint = () => {
    if (!roomName) {
      return;
    }
    generatePDF(roomName, textOfOptionTwoMonthsTwo);
  }

  const huddleCloseWindow = () => {
    setDisplay(!display);
  }

  return (
    <div className={`mathematics-diagnostic-assessment-general-dashboard-comparative display-${display}`}>
      <div className='mathematics-diagnostic-assessment-general-table-header'>
        <h1>Gráfico 1</h1>
        <InputSelect
          options={twoMonthsOptions}
          defaultValue='Bimestre'
          cb={huddleInputSelectTwoMonths}
          title=''
        />
        <InputSelect
          options={options}
          defaultValue='Níveis'
          cb={huddleInputSelectNivel}
          deleteAll={deleteOption}
          title=''
        />
        <h1>Gráfico 2</h1>
        <InputSelect
          options={twoMonthsOptions}
          defaultValue='Bimestre'
          cb={huddleInputSelectTwoMonthsTwo}
          title=''
        />
        <InputSelect
          options={options}
          defaultValue='Níveis'
          cb={huddleInputSelectNivelTwo}
          deleteAll={deleteOptionTwo}
          title=''
        />
        <button type='button' className='button-content' onClick={huddlePrint}>Imprimir</button>
        <button type='button' className='button-content' onClick={huddleCloseWindow}>Voltar</button>
      </div>
      <div className='mathematic-diagnostic-dashboard-content' id='mathematic-diagnostic-dashboard-comparative'>
        {
          optionSelected.length > 0
            ? (
              <>
                <div className='chart-comparative-content'>
                  <Chart
                    type='pie'
                    width={400}
                    height={400}
                    series={optionSelected}
                    options={{
                      labels: ['Não', 'Sim'],
                      colors: ['#FF2E00', '#118951'],
                      legend: {
                        fontSize: '16rem',
                        offsetY: 70,
                      },
                      chart: {
                        width: '50px',
                        height: '50px'
                      },
                      title: {
                        align: 'center',
                        margin: 30,
                        style: {
                          fontSize: '2rem'
                        },
                      }
                    }}
                  />
                  <Chart
                    type='pie'
                    width={400}
                    height={400}
                    series={optionSelectedTwo}
                    options={{
                      labels: ['Não', 'Sim'],
                      colors: ['#FF2E00', '#118951'],
                      legend: {
                        fontSize: '16rem',
                        offsetY: 70,
                      },
                      chart: {
                        width: '50px',
                        height: '50px'
                      },
                      title: {
                        align: 'center',
                        margin: 30,
                        style: {
                          fontSize: '2rem'
                        },
                      }
                    }}
                  />
                </div>
                <div className='mathematic-diagnostic-dashboard-info'>
                  <div>
                    <h1>Diagnóstico de Matemática</h1>
                  </div>
                  <ul>
                    <li>
                      <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                        backgroundColor: '#FF2E00',
                      }}></div>
                      <h1>Não: {optionSelected[0] + optionSelectedTwo[0]}</h1>
                    </li>
                    <li>
                      <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                        backgroundColor: '#118951',
                      }}></div>
                      <h1>Sim: {optionSelected[1] + optionSelectedTwo[0]}</h1>
                    </li>
                  </ul>
                </div>
              </>
            )
            : (
              <img className='dashboard-default-image' src={defaultBgImg} alt="dashboard default image" />
            )
        }
      </div>
    </div>
  );
}
