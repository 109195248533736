/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import uploadElementarySchoolWeeklyPlanApi from '../../../class/UploadElementarySchoolSchoolWeeklytWork/upload-weekly-plan-api';
import ContentTableElementaryWeeklyPlan from '../../../components/weekly-plan';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import EmptyComponent from '../../../components/Empty-component';
import FilterPlan from '../../../class/filterPlan';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';

import FormDescribeWeeklyPlan from '../form-description-weekly-plan';
import FormEditWeeklyPlan from '../form-edit-weekly-plan';
import FormAddWeeklyPlan from '../form-add-weekly-plan';

import WeeklyPlanDisplayInformation from '../weekly-plan-display-information';
import BackToTop from '../../../components/back-to-top/back-to-top';
import './style.scss';
import ReadCheckingWeeklyPlan from '../../../components/read-checking-weekly-plan';
import LoadContent from '../../../components/Load-content';
import FileDeliveryDate from '../file-delivery-data';
import RuleVerification from '../../../components/rule-verification';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayFormDescribeElementaryWeeklyPlan, setDisplayDescribeElementaryWeeklyPlan] = useState(false);
  const [displayFormEditElementaryWeeklyPlan, setDisplayEditElementaryWeeklyPlan] = useState(false);
  const [displayFormAddElementaryWeeklyPlan, setDisplayElementaryWeeklyPlan] = useState(false);
  const [displayWeeklyPlanInfo, setDisplayWeeklyPlan] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elementarySchoolWeeklyPlan, setElementarySchoolWeeklyPlan] = useState<ElementarySchoolDecentWorkType[]>([]);
  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [displayReadChecking, setDisplayReadChecking] = useState<boolean>(false);

  const [displayFileDeliveryData, setDisplayFileDeliveryData] = useState<boolean>(false);

  const [element, setElement] = useState<ElementarySchoolDecentWorkType>(elementDefault);
  const mainElement = useRef<any>();
  const huddleCreateNewElement = () => {
    setDisplayElementaryWeeklyPlan(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan(inputs, elementarySchoolWeeklyPlan);

    setElementFiltered(filterPlan.searchPlans());
  };

  const huddleFileDeliveryData = () => {
    setDisplayFileDeliveryData(true);
  }
  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }
    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      uploadElementarySchoolWeeklyPlanApi.get().then((response) => {
        setIsLoading(false);
        if (permission === 'professor') {
          const weeklyPlan = response.filter(value => value.issuer?.userId === user.id)
          setElementarySchoolWeeklyPlan(weeklyPlan);
          setElementFiltered(weeklyPlan);
          return;
        }

        setElementarySchoolWeeklyPlan(response);
        setElementFiltered(response);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    })
  }, [display]);

  useEffect(() => {
    setElementFiltered(elementarySchoolWeeklyPlan);
  }, [elementarySchoolWeeklyPlan]);

  return (
    <div className='table-container-elementary-school-weekly-plan'>
      <h1 className='title-table'>Ensino Fundamental - Rotina Semanal</h1>
      <FileDeliveryDate display={displayFileDeliveryData} setDisplay={setDisplayFileDeliveryData} />
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadCheckingWeeklyPlan
        displayReadChecking={displayReadChecking}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadChecking}
        setElementaryDecentWork={setElementarySchoolWeeklyPlan}
        type='elementary-school'
      />
      <FormAddWeeklyPlan
        displayFormAddWeeklyPlan={displayFormAddElementaryWeeklyPlan}
        setDisplayFormAddWeeklyPlan={setDisplayElementaryWeeklyPlan}
        setElementarySchoolWeeklyPlan={setElementarySchoolWeeklyPlan}
        elementarySchoolWeeklyPlan={elementarySchoolWeeklyPlan}
      />
      <FormEditWeeklyPlan
        displayFormEditWeeklyPlan={displayFormEditElementaryWeeklyPlan}
        setDisplayFormEditWeeklyPlan={setDisplayEditElementaryWeeklyPlan}
        setElementarySchoolWeeklyPlan={setElementarySchoolWeeklyPlan}
        setDisplayEditElement={setDisplayEditElementaryWeeklyPlan}
        elementId={elementId}
      />
      <FormDescribeWeeklyPlan
        displayFormDescribeWeeklyPlan={displayFormDescribeElementaryWeeklyPlan}
        setDisplayFormDescribeWeeklyPlan={setDisplayDescribeElementaryWeeklyPlan}
        setElementarySchoolWeeklyPlan={setElementarySchoolWeeklyPlan}
        elementId={elementId}
      />
      <WeeklyPlanDisplayInformation
        setWeeklyPlanSchoolWeeklyPlan={setElementarySchoolWeeklyPlan}
        weeklyPlanSchoolWeeklyPlan={elementarySchoolWeeklyPlan}
        setElement={setElement}
        displayWeeklyPlanInfo={displayWeeklyPlanInfo}
        setDisplayWeeklyPlanInfo={setDisplayWeeklyPlan}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar rotina semanal</button>
        <RuleVerification rule={['coordenador']}>
          <button onClick={huddleFileDeliveryData}>Visualizar dados de entrega</button>
        </RuleVerification>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='professor' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome da sala' />
        </div>
        <div className='date-container'>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '10rem',
              }
            }}
          />
          : (
            (display === 'true' && isLoading === false)
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar plano mensal) para adicionar um novo plano mensal</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType, index: number) => (
                    <ContentTableElementaryWeeklyPlan
                      index={index}
                      elementarySchoolWeeklyPlan={elementarySchoolWeeklyPlan}
                      setDisplayReadChecking={setDisplayReadChecking}
                      setDisplayEditElement={setDisplayEditElementaryWeeklyPlan}
                      setDisplayDescribeElementarySchoolWeeklyPlan={setDisplayDescribeElementaryWeeklyPlan}
                      setElementId={setElementId}
                      setElement={setElement}
                      setDisplayWeeklyPlan={setDisplayWeeklyPlan}
                      setElementarySchoolWeeklyPlan={setElementarySchoolWeeklyPlan}
                      type={'elementary-school'}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}
