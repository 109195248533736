/* eslint-disable @typescript-eslint/no-unused-vars */
import type StrategyCustomerProtocol from '../types/custumer-protocol';
import Validator from '../Validator';
import JustificationOfAbsencesApi from './JustificationOfAbsencesAPI';
import JustificationOfAbsencesFactory from './JustificationOfAbsences-factory';
import { type JustificationOfAbsencesType } from '../types/JustificationOfAbsences';
import lscache from 'lscache';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import { type StudentType } from '../types/Student/student-factory-protocol';

class JustificationOfAbsencesCostumer implements StrategyCustomerProtocol<JustificationOfAbsencesType> {
	private readonly justificationOfAbsencesApi = JustificationOfAbsencesApi;

	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly student: any,
    private readonly roomId: string,
    private readonly matterId: string,
    private readonly twoMonths: string,
    private readonly elementId?: string,
    ) {}

	async post(): Promise<any> {
		const validation = new Validator(this.inputs);

		if (validation.validation(false, true)) {
			return [{
				error: true
			}];
		}

    const user = lscache.get('user') as UserClientProtocol;

    if (!user) {
      return [{
        error: true,
      }]
    }

		let newJustificationOfAbsences: JustificationOfAbsencesType[] = [];

		const justificationOfAbsencesFactory = new JustificationOfAbsencesFactory(this.inputs, this.student, user);

		const justificationOfAbsences = justificationOfAbsencesFactory.create();

		await this.justificationOfAbsencesApi.post(this.roomId, this.matterId, this.twoMonths, justificationOfAbsences).then((response) => {
			newJustificationOfAbsences = response;
		});

		return newJustificationOfAbsences;
	}

	async put(): Promise<any[]> {
		let elementarySchool: JustificationOfAbsencesType[] = [];

		if (!this.inputs) {
			return [];
		}

		const justificationOfAbsencesFactory = new JustificationOfAbsencesFactory(
        this.inputs,
        this.student,
      );

		const justificationOfAbsencesPut = justificationOfAbsencesFactory.put();

    if (!justificationOfAbsencesFactory) {
      return [];
    }

		await this.justificationOfAbsencesApi.put(this.roomId, this.matterId, this.twoMonths, this.elementId ?? '', justificationOfAbsencesPut).then((response: JustificationOfAbsencesType[]) => {
			elementarySchool = response;
		});

		return elementarySchool;
	}
}

export default JustificationOfAbsencesCostumer;
