/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import uploadElementarySchoolHTTPIApi from '../../../class/UploadElementarySchoolHTTPI/upload-HTTPI-api';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';

import FormDescribeHTTPI from '../form-description-HTTPI';
import FormEditHTTPI from '../form-edit-HTTPI';
import FormAddHTTPI from '../form-add-HTTPI';

import ContentTableElementaryHTTPI from '../../../components/HTTPI';
import HTTPIDisplayInformation from '../HTTPI-display-information';
import EmptyComponent from '../../../components/Empty-component';
import FilterPlan from '../../../class/filterPlan';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import ReadCheckingHTTPI from '../../../components/read-checking-HTTPI';
import LoadContent from '../../../components/Load-content';

import './styles.scss';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayHTTPIInfo, setDisplayHTTPIInfo] = useState(false);
  const [displayFormDescribeElementaryHTTPI, setDisplayDescribeElementaryHTTPI] = useState(false);
  const [displayFormAddElementaryHTTPI, setDisplayElementaryHTTPI] = useState(false);
  const [displayFormEditElementaryHTTPI, setDisplayEditElementaryHTTPI] = useState(false);
  const [displayReadChecking, setDisplayReadChecking] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elementarySchoolHTTPI, setElementarySchoolHTTPI] = useState<ElementarySchoolDecentWorkType[]>([]);

  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');

  const [element, setElement] = useState<ElementarySchoolDecentWorkType>(elementDefault);
  const mainElement = useRef<any>();

  const huddleCreateNewElement = () => {
    setDisplayElementaryHTTPI(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan(inputs, elementarySchoolHTTPI);

    setElementFiltered(filterPlan.searchPlans());
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }
    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      setIsLoading(false);
      uploadElementarySchoolHTTPIApi.get().then((response) => {
        if (permission === 'professor') {
          const decentWork = response.filter(value => value.issuer?.userId === user.id)
          setElementarySchoolHTTPI(decentWork);
          setElementFiltered(decentWork);
          return;
        }

        setElementarySchoolHTTPI(response);
        setElementFiltered(response);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    })
  }, [display]);

  useEffect(() => {
    setElementFiltered(elementarySchoolHTTPI);
  }, [elementarySchoolHTTPI]);

  return (
    <div className='table-container-elementary-school-httpi'>
      <h1 className='title-table'>Ensino Fundamental - HTTPI</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadCheckingHTTPI
        displayReadChecking={displayReadChecking}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadChecking}
        setElementaryDecentWork={setElementarySchoolHTTPI}
        type='elementary-school'
      />
      <FormAddHTTPI
        displayFormAddHTTPI={displayFormAddElementaryHTTPI}
        setDisplayFormAddHTTPI={setDisplayElementaryHTTPI}
        setElementarySchoolHTTPI={setElementarySchoolHTTPI}
      />
      <FormEditHTTPI
        setDisplayFormEditHTTPI={setDisplayEditElementaryHTTPI}
        displayFormEditHTTPI={displayFormEditElementaryHTTPI}
        setDisplayEditElement={setDisplayEditElementaryHTTPI}
        setElementarySchoolHTTPI={setElementarySchoolHTTPI}
        elementId={elementId}
      />
      <FormDescribeHTTPI
        displayFormDescribeHTTPI={displayFormDescribeElementaryHTTPI}
        setDisplayFormDescribeHTTPI={setDisplayDescribeElementaryHTTPI}
        setElementarySchoolHTTPI={setElementarySchoolHTTPI}
        elementId={elementId}
      />
      <HTTPIDisplayInformation
        elementarySchoolHTTPI={elementarySchoolHTTPI}
        setElementarySchoolHTTPI={setElementarySchoolHTTPI}
        setElement={setElement}
        setDisplayHTTPIInfo={setDisplayHTTPIInfo}
        displayHTTPIInfo={displayHTTPIInfo}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar HTTPI</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='professor' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome da sala' />
        </div>
        <div className='date-content'>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '10rem',
              },
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar HTTPI) para adicionar um nova HTTPI</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork: ElementarySchoolDecentWorkType, index: number) => (
                    <ContentTableElementaryHTTPI
                      index={index}
                      elementarySchoolHTTPI={elementarySchoolDecentWork}
                      setDisplayEditElement={setDisplayEditElementaryHTTPI}
                      setDisplayChecking={setDisplayReadChecking}
                      setDisplayDescribeElementarySchoolHTTPI={setDisplayDescribeElementaryHTTPI}
                      setElementId={setElementId}
                      setElement={setElement}
                      setDisplayHTTPI={setDisplayHTTPIInfo}
                      setElementarySchoolHTTPI={setElementarySchoolHTTPI}
                      type={'kindergartenSchool'}
                    />
                  ))
              )
              : ''
          )}
      </main>
    </div>
  );
}
