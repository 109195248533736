import React from 'react';
import Table from '../Table';
import './style.scss';

interface StrategyProps {
  id: string
  activityInfo: Record<string, string>
  display: string
}

export default function SpellingLevel({ id, activityInfo, display }: StrategyProps): React.ReactElement {
  return (
		<div className={'spelling-level-container'} id={id}>
			<Table display={display} activityInfo={activityInfo} />
		</div>
  );
}
