import { type TextualGenreTagType } from '../../types/new-writing-hypothesi/textual-genre';
import TextualGenreTagsApi from './textual-genre-tags-api';

export default class TextualGenreTagsCostumer {
  async post(roomId: string, twoMonths: string, textualGenreId: string, tag: string, color: string): Promise<TextualGenreTagType[]> {
    const textualGenreTagApi = new TextualGenreTagsApi();

    const data: TextualGenreTagType = {
      tag,
      color,
      date: (new Date()).toLocaleDateString('pt-BR', {
        timeZone: 'UTC',
      }),
    }
    const response = await textualGenreTagApi.post(roomId, twoMonths, textualGenreId, data);

    return response;
  }

  async update(roomId: string, twoMonths: string, textualGenreId: string, textualGenreTagId: string, tag: string, color: string): Promise<TextualGenreTagType[]> {
    const textualGenreTagApi = new TextualGenreTagsApi();

    const data: TextualGenreTagType = {
      tag,
      color,
    }

    const response = await textualGenreTagApi.update(roomId, twoMonths, textualGenreId, textualGenreTagId, data);

    return response;
  }
}
