/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import StrategyOfPlanCostumer from '../../../class/StrategyOfPlans/strategy-of-custumer';
import type { StrategyOfPlanProtocol } from '../../../class/types/Strategy-of-plan/Strategy-of-plan-factory-protocol';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setStrategyOfPlanCustumer: (actions: StrategyOfPlanProtocol[]) => void
}

export default function FormAddElement({ displayCreateNewElement, setDisplayCreateNewElement, setStrategyOfPlanCustumer }: FormAddElementProps): React.ReactElement {
  const { id } = useParams();

  const [displayElement, setDisplayElement] = useState<boolean>(true);

  const textArea = useRef<any>();
  const input = useRef<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (id) {
      setIsLoading(true);

      const strategyOfPlanCustumer = new StrategyOfPlanCostumer([
        textArea.current,
        input.current
      ], id);
      strategyOfPlanCustumer.post().then(response => {
        if (response[0].error) {
          setIsLoading(false);
          return;
        }

        setStrategyOfPlanCustumer(response);
        setDisplayCreateNewElement(false);
        clearAllInputs();
        setIsLoading(false);
        textArea.current.value = '';
      });
    }
  };

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);
    clearAllInputs();
    textArea.current.value = '';
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container display-${displayElement}`}>
      <form method='POST' className='form-add-element' id='form-add-element-strategy-of-plan' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Crie uma nova estratégia
          </h1>
          <IoCloseOutline onClick={hundleClickClose} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='strategyName'>Estratégia:</label>
            <textarea
              name='strategyName'
              id='strategyName'
              cols={50}
              rows={10}
              ref={textArea}
            ></textarea>
          </div>
          <div className='input-content'>
            <label htmlFor='timeline'>Cronograma:</label>
            <input
              type='text'
              id='timeline'
              placeholder='cronograma'
              ref={input}
            />
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }
                }
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={hundleClickClose}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
