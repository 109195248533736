import { type SpellingLevelApiResType } from '../types/SpellingLevel/spellingLevelType';

class SpellingLevelFactory {
	constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {}

	create(): SpellingLevelApiResType {
		return {
			content: this.inputs[0].value,
			date: this.getDate()
		};
	}

	createPut() {
		return {
			content: this.verifyValue(0),
			date: this.getDate()
		};
	}

	verifyValue(index: number): string {
		return this.inputs[index].value ? this.inputs[index].value : this.inputs[index].placeholder;
	}

	private getDate(): string {
		const date = new Date();

		return date.toLocaleDateString('UTC', {
			dateStyle: 'short'
		});
	}
}

export default SpellingLevelFactory;
