/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState, useEffect, useReducer } from 'react';
import './App.scss';
import Header from '../../components/Header';
import Nav from '../../components/reading-fluency-nav';
import UserAPI from '../../class/User/UserAPI';
import lscache from 'lscache';
import LoadContent from '../../components/Load-content';
import ReadingFluencyPage from '../../container/reading-fluency-page/reading-fluency-page';
import ReadingFluencyDashboard from '../../container/reading-fluency-dashboard/reading-fluency-dashboard';
import ReadingFluencyLevelContext, { initialState, reducer } from './hooks/reducer';
// import LoadContent from '../../components/Load-content';

type Elements = Array<{
  name: string
  boolean: string
}>;

function App() {
  const user = lscache.get('user');

  const [state, dispatch] = useReducer(reducer, initialState);

  const [elements, setElements] = useState([
    {
      name: 'strategy-1',
      boolean: 'false'
    },
    {
      name: 'strategy-2',
      boolean: 'false'
    },
  ]);

  const [display1, setDisplay1] = useState<string>('false');
  const [display2, setDisplay2] = useState<string>('false');

  const [isLoadingPermission, setIsLoadingPermission] = useState(true);

  const huddleSetElements = (elementsParams: Elements) => {
    setElements(elementsParams);
    setDisplay1(elements[0].boolean);
    setDisplay2(elements[1].boolean);
  };

  useEffect(() => {
    UserAPI.getPermission(user.id).then(response => {
      elements[0].boolean = 'true';

      huddleSetElements(elements);
      setIsLoadingPermission(false);
    });
  }, []);

  return (
    <ReadingFluencyLevelContext.Provider
      value={{
        dispatch,
        state
      }}
    >
      <div className='App'>
        <Header />
        <main className='main-container'>
          <Nav setElements={huddleSetElements} elements={elements} />
          <div className='content-containers'>
            <LoadContent isLoading={isLoadingPermission} />
            <div className='content' id={`display-${display1}`}>
              <ReadingFluencyPage id='strategy-1' />
            </div>
            <div className='content' id={`display-${display2}`}>
              <ReadingFluencyDashboard id='strategy-1' display={display2} />
            </div>
          </div>
        </main>
      </div>
    </ReadingFluencyLevelContext.Provider>
  );
}

export default App;
