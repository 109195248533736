/* eslint-disable @typescript-eslint/no-unsafe-call */

import lscache from 'lscache';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { StrategyResponseProtocol } from '../../../class/types/Strategy/StrategyAPI-protocol';
import './style.scss';

export default function InformationStrategy(): React.ReactElement {
  const [strategy, setStrategy] = useState<StrategyResponseProtocol>();
  const { id } = useParams();

  useEffect(() => {
    lscache.get('strategy').forEach((element: StrategyResponseProtocol) => {
      if (element.id === id) {
        setStrategy(element);
      }
    });
  }, []);

  return (
		<div className='information-strategy-element'>
			<div className='information-main'>
				<div className='information-header'>
					<h1>Informações</h1>
				</div>
				<div className='information-body'>
					<div className='information-content'>
						<h1>Estratégia:</h1>
						<h2>{strategy?.strategyName}</h2>
					</div>
					<div className='information-content'>
						<h1>Responsável:</h1>
						<h2>{strategy?.responsible}</h2>
					</div>
					<div className='information-content'>
						<h1>Meta:</h1>
						<h2>{strategy?.goal}</h2>
					</div>
					<div className='information-content'>
						<h1>Responsável pela meta:</h1>
						<h2>{strategy?.goalResponsible}</h2>
					</div>
					<div className='information-content'>
						<h1>Indicador:</h1>
						<h2>{strategy?.indicator}</h2>
					</div>
					<div className='information-content'>
						<h1>Responsável pelo indicador:</h1>
						<h2>{strategy?.indicatorResponsible}</h2>
					</div>
					<div className='information-content'>
						<h1>Estimativas de custos:</h1>
						<h2>{strategy?.cust}</h2>
					</div>
					<div className='information-content'>
						<h1>Fonte:</h1>
						<h2>{strategy?.source}</h2>
					</div>
				</div>
			</div>
		</div>
  );
}
