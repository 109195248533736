
import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type ElementarySchoolWeeklyPlanType } from '../types/Upload_ElementarySchoolWeeklyPlan';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import { type PsychologistReportType } from '../types/Psychologist';

class UploadPsychologistApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, teacher, date, name, userName, userId }: {
    file: File
    teacher: UserClientProtocol
    date: string
    name: string
    userName: string
    userId: string
  }): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		const formDate = new FormData();

		formDate.append('psychologistReport', file);
    formDate.append('teacherName', teacher.userName ?? '');
    formDate.append('teacherId', teacher.id ?? '');
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/psychologist/report',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newElementaryWeeklyPlan;
	}

	async put({ file, name, reportId }: {
    file: File
    name: string
    reportId: string
  }): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		const formData = new FormData();

		formData.append('psychologistReport', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/psychologist/report/${reportId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		});

		return newElementaryWeeklyPlan;
	}

	async get(): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		await axios.get(this.href + '/file/psychologist/report').then(response => {
			newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		}).catch(e => {
			//
		});

		return newElementaryWeeklyPlan;
	}

  async getByTeacher(teacherId: string): Promise<PsychologistReportType[]> {
    let newPsychologistReportType: PsychologistReportType[] = [];

    await axios.get(this.href + `/file/psychologist/report/by/teacher/${teacherId}`).then(response => {
      newPsychologistReportType = response.data as PsychologistReportType[];
    }).catch(e => {
      //
    });

    return newPsychologistReportType;
  }

	async delete(elementId: string, fileName: string): Promise<ElementarySchoolWeeklyPlanType[]> {
		let newElementaryWeeklyPlan: ElementarySchoolWeeklyPlanType[] = [];

		await axios.delete(this.href + `/file/psychologist/report/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			newElementaryWeeklyPlan = response.data as ElementarySchoolWeeklyPlanType[];
		}).catch(e => {
			//
		});

		return newElementaryWeeklyPlan;
	}
}

export default new UploadPsychologistApi();
