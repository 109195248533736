/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import lscache from 'lscache';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface IsLoggedInProps {
  path: React.ReactElement
}

export default function IsLoggedIn({ path }: IsLoggedInProps): React.ReactElement {
  const user = lscache.get('user');

  if (user) {
    return path;
  }

  return <Navigate to='/login' />;
}
