/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-no-undef */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import ContentTable from '../../../components/strategy-files/ContentTable';
import FormAddElement from '../FormAddElement-strategy';
import StrategyAPI from '../../../class/Strategy/StrategyAPI';
import './style.scss';
import FormEditElement from '../FormEditElement-strategy';
import EmptyComponent from '../../../components/Empty-component';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [elementId, setElementId] = useState<string>();
  const [strategies, setStrategies] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(true);
  };

  useEffect(() => {
    if (display === 'false') {
      return
    }
    setIsLoading(true);
    StrategyAPI.get().then((response: any[]) => {
      setIsLoading(false);
      setStrategies(response);
    });
  }, [display]);

  return (
    <div className='table-container'>
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setStrategies={setStrategies}
      />
      <FormEditElement
        displayEditElement={displayEditElement}
        setDisplayEditElement={setDisplayEditElement}
        setStrategies={setStrategies}
        elementId={elementId}
      />
      <div className='btn-create-new-action-container'>
        <button onClick={hundleCreateNewElement}>Criar novo objetivo estratégico</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Objetivo estratégico</li>
          <li className='header-table-ul-li'>Responsável</li>
          <li className='header-table-ul-li'>Data de criação</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table'>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
          />
          : (
        display === 'true' ? (
          strategies?.length === 0
          ? <EmptyComponent>click em (criar nova estratégia) para criar uma estratégia</EmptyComponent>
          : strategies?.map((action: any, index: number) => (
            <ContentTable
              index={index}
              action={action}
              setDisplayEditElement={setDisplayEditElement}
              setStrategies={setStrategies}
              setElementId={setElementId}
              key={String(action.id)}
            />
          ))
        ) : ''
          )
        }
      </main>
    </div>
  );
}
